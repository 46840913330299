import React, { useEffect, useState } from "react"
import { EpgChannelDetails } from "scenes/LiveTv/components/EpgChannelDetails"
import { EpgListWrapper } from "scenes/LiveTv/components/EpgListWrapper"
import { CreateComponent } from "components/utils/CreateComponent"
import { rootStore } from "store/RootStore"

export const LiveTvConfig = {
	cellHeight: 116,
	cellMarginBottom: 3,
	//MTV-3836 channelNumToPreGen: 20
}

const styles = theme => ({
	//liveTvWrapper: { width: theme.themeDefaultWidth, margin: "0 auto", marginTop: 0 },
	liveTvLeftColumn: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		padding: "5px 0px 0px 5px",
		width: "100%",
		WebkitUserSelect: "none",
		mozUserSelect: "none",
		UserSelect: "none",
	},
	/* CHECK_MERGE */
	// liveTvRightColumn: { width: 360, paddingLeft: 16, /* paddingTop: 10 */ },
	flexContainer: {
		display: "flex",
		margin: /* "15px 0 auto" */ "78px auto 0 auto",
		width: theme.themeDefaultWidth
	}
})

// If screenfull is used (disabled with MTV-2172), a scrollbar appears in fullscreen mode when the right epg column is too tall
const getWindowDimensions = () => window.innerHeight - 99 //todo - header height
const getWindowWidthDimentsions = () => window.innerWidth

const LiveTvBody = CreateComponent(styles, false, true, ({ classes, store, page, windowDimensions, ...props }) => {

	useEffect(() => {
		window.scrollTo(0, 0)
		// MTVW-272: invoked after a movie has been started from the OverlayEventDetails
		const elem = document.getElementById("tvLiveAndDetailsContainer")
		if (elem) elem.scrollTop = 0
	})
	return (
		// MTVW-754 trial
		<div style={{
			display: "flex",
			justifyContent: "center",
			/*alignItems: "center"*/
		}} className={classes.flexContainer + " flexContainerResponsive"}>
			{/* MTVW-754 trial */}
			<div id="tvLiveAndDetailsContainer" className={classes.liveTvLeftColumn + " LiveTvLeftColumnResponsive"} /*style={{ position: "relative", margin: "0 auto", width: "80vw" }}*/>
				<EpgChannelDetails page={page} />
			</div>
			<EpgListWrapper page={page} windowDimensions={windowDimensions} />
			{/* <Grid item className={classes.liveTvRightColumn + " LiveTvRightColumnResponsive"} style={{ height: windowDimensions }}>
				</Grid> */}
		</div>
		// {/* {props.windowWidthDimensions < 1023 && <BottomMenu store={store} />} */}
		// </Grid>

		// 	{props.windowWidthDimensions > 1023 && <BottomMenu store={store} />}
		// 	{/*<DialogPin />
		// 	<DialogChangeProfile />*/}
		// // </>
	)
})

export const LiveTv = CreateComponent(null, false, false, () => {
	const page = rootStore.page.LiveTv

	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
	const [windowWidthDimensions, setWindowWidthDimensions] = useState(getWindowWidthDimentsions())

	const handleResize = () => {
		setWindowDimensions(getWindowDimensions())
		setWindowWidthDimensions(getWindowWidthDimentsions())
	}

	useEffect(
		...page.getMountBody(
			() => {
				window.addEventListener("resize", handleResize)
			},
			() => {
				//console.debug("LiveTV UNMOUNT %o", page)
				window.removeEventListener("resize", handleResize)
			}
		)
	)

	return <LiveTvBody page={page} windowDimensions={windowDimensions} windowWidthDimensions={windowWidthDimensions} />
})
