/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-09
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-11
 */

import { observable, makeObservable } from "mobx"
import { TraxisModelAbstract } from "store/api/TraxisModelAbstract"
import { makeMapCollection } from "store/ModelTools"
//import { TvGuideConf } from "store/page/TvGuide"
//import { rootStore } from "store/RootStore"
//import { DayInSec } from "store/util/moment"
import { TraxisAbstract } from "./TraxisAbstract"
import { TraxisConditionAnd, TraxisConditionOr } from "./TraxisCondition"

export const RECORDINGS_STATUS = {}

export class GetRecordingsMarkersModel extends TraxisModelAbstract {
	/**
	 * @type {Map.<GetRecordingsMarkersItem>}
	 */
	_$Recording = new Map()
	resultCount = null

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			_$Recording: observable.shallow,
			resultCount: observable
		})
	}

	set Recording(v) {
		makeMapCollection(this._$Recording, v, i => (i.EventId ? i.EventId : i.SeriesId), () => new GetRecordingsMarkersItem())
		return this._$Recording
	}

	/**
	 * @returns {Map.<GetRecordingsMarkersItem>}
	 */
	get Recording() {
		return this._$Recording
	}

	/**
	 * @returns {GetRecordingsMarkersItem}
	 */
	getById(id) {
		return this.Recording.get(id) ?? null
	}
}

export class GetRecordingsMarkersItem {
	id = null
	EventId = null
	ParentRecordingCount = 0
	State = null
	IsPersonal = null
	SeriesId = null
	// String (Active, Suspended, Completed)
	FactoryState = null

	constructor() {
		makeObservable(this, {
			EventId: observable,
			ParentRecordingCount: observable,
			State: observable,
			IsPersonal: observable,
			SeriesId: observable,
			FactoryState: observable
		})
	}

	static createItem() {
		const item = new GetRecordingsMarkersItem()
		return item
	}

	get isSeries() {
		return this.ParentRecordingCount !== 0 || this.SeriesId !== null
	}

	get TitleId() {
		return this.EventId.split(",")[0]
	}

	get isComplete() {
		return this.State === "Complete"
	}

	get isDeleted() {
		return this.State === "Deleted"
	}

	get recType() {
		const t = ["rec", this.isSeries ? "series" : "single"]
		if (this.isComplete) {
			t.push("complete")
		} else {
			t.push("planned")
		}
		return t.join("_")
	}
}

/**
 * @property {array<string>} DataMaps
 */
export class GetRecordingsMarkers extends TraxisAbstract {
	_onStartCreate = true

	get _model() {
		return GetRecordingsMarkersModel
	}

	_getParseResp(data) {
		return data.Recordings
	}
	getXmlReq() {
		const filter = TraxisConditionAnd()
			.setAsFilterOption()
			.add(
				TraxisConditionOr(
					TraxisConditionAnd()
						.add("Type==Event")
						.add("Channels>0")
						.add("(State==Complete||State==Scheduled||State==Deleted)")
					// -9 days and 16 next
					//.add(`StartTime>${rootStore.time.getTimeFormatBackendTick(DayInSec, moment => moment.subtract(TvGuideConf.dayScopePrev + 2, "days"))}`)
					//.add(`StartTime<${rootStore.time.getTimeFormatBackendTick(DayInSec, moment => moment.add(TvGuideConf.dayScopeNext + 2, "days"))}`)
				).add(TraxisConditionAnd().add("Type==Series"))
			)
			.add(
				TraxisConditionOr()
					.add("IsPersonal==false")
					.add(`ProfileId=="${this.profileId}"`)
			)
		// <Option type="Propset">all</Option>
		//<Option type="Props">EventId,SeriesId,State,ParentRecordingCount,IsPersonal,FactoryState</Option>
		//${filter}
		return `<?xml version="1.0" encoding="utf-8"?>
<Request xmlns="urn:eventis:traxisweb:1.0">
	<Identity>
		<ProfileId>${this.profileId}</ProfileId>
	</Identity>
	<RootRelationQuery relationName="Recordings">
		<Options>
			<Option type="Props">EventId,SeriesId,State,ParentRecordingCount,IsPersonal,FactoryState,UpdateTime,NextScheduledStartTime</Option>
			${filter}
		</Options>
  </RootRelationQuery>
</Request>`
	}
}
