/**
 * @ Author: Kamila Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-07
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-11
 */

//import { AppBar, Button, IconButton, Toolbar } from "@mui/material"
import { AppBar, Toolbar } from "@mui/material"
//import SearchIcon from "@mui/icons-material/Search"
import { ProfileMenu } from "components/ProfileMenu"
// import { ChannelListMenu } from "components/ChannelListMenu"
import { CreateComponent } from "components/utils/CreateComponent"
import logo from "img/Quickline_logo.svg"
//import React from "react"
//import { Link } from "react-router-dom"
import { TopMenu } from "./TopMenu"
import { useHistoryRouteAdd } from "components/utils/RouteUtils"
import { ROUTE_NAMES } from "site-ql/RouteConst"
import { RouteTab, RouteTabs } from "./utils/RouteTab"
import searchIcon from "components/welcomeModal/icons/search.svg"
import { rootStore } from "store/RootStore"
//import { isMobileScreenSize } from "utils/Utils"


const styles = theme => ({
	//  MTV-2171 ToolBar: { display: "flex", /*width: theme.themeDefaultWidth,*/ margin: "0 auto", backgroundColor: "#2A2A2A" },
	ToolBar: { display: "flex", /*width: theme.themeDefaultWidth,*/ margin: "0 auto", backgroundColor: "#2A2A2A" },
	AppBar: { backgroundColor: "#2A2A2A" }, //theme.palette.background.default "#2A2A2A"
	NavBarLogo: {
		justifyContent: "flex-start", margin: "6px 39px 0px 24px", width: 165, height: 60, paddingLeft: 16, paddingRight: 16, cursor: "pointer"
	},
	NavBarWrapper: { justifyContent: "flex-end", display: "flex", flex: 1 },
	//NavBarItem: { margin: "8px 0 0 16px", textTransform: "none", fontSize: "0.75rem", opacity: 0.6, letterSpacing: 0.4, fontWeight: "normal" },
	NavBarItem: { margin: "0 0 0 16px", textTransform: "none", fontSize: "1rem", color: "#FFFFFF", opacity: 1, letterSpacing: 0.4, fontWeight: "normal" },
	NavBarItemSettings: { margin: 0 },
	NavBarItemLink: { "&:hover": { textDecoration: "none" } },
	//NavBarSearchIcon: { paddingRight: 0, opacity: 0.54, marginTop: 0, paddingLeft: 3, marginRight: 1, "&:hover": { backgroundColor: "transparent" } },
	// for scaling on zoom
	Icons: { display: "inline-block", overflow: "hidden", width: 14 },
	// Navbar responsive logo and topmenu
	NavBarWraperResponsive: { display: "flex" },
	TopMenuTabsSearch: { flexGrow: 1, minHeight: 40 }, //search
	TabItemSearch: { minWidth: "60px !important", width: "60px !important"/*, maxHeight: "40px !important"*/ /*minHeight: 74.8*/ },
	TopMenuWrapperSearch: {
		right: "70px",
		position: "absolute",
		/*
		"&:hover": {
			background: "rgba(255, 255, 255, 0.2)",
		}
		*/
	}
})

let pressTimer = null
export const NavBar = CreateComponent(styles, false, true, ({ classes }) => {
	const historyRouteAdd = useHistoryRouteAdd()

	const downEvent = (e) => {
		e.preventDefault()
		e.stopPropagation()
		pressTimer = setTimeout(() => {
			//console.debug("elapsed %s", window.location.href)
			pressTimer = null
			historyRouteAdd(ROUTE_NAMES.Internals)
		}, 3000)
	}
	const upEvent = (e) => {
		e.preventDefault()
		e.stopPropagation()
		if (pressTimer !== null) historyRouteAdd(ROUTE_NAMES.LiveTv)
		clearTimeout(pressTimer)
	}
	return (
		// MTVW-754 trial
		<div /*style={{
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		}}*/>
			{(!rootStore.page.Player.settings.isFullScreen) && <AppBar enableColorOnDark className={classes.AppBar} /*style={{
				position: "relative", margin: "0 auto", width: "80vw"
			}}*/>
				<div id="navbarContainer">
					<Toolbar className={classes.ToolBar + " NavBarContainer"}>
						{/* CHECK_MERGE */}
						<div className={classes.NavBarWraperResponsive + " NavBarWraperResponsive"}>
							{/*<Link underline="hover" to="/">*/}
							<img src={logo} className={classes.NavBarLogo} alt="Quickline"
								onMouseDown={e => { downEvent(e) }}
								onMouseUp={e => { upEvent(e) }}
								onTouchStart={e => { downEvent(e) }}
								onTouchEnd={e => { upEvent(e) }} />
							{/*</Link>*/}
							<TopMenu />
						</div>
						<div className={classes.TopMenuWrapperSearch + " TopMenuWrapperSearchResponsive"}>
							<RouteTabs centered className={classes.TopMenuTabsSearch + " TopMenuTabsSearchResponsive"}
								routeCustomName="search_menu"
								classes={{ indicator: classes.indicator }}>
								<RouteTab /*sx={{ height: "40px !important", maxHeight: "40px !important" }}*/ className={classes.TabItemSearch + " TabItemSearchResponsive"}
									icon={<img src={searchIcon} alt="search" style={{ height: 30, width: 30 }}></img>}
									value="Search"
									data-test="search" />
							</RouteTabs>
							{/* NEVER-USED .. {rootStore.page.Recordings.isVisible && <RecordingsMenuIcons page={rootStore.page.Recordings} />} */}
						</div>

						<div className={classes.NavBarWrapper + " NavBarWrapperMenuResponsive"}>
							<ProfileMenu classNames={classes} />
						</div>
					</Toolbar>
				</div>
			</AppBar>}
		</div>
	)
})
