import { flow /*, observable, computed, action */ } from "mobx"
import { rootStore } from "store/RootStore"
import { TraxisCpeIdMixin } from "store/api/mixin/TraxisCpeIdMixin"
// MTVW-585: no retries
import { fetchWithRetry, NO_RETRIES, DEFAULT_TIMEOUT } from "./FetchWithRetry"
import { serviceUrls } from "store/qlapi/ServiceUrls"
import { list, object, serializable, deserialize } from "serializr"
import { getQlHeaders } from "store/qlapi/QlHeaders"
import { appFeatures } from "AppFeatures"

class StartLiveResponse {
	@serializable sessionId = null
}

class ReplayAd {
	@serializable adUrl = null
	@serializable adsViewId = null
	@serializable countdownDelay = null
	@serializable countdownDuration = null
}

class PausedAd {
	@serializable adUrl = null
	@serializable adsViewId = null
	@serializable closeButtonAfter = null
	@serializable delayDuration = null
}

class StartReplayResponse {
	@serializable sessionId = null
	// Unix timestamp (UTC) in ms. Position of the start of the show in the linear stream (A marker) in milliseconds
	@serializable contentStartPosition = null
	@serializable(object(ReplayAd)) replayAd = null
}

class StartRecordingResponse {
	@serializable sessionId = null
	// Unix timestamp (UTC) in ms. Position of the start of the show in the linear stream (A marker) in milliseconds
	@serializable contentStartPosition = null
	@serializable(object(ReplayAd)) replayAd = null
}

class FastForwardAd {
	@serializable adsViewId = null
	@serializable adUrl = null
	// Start of the channel promo in milliseconds in the alternative ad stream. The customer can skip it after the `promoCountdown` (7 seconds).
	// Not returned when promo doesn't exist.
	@serializable promoStart = null
	// Duration in milliseconds after which the customer can skip the channel promo in the alternative ad stream.
	// Not returned when promo doesn't exist.
	@serializable promoCountdown = null
	// Array of positions in the alternative stream in milliseconds of each advertisement start.
	// From this array the client can determine the numner of advertisements.
	@serializable(list()) adsStart = []
	// MTVW-496, MTVW-539
	// Array of positions in the alternative stream in milliseconds of each advertisement end.
	@serializable(list()) adsEnds = []
}
class VerifyJumpResponse {
	// Tells what action the player should perform:
	// * `allow` - Jump to the time code specified in `jumpTo`
	// * `deny` - Player is not allowed to jump to requested position.
	@serializable action = null
	// Position to where the client should jump to.
	@serializable jumpTo = null
	@serializable(object(FastForwardAd)) fastForwardAd = null
}
// Returns information for Event details page regarding Ad Skipping.
// Used for Recordings as well as for Replays.
class AdSkipInfoResponse {
	// Returns the info if the given channel supports ad skipping and if the user can skip them or not.
	// enum: [ allowed, notAllowed, unavailable ]
	@serializable adSkipping = null
	// example: Premium Ad Skip
	@serializable adButtonTitle = null
	// Your can skip ads in this program because you recorded this program before it aired.
	// You can upgrade your TV plan if you would like the option to always skip ads in selected channels.
	@serializable adButtonText = null
}

class SessionPauseResponse {
	@serializable(object(PausedAd)) pauseAd = null
}

class AdZone {
	// Unix timestamp (UTC) in ms in the linear stream (marker B)
	@serializable start = null
	// Unix timestamp (UTC) in ms in the linear stream (marker D)
	@serializable end = null
	// Unix timestamp (UTC) in ms in the linear stream. It marks the point from which the skip ad shall be shown.
	// For non-premium: marker C1, for premium: marker B"
	@serializable skipStart = null
}

class SessionAdZonesResponse {
	@serializable(list(object(AdZone))) adZones = []
	// Timestamp in ms. Returns the next stream position in which we estimate
	// that the next ad zone is going to be known.
	@serializable nextPositionToCheck = null
}


const CREDENTIALS = 'include'
const STD_HEADERS = {
	'Accept': 'application/json',
	// Would cause CORS in keepAlive
	//'pragma': 'no-cache', 'cache-control': 'no-cache'
}
export class AdsService extends TraxisCpeIdMixin {

	get ADS_TIMEOUT() {
		//console.debug("ADS_TIMEOUT is", appFeatures.persistedAdsTimeout)
		return appFeatures.persistedAdsTimeout
	}
	get xHeaders() {
		// make a copy of STD_HEADERS, otherwise STD_HEADERS would be changed!
		const headers = {}
		Object.assign(headers, STD_HEADERS)
		Object.assign(headers, getQlHeaders())
		if (rootStore?.sso?.browserStore?.adsMock) headers['X-Quickline-Ad-Mock'] = rootStore?.sso?.browserStore?.adsMock
		return headers
	}

	_logDuration(start, url, requestId, info) {
		const duration = Math.trunc(performance.now() - start)
		// return asap, defer analytics log slightly
		setTimeout(() => {
			console.debug(`${info} duration %s ms`, duration)
			rootStore.logService.analyticsAsync("msRequestDuration", duration, { requestId: requestId, url: url })
		}, 1)
	}

	_logAdStats(adType, hasAd, channelId, requestId) {
		rootStore.logService.analyticsAsync("gt12AdStats", null, { client: "web", adType: adType, hasAd: hasAd, channelId: channelId, requestId: requestId, environment: serviceUrls.environment })
	}

	version = flow(function* (retryCount = NO_RETRIES) {
		const config = {
			url: serviceUrls.adsUrl + "version",
			fetchOptions: {
				method: 'GET',
				headers: this.xHeaders,
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["AdsService version"],
			service: "AdsService",
			createException: true,
			displayError: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		return `${resultJson["build_tag"]} (${resultJson["build_date"]})`
	})

	versionUrl() {
		return [serviceUrls.adsUrl + "version", this.xHeaders]
	}

	startLiveAsync = flow(function* (channelId, epgEventStart, epgEventEnd, retryCount = NO_RETRIES) {
		const props = { channelId: channelId, epgEventStart: epgEventStart, epgEventEnd: epgEventEnd }
		const url = serviceUrls.adsUrl + "startLive"
		const config = {
			url: url,
			fetchOptions: {
				method: 'POST',
				headers: this.xHeaders,
				credentials: CREDENTIALS,
				body: JSON.stringify(props),
			},
			retryCount: retryCount,
			timeout: this.ADS_TIMEOUT,
			codes: ["AdsService startLiveAsync"],
			service: "AdsService",
			createException: true,
			displayError: true
		}

		const now = performance.now()
		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		this._logDuration(now, url, requestId, "startLive")

		return deserialize(StartLiveResponse, resultJson, (err, result) => {
		})
	})

	startReplayAsync = flow(function* (channelId, epgEventStart, epgEventEnd, bookmarkPosition, retryCount = NO_RETRIES) {
		const props = { channelId: channelId, epgEventStart: epgEventStart, epgEventEnd: epgEventEnd /*, bookmarkPosition: bookmarkPosition*/ }
		const url = serviceUrls.adsUrl + "startReplay"
		const config = {
			url: url,
			fetchOptions: {
				method: 'POST',
				headers: this.xHeaders,
				credentials: CREDENTIALS,
				body: JSON.stringify(props),
			},
			retryCount: retryCount,
			timeout: this.ADS_TIMEOUT,
			codes: ["AdsService startReplayAsync"],
			service: "AdsService",
			createException: true,
			displayError: true
		}

		const now = performance.now()
		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		this._logDuration(now, url, requestId, "startReplay")

		//console.debug("StartReplayResponse resultJson %o", resultJson)
		const result = deserialize(StartReplayResponse, resultJson, (err, result) => {
		})
		this._logAdStats("Replay-Ad", result?.replayAd !== null, channelId, requestId)
		return result
	})

	// replayWindow: stream_lifetime from media MS converted to ms
	// moment.duration(stream_lifetime, moment.ISO_8601).valueOf()
	startRecordingAsync = flow(function* (channelId, recordingId, epgEventStart, epgEventEnd, isSeriesRecording, replayWindow, bookmarkPosition, bookingTime, retryCount = NO_RETRIES) {
		// we don't support the boockmarkPosition
		const props = { channelId: channelId, recordingId: recordingId, epgEventStart: epgEventStart, epgEventEnd: epgEventEnd, isSeriesRecording: isSeriesRecording, replayWindow: replayWindow, /*bookmarkPosition: bookmarkPosition,*/ bookingTime: bookingTime }
		const url = serviceUrls.adsUrl + "startRecording"
		const config = {
			url: url,
			fetchOptions: {
				method: 'POST',
				headers: this.xHeaders,
				credentials: CREDENTIALS,
				body: JSON.stringify(props),
			},
			retryCount: retryCount,
			timeout: this.ADS_TIMEOUT,
			codes: ["AdsService startRecordingAsync"],
			service: "AdsService",
			createException: true,
			displayError: true
		}

		const now = performance.now()
		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		this._logDuration(now, url, requestId, "startRecording")

		//console.debug("StartRecordingResponse resultJson %o", resultJson)
		const result = deserialize(StartRecordingResponse, resultJson, (err, result) => {
		})
		this._logAdStats("Replay-Ad", result?.replayAd !== null, channelId, requestId)
		return result
	})

	sessionPauseAsync = flow(function* (sessionId, channelId, epgEventStart, playbackPosition, retryCount = NO_RETRIES) {
		const url = serviceUrls.adsUrl + `sessions/${sessionId}/pause?channelId=${channelId}&epgEventStart=${epgEventStart}&playbackPosition=${Math.trunc(playbackPosition)}`
		const config = {
			url: url,
			fetchOptions: {
				method: 'GET',
				headers: this.xHeaders,
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: this.ADS_TIMEOUT,
			codes: ["AdsService sessionPauseAsync"],
			service: "AdsService",
			createException: true,
			displayError: true
		}

		const now = performance.now()
		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		this._logDuration(now, url, requestId, "sessionPause")

		//console.debug("sessionPauseAsync resultJson %o", resultJson)
		const result = deserialize(SessionPauseResponse, resultJson, (err, result) => {
		})
		this._logAdStats("Pause-Ad", result?.pauseAd?.adUrl !== undefined, channelId, requestId)
		return result
	})

	sessionAdZonesAsync = flow(function* (sessionId, epgEventStart, epgEventEnd, playbackPosition, retryCount = NO_RETRIES) {
		const url = serviceUrls.adsUrl + `sessions/${sessionId}/adZones?epgEventStart=${epgEventStart}&epgEventEnd=${epgEventEnd}&playbackPosition=${Math.trunc(playbackPosition)}`
		const config = {
			url: url,
			fetchOptions: {
				method: 'GET',
				headers: this.xHeaders,
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: this.ADS_TIMEOUT,
			codes: ["AdsService sessionAdZonesAsync"],
			service: "AdsService",
			createException: true,
			displayError: true
		}

		const now = performance.now()
		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		this._logDuration(now, url, requestId, "sessionAdZones")

		//console.debug("sessionAdZonesAsync resultJson %o", resultJson)
		return deserialize(SessionAdZonesResponse, resultJson, (err, result) => {
		})
	})

	sessionVerifyJumpAsync = flow(function* (sessionId, channelId, epgEventStart, epgEventEnd, from, to, inAdZone, retryCount = NO_RETRIES) {
		const props = { epgEventStart: epgEventStart, epgEventEnd: epgEventEnd, from: Math.trunc(from), to: Math.trunc(to) }
		const url = serviceUrls.adsUrl + `sessions/${sessionId}/verifyJump`
		const config = {
			url: url,
			fetchOptions: {
				method: 'POST',
				headers: this.xHeaders,
				credentials: CREDENTIALS,
				body: JSON.stringify(props),
			},
			retryCount: retryCount,
			timeout: this.ADS_TIMEOUT,
			codes: ["AdsService sessionVerifyJumpAsync"],
			service: "AdsService",
			createException: true,
			displayError: true
		}
		const now = performance.now()
		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		this._logDuration(now, url, requestId, "verifyJump")

		//console.debug("sessionVerifyJumpAsync resultJson %o", resultJson)
		const result = deserialize(VerifyJumpResponse, resultJson, (err, result) => {
		})
		if (inAdZone) this._logAdStats("FFwd-Ad", result.fastForwardAd?.adUrl !== undefined, channelId, requestId)
		return result
	})

	sessionNotifyJumpAsync = flow(function* (sessionId, epgEventStart, epgEventEnd, from, to, retryCount = NO_RETRIES) {
		const props = { epgEventStart: epgEventStart, epgEventEnd: epgEventEnd, from: Math.trunc(from), to: Math.trunc(to) }
		const url = serviceUrls.adsUrl + `sessions/${sessionId}/notifyJump`
		const config = {
			url: url,
			fetchOptions: {
				method: 'POST',
				headers: this.xHeaders,
				credentials: CREDENTIALS,
				body: JSON.stringify(props),
			},
			retryCount: retryCount,
			timeout: this.ADS_TIMEOUT,
			codes: ["AdsService sessionNotifyJumpAsync"],
			service: "AdsService",
			createException: true,
			displayError: true
		}

		const now = performance.now()
		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		this._logDuration(now, url, requestId, "notifyJump")

		//console.debug("sessionNotifyJumpAsync resultJson %o", resultJson)
		// no content
		//return deserialize(sessionNotifyJump, resultJson, (err, result) => {
		//})
	})

	sessionTrackingPauseAdAsync = flow(function* (sessionId, adsViewId, displayDuration, closeInteraction, err, retryCount = NO_RETRIES) {
		const props = { adsViewId: adsViewId, displayDuration: displayDuration, closeInteraction: closeInteraction, error: err }
		const url = serviceUrls.adsUrl + `sessions/${sessionId}/tracking/pauseAd`
		const config = {
			url: url,
			fetchOptions: {
				method: 'POST',
				headers: this.xHeaders,
				credentials: CREDENTIALS,
				body: JSON.stringify(props),
			},
			retryCount: retryCount,
			timeout: this.ADS_TIMEOUT,
			codes: ["AdsService sessionTrackingPauseAdAsync"],
			service: "AdsService",
			createException: true,
			displayError: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		//console.debug("sessionTrackingPauseAdAsync resultJson %o", resultJson)
		// no content
		//return deserialize(sessionTrackingPauseAd, resultJson, (err, result) => {
		//})
	})

	sessionTrackingReplayAdAsync = flow(function* (sessionId, adsViewId, playbackTime, closeInteraction, err, retryCount = NO_RETRIES) {
		const props = { adsViewId: adsViewId, playbackTime: playbackTime, closeInteraction: closeInteraction, error: err }
		const url = serviceUrls.adsUrl + `sessions/${sessionId}/tracking/replayAd`
		const config = {
			url: url,
			fetchOptions: {
				method: 'POST',
				headers: this.xHeaders,
				credentials: CREDENTIALS,
				body: JSON.stringify(props),
			},
			retryCount: retryCount,
			timeout: this.ADS_TIMEOUT,
			codes: ["AdsService sessionTrackingReplayAdAsync"],
			service: "AdsService",
			createException: true,
			displayError: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		//console.debug("sessionTrackingReplayAdAsync resultJson %o", resultJson)
		// no content
		//return deserialize(sessionTrackingReplayAd, resultJson, (err, result) => {
		//})
	})

	sessionTrackingFastForwardAdAsync = flow(function* (sessionId, adsViewId, playbackTime, closeInteraction, err, retryCount = NO_RETRIES) {
		const props = { adsViewId: adsViewId, playbackTime: playbackTime, closeInteraction: closeInteraction, error: err }
		const url = serviceUrls.adsUrl + `sessions/${sessionId}/tracking/fastForwardAd`
		const config = {
			url: url,
			fetchOptions: {
				method: 'POST',
				headers: this.xHeaders,
				credentials: CREDENTIALS,
				body: JSON.stringify(props),
			},
			retryCount: retryCount,
			timeout: this.ADS_TIMEOUT,
			codes: ["AdsService sessionTrackingReplayAdAsync"],
			service: "AdsService",
			createException: true,
			displayError: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		//console.debug("sessionTrackingFastForwardAdAsync resultJson %o", resultJson)
		// no content
		//return deserialize(sessionTrackingFastForwardAd, resultJson, (err, result) => {
		//})
	})

	// replayWindow: maxReplayDuration from channels MS converted to ms
	// moment.duration(maxReplayDuration, moment.ISO_8601).valueOf()
	adSkipInfoAsync = flow(function* (channelId, recordingId, epgEventStart, isSeriesRecording, bookingTime, replayWindow, isBookmark, retryCount = NO_RETRIES) {
		//console.debug("RECORDING ID %o", recordingId)
		let url = serviceUrls.adsUrl + `adSkipInfo?channelId=${channelId}&epgEventStart=${epgEventStart}&replayWindow=${replayWindow}&isBookmark=${isBookmark}`
		if (recordingId) url += `&recordingId=${recordingId}&isSeriesRecording=${isSeriesRecording}&bookingTime=${bookingTime}`
		const config = {
			url: url,
			fetchOptions: {
				method: 'GET',
				headers: this.xHeaders,
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: this.ADS_TIMEOUT,
			codes: ["AdsService adSkipInfoAsync"],
			service: "AdsService",
			createException: true,
			displayError: true
		}

		const now = performance.now()
		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		this._logDuration(now, url, requestId, "adSkipInfo")

		//console.debug("adSkipInfoAsync resultJson %o", resultJson)
		return deserialize(AdSkipInfoResponse, resultJson, (err, result) => {
		})
	})

	configurePauseMockAsync = flow(function* (enable = false, retryCount = NO_RETRIES) {
		const param = enable ? "enable" : "disable"
		const url = serviceUrls.adsUrl + `configureMock?pauseMock=${param}`
		const config = {
			url: url,
			fetchOptions: {
				method: 'POST',
				headers: this.xHeaders,
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: this.ADS_TIMEOUT,
			codes: ["AdsService adSkipInfoAsync"],
			service: "AdsService",
			createException: true,
			displayError: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		//console.debug("configurePauseMockAsync resultJson %o", resultJson)
		//return deserialize(configurePauseMock, resultJson, (err, result) => {
	})
}
