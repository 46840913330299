const config = {
	apiKey: "77bc80f160fb14a0d4d37033d5b729bf",
	apiBaseUrl: "https://api.themoviedb.org/3",
	apiPersonSearchUrl: "https://api.themoviedb.org/3/search/person",
	apiPersonImageUrl: "https://api.themoviedb.org/3/person",
	//imageBaseUrl: "https://image.tmdb.org/t/p/w1280"
	imageBaseUrl: "https://image.tmdb.org/t/p/w300"
}

export async function getPersonPicture(person, isAdult = false) {
	person = person.toLowerCase()
	try {
		//console.debug("fetching=", person)
		let response = await fetch(`${config.apiPersonSearchUrl}?api_key=${config.apiKey}&query=${person}&include_adult=${isAdult}`)
		if (response.ok) {
			let responseData = await response.json()
			let data = responseData?.results
			let id = null
			let profilePath = null
			for (var i = 0; i < data.length; i++) {
				if (data[i].name.toLowerCase() === person && data[i].profile_path) {
					id = data[i].id
					profilePath = data[i].profile_path
					//console.debug("getPersonPicture 1", response, id, data[i])
					break
				}
			}
			/*
			if (id) {
				response = await fetch(`${config.apiPersonImageUrl}/${id}/images?api_key=${config.apiKey}`)
				if (response.ok) {
					responseData = await response.json()
					//console.debug("getPersonPicture 2", responseData.profiles[0].file_path, `${config.imageBaseUrl}${responseData.profiles[0].file_path}?api_key=${config.apiKey}`)
					return `${config.imageBaseUrl}${responseData.profiles[0].file_path}?api_key=${config.apiKey}`
				}
			}
			*/
			if (profilePath) return `${config.imageBaseUrl}${profilePath}?api_key=${config.apiKey}`
		}
	} catch (error) {
		return null
	}
	return null
}

/*
async function getPopularMovies(page = 1) {
	let data = []
	try {
		const response = await fetch(`${ BASE_URL }movie / popular ? api_key = ${ API_KEY } & page=${ page }`)
		const responseData = await response.json()
		data = responseData?.results
	} catch (error) {

	}
	return data
}
*/
