import { types as t } from "mobx-state-tree"
import { TraxisAbstract } from "store/api/TraxisAbstract"

const DeleteChannelsFromRecordingOptInChannelListModel = t.model({})

export class DeleteChannelsFromRecordingOptInChannelList extends TraxisAbstract {
	//_onStartCreate = true
	//_onStartRemove = true

	get _model() {
		return DeleteChannelsFromRecordingOptInChannelListModel
	}

	_getParseResp(data) {
		return data
	}

	//_getHashReq(data) {
	//	return ``
	//}

	/*
	get tokenId() {
		return this._oVars.tokenId
	}
	*/

	getXmlReq() {
		return `<?xml version="1.0" encoding="utf-8"?>
		<Request xmlns="urn:eventis:traxisweb:1.0"> 
	<Identity> 
		<ProfileId>${this.profileId}</ProfileId> 
	</Identity> 
	<RootActionQuery actionName="DeleteAllChannelsFromRecordingOptInChannelList"> 
	</RootActionQuery> 
</Request>
`
	}
}
