const TraxisConditionClass = class {
	t = []
	state = true
	join = "&&"
	prefix = ""
	suffix = ""

	constructor(elem = null, join = "&&") {
		this.join = join
		this.add(elem)
	}

	add(elem, bAdd = null) {
		if (bAdd === false || elem === null || elem === void 0 || elem === "") {
			return this
		}
		if (Array.isArray(elem)) {
			this.t = this.t.concat(elem)
		} else {
			this.t.push(elem)
		}
		return this
	}

	getClone() {
		const obj = new TraxisConditionClass()
		obj.join = this.join
		obj.prefix = this.prefix
		obj.suffix = this.suffix
		return obj
	}

	setPrefix(value) {
		this.prefix = value
		return this
	}

	setSuffix(value) {
		this.suffix = value
		return this
	}

	setState(bState) {
		this.state = bState
		return this
	}

	setAsFilterOption() {
		return this.setPrefix('<Option type="Filter"><![CDATA[').setSuffix("]]></Option>")
	}

	toString() {
		const t = this.t.map(i => String(i)).filter(i => i !== "")
		if (!t?.length || !this.state) {
			return ""
		}
		if (t?.length === 1) {
			return this.prefix + t.join(this.join) + this.suffix
		}
		return this.prefix + "(" + t.join(this.join) + ")" + this.suffix
	}
}

export const TraxisCondition = (...args) => new TraxisConditionClass(...args)

export const TraxisConditionOr = elem => new TraxisConditionClass(elem, "||")

export const TraxisConditionAnd = elem => new TraxisConditionClass(elem, "&&")
