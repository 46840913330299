import { types as t } from "mobx-state-tree"
import { TraxisAbstract } from "store/api/TraxisAbstract"

const DeleteChannelListModel = t.model({})

export class DeleteChannelList extends TraxisAbstract {
	get _model() {
		return DeleteChannelListModel
	}

	_getParseResp(data) {
		return data
	}

	//_getHashReq(data) {
	//	return ``
	//}

	getXmlReq() {
		return `<?xml version="1.0" encoding="utf-8"?>
<Request xmlns="urn:eventis:traxisweb:1.0">
    <Identity>
        <ProfileId>${this.profileId}</ProfileId>
    </Identity>
    <RootActionQuery actionName="DeleteChannelList">
        <Arguments>
            <Argument name="Id">${this._oVars.channelListId}</Argument>
        </Arguments>
    </RootActionQuery>
</Request>`
	}
}
