import { types as t } from "mobx-state-tree"

export const SoftLink = t
	.model("SoftLink", {
		type: t.string,
		qualifier: t.string,
		id: t.identifier,
		idType: t.string,
		isReady: t.maybeNull(t.boolean)
	})
	// added for mobx 6.3 which is more strict
	.actions(self => ({
		setReady(value) {
			self.isReady = value
		}
	}))

export const SoftLinks = t
	.model("SoftLinks", {
		SoftLink: t.array(SoftLink),
		isReady: t.maybeNull(t.boolean)
	})
	.views(self => ({
		getByQualifier(callbackFn) {
			return self.SoftLink.find(i => callbackFn(i.qualifier) === true)
		}
	}))
	// added for mobx 6.3 which is more strict
	.actions(self => ({
		setReady(value) {
			//self.SoftLink.isReady = value
			self.isReady = value
		}
	}))
