/* eslint-disable no-restricted-globals */
import { Dialog } from "@mui/material"
import Slide from "@mui/material/Slide"
import { CreateComponent } from "components/utils/CreateComponent"
import React, { useEffect, useLayoutEffect, useState } from "react"
import { rootStore } from "store/RootStore"
import { EventEmitter } from "components/utils/EventEmitter"
import { CALLING_CONTEXT_TYPE } from "store/page/OverlayEventDetails"
import { isMobileScreenSize } from "utils/Utils"
import { useHistory, useLocation } from "react-router-dom";
import { EventComponent } from "scenes/Overlay/components/EventComponent"
import { SeriesComponent } from "scenes/Search/components/SeriesComponent"
import { PeopleGenresComponent } from "scenes/Search/components/PeopleGenresComponent"
import { ErrorMessage } from "components/ErrorMessage"
import { timeNow } from "utils/Utils"
import "./OverlayEventDetails.css"

const styles = theme => ({

	container: {
		display: "flex",
		justifyContent: "center",
		alignItems: "flex-Start",
	},
	containerWithErrorEvent: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

	modalPortraitMode: {
		maxWidth: "100%",
		margin: "auto",
	},

	modalLandscapeMode: {
		maxWidth: "70%",
		margin: "auto",
	},

	overlayDialogPaper: {
		overflowX: "hidden",
		borderRadius: "16px",
		boxShadow: "0 2px 50px 0 rgba(0, 0, 0, 0.5)"
	},

	// not really needed, just for dialog scroll trial (which does not work)
	overlayDialog: {
		overflowY: "scroll",
		//scrollbarWidth: "none",
		marginTop: 0,
		"&::-webkit-scrollbar": { display: "none" },
		//paddingBottom: 3
	},
})


const Transition = React.forwardRef((props, ref) => <Slide direction="up" {...props} />)

const OverlayEventDetailsComponent = ({ page, activityBackItem = null }) => {
	let _overlayEventDetailsComponent = null
	// MTVW-624: combined activityStack for related content and search
	console.debug("activityBackItem", activityBackItem)
	switch (activityBackItem?.type) {
		case 'series':
			page.setComponentType(activityBackItem?.type).setIdSerie(activityBackItem?.idSerie, activityBackItem?.subItem).setShow(true, CALLING_CONTEXT_TYPE.Search)
			page.setActivityBackItem(null)
			return
		case 'actors':
			page.setComponentType(activityBackItem?.type).setActorName(activityBackItem?.actor, activityBackItem?.subItem).setShow(true, CALLING_CONTEXT_TYPE.Search)
			page.setActivityBackItem(null)
			return
		case 'genres':
			page.setComponentType(activityBackItem?.type).setGenre(activityBackItem?.genre, activityBackItem?.subItem).setShow(true, CALLING_CONTEXT_TYPE.Search)
			page.setActivityBackItem(null)
			return
		default:
			break
	}
	if (page.getComponentType === 'event') {
		_overlayEventDetailsComponent = <EventComponent page={page} />
	} else if (page.getComponentType === 'series') {
		//console.debug("getComponentType series", page.getIdSeries)
		_overlayEventDetailsComponent = <SeriesComponent idSerie={page.getIdSeries
		} />
	} else if (page.getComponentType === 'actors') {
		_overlayEventDetailsComponent = <PeopleGenresComponent type="actors" actor={page.getActorName} genre={null} subItem={page.getSubItem} />
	} else if (page.getComponentType === 'genres') {
		_overlayEventDetailsComponent = <PeopleGenresComponent type="genres" genre={page.getGenre} actor={null} subItem={page.getSubItem} />
	}
	return (<>{_overlayEventDetailsComponent}</>)
}

const OverlayEventDetailsBody = CreateComponent(styles, false, true, ({ classes, page }) => {
	const [orientationClass, setOrientationClass] = useState(classes.modalPortraitMode)
	let history = useHistory();
	let location = useLocation();

	const closeModalForBack = () => {
		history.push(location.pathname)
		page.setIsPlayerPresent(false)
		page.setShow(false)
		// MTVW-624: combined activityStack for related content and search
		page.resetActivity();
	}

	function checkOrientationOnMobile(event = null) {
		if (event !== null) {
			event.stopPropagation();
			event.preventDefault();
		}
		setTimeout(() => { // need delay for updating window
			const switchToLandscape = window.innerWidth > window.innerHeight

			//if (window.innerWidth <= 812) {
			if (isMobileScreenSize()) {
				if (switchToLandscape) {
					setOrientationClass(classes.modalLandscapeMode)
				} else {
					setOrientationClass(classes.modalPortraitMode)
				}
			} else {
				setOrientationClass(classes.modalPortraitMode)
			}
		}, 100)
	}
	useEffect((props) => {
		if (page.isShow) {
			window.addEventListener("orientationchange", checkOrientationOnMobile)
			window.addEventListener("popstate", closeModalForBack)
			checkOrientationOnMobile() //MTV-2537 call every time the modal is shown to adjust the size of the window 
		}
		// MTVW-708
		else page.setPlayEventStarted(false)
		return (() => {
			window.removeEventListener("orientationchange", checkOrientationOnMobile)
			window.removeEventListener("popstate", closeModalForBack)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page.isShow, page.componentType])

	useEffect(() => {
		EventEmitter.subscribe("closeOverlayDialog", (event) => {
			page.setIsPlayerPresent(false)
			page.setShow(false)
			// MTVW-624: combined activityStack for related content and search
			page.resetActivity()
		})
	}, [page])

	useEffect(() => {
		if (rootStore.page.Player?.source /*&& rootStore.page.Player?.source?.Event*/ && rootStore.page.Player?.source?.Event) {
			console.debug("%s, EVENT CHANGED %o", timeNow(), rootStore.page.Player?.source)
			page.getAdSkipInfo()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rootStore.page.Player?.source, rootStore.page.Player?.source?.Event/*, rootStore.page.Player?.source?.sessionAdZones*/])

	useLayoutEffect(() => {
		let dialog = document.getElementById("overlay-dialog")
		if (!dialog) {
			dialog = document.getElementById("overlay-dialog-1")
		}
		if (dialog) {
			dialog.focus()
		}
	})

	if (page.callingContext === CALLING_CONTEXT_TYPE.Recording || page.callingContext === CALLING_CONTEXT_TYPE.RecordingRelatedContent) {
		// MTVW-624: combined activityStack for related content and search
		const callingContext = page.activityStack.length > 0 ? CALLING_CONTEXT_TYPE.RecordingRelatedContent : CALLING_CONTEXT_TYPE.Recording
		page.setCallingContext(callingContext)
	}
	const errorOrigin = page.oEvent?.error ? page.oEvent?.error : rootStore.page?.Player?.error
	const pageForErrorMessage = page.oEvent?.error ? page.oEvent : rootStore.page?.Player
	const isPlayerError = rootStore.page?.Player?.error !== null

	let dialogInstance = null

	return (
		<Dialog
			open={page.isShow}
			data-test="livetv_channel_summary_overlay"
			// MTVW-727b: disable backdropClick, close PiP on exit
			onClose={(event, reason) => {
				console.debug("closing modal")
				if (reason == "backdropClick") {
					page.setShow(true)
				}
				else {
					rootStore.page?.Player.player.showPiP(false).then(() => {
						page.setIsPlayerPresent(false)
						page.setShow(false)
						// MTVW-624: combined activityStack for related content and search
						page.resetActivity()
					}).catch((err) => console.error("CAUGHT in Dialog close"))
				}
			}}
			TransitionComponent={Transition}
			keepMounted
			id="overlay-dialog"
			tabIndex="-1" // make it focusable for keyboard ESC
			transitionDuration={200}
			// MTVW-810: TODO recheck error case
			//maxWidth={page.oEvent?.error ? "xs" : "md"} // MTVW-186 need xs in case of event error
			sx={{
				"& .MuiDialog-container": {
					"& .MuiPaper-root": {
						width: "100%",
						maxWidth: "1122px",  // our width
					},
				},
				"& .MuiDialog-paper": {
					borderRadius: "16px",
				},
				"& .MuiModal-backdrop": {
					backgroundColor: 'rgba(48,48,48,0.8)',
				},
			}}
			// deprecated, moved to sx above
			//BackdropProps={{ style: { backgroundColor: "rgba(48,48,48,0.8)" } }}
			PaperProps={{ sx: { backgroundColor: "#1A1A1A" } }}
			scroll="body"
			//disablePortal={true}
			//placement="top"
			className="overlayDialog"
			ref={dialog => { dialogInstance = dialog; /*console.debug("dialogInstance is", dialogInstance)*/ }}
			target="#overlay-dialog"
			classes={{
				container: page.oEvent?.error ? classes.containerWithErrorEvent : classes.container,
				scrollBody: orientationClass,
				paper: classes.overlayDialogPaper
			}}
			style={{ outline: "none" }} // no Blue border 
		>
			<div className="ErrorPosition" >
				{(!page.isPlayerPresent || page.isVisible) && (
					<ErrorMessage error={errorOrigin} isPlayerError={isPlayerError} classes={{ ErrorInfoWrapper: "ErrorInfoWrapper" }} page={pageForErrorMessage} id={"overlay"} />
				)}
			</div>
			{/* MTVW-637: !errorOrigin would close the dialog and the player
			{!errorOrigin && <OverlayEventDetailsComponent page={page} activityBackItem={page.activityBackItem} />}
			 */}
			<OverlayEventDetailsComponent page={page} activityBackItem={page.activityBackItem} />
		</Dialog>
	)
})

// MTV-2940 called from overlay not TvLive
export const OverlayEventDetails = CreateComponent(null, false, false, () => {
	const page = rootStore.page.OverlayEventDetails
	useEffect(...page.getMountBody())
	return <OverlayEventDetailsBody page={page} />

})
