/**
 * @Author: Kamil Michalak(k.michalak@kstudio.pl)
 * @Create Time: 2019 - 10
	* @Modified by: Kamil Michalak(k.michalak@kstudio.pl)
 * @Modified time: 2019 - 11
**/

import { Button, CardMedia, CircularProgress, Radio, Slider, ClickAwayListener, Popper, Typography /*, Tooltip*/ } from "@mui/material"
import IconButton from "@mui/material/IconButton"
//import { withStyles } from '@mui/styles';
import { alpha } from "@mui/material/styles"
import FullscreenEnter from "img/player/fullscreenEnter.svg"
import FullscreenExit from "img/player/fullscreenExit.svg"
import VolumeOffBrowser from "@mui/icons-material/VolumeOff"
import VolumeOn from "img/player/volumeOn.svg"
import VolumeOff from "img/player/volumeOff.svg"
import Subtitles from "img/player/subtitles.svg"
import CloseIcon from '@mui/icons-material/Close';
import classnames from "classnames"
import { Img } from "components/Img"
import { CreateComponent } from "components/utils/CreateComponent"
import Backward10 from "img/player/backward10.svg"
import Forward10 from "img/player/forward10.svg"
import NotLive from "img/player/notLive.svg"
import LiveIndicator from "img/player/liveIndicator.svg"
import NotRecording from "img/player/notrecording.svg"
import RecordPreparing from "img/player/recordPreparing.svg"
import RecordComplete from "img/player/recordComplete.svg"
import Pause from "img/player/pause.svg"
import Play from "img/player/play_white.svg"
import PlayRed from "img/player/play.svg"
import PlayHover from "img/player/play-hover.svg"
import Restart from "img/player/restart.svg"
import { EventEmitter } from "components/utils/EventEmitter"
import React, { forwardRef, useEffect, useRef, useState, useLayoutEffect } from "react"
import { useRootStore, rootStore } from "store/RootStore"
import { fallbackImageCategory } from "store/util/fallbackImage"
// import stylesInputRange from "./playerScroll.module.css"
// import stylesVolumeInputRange from "./volumeScroll.module.css"
import { SourceTypeList } from "store/page/player/source/SourceTypeList"
import { RecordingsManager } from "scenes/Recordings/components/RecordingsManager"
import { RECORDING_OPTIONS } from "store/page/recordings/RecordingsManager"

import CloseButton from "img/player/close.svg"
import CloseButtonHover from "img/player/close-hover.svg"
import { isIpad, isPhoneSize, isIphone, isAndroid, screenInfo } from "utils/Utils"
import { observer } from "mobx-react-lite"
import { PauseAd } from "./components/PauseAd"
import { FFAdAnimatedIcon, LinearSkipAd } from "./components/FFAdAnimatedIcon"
import { AdCounter } from "./components/AdCounter"

import JumpB from "img/player/jumpB.svg"
import JumpC from "img/player/jumpC.svg"
import JumpD from "img/player/jumpD.svg"
import { appFeatures } from "../../AppFeatures"
// MTVW-727
import PictureInPicture from "img/player/PiP.svg"
//import "store/page/player/videojs/PlayerVjs.css"

// GT12-SVG
//import svgCoverImg from "img/PauseAd_McDonals_Spot_Test_AdLabeling.svg"

const ID_MAIN_CONTROLLER_CONTAINER = "mainControllerContainer"
const ID_PLAYER_CONTROLLER_RESPONSIVE = "PlayerControlsResponsive"

const styles = theme => ({
	PlayerContainer: { background: theme.palette.background.default },

	PlayerFullscreen: {
		position: "fixed",
		left: 0,
		top: 0,
		width: "100%",
		height: "100%",
		zIndex: 1200,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center"
	},

	PlayerLoadingWrapper: {
		width: "100%",
		height: "100%",
		position: "absolute",
		top: 0,
		left: 0,
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},

	PlayerMutedByBrowserWrapper: {
		width: "100%",
		height: "100%",
		cursor: "pointer",
		position: "absolute",
		opacity: 0.6,
		background: alpha(theme.palette.background.default, 0.8),
		top: 0,
		left: 0,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		zIndex: 2
	},

	PlayerMutedByBrowserIcon: {
		fontSize: "120px"
	},

	PlayerWrapper: { position: "relative" },

	Player: {},

	PlayerControls: {
		position: "absolute",
		left: 0,
		bottom: 0,
		width: "100%",
		opacity: 0,
		transition: "opacity 1s ease-in-out",
		display: "flex",
		zIndex: 3,
		"&:hover": { opacity: 1, transition: "opacity 1s ease-in -out" },
		// "& img": {
		// 	filter: `drop-shadow(2px 2px 2px ${alpha(theme.palette.secondary.main, 0.9)})`
		// }
	},

	// MTV-3617: fix missing spinner in full screen
	SpinnerContainer: {
		position: "absolute",
		left: 0,
		top: "30%",
		width: "100%",
		height: "30%",
		opacity: 1,
		//transition: "opacity 1s ease-in-out",
		//display: "flex",
		zIndex: 1,
	},

	PlayerControlsResponsive: {
		position: "absolute",
		left: 0,
		top: 0,
		width: "100%",
		height: "100%",
		zIndex: 3,
		opacity: 0,
		transition: "opacity 1s ease-in-out",
		display: "flex",
		"&:hover": { opacity: 1, transition: "opacity 1s ease-in -out" },
		// "& img": {
		// 	filter: `drop-shadow(2px 2px 2px ${alpha(theme.palette.secondary.main, 0.9)})`
		// },
		"& div[id=mainControllerContainer]": {
			width: "100%",
			justifyContent: "space-between",
		}
	},
	//MTVW-509
	// PlayerProgramInfo: {
	// 	position: "fixed",
	// 	left: 0,
	// 	bottom: "35px",
	// 	padding: "0 2%",
	// 	width: "100%",
	// 	opacity: 1,
	// 	transition: "opacity 1s ease-in-out",
	// 	background: alpha(theme.palette.background.default, 0.8),
	// 	"&:hover": { opacity: 1 },
	// 	"& p": {
	// 		textShadow: `1px 1px 1px ${alpha(theme.palette.secondary.main, 0.9)}`
	// 	}
	// },

	PlayerDefaultCover: {},

	PlayerCover: { position: "absolute", top: 0, opacity: 1, zIndex: 1, width: "100%", height: "100%" },

	PlayerBody: {
		position: "absolute",
		width: "100%",
		height: "100%",
		top: 0,
		left: 0,
		background: "black",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	adsPlayerBody: {
		position: "relative"
	},
	PlayerControlsShowControls: { opacity: 1 },

	PlayerControlsMain: {
		// fix safari blur
		// height: "0%"
		height: 52
	},

	PlayerControlsTopWrapper: {
		position: "absolute",
		top: 0,
		right: 0,
		display: "flex"
	},

	PlayerControlsMainWrapper: {
		background: `linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.01) 8.62%, rgba(0,0,0,0.04) 16.56%, rgba(0,0,0,0.09) 23.93%, rgba(0,0,0,0.16) 30.85%, rgba(0,0,0,0.23) 37.42%, rgba(0,0,0,0.31) 43.77%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0.49) 56.23%, rgba(0,0,0,0.57) 62.58%, rgba(0,0,0,0.64) 69.15%, rgba(0,0,0,0.71) 76.07%, rgba(0,0,0,0.76) 83.44%, rgba(0,0,0,0.79) 91.38%, rgba(0,0,0,0.8) 100%);`,
		display: "flex",
		flexFlow: "row wrap",
		height: "100%",
		width: "100%",
		padding: "0.2% 0.2% 0.2% 0.2%",
		paddingBottom: "20px",
		opacity: 1.0,
	},

	PlayerControlsMainWrapperFullScreen: {
		height: "70%",
		paddingBottom: "20px",
		margin: "auto" // MTVW-733 center controls horizontally (ultrawidescreen)
	},

	PlayerControlsMainWrapperFullScreenResponsive: {
		height: "70%",
		paddingBottom: 17 // MTVW-452 minimum for iPhone
	},

	PlayerControlsVolume: {
		display: "flex",
		flexDirection: "column-reverse",
		alignItems: "flex-end",
		position: "relative",
	},

	PlayerVolumeScrollIcon: {
		"flex": "0 0 auto",
		padding: "6px 4px",
		minWidth: "40px",
		height: 40,
		width: 40
	},
	PlayerVolumeScrollIconResponsive: {
		"flex": "0 0 auto",
		padding: "6px 8px",
		minWidth: "24px"
	},
	PlayerVolumeScrollBar: {
		height: 120,
		display: "flex",
		padding: "20px 8px 0px 0px",
		zIndex: 2,
		position: "relative",
		alignItems: "center",
		justifyContent: "center",
		width: 60,
		left: 10,
		top: -16,
	},

	PlayerControlsClose: {
		zIndex: 100,
		width: 48,
		height: 48,
		background: `url("${CloseButton}") no-repeat`,
		backgroundPosition: "center",
		backgroundSize: "32px 32px",
		"&:hover": {
			background: `url("${CloseButtonHover}") no-repeat`,
			backgroundPosition: "center",
			backgroundSize: "40px 40px",

		},
	},

	//PlayerControlsPlaySize: { height: 27 },
	PlayerControlsLive: {
		height: 40,
		filter: `drop-shadow(2px 2px 2px ${alpha(theme.palette.secondary.main, 0.9)}) `,
		"-webkit-user-select": "none !important",
		"-moz-user-select": "none !important",
		"user-select": "none !important"
	},

	// MTVW-509
	// PlayerControlsSmallSize: { height: 20 },

	PlayerControlsSmallSize: {},

	PlayerControlsDisabled: { opacity: 0.4, cursor: "default" },

	PlayerControlSubtitlesPopper: { zIndex: 10000 },

	PlayerControlSubtitlesMenu: {
		position: "relative",
		top: "100%",
		left: -16,
		display: "flex",
		height: 192,
		width: 420,
		opacity: 0.8,
		borderRadius: 8,
		background: "linear-gradient(180deg, #191919 0%, #0C0C0C 100%)"
	},

	PlayerControlSubtitlesMenuResponsive: {
		width: "100%",
		left: 0,
	},

	//MTVW-509
	// PlayerControlsInformation: {
	// 	height: 21,
	// 	//paddingLeft: 30 MTV-3394
	// },

	PlayerControlChooseAudio: {
		width: "47%",
	},

	PlayerControlChooseAudioTitle: {
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		color: "#FFFFFF",
		fontSize: 24,
		fontWeight: "bold",
		letterSpacing: 0,
		padding: "12px 16px",
	},

	PlayerControlChooseAudioTitleLeft: {
		borderRadius: "8px 0px 0px 0px",
	},

	PlayerControlChooseAudioTitleRight: {
		borderRadius: "0px 8px 0px 0px",
	},

	PlayerControlChooseSubtitles: { width: "53%" },

	PlayerControlSubtitles: {
		padding: "6px 4px",
		"min-width": 40,
		height: 40,
		width: 40,
	},

	PlayerControlSubtitlesResponsive: { padding: "6px 8px", "min-width": 24 },

	PlayerControlLanguageWrapper: {
		display: "flex",
		alignItems: "center",
		padding: "0 24px 0 10px"
	},

	//PlayerInfoButton: { fontWeight: "normal" },

	PlayerControlsRow1: {
		opacity: 1.0,
		width: "100%",
		flex: "1 1 100%",
		alignSelf: "flex-start",
		padding: "0.2% 0.2% 0.2% 0.2%",
		display: "flex",
		flexFlow: "row",
		alignItems: "center",
		justifyContent: "flex-end",
		paddingRight: 16
	},

	PlayerControlsRow1FullScreen: {},
	PlayerControlsRow1Responsive: {
		width: "100%",
		display: "flex",
		justifyContent: "flex-start",
		zIndex: 2,
		marginLeft: "8px"
	},
	PlayerControlsRow1TestButtons: {
		flex: "0 0 99%", // 59% for ~middle
		display: "flex",
		justifyContent: "flex-start",
		cursor: "pointer",
	},

	PlayerControlsRow2: {
		opacity: 1.0,
		width: "100%",
		display: "flex",
		flex: "1 1 100%",
		alignSelf: "center",
		paddingBottom: 12,
		//marginBottom: 12, // Test iPad MTVW-710 fullscreen
	},

	PlayerControlsRow2Fullscreen: {},

	PlayerControlsRow3: {
		display: "flex",
		width: "100%",
		alignSelf: "flex-end",
		justifyContent: "space-between",
		height: "40px"
	},

	ImscContainer: {
		position: "absolute",
		left: 0,
		top: "0%", //85%",
		width: "100%",
		height: "100%", //"15%",
		opacity: 1,
		justifyContent: "space-between",
		margin: "auto",
		textAlign: "center",
		/*
		fontSize: 16,
		fontFamily: "EuropaRegular",
		*/
		zIndex: 1,
	},

	PlayerProgressBarWrapper: {
		display: "flex",
		flex: "1 1 100%",
		justifyContent: "center",
		alignItems: "center",
		"& p": {
			textShadow: `1px 1px 1px ${alpha(theme.palette.secondary.main, 0.9)}`
		}
	},

	PlayerProgressBar: {
		display: "flex",
		flex: "1 1 90%",
		margin: "0 10px 0 8px",
		position: "relative",
		paddingTop: 10,
		height: "25px",
		maxWidth: "80%",
	},

	PlayerControlsRow3Left: {
		display: "flex",
	},

	PlayerControlsRow3TestButtons: {
		flex: "0 0 99%", // 59% for ~middle
		display: "flex",
		justifyContent: "flex-start",
		position: "relative",
		/*bottom: "60%"*/
		top: "-100px" /* avoid overlap with other controls, so that click works */
	},

	PlayerControlsRow3Animation: {
		display: "flex",
		flexFlow: "row",
		height: 40,
		width: 40,
		overflow: "hidden",
		alignSelf: "center",
		// paddingBottom: "2px",
		zIndex: 0
	},


	PlayerControlsRow3Center: {
		display: "flex",
		justifyContent: "flex-end"
	},

	PlayerControlsRow3CenterFullScreen: {
		//flex: "0 0 43%",
	},

	// video with >= 768px amd <= 1024px
	PlayerControlsRow3CenterFullScreenDense: {
		flex: "0 0 25%",
	},

	PlayerControlsRow3Right: {
		display: "flex",
		alignItems: "flex-end",
		justifyContent: "flex-end",
	},

	PlayerProgressBarTextAfter: {
		fontFamily: "EuropaBold",
		display: "flex",
		flex: "1 1 10%",
		alignItems: "center",
		padding: "9px 0px 0px 8px",
		"-webkit-user-select": "none !important",
		"-moz-user-select": "none !important",
		"user-select": "none !important"
	},

	PlayerProgressBarTextBefore: {
		fontFamily: "EuropaBold",
		display: "flex",
		flex: "1 1 10%",
		justifyContent: "flex-end",
		alignItems: "center",
		paddingTop: 10,
		paddingRight: 10,
		"-webkit-user-select": "none !important",
		"-moz-user-select": "none !important",
		"user-select": "none !important"
	},

	PlayerControlLiveIndicator: { display: "flex", alignItems: "center" },
	PlayerNotLiveIndicatorImg: { marginTop: -6, marginLeft: -8 },
	PlayerLiveIndicatorContainer: { minWidth: 78, display: "flex", justifyContent: "center", alignItems: "center" },
	PlayerProgressBarTextAfterFullScreen: {},

	PlayerControlsFullscreen: {
		// added to fix safari blur
		padding: "6px 4px",
		"min-width": 40,
		height: 40,
		width: 40,
	},

	PlayerProgressBarScroll: { width: "100%", position: "absolute", top: -7 },

	buttonCustomRoot: {
		minWidth: "40px !important",
		height: 40,
		width: 40,
		padding: "6px 4px"
	},

	muiButtonNoHoverEffect: {
		minWidth: "40px !important",
		height: 40,
		width: 40,
		padding: "6px 4px",
		//added to fix safari blur added
		transform: 'scale(0.8)',
		"&:hover": {
			backgroundColor: "transparent !important",
			// fix safari blur added
			// transform: 'scale(1.3)'
			transform: 'scale(1)'
		}
	},

	subtitleRadioRoot: {
		color: "white!important"
	},

	playerControlSliderThumb: {
		backgroundColor: "#ffffff !important",
		marginLeft: "-0px !important",
		"&:focus, &:hover, &.Mui-active": {
			boxShadow:
				`0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02) !important`
			// Reset on touch devices, it doesn't add specificity
		},
		"&:after": {
			top: -5,
			left: -5,
			right: -5,
			bottom: 0
		}
	},

	playerControlProgressBarSliderThumb: {
		height: 16,
		width: 16,
	},

	playerControlVolumeSliderRail: {
		width: "8px !important",
		borderRadius: 4,
		opacity: 0.5,
		backgroundColor: '#bfbfbf !important',
	},

	playerControlVolumeSliderTrack: {
		width: "7px !important",
		borderRadius: 6,
	},

	playerControlProgressBarSliderRail: {
		height: "8px !important",
		borderRadius: 6,
		opacity: 1,
		backgroundColor: '#999999 !important',
	},

	playerControlProgressBarSliderTrack: {
		height: "7px !important",
		borderRadius: 4,
	},

	playerControlProgressBarMark: {
		height: "8px !important",
		width: "4px !important",
		color: "#FFC600 !important",
		'&.MuiSlider-markActive': {
			opacity: 0,
		},
	},

	playerControlSliderLabel: {
		marginBottom: 4,
		height: 22,
		width: 60,
		//marginLeft: "-8px !important",
		borderRadius: "20px !important",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "#FFFFFF !important",
		fontWeight: "bold",
		"& > span": {
			backgroundColor: "#FFFFFF !important",
		},
		"& > span > span": {
			color: "black !important",
		},
	},

	"@keyframes fadeAnimation": {
		from: { opacity: 1 },
		to: { opacity: 0 }
	},
	PlayerControlEventFullScreenInfoContainer: {
		animation: "$fadeAnimation 15s ease-in-out",
		position: "fixed",
		top: 16,
		left: 16,
		opacity: 1,
	},
	PlayerControlEventFullScreenInfoContainerResponsive: {
		animation: "$fadeAnimation 15s ease-in-out",
		position: "fixed",
		top: 48,
		// left: 16,
		opacity: 1,
	},

	PlayerControlEventFullScreenInfoTitle: {
		// height: 37,
		width: "50vw",
		color: "#FFFFFF",
		fontSize: 24,
		fontWeight: "bold",
		letterSpacing: 0.43,
		lineHeight: 1.5,
	},

	PlayerControlEventFullScreenInfoSubTitle: {
		// height: 20,
		width: "50vw",
		color: "rgba(255, 255, 255, 0.7)",
		fontSize: 16,
		letterSpacing: 0.28,
		lineHeight: 1.3,
	},

	playerRecordStatusIndicator: {
		padding: "0px 4px 0px 0px",
		// minWidth: 64,
		height: 40,
		width: 40,
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},

	centralPlayResumeButton: {
		height: 96,
		width: 96,
		position: "absolute !important",
		left: "50%",
		top: "50%",
		transform: "translate(-50%, -50%) scale(1.0)",
		//WebkitTransform: "translate(-50%, -50%) scale(1.0)",
		//MozTransform: "translate(-50%, -50%) scale(1.0)",
		color: theme.palette.text.primary,
		cursor: "pointer",
		zIndex: 4,
		background: `url("${PlayRed}") no-repeat`,
		backgroundPosition: "center",
		backgroundSize: "96px 96px",
		//WebkitBackgroundSize": "96px 96px",
		// MTVW-701: scale with animation
		transition: "all 250ms ease-in-out !important",
		//WebkitTransition: "all 250ms ease-in-out !important",
		//MozTransition: "all 250ms ease-in-out !important",
		"&:hover": {
			/*background: `url("${PlayHover}") no-repeat`,*/
			backgroundPosition: "center",
			// transition: ".5s  ease-in-out",
			// WebkitTransition: ".5s  ease-in-out",
			/*
			backgroundSize: "96px 96px",
			"webkit-background-size": "96px 96px",
			*/
			// MTVW-701: scale with animation
			transform: "translate(-50%, -50%) scale(1.1)",
			//WebkitTransform: "translate(-50%, -50%) scale(1.1)",
			//MozTransform: "translate(-50%, -50%) scale(1.1)",
		},
	},

	centralPlayResumeButtonMobile: {
		height: 48,
		width: 48,
		position: "absolute !important",
		left: "50%",
		top: "50%",
		transform: "translate(-50%, -50%)",
		color: theme.palette.text.primary,
		cursor: "pointer",
		zIndex: 4,
		background: `url("${PlayRed}") no-repeat`,
		backgroundPosition: "center",
		backgroundSize: "48px 48px",
		"&:hover": {
			background: `url("${PlayHover}") no-repeat`,
			backgroundPosition: "center",
			backgroundSize: "48px 48px",
			"webkit-background-size": "48px 48px",
		},
	}
})
// const LightTooltip = withStyles(theme => ({
// 	tooltip: {
// 		backgroundColor: theme.palette.common.white,
// 		color: 'rgba(0, 0, 0, 0.87)',
// 		boxShadow: theme.shadows[1],
// 		fontSize: 11,
// 	},
// }))(Tooltip);

const getWindowDimensions = () => window.innerWidth

const visibilityHelper = (bState, style = {}) => {
	return !bState ? { visibility: "hidden", height: 0, width: 0, minWidth: 0, padding: 0 } : style
}

let controlsTimerId = null
export function controlsTimerHandler(page, disableTimeout = false) {
	//console.trace("controlsTimerHandler", disableTimeout)
	//console.debug(new Error().stack)
	// MTV-1811: prevent playing of non subscribed channel
	if (page?.error === null) {
		if (controlsTimerId !== null) {
			clearTimeout(controlsTimerId)
			controlsTimerId = null
		}
		page.setMouseOver(true)
		// MTV-2525: add condition for isMutedByBrowser
		if (!disableTimeout && (page.player.isMutedByBrowser === false)) {
			controlsTimerId = setTimeout(() => {
				page.setMouseOver(false)
				controlsTimerId = null
			}, rootStore.sso.browserStore.controlsVisibleDuration)
		}
	}
}

const ButtonCustom = CreateComponent(
	styles,
	false,
	true,
	forwardRef(({ children, isDisabled = false, isHidden = false, isStreamLoaded = null, player = true, hoverCss = true, classes, ...props }, ref) =>
		isDisabled || isStreamLoaded === false ? (
			<Button
				disabled={false}
				disableFocusRipple={true}
				classes={hoverCss ? { root: `${classes.muiButtonNoHoverEffect}` } : `${classes.buttonCustomRoot}`}
				disableRipple={true}
				className={classes.PlayerControlsDisabled}
				style={visibilityHelper(!isHidden)}
				{...props}
				ref={ref}
				onClick={() => void 0}
			>
				{children}
			</Button>
		) : (
			<Button
				style={visibilityHelper(!isHidden)} {...props}
				ref={ref} classes={hoverCss ? { root: `${classes.muiButtonNoHoverEffect}` } : `${classes.buttonCustomRoot}`}
				disableFocusRipple={true}
				disableRipple={true}

			>

				{children}
			</Button>
		)
	)
)

const ButtonJumpMarker = CreateComponent(styles, false, true, ({ classes, page, enabled, icon, dataTest, action, ...props }) => {
	return (
		<ButtonCustom
			isDisabled={false}
			isHidden={!enabled}
			onClick={(e) => {
				e.stopPropagation()
				e.preventDefault()
				action()
				controlsTimerHandler(page, true)
			}}
			data-test={dataTest}
		>
			<img src={icon} className={`${classes.PlayerControlsLive} ${classes.PlayerControlsMain} `}
				style={{ marginRight: -22, paddingTop: 2, height: "28px" }} alt="" />
		</ButtonCustom>
	)
})

const volumeCheatHelper = val => 0 + parseInt(val, 10)

const PlayerControlAudioSubtitlesMenuItem = CreateComponent(null, false, true, ({ classes, i, checkCircleClass, ...props }) => (
	<div className={classes.PlayerControlLanguageWrapper}>
		<Radio
			checked={i.isSelected}
			onClick={(e) => { e.stopPropagation() }}
			onChange={(e) => { e.stopPropagation(); if (i.isSelectable && !i.isSelected) i.setTrack && i.setTrack() }}
			value={i.labelText}
			label={i.labelText}
			name="radio-buttons"
			classes={{ root: `${classes.subtitleRadioRoot}` }}
			inputProps={{ 'aria-label': 'A' }}
		/> {i.labelText}
	</div>
))

const PlayerControlAudioSubtitlesMenu = CreateComponent(null, false, true,

	forwardRef(({ classes, page, player, setSubtitle, isInPlayerControlsResponsive, ...props }, ref) => (
		<div
			ref={ref}
			className={!isInPlayerControlsResponsive ? classes.PlayerControlSubtitlesMenu : `${classes.PlayerControlSubtitlesMenu} ${classes.PlayerControlSubtitlesMenuResponsive}`}
			onMouseMove={() => {
				controlsTimerHandler(page, true)
			}}
			onMouseLeave={() => {
				controlsTimerHandler(page)
			}}
		>
			<div className={classes.PlayerControlChooseAudio} data-test="player_track_container_audio">
				<div className={`${classes.PlayerControlChooseAudioTitle} ${classes.PlayerControlChooseAudioTitleLeft}`}>
					Audio
				</div>
				{/* {console.debug("AUDIO CONTAINER %o", player.audioTracksContainer.items)} */}

				{player.audioTracksContainer.items.map(i => (
					<PlayerControlAudioSubtitlesMenuItem classes={classes} i={i} key={i.labelText} />
				))}
				{/* MTVW-92: Handled in module PlayerTrackVo
			{!player.audioTracksContainer.items.length && (
				<PlayerControlAudioSubtitlesMenuItem
					classes={classes}
					i={{
						labelText: "Keine Information",
						isSelected: true
					}}
				/>
			)}
			*/}
			</div>
			<div className={classes.PlayerControlChooseSubtitles} data-test="player_track_container_subtitle">
				<div className={`${classes.PlayerControlChooseAudioTitle} ${classes.PlayerControlChooseAudioTitleRight}`}>Untertitel</div>
				{player.subtitleTracksContainer.items.map(i => (
					<PlayerControlAudioSubtitlesMenuItem classes={classes} i={i} key={i.labelText} />
				))}
			</div>

			{/* Add close icon for mobile audio subtile in progress */}
			<div className="closeIconAudioSubtilesResponsive" style={{ display: "none" }}>
				<Typography variant="caption">
					<Button sx={{ color: "#fff !important" }} onClick={(e) => { e.stopPropagation(); void setSubtitle(false) }}><CloseIcon /></Button>
				</Typography>
			</div>
		</div>
	)))

/*
MTVW-509
const PlayerProgramInfo = CreateComponent(null, false, true, ({ classes, page }) => {
	const oEventData = page.source.Event
	return (
		<>
			{page.player.isFullScreen && oEventData && (
				<div className={classes.PlayerProgramInfo}>
					<Typography variant="h4" paragraph={true} data-test="player_full_title">
						{oEventData.Title.Name}
					</Typography>
					<Typography variant="body2" className={classes.PlayerProgramInfoSubtitle} data-test="player_program_info_subtitle">
						//Staffel 12 , Episode 9 , Die russische Widerlegung | 22:55 - 23:25
						<EpgChannelDetailsToolsInfo oEvent={oEventData} /> | <EpgChannelDetailsToolsTitleSecondary oTitle={oEventData} suffix=" | " /> {oEventData.AvailabilityStartDateFormat("HH:mm")} -{" "}
						{oEventData.AvailabilityEndDateFormat("HH:mm")}
					</Typography>
					<RecordingIndicator operation="rec_series_episode_successful" showText={true} />
				</div>
			)}
		</>
	)
})
*/

const onChange = (progressBar, val, done, e) => {
	if (progressBar?.isVisible) {
		progressBar.setPosition(val, done)
	}
	// MTV-3474
	// used in this function generated a bug that the progressbar slider kept changing even if the mouse was not on top, the onchange event was not closed
	// e.preventDefault()
}

const getPlayerStyle = (visible) => {
	let style = null
	if (import.meta.env.VITE_PLAYER === "vjs") {
		style = { display: visible ? "block" : "none", position: "relative", width: "100%", } //height: "100%",
	}
	else if (import.meta.env.VITE_PLAYER === "vo") {
		style = { display: visible ? "block" : "none", position: "relative", }
	}
	return style
}

const PlayerProgressBarWrapper = CreateComponent(null, false, true, ({ classes, page, source, player }) => {

	// MTVW-444: display values for playerFg while playerBg loading
	if (page?.playerFg) {
		player = page.playerFg
		source = page.playerFg._source
	}

	// MTV-3474: Remove condition && player.isLoading
	if ((!player.isStreamLoaded || !source /*&& !page.playerFg*/)) {
		return null
	}
	const progressBar = source.progressBar

	return (
		<div className={classes.PlayerProgressBarWrapper + " PlayerProgressBarWrapperResponsive"} onClick={(e) => e.stopPropagation()}>
			<div className={classnames(classes.PlayerProgressBarTextBefore + " PlayerProgressBarTextAfterResponsive", { [classes.PlayerProgressBarTextAfterFullScreen]: player.isFullScreen })}
				data-test="player_overall_time">
				{(source.progressBar["textAfter"] === "0:00" ? "0:00" : source.progressBar["textBefore"])}
			</div>
			<div className={classnames(classes.PlayerProgressBar, { "PlayerProgressBarFullScreen": player.isFullScreen })}>
				<Slider
					className={`${classes.PlayerProgressBarScroll} `}
					{...progressBar.scrollProps}
					marks={[{ value: progressBar.progressValueLiveMarker }]}
					valueLabelFormat={progressBar["textBefore"]}
					valueLabelDisplay="auto"
					classes={{
						thumb: `${classes.playerControlSliderThumb} ${classes.playerControlProgressBarSliderThumb}`,
						rail: classes.playerControlProgressBarSliderRail,
						track: classes.playerControlProgressBarSliderTrack,
						mark: classes.playerControlProgressBarMark,
						valueLabel: classes.playerControlSliderLabel,
					}}
					onChange={(e, newValue) => {
						void onChange(progressBar, newValue, false, e)
						controlsTimerHandler(page, true)

					}}
					onChangeCommitted={(e, newValue) => {
						void onChange(progressBar, newValue, true, e)
						controlsTimerHandler(page)
					}}
				>
				</Slider>
			</div>
			<div className={classnames(classes.PlayerProgressBarTextAfter + " PlayerProgressBarTextAfterResponsive", { [classes.PlayerProgressBarTextAfterFullScreen]: player.isFullScreen })}
				data-test="player_overall_time">
				{source.progressBar["textAfter"]}
			</div>
		</div>
	)
})

const PlayerLiveIndicator = (props) => {
	const { source, classes, player } = props
	//MTVW-509 
	// {/* OLD Live indicator
	// 					<div className={classes.PlayerControlLiveIndicator} style={visibilityHelper((source.type === SourceTypeList.ChannelLive) && (!player.isPaused))} >
	// 						<img src={LiveIndicator} className={`${classes.PlayerControlsLive} ${classes.PlayerControlsMain} `} alt="" />
	// 					</div>
	// 					<ButtonCustom player={player}
	// 						style={visibilityHelper(source.isButtonLiveEnabled || player.isPaused)}
	// 						//style={{ visibility: source.isButtonLiveEnabled || player.isPaused ? "visible" : "hidden" }}
	// 						// MTV-3061 isHidden={!source.isButtonLiveEnabled}
	// 						isDisabled={!source.isButtonLiveEnabled && !player.isPaused} onClick={(e) => { e.stopPropagation(); void source.setJumpLive() }} data-test="player_live">
	// 						<LightTooltip title="Zurück auf Live">
	// 							<img src={NotLive} className={`${classes.PlayerControlsSmallSize} ${classes.PlayerControlsMain} `} alt="" />
	// 						</LightTooltip>
	// 					// </ButtonCustom> */}
	const _liveIndicator = (source.type === SourceTypeList.ChannelLive) && (!player.isPaused) ?
		<div className={classes.PlayerControlLiveIndicator}  >
			{/* fix safari blur */}
			{/* <img src={LiveIndicator} className={`${classes.PlayerControlsLive} ${classes.PlayerControlsMain} `} alt="PlayerLiveOn" data-test="player_live" /> */}
			<img src={LiveIndicator} className={`${classes.PlayerControlsLive} $ `} alt="PlayerLiveOn" data-test="player_live" />
		</div>
		:
		<ButtonCustom player={player}

			isDisabled={!source.isButtonLiveEnabled && !player.isPaused} onClick={(e) => { e.stopPropagation(); void source.setJumpLive() }} data-test="player_live">
			{/* <LightTooltip title="Zurück auf Live"> */}
			{/* fix safari blur */}
			{/* <img src={NotLive} alt="PlayerLiveOff" data-test="player_not_live" /> */}
			<img src={NotLive} className={`${classes.PlayerControlsMain} `} alt="PlayerLiveOff" data-test="player_not_live" />
			{/* </LightTooltip> */}
		</ButtonCustom>

	return (<div className={classes.PlayerLiveIndicatorContainer}>{_liveIndicator}</div>)
}

const PlayerRecordingIndicator = observer((props) => {
	const { source, player, managerRecRef, classes } = props

	const isRecordingActive = () => {
		const marker = rootStore.page.Recordings.Manager.getMarker(source?.Event?.id)
		if (marker !== null) {
			//console.debug(marker.recType)
			return marker.recType.includes("planned") || marker.recType.includes("complete")
		}
		else return false
	}

	const IsRecorded = rootStore.page.Recordings.Manager.getMarker(source?.Event?.id)?.isComplete
	// MTV-3062 added for control the recording for series or single episode of series or single episode
	const optionRecording = source?.Event?.Title?.isSeries ? [RECORDING_OPTIONS.series_episode_record, RECORDING_OPTIONS.series_record] : [RECORDING_OPTIONS.episode_record]
	return (<>
		{(!isRecordingActive()) ?
			<ButtonCustom player={player} isDisabled={!source.isButtonRecEnabled}

				onClick={(e) => {
					e.stopPropagation();
					if (!isRecordingActive()) void managerRecRef.current.setTypeRecording(optionRecording)
				}}
				// GT12: MTVW-460, MTVW-502: Channels MS flags, !source.isButtonRecHidden
				//data-test="player_rec" >
				data-test="player_rec" style={visibilityHelper(!source.isButtonRecHidden && (IsRecorded === undefined || !IsRecorded), { paddingLeft: 0 })}>
				{/* <LightTooltip title="Aufnahme starten"> */}
				<img src={NotRecording} className={`${classes.PlayerControlsRow3Animation} ${classes.PlayerControlsMain} `} alt="" />
				{/* </LightTooltip> */}
			</ButtonCustom>
			:
			<>
				{IsRecorded ?
					<div className={classes.playerRecordStatusIndicator} >
						{/* <LightTooltip title="Aufnahme abgeschlossen"> */}
						<img src={RecordComplete} className={`${classes.PlayerControlsRow3Animation} ${classes.PlayerControlsMain} `} alt="" />
						{/* </LightTooltip> */}
					</div>
					:
					<div className={classes.playerRecordStatusIndicator} >
						{/* <LightTooltip title="Aufnahme geplant"> */}
						<img src={RecordPreparing} className={`${classes.PlayerControlsRow3Animation} ${classes.PlayerControlsMain} `} alt="" />
						{/* </LightTooltip> */}
					</div>
				}
			</>
		}
	</>
	)
	// {/* MTVW-509 OLD recording indicator */ }
	// <ButtonCustom player={player} isDisabled={!source.isButtonRecEnabled}

	//    onClick={(e) => {
	//       e.stopPropagation();
	//       if (!isRecordingActive()) void managerRecRef.current.setTypeRecording(optionRecording)
	//    }}
	//    GT12: MTVW-460, MTVW-502: Channels MS flags, !source.isButtonRecHidden
	//		data-test="player_rec" style={visibilityHelper(!source.isButtonRecHidden && (IsRecorded === undefined || !IsRecorded))}>

	//    <LightTooltip title="Aufnahme starten">
	//       <img src={NotRecording} className={`${classes.PlayerControlsRow3Animation} ${classes.PlayerControlsMain} `} alt="" style={visibilityHelper(!isRecordingActive())} />
	//    </LightTooltip>

	//    <LightTooltip title="">
	//       <img src={RecordingAnimation} className={`${classes.PlayerControlsRow3Animation} ${classes.PlayerControlsMain} `} alt="" style={visibilityHelper(isRecordingActive())} />
	//    </LightTooltip>
	// </ButtonCustom>

})

// MTV-3583: handle click event for fixing new click away issue introduced with React 17
let subTitleOpen = false
const PlayerControls = CreateComponent(
	null,
	false,
	true,

	forwardRef(({ classes, page, managerRecRef, row3CenterFs }, refWrapperEl) => {
		const [isSubtitle, setSubtitle] = useState(false)
		const [showVolumeBar, setShowVolumeBar] = useState(false)
		const anchorSubtitleEl = useRef(null)
		const player = page.player
		const source = page.source

		if (refWrapperEl.current) {
			refWrapperEl.current.style.visibility = player.isStreamLoaded === false ? "hidden" : "visible"
		}

		const testButtonEnabled = (marker) => {
			return appFeatures.persistedTestButtons &&
				source.hasAdZone(source.type === SourceTypeList.ContentPlaylist ? source.Event?.ChannelId : source.id, marker)
		}

		return (
			<>
				<div
					id={ID_MAIN_CONTROLLER_CONTAINER}
					className={classnames(classes.PlayerControlsMainWrapper, { [classes.PlayerControlsMainWrapperFullScreen]: player.isFullScreen })}
					ref={refWrapperEl}

					// MTV-2504 if click in some "non control" ara in the control bar nothing have to happened
					onClick={e => {
						// MTV-3583: handle click event for fixing new click away issue introduced with React 17
						// if the menu is open the event will be stopped in ClickAwayListener or in player 
						if (!subTitleOpen) {
							e.stopPropagation();
						}
						e.preventDefault()
						// if (refWrapperEl.current === e.target) {
						// 	page.player.setFullScreen()
						// }
					}}

					onDoubleClick={e => {
						e.stopPropagation()
						e.preventDefault()
						// if (refWrapperEl.current === e.target) {
						// 	page.player.setFullScreen()
						// }
					}}
				>

					{/* MTV-2133 move in parent */}
					{/* <RecordingsManager ref={managerRef} oEvent={source?.Event} /> */}

					{/* ROW 1 */}

					{/**/}
					{player.isFullScreen && <div className={classes.PlayerControlEventFullScreenInfoContainer}>
						<div id="eventTitleFullScreen" className={classes.PlayerControlEventFullScreenInfoTitle} >{source?.Event?.content?.Title}</div>
						{source?.Event?.isSeries && <div className={classes.PlayerControlEventFullScreenInfoSubTitle} >
							{source?.Event?.content?.Season ? `S${source?.Event?.content?.Season}  \u2022 ` : ''}
							{source?.Event?.content?.Episode ? `E${source?.Event?.content?.Episode}  \u2022 ` : ''}
							{source?.Event?.content?.EpisodeName ? ` ${source?.Event?.content?.EpisodeName} ` : ''}</div>}
					</div>}
					<div className={classnames(classes.PlayerControlsRow1, { [classes.PlayerControlsRow1FullScreen]: player.isFullScreen })}>
						<div id="testButtons" className={classes.PlayerControlsRow1TestButtons}>
							<ButtonJumpMarker page={page} enabled={testButtonEnabled(source.MARKER_B)} icon={JumpB} dataTest={"player_markerB"}
								marker={source.MARKER_B} action={() => source.setJumpMarker(source.MARKER_B)} />
							<ButtonJumpMarker page={page} enabled={testButtonEnabled(source.MARKER_C)} icon={JumpC} dataTest={"player_markerC"}
								marker={source.MARKER_C} action={() => source.setJumpMarker(source.MARKER_C)} />
							<ButtonJumpMarker page={page} enabled={testButtonEnabled(source.MARKER_D)} icon={JumpD} dataTest={"player_markerD"}
								marker={source.MARKER_D} action={() => source.setJumpMarker(source.MARKER_D)} />
						</div>
						{/*
						<span style={visibilityHelper((source.type === SourceTypeList.ChannelLive) && (!player.isPaused))} >
							<img src={LiveIndicator} className={`${classes.PlayerControlsLive} ${classes.PlayerControlsMain} `} alt="" />
						</span>
						*/}
					</div>
					{/**}

					{/* ROW 2  */}
					<div className={classnames(classes.PlayerControlsRow2, { [classes.PlayerControlsRow2Fullscreen]: player.isFullScreen })} style={visibilityHelper(!showVolumeBar)}>
						{/* MTV-3384 don't show the progress bar until the event is started */}
						{/* MTV-3474: Remove condition && !page.player.isLoading */}
						{/* MTVW-444: playerFg */}
						{(page.player.isStreamLoaded || page.playerFg) && <PlayerProgressBarWrapper page={page} source={page.source} player={page.player} classes={classes} />}
					</div>

					{/* ROW 3 */}
					<div className={classnames(classes.PlayerControlsRow3, { [classes.PlayerControlsRow3Fullscreen]: player.isFullScreen })}>
						<div className={classes.PlayerControlsRow3Left}>
							{/* restart */}
							<ButtonCustom player={player} isDisabled={!source.isButtonReplayEnabled} isHidden={source.isButtonReplayHidden} onClick={(e) => { e.stopPropagation(); void source.setReplay() }} data-test="player_restart">
								{/* <LightTooltip title="Zurück zum Start"> */}
								<img src={Restart} className={`${classes.PlayerControlsSmallSize} ${classes.PlayerControlsMain} `} alt="" />
								{/* </LightTooltip> */}
							</ButtonCustom>
							{/* 10 sec back */}
							<ButtonCustom player={player}
								isDisabled={!source.isButtonJumpRwdEnabled}
								isHidden={source.isButtonJumpRwdHidden}
								onMouseMove={() => {
									controlsTimerHandler(page, true)
								}}
								onMouseLeave={() => {
									controlsTimerHandler(page)
								}}
								onClick={(e) => {
									e.stopPropagation()
									void source.setJumpRwd()
									//MTV-3466 the controls bar doesn't disappear until the user clicks on backward
									controlsTimerHandler(page, true)
									setTimeout(() => { controlsTimerHandler(page) }, 3000)
								}}
								data-test="player_backward10">
								{/* <LightTooltip title="10 Sekunden zurück"> */}
								<img src={Backward10} className={`${classes.PlayerControlsSmallSize} ${classes.PlayerControlsMain} `} alt="" />
								{/* </LightTooltip> */}
							</ButtonCustom>
							{/* play-pause */}
							<ButtonCustom
								player={player}
								isDisabled={!source.isButtonPlayPauseEnabled}
								isHidden={source.isButtonPlayPauseHidden}
								onClick={(e) => {
									e.stopPropagation()
									e.preventDefault()
									void source.setPlayPause()
									controlsTimerHandler(page, true)
								}}
								data-test="player_play_pause_button"
								data-test-status={`player_is_paused ${player.isPaused}`}
							>
								{/* #TVBDD-468	 */}
								{/* <LightTooltip title="Play"> */}
								<img src={Play} className={`${classes.PlayerControlsPlaySize} ${classes.PlayerControlsMain} `}
									alt="" style={visibilityHelper(player.isPaused)} />
								{/* </LightTooltip> */}
								{/* <LightTooltip title="Pause"> */}
								<img src={Pause} className={`${classes.PlayerControlsPlaySize} ${classes.PlayerControlsMain} `}
									alt="" style={visibilityHelper(!player.isPaused)} />
								{/* </LightTooltip> */}
							</ButtonCustom>
							{/* 10 second forward */}
							<ButtonCustom player={player}
								// MTV-3109: enable unless the source is ChannelLive
								//isDisabled={!source.isButtonJumpFwdEnabled}
								// MTVW-444: playerFg
								isDisabled={source.type === SourceTypeList.ChannelLive || rootStore.page.Player.playerFg}
								isHidden={source.isButtonJumpFwdHidden}
								onMouseMove={() => {
									controlsTimerHandler(page, true)
								}}
								onMouseLeave={() => {
									controlsTimerHandler(page)
								}}

								onClick={(e) => {
									e.stopPropagation()
									void source.setJumpFwd()
									//MTV-3466 the controls bar doesn't disappear until the user clicks on forward
									controlsTimerHandler(page, true)
									setTimeout(() => { controlsTimerHandler(page) }, 3000)
								}}
								data-test="player_forward10">
								{/* <LightTooltip title="10 Sekunden nach vorn"> */}
								<img src={Forward10} className={`${classes.PlayerControlsSmallSize} ${classes.PlayerControlsMain} `} alt="" />
								{/* </LightTooltip> */}
							</ButtonCustom>
							{/* live indicator		 */}
							<PlayerLiveIndicator source={source} player={player} classes={classes} />
							{/* recording indicator */}
							<PlayerRecordingIndicator source={source} player={player} classes={classes} managerRecRef={managerRecRef} />
						</div>

						<div className={classes.PlayerControlsRow3Right}>
							<div className={classes.PlayerControlsVolume}
								id="PlayerControlsVolume"
								onMouseOver={() => setShowVolumeBar(true)}
								onFocus={() => setShowVolumeBar(true)}
								onMouseLeave={(e) => {
									e.stopPropagation()
									controlsTimerHandler(page)
									setShowVolumeBar(false)
								}}
								onTouchStart={(e) => {
									e.preventDefault()
									e.stopPropagation()
									setShowVolumeBar(true)
								}}
							>
								<ButtonCustom className={classes.PlayerVolumeScrollIcon}
									player={player} isStreamLoaded={true} data-test="player_volume"
								>
									{/* <LightTooltip title="Lautstärke"> */}

									<img src={VolumeOff} className={` ${classes.PlayerControlsMain} `} alt="volume Off"
										onClick={(e) => {
											e.stopPropagation();
											player.setMuteState(false)
										}}
										style={visibilityHelper(player.isMuted, { fontSize: 27 })} />
									{/* </LightTooltip> */}
									{/* <LightTooltip title="Lautstärke"> */}

									<img src={VolumeOn} className={` ${classes.PlayerControlsMain} `} alt="volume On"
										onClick={(e) => { e.stopPropagation(); player.setMuteState(true) }}
										style={visibilityHelper(!player.isMuted, { fontSize: 27 })} />
									{/* </LightTooltip> */}
								</ButtonCustom>

								{/* the volume cannot be set on iOS and iPad */}
								{!(isIpad() || isIphone()) ?
									<div
										className={classes.PlayerVolumeScrollBar}
										style={visibilityHelper(showVolumeBar)}
									>
										<Slider aria-label="Volume"
											id='volume-slider'
											classes={{
												thumb: classes.playerControlSliderThumb,
												rail: classes.playerControlVolumeSliderRail,
												track: classes.playerControlVolumeSliderTrack,
											}}
											max={100}
											min={0}
											step={1}
											orientation="vertical"
											value={volumeCheatHelper(player.volume)}
											onChange={(e, newValue) => {
												player.setVolume(newValue)
											}}
										/>
									</div>
									: ""}
							</div>

							<ButtonCustom
								className={classes.PlayerControlSubtitles}
								isDisabled={!source.isButtonTrackAudioEnabled && !source.isButtonTrackSubtitleEnabled}
								player={player}
								data-test="player_subtitles"
								ref={anchorSubtitleEl}
								onClick={(e) => {
									e.stopPropagation();
									void setSubtitle(!isSubtitle)
									// MTV-3583: handle click event for fixing new click away issue introduced with React 17
									subTitleOpen = !isSubtitle
								}}
							>
								{/* <LightTooltip title="Toneinstellungen"> */}
								<img src={Subtitles} className={`${classes.PlayerControlsSmallSize} ${classes.PlayerControlsMain} `} alt="" />
								{/* </LightTooltip> */}
							</ButtonCustom>
							<Popper
								open={page.isControlsVisible && isSubtitle ? true : false}
								anchorEl={() => anchorSubtitleEl.current}
								placement="top"
								modifiers={[
									{
										name: "offset",
										options: {
											offset: [0, 50],
										},
									},
								]}
								disablePortal={true}
								className={classes.PlayerControlSubtitlesPopper}
								onClick={(e) => {
									e.stopPropagation()
									// MTV-3583: handle click event for fixing new click away issue introduced with React 17
									// not necessary in responsive
									e.preventDefault()
									void setSubtitle(false)
									setTimeout(() => { subTitleOpen = false }, 500)
								}}
							>
								<ClickAwayListener onClickAway={(e) => {
									e.stopPropagation()
									e.preventDefault()
									// MTV-3583: handle click event for fixing new click away issue introduced with React 17
									// could be fixed in a future update, so this workaround could be eliminated.
									void setSubtitle(false)
									setTimeout(() => { subTitleOpen = false }, 500)
								}}>
									<PlayerControlAudioSubtitlesMenu page={page} player={player} setSubtitle={setSubtitle} classes={classes} isInPlayerControlsResponsive={false} />
								</ClickAwayListener>
							</Popper>

							{/* MTVW-727 */}
							{page.isPiPButtonEnabled && <ButtonCustom
								data-test="player_pip"
								player={player}
								isDisabled={false}
								isHidden={false}
								onClick={(e) => {
									e.stopPropagation();
									if (page.isControlsVisible) page.setMouseOver(false)
									source.showPiP(true)
								}}
							>
								<img src={PictureInPicture} className={` ${classes.PlayerControlsMain} `} alt="fullscreen enter" />
							</ButtonCustom>}

							<ButtonCustom
								className={classes.PlayerControlsFullscreen}
								data-test="player_fullscreen"
								player={player}
								isDisabled={!source.isButtonFullScreenEnabled}
								isHidden={source.isButtonFullScreenHidden}
								onClick={(e) => {
									e.stopPropagation();
									// MTVW-452: hide the controls if change to full screen and if is iPhone
									if (page.isControlsVisible && isIphone()) page.setMouseOver(false)
									void source.setFullScreen()
								}}
							>
								{/* <LightTooltip title={page.player.isFullScreen ? "Vollbild minimieren" : "Vollbild"}> */}
								{page.player.isFullScreen ?
									<img src={FullscreenExit} className={` ${classes.PlayerControlsMain} `} alt="fullscreen enter" /> :
									<img src={FullscreenEnter} className={` ${classes.PlayerControlsMain} `} alt="fullscreen exit" />
								}
								{/* </LightTooltip> */}
							</ButtonCustom>
						</div>
					</div>
				</div>
			</>
		)
	})
)

// CHECK_MERGE
const PlayerControlsResponsive = CreateComponent(
	null,
	false,
	true,

	forwardRef(({ classes, page, managerRecRef }, refWrapperEl) => {

		const [isSubtitle, setSubtitle] = useState(false)
		const anchorSubtitleEl = useRef(null)
		const player = page.player
		const source = page.source

		const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
		//const [videoWidth, setVideoWidth] = useState(getWindowDimensions())
		const handleResize = () => {
			setWindowDimensions(getWindowDimensions())
			/*
			const video = document.getElementsByClassName("vop-video")[0]
			setVideoWidth(video.clientWidth)
			*/
			//console.debug("PlayerControlsResponsive adjustPlayerStyles 	", page.player.settings.isFullScreen)
			// MTVW-733
			page.adjustPlayerStyles()
		}

		// Trying to set state for opened audio subtiles on mobile
		// let [AudioOpened, setAudioOpened] = useState(false);
		// const _handleChangeAudioOpened = () => {
		// 	return setAudioOpened(!AudioOpened)
		// }

		// const handleChangeAudioOpened = _handleChangeAudioOpened.bind(this)

		useEffect(() => {
			window.addEventListener("resize", handleResize)
			return (() => {
				window.removeEventListener("resize", handleResize)
			})
		})

		if (refWrapperEl.current) {
			refWrapperEl.current.style.visibility = player.isStreamLoaded === false ? "hidden" : "visible"
		}

		const testButtonEnabled = (marker) => {
			return appFeatures.persistedTestButtons &&
				source.hasAdZone(source.type === SourceTypeList.ContentPlaylist ? source.Event?.ChannelId : source.id, marker)
		}

		return (
			<div
				id={ID_MAIN_CONTROLLER_CONTAINER}
				// MTVW-452
				//className={classnames(classes.PlayerControlsMainWrapper, { [classes.PlayerControlsMainWrapperFullScreen]: player.isFullScreen })}
				className={classnames(classes.PlayerControlsMainWrapper, { [classes.PlayerControlsMainWrapperFullScreenResponsive]: player.isFullScreen })}
				ref={refWrapperEl}
				// MTV-2504 if click in some "non control" ara in the control bar nothing have to happened
				onClick={e => {
					// MTV-3583: handle click event for fixing new click away issue introduced with React 17
					if (!subTitleOpen) {
						e.stopPropagation();
					}
					if (page.isControlsVisible) page.setMouseOver(false)
					// e.stopPropagation()
					e.preventDefault()
					// if (refWrapperEl.current === e.target) {
					// 	page.player.setFullScreen()
					// }
				}}
				onDoubleClick={e => {
					e.preventDefault()
					e.stopPropagation()

					// if (refWrapperEl.current === e.target) {
					// 	page.player.setFullScreen()
					// }
				}}
				style={windowDimensions < 1440 ? { display: "flex", flexDirection: "column", background: "rgba(0,0,0,.6)", zIndex: 1 } : { display: "flex", flexDirection: "column", zIndex: 1 }}
			>

				{/* MTV-2133 move in parent */}
				{/* <RecordingsManager ref={managerRef} oEvent={source?.Event} /> */}

				<div className={classes.PlayerControlsRow1Responsive} id={`${ID_PLAYER_CONTROLLER_RESPONSIVE}_row1`}>

					{/* MTVW-509 */}
					{/* <div className={classnames(classes.PlayerControlsRow1Responsive, { [classes.PlayerControlsRow1FullScreen]: player.isFullScreen })}> */}

					{/* ROW 1 */}

					{player.isFullScreen && <div className={classes.PlayerControlEventFullScreenInfoContainerResponsive}>
						<div id="eventTitleFullScreen" className={classes.PlayerControlEventFullScreenInfoTitle} >{source?.Event?.content?.Title}</div>
						{source?.Event?.isSeries && <div className={classes.PlayerControlEventFullScreenInfoSubTitle} >
							{source?.Event?.content?.Season ? `S${source?.Event?.content?.Season}  \u2022 ` : ''}
							{source?.Event?.content?.Episode ? `E${source?.Event?.content?.Episode}  \u2022 ` : ''}
							{source?.Event?.content?.EpisodeName ? ` ${source?.Event?.content?.EpisodeName} ` : ''}</div>}
					</div>}
					<ButtonCustom
						className={classes.PlayerControlSubtitlesResponsive}
						isDisabled={!source.isButtonTrackAudioEnabled && !source.isButtonTrackSubtitleEnabled}
						player={player}
						data-test="player_subtitles"
						ref={anchorSubtitleEl}
						onClick={(e) => {
							e.stopPropagation();
							void setSubtitle(!isSubtitle)
							// MTV-3583: handle click event for fixing new click away issue introduced with React 17
							subTitleOpen = !isSubtitle
						}}
					>
						{/* {!isSubtitle && <LightTooltip title="Toneinstellungen">
							<img src={Subtitles} className={`${classes.PlayerControlsSmallSize} ${classes.PlayerControlsMain} `} alt="" />
						</LightTooltip>} */}
						{!isSubtitle && <img src={Subtitles} className={`${classes.PlayerControlsSmallSize} ${classes.PlayerControlsMain} `} alt="" />}


					</ButtonCustom>

					<Popper
						open={page.isControlsVisible && isSubtitle ? true : false}
						anchorEl={() => anchorSubtitleEl.current}
						placement="bottom-start"
						// MTV-2575: diablePortal must be true, otherwise the subtitles menu will not be displayed in fullscreen!
						disablePortal={true}
						className={"PlayerControlSubtitlesPopperResponsive"}
					>
						<ClickAwayListener onClickAway={(e) => {
							e.stopPropagation()
							e.preventDefault()
							void setSubtitle(false)
							// MTV-3583: handle click event for fixing new click away issue introduced with React 17
							// could be fixed in a future update, so this workaround could be eliminated.
							// if the menu is open the event will be stoped in ClickAwayListener or in player
							setTimeout(() => { subTitleOpen = false }, 500)
						}}>
							<PlayerControlAudioSubtitlesMenu page={page} player={player} setSubtitle={setSubtitle} classes={classes} isInPlayerControlsResponsive={true} />

						</ClickAwayListener>
					</Popper>
					{!isSubtitle && (
						<ButtonCustom className={classes.PlayerVolumeScrollIconResponsive} player={player} isStreamLoaded={true} data-test="player_volume" >
							{/* <LightTooltip title="Lautstärke"> */}

							<img src={VolumeOff} className={` ${classes.PlayerControlsMain} `} alt="volume Off" onClick={(e) => { e.stopPropagation(); player.setMuteState(false) }} style={visibilityHelper(player.isMuted, { fontSize: 27 })} />

							{/* </LightTooltip> */}
							{/* <LightTooltip title="Lautstärke"> */}
							<img src={VolumeOn} className={` ${classes.PlayerControlsMain} `} alt="volume On" onClick={(e) => { e.stopPropagation(); player.setMuteState(true) }} style={visibilityHelper(!player.isMuted, { fontSize: 27 })} />

							{/* </LightTooltip> */}
						</ButtonCustom>
					)}
					{/* MTVW-509 */}
					{/* {!isSubtitle && page.player.isFullScreen && (
								<ButtonCustom player={player} onClick={(e) => { e.stopPropagation(); if (!player.page.isPlayerOnOverlay) { page._pageStore.OverlayEventDetails.setEpgEventById(page.source.Event.id).setShow(true) } else { page.player.setFullScreen(false) } }} data-test="player_information">
									{/* <LightTooltip title="Info">
										<img src={InfoButton} className={`${classes.PlayerControlsInformation} ${classes.PlayerControlsMain} `} alt="" />
									</LightTooltip> 
								</ButtonCustom>
							)} */}
					{!isSubtitle && <>							<PlayerRecordingIndicator source={source} player={player} classes={classes} managerRecRef={managerRecRef} />
					</>}

					{/* MTVW-727 */}
					{!isSubtitle && page.isPiPButtonEnabled && <ButtonCustom
						data-test="player_pip"
						player={player}
						isDisabled={false}
						isHidden={false}
						onClick={(e) => {
							e.stopPropagation();
							if (page.isControlsVisible) page.setMouseOver(false)
							source.showPiP(true)
						}}
					>
						<img src={PictureInPicture} className={` ${classes.PlayerControlsMain} `} alt="fullscreen enter" />
					</ButtonCustom>}

					{!isSubtitle && <ButtonCustom
						className={classes.PlayerControlsFullscreen}
						data-test="player_fullscreen"
						player={player}
						isDisabled={!source.isButtonFullScreenEnabled}
						isHidden={source.isButtonFullScreenHidden}
						// MTVW-452: added style
						//style={page.player.isFullScreen ? { bottom: "13px" } : { bottom: "23px" }}
						onClick={(e) => {
							e.stopPropagation();
							// MTVW-452: hide the controls if change to full screen and if is iPhone
							if (page.isControlsVisible && isIphone()) page.setMouseOver(false)
							void source.setFullScreen()
						}}
					>
						{/* <LightTooltip title={page.player.isFullScreen ? "Vollbild minimieren" : "Vollbild"}> */}
						{page.player.isFullScreen ?
							<img src={FullscreenExit} className={` ${classes.PlayerControlsMain} `} alt="fullscreen enter" /> :
							<img src={FullscreenEnter} className={` ${classes.PlayerControlsMain} `} alt="fullscreen exit" />
						}
						{/* </LightTooltip> */}
					</ButtonCustom>}
				</div>

				{/* ROW 2 */}
				{!isSubtitle && <div className="PlayerControlsRow2Responsive">
					{/* MTVW-754:  justifyContent: "center" when using PlayerControlsRow3Center*/}
					<div className={classnames(classes.PlayerControlsRow3Center, { [classes.PlayerControlsRow3CenterFullScreen]: player.isFullScreen })} style={{ justifyContent: "center" }}>
						{/* restart */}
						<ButtonCustom player={player} isDisabled={!source.isButtonReplayEnabled} isHidden={source.isButtonReplayHidden} onClick={(e) => { e.stopPropagation(); void source.setReplay() }} data-test="player_restart">
							{/* <LightTooltip title="Zurück zum Start"> */}
							<img src={Restart} className={`${classes.PlayerControlsSmallSize} ${classes.PlayerControlsMain} `} alt="" />
							{/* </LightTooltip> */}
						</ButtonCustom>
						{/* 10 sec back */}
						<ButtonCustom player={player}
							isDisabled={!source.isButtonJumpRwdEnabled}
							isHidden={source.isButtonJumpRwdHidden}
							onMouseMove={() => {
								controlsTimerHandler(page, true)
							}}
							onMouseLeave={() => {
								controlsTimerHandler(page)
							}}
							onClick={(e) => {
								e.stopPropagation()
								void source.setJumpRwd()
								//MTV-3466 the controls bar doesn't disappear until the user clicks on backward
								controlsTimerHandler(page, true)
								setTimeout(() => { controlsTimerHandler(page) }, 3000)
							}}
							data-test="player_backward10">
							{/* <LightTooltip title="10 Sekunden zurück"> */}
							<img src={Backward10} className={`${classes.PlayerControlsSmallSize} ${classes.PlayerControlsMain} `} alt="" />
							{/* </LightTooltip> */}
						</ButtonCustom>
						{/* play pause  */}
						<ButtonCustom
							player={player}
							isDisabled={!source.isButtonPlayPauseEnabled}
							isHidden={source.isButtonPlayPauseHidden}
							onClick={(e) => {
								e.stopPropagation()
								e.preventDefault()
								void source.setPlayPause()
								controlsTimerHandler(page, true)
								// setTimeout(() => { controlsTimerHandler(page) }, 0)
							}}
							data-test="player_play_pause_button"
							data-test-status={`player_is_paused ${player.isPaused}`}
						>
							{/* <LightTooltip title="Play"> */}
							<img src={Play} className={`${classes.PlayerControlsPlaySize} ${classes.PlayerControlsMain} `} alt="" style={visibilityHelper(player.isPaused)} />
							{/* </LightTooltip> */}
							{/* <LightTooltip title="Pause"> */}
							<img src={Pause} className={`${classes.PlayerControlsPlaySize} ${classes.PlayerControlsMain} `} alt="" style={visibilityHelper(!player.isPaused)} />
							{/* </LightTooltip> */}
						</ButtonCustom>

						{/* 10 sec forward	 */}
						<ButtonCustom player={player}
							//MTV-3109 // still enable until is the event is Live
							//isDisabled={!source.isButtonJumpFwdEnabled}
							isDisabled={source.type === SourceTypeList.ChannelLive}
							isHidden={source.isButtonJumpFwdHidden}
							onMouseMove={() => {
								controlsTimerHandler(page, true)
							}}
							onMouseLeave={() => {
								controlsTimerHandler(page)
							}}
							onClick={(e) => {
								e.stopPropagation()
								void source.setJumpFwd()
								//MTV-3466 the controls bar doesn't disappear until the user clicks on forward
								controlsTimerHandler(page, true)
								setTimeout(() => { controlsTimerHandler(page) }, 3000)
							}}
							data-test="player_forward10">
							{/* <LightTooltip title="10 Sekunden nach vorn"> */}
							<img src={Forward10} className={`${classes.PlayerControlsSmallSize} ${classes.PlayerControlsMain} `} alt="" />
							{/* </LightTooltip> */}
						</ButtonCustom>
						{/* live indicator		 */}
						<PlayerLiveIndicator source={source} player={player} classes={classes} />
					</div>
				</div>}

				{/* ROW 3  */}
				{!isSubtitle && <div className="PlayerControlsRow3Responsive" id={`${ID_PLAYER_CONTROLLER_RESPONSIVE}_row3`}>
					<div id="testButtons" className={classes.PlayerControlsRow3TestButtons} >
						<ButtonJumpMarker page={page} enabled={testButtonEnabled(source.MARKER_B)} icon={JumpB} dataTest={"player_markerB"}
							marker={source.MARKER_B} action={() => source.setJumpMarker(source.MARKER_B)} />
						<ButtonJumpMarker page={page} enabled={testButtonEnabled(source.MARKER_C)} icon={JumpC} dataTest={"player_markerC"}
							marker={source.MARKER_C} action={() => source.setJumpMarker(source.MARKER_C)} />
						<ButtonJumpMarker page={page} enabled={testButtonEnabled(source.MARKER_D)} icon={JumpD} dataTest={"player_markerD"}
							marker={source.MARKER_D} action={() => source.setJumpMarker(source.MARKER_D)} />
					</div>
					{/*
					<div className={classnames(classes.PlayerControlsRow2, { [classes.PlayerControlsRow2Fullscreen]: player.isFullScreen })}>
						<div className={classes.PlayerControlsRow3Left}>
							{page.player.isStreamLoaded && <PlayerProgressBarWrapper source={page.source} player={page.player} classes={classes} />}
						</div>
					</div>
					*/}
					{/* MTVW-466: added div*/}
					<div id={`${ID_PLAYER_CONTROLLER_RESPONSIVE}_row2`} className={classnames(classes.PlayerControlsRow2, { [classes.PlayerControlsRow2Fullscreen]: player.isFullScreen })}>
						{/* MTV-3384 don't show progress bar until the event is loaded  */}
						{/* MTV-3474: Remove condition && !page.player.isLoading */}
						{/* MTVW-444: playerFg */}
						{(page.player.isStreamLoaded || page.playerFg) && <PlayerProgressBarWrapper page={page} source={page.source} player={page.player} classes={classes} />}
					</div>
				</div>}
			</div>
		)
	})
)

const PlayerLoading = CreateComponent(null, false, true, ({ classes, player }) => (
	<>
		{/* MTV-3427 Safari return a callback later and the spinner was visible behind the error message
			added rootStore.page?.Player?.error to not show the spinner on safari in case of error*/}
		{(player.isLoading && !rootStore.page?.Player?.error) && (
			<div className={classes.PlayerLoadingWrapper}>
				<CircularProgress size={100} data-test="player_loading" />
			</div>
		)}
	</>
))

const PlayerMutedByBrowser = CreateComponent(null, false, true, ({ classes, page }) => (
	<div
		className={classes.PlayerMutedByBrowserWrapper}
		onClick={(e) => {
			e.stopPropagation()
			void page.player.setMutedByBrowser(false)
			void page.setMouseOver(true)
		}}
	>
		{page.isControlsVisible && (<VolumeOffBrowser className={classes.PlayerMutedByBrowserIcon} sx={{ fontSize: "120px" }} data-test="player_browser_muted" />)}
	</div>
))

// MTVW-452:
// Adjust the screen size for phones
const resizePhonePlayerVideo = (page) => {
	// the values of window.screen.availWidth and window.screen.availHeight depend on how the application is opened, whether in Portrait or Landscape.
	// they never change even if the phone is rotated
	// in landscape the height is the lowest value

	const activePlayer = page.source.adState?.fastFwd || page.source.adState?.replay ? page.player.domElementId.ADS_PLAYER : page.player.domElementId.TV_PLAYER
	const playerElement = document.getElementById(activePlayer)

	if (!playerElement) return
	// MTVW-733: Test, exclude Android
	//if ((isPhoneSize()) || isAndroid()) {
	if (isIphone()) {
		if (page.player.isFullScreen && window.innerHeight < window.innerWidth) {
			// landscape
			playerElement.style.width = "100vw" //"95vw" //isAndroid() ? "90vw" : "95vw"
		} else {
			playerElement.style.width = "100%"
		}
	}

	// For iPhone: fix the issue of cut video in case the landscape tab bar is visible
	if (isIphone()) {
		const availHeight = Math.min(window.screen.availWidth, window.screen.availHeight)

		playerElement.style.margin = "unset"
		if ((page.player.settings.isFullScreen) && (availHeight > window.innerHeight) && (window.innerHeight < window.innerWidth)) {
			// calculation of the width with the new height:
			// innerWidth: availHeight = x: innerHeight
			const newWidth = window.innerWidth * window.innerHeight / availHeight
			playerElement.style.width = `${newWidth * 90 / 100}px`
			playerElement.style.margin = "auto"
		}
	}
}

const PlayerControlsWrapper = CreateComponent(null, false, true, ({ classes, page, managerRecRef }) => {
	const wrapperRef = useRef(null)
	// const managerRecRef = useRef() MTV-3398  Moved out of PlayerControlsWrapper
	//const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
	const [videoWidth, setVideoWidth] = useState(getWindowDimensions())

	//MTVW-452
	const handleOrientationChange = (event) => {
		if (event !== null) {
			event.stopPropagation();
			event.preventDefault();
		}
		// MTVW-733: Test with page.setMouseOver(false)
		// screen.orientation.type not available on Edge/iOS and iPadOS
		//console.debug("handleOrientationChange type %o, sInfo %o, event %o", screen?.orientation?.type, screenInfo(), event?.target)
		page.player?.__parent?.orientationChange(event)
		page.setMouseOver(false)

		//page.adjustPlayerStyles()
		setTimeout(() => {// need delay for updating window
			handleResize()
		}, 100)
	}

	const handleResize = () => {
		// MTVW-452
		resizePhonePlayerVideo(page)

		//setWindowDimensions(getWindowDimensions())
		// MTV-3523
		// added setTimeout for synchronization of the updated video's clientWidth
		// see links:
		// https://stackoverflow.com/questions/9083594/call-settimeout-without-delay
		// https://medium.com/@Rahulx1/understanding-event-loop-call-stack-event-job-queue-in-javascript-63dcd2c71ecd
		//setTimeout(() => {
		const video = page.getVideoElem()
		if (video) {
			// MTVW-733: Use page.calcVideoDimensions
			//setVideoWidth(video.clientWidth)
			//console.debug("handleResize sInfo", screenInfo())
			const vDimensions = page.calcVideoDimensions(page.player.settings.isFullScreen)
			setVideoWidth(vDimensions.width)
			//console.debug("wrapper handleResize adjustPlayerStyles, fs %o, vW %o, hH %o", page.player.settings.isFullScreen, video.clientWidth, video.clientHeight)
			// MTVW-733
			page.adjustPlayerStyles()
			//console.debug("video with %s", video.clientWidth)
		}
		//})
	}

	useEffect(
		...page.getMountBody(
			() => {
				// MTVW-452: added addEventListener for "orientationchange"
				// crashes on Edge/iPad
				//screen.orientation.addEventListener("change", handleOrientationChange)
				// actually deprecated
				window.addEventListener("orientationchange", handleOrientationChange)
				window.addEventListener("resize", handleResize)
				handleResize()
			},
			() => {
				//screen.orientation.removeEventListener("change", handleOrientationChange)
				window.removeEventListener("orientationchange", handleOrientationChange)
				window.removeEventListener("resize", handleResize)
			}
		)
	)

	useLayoutEffect(() => {
		handleResize()
		//console.debug("useLayoutEffect")
	})

	// MTVW-452:
	// needed to rerender the component also for AdsPlayer to force the call of handleResize
	useEffect(() => {
		// allow keeping the controls of the player inside the player e.g. full-screen portrait mobile
		//console.debug("useEffect adjustPlayerStyles fs=", page.player.settings.isFullScreen)
		page.adjustPlayerStyles()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page.player.settings.isFullScreen])

	if (page.player.isMutedByBrowser === true) {
		// MTV-2525: don't hide mute icon
		controlsTimerHandler(page, true)
		return <PlayerMutedByBrowser classes={classes} page={page} />
	}

	return (
		/* CHECK_MERGE */
		<div id="playerControls"
			// CAUTION: Applying a change to below breakpoint value requires also the same change in global-responsive.css below the marker "UNIQUE_MARKER: BP_PLAYER_CONTROLS"
			/*
			className={windowDimensions > 1239 - 21 ? classnames(classes.PlayerControls, { [classes.PlayerControlsShowControls]: page.isControlsVisible }) : classnames(classes.PlayerControlsResponsive, { [classes.PlayerControlsShowControls]: page.isControlsVisible })}
			*/
			// MTV-2528: Use video width for breakpoint (iPads in portrait mode have at least 768px)
			className={videoWidth >= 768 ? classnames(classes.PlayerControls, classes, { [classes.PlayerControlsShowControls]: page.isControlsVisible }) : classnames(classes.PlayerControlsResponsive, { [classes.PlayerControlsShowControls]: page.isControlsVisible })}
			// style={{ cursor: page.isControlsVisible && !page.isFullScreen ? "default" : "none" }}
			style={page.source.pauseAd && !page.isControlsVisible ? { pointerEvents: "none" } : {}}

			data-test="player_controls_wrapper"
			onClick={(e) => {
				if (e.target.closest(`#${ID_MAIN_CONTROLLER_CONTAINER} `)) return;
			}}
			onMouseMove={() => {
				// do nothing 
				// if the visibility of the controller is managed by the child component
				// in our case it is managed only from volume control
				if (!page.isControlsVisible) controlsTimerHandler(page)
			}
			}
		>
			{/* MTV-3398 Moved out of PlayerControlsWrapper */}
			{/* <div onDoubleClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
				<RecordingsManager ref={managerRecRef} oEvent={page.source?.Event} />
			</div> */}

			{/* CAUTION: Applying a change to below 2 breakpoint value requires also the same change in global-responsive.css below the marker "UNIQUE_MARKER: BP_PLAYER_CONTROLS" */}
			{/*
			{(page.isControlsVisible && windowDimensions > 1239 - 21) && (<PlayerControls classes={classes} page={page} managerRecRef={managerRecRef} ref={wrapperRef} />)}
			{(page.isControlsVisible && windowDimensions < 1240 - 21) && (<PlayerControlsResponsive classes={classes} page={page} managerRecRef={managerRecRef} ref={wrapperRef} />)}
			*/}
			{(page.isControlsVisible && videoWidth >= 768) && (<PlayerControls classes={classes} page={page} managerRecRef={managerRecRef} ref={wrapperRef} row3CenterFs={videoWidth > 1024 ? classes.PlayerControlsRow3CenterFullScreen : classes.PlayerControlsRow3CenterFullScreenDense} />)}
			{(page.isControlsVisible && videoWidth < 768) && (<PlayerControlsResponsive classes={classes} page={page} managerRecRef={managerRecRef} ref={wrapperRef} />)}
		</div>
	)
})

// button for close screen after full screen in tvguide
const PlayerCloseTvGuideScreen = CreateComponent(styles, false, true, ({ classes, page }) => {
	if (page.player.isMutedByBrowser === true) {
		// MTV-2525: don't hide mute icon
		controlsTimerHandler(page, true)
		return <PlayerMutedByBrowser classes={classes} page={page} />
	}
	//console.debug("PlayerCloseTvGuideScreen ctrlsVisible %o, onOverlay %s, !fs %s", page.isControlsVisible, page.isPlayerOnOverlay, !page.player.isFullScreen)
	return (
		<div
			data-test="player_close_tvguide"
			onClick={(e) => {
				if (e.target.closest(`#${ID_MAIN_CONTROLLER_CONTAINER} `)) return;
			}}
		>
			{page.isControlsVisible === true ?
				<div className={classes.PlayerControlsTopWrapper}>
					{page.isPlayerOnOverlay && !page.player.isFullScreen ?
						<IconButton player={page.player}
							className={`${classes.PlayerControlsClose} ${classes.PlayerControlsMain}`}
							onClick={(e) => {
								e.stopPropagation();
								EventEmitter.dispatch("closeOverlayDialog", null)
								page.player.setFullScreen(false)
							}} data-test="player_close">
							{/* <img src={CloseButton} className={`${classes.PlayerControlsClose} ${classes.PlayerControlsMain} `} alt="" /> */}
						</IconButton>
						: <></>}
				</div>
				: <></>
			}
		</div>
	)
})

/*
const PlayerCover = CreateComponent(null, false, true, ({ page, defaultCoverImg, ...props }) => (
	<CardMedia src={page?.source?.getPosterUrl ?? defaultCoverImg} component="img" data-test="player_cover" style={visibilityHelper(page.player.isPosterVisible)} {...props} />
))
*/
// MTV-3040: reenable with black poster image
const PlayerCover = CreateComponent(null, false, true, ({ page, defaultCoverImg, ...props }) => {
	//console.debug("isPosterVisible= %o %o %o %o %o", page.player.__path, page.player.isPosterVisible, page, defaultCoverImg)
	let visible = page?.player.isPosterVisible
	// MTVW-444: don't show cover if playerBg is in the background
	if (page.playerFg || page.isAdsPlayer) visible = false
	return (
		// MTVW-725: capturedVideoImage
		//<CardMedia src={page?.source?.getPosterUrl ? defaultCoverImg : defaultCoverImg} component="img" data-test="player_cover" style={visibilityHelper(visible)} {...props} />
		<CardMedia src={page?.source?.capturedVideoImage ? page?.source?.capturedVideoImage : defaultCoverImg} component="img" data-test="player_cover" style={visibilityHelper(visible)} {...props} />
	)
})


// GT12-SVG trial
//async function getSvg(svg) {
//	/*
//	const response = await fetch(svg, {
//		method: 'GET', headers: { 'pragma': 'no-cache', 'cache-control': 'no-cache' }
//	})
//	const testSvg = await response.text()
//	console.debug("defaultCoverImg %o, testSvg %o, copy %s", defaultCoverImg, testSvg, svg.slice())
//	*/
//	//return testSvg
//	//return svg.slice()
//	const test = await import("img/PauseAd_McDonals_Spot_Test_AdLabeling.svg")
//	console.debug("IMPORT %o, default %o", test, test.default)
//	return test.default
//}

//const testSvg = getSvg("img/PauseAd_McDonals_Spot_Test_AdLabeling.svg")
//const testSvg = svgCoverImg

// NOTE: SVG doesn't start at the beginning when repeatedly played (browser cache)
// SVG has been postponed in favor of JPG
//return (
//	<div style={visibilityHelper(page.player.isPosterVisible)} {...props} >
//		<object type="image/svg+xml" data={page?.source?.getPosterUrl ? testSvg : testSvg} aria-label="Close" /*style={{ visibility: "hidden" }} {...props} */ />
//		{/*<CardMedia src={defaultCoverImg} type="image/svg+xml" data={defaultCoverImg} aria-label="Close" component="img" data-test="player_cover" style={visibilityHelper(page.player.isPosterVisible)} {...props} />*/}
//		{/*<img src={defaultCoverImg} alt="" data-test="player_cover" />*/}
//	</div>
//)
//})
let hidePlayerControls = false
let clickTimer = null
let doubleClickTimer = null
let preventClick = false // differentiate between click and double click
const PlayerBodyInner = CreateComponent(styles, false, true, ({ classes, page, defaultCoverImg, playerName, managerRecRef }) => {
	//console.debug("container %o", page.player.container)

	// MTV-2504 don't need 2 useEffect function
	useEffect(() => {
		//(async () => {
		console.debug("MOUNT %s, %o", playerName, page, page.player.isFullScreen)
		// MTVW-444, MTVW-788, MTVW-787
		page.mountEvent()
		setCurrentChannel(rootStore.page.ChannelListRepo.activeChannelId)
		page.getPlayerByName(playerName).setRefElemContainer(document.getElementById(page.player.domElementId.TV_PLAYER), true)
		page.getPlayerByName("playerBg").setRefElemContainer(document.getElementById(page.player.domElementId.BG_PLAYER), true)
		page.getPlayerByName("playerAds").setRefElemContainer(document.getElementById(page.player.domElementId.ADS_PLAYER), true)

		// MTV-2534: Add touch detection to show player controls
		const elem = document.getElementById("body_inner")
		if (elem) {
			elem.addEventListener("touchstart", tapOrClick, false)
			elem.addEventListener("touchmove", tapOrClick, false)
			elem.addEventListener("touchend", tapOrClick, false)
			// mouseup handler causes fullscreen / double tap issues
			//elem.addEventListener("mouseup", tapOrClick, false)
		}
		// MTV-2695: Special case for native controls on iOS
		const video = page.getVideoElem()
		if (video) {
			//video.addEventListener("webkitbeginfullscreen", webkitBeginFullScreen, false)
			video.addEventListener('webkitendfullscreen', webkitEndFullScreen, false)
			video.addEventListener('volumechange', volumeChange, false)
		}
		// MTVW-788, MTVW-787: tvRef, bgRef
		page.setTvRef(tvRef)
		page.setBgRef(bgRef)
		//})()

		// Return a callback in useEffect and it will be called before unmounting.
		return () => {
			console.debug("UNMOUNT %s, %o", playerName, page, page.player.isFullScreen)
			const elem = document.getElementById("body_inner")
			if (elem) {
				elem.removeEventListener("touchstart", tapOrClick, false)
				elem.removeEventListener("touchmove", tapOrClick, false)
				elem.removeEventListener("touchend", tapOrClick, false)
				//elem.removeEventListener("mouseup", tapOrClick, false)
			}
			const video = page.getVideoElem()
			if (video) {
				//video.removeEventListener("webkitbeginfullscreen", webkitBeginFullScreen, false)
				video.removeEventListener('webkitendfullscreen', webkitEndFullScreen, false)
				video.removeEventListener('volumechange', volumeChange, false)
			}

			// MTVW-708: Enable Ansehen button
			rootStore.page.OverlayEventDetails.setPlayEventStarted(false)
			//(async () => {
			// GT12
			// MTVW-444, MTVW-788, MTVW-787
			page.mountEvent()
			let player = page.getPlayerByName(playerName)
			if (player) player.setRefElemContainer(null, true)
			// MTVW-444
			player = page.getPlayerByName("playerBg")
			if (player) player.setRefElemContainer(null, true)
			player = page.getPlayerByName("playerAds")
			if (player) player.setRefElemContainer(null, true)
			//})()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// MTVW-633: check for _refElemContainer, it could be set to null after an error condition
	useEffect(() => {
		const player = page.getPlayerByName(playerName)
		setCurrentChannel(rootStore.page.ChannelListRepo.activeChannelId)
		if (!player?._refElemContainer) console.warn("useEffect null check", playerName, player, player._refElemContainer)

		/* MTVW-444, MTVW-788, MTVW-787: causes garbage if pausing live stream and immediately skipping back 10 seconds
		if (!player?._refElemContainer) {
			//(async () => {
			// MTVW-444, MTVW-788, MTVW-787
			page.mountEvent()
			page.getPlayerByName(playerName).setRefElemContainer(document.getElementById(page.player.domElementId.TV_PLAYER), true)
			// MTVW-444
			page.getPlayerByName("playerBg").setRefElemContainer(document.getElementById(page.player.domElementId.BG_PLAYER), true)
			page.getPlayerByName("playerAds").setRefElemContainer(document.getElementById(page.player.domElementId.ADS_PLAYER), true)
			// MTVW-788, MTVW-787: tvRef, bgRef
			page.setTvRef(tvRef)
			page.setBgRef(bgRef)
			//})()
		}*/
	})

	const [currentChannel, setCurrentChannel] = useState(null)
	// MTVW-788, MTVW-787: tvRef, bgRef
	const tvRef = useRef()
	const bgRef = useRef()

	// MTVW-633: check for _refElemContainer, it could be set to null after an error condition
	/*
	useLayoutEffect(() => {
		const player = page.getPlayerByName(playerName)
		setCurrentChannel(rootStore.page.ChannelListRepo.activeChannelId)
		if (!player?._refElemContainer && rootStore.page.ChannelListRepo.activeChannelId) {
			//(async () => {
				console.debug("useEffect activeChannelId", player._refElemContainer, rootStore.page.ChannelListRepo.activeChannelId)
				page.getPlayerByName(playerName).setRefElemContainer(document.getElementById(page.player.domElementId.TV_PLAYER))
				page.getPlayerByName("playerAds").setRefElemContainer(document.getElementById(page.player.domElementId.ADS_PLAYER))
			//})()
		}
	}, [rootStore.page.ChannelListRepo.activeChannelId])
	*/

	/*
	function webkitBeginFullScreen(event) {
		event.preventDefault()
		event.stopPropagation()
		//console.debug("webkitBeginFullScreen")
	}
	*/

	// special case for iOS with native controls to close the fullscreen player
	function webkitEndFullScreen(event) {
		console.debug("webkitEndFullScreen paused %s by user %s", page.player.isPaused, page.player.isPausedUser)
		event.stopPropagation()
		event.preventDefault()
		page.player.setFullScreen(false)
		// The native player pauses video if it's closed, we need to apply a delay to start play again
		if (!page.player.isPausedUser) {
			setTimeout(() => {
				page.player.setPlay("webkitEndFullScreen")
			}, 600)
		}
	}

	// special case for iOS with native controls to handle mute / unmute
	function volumeChange(event) {
		const video = page.getVideoElem()
		//console.debug("volumeChange %o muted %s", event, video.muted)
		if (video) page.player.setMuteState(video.muted)
	}

	function tapOrClick(event) {
		//The code below blocks the appearance of the controls after pressing the play button in the middle of the screen
		if (hidePlayerControls && event.type === "touchend") {
			hidePlayerControls = false
			return
		}

		if (page.isControlsVisible && (event.target.id === ID_MAIN_CONTROLLER_CONTAINER || event.target.id.includes(ID_PLAYER_CONTROLLER_RESPONSIVE))) {
			page.setMouseOver(false)
		}
		//console.debug("touch %o type %s visible %s", event, event.type, page.isControlsVisible)
		if (event.type === "touchend") {
			// GT12
			if (clickTimer === null) {
				clickTimer = setTimeout(function () {
					clickTimer = null;
					// display player controls
					controlsTimerHandler(page)
					//console.debug("single")
				}, 500)
			} else {
				clearTimeout(clickTimer);
				clickTimer = null;
				// MTVW-424: for full screen with double touch
				preventClick = true;
				//console.debug("preventClick true tapOrClick")
				//console.debug("double")
				event.preventDefault()
				if (!page.isControlsVisible) {
					page.player.setFullScreen()
					// hide controls
					controlsTimerHandler(page, true)
					setTimeout(() => {
						page.setMouseOver(false)
					}, 100)
				}
			}
		}

		if (event.type === "touchmove") {
			if (!page.isControlsVisible) {
				// ignore event, it would generate onMouseMove event which enables the player controls
				event.preventDefault()
			}
		}

		if (!page.isControlsVisible) {
			if (page.error === null) {

				if (page.source.adState.isAdActive) {
					//event.preventDefault()
					page.setMouseOver(false)
					return
				}
				event.preventDefault()
				//event.stopPropagation()
				// don't show controls
				//controlsTimerHandler(page)
			}
		}
		//return false
	}

	function doClickAction() {
		// MTV-1811: prevent playing of non subscribed channel in case there is an error
		// MTV-3583: handle click event for fixing new click away issue introduced with React 17
		// if the menu is open the event will be stopped in ClickAwayListener of the player controller
		if (page.error === null) {
			// MTVW-424: condition to handle play icon in the middle of the screen in both player
			// GT12: condition with pauseAd, otherwise pause would be resumed when in OverlayEventDetails
			//console.debug("doClickAction isInPip", page.player.isInPip, page.player)
			// MTVW-727: isInPip
			if (!subTitleOpen && !page.player.isInPip) {
				void page.source.setPlayPause()
			}
		}
	}

	function doDoubleClickAction() {
		// MTVW-727: isInPip
		if (page.player.isInPip) return
		page.player.setFullScreen()
		// hide controls
		controlsTimerHandler(page, true)
		setTimeout(() => {
			page.setMouseOver(false)
		}, 100)
	}

	const middleScreenPlayButton = (<IconButton className={isPhoneSize() ? ` ${classes.centralPlayResumeButtonMobile} ` : ` ${classes.centralPlayResumeButton} `} alt="" //style={page.source.pauseAd ? { zIndex: 2 } : { zIndex: 4 }}
		id="middleScreenPlayButton"
		onClick={(e) => {
			e.stopPropagation()
			e.preventDefault()
			doClickAction()
		}}
		onTouchStart={e => {
			e.stopPropagation()
			e.preventDefault()
			page.setMouseOver(false)
			// the variable above will be checked in tapOrClick
			// need only in touchStart
			hidePlayerControls = true
		}}
		onTouchEnd={(e) => {
			e.stopPropagation()
			e.preventDefault()
			page.setMouseOver(false)
			doClickAction()
		}}
	/>)
	//console.debug("PLAYER %s, path %s", playerName, page?.player?.__path)
	//const list = document.getElementsByTagName("video")
	//console.debug("VIDEOS %o", list/*.namedItem("")*/)
	return (
		<>
			{/*console.debug("currentChannel", currentChannel)*/}
			<div id="body_inner" className={classes.PlayerWrapper + " PlayerWrapperResponsive"} >
				<div className={classes.Player}
					// show and hide <PlayerControlsWrapper>
					onMouseMove={() => {
						if (!page.source.pauseAd) {
							controlsTimerHandler(page)
						}
					}}
					onDoubleClick={(e) => {
						e.stopPropagation()
						e.preventDefault()
						clearTimeout(doubleClickTimer);
						// MTVW-452: 
						// added if condition to fix the issue of opening fullscreen with double touch on iPad
						if (!preventClick) {
							// VJS: prevent initial fullScreen when double clicked on poster play button to start video
							if (!page.player._isStreamLoaded) return
							preventClick = true;
							//console.debug("preventClick true onDoubleClick")
							doDoubleClickAction();
						}
					}}
					onClick={(e) => {
						// MTV-3583: handle click event for fixing new click away issue introduced with React 17
						// setTimeout to give time to close the menu
						setTimeout(() => { subTitleOpen = false }, 500)
						setTimeout(() => {
							e.stopPropagation();
						}, 600)
						e.preventDefault()
						doubleClickTimer = setTimeout(function () {
							if (!preventClick) {
								doClickAction();
							}
							//console.debug("preventClick false onClick")
							preventClick = false;
						}, 300);
					}}
				>
					<CardMedia src={defaultCoverImg} component="img" className={classes.PlayerDefaultCover} />
					{page.oEvent?.Data && (
						<Img
							src={page.oEvent.Data.Title?.Pictures?.getBoxCover}
							// MTVW-831
							params="mode=box&w=1920&h=1080"
							fallback={fallbackImageCategory(page.oEvent.Data.Title?.Genres?.itemFirst?.type)}
							alt={page.oEvent.Data.Title.Name}
							className={classes.movieCover}

						/>
					)}
					{/* MTV-2172: Move controls inside tvPlayer */}
					{/* GT12
					<div id="tvPlayer" ref={el => { page.getPlayerByName(playerName).setRefElemContainer(el) }} className={classes.PlayerBody}></div>
					*/}
					{/*
					// MTVW-733: addedd style for ultrawidescreen fix
					<div id="uiPlayers" className={classes.PlayerBody} style={isIphone() || isIpad() ? {} : { objectFit: "contain", height: "100%", paddingTop: "0px" }}>
					*/}
					<div id="uiPlayers" className={classes.PlayerBody} /*style={{ objectFit: "contain", height: "100%", paddingTop: "0px" }}*/>
						{/* MTV-3617: move PlayerCover into this "tvPlayer" div */}
						{/* MTV-3040: reenable with black poster image */}
						<PlayerCover page={page} defaultCoverImg={defaultCoverImg} className={classes.PlayerCover} />
						<PauseAd page={page} classes={classes} />
						<div className={classes.SpinnerContainer}>
							{/*<PlayerLoading classes={classes} player={page.getPlayerByName("playerBg")} />*/}
							<PlayerLoading classes={classes} player={page.getPlayerByName(playerName)} />
						</div>
						{/* MTVW-452 */}
						{/* <div id="tvPlayer" /*className={classes.PlayerBody} style = {{  */}
						<div ref={tvRef} id={page.player.domElementId.TV_PLAYER} style={getPlayerStyle(true)} channel-info={currentChannel}>
							{import.meta.env.VITE_PLAYER === "vjs" ?
								// MTVW-733: addedd style for ultrawidescreen fix
								// MTVW-710: workaround for iPad
								//<div style={isIphone() || isIpad() ? {} : { objectFit: "contain", height: "100%", paddingTop: "0px" }} data-vjs-player>
								<div style={{ /*objectFit: "contain",*/ height: "100%", paddingTop: "0px" }} data-vjs-player>
									{/* Do not use both data-setup and an options object
									<video id={"vjs_" + playerName} className='video-js' data-setup='{}' /> */}
									<video id={"vjs_" + playerName} className='video-js' />
								</div>
								: null}
							{/* MTV-3671: below commented code moved to Player component to fix black screen at end of playback caused by RecordingsManager (MTV-3398) */}
							{/*
							<div onDoubleClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
								<RecordingsManager ref={managerRecRef} oEvent={page.source?.Event} />
							</div>
							*/}
							{/* MTVW-424: 
							play button in the middle of the screen only if controls 
							are not visible, to fix problem with double play button for the responsive controls 
							{/* MTVW-424: commented WIP
							{page.source.player.isPausedUser && !page.isControlsVisible && middleScreenPlayButton}
							*/}
							{/* MTVW-444: section with PlayerControlsWrapper moved out  */}
							{/* {!page.source.adState?.linear?.skip && <FFAdAnimatedIcon />} */}
						</div>
						{/* MTVW-444*/}
						<div ref={bgRef} id={page.player.domElementId.BG_PLAYER} style={getPlayerStyle(false)} channel-info={currentChannel}>
							{import.meta.env.VITE_PLAYER === "vjs" ?
								<div style={{ /*objectFit: "contain",*/ height: "100%", paddingTop: "0px" }} data-vjs-player>
									<video id={"vjs_" + "playerBg"} className='video-js' />
								</div>
								: null}
							{/* MTVW-444: section with PlayerControlsWrapper moved out  */}

							{/* {!page.source.adState?.linear?.skip && <FFAdAnimatedIcon />} */}
						</div>
						{/* GT12 */}
						{/* MTVW-452 - MTVW-453 */}
						{/* <div id="adsPlayer" className={classes.adsPlayerBody} style={{ display: "none" /*, zIndex: 1000000, visibility: "visible" }} > */}
						<div id={page.player.domElementId.ADS_PLAYER} className={classes.adsPlayerBody} style={getPlayerStyle(false)}
							data-test={`${page.player.isFullScreen ? 'ads_player_full_screen' : 'ads_player_not_full_screen'}`}
						>
							{import.meta.env.VITE_PLAYER === "vjs" ?
								// MTVW-733: addedd style for ultrawidescreen fix
								// MTVW-710: workaround for iPad
								///<div style={isIphone() || isIpad() ? {} : { objectFit: "contain", height: "100%", paddingTop: "0px" }} data-vjs-player>
								<div style={{ /*objectFit: "contain",*/ height: "100%", paddingTop: "0px" }} data-vjs-player>
									{/* Do not use both data-setup and an options object
									<video id={"vjs_" + "playerAds"} className='video-js' data-setup='{}' />*/}
									<video id={"vjs_" + "playerAds"} className='video-js' />
								</div>
								: null}
							{page.source.player.isPausedUser && middleScreenPlayButton}
							{/* GT12 fastForward */}
							{page.source.adState?.fastFwd && <AdCounter page={page} adStyleValidCounter={page.player?.__parent?.adStyleValidCounter} adStyleInfo={page.player?.__parent?.adStyleInfo} isFullScreen={page.player.isFullScreen} />}
							{page.source.adState?.replay && <AdCounter page={page} adStyleValidCounter={page.player?.__parent?.adStyleValidCounter} adStyleInfo={page.player?.__parent?.adStyleInfo} isFullScreen={page.player.isFullScreen} />}
							{/*console.debug("AD DIV %o, %o", page.source, page.player._source)*/}
							{/*
							{page.source.adState?.replay ? console.debug("AD_REPLAY %s", page.source.adState.replay.countdown) : null}
							{page.source.adState?.fastFwd ? console.debug("AD_FFWD adNumber %s, adCount %s, promoCountdown %s, skip %s", page.source.adState.fastFwd.adNumber, page.source.adState.fastFwd.adCount, page.source.adState.fastFwd.promoCountdown, page.source.adState.fastFwd.skip) : null}
							{page.source.adState?.linear ? console.debug("AD_LINEAR skip %s", page.source.adState.linear.skip) : null}
							*/}
						</div>
						{/* MTVW-444: moved from div TV_PLAYER and div BG_PLAYER */}
						<PlayerControlsWrapper page={page} classes={classes} managerRecRef={managerRecRef} />
						<PlayerCloseTvGuideScreen page={page} classes={classes} />
						{/* GT12 fastForward */}
						{page.source.adState?.linear && !page.source.adState?.linear.skip ?
							<FFAdAnimatedIcon
								page={page}
								adStyleValidCounter={page.player?.__parent?.adStyleValidCounter}
								adStyleInfo={page.player?.__parent?.adStyleInfo}
								source={page.source}
								isControlsVisible={page.isControlsVisible}
								isFullScreen={page.player.isFullScreen}
							/> : null}
						{page.source.adState?.linear ? console.debug("AD_LINEAR skip %s", page.source.adState.linear.skip) : null}
						{page.source.adState?.linear?.skip && <LinearSkipAd
							page={page}
							adStyleValidCounter={page.player?.__parent?.adStyleValidCounter}
							adStyleInfo={page.player?.__parent?.adStyleInfo}
							source={page.source}
							isControlsVisible={page.isControlsVisible}
							isFullScreen={page.player.isFullScreen}
						/>}
						{/* MTVW-444: end moved from */}
						<div id="imscContainer" className={classes.ImscContainer}></div>
					</div>
					{/* MTV-3617: move PlayerCover into div "tvPlayer" above */}
					{/*
					<PlayerCover page={page} defaultCoverImg={defaultCoverImg} className={classes.PlayerCover} />
					*/}
				</div>
				{/* MTV-3617: move PlayerLoading into div "tvPlayer" above */}
				{/*
				<PlayerLoading classes={classes} player={page.getPlayerByName(playerName)} />
				*/}
				{/*
				{!(page.error?.isError || rootStore.page.Recordings.Manager.oRecEvent.error) ? <PlayerControlsWrapper page={page} classes={classes} /> : <></>}
				*/}

				{/* MTV-2172: Move controls inside tvPlayer
				<PlayerControlsWrapper page={page} classes={classes} />
				<PlayerCloseTvGuideScreen page={page} classes={classes} />
				*/}
			</div>
		</>
	)
})

const PlayerBodyInnerEmpty = CreateComponent(styles, false, true, ({ classes, page, defaultCoverImg, playerName, managerRecRef }) => {
	return (
		<div id="body_inner" className={classes.PlayerWrapper + " PlayerWrapperResponsive"} >
			<div className={classes.Player}>
			</div>
			<CardMedia src={defaultCoverImg} component="img" className={classes.PlayerDefaultCover} />
			<div id="uiPlayers" className={classes.PlayerBody}>
				<div id="tvPlayer" style={getPlayerStyle(true)} channel-info="">
					<PlayerControlsWrapper page={page} classes={classes} managerRecRef={managerRecRef} />
					<PlayerCover page={page} defaultCoverImg={defaultCoverImg} className={classes.PlayerCover} />
				</div>
				<div id="adsPlayer" className={classes.adsPlayerBody} style={getPlayerStyle(false)}
					data-test={`${page.player.isFullScreen ? 'ads_player_full_screen' : 'ads_player_not_full_screen'}`}
				>
				</div>
			</div>
		</div>
	)
})


export const PlayerBody = CreateComponent(styles, false, true, ({ classes, page, defaultCoverImg, playerName, managerRecRef }) => {
	return (
		<>
			<div id="ql_player_container" className={classnames(classes.PlayerContainer, { [classes.PlayerFullscreen]: page.player.isFullScreen })}
				data-test={page.player.isFullScreen ? "player_container_maximized" : "player_container_minimized"}
			>
				{/*
		{page.player.isFullScreen ? console.debug("container full %o", page.player.container) : console.debug("container min %o", page.player.container)}
		*/}

				{/* MTVW-727b: could be an option to go back to live TV without starting new stream
				{!page.remount && !page.player.isInPip ? */}
				{!page.remount ?
					<PlayerBodyInner classes={classes} page={page} defaultCoverImg={defaultCoverImg} playerName={playerName} managerRecRef={managerRecRef} />
					//: null
					: <PlayerBodyInnerEmpty classes={classes} page={page} defaultCoverImg={defaultCoverImg} playerName={playerName} managerRecRef={managerRecRef} />
				}
			</div>
		</>
	)
})

export const Player = CreateComponent(null, false, false, props => {
	// props contain defaultCoverImg
	const page = useRootStore().page.Player
	// MTV-3671: move ref to here
	const managerRecRef = useRef()

	//console.debug("--->>>>>>>>>>>>>> Player Player.js 1520" + page.isLoading + ' ' + rootStore.page.Player?.player?.isLoading)
	useEffect(...page.getMountBody(() => { }))
	useEffect(() => {
		// MTV-1532: fix Changing a channel while the current running channel is on pause, the new channel is also paused
		// MTV-3746: iPhone / iPad generates VO_OSMP_CB_PLAY_PAUSED event when the app is put into background, use isPausedUser instead of isPaused
		//console.debug("useEffect isPausedUser %s, %s, %s", page.player.isPausedUser, page.player.positionTimeStamp, page.player.activePosition)
		// MTVW-217: add condition for inactiveReason - DISABLED in PlayerVo
		// GT12: condition with pauseAd, otherwise pause would be resumend when in OverlayEventDetails
		// MTVW-444: && !page.playerFg
		if (page.player.isPausedUser && (page.player.inactiveReason === null) && !page.source.pauseAd && !page.playerFg) {
			page.source.setPlayPause()
		}
	}, [page.player.isPausedUser, page.player.inactiveReason, page.source])

	useEffect(() => {
		page.initPlayerControls(page.player.domElementId.TV_PLAYER)
		// MTVW-444
		page.initPlayerControls(page.player.domElementId.BG_PLAYER)
	}, [page])

	return (
		<>
			{/* MTV-2504: A double click on the REC button needs to be disabled here */}
			{/* MTV-3671: below div moved to here to fix black screen at end of playback caused by RecordingsManager (MTV-3398) */}
			<div id="player-rec-wrapper" onDoubleClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
				<RecordingsManager ref={managerRecRef} oEvent={page.source?.Event} />
			</div>
			<PlayerBody page={page} playerName="playerLiveTvVo" managerRecRef={managerRecRef} {...props} />
		</>
	)
})
