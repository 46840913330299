// l10n status: done
import React from "react";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import logo from "img/Quickline_logo.svg"
import { detect } from "detect-browser"
import { l10n } from "store/lang/L10n"

const useStyles = makeStyles(theme => ({
	rootDialog: {
		zIndex: "250000 !important"
	},
	paper: {
		margin: "auto",
		marginTop: "10%",
		width: "500px",
		backgroundColor: theme.palette.background.paper,
		//boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		background: "#272726",
		boxShadow: "2px 1px 10px rgba(192,192,192,0.4), -1px -1px 10px rgba(192,192,192,0.4) "
	},
	logoContainer: {
		textAlign: "center",
		margin: "auto",
		padding: "20px"
	},
	logo: {
		width: "40%",
		height: "auto",
	},
	container: {
		margin: "auto",
		paddingRight: "20px",
		paddingLeft: "20px",
		paddingTop: "20px"
	},
	txtFooter: {
		padding: "10px",
	},
	footer: {
		bottom: "0",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "6.2vh",
		textAlign: "center",
		margin: "auto",
		backgroundColor: theme.palette.background.paper,
		//background: "#272726",
		marginTop: "6px"
	},
	button: {
		background: "#D61036",
		width: "25%",
		height: "60%",
		color: "white",
		border: "none",
		borderRadius: "5px",
	},
	titleList: {
		marginTop: "1rem",
		marginBottom: "0px",
		textAlign: "center"
	}
}));

export function BrowserSupport() {
	const classes = useStyles();
	// getModalStyle is not a pure function, we roll the style only on the first render
	const [modalStyle] = React.useState({ top: "20%", left: "22%", width: 320 });
	const [open, setOpen] = React.useState(false);
	const [browserMessage, setBrowserMessage] = React.useState(["", ""])

	// const firefoxMessage = ["Die Quickline TV Web App ist noch nicht für Firefox optimiert.", "Bitte benutzen Sie auf Ihrem Computer Google Chrome oder Safari und auf mobilen Geräten die iOS bzw. Android App."]
	// const generalMessage = ["Die Quickline TV Web App ist nicht für diesen Browser optimiert.", "Bitte benutzen Sie auf Ihrem Computer Google Chrome, Safari oder Firefox und auf mobilen Geräten die iOS bzw. Android App."]
	const generalMessage = [l10n.commonMessageBrowserNotSupportedTitle, l10n.commonMessageBrowserNotSupportedHint]

	React.useEffect(() => {
		const browser = detect()
		// MTVW-322, CAUTION: The commented line below caused an excessive memory consumption when running BDD tests on Firefox
		//console.debug("OS %s, browser %s, %s, obj %o, nav %o", browser?.os, browser?.name, browser?.version, browser, navigator)
		console.debug("OS %s, browser %s %s", browser?.os, browser?.name, browser?.version)
		// eslint-disable-next-line no-alert
		//alert(browser.os + " " + browser.name)
		if (browser) {
			if (browser.os.indexOf("Windows Mobile") !== -1) {
				setBrowserMessage(generalMessage)
				setOpen(true)
			}
			else if ((browser.os.indexOf("Windows") !== -1) || (browser.os.indexOf("Mac OS") !== -1) ||
				(browser.os.indexOf("iOS") !== -1) || (browser.os.indexOf("iPadOS") !== -1) ||
				(browser.os.indexOf("Android OS") !== -1) || (browser.os.indexOf("Linux") !== -1)) {
				switch (browser && browser.name) {
					case 'chrome':
					case 'crios':
					case 'firefox':
					case 'fxios':
					case 'safari':
					case 'ios':
					// MTVW-608
					case 'edge-chromium':
					case 'edge-ios':
						//console.debug("%s supported", browser.name);
						setOpen(false)
						break;
					// MTV - 2614
					// case 'firefox':
					// 	setBrowserMessage(firefoxMessage)
					// 	//console.debug("%s not yet supported", browser.name);
					// 	setOpen(true)
					// 	break;

					default:
						//console.debug("%s never supported", browser.name);
						setBrowserMessage(generalMessage)
						setOpen(true)
				}
			}
			else {
				setBrowserMessage(generalMessage)
				setOpen(true)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<Modal
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			open={open}
			onClose={handleClose}
			closeAfterTransition
			className={classes.rootDialog}
			disableAutoFocus={true}
		>
			<div style={modalStyle} className={classes.paper}>
				<div className={classes.logoContainer}>
					<img src={logo} alt="QuickLine" className={classes.logo}></img>
				</div>

				<div className={classes.container}>
					<h3 className={classes.titleList}>{browserMessage[0]}</h3>
					<h3 className={classes.titleList}>{browserMessage[1]}</h3>
				</div>

				<div className={classes.txtFooter}>
					<p></p>
				</div>

				<div className={classes.footer}>
					<button type="button" onClick={handleClose} className={classes.button}>
						{l10n.commonActionOk}
					</button>
				</div>

			</div>
		</Modal>
	)
}
