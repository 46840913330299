// l10n status: partial
import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import { IconButton } from "@mui/material"
import { rootStore } from "store/RootStore"
import LiveIndicator from "img/icons/liveInSearch.svg"
import Play from "img/icons/playInSearch.svg"
import LinearProgress from "@mui/material/LinearProgress"
import serieLogo from "img/icons/series.svg"
import "./SearchComponents.css"
import "./../SearchStyle.css"
import { observer } from "mobx-react"
import { CALLING_CONTEXT_TYPE } from "store/page/OverlayEventDetails"
import { l10n } from "store/lang/L10n"
// import goBack from "img/icons/search/arrow_back_ios_white_36dp.svg"
// import goForward from "img/icons/search/arrow_forward_ios_white_36dp.svg"
import { FilterBar } from "scenes/Search/components/FilterBar"
import { NotFound, NOTFOUND_TYPE } from './../Search'
import { iso6391 } from "store/util/iso6391"


const Programs = ({ item, page, typeCards, context }) => {

	const _EVENT_DATA = {
		logoImgSrc: null,
		channelName: null,
		eventTitle: null,
		genres: null,
		seasonInfo: null,
		programDate: null,
		liveIcon: null,
		progressBar: null,
		playButton: null,
		seriesMobileIcon: null,
		type: null,
		idEvent: null,
		idSerie: null,
	}
	//backGroundImgStyle.backgroundImage = `${gradientImg},url(${item?.posterUrl}?mode=box&w=480&h=270)`
	const backGroundImgStyle = { ...page.backGroundImgStyle }
	backGroundImgStyle.backgroundImage = `${page.gradientImg},url(${item?.type === page.TYPE_EVENT ? item?.data?.content?.posterUrl : item?.data?.posterUrl}?mode=box&w=480&h=270)`
	const isLiveEvent = (item.type === page.TYPE_EVENT) && (item?.data?.event?.eventType === 'c') ? true : false
	const isPastEvent = (item.type === page.TYPE_EVENT) && (item?.data?.event?.eventType === 'p') ? true : false
	_EVENT_DATA.type = item.type
	if (item.type === page.TYPE_EVENT) {
		_EVENT_DATA.idEvent = item?.data?.event?.id
		_EVENT_DATA.logoImgSrc = page.getChannelLogo(item?.data?.event?.channelId)
		_EVENT_DATA.channelName = <div className="searchChannelName" data_test="search_channel_name">{page.getChannelName(item?.data?.event?.channelId)}</div>
		_EVENT_DATA.eventTitle = item?.data?.content?.Title
		_EVENT_DATA.genres = item?.data?.content?.genres.join(' \u25CF ')
		_EVENT_DATA.programDate = item?.data?.event?.dateAndTime
		_EVENT_DATA.liveIcon = isLiveEvent ? < img src={LiveIndicator} className="searchLiveIndicator" data_test="search_event_live_icon" alt="" /> : null
		_EVENT_DATA.progressBar = isLiveEvent ? <LinearProgress classes={{ root: "searchMuiLinearProgress" }} variant="determinate" value={item?.data?.event.durationInPercent} data-test="search_event_progressbar" /> : null
		_EVENT_DATA.playButton = (isLiveEvent || isPastEvent) && page.isReplayAllowed(item?.data?.event) ? <div style={{ position: 'relative' }}>
			<img className="searchPlayIcon" src={Play} alt="play" data_test="search_event_play_icon" style={{ bottom: isLiveEvent ? "10px" : "-1px" }}
				onClick={(e) => {
					// MTVW-624: autoplay support
					e.stopPropagation()
					rootStore.page.OverlayEventDetails.setEpgEventById(_EVENT_DATA.idEvent, true).then((res) => {
						rootStore.page.OverlayEventDetails.setComponentType('event').setShow(true, CALLING_CONTEXT_TYPE.Search)
					})
				}}></img>
		</div> : null
	} else if (item.type === page.TYPE_SERIE) {
		_EVENT_DATA.idSerie = item?.data?.id
		_EVENT_DATA.logoImgSrc = serieLogo
		_EVENT_DATA.eventTitle = item?.data?.Title
		//_EVENT_DATA.seasonInfo = `${item?.data?.seasonCount} ${item?.data?.seasonCount > 1 ? 'Staffeln' : 'Staffel'} ${'\u25CF'} ${item?.data?.episodeCount}  Episodes ${'\u25CF'} ${item?.data?.language} `
		_EVENT_DATA.seasonInfo = l10n.featureSearchSeriesInfo.format(item?.data?.seasonCount, item?.data?.episodeCount, iso6391.getNameByCode(item?.data?.language))
		_EVENT_DATA.seriesMobileIcon = <div style={{ position: 'relative' }}>
			<img className="searchSeriesMobileIcon" src={serieLogo} alt="series" data_test="search_event_series_icon"></img>
		</div>
	}
	return (
		<div className="eventContainer"
			data_test={`search_event ${item.type}`}
			onClick={(e) => {
				e.stopPropagation()
				if (item.type === page.TYPE_SERIE) {
					rootStore.page.OverlayEventDetails.setComponentType(page.CATEGORY_SERIES).setIdSerie(_EVENT_DATA.idSerie).setShow(true, CALLING_CONTEXT_TYPE.Search)
				} else if (item.type === page.TYPE_EVENT) {
					rootStore.page.OverlayEventDetails.setEpgEventById(_EVENT_DATA.idEvent).then((res) => {
						rootStore.page.OverlayEventDetails.setComponentType('event').setShow(true, CALLING_CONTEXT_TYPE.Search)
					})
				}
				// MTVW-624: combined activityStack for related content and search
				rootStore.page.OverlayEventDetails.pushActivity({ type: context.type, actor: context.actor, genre: context.genre, subItem: page.genresSelected })
			}}
		>
			<div className="searchEventLogoChannel">
				<img className="searchLogo" src={_EVENT_DATA.logoImgSrc} alt="channel logo"></img>

			</div>
			<div className="searchEventInfoContainer">
				<div className="searchEventInfo">
					<div className="searchEventInfoText" >
						{_EVENT_DATA.channelName}
						<div className="searchEventTitle" data_test="search_event_title">{_EVENT_DATA.eventTitle}</div>
						<div className="searchEventGenresInfo">{_EVENT_DATA.genres}</div>
					</div>
					<div className="searchEventInfoPoster">
						<div className="eventPosterSection" style={backGroundImgStyle} data_test="search_event_poster"></div>
					</div>
				</div>
				<div className="searchEventIconsAndBar">
					{_EVENT_DATA.liveIcon}
					<span data_test="search_event_info">{_EVENT_DATA.seasonInfo ? _EVENT_DATA.seasonInfo : _EVENT_DATA.programDate}</span>
					{_EVENT_DATA.progressBar}
					{_EVENT_DATA.playButton}
					{_EVENT_DATA.seriesMobileIcon}
				</div>
			</div>
		</div>)
}

export const PeopleGenresComponent = observer((props) => {
	const { type, actor, genre, subItem } = props
	//console.debug("props=", props, subItem)
	const page = rootStore.page.Search
	const pageOverlay = rootStore.page.OverlayEventDetails
	const refListContainer = useRef()

	const [allContent, setAllContent] = useState(null)

	useLayoutEffect(() => {
		page.setRefresh(true)
		//setAllContent(null)
		if (type === page.CATEGORY_ACTORS) {
			page.infoActor(actor)
		} else if (type === page.CATEGORY_GENRES) {
			page.infoGenre(genre)
		}
	}, [page, /*actor, genre,*/ type])

	//the code below is necessary to refresh the data
	useEffect(() => {
		// eslint-disable-next-line no-unused-expressions
		refListContainer?.current?.scrollTo(0, 0)
		return (() => {
			//page.setGenresSelected(page.ALL_GENRES_SELECTED)
			//page.setGenresSelected(subItem ? subItem : page.ALL_GENRES_SELECTED)
			//pageOverlay.setActivityBackItem(null)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page.genre, page.actor])
	page.setRefresh(false)
	// end code for refresh

	useEffect(() => {
		//console.debug("genre changed", page.genresSelected, type)
		setAllContent(null)
		if (type === page.CATEGORY_ACTORS) {
			if (page.genresSelected === page.ALL_GENRES_SELECTED) {
				//processGenres(page.CATEGORY_ACTORS, page?.actor?.contents)
				page.infoActor(actor, true).then((res) => {
					//console.debug("searchActorInGenre=", res)
					processGenres(page.CATEGORY_ACTORS, page?.actor?.contents)
				})
			}
			else {
				page.searchActorInGenre(actor, page.genresSelected, false).then((res) => {
					//console.debug("searchActorInGenre=", res)
					processGenres(page.CATEGORY_ACTORS, res?.contents)
				})
			}
		}
		else if (type === page.CATEGORY_GENRES) {
			if (page.genresSelected === page.ALL_GENRES_SELECTED) {
				//processGenres(page.CATEGORY_GENRES, page?.genre?.contents)
				page.infoGenre(genre, true).then((res) => {
					//console.debug("infoGenre=", page?.genre?.contents)
					processGenres(page.CATEGORY_GENRES, page?.genre?.contents)
				})
			}
			//else if (page.genre) {
			if (page.genresSelected !== page.ALL_GENRES_SELECTED) page.searchGenreInGenre(genre, page.genresSelected, false).then((res) => {
				//console.debug("infoGenre=", page?.genre?.contents, page)
				processGenres(page.CATEGORY_GENRES, res?.contents)
			})
			//}
		}
	}, [page.genresSelected, actor, genre])

	const processGenres = (gType, contents) => {
		// eslint-disable-next-line no-unused-expressions
		let result = []

		// MTVW-631: for gType ==== page.CATEGORY_GENRES, object with all genres, preset to false
		const genresFound = {}
		if (gType === page.CATEGORY_ACTORS && page.genresSelected === page.ALL_GENRES_SELECTED) {
			page?.actor?.person?.genres.forEach(genre => {
				//console.debug("genre in actor", genre)
				genresFound[genre] = false
			})
		}

		contents?.forEach(item => {
			let genres = null
			//console.debug("item", item, page.genresSelected)
			if (item.type === page.TYPE_SERIE) {
				//console.debug("serie=", item)
				genres = item?.data?.genres
				if (genres?.includes(page.genresSelected) || page.genresSelected === page.ALL_GENRES_SELECTED) {
					result.push(item)
				}
			}
			else if (item.type === page.TYPE_EVENT) {
				//console.debug("item", item, page.genresSelected, item.data.content.genres, item.data.content.genres.includes(page.genresSelected))
				genres = item?.data?.content?.genres
				if (genres?.includes(page.genresSelected) || page.genresSelected === page.ALL_GENRES_SELECTED) {
					result.push(item)
				}
			}
			if (gType === page.CATEGORY_ACTORS /*&& page.genresSelected === page.ALL_GENRES_SELECTED*/ && genres) {
				for (var i in genres) {
					// MTVW-631: updated object with all genres
					genresFound[genres[i]] = true
				}
			}
		})
		if (gType === page.CATEGORY_ACTORS && page.genresSelected === page.ALL_GENRES_SELECTED) {
			// MTVW-631: some genre(s) might be empty after having applied channels filter, remove them
			// loop through genresFound and not page?.actor?.person?.genres (which will be modified)
			const genres = Object.keys(genresFound)
			//console.debug("keys", genres)
			genres.forEach(genre => {
				if (genresFound[genre] === false) {
					console.debug("no genre", genre)
					const index = page?.actor?.person?.genres.indexOf(genre)
					if (index !== -1) page?.actor?.person?.genres.splice(index, 1);
				}
			})
		}
		setAllContent(result)
		//console.debug("result=", result, genresFound)
	}

	const _content = allContent?.map((item, index) => {
		return (
			<Programs
				item={item}
				page={page}
				typeCards={page.categorySelected}
				context={props}
				key={`${page.categorySelected}-${index}`} />
		)
	})

	//console.debug("check", page.genre, page.actor)
	return (
		<div className="peopleComponentMainContainer" >
			<div className="peopleComponentTitleContainer">
				<div className="peopleComponentButtonsContainer">
					<IconButton
						aria-label="Back"
						disableRipple={true}
						disableFocusRipple={true}
						className="peopleComponentBackButton"
						onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
							// MTVW-624: combined activityStack for related content and search
							pageOverlay.restoreActivity()
							// reset filter
							page.setSeasonSelected(page.ALL_SEASON_SELECTED)
							page.setGenresSelected(page.ALL_GENRES_SELECTED)
							// MTVW-620: FILTER_EVENT_ALL -> FILTER_EVENT_LIVE_REPLAY
							page.setFilterEventType(page.FILTER_EVENT_LIVE_REPLAY)
						}}
						data-test="back_people_component"
						style={{ visibility: pageOverlay.activityStack.length > 0 ? "visible" : "hidden" }}
					></IconButton>
					<div className="peopleComponentCloseButtonContainer">
						<IconButton aria-label="Close"
							disableRipple={true}
							disableFocusRipple={true}
							className="peopleComponentCloseButton" onClick={() => {
								// MTVW-624: combined activityStack for related content and search
								pageOverlay.resetActivity()
								page.setGenresSelected(page.ALL_GENRES_SELECTED)
								page.setSeasonSelected(page.ALL_SEASON_SELECTED)
								pageOverlay.setShow(false)
							}} data-test="close_people_component">
						</IconButton>
					</div>
				</div>
				<div className="peopleComponentTitle" data_test='search_component_title'>
					{type === page.CATEGORY_ACTORS ? actor : genre}
				</div>
				{type === page.CATEGORY_ACTORS && <div className="seriesComponentFilterSeriesContainer">
					{/* <FilterGenres page={page} /> */}
					<FilterBar page={page} filterType={page.CATEGORY_ACTORS} />
				</div>}
				{type === page.CATEGORY_GENRES && <div className="seriesComponentFilterSeriesContainer">
					{/* <FilterGenres page={page} /> */}
					<FilterBar page={page} filterType={page.CATEGORY_GENRES} />
				</div>}

			</div>
			{/* MTVW-631: some genre(s) might be empty after having applied channels filter */}
			{/* MTVW-808: no search results for some actors, commented || (!page.actor && type === page.CATEGORY_ACTORS) */}
			{_content?.length > 0 ?
				< div className="peopleComponentListContainer" ref={refListContainer}>
					{_content}
				</div>
				: (!page.genre && type === page.CATEGORY_GENRES) /*|| (!page.actor && type === page.CATEGORY_ACTORS)*/ || (allContent === null)
					? <></> : <NotFound type={NOTFOUND_TYPE.NOHINT} />
			}
		</div>
	)
})
