// l10n status: partial
import { CircularProgress, Slide, Snackbar, SnackbarContent } from "@mui/material"
import { CreateComponent } from "components/utils/CreateComponent"
import React, { forwardRef, useImperativeHandle, useState } from "react"
import { RecordingsConfirmDialog } from "scenes/Recordings/components/RecordingsConfirmDialog"
import { RecordingsStartDialog } from "scenes/Recordings/components/RecordingsStartDialog"
import { RECORDING_OPTIONS } from "store/page/recordings/RecordingsManager"
import { rootStore } from "store/RootStore"
import { EventEmitter } from "components/utils/EventEmitter"
import { CALLING_CONTEXT_TYPE } from "store/page/OverlayEventDetails"
import { l10n } from "store/lang/L10n"


const style = theme => ({
	recordingsSnackbar: {
		background: theme.palette.secondary.main,
		//color: theme.palette.text.primary,
		color: "#FFF !important",
		// MTVW-379: When a recording was started, the snackbar in full screen mode was positioned on top of the center player
		// controls and blocking those controls, even when the snackbar disappeared
		position: "absolute",
		top: "-150px",
		backgroundColor: theme.palette.secondary.main + "!important",
	},
	recordingsLoader: {
		position: "absolute",
		top: "50%",
		left: "50%"
	},
})

export const RecordingsManager = CreateComponent(
	style,
	false,
	true,
	forwardRef(({ classes, oEvent, buttonFunction, onEnd, isPrevMenu = false, isSingleButton = false }, ref) => {
		const eventManager = rootStore.page.Recordings.Manager.getRecordingEvent(oEvent)

		//console.debug("-----------> buttonFunction: " + buttonFunction)

		const [optionsDeleteRecording, setOptionsDeleteRecording] = useState(buttonFunction ?? null)
		const [optionsRecording, setOptionsRecording] = useState([])

		const [textSnackbar, setTextSnackbar] = useState(null)

		const context = rootStore.page.OverlayEventDetails.callingContext
		if (rootStore.page.Recordings.Manager.oRecEvent.error) {
			//console.debug("rootStore.page.Recordings.Manager.oRecEvent.error" + rootStore.page.Recordings.Manager.oRecEvent.error)
		}
		//else console.debug("NO rootStore.page.Recordings.Manager.error")
		//eventManager.handleEpisodeRecord()

		useImperativeHandle(ref, () => ({
			setTypeRecording: (recOptions) => {
				setOptionsRecording(recOptions)
			},
			setTypeDelete: (name) => {
				setOptionsDeleteRecording(name)
			}
		}))

		const onAcceptHandler = props => {
			console.warn("RecordingsManager props=%o", props)

			// MTV-3062 
			//in the case of series, the user can choose  if he wants to record one episode or the series in RecordingStartDialog
			// So is possible to define what callback call only here 
			let callbackName = props.callbackName ? props.callbackName : props.recordSeries ? "handleSerieRecord" : "handleEpisodeRecord"


			if (callbackName) {
				eventManager.setLoaderVisible(true)
				eventManager[callbackName](props).then(() => {
					eventManager.setLoaderVisible(true)
					rootStore.api
						.GetRecordingsMarkers()
						.fetchDataAsync()
						.finally(() => {
							// MTV-1776: close EventDetails when delete a recording in Aufnahmen section
							// MTV-2940: dont close if in TvLive, TvGuide, RelatedContent
							if (context === CALLING_CONTEXT_TYPE.Recording) {
								// MTVW-499
								if (callbackName === "handleEpisodeDelete" ||
									callbackName === "handleSeriesDelete" ||
									callbackName === "handleSeriesCancel") EventEmitter.dispatch("closeOverlayDialog", null)
							}
							// MTV-1736: refresh page
							rootStore.page.Recordings.IndexPage.refreshData()
							rootStore.page.Recordings.ListPage.refreshData()
							eventManager.setLoaderVisible(false)
							setTextSnackbar(`${props.snackbarText}`)
						})
				})
				setOptionsDeleteRecording(null)
				setOptionsRecording([])
			}
		}

		const onCloseSnackbar = () => {
			setTextSnackbar(null)
			if (onEnd) {
				onEnd()
			}
		}

		const onCancelHandler = () => {
			setOptionsDeleteRecording(null)
			setOptionsRecording([])
			setTextSnackbar(null)
			if (onEnd) {
				onEnd()
			}
		}

		// TODO: this code  will never run ... singlebutton will never be equal to true
		if (isSingleButton === true) {
			const buttons = eventManager.buttonsLists
			console.log("buttons=%o", buttons)
			if (buttons[RECORDING_OPTIONS.series_episode_record] || buttons[RECORDING_OPTIONS.series_record])
				return <RecordingsStartDialog title="Einzelaufnahme!!!" additionalInfo={null} onButtonHandlerA={onCancelHandler} onButtonHandlerB={onAcceptHandler} buttonTextA={l10n.commonActionCancel} buttonTextB={l10n.commonActionRecord} />
		}
		// Weiterhin aufnehmen
		// Aufnahme abbrechen

		//console.debug("optionsRecording", optionsRecording, optionsRecording.length)
		return (
			<>
				<Snackbar open={textSnackbar !== null} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={onCloseSnackbar} TransitionComponent={props => <Slide {...props} direction="left" data-test="message" />}>
					<SnackbarContent className={classes.recordingsSnackbar} message={<div>{textSnackbar}</div>}></SnackbarContent>
				</Snackbar>
				{eventManager.isLoading && <CircularProgress className={classes.recordingsLoader}></CircularProgress>}

				{/* user must choose if record only one episode or the series */}
				{optionsRecording?.length === 2 && (
					<RecordingsStartDialog
						title={l10n.commonLabelRecording}
						additionalInfo={null}
						onButtonHandlerA={onCancelHandler}
						onButtonHandlerB={onAcceptHandler}
						flagSeriesDefaultValue={null}
						// callbackName="handleEpisodeRecord"
						buttonTextA={isPrevMenu ? l10n.commonActionBack : l10n.commonActionCancel}
						buttonTextB={l10n.commonActionRecord}
						snackbarText={`${oEvent?.Title?.Name} wird aufgenommen.`}
					/>
				)}
				{optionsRecording[0] === RECORDING_OPTIONS.episode_record && (
					<RecordingsStartDialog
						title={l10n.commonLabelRecording}
						additionalInfo={null}
						onButtonHandlerA={onCancelHandler}
						onButtonHandlerB={onAcceptHandler}
						flagSeriesDefaultValue={false}
						callbackName="handleEpisodeRecord"
						buttonTextA={isPrevMenu ? l10n.commonActionBack : l10n.commonActionCancel}
						buttonTextB={l10n.commonActionRecord}
						snackbarText={`${oEvent?.Title?.Name} wird aufgenommen.`}
					/>
				)}
				{/* MTV-3062	 */}
				{/* {optionsRecording === RECORDING_OPTIONS.series_episode_record && (
					<RecordingsStartDialog
						title="Einzelaufnahme"
						additionalInfo={null}
						onButtonHandlerA={onCancelHandler}
						onButtonHandlerB={onAcceptHandler}
						buttonTextA={isPrevMenu ? l10n.commonActionBack : l10n.commonActionCancel}
						buttonTextB={l10n.commonActionRecord}
						callbackName="handleEpisodeRecord"
						snackbarText={`${oEvent?.Title?.Name} wird aufgenommen.`}
					/>
				)} */}
				{/* {optionsRecording === RECORDING_OPTIONS.series_record && (
					<RecordingsStartDialog
						title="Serienaufnahme"
						additionalInfo={null}
						onButtonHandlerA={onCancelHandler}
						onButtonHandlerB={onAcceptHandler}
						buttonTextA={isPrevMenu ? l10n.commonActionBack : l10n.commonActionCancel}
						buttonTextB={l10n.commonActionRecord}
						callbackName="handleSerieRecord"
						snackbarText={`${oEvent?.Title?.Name} wird aufgenommen.`}
					/>
				)} */}

				{optionsRecording[0] === RECORDING_OPTIONS.series_record_add && (
					<RecordingsStartDialog
						title={l10n.commonLabelRecording}
						additionalInfo={null}
						onButtonHandlerA={onCancelHandler}
						onButtonHandlerB={onAcceptHandler}
						flagSeriesDefaultValue={true}
						buttonTextA={isPrevMenu ? l10n.commonActionBack : l10n.commonActionCancel}
						buttonTextB={l10n.commonActionRecord}
						callbackName="handleSerieRecord"
						snackbarText={`${oEvent?.Title?.Name} wird aufgenommen.`}
					/>
				)}

				{optionsDeleteRecording === RECORDING_OPTIONS.episode_cancel && (
					<RecordingsConfirmDialog
						onButtonHandlerA={onCancelHandler}
						onButtonHandlerB={onAcceptHandler}
						buttonTextA="Weiterhin aufnehmen"
						buttonTextB={l10n.dialogCancelRecordingAction}
						callbackName="handleEpisodeDelete"
						snackbarText="Einzelaufnahme abgebrochen."
					>
						Soll diese Einzelaufnahme abgebrochen werden?
					</RecordingsConfirmDialog>
				)}

				{optionsDeleteRecording === RECORDING_OPTIONS.series_episode_cancel && (
					<RecordingsConfirmDialog
						onButtonHandlerA={onCancelHandler}
						onButtonHandlerB={onAcceptHandler}
						buttonTextA="Auswahl ändern"
						buttonTextB={l10n.dialogCancelRecordingAction}
						callbackName="handleEpisodeDelete"
						snackbarText="Einzelaufnahme abgebrochen."
					>
						Soll die geplante Einzelaufnahme abgebrochen werden? Die Serie wird weiterhin aufgenommen.
					</RecordingsConfirmDialog>
				)}

				{optionsDeleteRecording === RECORDING_OPTIONS.series_cancel && (
					<RecordingsConfirmDialog
						onButtonHandlerA={onCancelHandler}
						onButtonHandlerB={onAcceptHandler}
						buttonTextA="Auswahl ändern"
						buttonTextB={l10n.dialogCancelRecordingAction}
						callbackName="handleSeriesCancel"
						snackbarText="Serienaufnahme abgebrochen."
					>
						Soll diese Serienaufnahme abgebrochen werden? Bereits aufgenommene Episoden bleiben erhalten.
					</RecordingsConfirmDialog>
				)}

				{optionsDeleteRecording === RECORDING_OPTIONS.series_episode_delete && (
					<RecordingsConfirmDialog
						onButtonHandlerA={onCancelHandler}
						onButtonHandlerB={onAcceptHandler}
						buttonTextA="Auswahl ändern"
						buttonTextB={l10n.dialogDeleteRecordingAction}
						callbackName="handleEpisodeDelete"
						snackbarText="Einzelaufnahme gelöscht."
					>
						Soll diese Einzelaufnahme gelöscht werden? Die anderen Aufnahmen dieser Serie bleiben erhalten.
					</RecordingsConfirmDialog>
				)}

				{optionsDeleteRecording === RECORDING_OPTIONS.episode_delete && (
					<RecordingsConfirmDialog
						onButtonHandlerA={onCancelHandler}
						onButtonHandlerB={onAcceptHandler}
						buttonTextA="Aufnahme behalten"
						buttonTextB={l10n.dialogDeleteRecordingAction}
						callbackName="handleEpisodeDelete"
						snackbarText="Einzelaufnahme gelöscht."
					>
						Soll diese Einzelaufnahme gelöscht werden?
					</RecordingsConfirmDialog>
				)}

				{optionsDeleteRecording === RECORDING_OPTIONS.series_delete && (
					<RecordingsConfirmDialog
						onButtonHandlerA={onCancelHandler}
						onButtonHandlerB={onAcceptHandler}
						buttonTextA="Auswahl ändern"
						buttonTextB="Aufnahmen löschen"
						callbackName="handleSeriesDelete"
						snackbarText="Serienaufnahmen gelöscht."
					>
						Sollen alle aufgenommenen Episoden dieser Serienaufnahme gelöscht werden?
					</RecordingsConfirmDialog>
				)}
			</>
		)
	})
)
