// l10n status: partial
import React from "react";
import { CreateComponent } from "components/utils/CreateComponent"
import { Button, Typography, Link } from "@mui/material"
import { alpha } from "@mui/material/styles"
import { rootStore } from "store/RootStore"
import { EventEmitter } from "components/utils/EventEmitter"
import { useHistoryRouteAdd } from "components/utils/RouteUtils"
import { ROUTE_NAMES } from "site-ql/RouteConst"
//import { Title } from "store/model/traxis/Title";
import { logTraxisError } from "utils/BugsnagReporter"
import { controlsTimerHandler } from "scenes/Player/Player"
import { MicroServiceError } from "store/qlapi/MicroServiceError"
import { runInAction } from "mobx"
import { l10n } from "store/lang/L10n"


const styles = theme => ({
	ErrorPlayerInfoBackground: {
		display: "flex",
		justifyContent: "center",
		width: "78%",
		maxWidth: 660,
		backgroundColor: alpha(theme.palette.secondary.main, 0.7),
		margin: "0 auto",
		padding: 20
	},
	ErrorEventInfoBackground: {
		display: "flex",
		justifyContent: "center",
		backgroundColor: alpha(theme.palette.secondary.main, 0.7),
		margin: "0 auto",
		padding: 20
	},


	ErrorInfo: {},
	ErrorButtonsWrapper: { display: "flex", justifyContent: "center" },

	ErrorInfoButton: {
		background: theme.palette.primary.main,
		color: "white",
		"&:hover": {
			background: theme.palette.primary.hover,
		},
		fontWeight: "normal",
		margin: "20px 20px 0px 20px"
	},
	ErrorInfoWrapper: {}  // set by parent class
})


// MTVW-178 MTVW-186 added isPlayerError and management
export const ErrorInfoWrapper = CreateComponent(null, false, true, ({ classes, page, isPlayerError, children }) => {
	let onLineStyle = {}

	if (isPlayerError) {
		// MTVW-511: player.__path condition
		// MTVW-637: condition overlay isVisible intead of player.__path
		//console.debug("overlay %s, path %s", rootStore.page?.OverlayEventDetails?.isShow, page?.player?.__path)
		onLineStyle = rootStore.page.Player?.settings?.isFullScreen ? { position: "fixed", top: 0, zIndex: 100000 } :
			rootStore.page?.OverlayEventDetails?.isVisible ? { position: "fixed", zIndex: 100000 } : { position: "absolute", zIndex: 100000 }
	} else {
		onLineStyle = { zIndex: 100000, marginBottom: "0%", marginTop: "0%" }
	}
	//console.debug("ErrorInfoWrapper", isPlayerError, onLineStyle, page?.player?.__path)

	return (
		<div style={onLineStyle} className={classes.ErrorInfoWrapper + " ErrorInfoWrapperResponsive"}>
			{/*
		{rootStore.page.Player?.settings?.isFullScreen ? console.debug("FULLSCREEN") : console.debug("NOT FULLSCREEN")}
		*/}
			<div className={isPlayerError ? classes.ErrorPlayerInfoBackground : classes.ErrorEventInfoBackground} >
				<div className={classes.ErrorInfo}>{children}</div>
			</div>
		</div>
	)
})


export const ErrorMessage = CreateComponent(styles, false, true, ({ error, isPlayerError, classes, page, id }) => {

	/*
	React.useEffect(() => {
		// component did mount
		
		// callback before unmount
		return () => {
			//console.debug("UNMOUNT")
			latchedError.resetError()
		}
	}, []);

	// component did update
	React.useLayoutEffect(() => {
	});

	// component will unmount
	React.useEffect(() => {
		return () => {
			
		}
	}, []);
	*/

	const historyRouteAdd = useHistoryRouteAdd()
	// MTV-3427 added restartPlayer flag to  control the restart of the player after a message
	// MTVW-637: added stopPlayer
	const defaultInfoErrorMessage = (title, content, restartPlayer = false, stopPlayer = true) => {
		return {
			title,
			content,
			button0: {
				txt: l10n.commonActionClose,
				onClick: () => {
					if (stopPlayer) EventEmitter.dispatch("closeOverlayDialog", null)
					runInAction(() => {
						rootStore.page.Player.error = null
						if (rootStore.page.OverlayEventDetails.oEvent) rootStore.page.OverlayEventDetails.oEvent.error = null; // MTVW-186 MTVW-178
					})
					// MTV-2578: re-display player controls with default timeout so that they are also shown on iPad
					controlsTimerHandler(rootStore.page.Player)
					//MTVW-176 fixed  the problem by not changing the source.Channel.id if the error is from overlay
					//if (restartPlayer) {
					// need to check if the problem  is from player or overlay the first need to go back live the second one not
					// another solution can be: check page.isPlayerOnOverlay 
					if ((restartPlayer) && (id === "player")) {
						rootStore.page.LiveTv.handlePlayChannelAsync(rootStore.page.Player?.source?.Channel?.id)
					}
				}
			},
			// MTVW-512, MTVW-517: applyFilter, MTVW-637: stopPlayer
			applyFilter: false,
			stopPlayer: stopPlayer
		}
	}
	const errorInfoTxt = (e, page) => {
		//console.debug("errorInfoTxt %o %o", e, e instanceof MicroServiceError)
		if (e instanceof MicroServiceError) {
			// MTV-3570: TODO in case of 405 (LOOKUP instead of GET in MS) button "Schliessen" is shown
			let restartPlayer = false
			//console.debug("error detail %s", e.datail)
			// HACK: should have better solution
			if (e.detail?.indexOf("Inhalt ausserhalb des verfügbaren Zeitfensters der Replay-Funktion") > -1 || e.detail?.indexOf("Replay ist für diesen Kanal nicht verfügbar") > -1) restartPlayer = true
			if (!e.title || !e.detail) return defaultInfoErrorMessage(
				"Wiedergabe derzeit nicht möglich",//title
				`Bitte versuchen Sie es zu einem späteren Zeitpunkt.`
			)
			return defaultInfoErrorMessage(e.title, e.detail, restartPlayer)
		}

		switch (true) {
			case e.isErrorInvalidStbId === true:
				return {
					// Device not yet registered
					title: "Gerät noch nicht registriert",
					// To play content on this device, it must be connected to your customer account.
					content: "Um Inhalte auf diesem Gerät wiedergeben zu können, muss es mit Ihrem Kundenkonto verbunden sein.",
					button: {
						// Register device
						txt: "Gerät registrieren",
						onClick: () => page.setRegisterDeviceAsync()
					}
				}
			case e.isErrorPlayer_DRM === true:
				// Playback is not possible on this device
				// You can currently watch this channel only on your mobile devices and your Quickline TV Box.
				return defaultInfoErrorMessage(
					"Wiedergabe auf diesem Gerät nicht möglich", //title
					"Diesen Sender können Sie zurzeit nur auf der iOS- und Android-App oder auf Ihrer Quickline TV Box anschauen."//content
				)
			//MTV-1729
			// return {
			// 	// Playback is not possible on this device
			// 	title: "Wiedergabe auf diesem Gerät nicht möglich",
			// 	// You can currently watch this channel only on your mobile devices and your Quickline TV Box.
			// 	content: "Diesen Sender können Sie zurzeit nur auf der iOS- und Android-App oder auf Ihrer Quickline TV Box anschauen."
			// }
			case e.isErrorNotAuthorized_GeoBlock === true:
			case e.isErrorNotAuthorized_Replay_GeoBlock === true:
				// Playback outside Switzerland not possible
				// Abroad, you can not view content with the Quickline TV apps. We ask for your understanding.
				return defaultInfoErrorMessage(
					"Wiedergabe ausserhalb der Schweiz oder ausserhalb des Quickline-Netzes nicht möglich",//title
					"Im Ausland können Sie keine Inhalte mit den Quickline TV Apps ansehen. Zusatzpakete und Sprachpakete können nur innerhalb des Quickline-Netzes abgespielt werden. Wir bitten um Verständnis."//content
				)
			//MTV-1729
			// return {
			// 	// Playback outside Switzerland not possible
			// 	title: "Wiedergabe ausserhalb der Schweiz nicht möglich",
			// 	// Abroad, you can not view content with the Quickline TV apps. We ask for your understanding.
			// 	content: "Im Ausland können Sie keine Inhalte mit den Quickline TV Apps ansehen. Wir bitten um Verständnis."
			// }
			case e.isErrorNotAuthorized_Channel === true:
			case e.message?.indexOf("NetworkRecordingEntitlementState NotEntitled") > -1:
				return {
					// You did not subscribe to this channel.
					title: "Sie haben diesen Sender nicht abonniert.",
					// Under you can easily order this channel package.
					content: (
						<>
							Das Zusatzpaket My Sports Pro sowie My Sports Tagestickets können einfach unter
							{" "}
							<Link underline="hover" href={import.meta.env.VITE_URL_TV_PACKAGES} target="_blank">
								{import.meta.env.VITE_URL_TV_PACKAGES_NAME}
							</Link>{" "}
							gekauft werden und auf Quickline TV Web angeschaut werden.
						</>
					),
					content1: (
						<>
							Alle anderen kostenpflichtigen Sender eines Zusatz- und Sprachpaketes können aktuell nicht auf Quickline TV Web sondern nur auf Ihrer Quickline TV Box abgespielt werden.
						</>
					),
					button: {
						txt: import.meta.env.VITE_URL_TV_PACKAGES_NAME,
						onClick: () => window.open(import.meta.env.VITE_URL_TV_PACKAGES, "_blank")
					}
				}
			case e.isErrorRecordingNotAuthorized_Channel === true:
				return {
					// You did not subscribe to this channel.
					title: "Aufnahme nicht möglich. Sie haben diesen Sender nicht abonniert.",
					// Under you can easily order this channel package.
					content: (
						<>
							Das Zusatzpaket My Sports Pro sowie My Sports Tagestickets können einfach unter
							{" "}
							<Link underline="hover" href={import.meta.env.VITE_URL_TV_PACKAGES} target="_blank">
								{import.meta.env.VITE_URL_TV_PACKAGES_NAME}
							</Link>{" "}
							gekauft werden und auf Quickline TV Web angeschaut werden.
						</>
					),
					content1: (
						<>
							Alle anderen kostenpflichtigen Sender eines Zusatz- und Sprachpaketes können aktuell nicht auf Quickline TV Web sondern nur auf Ihrer Quickline TV Box abgespielt werden.
						</>
					),
					button: {
						txt: import.meta.env.VITE_URL_TV_PACKAGES_NAME,
						onClick: () => window.open(import.meta.env.VITE_URL_TV_PACKAGES, "_blank")
					}
				}
			case e.isErrorRecordedAlready === true:
				// Event or Series already recorded
				return defaultInfoErrorMessage(
					"Aufnahme nicht möglich",//title
					"Die Einzelaufnahme oder Serienaufnahme wurde bereits aufgezeichnet.",//content
					false, //restartPlayer
					// MTVW-637
					false //stopPlayer
				)

			// return {
			// 	// Event or Series already recorded
			// 	title: "Aufnahme nicht möglich",
			// 	// 
			// 	content: "Die Einzelaufnahme oder Serienaufnahme wurde bereits aufgezeichnet."
			// }
			case e.isErrorMaximumMutationReached === true:
				return {
					// Playback is not possible on this device
					title: "Wiedergabe auf diesem Gerät nicht möglich",
					// You have reached the maximum number of registered devices. Under you can manage your devices
					content: (
						<>
							Sie haben die maximale Anzahl an registrierten Geräten erreicht. Unter{" "}
							<Link underline="hover" href={import.meta.env.VITE_URL_COCKPIT_OTT_DEVICES} target="_blank">
								{import.meta.env.VITE_URL_COCKPIT_NAME}
							</Link>{" "}
							können Sie Ihre Geräte verwalten.
						</>
					),
					button: {
						txt: import.meta.env.VITE_URL_COCKPIT_NAME,
						onClick: () => window.open(import.meta.env.VITE_URL_COCKPIT_OTT_DEVICES, "_blank")
					}
				}
			case e.isErrorNotMutationLeft === true:
				return {
					// Playback is not possible on this device
					title: "Wiedergabe auf diesem Gerät nicht möglich",
					// You have reached the maximum number of available mutations. Under you can manage your devices.
					content: (
						<>
							Sie haben die maximale Anzahl an verfügbaren Mutationen erreicht. Unter{" "}
							<Link underline="hover" href={import.meta.env.VITE_URL_COCKPIT_OTT_DEVICES} target="_blank">
								{import.meta.env.VITE_URL_COCKPIT_NAME}
							</Link>{" "}
							können Sie Ihre Geräte verwalten.
						</>
					),
					button: {
						txt: import.meta.env.VITE_URL_COCKPIT_NAME,
						onClick: () => window.open(import.meta.env.VITE_URL_COCKPIT_OTT_DEVICES, "_blank")
					}
				}
			case e.isErrorNotAuthorized_RollingBuffer_OutsidePlayableWindow === true:
				// ERROR! Replay function not available for the selected period.

				return defaultInfoErrorMessage(
					l10n.errorGenericTitle,//title
					"Replay Funktion für den gewählten Zeitraum nicht verfügbar.",//content
					true //MTV-3427 added for restart in Live mode after the error message
				)
			// MTV-1729			
			// return {
			// 	// Error!
			// 	title: l10n.errorGenericTitle,
			// 	// Replay function not available for the selected period.
			// 	content: "Replay Funktion für den gewählten Zeitraum nicht verfügbar."
			// }
			case e.isErrorRecordingQuotaExceeded === true:
				return {
					// Not enough available storage
					title: "Aufnahmespeicher voll",
					content: (
						<>
							Sie haben Ihre maximale Aufnahmenkapazität erreicht. Um weitere Sendungen aufzunehmen, müssen Sie bestehende Aufnahmen löschen
						</>
					),
					button0: {
						txt: l10n.commonActionCancel,
						onClick: () => {
							runInAction(() => {
								rootStore.page.Player.error = null
								rootStore.page.OverlayEventDetails.oEvent.error = null; //MTVW-186 MTVW-178
							})
						}
					},
					button: {
						txt: "Aufnahmen verwalten",
						onClick: () => {
							runInAction(() => {
								rootStore.page.Player.error = null
								rootStore.page.OverlayEventDetails.oEvent.error = null;//MTVW-186 MTVW-178
								EventEmitter.dispatch("closeOverlayDialog", null);
								//window.open("/#/recordings", "_self")
								// MTVW-637: without delay, when going back from recordings to the current tab, a warning
								// ("Hash history cannot PUSH the same path; a new entry will not be added to the history stack")
								// would be generated and navigation would fail
								setTimeout(() => {
									historyRouteAdd(ROUTE_NAMES.Recordings)
								}, 100)
							})
						}
					},
					// MTVW-637
					restartPlayer: false,
					stopPlayer: false
				}
			case e.isErrorRecordingManagementNotEntitled === true:
			case e.isErrorRestServiceRecordedNotFound === true:
				// Error!
				// Recording function not available for the selected period.
				return defaultInfoErrorMessage(
					l10n.errorGenericTitle,//title
					"Aufnahme Funktion für den gewählten Zeitraum nicht verfügbar.",//content
					true //MTV-3427 added for restart in Live mode after the error message
				)
			case e.isErrorRestServiceResourceNotFound === true:
				// Error!
				// event id not found .
				return defaultInfoErrorMessage(
					l10n.errorGenericTitle,//title
					"Das Programm hat sich geändert und wird in Kürze aktualisiert.")

			default:
				{
					let msg = e.message !== null ? e.message : ""

					return defaultInfoErrorMessage(
						"Wiedergabe derzeit nicht möglich", //title
						`Bitte versuchen Sie es zu einem späteren Zeitpunkt.\n [${msg}]`, //content added [] for filter for user
						// HACK: should have better solution
						msg.indexOf("Schlafzustand") > 0 ? true : false
					)
				}
		}
	}

	//console.debug("error %o id %s", error, id)
	//console.debug("id %s overlay visible %s", id, rootStore.page.OverlayEventDetails.isVisible)
	// Prevent double message when in LiveTV fullscreen and pressing the i icon followed by EINZELAUFNAHME
	//console.debug("err %o, id %s, vis %s", error, id, rootStore.page.OverlayEventDetails.isVisible)
	if ((error !== null) && ((id === "player" && !rootStore.page.OverlayEventDetails.isVisible) ||
		(id === "overlay" && rootStore.page.OverlayEventDetails.isVisible))) {
		//console.debug("error message", id)
		//rootStore.page.Player.player.setIsPosterVisible(true)
		// MTVW-512, MTVW-517: applyFilter, MTVW-637: stopPlayer
		const { title, content, content1 = null, button0 = null, button = null, applyFilter = true, stopPlayer = true } = errorInfoTxt(error, page)

		// hide player controls
		rootStore.page.Player.setMouseOver(false)
		if (stopPlayer) {
			// MTVW-444, MTVW-788, MTVW-787: replaced with stopInstance for vjs
			if (import.meta.env.VITE_PLAYER === "vo") {
				rootStore.page.Player.player.setStop()
				// MTVW-633
				rootStore.page.Player.player.setClose()
				//rootStore.page.Player.player.setRefElemContainer(null)
			}
			else if (import.meta.env.VITE_PLAYER === "vjs") {
				rootStore.page.Player.player.stopInstance()
			}
		}

		rootStore.page.Player.player.setFullScreen(false)
		// MTVW-727b
		rootStore.page.Player.player.showPiP(false)
		logTraxisError(error, {
			"Title": title,
			"Content": content?.props?.children ? content?.props?.children[0] : content,
			"Content1": content1?.props?.children ? content1.props.children : content1
		})

		// MTV-2308
		// MTVW-512, MTVW-517: applyFilter
		// in some error cases, content contains a react component and not a simple string
		const contentFiltered = typeof content === "string" && applyFilter ? content.replace(/\s*\[.*?\]\s*/g, '') : content
		//console.debug("contentFiltered", contentFiltered, content)
		return (
			<ErrorInfoWrapper classes={classes} page={page} isPlayerError={isPlayerError}>
				{title !== null && (
					<Typography variant="h5" paragraph={true} data-test="error_message_title">
						{title}
					</Typography>
				)}
				{/* text with newline(s), e.g. for geo location error */}
				<Typography variant="body2" paragraph={true} data-test="error_message_content" style={{ whiteSpace: "pre-line" }} >
					{contentFiltered}
				</Typography>
				<div className={classes.ErrorButtonsWrapper}>
					{button0 !== null && (
						<Button className={classes.ErrorInfoButton} data-test="error_info_button0"
							onClick={button0.onClick}>
							{button0.txt}
						</Button>
					)}
					{button !== null && (
						<Button className={classes.ErrorInfoButton} data-test="error_info_button"
							onClick={button.onClick}>
							{button.txt}
						</Button>
					)}
				</div>
				{content1 !== null && (
					<div><p>
						<Typography variant="body2" paragraph={true}>
							{content1}
						</Typography>
					</p></div>
				)}
			</ErrorInfoWrapper>
		)
	}
	// MTV-3583: React 17 requires a value to be returned
	else return null
})
