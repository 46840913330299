// l10n status: done
import { Link, Typography } from "@mui/material"
import React, { useLayoutEffect } from "react"
import IconButton from "@mui/material/IconButton"

// import { relatedMovies } from "./../../../data/RelatedMovies"
import { CreateComponent } from "components/utils/CreateComponent"
import { rootStore } from "store/RootStore"
import tvImg from "img/relatedContent/fallback_image_tv.png"
import seriesImg from "img/relatedContent/fallback_image_tv_series.png"
//import seriesLogo from "img/series-folder.svg"
import serieLogo from "img/icons/series.svg"
import { logResourceLoadFailure } from "utils/BugsnagReporter"
import { CALLING_CONTEXT_TYPE } from "store/page/OverlayEventDetails"
import Play from "img/player/play.svg"
// import PlayHover from "img/player/play-hover.svg"
//import { EventComponent } from "../../Overlay/components/EventComponent"

import "./slideRelatedContent.css"
//import { Img } from "components/Img"
import { computed } from "mobx"
import { l10n } from "store/lang/L10n"


const styles = theme => ({
	relatedMoviesGridList: {
		// width: "100%",
		// display: "flex",
		// flexFlow: "row wrap"
		display: "grid",
		//MTV-3492 css mobile first look relatedMoviesGridListResponsive
		gridTemplateColumns: "repeat(auto-fill, minmax(50%, 1fr))",
		gridGap: "16px",
	},
	relatedMoviesGridItem: {
		// marginBottom: 16,
		// width: 296,
		display: "flex",
		flexWrap: "wrap",
		backgroundColor: " #141313",
		color: "rgba(255, 255, 255, 0.6)",
		// margin: 5,
		// flex: "1 1 auto",
		cursor: "pointer"

	},
	relatedMoviesChannel: {
		color: "rgba(255, 255, 255, 0.6)",
		letterSpacing: 2,
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		padding: "4px 4px 0px 8px",
	},
	relatedMoviesTitle: {
		height: 60,
		overflow: "hidden",
		fontWeight: 600,
		color: "rgba(255, 255, 255)",
		flex: "1 1 100%",
		padding: "4px 4px 0px 8px",
	},
	relatedMoviesDetails: {
		height: 19, flex: "1 1 100%",
		padding: "0px 8px 8px 8px",
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
	},
	relatedBottomContainer: {
		display: "flex",
		flex: "1 1 100%",
		flexDirection: "row"
	},
	relatedMoviesDateOrEpisodes: {
		flex: "1 1 100%", padding: "0px 8px 8px 8px",
	},
	moviePlayButton: {
		height: 48,
		width: 48,
		// top: "35%",
		// textAlign:"center",		// left:35%
		position: "absolute !important",
		color: theme.palette.text.primary,
		cursor: "pointer",
		zIndex: 100,
		backgroundPosition: "center",
		backgroundSize: "48px 48px",
		background: `url("${Play}") no-repeat`,
		// "&:hover": {
		// 	background: `url("${PlayHover}") no-repeat`,
		// 	backgroundPosition: "center",
		// 	// transition: ".5s  ease-in-out",
		// 	// WebkitTransition: ".5s  ease-in-out",
		// 	backgroundSize: "120px 120px",
		// 	"webkit-background-size": "120px 120px",
		// },


		//WebkitBackgroundSize": "96px 96px",
		// transition: "5.s  ease-in-out",
		// WebkitTransition: ".5s ",

	},
	// relatedContentTitleButtonBack: { padding: 0 },
	relatedMoviesGridTextWrapper: { padding: "5px 5px 15px", backgroundColor: theme.palette.secondary.main },
	relatedContentImg: { position: "relative", display: "flex", alignItems: "center", justifyContent: "center" },
	relatedMoviesPoster: { display: "block", maxWidth: "100%", aspectRatio: 16 / 9 },
	RecordingIndicatorIcon: { height: 11, marginRight: 6, marginTop: 4, float: "left" },
	SerieTypeLogo: { display: "block", position: "relative", right: 10, width: 24, bottom: 8 },
})


/** MTV-2808
 * if the player plays in overlay (from tvGuide and not tvLive)
 * the overlay must be reset if you change the event
 * * @ resetOverlay is the function that resets the state of OverlayEventDetails
 * This parameter is not used by TvLive
 
 **/

export const EpgChannelRelatedContent = CreateComponent(styles, false, true, ({ classes, oEventData, page, callingContext = CALLING_CONTEXT_TYPE.keepOldContext, resetOverlay = () => { } }) => {
	//use to open new overlayDetails
	const pageOverlay = rootStore.page.OverlayEventDetails

	useLayoutEffect(() => {
		if (oEventData?.id) {
			//console.debug("loading related content id %s", oEventData.id)
			page.loadRelatedContent(oEventData)
		}
	}, [oEventData, page])

	// MTV-3737 if there are related content then show with animation
	// MTV-3842 count only valid related content
	let countRelatedContent = 0
	// eslint-disable-next-line array-callback-return
	void page.relatedContent?.map((item) => {
		//console.debug("item", item, item.data)
		if (item.data) {
			countRelatedContent++
		}
	})
	// MTV-3842 check whether there are 3 or 6 related contents (1 or 2 lines)
	// const classRelatedContentAnimation = page.relatedContent.length > 0
	const classRelatedContentAnimation = countRelatedContent > 0 ?
		countRelatedContent <= 3 ? "slideRelatedContent slideRelatedContentHalf" : "slideRelatedContent slideRelatedContentFull"
		: ""
	// MTV-3583: React 17 requires a value to be returned
	//console.debug("page.isReady", page.isReady, countRelatedContent, classRelatedContentAnimation)
	return !page.isReady ? null : (
		<div className={`${classRelatedContentAnimation}`}>
			<div>
				{page.relatedContent?.length > 0 ?
					<Typography variant="h6" className="relatedMoviesHeader">
						<Link underline="hover">{l10n.featureDetailsRecommendations.toUpperCase()}</Link>
					</Typography>
					:
					null}
			</div>
			<div className={classes.relatedMoviesGridList + " relatedMoviesGridListResponsive"} >
				{page.relatedContent?.map((item, index) => {
					const relatedItem = item.data
					const relatedTitle = page.title(item)
					const nbEpisodes = page.episodeCount(item)
					const relatedEpisodes = nbEpisodes > 1 ? l10n.featureRecordingsNumberOfEpisodes.format(nbEpisodes) : l10n.featureRecordingsNumberSingleEpisode.format(nbEpisodes)
					//if (page.episodeName(item)) console.warn("episode", page.episodeName(item))

					let relatedDetails = ""
					if (page.episodeName(item)) {
						let episode = page.episode(item) || ""
						let season = page.season(item) || ""
						episode = episode === "" ? "" : `E:${episode}`
						season = season === "" ? "" : `S:${season}`
						relatedDetails = `${season} ${episode} ${page.episodeName(item)} `
					}
					//console.debug("item is", item.type, item, index, page.id(item), page.title(item), page.eventType(item))

					// GT12: MTVW-460, MTVW-502: Channels MS flags
					// seriesId: relatedTitle?.SeriesCollection?.Series[0]?.id
					const showPlayButton = () => {
						let isReplayAllowed = computed(() => rootStore.page.MsEpg?.isReplayAllowed(page.channelId(item), page.startTs(item), page.endTs(item))).get()
						// MTVW-629: commented 2nd parameter, recheck if needed
						if (rootStore.page.Recordings.Manager.getMarker(page.id(item)/*, relatedTitle?.SeriesCollection?.Series[0]?.id*/)?.isComplete) isReplayAllowed = true
						//console.debug("isReplayAllowed", isReplayAllowed, page.channelId(item), page.startTs(item), page.endTs(item))
						/*
						return (
							(page.eventType(item) !== "n") ?
								((((page.eventType(item)) &&
									// GT12
									//((((relatedItem.getCurrentEventType === "p") && !relatedItem.Channels.Channel[0].IsOnRecordingOptinList)
									!isReplayAllowed)
									? false
									: true))
								: false)
						*/
						return page.eventType(item) && page.eventType(item) !== "n" && isReplayAllowed
					}

					return (
						<div key={index + page.id(item)} className={classes.relatedMoviesGridItem} data-test={"related_recommendation"} onClick={(e) => {
							if (item.type === "event") {
								if (pageOverlay.isVisible) {
									// MTVW-624: combined activityStack for related content and search
									pageOverlay.pushActivity({ type: 'related', id: oEventData?.id })
								}
								pageOverlay.setEpgEventById(page.id(item)).then(res => {
									pageOverlay.setShow(false)
									//MTV-2808 if the player in overlay is playing it must be closed
									resetOverlay()
									//MTVW-490: added setComponentType
									pageOverlay.setEpgEventById(page.id(item), false).then(res => {
										pageOverlay.setComponentType('event').setShow(true, callingContext)
									})
								})
							}
							else if (item.type === "series") {
								// launch search, see OverlayEventDetailsComponent
								if (pageOverlay.isVisible) {
									// MTVW-624: combined activityStack for related content and search
									pageOverlay.pushActivity({ type: 'related', id: oEventData?.id })
									//pageOverlay.pushActivity({ type: page.CATEGORY_SERIES, idSerie: page.id(item) })
								}
								e.stopPropagation()
								pageOverlay.setComponentType(rootStore.page.Search.CATEGORY_SERIES).setIdSerie(page.id(item)).setShow(true, /*callingContext*/ CALLING_CONTEXT_TYPE.keepOldContext)
							}
						}}>
							<div className={classes.relatedContentImg}>
								<img src={page.poster(item, tvImg, seriesImg)}
									alt={page.title(item)}
									className={classes.relatedMoviesPoster}
									onError={(e) => {
										e.stopPropagation()
										logResourceLoadFailure(page.poster(item, tvImg, seriesImg), e)
										e.target.src = page.episodeName(item) ? seriesImg : tvImg
									}} />

								{showPlayButton() && (
									<IconButton
										className={classes.moviePlayButton}
										data-test="play_button_related_content"
										onClick={async (e) => {
											// MTVW-629: autoplay
											e.stopPropagation()
											//console.debug("rootStore.page.Player", rootStore.page.Player.player)
											rootStore.page.Player.player.setIsPosterVisible(true, "autoplay button", true)
											// necessary to move dialog on top
											//await pageOverlay.setEpgEventById(page.id(item))
											//pageOverlay.setShow(false)
											//MTV-2808 if the player in overlay is playing it must be closed
											// necessary to move dialog on top
											resetOverlay()
											// MTVW-629: hide overlay if liveTv
											await pageOverlay.setEpgEventById(page.id(item), true)
											pageOverlay.setComponentType('event').setShow(!rootStore.page.Player.playerLiveTvVo.isPlayerActive, callingContext)
										}}
									>
									</IconButton>
								)}

							</div>
							<div className={classes.relatedMoviesChannel} data-test="recommendation_channel">
								{page.channelName(item)}
							</div>
							<div className={classes.relatedMoviesTitle} data-test="recommendation_title">
								{page.title(item)}
							</div>
							<div className={classes.relatedMoviesDetails}>
								{relatedDetails}
							</div>
							<div className={classes.relatedBottomContainer}>
								<div className={classes.relatedMoviesDateOrEpisodes}>
									{item.type === "event" && (
										page.date(item)
									)}
									{item.type === "series" && (
										relatedEpisodes
									)}
								</div>
								{item.type === "series" && (
									<img src={serieLogo} className={classes.SerieTypeLogo} alt="Serie" />
								)}
							</div>
						</div>
					)
				})
				}
			</div>
		</div>
	)
})
