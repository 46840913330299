import Bugsnag from '@bugsnag/js'
//import { ErrorBase } from "store/ErrorBase"
import { TraxisError } from "store/api/TraxisError"
import { MicroServiceError } from "store/qlapi/MicroServiceError"

export function logResourceLoadFailure(url, syntheticEvent) {
	/* disable, it doesn't provide any value
	Bugsnag.notify(new Error("Resource load failure: " + url), (event) => {
		event.errorClass = "ResourceEvent"
		event.errors[0].errorClass = "ResourceEvent"
		event.request.url = url
	})
	*/
}

export function logTraxisError(error, displayMetadata) {
	if (error instanceof TraxisError) {
		Bugsnag.notify(new Error(error?.e?.message), (event) => {
			event.errorClass = "TraxisEvent"
			event.errors[0].errorClass = "TraxisEvent"
			event.request.url = error?.OriginalUri
			event.addMetadata(
				"Traxis",
				{
					"InternalError": error?.InternalError,
					"Message": error?.Message,
					"AdditinalInfo": error?.AdditionalInfo,
					"OriginalUri": error?.OriginalUri,
					"Timestamp": error?.Timestamp,
					"Source": error?.Source
				}
			)
			event.addMetadata(
				"Display", displayMetadata
			)
		})
	}
	else if (error instanceof MicroServiceError) {
		let codes = ""
		error.codes.forEach(function (value) {
			codes += ", " + value
		})
		//console.debug("log MicroServiceError %o, %o", error, displayMetadata)
		Bugsnag.notify(new Error(error?.e + codes), (event) => {
			event.errorClass = "MicroServiceEvent"
			event.errors[0].errorClass = "MicroServiceEvent"
			event.request.url = error?.url
			event.addMetadata(
				"MicroService",
				{
					"Action": error?.action,
					"Detail": error?.detail,
					"Hint": error?.hint,
					"Internal": error?.internal,
					"Source:": error?.source,
					"Status": error?.status,
					"Title": error?.title,
					"RequestId": error?.requestId,
					"Url": error?.url,
					"TimeoutDuration": error?.timeoutDuration
				}
			)
			event.addMetadata(
				"Display", displayMetadata
			)
		})
	}
	else {
		// ErrorBase
		Bugsnag.notify(new Error(error?.message), (event) => {
			event.errorClass = "ErrorBaseEvent"
			event.errors[0].errorClass = "ErrorBaseEvent"
			event.addMetadata(
				"Display", displayMetadata
			)
		})
	}
}

export function logPlayerVoError(error) {
	Bugsnag.notify(new Error(error?.message), (event) => {
		event.errorClass = "PlayerVoEvent"
		event.errors[0].errorClass = "PlayerVoEvent"
		event.addMetadata(
			"PlayerVo",
			{
				"Message": error?.message,
				"Code": error?.code,
			}
		)
	})
}

export function logSsoError(error, location) {
	Bugsnag.notify(new Error(error?.e?.message), (event) => {
		event.errorClass = "SsoEvent"
		event.errors[0].errorClass = "SsoEvent"
		event.request.url = error?.e?.config?.url + " data " + error?.e?.config?.data
		event.addMetadata(
			"Sso",
			{
				"Description": error?.error_description,
				"Location": location
			}
		)
	})
}
