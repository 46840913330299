/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-09
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-10
 */

import { observable, makeObservable, computed } from "mobx"
import { ClassAbstract } from "store/ClassTools"
import { rootStore } from "store/RootStore"

export class PlayerSettings extends ClassAbstract {
	// MTVW-96: persistent volume and mute state
	_volume = import.meta.env.VITE_DEFAULT_PLAYER_VOLUME ?? 60
	_isMuted = false
	isFullScreen = false
	jumpFwdSec = 10
	jumpRwdSec = 10
	updateTimerId = null


	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			// MTVW-96
			_volume: observable,
			volume: computed,
			_isMuted: observable,
			isMuted: computed,
			isFullScreen: observable,
			jumpFwdSec: observable,
			jumpRwdSec: observable,
		})
		// MTVW-96
		this._volume = rootStore.sso.browserStore.getVolume()
		this._isMuted = rootStore.sso.browserStore.getMuted()
		//console.debug("initial volume", this._volume, this._isMuted)
	}

	get volume() {
		//return rootStore.sso.browserStore.getVolume()
		return this._volume
	}

	set volume(value) {
		this._volume = value
		if (this.updateTimerId) clearTimeout(this.updateTimerId)
		this.updateTimerId = setTimeout(() => {
			rootStore.sso.browserStore.setVolume(value)
		}, 200)
	}

	get isMuted() {
		return this._isMuted
	}

	set isMuted(value) {
		this._isMuted = value
		rootStore.sso.browserStore.setMuted(value)
	}
}
