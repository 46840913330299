// MTV-577: removed files StreamPlaylistAbstract, CreatePlaylistAbstract, CreateChannelPlaylist, CreateContentPlaylist, CretateRollingBufferPlaylist  
import { ErrorBase } from "store/ErrorBase"
import { moment } from "store/util/moment"
import { flow, runInAction } from "mobx"
//import { MicroServiceError } from "store/qlapi/MicroServiceError"
import { rootStore } from "store/RootStore"
import { TimeoutError } from "store/qlapi/TimeoutError"
import { timeNow, sleep } from "utils/Utils"
import { EventEmitter } from "components/utils/EventEmitter"
import { TIME_FORMAT_BACKEND } from "store/model/Time"
import { Mutex } from 'async-mutex'

export class StreamPlayList {
	// TODO joachim: add example MediaService reply
	// json structure returned from MediaLookupService
	StreamInfo = null
	// ID for the stream
	StreamID = null
	// set by MediaLookupService
	mediaService = null
	// MTVW-788, MTVW-787: sessionIdsMap, savedSession, mutex
	static sessionIdsMap = new Map()
	static savedSessions = []	// empty array for inital cleanupSessions, later null
	// MTVW-795: mutex for proper synchronization and session / keep alive handling
	static mutex = new Mutex()
	// MTVW-795
	static startup = true
	player = null
	// interval timer id, only 1 active stream
	// MTVW-788, MTVW-787: not static anymore
	_keepAliveId = null
	// MTVW-589: background timer to check renew delegation (in case we get control in the background again),
	// it's not guaranteed that it will be always called
	// MTVW-788, MTVW-787: not static anymore
	_bgCheckerId = null
	// MTVW-613: global for proper removal of event listener
	// MTVW-788, MTVW-787: not static anymore
	boundVisibilityHandler = null
	// MTVW-613: global
	currentVisibility = "visible" //document.visibilityState
	lastRenewal = null

	// MTVW-725
	validTimeoutId = null

	constructor(parent, path) {
		//console.debug("%s: new StreamPlayList", timeNow(), parent, path, this)
		// MTVW-613: Stop keepalive for previous stream in any case, handled in startKeepAlive()
		//this._keepAliveId = null
		//this._bgCheckerId = null
		//console.debug("new StreamPlayList", StreamPlayList.sessionIdsMap)
	}

	static {
		window.addEventListener('beforeunload', (event) => {
			//event.preventDefault()
			// We can't do much in 'beforeunload' event, asynchronously deleting sessison, wouldn't work,
			// therefore save the sessions in the session storage, so that they can be deleted after the browser is reloaded
			const sessions = []
			StreamPlayList.sessionIdsMap.forEach((value, key) => {
				//console.debug("beforeUnload val %o, key %o", value.streamPlayList.sessionId, key)
				sessions.push(value.streamPlayList.sessionId)
			})
			sessionStorage.setItem("autosave", JSON.stringify(sessions))
		})

		// check if we have saved sessions that should be deleted
		StreamPlayList.savedSessions = JSON.parse(sessionStorage.getItem("autosave"))
	}

	//setStreamInfo(streamInfo, streamID, mediaService, player) {
	setStreamInfo = flow(function* (streamInfo, streamID, mediaService, player) {
		this.StreamInfo = streamInfo
		this.StreamID = streamID
		this.mediaService = mediaService
		// MTVW-788, MTVW-787: sessionIdsMap
		this.player = player
		let release = null
		try {
			// session cleanup it the app is started
			yield this.cleanupSessions()

			// MTVW-795: Use sessionId instead of player.__path
			if (StreamPlayList.sessionIdsMap.has(this.sessionId)) {
				//const info = StreamPlayList.sessionIdsMap.get(this.sessionId)
				console.warn("sessionId exists")
			}
			console.debug("setStreamInfo setting", this.sessionId, this)
			release = yield StreamPlayList.mutex.acquire()
			StreamPlayList.sessionIdsMap.set(this.sessionId, {
				streamPlayList: this,
			})
		}
		finally {
			// release Mutex
			if (release) release()
		}
		console.debug("setStreamInfo", StreamPlayList.sessionIdsMap)
	})

	get getStreamId() {
		if (this.StreamID) {
			return this.StreamID
		}
		throw ErrorBase.CreateError(["system"], null, this, "stream")
	}

	get getStreamUrl() {
		if (this.StreamInfo) {
			console.info("StreamPlayList.getStreamUrl: type=%o begin=%o eventStart=%o", this.eventType, this.begin, this.eventStart)

			// TODO also check for "live-replay" type?
			if (this.seekable || !this.eventStart) {
				return this.StreamInfo.stream_url
			}
		}
		throw ErrorBase.CreateError(["system"], null, this, "stream")
	}

	get _offset() {
		//return (this.StreamInfo.rstv?.time_offset || 0)                  // anvato live streams have no rstv
		// TOCHECK case play recording when not yet finished. Should we return 1 in this case, otherwise 0?
		let result = this.StreamInfo.buffer_start ? moment(this.StreamInfo.buffer_start).valueOf() : 0
		return result
	}

	get begin() {
		//return this._offset + (this.StreamInfo.rstv?.begin || 0)         // anvato live streams have no rstv
		// MTVW-543
		let result = this.StreamInfo.buffer_start ? moment(this.StreamInfo.buffer_start).valueOf() : 0
		// MTVW-648: workaround, buffer_start should be < event_start
		if (result >= this.eventStart) result = this.eventStart - 200
		return result
	}

	get end() {
		//return this._offset + (this.StreamInfo.rstv?.end || 0)           // anvato live streams have no rstv
		// MTVW-543
		return this.StreamInfo.buffer_end ? moment(this.StreamInfo.buffer_end).valueOf() : 0
	}

	get seekable() {
		//return this.StreamInfo.rstv?.seekable || false                   // anvato live streams have no rstv
		return this.streamType !== "live"
	}

	get eventStart() {
		// this property applies positioning after disconnect / background state
		//return this.begin + (this.StreamInfo.rstv?.event_offset || 0)    // anvato live streams have no rstv
		// MTVW-543
		let result = this.StreamInfo.event_start ? moment(this.StreamInfo.event_start).valueOf() : 0
		//console.debug("result = %o, streamInfo %o", result, this.StreamInfo)

		// TODO: Anvato streams are OBSOLETE. Below condition cna be removed
		// TODO: It makes sense to use event_prefix in recordings since it's not guaranteed that
		// buffer_start corresponds to event_start - event_prefix.
		if (this.streamType === "recording" && result > 0) {
			if (result - moment(this.StreamInfo.buffer_start).unix() === moment.duration(this.StreamInfo.event_prefix).asSeconds()) {
				// initial (minus) position with event_prefix active
				result = 0
			}
			//console.debug("recording offset %s", result - moment(this.StreamInfo.buffer_start).valueOf())
		}
		return result
	}

	get streamType() {
		return this.StreamInfo?.event_type
	}

	get keepAliveUrl() {
		return this.StreamInfo?.keep_alive_url
	}

	get keepAliveInterval() {
		let interval = moment.duration(this.StreamInfo.keep_alive_interval).seconds()
		return interval > 0 ? interval : 20
	}

	get sessionId() {
		return this.StreamInfo?.session_id
	}

	// MTVW-578 PoC
	get getDrmScheme() {
		if (this.StreamInfo) {
			console.debug("playlist drmScheme", this.StreamInfo?.drm_scheme)
			return this.StreamInfo?.drm_scheme
		}
		throw ErrorBase.CreateError(["system"], null, this, "stream")
	}

	get getStreamUrlLicense() {
		if (this.StreamInfo) {
			console.debug("playlist streamUrlLicense", this.StreamInfo?.key_request_url)
			return this.StreamInfo?.key_request_url
		}
		throw ErrorBase.CreateError(["system"], null, this, "stream")
	}

	get getStreamUrlCertificate() {
		if (this.StreamInfo) {
			console.debug("playlist streamUrlCertificate", this.StreamInfo?.cert_url)
			return this.StreamInfo?.cert_url
		}
		throw ErrorBase.CreateError(["system"], null, this, "stream")
	}

	// MTVW-725
	get validTillDuration() {
		const till = this.StreamInfo.valid_till ? moment(this.StreamInfo.valid_till).utc().valueOf() : 0
		const duration = till - moment().utc().valueOf()
		console.debug("validTill: %s, now: %s, %s sec", moment(till).utc().format(TIME_FORMAT_BACKEND), moment().utc().format(TIME_FORMAT_BACKEND), duration / 1000)
		return duration
	}

	_playerError = (error) => {
		console.debug("%s: _playerError", timeNow(), this._bgCheckerId, error)
		if (this._bgCheckerId) {
			this._handleKeepAliveError(new TimeoutError())
		}
	}

	//_wakeup(event) { //= flow(function* (event) {
	// Use arrow function for proper this binding. Using the bind method would result in 'native' code
	// and EventEmitter.release can not detect if the function is already subscribed
	_wakeup = (event = "interval") => {
		const DELEGATION_DURATION = 30 * 60000
		console.debug("%s: WAKEUP event", timeNow(), event, this, this.currentVisibility, this._bgCheckerId)
		if (this.mediaService && this.currentVisibility !== "visible" && this._bgCheckerId) {
			// check for expired time
			const now = moment().valueOf()
			console.debug("%s: lastRenewal", timeNow(), this.lastRenewal)
			if (this.lastRenewal) console.debug("diff _renewKeepAliveDelegation", now - this.lastRenewal)
			if (this.lastRenewal && (now - this.lastRenewal) > DELEGATION_DURATION) {
				console.debug("elapsed=", now - this.lastRenewal, now, this.lastRenewal)
				this._handleKeepAliveError(new TimeoutError())
			}
			else {
				this.lastRenewal = now
				//console.debug("calling _renewKeepAliveDelegation", this.sessionId)
				this._renewKeepAliveDelegation(this.sessionId).catch((err) => {
					this._handleKeepAliveError(err)
					//console.error("CAUGHT in setInterval", err)
					// error handling
				})
			}
		}
	}

	// MTVW-577
	delegationState = flow(function* (sessionId) {
		// check error 2x put true,
		// getSessions no sessions,
		// visibilitychange SUSPEND DELEGATION: (delete session or keepAliveDelegation) session not found
		let isDelegated = false
		let isInSessions = false
		if (this.mediaService && this.keepAliveUrl && this.keepAliveInterval && sessionId) {
			const sessions = yield this.mediaService.getSessions()
			console.debug("delegationState", sessions)
			//console.debug("Number of streaming sessions", sessions?.length)
			return this.isInMsSessions(sessionId, sessions)
		}
		return [isDelegated, isInSessions]
	})

	// MTVW-633
	_handleKeepAliveError = flow(function* (error) {
		try {
			//if (error instanceof TimeoutError) {
			const [isDelegated, isInSessions] = yield this.delegationState(this.sessionId)
			console.error("%s: _handleKeepAliveError %s, %s, %o", timeNow(), isDelegated, isInSessions, error)
			if (isDelegated) {
				console.debug("%s, STOPPING keepAliveDelegation", timeNow())
				yield this.mediaService?.keepAliveDelegation(this.sessionId, false)
			}
		}
		catch (e) {
			console.error("%s, CAUGHT in _handleKeepAliveError", timeNow(), e)
		}
		finally {
			try {
				const isMapEntry = StreamPlayList.sessionIdsMap.has(this.sessionId)
				//yield this.stopKeepAlive()
				yield this.player.stopInstance()
				// MTVW-795
				yield this.deleteAllSessions()

				// in case there was no entry in sessionIdsMap anymore
				/*
				if (!isMapEntry) {
					console.warn("_handleKeepAliveError no map entry")
					clearInterval(this._keepAliveId)
					this._keepAliveId = null
					clearInterval(this._bgCheckerId)
					this._bgCheckerId = null
					yield this.deleteSession(this.sessionId)
				}
				*/
			}
			catch (e) {
				console.error("%s: stopKeepAlive failed", timeNow(), e)
			}
			// CreateError(["system"], null, this, "stream")
			//throw ErrorBase.CreateError(["player", "CB_ERROR", msg], null, { code: error.code, type: error.type })
			runInAction(() => {
				const internal = error.msResponse?.internal ? "\n- " + error.msResponse?.internal : ""
				rootStore.page.Player.error = ErrorBase.CreateError(["Der Browser wurde in den Schlafzustand versetzt" + internal], null, this, "stream")
			})
			this.lastRenewal = null
			EventEmitter.release("wakeup", this._wakeup)
			EventEmitter.release("playerError", this._playerError)
		}
	})

	// MTVW-577
	_renewKeepAliveDelegation = flow(function* (sessionId) {
		//try {
		// In case delegation is already active, we need to stop and start it again
		const [isDelegated, isInSessions] = yield this.delegationState(sessionId)
		console.debug("%s: RENEW DELEGATION delegated %s, inSession,%s, sessionId %s", timeNow(), isDelegated, isInSessions, sessionId)
		if (isDelegated) yield this.mediaService?.keepAliveDelegation(sessionId, false)
		//console.debug("keepAliveDelegation", this.sessionId)
		sleep(500)
		if (isInSessions) yield this.mediaService?.keepAliveDelegation(sessionId, true)
		// MTVW-633: debug output
		console.debug("%s: renew done", timeNow())
		yield rootStore.sso.profile.refreshTokens()
		//}
		/*
		catch (e) {
			console.error("_renewKeepAliveDelegation", e)
			//throw e
			runInAction(() => {
				rootStore.page.Player.error = e
			})
			rootStore.page.Player?.player?.setRefElemContainer?.(null)
		}
		return result
		*/
	})

	// MTVW-577
	visibilityChange = flow(function* () {
		const visibilityState = document.visibilityState
		//console.debug("%s: StreamPlayList visibility %s", timeNow(), visibilityState)
		if (!this.boundVisibilityHandler || !this._keepAliveId) return
		// occasionaly multiple events with same visibilityState
		if (this.currentVisibility === visibilityState) return
		this.currentVisibility = visibilityState
		console.debug("%s: StreamPlayList visibility %s", timeNow(), visibilityState, this.StreamInfo, this.sessionId)
		try {
			if (this.mediaService && this.keepAliveUrl && this.keepAliveInterval && this.sessionId) {
				if (rootStore.page.Player.playerFg && import.meta.env.VITE_DISPOSE_LIVE_PLAYER === "true") {
					// don't delegate keep alive for foreground player, to avoid 'keep alive delegation is already enabled' error
					if (this.sessionId === rootStore.page.Player.playerFg?._source?.playlist?.StreamInfo?.session_id) {
						console.debug("no keep alive delegation!")
						return
					}
				}
				if (visibilityState !== "visible") {
					//console.debug("subscribing", this._wakeup)
					EventEmitter.release("wakeup", this._wakeup)
					EventEmitter.subscribe("wakeup", this._wakeup)
					EventEmitter.release("playerError", this._playerError)
					EventEmitter.subscribe("playerError", this._playerError)
					// activate background keep alive delegation
					//console.debug("START BG TIMER")
					yield this._renewKeepAliveDelegation(this.sessionId)

					// MTVW-589: background checker timer
					clearInterval(this._bgCheckerId)
					// performance.now() is not accurate during sleep
					this.lastRenewal = moment().valueOf()
					const self = this
					const INTERVAL = 5 * 60000 // 5 minutes
					const DELEGATION_DURATION = 30 * 60000 // 30 minutes
					this._bgCheckerId = setInterval(this._wakeup, INTERVAL)
				}
				else {
					yield rootStore.sso.profile.refreshTokens()
					// MTVW-589: background checker timer
					clearInterval(this._bgCheckerId)
					this._bgCheckerId = null
					// dactivate background keep alive delegation
					console.debug("%s: SUSPEND DELEGATION", timeNow())
					yield this.mediaService.keepAliveDelegation(this.sessionId, false)
					// trigger keepalive
					yield this.mediaService.keepAlive(this.keepAliveUrl)
					EventEmitter.release("wakeup", this._wakeup)
					EventEmitter.release("playerError", this._playerError)
					console.debug("hidden isPaused", rootStore.page.Player, rootStore.page.Player.player.isPaused)
					// MTVW-683, MTVW-685: Fix condition
					if (!rootStore.page.Player.player.isPaused) yield rootStore.page.Player.player.setPlay("visibilityChange")
				}
			}
		}
		catch (e) {
			console.error("%s: visibilityChange", timeNow(), e)
			this._handleKeepAliveError(e)
		}
	})

	execKeepAlive = flow(function* () {
		// MTV-3180: avoid sporadic TypeError (mediaService === null)
		// MTVW-577: visibilityState
		if (this.mediaService /*&& StreamPlayList.sessionIdsMap.has(this.sessionId)*/ && !this._bgCheckerId) {
			console.debug("%s: refresh keepAlive", timeNow(), this.player.__path, this.sessionId, this.keepAliveUrl)
			try {
				yield this.mediaService.keepAlive(this.keepAliveUrl)
			}
			catch (err) {
				console.error("%s: refresh keepAlive", timeNow())
				this._handleKeepAliveError(err)
			}
		}
	})

	// MTVW-577: keep alive delegation
	startKeepAlive = flow(function* () {
		console.debug("%s startKeepAlive %s", timeNow(), this.player.__path, this.player._source)
		// MTVW-725
		clearInterval(this.validTimeoutId)
		//console.debug("till: %s ms", this.validTillDuration, this, this.player._source)
		if (this.validTillDuration > 0) {
			// use setInterval instead of setTimeout to improve accuracy in case of background activation
			// clearInterval
			this.validTimeoutId = setInterval(() => {
				// safety margin of 1 minute
				if (this.validTillDuration <= 60000) {
					if (!this.player.__parent.isAdsPlayer) this.player._source?.videoSnapshot()
					this.player.setIsPosterVisible(true, "validTill", false)
					clearInterval(this.validTimeoutId)
					this.validTimeoutId = null
					this.player._isInactive("validTill")
					setTimeout(() => {
						this.player._isActive("validTill")
					}, 200)
				}
			}, 60000)
		}
		clearInterval(this._keepAliveId)
		this._keepAliveId = null
		clearInterval(this._bgCheckerId)
		this._bgCheckerId = null
		this.currentVisibility = "visible" //document.visibilityState
		if (this.mediaService && this.keepAliveUrl && this.keepAliveInterval) {
			// MTVW-788, MTVW-787: do not delete other sessions
			//this.deleteInactiveSessions(this.sessionId)
			// do initial keepAlive
			yield this.execKeepAlive()
			const self = this
			self._keepAliveId = setInterval(() => {
				self.execKeepAlive()
			}, self.keepAliveInterval * 1000)
		}

		if (this.boundVisibilityHandler) document.removeEventListener('visibilitychange', this.boundVisibilityHandler)
		this.boundVisibilityHandler = this.visibilityChange.bind(this)
		document.addEventListener('visibilitychange', this.boundVisibilityHandler)

		// for a given player there should be only 1 session, check and delete any older sessions(s)
		yield this.deleteInactiveSessions(this.sessionId, this.player.__path)

		// live pause case, the browser could have switched into the background
		if (document.visibilityState === "hidden") {
			console.warn("visibilitychange HIDDEN!")
			// check keep alive delegation state
			/* TODO recheck
			const sessions = yield this.mediaService.getSessions()
			for (let i = 0; i < sessions?.length; i++) {
				if (sessions[i]?.keep_alive_delegation) {
					try {
						yield this.mediaService.keepAliveDelegation(sessions[i]?.session_id, false)
					}
					catch (e) {
						console.error("%s: delegationState", timeNow(), e)
					}
				}
			}
			*/
			yield this.visibilityChange()
		}
	})

	// MTVW-795
	isInMsSessions(sessionId, msSessions) {
		let isDelegated = false
		let isInSessions = false
		//console.debug("Number of streaming sessions", sessions?.length)
		for (let i = 0; i < msSessions?.length; i++) {
			try {
				if (msSessions[i]?.session_id === sessionId) {
					isInSessions = true
					if (msSessions[i]?.keep_alive_delegation) isDelegated = true
					break
				}
			}
			catch (e) {
				console.error("%s: isInMsSessions", timeNow(), e)
			}
		}
		return [isDelegated, isInSessions]
	}

	// MTVW-787, MTVW-788
	deleteSession = flow(function* (sessionId, msSessions) {
		console.debug("deleteSession", sessionId, this.mediaService)
		if (this.mediaService) {
			try {
				if (StreamPlayList.sessionIdsMap.has(sessionId)) {
					const info = StreamPlayList.sessionIdsMap.get(sessionId)
					if (info.boundVisibilityHandler) document.removeEventListener('visibilitychange', info.boundVisibilityHandler)
					info.boundVisibilityHandler = null
					info.currentVisibility = document.visibilityState
					clearInterval(info.streamPlayList._keepAliveId)
					info.streamPlayList._keepAliveId = null
					clearInterval(info.streamPlayList._bgCheckerId)
					info.streamPlayList._bgCheckerId = null
				}
				StreamPlayList.sessionIdsMap.delete(sessionId)

				const [isDelegated, isInSessions] = this.isInMsSessions(sessionId, msSessions)
				if (isInSessions) yield this.mediaService.deleteSession(sessionId)
				else console.warn("session %s not in msSessions", sessionId)
			}
			catch (e) {
				console.error("%s: deleteSession %s, %o, %o", timeNow(), sessionId, e)
			}
		}
	})

	// MTVW-795
	deleteAllSessions = flow(function* () {
		const sessions = yield this.mediaService.getSessions()
		for (let i = 0; i < sessions?.length; i++) {
			const sessionId = sessions[i]?.session_id
			console.debug("deleteAllSessions", sessionId)
			yield this.deleteSession(sessionId, sessions)
		}

		// check if there is any remaining entry in the map
		StreamPlayList.sessionIdsMap.forEach((value, key) => {
			if (value.boundVisibilityHandler) document.removeEventListener('visibilitychange', value.boundVisibilityHandler)
			value.boundVisibilityHandler = null
			value.currentVisibility = document.visibilityState
			clearInterval(value.streamPlayList._keepAliveId)
			value.streamPlayList._keepAliveId = null
			clearInterval(value.streamPlayList._bgCheckerId)
			value.streamPlayList._bgCheckerId = null
		})
		StreamPlayList.sessionIdsMap.clear()
	})

	// MTVW-787, MTVW-788	
	cleanupSessions = flow(function* () {
		try {
			const sessions = yield this.mediaService.getSessions()
			console.debug("cleanupSessions media %s, %o, sessionIdsMap %s, %o", sessions?.length, sessions, StreamPlayList.sessionIdsMap.size, StreamPlayList.sessionIdsMap)
			// savedSessions might exist if the app has been reloaded. If the browser has been quit do the cleanup on startup.
			if (StreamPlayList.startup || StreamPlayList.savedSessions) {
				console.debug("cleanupSessions savedSessions", StreamPlayList.savedSessions, StreamPlayList.savedSessions?.length)
				for (let i = 0; i < sessions?.length; i++) {
					try {
						const sessionId = sessions[i]?.session_id
						if ((StreamPlayList.startup && this.sessionId !== sessionId) || (StreamPlayList.savedSessions > 0 && StreamPlayList.savedSessions.includes(sessionId))) {
							console.debug("cleanupSessions delete", sessions[i]?.session_id)
							yield this.mediaService.deleteSession(sessions[i].session_id)
						}
					}
					catch (e) {
						console.error("%s: cleanupSessions %s, %o, %o", timeNow(), sessions[i].session_id, sessions, e)
					}
				}
				StreamPlayList.savedSessions = null
				StreamPlayList.startup = false
			}
		}
		catch (err) {
			console.error("cleanupSessions", err)
		}
	})

	// MTVW-589
	deleteInactiveSessions = flow(function* (sessionToKeep, playerPath) {
		if (this.mediaService) {
			const oldSessions = []
			//console.debug("deleteInactiveSessions start", StreamPlayList.sessionIdsMap.size)
			StreamPlayList.sessionIdsMap.forEach((value, key) => {
				if (value.streamPlayList.sessionId !== sessionToKeep &&
					value.streamPlayList.player.__path === playerPath) oldSessions.push(value.streamPlayList.sessionId)
			})
			if (oldSessions.length > 0) console.warn("oldSessions", oldSessions)

			try {
				const sessions = yield this.mediaService.getSessions()
				for (let i = 0; i < sessions?.length; i++) {
					if (oldSessions.includes(sessions[i]?.session_id)) {
						console.warn("deleteInactiveSessions session", sessions[i].session_id)
						yield this.deleteSession(sessions[i].session_id, sessions)
					}
				}
			}
			catch (e) {
				console.error("%s: deleteInactiveSessions getSessions %o", timeNow(), e)
			}
			//console.debug("deleteInactiveSessions end", StreamPlayList.sessionIdsMap.size)
		}
	})

	// MTVW-577: keep alive delegation, called from player when it is killed
	stopKeepAlive = flow(function* () {
		console.debug("stopKeepAlive", StreamPlayList.sessionIdsMap.size, this.sessionId, StreamPlayList.sessionIdsMap, StreamPlayList.sessionIdsMap.has(this.sessionId))
		// MTVW-654: prevent concurrent calls, deleteInactiveSessions would generate 404
		const release = yield StreamPlayList.mutex.acquire()
		try {
			// MTVW-788, MTVW-787: delete specific session
			// MTVW-795: Use sessionId instead of player.__path
			if (StreamPlayList.sessionIdsMap.has(this.sessionId)) {
				const info = StreamPlayList.sessionIdsMap.get(this.sessionId)
				const sessions = yield this.mediaService.getSessions()
				yield info.streamPlayList.deleteSession(this.sessionId, sessions)
				// MTVW-725
				/*if (!rootStore.page.Player.playerFg)*/ clearInterval(info.streamPlayList.validTimeoutId)
			}
		}
		catch (e) {
			console.error("stopKeepAlive", e)
		}
		finally {
			release()
		}
		//yield this.mediaService.getSessions()
		console.debug("stopKeepAlive done", StreamPlayList.sessionIdsMap.size, this.player.__path, StreamPlayList.sessionIdsMap, StreamPlayList.sessionIdsMap.has(this.sessionId))
	})
}
