/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-09
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-09
 */

import { types as t, flow as flowMst } from "mobx-state-tree"
import { ClassAbstract } from "store/ClassTools"
import { computed, flow, action, makeObservable } from "mobx"

export class RepoAbstract extends ClassAbstract {
	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			isReady: computed,
			fetchDataAsync: action
		})
	}

	get isReady() {
		return false
	}

	fetchDataAsync = flow(function* (bOnlyIfNoData = null, ...args) { })

	waitAsync = flow(function* () {
		return yield this.fetchDataAsync(true)
	})

	fetchData(...args) {
		this.fetchDataAsync(...args)
		return this
	}
}

export const RepoAbstractMbx = t
	.model("RepoAbstractMbx", {})
	//.volatile(self => ({}))
	.views(self => ({
		_init() { },
		get isReady() {
			return false
		}
	}))
	.actions(self => ({
		fetchDataAsync: flowMst(function* (bOnlyIfNoData = null, ...args) { }),
		waitAsync: flowMst(function* () {
			return yield self.fetchDataAsync(true)
		}),
		fetchData(...args) {
			self.fetchDataAsync(...args)
			return self
		}
	}))
