import { action, computed, flow, observable, makeObservable, runInAction } from "mobx"
import { rootStore } from "store/RootStore"
import { urlFixHttpProtocolMismatch } from "store/util/urlFixHttpProtocolMismatch"
import { EventDetails } from "store/qlapi/EpgService"
import { iso6391 } from "store/util/iso6391"
import { adjustUtc, moment } from "store/util/moment"
import { TIME_FORMAT_BACKEND } from "store/model/Time"

export class MsEpg {
	msEpg = rootStore.epgService

	channels = []
	adultChannels = []
	notSubscribedChannels = []
	stbChannels = []
	//oChannelLists = null
	//oProducts = null
	programs = []
	//activeChannelId = null
	epgDayCache = new Map()
	epgDay = null
	channelDayPrograms = []
	channelDayCache = new Map()
	epgChannelDay = null

	constructor() {
		makeObservable(this, {
			channels: observable.shallow,
			//oChannelLists: observable,
			//oProducts: observable,
			programs: observable.shallow,
			//activeChannelId: observable,
			// MTVW-536: need to be observable
			epgDay: observable,
			epgDayCache: observable.shallow,
			isReady: computed,
			getProgramsForDayAsync: action,
			// MTVW-340
			channelDayPrograms: observable.shallow,
			channelDayCache: observable.shallow,
			isChannelDayReady: computed,
			getProgramsForChannelAsync: action
		})
	}

	get isReady() {
		//const result = (this.channels !== null && this.programs !== null && rootStore?.page?.ChannelListRepo?.isReady)
		const result = (this.channels !== null && this.epgDayCache.get(this.epgDay) !== undefined && rootStore?.page?.ChannelListRepo?.isReady)
		//console.debug("DAY isReady %s", result, this.epgDay, this.epgDayCache.get(this.epgDay), rootStore?.page?.ChannelListRepo?.isReady)
		return result
		//return (this.channels !== null && this.programs !== null && rootStore?.page?.ChannelListRepo?.isReady)
	}

	// MTVW-340
	get isChannelDayReady() {
		const result = (this.channels !== null && this.channelDayCache.get(this.epgChannelDay) !== undefined && rootStore?.page?.ChannelListRepo?.isReady)
		//console.debug("isChannelDayReady isReady %s", result)
		return result
	}

	getProgramsForDayAsync = flow(function* (day) {
		this.epgDay = day
		try {
			const cacheItem = this.epgDayCache.get(day)
			if (cacheItem && Array.isArray(cacheItem.progs)) {
				this.programs = cacheItem.progs
			}
			else {
				// set to null, otherwise overlapping day events would be displayed
				//this.programs = null
				// set fake data instead
				//const start = performance.now()
				this.programs = rootStore.epgService.fakeProgramsDay(day, this.channels)
				//console.debug("FAKE GEN %s ms", performance.now() - start)
				this.epgDayCache.set(day, { progs: this.programs, etag: null })
				//console.debug("FAKE %o", rootStore.epgService.fakeProgramsDay(day, this.channels))
			}
			const result = yield rootStore.epgService.programsForDayAsync(day, cacheItem ? cacheItem.etag : null)
			// Apply workaround for QLMS-850
			rootStore.epgService.qlms_850(result.progs, day)
			// in case the etag did not change progs is null and we keep the cache item
			if (result.progs) this.epgDayCache.set(day, result)
			// enable for testing fake data
			//this.epgDayCache.set(day, { progs: rootStore.epgService.fakeProgramsDay(day, this.channels), etag: null })

			// Now store the programs in the object. Avoid visual artifact, set only if no cache item was exsisting
			if (!cacheItem) {
				//this.programs = this.epgDayCache.get(day).progs
				// return the last requested day (click on not loaded day and click immediately on JETZT)
				this.programs = this.epgDayCache.get(this.epgDay).progs
			}
			return result.progs
		}
		catch (e) {
			console.error("getProgramsForDayAsync", e)
			return null
		}
	})

	// MTVW-340
	getProgramsForChannelDayAsync = flow(function* (channel, day) {
		const progs = []
		try {
			const dayProgs = yield rootStore.epgService.programsForChannelAsync(channel, day)
			dayProgs.forEach(j => {
				if (j.AvailabilityStartDateFormat("YYYYMMDD") === day) {
					progs.push(j)
				}
			})
		}
		catch (e) {
			console.error("getProgramsForChannelDayAsync", e)
		}
		//console.debug("getProgramsForChannelDayAsync %o", progs)
		return progs
	})

	// MTVW-340
	getProgramsForChannelAsync = flow(function* (channel, daysBefore, daysAfter) {
		this.epgChannelDay = channel
		let progs = []
		try {
			const cacheItem = this.channelDayCache.get(channel)
			if (cacheItem && Array.isArray(cacheItem)) {
				this.channelDayPrograms = cacheItem
				//console.debug("CACHE ITEM")
			}
			else {
				// invalidate data
				this.channelDayPrograms = null
				//console.debug("NO CACHE ITEM")
			}
			for (let i = -daysBefore; i <= daysAfter; i++) {
				// MTVW-414 
				//const day = moment().utc().add(i, "days").format("YYYYMMDD")
				const day = adjustUtc().add(i, "days").format("YYYYMMDD")
				const dayProgs = yield rootStore.epgService.programsForChannelAsync(channel, day)
				// WORKAROUND FOR: Error: C:\git\quickline\tmp4\tv-web\src\store\page\repo\MsEpg.js:
				// Internal Babel error: path is not in loop.Please report this as a bug.
				/*
				dayProgs.forEach(j => {
					if (j.AvailabilityStartDateFormat("YYYYMMDD") === day) {
						progs.push(j)
					}
				})
				*/
				for (let j = 0; j < dayProgs.length; j++) {
					if (dayProgs[j].AvailabilityStartDateFormat("YYYYMMDD") === day) progs.push(dayProgs[j])
				}
			}
			this.channelDayCache.set(channel, progs)
			if (!cacheItem) {
				//console.debug("SET CACHE ITEM")
				this.channelDayPrograms = this.channelDayCache.get(channel)
			}
		}
		catch (e) {
			console.error("getProgramsForChannelAsync", e)
		}
		//console.debug("getProgramsForChannelAsync %o", progs)
		return progs
	})

	// MTVW-536: flow function
	preloadDay = flow(function* (day) {
		const res = yield rootStore.epgService.programsForDayAsync(day, null)
		this.epgDayCache.set(day, res)
	})

	// MTVW-487: replace traxis rootStore.api.GetEventByChannelTime with epgMS implementation
	getEventByChannelTime = flow(function* (channelId, eventTime) {
		console.debug("getEventByChannelTime", channelId, eventTime, moment(eventTime).utc().format(TIME_FORMAT_BACKEND))
		const day = moment(eventTime).utc().format("YYYYMMDD")
		const cacheItem = this.epgDayCache.get(day)
		//console.debug("day %o, time %o, cache %o", day, eventTime, cacheItem)
		let progs = null
		if (cacheItem && Array.isArray(cacheItem.progs)) {
			progs = cacheItem.progs
		}
		else {
			yield this.getProgramsForDayAsync(day)
			progs = this.programs
		}
		progs = progs?.filter(i => i.channelId === channelId)[0]
		//console.debug("PROGS %o", progs)
		if (progs) {
			// MTVW-506: <= i.event.AvailabilityEndTs changed to < i.event.AvailabilityEndTs
			const event = progs.Events.filter(i => eventTime >= i.event.AvailabilityStartTs && eventTime < i.event.AvailabilityEndTs)?.[0]
			//console.debug("EVENT = %o", event)
			return yield this.getEventDetailsAsync(event?.event?.id)
		}
		return null
	})

	//MTVW-272
	_getTraxisEventDetails = flow(function* (eventId) {
		let traxisEvent = yield rootStore._root.api
			.GetEventById({ _idCache: "OverlayEventDetails", EventId: eventId })
			.abortReq()
			.fetchDataAsync()
		//console.debug("traxisEvent %o", traxisEvent)
		return traxisEvent
	})

	_getTraxisCredits(traxisCredits, type) {
		const result = []
		if (traxisCredits && traxisCredits?.length > 0) {
			traxisCredits.forEach(i => {
				if (i.type === type) {
					result.push(i.givenName + " " + i.familyName)
				}
			})
		}
		return result
	}

	_getTraxisGenres(traxisGenres) {
		const result = []
		if (traxisGenres && traxisGenres?.length > 0) {
			traxisGenres.forEach(i => {
				result.push(i.Value)
			})
		}
		return result
	}

	_convertTraxisEvent(data) {
		const result = new EventDetails()
		result.event = {
			id: data?.id ? data?.id : null,
			channelId: data?.Channels?.Channel[0]?.id ? data?.Channels?.Channel[0]?.id : null,
			AvailabilityStartTs: data?.AvailabilityStart ? moment(data?.AvailabilityStart).valueOf() : null,
			AvailabilityEndTs: data?.AvailabilityEnd ? moment(data?.AvailabilityEnd).valueOf() : null
		}

		const title = data.Titles?.Title[0]
		const credits = title?.Credits
		result.content = {
			id: title?.id ? title?.id : null,
			Title: title?.Name ? title?.Name : null,
			LongSynopsis: title?.LongSynopsis ? title?.LongSynopsis : null,
			BoxCover: title?.Pictures?.Picture[0]?.Value ? title?.Pictures?.Picture[0]?.Value : null,
			Rating: title?.Ratings?.Rating[0] ? title?.Ratings?.Rating[0]?.authority + title?.Ratings?.Rating[0]?.value : null,
			series_name: title?.SeriesCollection?.Series[0] ? title?.SeriesCollection?.Series[0]?.Name : null,
			SeriesId: title?.SeriesCollection?.Series[0] ? title?.SeriesCollection?.Series[0]?.id : null,
			EpisodeName: title?.EpisodeName ? title?.EpisodeName : null,
			Season: title?.SeriesCollection?.Series[0]?.ParentSeriesCollection?.Series[0]?.RelationOrdinal ? title?.SeriesCollection?.Series[0]?.ParentSeriesCollection?.Series[0]?.RelationOrdinal : null,
			Episode: title?.SeriesCollection?.Series[0]?.RelationOrdinal ? title?.SeriesCollection?.Series[0]?.RelationOrdinal : null,
			// TODO: format sample "PT22M", duration is currently calculated from end - start
			duration: title?.DurationInSeconds ? title?.DurationInSeconds : null,
			// TODO: AllGenres.AllGenre?
			Genres: this._getTraxisGenres(title?.Genres?.Genre),
			ShortSynopsis: title?.ShortSynopsis ? title?.ShortSynopsis : null,
			OriginalLanguage: title?.OriginalLanguages?.OriginalLanguage[0] ? iso6391.getNameByCode(title?.OriginalLanguages?.OriginalLanguage[0]) : null,
			ProductionDate: title?.ProductionDate ? title?.ProductionDate : null,
			ProductionLocations: title?.ProductionLocations?.ProductionLocation ? title?.ProductionLocations?.ProductionLocation : null,
			//seriesContainerId: ?
			//seriesRecordingId: title?.SeriesCollection ? title?.SeriesCollection?.Series[0]?.id : null,
			//seasonId: ?
			//language_id: ?
			ProductionLocationsIds: title?.ProductionLocations?.ProductionLocation ? title?.ProductionLocations?.ProductionLocation : null,
			//programType: ?
			//seriesType: ?
			Actors: this._getTraxisCredits(credits?.Credit, "ACTOR"),
			Writers: this._getTraxisCredits(credits?.Credit, "SCRIPTWRITER"),
			Directors: this._getTraxisCredits(credits?.Credit, "DIRECTOR"),
			//SeriesMoniker: ?
			// IsHD is obsolete
			//IsHD: data?.IsHD ? data?.IsHD : null,
			MovieType: title?.Genres?.Genre[0].type ? title?.Genres?.Genre[0].type : null
		}
		//result.error = data.error
		//console.debug("EventDetails %o, traxis %o, RATING %s", result, data, result.content.Rating)
		return result
	}

	//MTVW-272
	getEventDetailsAsync = flow(function* (eventId) {
		let result = null
		let msError = null
		console.debug("getEventDetailsAsync", eventId)
		yield rootStore.epgService.eventDetailsAsync(eventId, 0, false).then((res) => {
			result = res
		}).catch((err) => {
			// don't display error message (for recordings)
			runInAction(() => {
				rootStore.page.Player.error = null
			})
			msError = err
			console.error("getEventDetailsAsync error", err)
		})
		//console.debug("EPG result %o", result)
		if (msError?.status === 404) {
			//console.debug("FETCH FROM Traxis %o", msError)
			const traxisEvent = yield this._getTraxisEventDetails(eventId).catch((err) => {
				result = { error: err }
				console.error("CATCH getEventDetailsAsync %o, %o", eventId, result)
			})
			if (traxisEvent?.statusText === "OK") {
				result = this._convertTraxisEvent(traxisEvent.jsonParsed)
				//console.debug("CONVERT %o", result)
			}
		}
		else if (msError) {
			result = { error: msError }
		}
		//console.debug("return result %o", result)
		return result
	})

	// MTVW-246
	getProgramsForNow = flow(function* () {
		let progs = []
		try {
			progs = yield rootStore.epgService.programsForNowAsync()
		}
		catch (e) {
			console.error("getProgramsForNow", e)
		}
		return progs
	})

	getChannel(channelId) {
		if (!this.channels) return null
		const index = this.channels.findIndex(i => i.id === channelId)
		return (index > -1) ? this.channels[index] : null
	}

	getChannelName(channelId) {
		const channel = this.getChannel(channelId)
		if (!channel) return null
		return channel.Name
	}

	// GT12: MTVW-460, MTVW-502: Channels MS flags
	/*
	hasReplay:
	----------
		True if the channel has replay. Set to false if no replay content is avaible

		if isSubscribed is false:
			set to false
		else if user cannot fast forward or rewind:
			set to false
		else if maxReplayDuration <= PT2H:
			set to false
		else if recordingAndReplayAllowedSince == 0:
			set to false
		else:
			set to true

	maxReplayDuration:
	------------------
	The maximal allowed replay duration in ISO 8601 format. Usually set to P7D, P1DT6H, PT2H. If set to PT0S, no replay is allowed at all. This field never contains an empty string. The actual replay duration depends also on the recording opt-in date of the user.

		if isSubscribed is false:
			replay is not allowed.
		else if maxReplayDuration is PT0S:
			replay is not allowed.
		else if recordingAndReplayAllowedTill <= now
			replay is not allowed.
		else if recordingAndReplayAllowedSince == 0:
			replay is allowed since now-PT2H.
		else if now-maxReplayDuration < recordingAndReplayAllowedSince:
			replay is allowed since recordingAndReplayAllowedSince.
		else:
			replay is allwed since now-maxReplayDuration.

	rollingBufferId:
	----------------
	The rollingBufferId is used to create a replay streaming session. For further information refer to maxReplayDuration.
	If an empty string is given, replay and recording is not allowed at all on this channel.
	*/
	// MTVW-631: add parameter to overrid maxReplayDuration
	_allowedSince(channelId, eventStart, eventEnd, isRecording = false, maxReplayDuration = null) {
		const channel = this.getChannel(channelId)
		const now = moment().utc().valueOf()
		const enablerFlag = isRecording ? channel?.hasNPVR : channel?.hasReplay
		const _maxReplayDuration = maxReplayDuration ? maxReplayDuration : channel?.maxReplayDuration
		//console.debug("maxReplayDuration", _maxReplayDuration, maxReplayDuration)

		if (enablerFlag && channel?.rollingBufferId?.length > 0) {
			let allowedSince = now - moment.duration(_maxReplayDuration).valueOf()
			if (!maxReplayDuration && channel?.recordingAndReplayAllowedSince === 0) {
				allowedSince = now - moment.duration("PT2H").valueOf()
			}
			else if (now - moment.duration(_maxReplayDuration).valueOf() < channel?.recordingAndReplayAllowedSince) {
				allowedSince = channel?.recordingAndReplayAllowedSince
			}
			// else allowedSince = now - _maxReplayDuration
			return allowedSince
		}
		else return now
	}

	replayOrRecordingAllowedSince(channelId, eventStart, eventEnd, isRecording = false) {
		return this._allowedSince(channelId, eventStart, eventEnd, isRecording)
	}

	// MTVW-631: add parameter to override maxReplayDuration
	isReplayAllowed(channelId, eventStart, eventEnd, isRecording = false, maxReplayDuration = null) {
		//console.debug("isReplayAllowed", channelId, eventStart, eventEnd, channelId, isRecording, this.getChannel(channelId))
		return eventStart >= this._allowedSince(channelId, eventStart, eventEnd, isRecording, maxReplayDuration)
		/*
		return (isRecording ? true : eventStart >= moment().utc().valueOf() - moment.duration(channel?.maxReplayDuration).valueOf())
			&& (eventStart >= channel?.recordingAndReplayAllowedSince)
			&& (eventEnd <= channel?.recordingAndReplayAllowedTill)
		//&& (channel?.hasReplay || isRecording)
		*/
	}

	// GT12: MTVW-460, MTVW-502: Channels MS flags
	/*
	hasNPVR:
	--------
	If set to true, it is allowed to book recordings on this channel. Set to false if no recordings may be booked.
	
	if isSubscribed is false:
		booking of recordings is not allowed.
	else if maxReplayDuration is PT0S or isRecordingAllowed is false:
		booking of recordings is not allowed.
	else if recordingAndReplayAllowedTill <= now
		booking of recordings is not allowed.
	else if recordingAndReplayAllowedSince == 0:
		booking of recordings is allowed since now-PT2H.
	else if now-maxReplayDuration < recordingAndReplayAllowedSince:
		booking of recordings is allowed since recordingAndReplayAllowedSince.
	else:
		booking of recordings is allwed since now-maxReplayDuration.
	*/
	isRecordingAllowed(channelId, eventStart, eventEnd) {
		return eventStart >= this._allowedSince(channelId, eventStart, eventEnd, true)
	}

	getChannelsFromList(list) {
		// Channels MS does not yet support channellists, need to use ChannelListRepo
		if (!this.channels) return []
		if (!list) {
			//console.debug("getChannelsFromList ALL")
			return this.channels
		}
		else {
			//console.debug("getChannelsFromList list %o, count %s, %o", list, list?.Channels.resultCount, list?.Channels?.Channel)
			//const filtered = this.channels?.filter(i => { return list?.Channels?.Channel?.find(j => j.id === i.id) })
			return this.convertTraxisChannelList(this.channels, list?.Channels?.Channel)
		}
	}

	// added for traxis cleanup
	getChannelsForList(id = null) {
		if (id === null) {
			return this.channels
		}

		const chList = rootStore.page.ChannelListRepo.getChannelList(id)
		// channel list might be empty
		//return (!chList?.Channels) ? [] : chList.Channels.Channel?.map(i => self.Channels.find(elem => elem.id === i.id)).filter(self._isChannelFilter)
		return this.getChannelsFromList(chList)
	}

	events(channelId) {
		if (!this.isReady || typeof this.programs?.find !== "function") return []
		const result = this.programs?.find(i => i.channelId === channelId)
		//console.debug("RESULT for channel %s, %o", channelId, result)
		return result ? result.Events : []
	}

	getChannelsAsync = flow(function* () {
		// MTVW-246: commented
		//this.channels = null
		let channels = []
		try {
			// TODO: Move to separate MsChannels ?
			channels = yield rootStore.channelsService.tvChannelsAsync(rootStore?.sso?.contractId)
			if (!channels) return []

			// We don't have all the information in the channels to do the filtering and ordering of channels
			// (e.g.non subscribed channels). Therefore we still need to use the traxis ChannelListRepo

			channels.forEach(i => {
				i.logoUrl = urlFixHttpProtocolMismatch(i.logoUrl)
			});
			// change this.channels once we have the channel list with filters applied
			this.channels = this._applyChannelFilters(channels)
			// MTVW-831: "?mode=box&w=" + 44 * 4 -> "?mode=box&w=480&h=270"
			this.preloadChannelPictures("?mode=box&w=480&h=270")
			//console.debug("CHANNELS %o, ALL %o", this.channels, channels)
		}
		catch (e) {
			console.error("getChannelsAsync", e)
			return []
		}
		return this.channels
	})

	// MTVW-728
	getChannelsArrayAsync = flow(function* () {
		const channels = yield this.getChannelsAsync()
		const channelsArray = []
		channels.forEach(i => {
			channelsArray.push(i.id)
		})
		return channelsArray
	})

	_resetChannelFilterStats() {
		this.adultChannels = []
		this.notSubscribedChannels = []
		this.stbChannels = []
	}

	_channelFilter(i) {
		if (!i) {
			return false
		}
		if (i.IsAdult) {
			this.adultChannels.push(i)
			return false
		}
		if (!i.isSubscribed) {
			this.notSubscribedChannels.push(i)
			return false
		}
		// MTVW-503: UHD channel filter, decision changed to use backend solution
		/*
		if (i.isSTBOnly) {
			this.stbChannels.push(i)
			return false
		}
		*/
		return true
	}

	_applyChannelFilters(msChannels) {
		let filtered = []
		// MTVW-312: if condition for robustness
		if (Array.isArray(msChannels)) {
			this._resetChannelFilterStats()
			filtered = msChannels.filter(this._channelFilter.bind(this))
			console.debug("total ms channels %s, filtered %s, adult %s, not subscribed %s, stb %s", msChannels?.length, filtered?.length, this.adultChannels?.length, this.notSubscribedChannels?.length, this.stbChannels?.length)
		}
		return filtered
	}

	convertTraxisChannelList(msChannels, traxisChannels) {
		// Channels MS does not yet support channellists, need to use ChannelListRepo and return MS Channels
		// keep the filter and order of the traxis channels
		//console.debug("convertTraxisChannelList msChannels %o, traxis %o", msChannels, traxisChannels)
		const result = []
		// MTVW-312: if condition for robustness
		if (!Array.isArray(msChannels) || !Array.isArray(traxisChannels)) return result
		traxisChannels.forEach(i => {
			const local = msChannels.find((j => j.id === i.id))
			if (local) result.push(local)
		})
		//console.debug("convertTraxisChannelList ms %s, traxis %s, result %s", msChannels?.length, traxisChannels?.length, result?.length)
		return result
	}

	preloadChannelPictures(param) {
		return this.channels?.map(i => {
			if (i.logoUrl !== null) {
				let img = new Image()
				img.src = i.logoUrl + param
				img.onerror = (e) => { /*console.debug("logo not found %s", i.logoUrl)*/ }
				//console.debug("IMG %s", i.logoUrl + param)
				return img
			}
			return null
		})
	}

	_init() {
		//console.debug("in _init MsEpg %o", new Error().stack)
		// done in TV Guide
		//this.getChannelsAsync()
		//this.getProgramsForDayAsync(moment().utc().format("YYYYMMDD"))
		//this.getProgramsForNow()
	}
}
