class Emitter {

	// Keeps track of subscriptions as <name: string, callbacks: []>.
	subscriptions = new Map()

	constuctor() { }

	// Creates a new subscription or adds the callback to an existing subscription.
	subscribe = (functionName, callback) => {
		//console.debug("subscribe", functionName, callback)
		//this.release(functionName, callback)
		const subscription = this.subscriptions.get(functionName)

		if (subscription) {
			subscription.push(callback)
		} else {
			this.subscriptions.set(functionName, [callback])
		}

		console.debug("Emitter subscribe", this.subscriptions)
		return this
	}

	// When subscription.dispatch() is called, all of the callback are executed for that subscription.
	dispatch = (functionName, ...rest) => {
		//console.debug("dispatch", functionName)
		const subscription = this.subscriptions.get(functionName)

		if (subscription) {
			subscription.forEach(cb => cb(...rest))
		}
	}

	// subscription.release() removes the callback for that event and updates the subscription.
	release = (functionName, callback) => {
		//console.debug("release", functionName)
		const subscription = this.subscriptions.get(functionName)

		if (subscription) {
			//console.debug("subscription", subscription)
			//subscription.forEach(cb => console.debug("cb", cb, callback, cb == callback))
			const updatedSubscription = subscription.filter(cb => cb !== callback)
			//console.debug("updatedSubscription", updatedSubscription)
			this.subscriptions.set(functionName, updatedSubscription)
		}
		console.debug("Emitter release", this.subscriptions)
	}
}

const EventEmitter = new Emitter()
export { EventEmitter }


/* OLD IMPLEMENTATION
const EventEmitter = {
	events: {},
	dispatch: function (event, data) {
		if (!this.events[event]) {
			return
		}
		//console.debug("dispatch", this.events)
		Array.prototype.forEach.call(this.events[event], callback => callback(data))
	},
	subscribe: function (event, callback) {
		//console.debug("subscribe", event, callback, this.events[event])
		if (!this.events[event]) {
			this.events[event] = []
		}
		// MTVW-636: register callback code only once
		const registered = this.events[event].find(elem => elem.toString() === callback.toString())
		//for (let i = 0; i < this.events[event].length; i++) {
		//	if (this.events[event][i].toString() === callback.toString()) console.debug("callback registered")
		//	console.debug("entry %o, %o", this.events[event][i], callback)
		//}

		if (!registered) this.events[event].push(callback)
		//console.debug("registered", registered, this.events[event])
	}
}
module.exports = { EventEmitter }
*/
