import { types as t } from "mobx-state-tree"
import { TraxisAbstract } from "store/api/TraxisAbstract"

const DeletePrivateChannelListModel = t.model({})

export class DeletePrivateChannelList extends TraxisAbstract {
	get _model() {
		return DeletePrivateChannelListModel
	}

	_getParseResp(data) {
		return data
	}

	//_getHashReq(data) {
	//	return ``
	//}

	getXmlReq() {
		return `<?xml version="1.0" encoding="utf-8"?>
<Request xmlns="urn:eventis:traxisweb:1.0">
    <Identity>
        <ProfileId>${this.profileId}</ProfileId>
    </Identity>
	 <ActionQuery resourceType="ChannelList" resourceId="${this._oVars.channelListId}" actionName="DeleteFromPersonalChannelLists" />
</Request>`
	}
}
