import { flow /*, observable, computed, action */ } from "mobx"
import { TraxisCpeIdMixin } from "store/api/mixin/TraxisCpeIdMixin"
// MTVW-585: 1 retry
import { fetchWithRetry, DEFAULT_RETRIES, NO_RETRIES, DEFAULT_TIMEOUT } from "./FetchWithRetry"
import { alias, list, object, /*createModelSchema, getDefaultModelSchema,*/ serializable, deserialize, primitive } from "serializr"
import { ServiceUrls, serviceUrls } from "store/qlapi/ServiceUrls"
import { appFeatures } from "AppFeatures"
import { getQlHeaders } from "store/qlapi/QlHeaders"


/* Moved to ServiceUrls
export const BASE_LOGIN_URL = "https://content.alpha.quickline.ch/login/v001"
// For nginx
//export const BASE_LOGIN_URL = "https://content.quickline.ch/login/v001"
*/
class LoginProfile {
	// mappings to old SSO values
	//@serializable id = null
	@serializable(alias("id", primitive())) ProfileIdentificationGuid = null
	@serializable(alias("name", primitive())) Nickname = null
	//@serializable isLoginPinProtectionEnabled = false
	@serializable(alias("isLoginPinProtectionEnabled", primitive())) LoginPinProtected = false
	@serializable(alias("isMainProfile", primitive())) IsMainProfile = false
	@serializable(alias("isChildProfile", primitive())) IsChildProfile = false
}

// MTVW-657: predefined channel lists
class ChannelList {
	@serializable activated = null
	@serializable(list()) channelIds = []
	@serializable id = null
	@serializable name = null
}
class Settings {
	@serializable(list(object(ChannelList))) channelLists = []
}
class AuthenticateResult {
	@serializable(alias("contractId", primitive())) ContractId = null
	@serializable(object(ServiceUrls)) serviceUrls
	@serializable(list(object(LoginProfile))) profiles = []
	// MTVW-657: predefined channellists
	@serializable(object(Settings)) settings
}

export class Profile {
	/* old SSO implementation ✓
		✓ "ProfileIdentificationGuid": "a4e37055-90ec-4ed8-b21d-1f9292d72a26",
		- "SubscriberIdentificationGuid": "524fc30e-f533-408d-bb95-23e1aa8e6ef4",
		✓ "Nickname": "Marta",
		- "Username": "marta3",
		✓ "MobileNumber": "41791258085",
		- "Language": "De",
		✓ "IsChildProfile": false,
		✓ "IsMainProfile": false,
		✓ "PurchaseAuthentication": "ProfilePinRequired",
		✓ "AdultAuthentication": "NoPinRequired",
		- "ProfileAuthentication": "NoPinRequired",
		✓ "ManagementAuthentication": "ProfilePinRequired"

	MS login
		✓ "id": "a4e37055-90ec-4ed8-b21d-1f9292d72a26",
		✓ "name": "Marta",
		✓ "mobileNumber": "41791258085",
		✓ "isMainProfile": false,
		✓ "isChildProfile": false,
		+ "isFirstInstallationDone": true,
		+ "isLoginPinProtected": false,
		✓"purchaseAuthentication": "ProfilePinRequired",
		✓ "profileManagementAuthentication": "ProfilePinRequired",
		✓"adultAuthentication": "NoPinRequired",
		+ "isAdultContentAccessShown": false,
		+ "nonSubscribedChannelsShown": false,
		+ "personalizedRecommendationsEnabled": true

	Traxis
		"id": "a4e37055-90ec-4ed8-b21d-1f9292d72a26",
		"PersonalizationOptIn": true,
		"ViewingPeriods": "",
		"NamedProperties": {
			"Property": [{
				"Value": "true",
				"name": "ATP.FirstInstallationDone"
			}, {
				"Value": "NoPinRequired",
				"name": "AdultAuthentication"
			}, {
				"Value": "false",
				"name": "IsChildProfile"
			}, {
				"Value": "false",
				"name": "IsMainProfile"
			}, {
				"Value": "ProfilePinRequired",
				"name": "ManagementAuthentication"
			}, {
				"Value": "NoPinRequired",
				"name": "ProfileAuthentication"
			}, {
				"Value": "ProfilePinRequired",
				"name": "PurchaseAuthentication"
			}]
		},
		"Name": "Marta",
		"Pin": "0000"
	*/

	// mappings to old SSO values
	@serializable(alias("id", primitive())) ProfileIdentificationGuid = null
	@serializable(alias("name", primitive())) Nickname = null
	@serializable(alias("eMailAddress", primitive())) EMail = null
	@serializable(alias("mobileNumber", primitive())) MobileNumber = null
	@serializable(alias("color", primitive())) Color = null
	@serializable(alias("isMainProfile", primitive())) IsMainProfile = false
	@serializable(alias("isChildProfile", primitive())) IsChildProfile = false
	@serializable(alias("isFirstInstallationDone", primitive())) FirstInstallationDone = false
	@serializable(alias("isLoginPinProtected", primitive())) LoginPinProtected = false
	@serializable(alias("purchaseAuthentication", primitive())) _PurchaseAuthentication = null
	@serializable(alias("profileManagementAuthentication", primitive())) _ManagementAuthentication = null
	@serializable(alias("adultAuthentication", primitive())) _AdultAuthentication = null
	@serializable(alias("isAdultContentAccessShown", primitive())) AdultContentAccessShown = false
	@serializable(alias("nonSubscribedChannelsShown", primitive())) NonSubscribedChannelsShown = null
	// MTVW-627: not used anymore
	@serializable(alias("personalizedRecommendationsEnabled", primitive())) PersonalizedRecommendationsEnabled = false
}
class LoginResult {
	// mappings to old SSO values
	@serializable(alias("profileToken", primitive())) ProfileToken = null
	// TODO: missing support?
	@serializable(alias("profileTokenExpiresAt", primitive())) ProfileTokenExpiresAt = null
	@serializable(alias("partnerId", primitive())) PartnerId = null
	@serializable(alias("accessTechnology", primitive())) AccessTechnology = null
	@serializable(alias("canCreateProfiles", primitive())) CanCreateProfiles = false
	@serializable(alias("canSwitchProfiles", primitive())) CanSwitchProfiles = false
	@serializable(alias("gt12Enabled", primitive())) gt12Enabled = false
	// TODO: missing support
	@serializable(alias("maxProfiles", primitive())) maxProfiles = 0
	@serializable(alias("subscriberId", primitive())) SubscriberIdentificationGuid = null
	//@serializable(alias("mainProfileUserName", primitive())) MainProfileUserName = null
	@serializable(object(Profile)) profile = null
}
class MainUserContactInfoResult {
	@serializable(alias("userName", primitive())) UserName = null
	@serializable(alias("eMailAddress", primitive())) EMail = null
	@serializable(alias("mobileNumber", primitive())) MobileNumber = null
}
class RefreshResult {
	@serializable(alias("profileToken", primitive())) ProfileToken = null
}

// MTVW-435
class SentTo {
	@serializable type = null
	@serializable destination = null
}
class ResetPinCodeResult {
	@serializable(object(SentTo)) sentTo = null
}

const CREDENTIALS = 'include'
//const CREDENTIALS = 'same-origin'
const STD_HEADERS = {
	'Accept': 'application/json',
	/*
	'pragma': 'no-cache', 'cache-control': 'no-cache',
	*/
}
export class LoginService extends TraxisCpeIdMixin {

	get xHeaders() {
		// make a copy of STD_HEADERS, otherwise STD_HEADERS would be changed!
		const headers = {}
		Object.assign(headers, STD_HEADERS)
		Object.assign(headers, getQlHeaders())
		return headers
	}

	version = flow(function* (retryCount = DEFAULT_RETRIES) {
		const config = {
			url: serviceUrls.loginUrl + "version",
			fetchOptions: {
				method: 'GET',
				headers: this.xHeaders,
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["LoginService version"],
			service: "LoginService",
			createException: true,
			displayError: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		return `${resultJson["build_tag"]} (${resultJson["build_date"]})`
	})

	// No retry, otherwise potential error will take too long
	authenticateAsync = flow(function* (user, pw, keepSignedIn, reAuth = false, retryCount = NO_RETRIES) {
		//console.debug("user %s, pw %s, url %s", user, pw, serviceUrls.loginUrl + "authenticateUntrusted")
		const base64String = btoa(user + ":" + pw)
		const headers = this.xHeaders
		let url = serviceUrls.loginUrl + "authenticateUntrusted"
		if (!reAuth) {
			headers['Authorization'] = `Basic ${base64String}`
			url += `?persistcredentials=${keepSignedIn}`
		}
		// else case: no basic auth, cookie is used for re-authentication

		const config = {
			url: url,
			fetchOptions: {
				method: 'POST',
				headers: headers,
				//mode: "cors",
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["LoginService authenticateAsync"],
			service: "LoginService",
			createException: true,
			displayError: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)

		// cookie is not accessible
		//console.debug("cookie %o, %o", document.cookie, response.headers)

		const authResult = deserialize(AuthenticateResult, resultJson, (err, result) => {
		})
		console.debug("authResult", authResult)
		serviceUrls.setUrls(authResult.serviceUrls)
		//console.debug("serviceURls %o", serviceUrls)
		return authResult
	})

	// No retry, otherwise potential PIN error will take too long
	loginAsync = flow(function* (profileId, pin = "", retryCount = NO_RETRIES) {
		//console.debug("profileId %s, url %s", profileId, serviceUrls.loginUrl + "login/" + profileId)
		const headers = this.xHeaders
		headers['X-Quickline-Pin'] = pin
		const config = {
			url: serviceUrls.loginUrl + "login/" + profileId,
			fetchOptions: {
				method: 'POST',
				headers: headers,
				//mode: "cors",
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["LoginService loginAsync"],
			service: "LoginService",
			createException: true,
			displayError: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)

		const loginResult = deserialize(LoginResult, resultJson, (err, result) => {
		})
		//console.debug("loginResult", loginResult)
		// MTVW-540
		appFeatures.setGt12EnabledState(loginResult.gt12Enabled)
		return loginResult
	})

	getProfileAsync = flow(function* (profileId, retryCount = DEFAULT_RETRIES) {
		//console.debug("profileId %s, url %s", profileId, serviceUrls.loginUrl + "profile/" + profileId)
		const config = {
			url: serviceUrls.loginUrl + "profile/" + profileId,
			fetchOptions: {
				method: 'GET',
				headers: this.xHeaders,
				//mode: "cors",
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["LoginService getProfileAsync"],
			service: "LoginService",
			createException: true,
			displayError: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)

		return deserialize(Profile, resultJson, (err, result) => {
		})
	})

	// No retry, otherwise potential PIN error will take too long
	updateProfileAsync = flow(function* (profileId, props = {}, pin = "", retryCount = NO_RETRIES) {
		//console.debug("profileId %s, url %s", profileId, serviceUrls.loginUrl + "profile/" + profileId)
		//console.debug("props %o", props)
		//console.debug("json %s, pin %s, len %s", JSON.stringify(props), pin, pin?.length)
		const headers = this.xHeaders
		headers['X-Quickline-Pin'] = pin
		const config = {
			url: serviceUrls.loginUrl + "profile/" + profileId,
			fetchOptions: {
				method: 'PATCH',
				body: JSON.stringify(props),
				headers: headers,
				//mode: "cors",
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["LoginService updateProfileAsync"],
			service: "LoginService",
			createException: true,
			displayError: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		//console.debug("updateProfileAsync resultJson", resultJson)

		//return deserialize(LoginResult, resultJson, (err, result) => {
		//})
	})

	deleteProfileAsync = flow(function* (profileId, pin = "", retryCount = DEFAULT_RETRIES) {
		//console.debug("profileId %s, url %s", profileId, serviceUrls.loginUrl + "profile/" + profileId)
		const headers = this.xHeaders
		headers['X-Quickline-Pin'] = pin
		const config = {
			url: serviceUrls.loginUrl + "profile/" + profileId,
			fetchOptions: {
				method: 'DELETE',
				headers: headers,
				//mode: "cors",
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["LoginService deleteProfileAsync"],
			service: "LoginService",
			createException: true,
			displayError: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)

		// no value in reponse
		//return deserialize(Profile, resultJson, (err, result) => {
		//})
	})

	createProfileAsync = flow(function* (props = {}, pin = "", retryCount = DEFAULT_RETRIES) {
		//console.debug("url %s", serviceUrls.loginUrl + "profile")
		const headers = this.xHeaders
		headers['X-Quickline-Pin'] = pin
		const config = {
			url: serviceUrls.loginUrl + "profile",
			fetchOptions: {
				method: 'POST',
				body: JSON.stringify(props),
				headers: headers,
				//mode: "cors",
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["LoginService createProfileAsync"],
			service: "LoginService",
			createException: true,
			displayError: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)

		return `${resultJson["profileId"]}`
	})

	getLoginProfilesAsync = flow(function* (retryCount = DEFAULT_RETRIES) {
		//console.debug("url %s", serviceUrls.loginUrl + "loginProfiles")
		const config = {
			url: serviceUrls.loginUrl + "loginProfiles",
			fetchOptions: {
				method: 'GET',
				headers: this.xHeaders,
				//mode: "cors",
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["LoginService getLoginProfilesAsync"],
			service: "LoginService",
			createException: true,
			displayError: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)

		return deserialize(LoginProfile, resultJson, (err, result) => {
		})
	})

	// current token expirations
	// ProfileToken: 60 minutes
	// PurchaseToken: 10 minutes
	// TODO: expiration times from API
	get profileTokenRefreshInterval() {
		// safe value of 50 minutes
		return 50 * 60
	}

	getMainUserContactInfoAsync = flow(function* (retryCount = DEFAULT_RETRIES) {
		//console.debug("url %s", serviceUrls.loginUrl + "mainUserContactInfo")
		const config = {
			url: serviceUrls.loginUrl + "mainUserContactInfo",
			fetchOptions: {
				method: 'GET',
				headers: this.xHeaders,
				//mode: "cors",
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["LoginService getMainUserContactInfoAsync"],
			service: "LoginService",
			createException: true,
			displayError: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)

		return deserialize(MainUserContactInfoResult, resultJson, (err, result) => {
		})
	})

	refreshTokens = flow(function* (retryCount = DEFAULT_RETRIES) {
		//console.debug("url %s", serviceUrls.loginUrl + "refreshTokens")
		const config = {
			url: serviceUrls.loginUrl + "refreshTokens",
			fetchOptions: {
				method: 'POST',
				headers: this.xHeaders,
				//mode: "cors",
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["LoginService refreshTokens"],
			service: "LoginService",
			createException: true,
			displayError: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)

		return deserialize(RefreshResult, resultJson, (err, result) => {
		})
	})

	logoff = flow(function* (retryCount = DEFAULT_RETRIES) {
		//console.debug("url %s", serviceUrls.loginUrl + "logoff")
		const config = {
			url: serviceUrls.loginUrl + "logoff",
			fetchOptions: {
				method: 'POST',
				headers: this.xHeaders,
				//mode: "cors",
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["LoginService logoff"],
			service: "LoginService",
			createException: true,
			displayError: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		//console.debug("logoff resultJson", resultJson)
		return
	})

	// MTVW-435
	resetPinAsync = flow(function* (profileId, contactType, retryCount = NO_RETRIES) {
		const config = {
			url: serviceUrls.loginUrl + "resetPin/" + profileId + `?contactType=${contactType}`,
			fetchOptions: {
				method: 'POST',
				headers: this.xHeaders,
				//mode: "cors",
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["LoginService resetPinAsync"],
			service: "LoginService",
			createException: true,
			displayError: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		//console.debug("resetPinAsync %o", resultJson)

		return deserialize(ResetPinCodeResult, resultJson, (err, result) => {
		})
	})
}
