import { computed, makeObservable } from "mobx";
import { rootStore } from "store/RootStore"

export class ClassAbstract {
	__parent = null
	__path = null

	constructor(parent, path = "") {
		makeObservable(this, {
			_path: computed
		})

		this.__parent = parent
		this.__path = path
		console.info("@@constructor(parent=%o, path=%o)", parent, path)
	}

	/**
	 *	@returns {rootStore}
	 *	@instance
	 */
	get _root() {
		return rootStore
	}

	get _parent() {
		return this.__parent ?? null
	}

	get _path() {
		if (this.__parent && this.__parent._path) {
			return `${this.__parent._path}/${this.__path}`
		}
		return `/${this.__path}`
	}

	get _name() {
		return this.constructor.name
	}
}
