import { v4 as uuid } from "uuid"

// MTVW-471
export class AppSession {
   _sessionId: string = uuid()

   get sessionId(): string {
      return this._sessionId
   }

   set sessionId(id: string) {
      this._sessionId = id
   }

   createNewSessionId() {
      this._sessionId = uuid()
   }

   get uniqueRequestId(): string {
      // create request id and replace the first 18 characters with the prefix of the session id
      const newUuid = uuid()
      return this._sessionId.substring(0, 18) + newUuid.substring(18, newUuid.length)
   }
}
