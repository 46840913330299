import { types as t } from "mobx-state-tree"
import { Pictures } from "./Picture"
import { iso6391 } from "store/util/iso6391"

export const Title_ProductionLocations_Part = t
	.model({
		ProductionLocations: t.maybeNull(
			t
				.model("Title_ProductionLocations_Part__ProductionLocations", {
					ProductionLocation: t.array(t.string)
				})
				.views(self => ({
					get items() {
						return self.ProductionLocation
					}
				}))
		)
	})
	.views(self => ({
		get ProductionLocation() {
			return self.ProductionLocations?.items?.[0] ?? null
		}
	}))

export const Title_Genres_Part = t.model({
	Genres: t.maybeNull(
		t
			.model("Title_Genres_Part__Genres", {
				Genre: t.array(
					t.model("Title_Genres_Part__Genre", {
						Value: t.string,
						type: t.identifier,
						ordinal: t.string
					})
				)
			})
			.views(self => ({
				get items() {
					return self.Genre
				},
				get itemFirst() {
					return self.Genre?.[0]
				},
				get mainCategoryId() {
					return self.Genre[0]?.type
				}
			}))
	)
})

export const Title_AllGenres_Part = t
	.model({
		AllGenres: t.maybeNull(
			t
				.model("Title_AllGenres_Part__AllGenres", {
					AllGenre: t.array(
						t.model("Title_AllGenres_Part__AllGenre", {
							Value: t.string,
							href: t.identifier,
							level: t.string
						})
					)
				})
				.views(self => ({
					get items() {
						return self.AllGenre.filter(i => i.Value.toLowerCase() !== "undefined")
					}
				}))
		)
	})
	.views(self => ({
		get AllGenre() {
			return self.AllGenres?.items?.[0] ?? null
		}
	}))

export const Title_DurationInSeconds_Part = t
	.model({
		DurationInSeconds: t.maybeNull(t.number)
	})
	.views(self => ({
		get DurationInMinutes() {
			return self.DurationInSeconds === null ? null : Math.floor(self.DurationInSeconds / 60)
		}
	}))

export const Title_OriginalLanguages_Part = t
	.model({
		OriginalLanguages: t.maybeNull(
			t
				.model("Title_OriginalLanguages_Part__OriginalLanguages", {
					OriginalLanguage: t.array(t.string)
				})
				.views(self => ({
					get items() {
						return self.OriginalLanguage
					}
				}))
		)
	})
	.views(self => ({
		get OriginalLanguage() {
			return self.OriginalLanguages?.items?.[0] ?? null
		},
		get OriginalLanguageName() {
			return self.OriginalLanguage === null ? null : iso6391.getNameByCode(self.OriginalLanguage)
		}
	}))

export const Title_SeriesCollection_Collection = t
	.model({
		EpisodeName: t.maybeNull(t.string),
		SeriesCollection: t.maybeNull(
			t
				.model("Title_SeriesCollection_Part__SeriesCollection", {
					resultCount: t.number,
					Series: t.array(
						t.model("Series", {
							id: t.identifier,
							Name: t.string,
							Pictures: t.maybeNull(Pictures),
							RelationOrdinal: t.maybeNull(t.number),
							Type: t.maybeNull(t.string),
							ParentSeriesCollection: t.maybeNull(
								t
									.model("Title_SeriesCollection_Part__ParentSeriesCollection", {
										resultCount: t.number,
										Series: t.array(
											t.model("Title_SeriesCollection_Part__ParentSerie", {
												id: t.identifier,
												RelationOrdinal: t.number
											})
										)
									})
									.views(self => ({
										get Serie() {
											return self.Series?.[0] ?? null
										},
										get RelationOrdinal() {
											return self.Serie?.RelationOrdinal ?? null
										}
									}))
							)
						})
					)
				})
				.views(self => ({
					get Serie() {
						return self.Series?.[0] ?? null
					},
					get ParentSerie() {
						return self.Serie?.ParentSeriesCollection?.Serie ?? null
					},
					get RelationOrdinal() {
						return self.Serie?.RelationOrdinal ?? null
					}
				}))
		)
	})
	.views(self => ({
		get EpisodeNum() {
			return self.SeriesCollection?.RelationOrdinal ?? null
		},
		get EpisodeTitle() {
			return self.SeriesCollection?.Serie?.Name ?? null
		},
		get SeasonNum() {
			return self.SeriesCollection?.ParentSerie?.RelationOrdinal ?? null
		},
		get isSeries() {
			return self.SeriesCollection && self.SeriesCollection.Serie ? true : false
		},
		get SeriesId() {
			return self?.SeriesCollection?.Serie?.id ?? null
		},
		get isTitleSecondary() {
			return self?.SeasonNum || self?.EpisodeNum || self?.EpisodeName ? true : false
		}
	}))

export const Title_Credits_Part = t.model({
	Credits: t.maybeNull(
		t
			.model("Title_Credits_Part__Credits", {
				Credit: t.array(
					t
						.model("Title_Credits_Part__Credit", {
							Value: t.identifier,
							ordinal: t.string,
							givenName: t.maybeNull(t.string),
							familyName: t.maybeNull(t.string),
							type: t.string
						})
						.views(self => ({
							get Name() {
								if (self.familyName === null && self.givenName === null) {
									return self.Value
								}
								if (self.familyName === null) {
									return self.givenName
								}
								return self.givenName + " " + self.familyName
							}
						}))
				)
			})
			.views(self => ({
				get Directors() {
					return self.Credit.filter(i => i.type === "DIRECTOR" || i.type === "DIRECTOR-DISPLAY")
				},
				get Actors() {
					return self.Credit.filter(i => i.type === "ACTOR")
				}
			}))
	)
})

export const Title_Ratings_Part = t
	.model({
		Ratings: t.maybeNull(
			t
				.model("Title_Ratings_Part__Ratings", {
					Rating: t.array(
						t.model("Title_Ratings_Part__Rating", {
							authority: t.identifier,
							inferredMinimumAge: t.number,
							value: t.string
						})
					)
				})
				.views(self => ({
					get items() {
						return self.Rating
					},
					get minimumAge() {
						return self.items?.[0]?.inferredMinimumAge ?? null
					}
				}))
		)
	})
	.views(self => ({
		get OriginalLanguage() {
			return self.OriginalLanguages?.items?.[0] ?? null
		}
	}))

export const Title = t.compose(
	"Title",
	Title_DurationInSeconds_Part,
	t.model({
		id: t.identifier,
		Name: t.string
	})
	//.views(self => ({}))
)

export const TitleFull = t.compose(
	"TitleFull",
	Title,
	Title_SeriesCollection_Collection,
	Title_ProductionLocations_Part,
	Title_Genres_Part,
	Title_AllGenres_Part,
	Title_OriginalLanguages_Part,
	Title_Credits_Part,
	Title_Ratings_Part,
	t.model({
		SeriesCount: t.maybeNull(t.number),
		ProductionDate: t.maybeNull(t.string),
		Pictures: t.maybeNull(Pictures),
		LongSynopsis: t.maybeNull(t.string),
		MediumSynopsis: t.maybeNull(t.string),
		ShortSynopsis: t.maybeNull(t.string),
		OnWishList: t.boolean
	})
)

export const Titles_Collection = (prefix = "", { _Title = Title } = {}) =>
	t.model("Titles_Collection", {
		resultCount: t.number,
		Title: t.array(_Title)
	})

export const Titles = Titles_Collection("Title", { _Title: Title })

export const TitlesFull = Titles_Collection("TitleFull", { _Title: TitleFull })
