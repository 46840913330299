import { flow /*, observable, computed, action */ } from "mobx"
import { rootStore } from "store/RootStore"
import { TraxisCpeIdMixin } from "store/api/mixin/TraxisCpeIdMixin"
// MTVW-585: 1 retry
import { fetchWithRetry, DEFAULT_RETRIES, DEFAULT_TIMEOUT } from "./FetchWithRetry"
import { alias, list, object, /*createModelSchema, getDefaultModelSchema,*/ serializable, deserialize, primitive } from "serializr"
import { moment } from "store/util/moment"
import { urlFixHttpProtocolMismatch } from "store/util/urlFixHttpProtocolMismatch"
import { serviceUrls } from "store/qlapi/ServiceUrls"
import { getQlHeaders } from "store/qlapi/QlHeaders"

const nDash = "\u2013"

class SearchEvent {
   @serializable id = null
   @serializable(alias("channel_id", primitive())) channelId = null
   @serializable(alias("content_id", primitive())) contentId = null
   @serializable start = null
   @serializable end = null
   @serializable(alias("duration", primitive())) duration = null
   @serializable(alias("is_hd", primitive())) isHd = null
   // MTVW-508
   @serializable(alias("start_millis", primitive())) AvailabilityStartTs = null
   @serializable(alias("end_millis", primitive())) AvailabilityEndTs = null

   get dateAndTime() {
      return moment(this.start).format(`dd. DD.MM.YYYY, HH:mm`) + nDash + moment(this.end).format(`HH:mm`)
   }
   get eventTime() {
      return moment(this.start).format(`HH:mm`) + nDash + moment(this.end).format(`HH:mm`)

   }
   get eventType() {
      // MTVW-508
      /*
      const timeCurr = rootStore.time.getTimeStampTick(60),
         timeStart = moment(this.start).utc().valueOf(),
         timeEnd = moment(this.end).utc().valueOf()
      //console.debug("timeCurr %s, timeStart %s, timeEnd %s, %s", timeCurr, timeStart, timeEnd, timeStart <= timeCurr && timeEnd > timeCurr ? "c" : timeStart >= timeCurr ? "n" : "p")
      return timeStart <= timeCurr && timeEnd > timeCurr ? "c" : timeStart >= timeCurr ? "n" : "p"
      */
      const timeCurr = moment().utc().valueOf()
      return this.AvailabilityStartTs <= timeCurr && this.AvailabilityEndTs > timeCurr ? "c" : this.AvailabilityStartTs >= timeCurr ? "n" : "p"
   }

   get durationInPercent() {
      if (this.eventType !== "c") return 0
      const timeCurr = rootStore.time.getTimeStampTick(60),
         timeStart = moment(this.start).utc().valueOf(),
         timeEnd = moment(this.end).utc().valueOf()
      return parseFloat((100 * (timeCurr - timeStart) / (timeEnd - timeStart)).toFixed(2))
   }
}

class SearchContent {
   @serializable(alias("id", primitive())) contentId = null
   @serializable(alias("title", primitive())) Title = null
   @serializable rating = null
   @serializable description = null
   @serializable synopsis = null
   @serializable(alias("poster_url", primitive())) _posterUrl = null
   @serializable(alias("series_name", primitive())) SeriesName = null
   @serializable(alias("series_id", primitive())) SeriesId = null
   @serializable(alias("episode_name", primitive())) EpisodeName = null
   // only for programs/now
   @serializable(alias("season", primitive())) Season = null
   // only for programs/now
   @serializable(alias("episode", primitive())) Episode = null
   @serializable duration = null
   @serializable(list()) genres = []
   @serializable language = null
   @serializable(alias("language_id", primitive())) languageId = null
   @serializable(list()) actors = []
   @serializable(list()) writers = []
   @serializable(list()) directors = []
   @serializable(alias("production_year", primitive())) productionYear = null
   @serializable(alias("production_locations", list())) roductionLocations = null
   @serializable(alias("production_location_ids", list())) roductionLocationIds = null
   @serializable(alias("series_container_id", primitive())) seriesContainerId = null
   @serializable(alias("series_collection_id", primitive())) seriesCollectionId = null
   @serializable(alias("series_recording_id", primitive())) seriesRecordingId = null
   @serializable(alias("season_id", primitive())) primitive = null
   @serializable(alias("program_type", primitive())) programType = null
   @serializable(alias("series_type", primitive())) seriesType = null

   // make sure to return secure url
   get posterUrl() {
      return urlFixHttpProtocolMismatch(this._posterUrl)
   }
}

class EventWithContent {
   @serializable(object(SearchEvent)) event
   @serializable(object(SearchContent)) content
}

class Channel {
   @serializable id = null
   @serializable(alias("name", primitive())) Name = null
   @serializable(alias("is_adult", primitive())) IsAdult = null
   // is_hd is supported in the channels
   @serializable(alias("is_hd", primitive())) IsHd = null
   @serializable(alias("logo_url", primitive())) logoUrl = null
   @serializable(alias("channel_number", primitive())) LogicalChannelNumber = null
   @serializable(alias("replay_into_past", primitive())) ReplayIntoPast = null
   // TODO: Needed? Not returned by MS
   IsOnRecordingOptinList = true
}

class ContentDetails {
   //MTVW-272
   @serializable id = null
   @serializable(alias("title", primitive())) Title = null
   @serializable(alias("description", primitive())) LongSynopsis = null
   @serializable(alias("poster_url", primitive())) BoxCover = null
   @serializable(alias("rating", primitive())) Rating = null
   @serializable series_name = null
   @serializable(alias("series_id", primitive())) SeriesId = null
   @serializable(alias("episode_name", primitive())) EpisodeName = null
   @serializable(alias("season", primitive())) Season = null
   @serializable(alias("episode", primitive())) Episode = null
   @serializable duration = null
   @serializable(alias("genres", list())) Genres = null
   @serializable(alias("synopsis", primitive())) ShortSynopsis = null
   @serializable(alias("language", primitive())) OriginalLanguage = null
   @serializable(alias("production_year", primitive())) ProductionDate = null
   @serializable(alias("production_locations", list())) ProductionLocations = null
   // for episode.content in series request
   @serializable(alias("series_collection_id", primitive())) seriesCollectionId = null
   @serializable(alias("series_container_id", primitive())) seriesContainerId = null
   @serializable(alias("series_recording_id", primitive())) seriesRecordingId = null
   @serializable(alias("season_id", primitive())) seasonId = null
   @serializable language_id = null
   @serializable(alias("production_location_ids", list())) ProductionLocationsIds = null
   @serializable(alias("program_type", primitive())) programType = null
   @serializable(alias("series_type", primitive())) seriesType = null
   @serializable(alias("actors", list())) Actors = null
   @serializable(alias("writers", list())) Writers = null
   @serializable(alias("directors", list())) Directors = null
   @serializable SeriesMoniker = null

   // obsolete
   //@serializable(alias("is_hd", primitive())) IsHD = null
   MovieType = null
}

class Person {
   @serializable name = null
   @serializable(list()) roles = []
   @serializable(list()) genres = []
   // MTVW-631
   @serializable(list()) channels = []
}

class Series {
   @serializable id = null
   @serializable(alias("title", primitive())) Title = null
   @serializable(alias("poster_url", primitive())) _posterUrl = null
   @serializable language = null
   @serializable(list()) genres = []
   @serializable(alias("event_count", primitive())) eventCount = null
   @serializable(alias("episode_count", primitive())) episodeCount = null
   @serializable(alias("season_count", primitive())) seasonCount = null
   @serializable(alias("program_type", primitive())) programType = null
   @serializable(alias("series_type", primitive())) seriesType = null
   // MTVW-631
   @serializable(list()) channels = []
   // make sure to return secure url
   get posterUrl() {
      return urlFixHttpProtocolMismatch(this._posterUrl)
   }
}

// eslint-disable-next-line no-unused-vars
class Episode {
   @serializable score = 0
   @serializable(object(SearchEvent)) event
   @serializable(object(ContentDetails)) content
}

// TopData for type "series" is currently the same as Series
class TopData {
   // serializr doesn't support unions: https://github.com/symfony/symfony/issues/40474

   // for type: "event"
   @serializable(object(SearchEvent)) event
   @serializable(object(SearchContent)) content

   // for type: "series"
   @serializable id = null
   @serializable(alias("title", primitive())) Title = null
   @serializable(alias("poster_url", primitive())) _posterUrl = null
   @serializable language = null
   // NO rating, but score
   //@serializable rating = null
   @serializable score = null
   @serializable(list()) genres = []
   @serializable(alias("event_count", primitive())) eventCount = null
   @serializable(alias("episode_count", primitive())) episodeCount = null
   @serializable(alias("season_count", primitive())) seasonCount = null
   @serializable(alias("program_type", primitive())) programType = null
   @serializable(alias("series_type", primitive())) programType = null
   // MTVW-631
   @serializable(list()) channels = []
   // for type: "genre"
   // ID not used, id as defined above
   //@serializable ID = null
   @serializable name = null
   @serializable(list()) related = null

   // for type: "credits"
   // name from "genre" above
   //@serializable name = null
   @serializable(list()) roles = null
   @serializable(list()) genres = null

   // for type: "channel"
   // defined above
   //@serializable id = null
   //@serializable name = null
   @serializable(alias("is_adult", primitive())) isAdult = null
   @serializable(alias("is_hd", primitive())) isHd = null
   @serializable(alias("logo_url", primitive())) _logoUrl = null
   @serializable(alias("channel_number", primitive())) channelNumber = null
   @serializable(alias("replay_into_past", primitive())) replayIntoPast = null

   // make sure to return secure url
   get posterUrl() {
      return urlFixHttpProtocolMismatch(this._posterUrl)
   }

   get logoUrl() {
      return urlFixHttpProtocolMismatch(this._logoUrl)
   }
}

class SearchMatch {
   //@serializable(primitive(myHandler)) type = null
   @serializable type = null
   //@serializable(object(TopData, myHandler)) data
   @serializable(object(TopData)) data
}

class Genre {
   // ID not used, use id
   //@serializable ID = null
   @serializable id = null
   @serializable name = null
   @serializable(list()) related = null
}

/*order, should be fixed in UI!
      [this.CATEGORY_TOP]: 'Top Results',
      [this.CATEGORY_SERIES]: 'Series',
      [this.CATEGORY_MOVIES]: 'Movies',
      [this.CATEGORY_SPORTS]: 'Sports',
      [this.CATEGORY_ACTORS]: 'People',
      [this.CATEGORY_GENRES]: 'Genres',
      [this.CATEGORY_CHANNELS]: 'Channels',
*/
class AdvancedSearchResult {
   @serializable(list(object(SearchMatch))) top
   @serializable(list(object(Series))) series
   @serializable(list(object(EventWithContent))) movies
   //@serializable(list(object(EventWithContent))) sports
   @serializable(list(object(SearchMatch))) sports
   @serializable(list(object(Person))) actors
   // CHECK: was array before
   //@serializable(list()) genres = []
   @serializable(list()) genres = []
   @serializable(list(object(Channel))) channels
   //@serializable(list(object(EventWithContent))) programs
}

class ActorResult {
   @serializable(object(Person)) person
   @serializable(list(object(SearchMatch))) contents = null
}

class GenreWithEvents {
   @serializable(object(Genre)) genre
   // CHANGE in search MS
   @serializable(list(object(SearchMatch))) contents
   //@serializable(list(object(EventWithContent))) contents
}

class PersonWithEvents {
   @serializable(object(Person)) person
   // CHANGE in search MS
   @serializable(list(object(SearchMatch))) contents
   //@serializable(list(object(EventWithContent))) contents
}

const STD_HEADERS = {
   'Accept': 'application/json',
   // no-cache would not generate 304 on all browsers
   //'pragma': 'no-cache', 'cache-control': 'no-cache'
}
export class SearchService extends TraxisCpeIdMixin {

   get xHeaders() {
      // make a copy of STD_HEADERS, otherwise STD_HEADERS would be changed!
      const headers = {}
      Object.assign(headers, STD_HEADERS)
      Object.assign(headers, getQlHeaders())
      return headers
   }

   version = flow(function* (retryCount = DEFAULT_RETRIES) {
      const config = {
         url: serviceUrls.searchUrl + "version",
         fetchOptions: {
            method: 'GET',
            headers: this.xHeaders,
         },
         retryCount: retryCount,
         timeout: DEFAULT_TIMEOUT,
         codes: ["SearchService version"],
         service: "SearchService",
         createException: true,
         displayError: true
      }

      // eslint-disable-next-line no-unused-vars
      const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
      return `${resultJson["build_tag"]} (${resultJson["build_date"]})`
   })

   searchAsync = flow(function* (query, genre = "", actor = "", category, retryCount = DEFAULT_RETRIES) {
      console.debug("@@searchAsync() ->")
      //console.debug("category %o", category)

      let url = serviceUrls.searchUrl
      switch (category) {
         case rootStore.page.Search.CATEGORY_EVENTS:
            url += `programs/search/events?keywords=${query}`
            break
         case rootStore.page.Search.CATEGORY_MOVIES:
            url += `programs/search/scopes/movie?keywords=${query}`
            break
         case rootStore.page.Search.CATEGORY_SERIES:
            url += `programs/search/scopes/series?keywords=${query}`
            break
         case rootStore.page.Search.CATEGORY_EPISODES:
            url += `programs/search/scopes/episode?keywords=${query}`
            break
         default:
            console.error("INVALID SEARCH CATEGORY %s", category)
            return null
      }

      if (genre !== "") url += `&genre=${genre}`
      if (actor !== "") url += `&actor=${actor}`
      //console.debug("searchAsync: category %s, query %s, url %s", category, query, url)

      const config = {
         url: url,
         fetchOptions: {
            method: 'GET',
            headers: this.xHeaders,
         },
         retryCount: retryCount,
         timeout: DEFAULT_TIMEOUT,
         codes: ["SearchService searchAsync"],
         service: "SearchService",
         createException: true,
         displayError: true
      }
      // eslint-disable-next-line no-unused-vars
      const [response, resultJson, requestId, error] = yield fetchWithRetry(config)

      //console.debug("searchAsync resultJson %o, url %s", resultJson, url)
      /*
      createModelSchema(EventWithContent, {
         entries: list(object(EventWithContent))
      })
      */

      // JSON.parse(resultJson) would fail
      return deserialize(EventWithContent, resultJson, (err, result) => {
         // amendments could be done here 
         //if (result?.length > 0) result[0].event.newProp = "test prop"; console.debug("cb err %o result: %o", err, result)
      })
   })

   overviewAsync = flow(function* (query, retryCount = DEFAULT_RETRIES) {
      const url = serviceUrls.searchUrl + `overview?keywords=${query}`
      //console.debug("overviewAsync: query %s, url %s", query, url)
      const config = {
         url: url,
         fetchOptions: {
            method: 'GET',
            headers: this.xHeaders,
         },
         retryCount: retryCount,
         timeout: DEFAULT_TIMEOUT,
         codes: ["SearchService overviewAsync"],
         service: "SearchService",
         createException: true,
         displayError: true
      }

      // eslint-disable-next-line no-unused-vars
      const [response, resultJson, requestId, error] = yield fetchWithRetry(config)

      console.debug("overview resultJson %o, url %s", resultJson, url)
      /*
      createModelSchema(EventWithContent, {
         entries: list(object(EventWithContent))
      })
      */

      // JSON.parse(resultJson) would fail
      //fixTop = []
      const result = deserialize(AdvancedSearchResult, resultJson, (err, result) => {
         // amendments could be done here 
         //if (result?.length > 0) result[0].event.newProp = "test prop"; console.debug("cb err %o result: %o", err, result)
      })
      /*
      console.debug("fixTop", fixTop, Object.assign([], fixTop))
      result.top = Object.assign([], fixTop)
      */
      return result
   })

   // requires exact match, otherwise 404
   infoActorAsync = flow(function* (query, retryCount = DEFAULT_RETRIES) {
      const config = {
         url: serviceUrls.searchUrl + `people/${query}`,
         fetchOptions: {
            method: 'GET',
            headers: this.xHeaders,
         },
         retryCount: retryCount,
         timeout: DEFAULT_TIMEOUT,
         codes: ["SearchService infoActorAsync"],
         service: "SearchService",
         createException: true,
         displayError: false
      }

      // exception for 404 might be generated
      try {
         // eslint-disable-next-line no-unused-vars
         const [response, resultJson, requestId, error] = yield fetchWithRetry(config)

         // JSON.parse(resultJson) would fail
         return deserialize(PersonWithEvents, resultJson, (err, result) => {
            // amendments could be done here 
            //if (result?.length > 0) result[0].event.newProp = "test prop"; console.debug("cb err %o result: %o", err, result)
         })
      }
      catch (e) {
         return null
      }
   })

   // requires exact match, otherwise 404
   infoGenreAsync = flow(function* (query, retryCount = DEFAULT_RETRIES) {
      const config = {
         url: serviceUrls.searchUrl + `genre/${query}`,
         fetchOptions: {
            method: 'GET',
            headers: this.xHeaders,
         },
         retryCount: retryCount,
         timeout: DEFAULT_TIMEOUT,
         codes: ["SearchService infoGenreAsync"],
         service: "SearchService",
         createException: true,
         displayError: false
      }

      // exception for 404 might be generated
      try {
         // eslint-disable-next-line no-unused-vars
         const [response, resultJson, requestId, error] = yield fetchWithRetry(config)

         // JSON.parse(resultJson) would fail
         //console.debug("infoGenreAsync", resultJson)
         return deserialize(GenreWithEvents, resultJson, (err, result) => {
            // amendments could be done here 
            //if (result?.length > 0) result[0].event.newProp = "test prop"; console.debug("cb err %o result: %o", err, result)
         })
      }
      catch (e) {
         return null
      }
   })

   // requires exact match, otherwise 404
   searchActorInGenreAsync = flow(function* (actor, genre, retryCount = DEFAULT_RETRIES) {
      const config = {
         url: serviceUrls.searchUrl + `people/${actor}?fmt=condensed&genre=${genre}`,
         fetchOptions: {
            method: 'GET',
            headers: this.xHeaders,
         },
         retryCount: retryCount,
         timeout: DEFAULT_TIMEOUT,
         codes: ["SearchService searchActorInGenreAsync"],
         service: "SearchService",
         createException: true,
         displayError: false
      }

      // exception for 404 might be generated
      try {
         // eslint-disable-next-line no-unused-vars
         const [response, resultJson, requestId, error] = yield fetchWithRetry(config)

         // JSON.parse(resultJson) would fail
         return deserialize(ActorResult, resultJson, (err, result) => {
            // amendments could be done here 
            //if (result?.length > 0) result[0].event.newProp = "test prop"; console.debug("cb err %o result: %o", err, result)
         })
      }
      catch (e) {
         return null
      }
   })

   // requires exact match, otherwise 404
   searchGenreInGenreAsync = flow(function* (genre, subGenre, retryCount = DEFAULT_RETRIES) {
      const config = {
         url: serviceUrls.searchUrl + `genre/${genre}?fmt=condensed&genre=${subGenre}`,
         fetchOptions: {
            method: 'GET',
            headers: this.xHeaders,
         },
         retryCount: retryCount,
         timeout: DEFAULT_TIMEOUT,
         codes: ["SearchService searchGenreInGenreAsync"],
         service: "SearchService",
         createException: true,
         displayError: false
      }

      // exception for 404 might be generated
      try {
         // eslint-disable-next-line no-unused-vars
         const [response, resultJson, requestId, error] = yield fetchWithRetry(config)

         // JSON.parse(resultJson) would fail
         return deserialize(GenreWithEvents, resultJson, (err, result) => {
            // amendments could be done here 
            //if (result?.length > 0) result[0].event.newProp = "test prop"; console.debug("cb err %o result: %o", err, result)
         })
      }
      catch (e) {
         return null
      }
   })
}
