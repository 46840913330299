import React from "react"
import { CreateComponent } from "components/utils/CreateComponent"
import { TvGuideConf } from "store/page/TvGuide"
import { moment, adjustUtcStartDay } from "store/util/moment"

// MTV-2856 no need a top value  or an offset  the div are perfectly aligned
//const TvGuideNowMarkerLineTop = 51;
const styles = () => ({
	TvGuideNowMarkerWrapper: { position: "absolute", zIndex: 7, pointerEvents: "none", maxWidth: "100%" }, //top:51
	TvGuideNowMarkerLine: {
		left: "0px",
		borderRight: "3px solid #FFC1077F",
		background: "rgba(255, 255, 255, 0.08)",
	}
})

export const TvGuideNowMarker = CreateComponent(styles, false, true, ({ classes, page }) => {
	// use page observable so that marker gets updated every minute
	const actualHour = page.currentHour
	let nowMarkerStyle = {}
	let nowMarkerLine = {}
	const selectedDayTs = page.getSelectedDayTs
	//MTVW-414:fixed problem of nowMarker in the previous day if the hour is between 00: 00 and 01: 00
	//const dateCurrTs = page.getDateCurrTs
	const dateCurrTs = adjustUtcStartDay(moment().valueOf()).valueOf()

	if (selectedDayTs === dateCurrTs) {
		const visibleLeftHour = page.getVisibleLeftHour
		const visibility = actualHour >= visibleLeftHour ? 'visible' : 'hidden'
		nowMarkerStyle = { width: `${(actualHour - visibleLeftHour) * 360}px`, visibility: `${visibility}` }
		// hide border if marker line is on the right
		if (Math.min(actualHour - page.getVisibleHour) > 0) nowMarkerLine = { borderRight: "none", }
	} else {
		nowMarkerStyle = selectedDayTs < dateCurrTs ? { width: '100%', visibility: 'visible' } :
			{ width: '0px', visibility: 'hidden' }
		nowMarkerLine = { borderRight: "none", } // hide border if marker line is on the left
	}
	return (
		<>
			<div className={classes.TvGuideNowMarkerWrapper} style={nowMarkerStyle} >
				<div
					className={classes.TvGuideNowMarkerLine} data-test="tvguide_now_marker_line"
					style={{
						height: page.ChannelsFromList?.length * TvGuideConf.rowHeightInPx,
						...nowMarkerLine // hide marker line on the borders
					}}
				/>
			</div>
		</>
	)
})
