/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-03
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-10
 */

import axios from "axios"
import { serviceUrls } from "store/qlapi/ServiceUrls"

export const TraxisAjaxCancel = axios.CancelToken

export const TraxisAjax = axios.create({
	nameGroup: "TraxisAjax",
	url: serviceUrls.traxisUrl,
	method: "post",
	// MTVW-633: cancelToken is deprecated, use AbortController
	//timeout: 40000,
	responseType: "text", // text not json due to hash of resp
	headers: {
		"Content-Type": "text/xml;charset=UTF-8"
		//"If-None-Match": "test"
	},
	transformResponse: [data => data]
	//  validateStatus: function(status) {
	//    return status < 300
	//  }
})
