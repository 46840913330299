// l10n status: partial
import { CreateComponent } from "components/utils/CreateComponent"
import React, { useEffect, useRef } from "react"
import "./../../../css/tvGuide.css"
import { adjustUtc, adjustUtcStartDay, moment } from "store/util/moment"
import Velocity from "velocity-animate"
import "velocity-animate/velocity.ui.min.js"
import { stopWatch } from "utils/TestSupport"
import throttle from "lodash/throttle"
import { l10n } from "store/lang/L10n"

const cellWidth = 56 + 8 // cell width + margin
//const easingOption = "easeOutCirc"
//const easingOption = [.17, .67, .83, .67]
const easingOption = [0, 0.75, 0, 1]
let mouseMoving = false // for controlling the click or the drag in the day cell
let animationActive = false
let dayQueue = []

function enqueueDay(page, dayTs) {
	stopWatch.lap("enqueueDay")
	dayQueue.push(dayTs)
	dayQueue = [...new Set(dayQueue)] // MTVW-331-queue delete the duplicate in case of fast click
	dequeueDay(page)
}

function dequeueDay(page) {
	if (!animationActive) {
		const dayTs = dayQueue.shift()
		if (dayTs) {
			//console.debug("dayTs", moment(dayTs).toString())
			page.setVisibleTime(dayTs)
		}
	}
}

function handleClickOnDay(dayDateTs, page, index, refDayCell) {
	stopWatch.start("handleClickOnDay")
	// Don't do anything if clicked on the selected day
	// TODO: remove next 2 lines?
	const classesOfCell = document.getElementById(`cell${index}`)?.className
	if (!classesOfCell) return
	if (dayDateTs === page.selectedDayTs) return
	//currentCell = index

	//page.setVisibleTime(dayDateTs)
	enqueueDay(page, dayDateTs)
	/* caused issues with fast clicks, this is now synchronized with the call to page.setVisibleTime(dayDateTs)
	const offs = (index + 0.5) * cellWidth - document.getElementById("dateSelector").clientWidth / 2
	// start animation in hour bars simultaneously (no need to wait for completion)
	velocityAnimation(document.getElementById("dateSelector"), offs, 400, () => {
		//page.setVisibleTime(dayDateTs)
	})
	*/
}

function velocityAnimation(elem, left, duration, onComplete = null) {
	Velocity(elem, { scrollLeft: left + "px" }, {
		duration: duration,
		easing: easingOption,
		complete: function (val) {
			if (onComplete) onComplete()
		}
	})
}

const DayCell = (props) => {
	const { daysOffset, page, index, daysBefore } = props
	const refDayCell = useRef(null)

	// MTVW-414
	//const dayDateTs = moment().utc().startOf('day').add(daysOffset, 'd').valueOf()
	//const dayDate = moment().utc().add(daysOffset, 'd')
	const dayDateTs = adjustUtcStartDay().add(daysOffset, 'd').valueOf()
	const dayDate = adjustUtc().add(daysOffset, 'd')
	const labelDayDate = dayDate.format('dd ')
	const labelDayMonth = daysOffset === 0 ? l10n.commonLabelToday.toUpperCase() : moment(dayDate).format('D MMM')

	let cssClasses = ["DayCell"]
	if (dayDateTs === page.selectedDayTs) {
		cssClasses.push("selectedDay")
	}
	if (daysOffset === 0) {
		cssClasses.push("today")
	}
	if (Math.abs(daysOffset) > daysBefore) {
		cssClasses.push("disableDays")
	}

	return !page.selectedDayTs ? null : (
		<div tabIndex={index}
			ref={refDayCell}
			className={cssClasses.join(" ")}
			date={dayDate.format()}
			id={"cell" + index}
			// MTVW-331-queue use click event instead of mouseUp
			// previously used onMouseUp instead of onclick to differentiate the click from the mouseMove management
			/*
			onMouseUp={() => {
				if (mouseMoving) return
				handleClickOnDay(dayDateTs, page, index, refDayCell)
				mouseMoving = false
			}}
			*/
			onClick={(e) => {
				e.preventDefault()
				e.stopPropagation()
				if (mouseMoving) {
					mouseMoving = false
					return // don't do anything in case of mouse moving
				}
				handleClickOnDay(dayDateTs, page, index, refDayCell)
			}}
		>
			<div className="dayWeek">{labelDayDate}</div>
			<div className="dayDateMonth">{labelDayMonth}</div>
		</div>
	)
}

// global state to survive component refreshes / re-renders
// TODO: put into named object

// handle scroll with mouse drag
let mouseUp = true
// position of the component
let startPositionDrag = 0
let dragCellOffset = 0
// start and end position of the mouse
let startPositionX = 0
let endPositionX = 0
let savedCellScrolled = 0
let currentCell = 0

let touchUp = true
let touchStarted = false

let resizeTimer = null

export const DateSelector = CreateComponent(null, false, true, (props) => {
	stopWatch.lap("DateSelector 1")
	//console.debug("in DateSelector %o", props)
	const { daysBefore, daysAfter, page, windowSize } = props
	const refDateSelector = useRef(null)
	// Days are dynamically added according to the screen size,
	// needed as an offset for the overflow to have the first available day in the middle of the screen
	const refAdditionalDisabledDays = useRef(0)
	// Loading refAdditionalDisabledDays can produce a gap of + or - (cellWidth / 2) pixels.
	// It is therefore necessary to know the exact starting point of our first available day
	// UNUSED!
	//const refStartPositionScroll = useRef(0)
	//const refFirstLoad = useRef(true)

	// Load the days and dynamically calculate the required number of days for the overflow 
	// to have the first or last valid day in the middle of the screen
	const calendarDays = []
	// Recalculate the disabled days before and after
	refAdditionalDisabledDays.current = Math.round(windowSize / 2 / cellWidth)
	//refStartPositionScroll.current = refAdditionalDisabledDays.current * cellWidth - (windowSize - cellWidth) / 2
	const startPoint = daysBefore + refAdditionalDisabledDays.current
	for (let i = -startPoint; i <= startPoint; i++) {
		calendarDays.push(i)
	}

	function cellFromPosition(xPos, selectableOnly = true) {
		if (!refDateSelector?.current || !refAdditionalDisabledDays?.current) return
		let cell = Math.floor((refDateSelector.current.scrollLeft + xPos) / cellWidth)
		if (selectableOnly) {
			if (cell < daysBefore - 1) cell = daysBefore - 1
			else if (cell >= refAdditionalDisabledDays.current + daysBefore + daysAfter) cell = refAdditionalDisabledDays.current + daysBefore + daysAfter
		}
		return cell
	}

	function highLightCell(cell) {
		const _dataSelector = document.getElementById("dateSelector" + page.contextName)
		const cells = _dataSelector.getElementsByClassName("DayCell")
		const current = _dataSelector.getElementsByClassName("selectedDay")
		//const diffCell = cell - currentCell
		if (!cells || !current || current?.length === 0) return
		//if (!cells || !current || current?.length === 0 || diffCell === 0) return

		//console.debug("saved %s, current %s, cell %s", savedCellScrolled, currentCell, cell)
		current[0].classList.remove("selectedDay")
		// TODO: can cells[numCellScrolled] be undefined
		cells[cell].classList.add("selectedDay")
	}

	function handleMouseDown(event) {
		if (event.type !== "touchstart") {
			// touch events would cause a warning
			event.preventDefault()
			event.stopPropagation()
		}
		mouseUp = false
		mouseMoving = false

		startPositionDrag = event.type === "touchstart" ? event.targetTouches[0].clientX : event.clientX
		startPositionX = event.type === "touchstart" ? event.targetTouches[0].clientX : event.clientX
		savedCellScrolled = currentCell
		dragCellOffset = currentCell - cellFromPosition(startPositionX, false)
	}

	function handleTouchStart(event) {
		event.stopPropagation()
		//console.debug("handleTouchStart")
		touchUp = false
		touchStarted = true
	}

	function handleTouchEnd(event) {
		event.stopPropagation()
		//console.debug("handleTouchEnd")
		touchUp = true
	}

	function handleMouseUp(event) {
		if (event.type !== "touchend") {
			event.preventDefault()
			event.stopPropagation()
		}
		mouseUp = true
		endPositionX = event.type === "touchend" ? event.changedTouches[0].clientX : event.clientX
		if (mouseMoving) {
			centerCurrentCell()
		}
	}

	function handleMouseLeave(event) {
		event.stopPropagation()
		event.preventDefault()
		if (mouseUp) return
		else /*if (!mouseUp)*/ {
			handleMouseUp(event)
			return
		}
	}

	function handleMouseMove(event) {
		if (event.type !== "touchmove") {
			event.preventDefault()
			event.stopPropagation()
			if (mouseUp) return
			mouseMoving = true
		}
		if (!refDateSelector?.current || !refAdditionalDisabledDays?.current) return

		const xPosition = event.type === "touchmove" ? event.changedTouches[0].clientX : event.clientX

		const delta = xPosition - startPositionX

		endPositionX = startPositionDrag + (startPositionX - xPosition)
		if (refDateSelector.current.scrollLeft + endPositionX < (refAdditionalDisabledDays.current - dragCellOffset + 0.5) * cellWidth) {
			//console.debug("TRUNC left %s, l %s", (refAdditionalDisabledDays.current + 0.5) * cellWidth - refDateSelector.current.scrollLeft, refDateSelector.current.scrollLeft)
			endPositionX = (refAdditionalDisabledDays.current - dragCellOffset + 0.5) * cellWidth - refDateSelector.current.scrollLeft
		}
		else if (refDateSelector.current.scrollLeft + endPositionX > (refAdditionalDisabledDays.current + daysBefore + daysAfter - dragCellOffset + 0.5) * cellWidth) {
			//console.debug("TRUNC right %s, l %s", (refAdditionalDisabledDays.current + daysBefore + daysAfter + 0.5) * cellWidth - refDateSelector.current.scrollLeft, refDateSelector.current.scrollLeft)
			endPositionX = (refAdditionalDisabledDays.current + daysBefore + daysAfter - dragCellOffset + 0.5) * cellWidth - refDateSelector.current.scrollLeft
		}
		let cCell = Math.floor((refDateSelector.current.scrollLeft + endPositionX) / cellWidth)

		cCell = cCell + dragCellOffset
		//console.debug("deltaCell, %s cCell %s, current %s", shift, cCell, currentCell)
		if (cCell !== savedCellScrolled) {
			//console.debug("savedCellScrolled %s, cCell %s", savedCellScrolled, cCell)
			highLightCell(cCell)
			// NOTE: page values will be applied in handleMouseUp by calling centerCurrentCell()
			savedCellScrolled = cCell
			currentCell = cCell
		}
		const offs = refDateSelector.current.scrollLeft - delta
		// scroll to new position
		refDateSelector.current.scrollLeft = offs
		startPositionX = xPosition
	}
	//  stop handle the scroll with mouse drag

	function moveToSelectedDay(animationDuration = 400) {
		stopWatch.lap("start moveToSelectedDay")
		// Determine how many days are passed from today to the selectedDate.

		if (!refDateSelector?.current || !refAdditionalDisabledDays.current) return
		animationActive = true
		// MTVW=414  
		//const toDay = moment().utc().startOf('day')
		const toDay = adjustUtcStartDay()
		const selectedDate = moment(page.getSelectedDayTs)
		const daysDiff = selectedDate.diff(toDay, 'days')
		//const previousCell = currentCell
		currentCell = refAdditionalDisabledDays.current + daysBefore + daysDiff
		//console.debug("moveToSelectedDay %s, %s:%s, %s, %s %s", selectedDate.toString(), page.getDateHour, page.getDateMinute, daysDiff, animationDuration, currentCell)
		//highLightCell(currentCell)
		//console.debug("toDay %o, selectedDate %o, diff %o", toDay, selectedDate, daysDiff)

		const offs = (refAdditionalDisabledDays?.current + daysBefore + daysDiff + 0.5) * cellWidth - refDateSelector.current?.clientWidth / 2
		// avoid bouncing back and forth by applying a threshold
		//const threshold = 10
		//if (Math.abs(offs - refDateSelector.current.leftPos) > threshold || firstLoad /*|| previousCell !== currentCell*/) {
		velocityAnimation(refDateSelector.current, offs, animationDuration, () => {
			setTimeout(() => {
				animationActive = false
				dequeueDay(page)
				stopWatch.lap("moveToSelectedDay done")
			}, 100)
		})
		//refDateSelector.current.scrollLeft = offs
		//}
	}

	useEffect(() => {
		const savedRef = refDateSelector.current
		refDateSelector.current.addEventListener("scroll", handleScroll)
		refDateSelector.current.addEventListener("wheel", throttle(handleWheel), 100)
		moveToSelectedDay(0)

		return (() => {
			savedRef.removeEventListener("scroll", handleScroll) // when unload
			savedRef.removeEventListener("wheel", throttle(handleWheel), 100)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		// TODO: recheck if condition in case of issues
		if (!animationActive) {
			stopWatch.lap("useEffect moveToSelectedDay")
			moveToSelectedDay()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page.selectedDayTs])

	useEffect(() => {
		// MTVW-321: wait until manual sizing has settled
		animationActive = true // prevent scroll
		if (resizeTimer) clearTimeout(resizeTimer)
		resizeTimer = setTimeout(() => {
			moveToSelectedDay(0)
		}, 200)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [windowSize])

	/*
	useLayoutEffect(() => {
		setTimeout(() => { refFirstLoad.current = false }, 500)
	}, [])
	*/

	function centerCurrentCell() {
		if (!refDateSelector?.current || !refAdditionalDisabledDays.current) return
		const offs = (currentCell + 0.5) * cellWidth - refDateSelector.current.clientWidth / 2
		//console.debug("CURRENT %o", document.getElementsByClassName("selectedDay"))
		//console.debug("currentCell %s, %s, %s", currentCell, currentCell - refAdditionalDisabledDays.current - daysBefore, moment(page.selectedDayTs).startOf('day').format("YYYYMMDD"))
		//console.debug("disabled %s, before %s ", refAdditionalDisabledDays.current, daysBefore)
		// MTVW-414
		//const selectedDay = moment().utc().add(currentCell - refAdditionalDisabledDays.current - daysBefore, 'd')
		const selectedDay = adjustUtc().add(currentCell - refAdditionalDisabledDays.current - daysBefore, 'd')
		refDateSelector.current.scrollLeft = offs
		//console.debug("center selectedDay %o", selectedDay, currentCell)
		// MTVW-323: Use setVisibleTime to scroll hour bars
		//page.setSelectedDayTs(selectedDay)
		//console.debug("centerCurrentCell %s", selectedDay.startOf('day').format("YYYYMMDD"))
		page.setVisibleTime(selectedDay.startOf('day').valueOf())
	}

	let scrollTimeout = null
	function handleScroll(event) {
		event.stopPropagation()
		event.preventDefault()
		if (!refDateSelector?.current || refAdditionalDisabledDays?.current || mouseMoving || animationActive || !touchStarted) return

		//console.debug("onScroll %s, %s, %o", refDateSelector.current.scrollLeft, document.getElementById("dateSelector").scrollLeft, event)
		const offs = (refAdditionalDisabledDays.current + daysBefore + 0.5) * cellWidth - refDateSelector.current.clientWidth / 2 - refDateSelector.current.scrollLeft
		let cCell = refAdditionalDisabledDays.current + daysBefore - Math.floor((offs + cellWidth / 2) / cellWidth)
		if (cCell < refAdditionalDisabledDays.current) {
			cCell = refAdditionalDisabledDays.current
		}
		else if (cCell > refAdditionalDisabledDays.current + daysBefore + daysAfter) {
			cCell = refAdditionalDisabledDays.current + daysBefore + daysAfter
		}
		//console.debug("CELL %s, before %s", cCell, daysBefore)

		highLightCell(cCell)

		if (scrollTimeout) clearTimeout((scrollTimeout))
		scrollTimeout = setTimeout(() => {
			currentCell = cCell
			if (touchUp) {
				centerCurrentCell()
				touchStarted = false
			}
			// MTVW-323: increase timeout
		}, 300)
	}

	function handleWheel(event) {
		touchStarted = true
		// moving is done in in handleScroll
	}

	stopWatch.lap("DateSelector before render")
	return (
		<>
			{/*
			<div className={`dateSelector ${refFirstLoad.current ? '' : 'scrollAnimation'}`}
			*/}
			<div className={`dateSelector scrollAnimation`}
				id={"dateSelector" + page.contextName}
				ref={refDateSelector}
				onMouseDown={handleMouseDown}
				onMouseMove={handleMouseMove}
				onMouseUp={handleMouseUp}
				onMouseLeave={handleMouseLeave}
				/*
				onTouchMove={handleMouseMove}
				*/
				onTouchStart={handleTouchStart}
				onTouchEnd={handleTouchEnd}
			>
				{
					calendarDays.map((item, index) => {
						return (
							<DayCell
								daysOffset={item}
								// key will be applied implicitly
								key={index}
								index={index}
								page={page}
								daysBefore={daysBefore}
							/>
						)
					})
				}
				{stopWatch.lap("DateSelector after render")}
			</div>
		</>
	)
})


/*
function makeStruct(names) {
	const items = names.split(' ')
	var count = items.length;
	function constructor() {
		for (var i = 0; i < count; i++) {
			this[items[i]] = arguments[i]
		}
	}
	return constructor;
}

function Queue() {
	this.items = []

	Queue.prototype.enqueue = function (e) {
		this.items.push(e)
	}

	Queue.prototype.dequeue = function () {
		if (!this.isEmpty()) return this.items.shift()
		return null
	}

	Queue.prototype.isEmpty = function () {
		return this.items.length === 0
	}

	Queue.prototype.front = function () {
		return !this.isEmpty() ? this.items[0] : null
	}

	Queue.prototype.tail = function () {
		const len = this.items.length
		const item = len > 0 ? this.items[len - 1] : null
		//console.debug("TAIL length %s, item %o", len, item)
		return item
	}

	Queue.prototype.length = function () {
		return this.items.length
	}
}
*/


/*
let dPos = 0
let dTs = 0
let eventQueue = null
if (!eventQueue) {
	eventQueue = new Queue()
}
else {
	const tail = eventQueue.tail()
	//console.debug("from tail item %o, %s, %s, %s, %s", tail, tail.xPos, tail.ts, tail.dPos, tail.dTs)
	dPos = tail.xPos - xPosition
	dTs = tail.ts - event.timeStamp
}
const QueueEntry = makeStruct("xPos, ts, dPos, dTs")
const item = new QueueEntry(xPosition, event.timeStamp, dPos, dTs)
//console.debug("new dPos %s, dTs %s, item %o", dPos, dTs, item)

eventQueue.enqueue(item)
*/
