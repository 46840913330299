/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-09
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-09
 */

import platform from "platform"

export const platformDescription = import.meta.env.VITE_PLATFORM_NAME !== "" ? import.meta.env.VITE_PLATFORM_NAME : `Web ${platform.description}`
