import { types as t } from "mobx-state-tree"
import { TraxisAbstract } from "store/api/TraxisAbstract"

const UpdateProfilePropertyModel = t.model({})

export class UpdateProfileProperty extends TraxisAbstract {
	//_onStartCreate = true
	//_onStartRemove = true

	get _model() {
		return UpdateProfilePropertyModel
	}

	_getParseResp(data) {
		return data
	}

	//_getHashReq(data) {
	//	return ``
	//}

	get tokenId() {
		return this._oVars.tokenId
	}

	getXmlReq() {
		return `<?xml version="1.0" encoding="utf-8"?>
<Request xmlns="urn:eventis:traxisweb:1.0">
    <Identity>
        <ProfileId>${this.profileId}</ProfileId>
    </Identity>
    <ActionQuery resourceType="Profile" resourceId="${this.profileId}" actionName="Update">
        <Arguments>
            <Argument name="${this._oVars.property}">${this._oVars.newValue}</Argument>
        </Arguments>
    </ActionQuery>
</Request>
`
	}
}
