import { types as t } from "mobx-state-tree"

export const EventDetailsMixin = t
	.model("EventDetailsMixin", {
		isTextExpanded: t.optional(t.boolean, false)
	})
	.actions(self => ({
		setTextExpanded(isTextExpanded = null) {
			self.isTextExpanded = isTextExpanded === null ? !self.isTextExpanded : isTextExpanded
			return self
		}
	}))
