import React from "react"
import { CreateComponent } from "components/utils/CreateComponent"
import { action, computed, observable, makeObservable, flow } from "mobx"
import { ClassAbstract } from "store/ClassTools"
//import { fade } from "@mui/material/styles"
import { Typography } from "@mui/material"
import { EventEmitter } from "components/utils/EventEmitter"
import { rootStore } from "store/RootStore"
import { appStats } from "utils/TestSupport"
import { timeNow } from "utils/Utils"
//import { PageAbstract } from "store/page/PageAbstract"

//class MonitorState extends PageAbstract {
class MonitorState extends ClassAbstract {
	static get ACTIVE() { return 0 }
	static get STOPPED() { return 1 }
	static get SUSPENDED() { return 2 }
	static get BACKGROUND() { return 3 }

	connected = true
	title = null
	lastSampleTime = (new Date()).getTime()
	lastSeenActive = (new Date()).getTime()
	state = MonitorState.ACTIVE
	// MTVW-255
	appVisible = true
	forcedPause = false
	wasSleeping = false
	cb = null

	constructor(parent, path) {
		super(parent, path)

		// called from event handler, needs binding
		this.setForcedPause = function (value) {
			this.forcedPause = value
			console.warn("forcedPause %s", value)
			if (this.cb) this.cb()
		}.bind(this)

		this.setWasSleeping = function (value) {
			this.wasSleeping = value
			console.debug("%s: wasSleeping %s", timeNow(), value)
			if (this.cb) this.cb()
		}.bind(this)

		// called from event handler, needs binding
		this.visibilityChange = function () {
			if (document.visibilityState === "visible") {
				this.appVisible = true
				this.forcedPause = false
				this.wasSleeping = false
			}
			else {
				this.appVisible = false
			}
			console.debug("%s: appVisisble %s", timeNow(), this.appVisible)
			if (this.cb) this.cb()
		}.bind(this)
		document.addEventListener("visibilitychange", this.visibilityChange, false)

		makeObservable(this, {
			connected: observable,
			title: observable,
			state: observable,
			appVisible: observable,
			forcedPause: observable,
			wasSleeping: observable,
			setState: action,
			visibilityChange: action,
			setForcedPause: action,
			setWasSleeping: action,
			setTitle: action,
			isSuspended: computed,
			isInBackground: computed
		})

		/*
		// doesn't really work
		this.onLine = function (e) {
			console.debug("onLine event, %o", e)
		}
		// doesn't really work
		this.offline = function (e) {
			console.debug("offline event, %o", e)
		}
		// fires if not visible
		this.onBlur = function (e) {
			console.debug("blur event, %o", e)
		}
		// fires if visible
		this.onFocus = function (e) {
			console.debug("focus event, %o", e)
		}

		document.addEventListener("online", this.online, false)
		document.addEventListener("offline", this.offline, false)
		window.addEventListener("blur", this.onBlur, false)
		window.addEventListener("focus", this.onFocus, false)
		*/

		const INTERVAL = 5000
		const MAX_DIFF = 1000
		let currentTime = (new Date()).getTime()
		setInterval(async () => {
			const now = (new Date()).getTime()
			const diff = now - INTERVAL - currentTime
			if (diff > MAX_DIFF) {
				console.debug("%s: SLEEP detected %s", timeNow(), diff)
				this.setWasSleeping(true)
			}
			currentTime = now
		}, INTERVAL)
	}

	setState(newState) {
		console.debug("%s: STATE: %s", timeNow(), newState)
		this.state = newState
	}

	setTitle(title) { this.title = title }

	// MTVW-255: new methods

	// MTVW-255: Attempt to implement suspend / locked screen detection (mainly for Android)
	get isSuspended() {
		//console.debug("isSuspended %s", this.forcedPause && !this.appVisible)
		return this.forcedPause && !this.appVisible && this.wasSleeping
	}

	get isActive() {
		return this.state === MonitorState.ACTIVE
	}

	get isInBackground() {
		return this.forcedPause && !this.appVisible
	}

	setCallBack(cb) {
		this.cb = cb
		//console.debug("setting callback %o", this.cb)
	}
}

export const monitorState = new MonitorState(null, "monitorState")

const styles = theme => ({
	ConnectionInfoWrapper: {
		/*
		display: "flex",
		position: "absolute",
		alignItems: "center",
		height: "100%",
		width: 664,
		left: 256,
		top: 0,
		*/
		position: "absolute",
		zIndex: 100000
	},
	ConnectionInfoBackground: {
		/*
		display: "flex",
		justifyContent: "center",
		width: "100%",
		//maxWidth: 660,
		backgroundColor: fade(theme.palette.secondary.main, 0.7),
		margin: "0 auto",
		padding: 20
		*/
		position: "fixed",
		width: "100%",
		//top: "50%",
		height: "64px",
		bottom: 0,
		left: "50%",
		transform: "translate(-50%, -50%)",
		//backgroundColor: fade("theme.palette.secondary.main", 0.7),
		backgroundColor: "#E7BC2E",
	},
	ConnectionInfo: {},
})

const ConnectionInfoWrapper = CreateComponent(null, false, true, ({ classes, children }) => (
	<div className={classes.ConnectionInfoWrapper}>
		<div className={classes.ConnectionInfoBackground} id="offlineMessage">
			<div className={classes.ConnectionInfo}>{children}</div>
		</div>
	</div>
))

const ConnectionInfo = CreateComponent(null, false, true, ({ classes, title }) => {
	return title === null ? null : (
		<ConnectionInfoWrapper classes={classes}>
			<Typography variant="h5" paragraph={true} align="center" style={{ fontSize: 20, fontWeight: "bold", /*fontFamily: "EuropaBold",*/ color: "#191919", paddingTop: "20px" }}>
				{title}
			</Typography>
		</ConnectionInfoWrapper>
	)
})

export const ConnectionMonitor = CreateComponent(styles, false, false, ({ classes, children }) => {
	const [title, setTitle] = React.useState(null)

	// MTVW-255
	const eventNotification = () => {
		//function eventNotification() {
		console.debug("%s: suspended %s, wasSleeping %s, forcedPause %s", timeNow(), monitorState.isSuspended, monitorState.wasSleeping, monitorState.forcedPause)
		//console.debug("win %o, doc %o, nav %o", window, document, navigator)
		if (monitorState.isSuspended) {
			if (monitorState.state === MonitorState.ACTIVE) {
				console.debug("%s: setting INACTIVE", timeNow())
				_isInactive()
				monitorState.setState(MonitorState.STOPPED)
			}
		}
		/*
		else {
			if (monitorState.state === MonitorState.SUSPENDED) {
				console.debug("setting ACTIVE")
				_isActive()
				monitorState.setState(MonitorState.ACTIVE)
			}
		}
		*/
	}
	monitorState.setCallBack(eventNotification)

	React.useEffect(() => {
		console.debug("%s: opening ConnectionMonitor", timeNow())
		// MTVW-633: added below statement
		rootStore.appError._init()
		// for test purpose
		//goThere()
		setTitle(null)
		if (window.addEventListener) {
			//console.debug("registering events")
			//window.addEventListener("online", _isActive, false)
			//window.addEventListener("offline", _isInactive, false)
		}

		// MTVW-106: disable
		//document.addEventListener("visibilitychange", _visibilityChange, false)

		// xhr intercept trial
		//intercept(null, cb)

		//fetchIntercept()

		const INTERVAL = 12000
		setInterval(async () => {
			const currentTime = (new Date()).getTime()
			/* MTVW-255 / MTVW-257: disable
			if ((monitorState.state === MonitorState.ACTIVE) && (currentTime > (monitorState.lastSampleTime + INTERVAL + 2000))) {
				// Wake up
				console.debug("wake up detected %s, %s", currentTime - monitorState.lastSampleTime, new Date())
				_isInactive(false)
				monitorState.setState(MonitorState.STOPPED)
			}
			*/
			if (currentTime > (monitorState.lastSampleTime + INTERVAL + 2000)) {
				// Wake up
				console.debug("%s: wake up detected %s", timeNow(), currentTime - monitorState.lastSampleTime)
				EventEmitter.dispatch("wakeup", "connMon")
			}

			monitorState.lastSampleTime = currentTime
			//console.debug(result ? "Online" : "OFFline")
			//if (monitorState.isSuspended) {
			const isOnline = await checkOnlineStatus()
			if (isOnline) monitorState.lastSeenActive = currentTime
			// max. time until switching to offline can be 2 x INTERVAL
			if ((monitorState.state === MonitorState.ACTIVE) && !isOnline && (currentTime > monitorState.lastSeenActive + INTERVAL + 2000)) {
				console.debug("%s: connection down", timeNow())
				monitorState.connected = false
				// MTVW-255: RECHECK
				_isInactive()
				monitorState.setState(MonitorState.STOPPED)
			}
			if (isOnline && !monitorState.isInBackground && (monitorState.state !== MonitorState.ACTIVE) && (monitorState.state !== MonitorState.BACKGROUND)) {
				console.debug("%s: connection back %s", timeNow(), monitorState.state)
				// MTVW-255: RECHECK
				_isActive()
				monitorState.setState(MonitorState.ACTIVE)
				monitorState.setForcedPause(false)
				monitorState.setWasSleeping(false)
				appStats.backToOnline++
			}
			//}
		}, INTERVAL) // probably too often, try 30000 for every 30 seconds

		return () => {
			//window.removeEventListener("online", _isActive, false)
			//window.removeEventListener("offline", _isInactive, false)
			// MTVW-106: disable
			//document.removeEventListener("visibilitychange", _visibilityChange, false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const checkOnlineStatus = async () => {
		try {
			/*
			const online = await fetch("/1pixel.png", {
				method: 'GET', headers: { 'pragma': 'no-cache', 'cache-control': 'no-cache' }
			})
			*/
			const CREDENTIALS = 'include'
			const [url, headers] = rootStore?.mediaService?.versionUrl()
			headers['pragma'] = 'no-cache'
			headers['cache-control'] = 'no-cache'
			const online = await fetch(url, {
				method: 'GET', headers: headers,
				credentials: CREDENTIALS
			})
			//console.debug("RSP STAT %s, %o", online.status, online)
			return online.status >= 200 && online.status < 300
		} catch (err) {
			console.error("%s: CATCH FETCH, %o", timeNow(), err)
			return false // definitely offline
		}
	}

	/* fetch intercept trial
	const fetchIntercept = () => {
		let fetch = window.fetch
		window.fetch = function () {
			console.debug("fetch intercept args %o", arguments)
			const result = fetch.apply(this, arguments)
			result.then(function (res) {
				console.debug("res %o %s", res, res.status)
			})
			console.debug("%o, %s", result, result.response)
			return result
		}
	}
	*/

	/* xhr intercept trial
	const intercept = (urlmatch, callback) => {
		let send = XMLHttpRequest.prototype.send;
		XMLHttpRequest.prototype.send = function () {
			this.addEventListener('readystatechange', function () {
				//if (this.responseURL.includes(urlmatch) && this.readyState === 4) {
				if (this.readyState === 4) {
					if (this.responseURL.endsWith(".m3u8")) {
						console.debug("before cb %o", this)
						// eslint-disable-next-line no-debugger
						//debugger
					}
					callback(this)
				}
			}, false)
			console.debug("after cb %o", this)
			send.apply(this, arguments)
		}
	}

	const cb = (arg) => {
		if (arg.responseURL.endsWith(".m3u8")) {
			console.debug("HIT %s", arg.responseURL)
			console.debug(arg.responseText)
			const modified = arg.responseURL + "\nblabla"
			Object.defineProperty(arg, 'responseText', {
				//get: getter,
				//set: setter,
				//configurable: true,
				writable: true,
				value: modified
			})
			Object.defineProperty(arg, 'response', {
				writable: true
			})
			console.debug("cb: %s %o", arg.responseText, arg)
			arg.responseText = arg.responseText + "\nblabla"
			//setter(arg.repoonseText + "/nblabla")
			console.debug("cb: %s %o", arg.responseText, arg)
			// eslint-disable-next-line no-debugger
			//debugger
		}
	}
	*/

	const UP = 0
	const DOWN = 1
	const moveMessage = (direction) => {
		const elem = document.getElementById("offlineMessage")
		if (!elem) return
		let pos = -2 * elem.clientHeight
		// bottom instead of middle
		//let stopPos = window.innerHeight / 2 - elem.clientHeight / 2
		let stopPos = -elem.clientHeight / 2
		if (direction === DOWN) {
			//pos = window.innerHeight / 2 - elem.clientHeight / 2
			pos = -elem.clientHeight / 2
			stopPos = -2 * elem.clientHeight
		}
		var id = setInterval(frame, 1)
		function frame() {
			if (direction === UP) {
				if (pos >= stopPos) {
					clearInterval(id)
				} else {
					pos = pos + 2
					elem.style.bottom = pos + 'px'
				}
			}
			else {
				if (pos <= stopPos) {
					clearInterval(id)
					setTitle(null)
				} else {
					pos = pos - 2
					elem.style.bottom = pos + 'px'
				}
			}
		}
	}

	// MTVW-106: disable
	/*
	const _visibilityChange = (event) => {
		if (document.visibilityState === 'visible') {
			console.debug("now visible")
			if (monitorState.state === MonitorState.BACKGROUND) {
				_isActive()
				monitorState.setState(MonitorState.ACTIVE)
			}
		} else {
			console.debug("now NOT visible")
			if (monitorState.state === MonitorState.ACTIVE) {
				_isInactive()
			}
			monitorState.setState(MonitorState.BACKGROUND)
		}
	}
	*/

	const _isInactive = () => {
		console.debug("%s: INACTIVE detected", timeNow())
		if (!monitorState.connected) {
			rootStore.page.Player.player.setFullScreen(false)
			setTitle("KEINE INTERNETVERBINDUNG")
			moveMessage(UP)
		}

		EventEmitter.dispatch("inactive", null)
	}

	const refreshToken = flow(function* () {
		try {
			yield rootStore.time.handleSyncDateFromServerAsync()
			// MTVW-157
			//yield rootStore.sso.handleTokenVerificationAsync(true)
			yield rootStore.sso.profile.refreshTokens()
			// would login
			//yield rootStore.sso.handleAuthenticateAsync()
		}
		catch (e) {
			console.debug("caught in ConnectionMonitor.refreshToken %o", e)
		}
	})

	const _isActive = () => {
		console.debug("%s: ACTIVE detected", timeNow())
		refreshToken()
		if (!monitorState.connected) moveMessage(DOWN)

		EventEmitter.dispatch("active", null)
		monitorState.connected = true
	}

	return (
		<ConnectionInfo classes={classes} title={title} />
	)
})
