import { flow /*, observable, computed, action */ } from "mobx"
import { TraxisCpeIdMixin } from "store/api/mixin/TraxisCpeIdMixin"
// MTVW-585: 1 retry
import { fetchWithRetry, DEFAULT_RETRIES, DEFAULT_TIMEOUT } from "./FetchWithRetry"
import { serviceUrls } from "store/qlapi/ServiceUrls"
import { alias, serializable, deserialize, primitive } from "serializr"
import { getQlHeaders } from "store/qlapi/QlHeaders"


class TvChannel {
	@serializable id = null
	@serializable(alias("name", primitive())) Name = null
	@serializable(alias("logoUri", primitive())) logoUrl = null
	@serializable(alias("lcn", primitive())) LogicalChannelNumber = null
	@serializable(alias("isAdult", primitive())) IsAdult = null
	@serializable isEncrypted = null
	// The rollingBufferId is used to create a replay streaming session. For further information refer to maxReplayDuration.
	// If an empty string is given, replay and recording is not allowed at all on this channel.
	@serializable rollingBufferId = null
	// The maximal allowed replay duration in ISO 8601 format. Usually set to P7D, P1DT6H, PT2H.
	// If set to PT0S, no replay is allowed at all. This field never contains an empty string.
	// The actual replay duration depends also on the recording opt-in date of the user.
	// ```
	// if isSubscribed is false:
	//   replay is not allowed.
	// else if maxReplayDuration is PT0S:
	//   replay is not allowed.
	// else if recordingAndReplayAllowedTill <= now
	//   replay is not allowed.
	// else if recordingAndReplayAllowedSince == 0:
	//   replay is allowed since now-PT2H.
	// else if now-maxReplayDuration < recordingAndReplayAllowedSince:
	//   replay is allowed since recordingAndReplayAllowedSince.
	// else:
	//   replay is allwed since now-maxReplayDuration.
	// ...
	@serializable maxReplayDuration = null
	// If set to true, it is allowed to book recordings on this channel. Set to false if no recordings may be booked.
	// ```
	// if isSubscribed is false:
	//   booking of recordings is not allowed.
	// else if maxReplayDuration is PT0S or isRecordingAllowed is false:
	//   booking of recordings is not allowed.
	// else if recordingAndReplayAllowedTill <= now
	//   booking of recordings is not allowed.
	// else if recordingAndReplayAllowedSince == 0:
	//   booking of recordings is allowed since now-PT2H.
	// else if now-maxReplayDuration < recordingAndReplayAllowedSince:
	//   booking of recordings is allowed since recordingAndReplayAllowedSince.
	// else:
	//   booking of recordings is allwed since now-maxReplayDuration.
	// ```
	@serializable hasNPVR = null // isRecordingAllowed
	// This property restricts the time from which content can be recorded resp. played via replay. In millis since epoch.
	// This is also known as Recording-OptIn date. If the customer did not opt-in to this channel, yet, 0 will be returned.
	@serializable recordingAndReplayAllowedSince = null
	// This property restricts the time from which recording of content resp. replay playback is no longer allowed.
	// In millis since epoch. This value is usually set if the customer booked a DayPass, to watch a channel for a single day.
	// The value defines the end of this 24h period. As soon as the 24h period has passed, the customer is no longer allowed
	// to watch replay resp. to book recordings, since he has not subscribed the channel. For regularly subscribed channels
	// a date in the future will be returned.
	@serializable recordingAndReplayAllowedTill = null
	// True if the customer subscribed this channel and can play its content. False otherwise.
	@serializable isSubscribed = null
	// True if the channel is part of Branchen Vereinbarung. The clients need to call adsMS.
	@serializable hasAlternativeAds = null
	// True if the channel is part of Branchen Vereinbarung. The clients need to call adsMS.
	@serializable hasMarkers = null
	// True if the channel is part of Branchen Vereinbarung and user can skip ads. True for premium subscription only.
	@serializable hasAdSkipping = null
	// True if the channel has replay. Set to false if no replay content is available.
	// ```
	// if isSubscribed is false:
	//   set to false
	// else if user cannot fast forward or rewind:
	//   set to false
	// else if maxReplayDuration <= PT2H:
	//   set to false
	// else if recordingAndReplayAllowedSince == 0:
	//   set to false
	// else:
	//   set to true
	// ```
	@serializable hasReplay = null
	// True if the channel allows livePause.
	// ```
	// if isSubscribed is false:
	//   set to false
	// else if maxReplayDuration = PT0H:
	//   set to false
	// else:
	//   set to true
	// ```
	@serializable hasLivePause = null
	// True if the channel allows start from the beginning.
	// ```
	// if isSubscribed is false:
	//   set to false
	// else if maxReplayDuration = PT0H:
	//   set to false
	// else:
	//   set to true
	// ```
	@serializable hasStartOver = null
	// True if client can position within the content.
	// ```
	// if isSubscribed is false:
	// 	set to false
	// else if can_fast_forward from cookie is false:
	// 	set to false
	// else if maxReplayDuration = PT0H:
	// 	set to false
	// else:
	// 	set to true
	// ```
	@serializable hasForwardRewind = null
	// True if channel is meant to be displayed only on set top box
	@serializable isSTBOnly = null
	/* MTVW-464: No HD badge
	// enum: [ SD, HD, UHD ]
	@serializable resolution = null // deprecated replacement for IsHd
	*/
	// unused replay_into_past (i.e. ReplayIntoPast) returned in EpgService
	// TODO: Needed? Not returned by MS
	IsOnRecordingOptinList = true

	/* MTVW-464: No HD badge
	get IsHD() {
		return this.resolution === "HD"
	}
	*/
}

const CREDENTIALS = 'include'
const STD_HEADERS = {
	'Accept': 'application/json',
	// Would cause CORS in keepAlive
	//'pragma': 'no-cache', 'cache-control': 'no-cache'
}
export class ChannelsService extends TraxisCpeIdMixin {

	get xHeaders() {
		// make a copy of STD_HEADERS, otherwise STD_HEADERS would be changed!
		const headers = {}
		Object.assign(headers, STD_HEADERS)
		Object.assign(headers, getQlHeaders())
		return headers
	}

	version = flow(function* (retryCount = DEFAULT_RETRIES) {
		const config = {
			url: serviceUrls.channelsUrl + "version",
			fetchOptions: {
				method: 'GET',
				headers: this.xHeaders,
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["ChannelsService version"],
			service: "ChannelsService",
			createException: true,
			displayError: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		return `${resultJson["build_tag"]} (${resultJson["build_date"]})`
	})

	versionUrl() {
		return [serviceUrls.channelsUrl + "version", this.xHeaders]
	}

	tvChannelsAsync = flow(function* (contractId, retryCount = DEFAULT_RETRIES) {
		const config = {
			url: serviceUrls.channelsUrl + `contracts/${contractId}/tvChannels?type=ott`,
			fetchOptions: {
				method: 'GET',
				headers: this.xHeaders,
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["ChannelsService tvChannelsAsync"],
			service: "ChannelsService",
			createException: true,
			displayError: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		return deserialize(TvChannel, resultJson, (err, result) => {
		})
	})

	// MTVW-728
	replayOptIn = flow(function* (contractId, channels, retryCount = DEFAULT_RETRIES) {
		const config = {
			url: serviceUrls.channelsUrl + `contracts/${contractId}/replayOptIn`,
			fetchOptions: {
				method: 'POST',
				body: JSON.stringify(channels),
				headers: this.xHeaders,
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["ChannelsService replayOptIn"],
			service: "ChannelsService",
			createException: true,
			displayError: false
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		return response
	})

	// MTVW-728: It clears the optIn date. Requires Admin authorization.
	// It's not intended to be used by the client. Only for test automation purposes
	/*
	replayOptOut = flow(function* (contractId, retryCount = DEFAULT_RETRIES) {
		const config = {
			url: serviceUrls.channelsUrl + `contracts/${contractId}/replayOptOut`,
			fetchOptions: {
				method: 'DELETE',
				headers: this.xHeaders,
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["ChannelsService replayOptOut"],
			service: "ChannelsService",
			createException: true,
			displayError: false
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		return response
	})
	*/
}
