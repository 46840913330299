import { withStyles } from "@mui/styles"
// epg problem timezone to check
//import { observer } from "mobx-react-lite"
import { inject, observer } from "mobx-react"
//import React, { useState } from "react"
//import { observer } from "mobx-react"

export const CreateComponent = (styles, isStore = true, isObserver = true, component) => {
	if (isObserver === true) {
		component = observer(component)
	}
	if (isStore === true) {
		component = inject("store")(component)
	}
	if (styles !== null) {
		return withStyles(styles)(component)
	}
	return component
}
