/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-09
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-11
 */

import { types as t, flow } from "mobx-state-tree"
import { TraxisModel } from "store/api/TraxisModel"
import { RepoAbstractMbx } from "store/page/repo/RepoAbstract"
import { rootStore } from "store/RootStore"
import { logResourceLoadFailure } from "utils/BugsnagReporter"

export const ChannelListRepo = RepoAbstractMbx.named("ChannelListRepo")
	.props({
		//activeChannelId: t.maybeNull(t.string),
		activeChannelListId: t.maybeNull(t.string),
		// true - show adult channels
		isAdult: t.optional(t.boolean, false),
		// true - show child channels @todo
		isChild: t.optional(t.boolean, false),
		// @todo
		isViewableOnCpe: t.optional(t.boolean, false),
		// true - only channels with products
		isAvailableOnly: t.optional(t.boolean, true),
		// false - ch without number
		isLogicalChannel: t.optional(t.boolean, true),
		isPersonalBlockedChannel: t.optional(t.boolean, true)
	})
	.volatile(self => ({
		oChannels: new TraxisModel(),
		oChannelLists: new TraxisModel(),
		oProducts: new TraxisModel(),
		activeChannelId: null,
		// MTVW-251: add filterStats
		filterStats: { isNull: 0, isChild: 0, isAdult: 0, isLogical: 0, isNotSubscribed: 0, isPersonalBlocked: 0 }
	}))
	.views(self => ({
		_init() { },
		_resetFilterStats() {
			self.filterStats.isNull = 0
			self.filterStats.isChild = 0
			self.filterStats.isAdult = 0
			self.filterStats.isLogical = 0
			self.filterStats.isNotSubscribed = 0
			self.filterStats.isPersonalBlocked = 0
		},
		get isReady() {
			return self.oChannels?.isReady && self.oChannelLists?.isReady && self.oProducts?.isReady
		},
		get ChannelLists() {
			return self.oChannelLists?.Data?.items.filter(i => i.IsPersonal === false || i.ProfileId === rootStore.sso.profile?.id)
		},
		get ChannelList() {
			return self.ChannelLists?.find(i => i.id === self.activeChannelListId)
		},
		get ChannelsFromList() {
			//console.debug("Channels?.length %s, oChannels %s", self.Channels?.length, self.oChannels?.Data?.items?.length)
			if (self.activeChannelListId === null) {
				return self.Channels
			}
			const chList = self.ChannelList
			if (chList === void 0 || chList.Channels === null || chList?.Channels?.Channel?.length === 0) {
				return self.Channels
			}
			//return chList.Channels.items.filter(self._isChannelFilter)
			self._resetFilterStats()
			return chList?.Channels?.Channel?.map(i => self.Channels?.find(j => j.id === i.id)).filter(self._isChannelFilter)
		},
		getChannelsForList(id = null) {
			if (id === null) {
				return self.Channels
			}

			const chList = self.ChannelLists?.find(i => i.id === id)
			// channel list might be empty
			return (!chList?.Channels) ? [] : chList.Channels?.Channel?.map(i => self.Channels?.find(elem => elem.id === i.id)).filter(self._isChannelFilter)
		},
		getChannelList(id) {
			return self.ChannelLists?.find(i => i.id === id)
		},
		get Channels() {
			//console.debug("Channels %o", self.oChannels.Data?.items)
			self._resetFilterStats()
			const result = self.oChannels?.Data?.items?.filter(self._isChannelFilter)
			console.debug("total traxis channels %s, filtered %s, stats %o", self.oChannels?.Data?.items?.length, result?.length, self.filterStats)
			return result
		},
		isChannelOnList(channelId) {
			return self.ChannelsFromList?.find(i => i.id === channelId) !== void 0
		},
		getIndexChannelOnList(channelId) {
			return self.ChannelsFromList?.findIndex(i => i.id === channelId)
		},
		get getFirstChannel() {
			return self.ChannelsFromList?.[0]
		},
		preloadChannelPictures(param) {
			return self.ChannelsFromList?.map(i => {
				if (i.Pictures !== null) {
					let img = new Image()
					img.src = i.Pictures.getLogo + param
					img.onerror = (e) => { logResourceLoadFailure(img.src, e) }
					return img
				}
				return null
			})
		},
		_isChannelFilter(i) {
			if (!i) {
				self.filterStats.isNull++
				return false
			}
			if (self.isChild === true && i.Blocked === true) {
				self.filterStats.isChild++
				return false
			}
			if (self.isAdult === false && i.IsAdult === true) {
				self.filterStats.isAdult++
				return false
			}
			if (self.isLogicalChannel === true && i.LogicalChannelNumber <= 0) {
				// filters MyChannel with LogicalChannelNumber 0
				self.filterStats.isLogical++
				//console.debug("isLogical %o", i)
				return false
			}
			if (self.isAvailableOnly === true && i.Products === null) {
				self.filterStats.isNotSubscribed++
				// filters not subscribed channels (no Product)
				//console.debug("filter 5, isAvailableOnly %s, i.Products %s, id %s", self.isAvailableOnly, i.Products === null, i.id)
				//console.debug("isNotSubscribed %o", i)
				return false
			}
			if (self.isPersonalBlockedChannel === true && i.IsPersonalBlockedChannel === true) {
				self.filterStats.isPersonalBlocked++
				return false
			}
			return true
		}
	}))
	.actions(self => ({
		afterCreate() {
			// NOTE: Comment added for MTVW-251: below items are originally loaded at startup
			self.oChannels = rootStore.singleton.channels
			self.oChannelLists = rootStore.singleton.channelLists
			self.oProducts = rootStore.singleton.products
			//self._afterCreateAsync()
		},
		//_afterCreateAsync: flow(function*() {}),
		setActiveChannel(activeChannelId) {
			self.activeChannelId = activeChannelId
			return self
		},
		setActiveChannelList(activeChannelListId, bCheckChannelFromList = null) {
			self.activeChannelListId = activeChannelListId
			if (bCheckChannelFromList === true) {
				self.checkChannelFromList()
			}
			return self
		},
		setViewableOnCpeState(isViewableOnCpe = false) {
			self.isViewableOnCpe = !!isViewableOnCpe
			return self
		},
		setAdultState(isAdult = false) {
			self.isAdult = !!isAdult
			return self
		},
		setAvailableOnlyState(isAvailableOnly = true) {
			self.isAvailableOnly = !!isAvailableOnly
			return self
		},
		setChildState(isChild = false) {
			self.isChild = !!isChild
			return self
		},
		setLogicalChannelState(isLogicalChannel = true) {
			self.isLogicalChannel = !!isLogicalChannel
			return self
		},
		setPersonalBlockedChannelState(isPersonalBlockedChannel = true) {
			self.isPersonalBlockedChannel = isPersonalBlockedChannel
			return self
		},
		checkChannelFromList() {
			// MTV-2807 
			// if the channelList changes, we dont'need to change the selected channel, the player has to continue to show the active channel

			if (self.activeChannelId === null) {// || self.isChannelOnList(self.activeChannelId) === false)
				self.setActiveChannel(self.getFirstChannel ? self.getFirstChannel.id : null)
			}
			return self
		},
		fetchDataAsync: flow(function* (bOnlyIfNoData = null, ...args) {
			console.info("ChannelListRepo.fetchDataAsync(bOnlyIfNoData=%o,...args=%o)", bOnlyIfNoData, args)
			const promiseAll = yield Promise.all([
				self.oChannels.fetchDataAsync(bOnlyIfNoData, ...args),
				self.oChannelLists.fetchDataAsync(bOnlyIfNoData, ...args),
				self.oProducts.fetchDataAsync(bOnlyIfNoData, ...args)
			])
			if (bOnlyIfNoData !== false) {
				self.checkChannelFromList()
			}
			return promiseAll
		})
	}))
