import { Button, Dialog, DialogActions, Typography } from "@mui/material"
import DialogContent from "@mui/material/DialogContent"
import { CreateComponent } from "components/utils/CreateComponent"
import React from "react"
import { rootStore } from "store/RootStore"


import "./recordingComponentsStyles.css"

const style = theme => ({

	RecordingsConfirmDialogButtons: {
		alignItems: "flex-end",
		flexDirection: "column",
		alignContent: "flex-end"

	}
})

export const RecordingsConfirmDialog = CreateComponent(style, false, false, ({ classes, onButtonHandlerA, onButtonHandlerB, buttonTextA, buttonTextB, children, ...props }) => {
	return (
		<Dialog open={true} /*transition */
			placement="bottom-end"
			BackdropProps={{ style: { backgroundColor: "rgba(48,48,48,0.8)" } }}
			disablePortal={rootStore.page.Player.player.isFullScreen}
			data-test="vert_window">
			{/*{({ TransitionProps }) => (
				<Slide {...TransitionProps} timeout={350} direction="up">*/}
			<DialogContent className="RecordingsConfirmDialog">
				<Typography variant="body3" className="RecordingsConfirmDialogText">
					{children}
				</Typography>

				<DialogActions classes={{ root: classes.RecordingsConfirmDialogButtons }}>
					{buttonTextA && (
						<Button className="RecordingsConfirmDialogButton" onClick={() => onButtonHandlerA(props)} data-test="confirm">
							{buttonTextA}
						</Button>
					)}
					{buttonTextB && (
						<Button className="RecordingsConfirmDialogButton" onClick={() => onButtonHandlerB(props)} data-test="cancel">
							{buttonTextB}
						</Button>
					)}
				</DialogActions>
			</DialogContent>
			{/*</Slide>
			)}*/}
		</Dialog>
	)
})
