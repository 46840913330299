// l10n status: done
import { Button, Link, Menu, MenuItem, /*Tooltip,*/ ListItemIcon, Divider } from "@mui/material"
// import ClickAwayListener from "@mui/material/ClickAwayListener"
import { CreateComponent } from "components/utils/CreateComponent"
import { RouteLink, useHistoryRouteAdd } from "components/utils/RouteUtils"
import React from "react"
import { ROUTE_NAMES } from "site-ql/RouteConst"
import { rootStore } from "store/RootStore"
import { ModalMessage } from "components/ModalMessage"

// icon does not scale when zooming on Safari
//import PersonIcon from '@mui/icons-material/Person'
import PersonIcon from "img/person.svg"
import CloseIcon from '@mui/icons-material/Close'
// icon does not scale when zooming on Safari
//import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
//import ArrowDown from "img/arrow-down.svg"
import AddIcon from "img/icons/add-white.svg"
import CheckIcon from "img/icons/check-white.svg"
import SettingIcon from "img/icons/settings-white.svg"
import ExitIcon from "img/icons/log-out.svg"
import classnames from "classnames"
import { firstInstallStore } from "store/FirstInstallStore"
import { l10n } from "store/lang/L10n"

const styles = theme => ({
	NavBarArrowIcon: { padding: 0, marginTop: "-4px" },
	ProfileMenuList: {
		//padding: "5px 0 10px",
		zIndex: 1500,
		borderRadius: "4px",
		boxShadow: "0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)",
		outline: "0 solid",
		background: "#3F3F3F !important",
		// VITE?
		//width: "min-content"
	},
	ProfileMenuListItem: { display: "flex", justifyContent: "flex-start", fontSize: "14px", padding: "4px 80px 0px 16px", height: "2.5rem" },
	Icon: { width: 30, height: 30 },
	/*
	IconProfileContainer: {
		width: 60, height: 60,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	*/
	CheckIconContainer: { minWidth: "40px" },
	ProfileMenuSelected: { background: "rgba(0,0,0,0.08)" }
})



export const ProfileMenu = CreateComponent(styles, false, true, ({ classes, classNames }) => {

	const DisabledMessage = {
		show: false, info: {}, onCancel: null, onConfirm: null
	}
	const confirmError = (modalMessage) => {
		setModalMessage(modalMessage => ({ ...modalMessage, show: false }))
	}
	const TooManyProfilesMessage = {
		show: true,
		info: {
			title: l10n.errorGenericTitle,
			content: l10n.featureNewProfileTooManyProfilesText,
			button1: null,
			button2: l10n.commonActionOk
		},
		onCancel: null, onConfirm: confirmError
	}
	const IsNotMainProfileMessage = {
		show: true,
		info: {
			title: l10n.featureNewProfileOnlyFromMainTitle,
			content: l10n.featureNewProfileOnlyFromMainText,
			button1: null,
			button2: l10n.commonActionOk
		},
		onCancel: null, onConfirm: confirmError
	}

	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)
	const [modalMessage, setModalMessage] = React.useState(DisabledMessage)

	const handleToggle = (e) => {
		setAnchorEl(e.currentTarget)
		e.preventDefault()
		// MTV-3583: e.stopPropagation required with React 17
		e.stopPropagation()
		//console.debug("handleToggle %o", e)
		// fetch current profiles
		rootStore.sso.getLoginProfiles()
	}

	const handleClose = (e) => {
		setAnchorEl(null)
	}

	const historyRouteAdd = useHistoryRouteAdd()
	const handleCreateProfile = (e) => {
		//console.debug("profile:", rootStore.sso.profile, rootStore.sso.profile.loginResult.CanCreateProfiles, rootStore.sso.profile.loginResult.CanSwitchProfiles, rootStore.sso.profile.loginResult.maxProfiles)
		// MTVW-627: Profiles can only be created from the main profile
		//if (rootStore.sso.profile.IsMainProfile) {
		// MTVW-663: Profile creation: relax/fix check for creation from non-main profile
		if (rootStore.sso.profile?.loginResult?.CanCreateProfiles) {
			if (rootStore.sso.profileOrder?.length < rootStore.sso.profile?.loginResult?.maxProfiles) {
				//console.debug("handleCreateProfile %o", e)
				handleClose(e)
				// MTVW-405: set flag to avoid intermediate rendering transitions
				firstInstallStore.startNewProfile(true)
				historyRouteAdd(ROUTE_NAMES.CreateProfile/*, { type: "create", id: null }*/)
			}
			else {
				handleClose(e)
				setModalMessage(TooManyProfilesMessage)
			}
		}
		else {
			handleClose(e)
			setModalMessage(IsNotMainProfileMessage)
		}
	}

	const handleChangeProfile = (e, profile) => {
		handleClose(e, "change profile")
		// is current profile
		if (rootStore.sso.profile && rootStore.sso.profile.id !== profile.id) {
			//MTV-2959: Do not start from Live TV when changing the profile
			//historyRouteAdd(ROUTE_NAMES.LiveTv)
			rootStore.sso.handleChangeProfile(profile.id)
			//rootStore.singleton.profile.fetchData()
			// TODO: reload() is currently to check whether FirstInstall is done
			// eslint-disable-next-line no-restricted-globals
			// MTVW-235: No reload when changing profile
			//location.reload()
		}
	}
	const handleLogOff = (e) => {
		rootStore.sso.handleLogOffAsync()
	}

	return (
		<div >
			<ModalMessage
				show={modalMessage.show}
				msgInfo={modalMessage.info}
				onCancel={() => { if (modalMessage.onCancel) modalMessage.onCancel(modalMessage) }}
				onConfirm={() => { if (modalMessage.onConfirm) modalMessage.onConfirm(modalMessage) }}
			/>
			<Button //style={{ backgroundColor: 'transparent' }}	// disable hover effect
				disableFocusRipple={true}
				className={`${classNames.NavBarItemLink} ${classNames.NavBarItem}`}
				data-test="profile_menu_current"
				// MTVW-151 style={{ marginTop: "1px" }}
				aria-controls="customized-menu"
				aria-haspopup="true"
				onClick={(e) => { handleToggle(e) }}
			>
				{/* <div className={`PersonIconResponsive ${anchorEl ? "opened" : ""}`} style={{ cursor: "pointer" }}> */}
				<div className={` PersonIconResponsive ${anchorEl ? "opened" : ""}`} style={{ cursor: "pointer", color: "#FFF !important" }}>
					{/* <div className={classes.IconProfileContainer}> */}
					<img className={classes.Icon} src={PersonIcon} alt="Profil"></img>
					{/* MTVW-151 <label className="HideLabelMuiButtonResponsive" style={{ cursor: "pointer" }}>{rootStore.sso.profile?.Nickname}</label> 
					<img className={classes.NavBarArrowIcon} src={ArrowDown} alt=""></img>*/}
				</div>
				{anchorEl && <CloseIcon sx={{ color: "#FFF" }} className="CloseIconResponsive" />}
			</Button>
			<Menu
				id="customized-menu"
				aria-labelledby="customized-menu"
				anchorEl={anchorEl}
				elevation={1}
				//getcontentanchorel={null}
				classes={{
					paper: `${classes.ProfileMenuList} NavBarItemResponsive`
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
				keepMounted
				open={open}
				onClose={handleClose}
			>

				{rootStore.sso.profileOrder.map(i => (

					<MenuItem key={i?.id} onClick={(e) => handleChangeProfile(e, i)}
						className={classnames(classes.ProfileMenuListItem, { [classes.ProfileMenuSelected]: i?.isSelected })}
						// className={classes.ProfileMenuListItem} disabled={i.isSelected}
						data-test="profile_menu_item">

						{i?.isSelected ?
							<ListItemIcon className={classes.CheckIconContainer}>
								<img src={CheckIcon} alt="check icon" />
							</ListItemIcon>
							:
							<div className={classes.CheckIconContainer}></div>
						}
						{i?.Nickname}
					</MenuItem>
				))}

				<Divider />
				{/*
				<Tooltip title="Diese Funktion wird zur Zeit entwickelt." arrow placement={"left"}
					//to fix the alignment of the tooltip
					PopperProps={{
						popperOptions: {
							modifiers: {
								offset: {
									enabled: true,
									offset: '-15px, -300px',
								},
							},
						},
					}}
				>
				*/}
				<span>
					<MenuItem onClick={(e) => { handleCreateProfile(e) }} className={classes.ProfileMenuListItem} /*disabled*/>
						<ListItemIcon className={classes.CheckIconContainer}>
							<img src={AddIcon} alt="add profile" />
						</ListItemIcon>
						{l10n.featureSettingsNewProfile}
					</MenuItem>
				</span>
				{/*</Tooltip>*/}

				<RouteLink Component={MenuItem} onClick={(e) => { handleClose(e, "routelink", false) }} name={ROUTE_NAMES.Settings} className={classes.ProfileMenuListItem} data-test="navbar_settings">
					<ListItemIcon className={classes.CheckIconContainer}>
						<img src={SettingIcon} alt="Setting icon" />
					</ListItemIcon>
					{l10n.featureMoreDeprecatedProfileSettings}
				</RouteLink>


				<Divider />
				{/* MTVW-799: Add window.open so that the link is opened on a click into the cell */}
				<MenuItem onClick={(e) => { window.open(l10n.configInformationalImprintDefaultUrl, "_blank"); handleClose(e, "menuitem", false) }} variant="caption" className={classes.ProfileMenuListItem} data-test="bottom_menu_impressum">
					<ListItemIcon className={classes.CheckIconContainer}></ListItemIcon>
					{/* MTVW-799, MTVW-813: replace Link with div
					<Link underline="hover" href={l10n.configInformationalImprintDefaultUrl} target="_blank">
						{l10n.featureSettingsHelpImprint}
					</Link>
					*/}
					<div>{l10n.featureSettingsHelpImprint}</div>
				</MenuItem>
				<MenuItem onClick={(e) => { window.open(l10n.configInformationalTermsDefaultUrl, "_blank"); handleClose(e, "menuitem", false) }} variant="" className={classes.ProfileMenuListItem} data-test="bottom_menu_agb">
					<ListItemIcon className={classes.CheckIconContainer}></ListItemIcon>
					<div>{l10n.featureSettingsHelpUsageGuidelines}</div>
				</MenuItem>
				<MenuItem onClick={(e) => { window.open(l10n.configInformationalContactDefaultUrl, "_blank"); handleClose(e, "menuitem", false) }} variant="caption" className={classes.ProfileMenuListItem} data-test="bottom_menu_feedback">
					<ListItemIcon className={classes.CheckIconContainer}></ListItemIcon>
					<div>{l10n.featureSettingsHelpContact}</div>
				</MenuItem>
				<MenuItem onClick={(e) => { window.open(l10n.configInformationalHelpDefaultUrlWeb, "_blank"); handleClose(e, "menuitem", false) }} variant="caption" className={classes.ProfileMenuListItem} data-test="bottom_menu_hilfe">
					<ListItemIcon className={classes.CheckIconContainer}></ListItemIcon>
					<div>{l10n.featureSettingsHelp}</div>
				</MenuItem>


				<Divider />
				<MenuItem onClick={(e) => handleLogOff(e)} className={classes.ProfileMenuListItem} data-test="profile_menu_logout">
					<ListItemIcon className={classes.CheckIconContainer}>
						<img src={ExitIcon} alt="Abmelden icon" />
					</ListItemIcon>
					{l10n.commonActionSignOut}
				</MenuItem>

			</Menu>
		</div>
	)
})
