// l10n status: done
import { List, Paper } from "@mui/material"
import { CreateComponent } from "components/utils/CreateComponent"
import React, { useEffect, useState } from "react"
import { EpgChannel } from "scenes/LiveTv/components/EpgChannel"
import { LiveTvConfig } from "scenes/LiveTv/LiveTv"
import { rootStore } from "store/RootStore"
import { MiniEpg } from "scenes/components/miniEpg/MiniEpg"
import { l10n } from "store/lang/L10n"


const styles = theme => ({
	tabs: { color: theme.palette.text.primary },
	tab: { width: "30%", minWidth: 0 }
})

export const EpgList = CreateComponent(styles, false, true, ({ filteredChannels, page, divider, type,
	setTopPositionList, windowDimensions }) => {

	const [start, setStart] = useState(Math.floor(page.scrollTop / (LiveTvConfig.cellHeight + LiveTvConfig.cellMarginBottom)))
	const numChannelDisplayable = Math.floor(windowDimensions / (LiveTvConfig.cellHeight + LiveTvConfig.cellMarginBottom))
	const offsetForLoadedChannel = numChannelDisplayable <= 20 ? 15 : 5 //MTV-3836 the offset depends on windowDimensions, important to keep scroll and overflow

	const [oldChanneListLname, setOldChanneListLname] = useState(l10n.featureChannelListsAllSenders) // to control when the name of the channel list changes
	const [isChannelListReset, setIsChannelListReset] = useState(false)

	const getIndexChannelOnFilteredList = (channelId) => {
		// MTVW-246: i.id -> i.Channel.id
		return filteredChannels?.findIndex(i => i.Channel.id === channelId)
	}

	const resetChanneslList = () => {
		const activeChannelId = page?.channelListRepo.activeChannelId //observe

		let pos = getIndexChannelOnFilteredList(activeChannelId) < 0 ? 0 : getIndexChannelOnFilteredList(activeChannelId)

		page.setScrollTop(pos * (LiveTvConfig.cellHeight + LiveTvConfig.cellMarginBottom))
		//console.debug("setStart -a %s ", Math.floor(page.scrollTop / (LiveTvConfig.cellHeight + LiveTvConfig.cellMarginBottom)))
		setStart(Math.floor(page.scrollTop / (LiveTvConfig.cellHeight + LiveTvConfig.cellMarginBottom)))

		page.setVerticalScroll()
		if (pos > start + numChannelDisplayable || pos < start) {
			/** MTV-2401
			 * if the channel is out of view move the channel to the top of the list.
			 * To display the channel at the top of the list need to render the parent component
			 * that fixes the problem of having a gap in the list...
			 */
			rootStore.page.LiveTv.setEpgListPos(activeChannelId, LiveTvConfig.cellHeight + LiveTvConfig.cellMarginBottom)
			//page.scrollTop = pos * (LiveTvConfig.cellHeight + LiveTvConfig.cellMarginBottom)
			page.setScrollTop(pos * (LiveTvConfig.cellHeight + LiveTvConfig.cellMarginBottom))
			/**MTV-2401
			 * another possible alternative
				page.setRefElemScroll(null)
				if (activeChannelId) {
					rootStore.page.LiveTv.handlePlayChannelLive(activeChannelId, LiveTvConfig.cellHeight + LiveTvConfig.cellMarginBottom)
				}
			 */

			// change the state of epgWrapComponent
			setTopPositionList(page.scrollTop)
			setIsChannelListReset(true)
		}
	}

	//MTV-3480 when the channelList changes : for refresh or for user selection or loading the page
	useEffect(() => {
		// rootStore.page.ChannelListRepo.ChannelList?.Name is undefine if the list is Alle sender
		const _channelListname = rootStore.page.ChannelListRepo.ChannelList?.Name === undefined ? l10n.featureChannelListsAllSenders : rootStore.page.ChannelListRepo.ChannelList?.Name

		//MTV-3480 reset the list only if the channelList changes but not if there is a refresh
		if (_channelListname !== oldChanneListLname) {
			resetChanneslList()
			setOldChanneListLname(_channelListname)
		} else {
			if (isChannelListReset === false) {
				resetChanneslList()
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filteredChannels])

	// MTV-3480
	// Added for loading new channel after the first 20
	// start must be re-initialized when it changes
	// used for scroll
	useEffect(() => {
		let nextStart = Math.floor(page.scrollTop / (LiveTvConfig.cellHeight + LiveTvConfig.cellMarginBottom))
		// MTV-3836 fix problem with zoom
		//if ((nextStart > (start + numChannelDisplayable)) || (nextStart < start)) {
		if (nextStart !== start) {
			//console.debug("setStart -b %s ", nextStart)
			setStart(nextStart)
		}
	}, [page.scrollTop, start])

	useEffect(() => {
		if (!isChannelListReset) {
			resetChanneslList()
		}
		// MTV-3005 reset the channel list when unmounting the component for disable scrolling 
		return () => {
			//page.scrollTop = 0
			page.setScrollTop(0)
			//setTopPositionList(page.scrollTop)
			setIsChannelListReset(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// MTV-3740 added for changes of channel by overlay related content	
	useEffect(() => {
		const activeChannelId = page?.channelListRepo.activeChannelId //observe
		let pos = getIndexChannelOnFilteredList(activeChannelId) < 0 ? 0 : getIndexChannelOnFilteredList(activeChannelId)
		/** MTV-3836 
			a fixed number of channels is no longer loaded.
			A variable number of channels is loaded depending on the available space.
			* if ((pos - start) > LiveTvConfig.channelNumToPreGen || (pos - start) < 0) {
		*/
		if ((pos - start) > numChannelDisplayable || (pos - start) < 0) {
			resetChanneslList()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page?.channelListRepo.activeChannelId])

	return (
		<Paper>
			<MiniEpg />
			<List component="nav"  >
				{/* MTV-3836 check comment above 
				 {filteredChannels?.slice(start, start + LiveTvConfig.channelNumToPreGen).map((i, k) => { 
				 in the new code in the line below, the + offsetForLoadedChannel is an offset needed to have the scroll and generate overflow*/}
				{/*{console.debug(">>> start %s, end %s, num %s, offs %s", start, start + numChannelDisplayable + offsetForLoadedChannel, numChannelDisplayable, offsetForLoadedChannel)}*/}
				{filteredChannels?.slice(start, start + numChannelDisplayable + offsetForLoadedChannel).map((i, k) => {
					return (
						// NOTE: Providing a non unique key would result in flickering of the channel logo (mainly iPad and iPhone)
						<EpgChannel page={page} key={i.Channel.id} top={(k + start) * (LiveTvConfig.cellHeight + LiveTvConfig.cellMarginBottom)} i={i} divider={divider} type={type} />
					)
				}
				)}
			</List>
		</Paper>
	)
})
