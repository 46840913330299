import { action, flow, observable, makeObservable } from "mobx"
import { moment } from "store/util/moment"
import { TIME_FORMAT_BACKEND } from "store/model/Time"
//import { SourceAbstract } from "store/page/player/source/SourceAbstract"
//import { SourceTypeList } from "store/page/player/source/SourceTypeList"
import { SourceTypeList, SourceAbstract } from './InternalImports'
import { rootStore } from "store/RootStore"
//import { serviceUrls } from "store/qlapi/ServiceUrls"
//import { GetEventByChannelTime } from "store/api/GetEvent"
import { logLevel } from "store/qlapi/LogService"
import { cDebug } from "utils/TestSupport"
import { isIphone, sleep } from "utils/Utils"

export class SourceChannelReplay extends SourceAbstract {
	type = SourceTypeList.ChannelReplay
	time = null

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			time: observable,
			getClone: action,
			setPlayPause: action,
			setJumpLive: action,
			setJumpRwd: action,
			setJumpFwd: action,
			setReplay: action
		})
		//console.debug("constructor SourceChannelReplay %o, %o", parent.player, path)
		this._player = parent.player
	}

	/**
	 * @returns {SourceChannelReplay}
	 */
	static create(parent, channelId, oEventFull, bufferTimeStart, trueReplaySession = true, sessionId = null, restartDone = false) {
		console.log("@@create(parent=%o, channelId=%o, oEventFull=%o, bufferTimeStart=%o)", parent, channelId, oEventFull, bufferTimeStart)
		const source = new SourceChannelReplay(parent, "source")
		source.Channel = channelId
		source.id = channelId
		source.GetEvent = oEventFull
		// would create moment.js warning
		//source.time = String(bufferTimeStart)
		source.time = moment(bufferTimeStart).utc().format(TIME_FORMAT_BACKEND)
		console.debug("create SourceChannelReplay:", bufferTimeStart, source.time)
		source.trueReplaySession = trueReplaySession
		source.sessionId = sessionId
		source.restartDone = restartDone
		//console.debug("---SourceChannelReplay CREATE %o, %o, %o", source, oEventFull, parent)
		return source
	}

	/**
	 * @returns {SourceChannelReplay}
	 */
	getClone() {
		const source = new SourceChannelReplay(this._parent, "source")
		source.Channel = this.Channel.id
		source.id = this.id
		source.GetEvent = this.GetEvent
		source.time = this.time
		source.trueReplaySession = this.trueReplaySession
		source.sessionId = this.sessionId
		source.restartDone = this.restartDone
		source.isLoading = false
		source.adState = this.adState
		source.sessionAdZones = this.sessionAdZones
		source.sessionEnd = this.sessionEnd
		//console.debug("getClone", source)
		return source
	}

	isSourceEqual(source) {
		return this.type === source.type && this.id === String(source.id) && this.time === String(source.time)
	}

	get isLive() {
		return false
	}

	// buttons disable state
	get isButtonLiveEnabled() {
		// only when we are not in live range
		return this.player.isStreamLoaded
	}

	get isButtonReplayEnabled() {
		// TODO joachim: temporary hack until we solve anvato positioning
		if (this.player.isAnvatoSource) {
			return false
		}
		return this.player.isStreamLoaded
	}

	get isButtonJumpFwdEnabled() {
		// TODO joachim: temporary hack until we solve anvato positioning
		if (this.player.isAnvatoSource) {
			return false
		}
		return this.player.isJumpFwdPossibleSec(this.settings.jumpFwdSec)
		//return false
	}

	get isButtonJumpRwdEnabled() {
		// TODO joachim: temporary hack until we solve anvato positioning
		if (this.player.isAnvatoSource) {
			return false
		}
		return this.player.isJumpRwdPossibleSec(this.settings.jumpRwdSec)
		//return false
	}

	get isButtonPlayPauseEnabled() {
		// TODO joachim: temporary hack until we solve anvato positioning
		if (this.player.isAnvatoSource) {
			return false
		}
		return this.player.isStreamLoaded
	}

	get isButtonRecEnabled() {
		return this.player.isStreamLoaded
	}

	openStreamAsync = flow(function* (startPosition = null) {
		console.debug("@@Replay openStreamAsync() -> ", this.time, this)
		this._waitPreload.setReset()
		if (import.meta.env.VITE_PLAYER === "vjs") {
			//yield this._openStreamRollingBufferAsync(this.GetEvent?.AvailabilityStartDate, startPosition ? startPosition : this.time)
			//console.debug("this.GetEvent?.AvailabilityStartDate", this.GetEvent?.AvailabilityStartDate)
			// OPTIMIZATION for SourceChannelReplay.setReplay (currently only for non GT12 channels), line below changed
			//yield this._openStreamRollingBufferAsync(null, startPosition ? startPosition : this.time)
			yield this._openStreamRollingBufferAsync(this.GetEvent?.AvailabilityStartDate, startPosition ? startPosition : this.time, this.GetEvent?.AvailabilityEndDate)
		} else if (import.meta.env.VITE_PLAYER === "vo") {
			// VO PLAYER SYNC
			//yield this._openStreamRollingBufferAsync(null, this.time)
			yield this._openStreamRollingBufferAsync(this.GetEvent?.AvailabilityStartDate, startPosition ? startPosition : this.time, this.GetEvent?.AvailabilityEndDate)
		}
		console.info("@@openStreamAsync() <- ")
	})

	// now obsolete was called from _old_setJumpRwd
	handleStreamOnStart() {
		console.log("@()")

		this._openStreamRollingBufferAsync(this.player.positionTimeStamp - 2 * 3600, this.player.positionTimeStamp)
	}

	// MTVW-543: use handleSetPosition for buffer_start / buffer_end detection
	handleStreamOnEnd = flow(function* (positionTimeStamp) {
		const source = this.getClone().setAsLoaded()
		// MTVW-678: parameter positionTimeStamp, set source.time
		source.time = moment(positionTimeStamp).utc().format(TIME_FORMAT_BACKEND)
		console.debug("handleStreamOnEnd", source, positionTimeStamp)
		// GT12: Fix old existing bug, need to use 'source' for callback and not 'this
		// MTVW-822, MTVW-823: added source.Event?.AvailabilityEndDateTs)
		yield this._parent.handlePlaySourcePreserveProgressBarAsync(source, (source) => source._openStreamRollingBufferAsync(source.Event?.AvailabilityStartDateTs, moment(positionTimeStamp), source.Event?.AvailabilityEndDateTs))
	})

	_old_handleStreamOnEnd() {
		console.log("@()")
		this._openStreamRollingBufferAsync(this.player.positionTimeStamp, this.player.positionTimeStamp)
	}

	//setPlayPause() {
	setPlayPause = flow(function* () {
		//if (!this.debounced()) return
		//console.debug("setPlayPause SourceChannelReplay", this.player.isPaused, this.player, this.player.__parent.playerFg)
		if (!this.player.isPaused) {
			this.player.setPause()
		} else {
			this.removePauseAd()
			console.debug("setPlayPause replay", this._parent.playerFg, this._parent.player)
			// MTVW-444, MTVW-788, MTVW-787
			if (this._parent.playerFg) {
				yield this._waitPreload.waitAsync()
				// if VITE_DISPOSE_LIVE_PLAYER playerFg will be cleared on preload
				if (!(import.meta.env.VITE_DISPOSE_LIVE_PLAYER === "true")) {
					// swap players and switch to background player
					yield this._parent.swapPlayers()
				}
				console.debug("setPlayPause replay 1", this._parent.playerFg, this._parent.player)
			}
			yield this.player.setPlay("setPlayPause")
		}
		return this
	})

	setJumpLive = flow(function* () {
		cDebug("SourceChannelReplay setJumpLive")
		if (import.meta.env.VITE_PLAYER === "vjs") {
			//yield this.player.setRefElemContainer(null, isIphone() ? false : true)
			//yield this._parent.playerAds.setRefElemContainer(null, isIphone() ? false : true)
			//yield this.player.setRefElemContainer(null, true)
			//yield this._parent.playerAds.setRefElemContainer(null, true)
			//yield this._parent.remountPlayerComponent()
		}
		if (this._parent.playerFg && !(import.meta.env.VITE_DISPOSE_LIVE_PLAYER === "true")) {
			// MTVW-444, MTVW-788, MTVW-787: we can jump to live using the existing live source
			// keep foreground player
			yield this._parent.deactivateBgPlayer()
			yield this._parent.player._source.setJumpLive()
		}
		else {
			if (this._parent.playerFg) {
				yield this._parent.inactivePlayer.stopInstance()
				this._parent.setPlayerFg(null)
			}
			yield this._parent.player.stopInstance()
			yield this._parent.setPlayChannelLiveAsync(this.Channel?.id, this.GetEvent, false, this.restartDone)
		}
	})

	//actionAfterFastForwardAd = (closeInteraction = "") => {
	actionAfterFastForwardAd = flow(function* (closeInteraction = "") {
		try {
			//console.debug("actionAfterFastForwardAd")
			// MTVW-544: suppress "Überspringen" after ffad
			this.markerPosD = this.adZone?.end
			if (this.adState?.linear) this.adState.linear.skip = false
			yield this.exitAdsPlayer()
			cDebug("JUMPTO %s", moment(this.fastForwardAdInfo.jumpTo).utc().format(TIME_FORMAT_BACKEND))
			const from = this.player.positionTimeStamp
			yield this.player.setPositionTimeStampAsync(this.fastForwardAdInfo.jumpTo)
			if (this.player.isPaused) yield this.player.setPlay("actionAfterFastForwardAd")
			this.player.setIsPosterVisible(false, "actionAfterFastForwardAd")
			// GT12 TODO: special cases
			// closeInteraction: [ changeAsset, interruption, skipPromo ] or "" for no interaction
			// error: [ adNotFound, other ] or ""  for no error
			//console.debug("AFTER FASTFORWARD", moment(this.adPlayStart).utc().format(TIME_FORMAT_BACKEND), moment().utc().format(TIME_FORMAT_BACKEND))
			rootStore.adsService.sessionTrackingFastForwardAdAsync(this.sessionId, this.fastForwardAdInfo.fastForwardAd.adsViewId, moment().valueOf() - this.adPlayStart, closeInteraction, "")
			// MTVW-649: disable notifyJump
			/*
			if (from !== this.fastForwardAdInfo.jumpTo)
				rootStore.adsService.sessionNotifyJumpAsync(this.sessionId, this.Event?.AvailabilityStartDateTs, this.Event?.AvailabilityEndDateTs, from, this.fastForwardAdInfo.jumpTo)
			*/
		}
		catch (e) {
			console.error("actionAfterFastForwardAd", e)
			rootStore.logService.logAsync(logLevel.ERROR, "actionAfterFastForwardAd", "SourceChannelReplay", e)
		}
	})

	// MTVW-543: use handleSetPosition for buffer_start / buffer_end detection
	setJumpRwd = flow(function* () {
		//console.debug("setJumpRwd", this.player.positionTimeStamp, this.player)
		//  MTVW-444, MTVW-788, MTVW-787
		if (this._parent.playerFg) {
			console.debug("setJumpRwd", this.player.positionTimeStamp, this._parent.playerFg, this._waitPreload.isDone)
			// swap players and switch to background player
			// MTVW-444, MTVW-788, MTVW-787
			yield this._waitPreload.waitAsync()
			// if VITE_DISPOSE_LIVE_PLAYER playerFg will be cleared on preload
			//console.debug("setJumpRwd paused", this._parent.playerFg.isPaused, this.player, this.player.isPaused)
			//console.debug("setJumpRwd fg", this._parent.playerFg?.isPaused, this.player.positionTimeStamp, this._parent.playerFg?.positionTimeStamp)
			if (!(import.meta.env.VITE_DISPOSE_LIVE_PLAYER === "true")) {
				//yield this._parent.playerFg.setPositionTimeStamp(timeStamp - this.settings.jumpFwdSec * 1000)
				yield this._parent.swapPlayers()
			}
			// let _startReplaySession position first, added for setting VITE_DISPOSE_LIVE_PLAYER
			yield sleep(200)
			yield this._parent.player.setPositionTimeStampAsync(this.player.positionTimeStamp - this.settings.jumpFwdSec * 1000)
			return
		}
		//console.debug("setJumpRwd", this.player.positionTimeStamp)
		//console.debug("setJumpRwd", this.player, this.player.isPaused)
		yield this.handleSetPosition(moment(this.player.positionTimeStamp - this.settings.jumpFwdSec * 1000))
	})

	_old_setJumpRwd() {
		// jump rwd button

		// TODO joe: for anvato update the play_ts URL and restart stream ??

		cDebug("@  source.setJumpRwd")
		if (this._checkStreamRwd()) {
			//this.removePauseAd()
			cDebug("positionTimeStamp %s, %o", this.player.positionTimeStamp, this.player)
			//this.player.setJumpRwd()
			this.handleFastForwardAd(this.player.positionTimeStamp, this.player.positionTimeStamp - this.settings.jumpRwdSec * 1000, this.actionAfterFastForwardAd)
		}
	}

	// MTVW-543: use handleSetPosition for buffer_start / buffer_end detection
	setJumpFwd = flow(function* () {
		//console.debug("handleSetPosition", this.settings.jumpFwdSec, this.player.positionTimeStamp, moment(this.player.positionTimeStamp + this.settings.jumpFwdSec * 1000))
		//  MTVW-444, MTVW-788, MTVW-787
		if (this._parent.playerFg) {
			// fwd button is disabled
		}
		yield this.handleSetPosition(moment(this.player.positionTimeStamp + this.settings.jumpFwdSec * 1000))
	})

	_old_setJumpFwd() {
		// jump fwd button

		// TODO joe: for anvato update the play_ts URL and restart stream ??

		//this.removePauseAd()
		if (this.isButtonJumpFwdEnabled) {
			cDebug("positionTimeStamp %s, %o", this.player.positionTimeStamp, this.player)
			// this.player.setJumpFwd()
			this.handleFastForwardAd(this.player.positionTimeStamp, this.player.positionTimeStamp + this.settings.jumpFwdSec * 1000, this.actionAfterFastForwardAd)
		} else {
			// GT12 TODO ?
			/*
			const isAd = this.handleFastForwardAd(() => {
				this.exitAdsPlayer()
				//this.setPlayPause()
				this._parent.setPlayChannelLiveAsync(this.Channel?.id, this.GetEvent, false, this.restartDone)
			})
			if (!isAd) this._parent.setPlayChannelLiveAsync(this.Channel?.id, this.GetEvent, false, this.restartDone)
			*/
			this._parent.setPlayChannelLiveAsync(this.Channel?.id, this.GetEvent, false, this.restartDone)
		}
	}

	setReplay = flow(function* () {
		// restart button
		//console.debug("@gt12 source.setReplay", this.Event?.AvailabilityStartDateTs, this.player.rangeTimeStampStart, this.restartDone)
		//this.removePauseAd()
		//console.debug("setReplay", this.player)
		//  MTVW-444, MTVW-788, MTVW-787
		if (this._parent.playerFg && !(import.meta.env.VITE_DISPOSE_LIVE_PLAYER === "true")) {
			// keep foreground player
			yield this._parent.deactivateBgPlayer()
		}
		else if (this._parent.playerFg) {
			//this.removePauseAd("close")
			yield this._waitPreload.waitAsync()
			this._parent.inactivePlayer._source.removePauseAd()
			this.removePauseAd()
			yield this._parent.inactivePlayer.stopInstance()
			//this._parent.setPlayerFg(null)
		}

		if (this.Event?.AvailabilityStartDateTs <= this.player.rangeTimeStampStart) {
			console.debug("setReplay 1")
			if (import.meta.env.VITE_PLAYER === "vjs") {
				//yield this.player.setRefElemContainer(null, true)
				//yield this._parent.playerAds.setRefElemContainer(null, true)
				//yield this._parent.remountPlayerComponent()
			}
			this.player.setIsPosterVisible(true, "setReplay", true)
			yield this._parent.player.stopInstance()
			yield this._parent.handlePlaySourceAsync(SourceChannelReplay.create(this._parent, this.Channel?.id, this.GetEvent, this.Event?.AvailabilityStartDate, true, null, this.restartDone).setAsLoaded())
		} else {
			console.debug("setReplay 2 %s, %s, %s", this.sessionId, this.trueReplaySession, this.restartDone)
			console.debug("setReplay isGt12Channel", this.Channel?.id, this.isGt12Channel(this.Channel?.id))
			// GT12
			//this.player.setPositionTimeStamp(this.Event?.AvailabilityStartDate)
			if (this.isGt12Channel(this.Channel?.id) && (this.sessionId === null || !this.trueReplaySession)) {
				console.debug("setReplay 2a")
				// channelId, bufferTimeStart, oEventFull = null, bForceLoad = false, trueReplaySession = true, sessionId = null, restartDone = false
				if (import.meta.env.VITE_PLAYER === "vjs") {
					//yield this.player.setRefElemContainer(null, true)
					//yield this._parent.playerAds.setRefElemContainer(null, true)
					//yield this._parent.remountPlayerComponent()
				}
				this.player.setIsPosterVisible(true, "setReplay", true)
				yield this._parent.player.stopInstance()
				yield this._parent.setPlayChannelReplayAsync(this.Channel?.id, this.Event?.AvailabilityStartDate, this.GetEvent, true, true, null, this.restartDone).then(() => {
					//this.player.setPositionTimeStamp(this.Event?.AvailabilityStartDate).setPlay("SourceChannelReplay.setReplay")
					//console.debug("restartDone %s", this.restartDone)
				})
			}
			else {
				console.debug("setReplay 2b %s", this.sessionStart)
				// MTVW-495
				//this.player.setPositionTimeStamp(this.Event?.AvailabilityStartDate)
				// VO PLAYER SYNC
				//if (import.meta.env.VITE_PLAYER === "vjs") {
				if (!this.player.isPaused) this.player.setIsPosterVisible(true, "setReplay", false)
				// OPTIMIZATION for SourceChannelReplay.setReplay (currently only for non GT12 channels)
				if (!this.isGt12Channel(this.Channel?.id)) this.sessionStart = null
				//}
				yield this.player.setPositionTimeStampAsync(this.sessionStart ? this.sessionStart : this.Event?.AvailabilityStartDate)
			}
		}
		this.markerPosD = null
	})

	timeStampEvent(timeStamp, offsetSeconds, playCompleted) {
		this.checkForLinearAd(timeStamp, offsetSeconds)
	}

	// handle click in time bar, called also from skipAd
	handleSetPosition = flow(function* (positionMoment) {
		cDebug("handleSetPosition replay ", positionMoment, this.player.rangeTimeStampStart, this.player.rangeTimeStampEnd /*, new Error().stack*/)
		//this.removePauseAd()

		// MTVW-444, MTVW-788, MTVW-787
		if (this._parent.playerFg) {
			//const fgPaused = this._parent.playerFg.isPaused
			// swap players and switch to background player
			yield this._waitPreload.waitAsync()
			// if VITE_DISPOSE_LIVE_PLAYER playerFg will be cleared on preload
			const diff = this._parent.playerFg ? this._parent.playerFg.positionTimeStamp - moment(positionMoment).utc().valueOf() :
				this._parent.player.positionTimeStamp - moment(positionMoment).utc().valueOf()
			console.debug("diff=", diff)
			//console.debug("handleSetPosition fg", fgPaused, positionMoment, moment(positionMoment).utc().valueOf())
			if (!(import.meta.env.VITE_DISPOSE_LIVE_PLAYER === "true")) {
				//yield this._parent.playerFg.setPositionTimeStamp(moment(positionMoment).utc().valueOf())
				yield this._parent.swapPlayers()
			}
			// let _startReplaySession position first, added for setting VITE_DISPOSE_LIVE_PLAYER
			yield sleep(200)
			//this._parent.player.setPositionTimeStamp(moment(positionMoment).utc().valueOf())
			//console.debug("diff=", this._parent.player.positionTimeStamp, moment(this._parent.player.positionTimeStamp).utc())
			// TODO: We should use handlePlaySourceAsync live setPlayPause in order to jump back more than 15 minutes
			/* possible workaround
			const source = this.getClone().setAsLoaded()
			yield this._parent.handlePlaySourcePreserveProgressBarAsync(source, (source) => source._openStreamRollingBufferAsync(source.Event?.AvailabilityStartDateTs, positionMoment))
			*/
			yield this._parent.player.setPositionTimeStampAsync(this._parent.player.positionTimeStamp - diff)
			return
		}

		if (positionMoment.isAfter(moment())) {
			cDebug("handleSetPosition setJumpLive")
			yield this.setJumpLive()
		} else if (positionMoment.isBefore(this.player.rangeTimeStampStart) || (positionMoment.isAfter(this.player.rangeTimeStampEnd) && this.player.isStreamGrowing === false)) {
			cDebug("handleSetPosition special case outside range")
			const source = this.getClone().setAsLoaded()
			// GT12: Fix old existing bug, need to use 'source' for callback and not 'this
			yield this._parent.player.stopInstance()
			// MTVW-822, MTVW-823: added source.Event?.AvailabilityEndDateTs)
			yield this._parent.handlePlaySourcePreserveProgressBarAsync(source, (source) => source._openStreamRollingBufferAsync(source.Event?.AvailabilityStartDateTs, positionMoment, source.Event?.AvailabilityEndDateTs))
		} else {
			cDebug("handleSetPosition positionTimeStamp %s, %o", this.player.positionTimeStamp, this.player)
			//this.player.setPositionTimeStamp(positionMoment)
			yield this.handleFastForwardAd(this.player.positionTimeStamp, moment(positionMoment).utc().valueOf(), this.actionAfterFastForwardAd)
		}
	})

	// now obsolete was called from _old_setJumpRwd
	_checkStreamRwd() {
		console.log("@")
		// less than 30min
		if (this.player.positionTimeStamp <= this.player.minimalPositionTimestamp) {
			this.handleStreamOnStart()
			return false
		}
		return true
	}
}
