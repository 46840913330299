import { Typography } from "@mui/material"
import { CreateComponent } from "components/utils/CreateComponent"
import React, { useState } from "react"
import { useInView } from "react-intersection-observer"
import { RecordingIndicator } from "scenes/components/RecordingIndicator"
// import { TvGuideConf } from "store/page/TvGuide"
import { rootStore } from "store/RootStore"
import moment from 'moment'
import LiveIndicator from "img/icons/liveInSearch.svg"
import { SourceTypeList } from "store/page/player/source/SourceTypeList"

//import { EventComponent } from "../../Overlay/components/EventComponent"
import "./TvGuideProgram.css"
//TODO: insert this css in css file
const styles = theme => ({
	// "@keyframes fadeAnimation": {
	// 	from: { opacity: 0, width: "100%", minWidth: "none" },
	// 	to: { opacity: 1, width: "auto", minWidth: "100%" }
	// },

	// tvGuideProgram: {
	// 	background: "linear-gradient(270deg, rgba(0, 0, 0, 0.22) 0%, rgba(0, 0, 0, 0) 100%)",
	// 	backgroundColor: "rgba(255,255,255,0.18)",
	// 	borderLeft: "1px solid rgba(255,255,255,0.18)",
	// 	// trying to avoid flicker with commented styles
	// 	height: "70px", /*"-webkit-overflow-scrolling": "touch", "-webkit-transform": "translate3d(0,0,0)", "-webkit-backface-visibility": "hidden", position: "static"*/
	// 	"&:hover": {
	// 		background: theme.themeSurfaceColorHover,
	// 	}
	// },
	// tvGuideProgramHover: {
	// 	//paddingLeft: "0px",
	// 	paddingRight: "20px",
	// 	animation: "0.5s fadeAnimation linear",
	// 	//width: "inherit",
	// 	minWidth: "fit-content",
	// 	transition: "all 0.3s ease-in",
	// 	position: "absolute",
	// 	top: "0px",
	// 	zIndex: 5,
	// 	background: theme.themeSurfaceColorHover,
	// 	cursor: "pointer",
	// 	borderLeft: "none !important"
	// },

	tvGuideProgramUnavailable: { "& div": {} },

	// tvGuidePast: {
	// 	backgroundColor: "rgba(255,255,255,0.23) !important",
	// 	//opacity: "0.5",
	// },
	// tvGuidePastTitle: {
	// 	color: "rgb(208,208,208)"
	// },

	//MTVW-302
	// GuideProgramType: {
	// 	backgroundColor: "black", //"greenyellow",   

	// 	// "& h6": { fontWeight: "bold", padding: "8px 2px 4px 8px", color: "red" }
	// },


	// GuideProgramType_n: {
	// 	backgroundColor: "rgba(255,255,255,0.08)", //"greenyellow",   
	// 	"& div": { background: "linear-gradient(270deg, rgba(0, 0, 0, 0.22) 0%, rgba(0, 0, 0, 0) 100%)", },
	// 	"& h6": { opacity: 0.5, padding: "8px 2px 4px 8px" }
	// },
	// GuideProgramType_c: { background: theme.themeSurfaceColorLight },
	// GuideProgramType_p: {
	// 	backgroundColor: theme.palette.secondary.main,
	// 	"& div": { backgroundColor: theme.palette.secondary.main }
	// },
	// MTV-2272
	// tvGuideProgramSelected: { backgroundColor: theme.themeSurfaceColorSelected },

	//tvGuideProgramSelected: { borderBottom: `2px solid ${theme.palette.primary["main"]}` },

	// MTV-2349: font size and weight
	// tvGuideProgramTitle: {
	// 	textOverflow: "clip",
	// 	fontSize: "16px",
	// 	fontWeight: "bold",
	// 	padding: "8px 2px 4px 8px",
	// 	color: "rgba(255,255,255,0.8)"
	// },
	// tvGuideProgramFake: {
	// 	// background: "transparent",
	// 	backgroundColor: "rgba(255,255,255,0.03)",
	// 	borderLeft: "1px solid rgba(178,178,178,0.05)",
	// 	// trying to avoid flicker with commented styles
	// 	height: "70px", /*"-webkit-overflow-scrolling": "touch", "-webkit-transform": "translate3d(0,0,0)", "-webkit-backface-visibility": "hidden", position: "static"*/

	// },
	// tvGuideFakeTitleContainer: {
	// 	background: "transparent !important",
	// 	padding: "8px 2px 4px 8px",
	// 	height: "40px",
	// },
	// tvGuideFakeTimeContainer: {
	// 	background: "transparent !important",
	// 	padding: "0px 2px 4px 8px",
	// },
	// tvGuideFakeBar: {
	// 	backgroundColor: "rgba(255,255,255,0.05)",
	// },
	// tvGuideProgramTime: {
	// 	padding: "0px 2px 0px 8px",
	// 	display: "flex",
	// 	color: "rgba(255, 255, 255, 0.8)",
	// 	fontSize: "14px",
	// 	overflow: "hidden",
	// 	whiteSpace: "nowrap",
	// 	textOverflow: "ellipsis",
	// 	background: "transparent !important",
	// 	borderLeft: "none !important",
	// },
	// tvGuideLiveIndicator: {
	// 	width: "32px",
	// 	height: "auto",
	// 	marginRight: "4px"
	// }
})


export const TvGuidePrograms = CreateComponent(null, false, true, ({ className, k, id, ch, page }) => {
	// MTV-1754: reduce margin to avoid unnecessary rows to be refreshed for increased performance
	// TODO: The bug in open ticket MTVW-214 is caused here
	//const [ref, inView, entry] = useInView({ rootMargin: "-200px 0px 0px 0px" /*, root: document.querySelector(".innerTvGuideChannels")*/ })

	// eslint-disable-next-line no-unused-vars
	const [ref, inView, entry] = useInView({ rootMargin: "0px 0px 0px 0px" /*, root: document.querySelector(".innerTvGuideChannels")*/ })

	/*
	if (inView) {
		console.debug("TvGuidePrograms inView ch %s %s %o %o", ch.Name, entry.isIntersecting, entry.target, entry)
	}
	*/

	// MTVW-109 not needed here. Moved to parent TvGuide, here it is repeated for each channel
	// useEffect(() => {
	// 	// MTV-1646: scroll to active channel
	// 	// MTV-1704: set position only on componentDidMount
	// 	if (rootStore.page.ChannelListRepo.isReady) {
	// 		const index = rootStore.page.ChannelListRepo.getIndexChannelOnList(rootStore.page.ChannelListRepo.activeChannelId)
	// 		window.scrollTo(0, index * TvGuideConf.rowHeightInPx)
	// 	}
	// }, []);

	// page.getEpgForChannel triggers a re-render if the returned reference changes
	return (
		<ul ref={ref} className={className} style={{ top: 0, left: 0 }} data-test={`${ch.Name} programs`}>
			{/*console.debug("CHANNEL ROW %s", ch.id)*/}
			{page.isReady && inView && <TvGuideProgramRow key={ch.id} TvGuideItems={page.getEpgForChannel(ch.id)} page={page} channel={ch} />}
		</ul>
	)
})

//let counterRow = 0
const TvGuideProgramRow = CreateComponent(null, false, true, ({ TvGuideItems, channel, page }) => {
	const t = []
	// TvGuideItems contains the programs of 1 day
	//console.debug("TvGuideItems %o CHANNEL %s", TvGuideItems, channel.id)

	// TODO: remove
	//console.debug("PUSHING")
	TvGuideItems.forEach((i, k) => {
		//MTVW-302 isElemInVisibleArea load only visible row elements 
		if (page.isElemInVisibleArea(i)) {
			const programId = `r${channel.id}-${k}`
			//console.debug("PUSH key %s, %s, %s, %s, %s", programId, channel.Name, i.Title.Name, i.AvailabilityStartDateFormat("HH:mm") + "-" + i.AvailabilityEndDateFormat("HH:mm"))
			t.push(<TvGuideProgramRowElem key={programId} ix={k} channel={channel} i={i} page={page} />)
		}
	})

	//console.debug("END PUSH %s", t.length)
	//counterRow++
	//console.debug("counterRow %s items %s visible %s channel %", counterRow, TvGuideItems.size, k, channel.Name)
	return <>{t}</>
})

const TvGuideProgramRowElem = CreateComponent(styles, false, true, ({ ix, i, channel, classes, page }) => {
	const [isShowFullInfoClicked, setShowFullInfoClicked] = useState(false)
	/*
	if (!page.isElemInVisibleArea(i)) {
		console.debug("rowElem %s %s %o", channel.Name, i.Title.Name, i)
		return
	}
	*/
	//const nDash = '\u2013'

	//console.debug("doUpdate %s", page.doUpdate)

	const ref = React.createRef()
	// NOTE: useInView would not work

	// create short programId to improve speed of map key hash
	const programId = `${channel.id}-${ix}`

	const isCurrentEvent = () => {
		// MTV-3012: return true as long as a potential ongoing live playback is not finished
		// Note that Live playback has a typical latency of 40 seconds
		let result = i.isCurrentEvent
		//console.debug("i %o %o", i, rootStore.page.Player.player?._source?.type)
		// GT12 MTVW-487: seconds precision
		//if (page.isSelectedChannel(channel.id) && rootStore.page.Player.player?.isPlayerActive && (rootStore.page.Player.player?._source?.type === SourceTypeList.ChannelLive) && rootStore.page.Player.player?.positionTimeStamp60s) {
		if (page.isSelectedChannel(channel.id) && rootStore.page.Player.player?.isPlayerActive && (rootStore.page.Player.player?._source?.type === SourceTypeList.ChannelLive) && rootStore.page.Player.player?.positionTimeStamp) {
			//if ((moment(i.AvailabilityStart).isSameOrBefore(rootStore.page.Player.player?.positionTimeStamp60s) && moment(i.AvailabilityEnd).isAfter(rootStore.page.Player.player?.positionTimeStamp60s))) {
			if ((moment(i.AvailabilityStart).isSameOrBefore(rootStore.page.Player.player?.positionTimeStamp) && moment(i.AvailabilityEnd).isAfter(rootStore.page.Player.player?.positionTimeStamp))) {
				result = true
			}
			else {
				result = false
			}
		}
		return result
	}

	// 'page.doUpdate' is currently not used
	const isCurrent = isCurrentEvent()

	return /*inView ? null :*/ (
		<li
			// MATTEO
			/*
			style={{
				left: page.getVisibleElemPosLeftInPx(i) + "px",
				width: page.getVisibleElemWidthInPx(i, ref, programId) + "px"
			}}
			*/
			key={programId}
			data-test-selected={isCurrent && (page.isSelectedChannel(channel.id) ? true : false)}
			data-test={`${channel.Name} program`}
			onMouseEnter={() => setShowFullInfoClicked(true)}
			onMouseLeave={() => setShowFullInfoClicked(false)}
			//className={`${!i.available && classes.tvGuideProgramUnavailable} ${i.current ? classes.tvGuideProgramCurrent : classes.tvGuideProgramPastOrFuture} ${selected &&
			//classes.tvGuideProgramSelected}`}
			// classes.tvGuideProgramUnavailable
			// classes.tvGuideProgramCurrent : classes.tvGuideProgramPastOrFuture
			// classes.tvGuideProgramSelected

			// MTV-1630: gray out titles for channels without replay
			// className={
			// 	// eslint-disable-next-line no-constant-condition
			// 	`${true ? "" : classes.tvGuideProgramUnavailable} 
			// 	${isCurrent ? (page.isSelectedChannel(channel.id) ? classes.tvGuideProgramSelected : classes.GuideProgramType_c)
			// 		: (channel.IsOnRecordingOptinList ? classes["GuideProgramType_" + i.getCurrentEventType] : classes.GuideProgramType_n)
			// 	} `
			// }

			// MTVW-302 new css
			className={
				// eslint-disable-next-line no-constant-condition
				` GuideProgramType ${true ? "" : classes.tvGuideProgramUnavailable}
				${isCurrent && (page.isSelectedChannel(channel.id) ? "tvGuideProgramSelected" : "")} `
			}

			onClick={() => { if (!i.event.fake) page.setEpgEventClick(i) }}
		>
			<div ref={ref}
				id={programId}
				style={{
					//left: page.getVisibleElemPosLeftInPx(i) + "px",
					// No gradient on the playing program
					background: isCurrent && page.isSelectedChannel(channel.id) ? "#41111D" : "",
					width: `${page.getVisibleElemWidthInPx(i, ref, programId) - 1}px`,
					boxSizing: "border-box",
					// NOTE: the domRef is only defined at the 2nd call
					paddingLeft: `${page.getElemLeftPadding(i, ref, programId)}px`
				}}
				// selected date is iDateTs and current date (today) is  getDateCurrTs
				// className={`${classes.tvGuideProgram} ${moment(page.iDateTs).isBefore(page.getDateCurrTs) ? classes.tvGuidePast : ""}`}
				className={(!i.event.fake) ? "tvGuideProgram" : "tvGuideProgramFake"}

			>
				{!i.event.fake ? (
					<Typography noWrap={true}
						// className={`${classes.tvGuideProgramTitle} ${moment(page.iDateTs).isBefore(page.getDateCurrTs) ? classes.tvGuidePastTitle : ""}`}
						className="tvGuideProgramTitle"
						data-test="tvguide_channel_title">
						{i.Title.Name}
					</Typography>
				) : (
					<div className="tvGuideFakeTitleContainer">
						<div className="tvGuideFakeBar" style={{ width: "50%", minWidth: "100px", maxWidth: "300px", }} >
							&nbsp;
						</div>
					</div>
				)}
				{/* MTV-1757: SeriesID would also display canceled series events */}

				{!i.event.fake ? (
					<div className="tvGuideProgramTime" data-test="tvguide_channel_airtime">
						<RecordingIndicator eventId={i.id} showText={false} />
						{isCurrent && <img src={LiveIndicator} className="tvGuideLiveIndicator" data-test="tvguide_live_icon" alt="" />}
						{i.AvailabilityStartDateFormat("HH:mm")}
					</div>
				) : (
					<div className="tvGuideFakeTimeContainer">
						<div className="tvGuideFakeBar" style={{ minWidth: "50px", maxWidth: "50px" }} >
							&nbsp;
						</div>
					</div>
				)}

				{isShowFullInfoClicked && !i.event.fake && (
					<div className={`tvGuideProgram tvGuideProgramHover`} style={{
						// width is required here for the hover state of the now playing program
						width: `${page.getVisibleElemWidthInPx(i, ref, programId) - 1}px`,
					}}>
						<Typography noWrap={true} className="tvGuideProgramTitle">
							{i.Title.Name}
						</Typography>
						{/* MTV-1757: SeriesID would also display canceled series events */}

						<div className="tvGuideProgramTime">
							<RecordingIndicator eventId={i.id} /* seriesId={i.Title.SeriesId} */ showText={false} />
							{isCurrent && <img src={LiveIndicator} className="tvGuideLiveIndicator" data-test="tvguide_live_icon" alt="" />}
							{i.AvailabilityStartDateFormat("HH:mm")}
						</div>
					</div>
				)}
			</div>
		</li>
	)
})
