/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-04
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-10
 */

import md5 from "md5"
import axios from "axios"
import { action, flow, observable, makeObservable } from "mobx"
import { applySnapshot, getIdentifier } from "mobx-state-tree"
import { TraxisError } from "store/api/TraxisError"
import { ClassAbstract } from "store/ClassTools"
import { rootStore } from "store/RootStore"
import { tryJsonParse } from "store/util/jsonUtils"
import { Wait } from "store/util/Wait"
import { TraxisAjax, TraxisAjaxCancel } from "./TraxisAjax"
import { TraxisCacheItem } from "./TraxisCache"
import { getCpeId } from "store/api/mixin/TraxisCpeIdMixin"
import { appStats, updateMemoryStats } from "utils/TestSupport"
import { serviceUrls } from "store/qlapi/ServiceUrls"
import { getQlHeaders } from "store/qlapi/QlHeaders"
import { ErrorBase } from "store/ErrorBase"
import { timeNow, sleep, backoff, shouldRetry } from "utils/Utils"

export class TraxisAbstract extends ClassAbstract {
	_reqNum = ++appStats.traxisObjects
	_oStatus = new Wait(this, `_oStatus[${appStats.traxisObjects}]`)
	_cancelToken = null
	_timeCreate = rootStore.time.getTime()

	_oVars = {}
	_oCache = {}
	_cacheAdapter = null
	_oReq = {
		hash: null
	}
	_oResp = {
		hash: null,
		tHeader: []
	}

	Data = null
	updateTimeStamp = 0
	_onStartCreate = false
	_onStartRemove = false

	error = null

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			Data: observable,
			updateTimeStamp: observable,
			error: observable.ref,
			_setParseResp: action,
			_setVars: action
		})
	}

	get DataArray() {
		return this.Data !== null ? this.Data : []
	}

	get DataMap() {
		return this.Data !== null ? this.Data : new Map()
	}

	get isReady() {
		return this.Data && this.Data.isReady ? true : false
	}

	_setParseResp(data) {
		const model = this._model
		if (model.create) {
			//|| this._onStartRemove === true
			if (this.Data === null) {
				this.Data = model.create(data, { api: this })
			} else {
				// little mess
				const id = getIdentifier(this.Data)
				if (id) {
					const dataModel = model.create(data, { api: this })
					if (getIdentifier(dataModel) === id) {
						applySnapshot(this.Data, data)
					} else {
						this.Data = dataModel
					}
				} else {
					applySnapshot(this.Data, data)
				}
			}
		} else if (this.Data === null || this._onStartRemove === true) {
			// NOTE: this will invoke the setter, e.g. set Channels(v)
			//console.debug("Object.assign 1")
			this.Data = Object.assign(new model(this, "Data"), data)
		} else {
			//console.debug("Object.assign 2")
			Object.assign(this.Data, new model(this, "Data"), data)
		}
		this.updateTimeStamp = rootStore.time.getTimeStamp()
		// change for mobx 6.3 which is more strict
		//this.Data.isReady = true
		// MTVW-636, avoid potential excpetion
		this.Data?.setReady?.(true)
		return this
	}

	get profileId() {
		return rootStore.api._getProfileId(this._oVars._profileId)
	}

	get tokenId() {
		return rootStore.api._getTokenId(this._oVars._profileId)
	}

	get isInProgress() {
		return this._oStatus.isStarted
	}

	get isInApi() {
		return true
	}

	_setVars(oVars, oCache, cacheAdapter) {
		this._oVars = oVars
		this._oCache = oCache
		this._cacheAdapter = cacheAdapter
		if (this._onStartCreate === true && this.Data === null) {
			this.Data = new this._model(this, "Data")
		}
		return this
	}

	// abstract section start
	getXmlReq() {
		throw Error("getXmlReq should be implemented")
	}

	_getParseResp(data) {
		return { data }
	}

	// MTVW-636: cache buster for GetChannelLists
	get _CACHE_BUSTER() {
		return this._oVars._objectName === "GetChannelLists" ? "&client=web" + Math.floor(Math.random() * 999) : ""
	}

	_getParseReq(req) {
		// MTV-2205: Enable request name also in production build for more detailed info in Bugsnag
		//if (import.meta.env.MODE === "development") {
		return {
			...req,
			...{
				url: serviceUrls.traxisUrl + "&req_name=" + this._oVars._objectName + this._CACHE_BUSTER
			}
		}
		//}
		//return req
	}

	_getHashReq(data) {
		console.info("@(data=%o)", data)
		return md5(JSON.stringify(data())).toString()
	}
	// abstract section end

	/**
	 * @returns {this}
	 */
	abortReq() {
		console.info("@()")
		if (this._cancelToken && this._cancelToken.cancel && this._cancelToken.token) {
			this._cancelToken.cancel()
			this._cancelToken.token = null
		}
		if (this._oStatus.isStarted) {
			//console.debug("abort reject")
			this._oStatus.setReject()
		}
		return this
	}

	waitAsync = flow(function* () {
		return yield this.fetchDataAsync(true)
	})

	/**
	 * @description
	 * @param {...[*]} args true=bOnlyIfNoData
	 * @returns {this}
	 */
	fetchData(...args) {
		this.fetchDataAsync(...args)
		return this
	}

	fetchDataAsync = flow(function* (bOnlyIfNoData = null) {
		//console.debug("fetchDataAsync", bOnlyIfNoData, this)
		try {
			if (bOnlyIfNoData === true && this.isReady === true) {
				return null
			}
			if (this._oStatus.isStarted === true) {
				console.info("@@fetchDataAsync(bOnlyIfNoData=%o) current same req in progress so we can wait", bOnlyIfNoData)
				return yield this._oStatus.waitAsync()
			}

			this._oStatus.setRestart()

			let oSteps = {}

			// if needs profileID in hard way
			if (this._oVars._profileId === true) {
				if (!this._root.waitForAuth.isDone) {
					oSteps.afterCacheWaitForAuth = () => this._root.waitForAuth.waitAsync()
				}
				if (this._oCache.softProfile !== true || !this._root.sso.profile) {
					if (!this._root.waitForAuth.isDone) {
						oSteps.beforeCacheWaitForAuth = () => this._root.waitForAuth.waitAsync()
					}
				}
			}

			const resp = yield this._fetchDataAsync(oSteps)
			this._oStatus.setResolve()
			//this.Data.isReady = true added for MTVW-186 but it does not resolve the problem 
			//console.debug("fetchDataAsync resp", resp, this)
			return resp
		}
		catch (e) {
			console.error("caught in TraxisAbstract.fetchDataSync", e)
			throw e
		}
	})

	fillData() {
		console.log("@")
		if (this.isReady !== true) {
			this.fetchDataAsync(true)
		}
		return this
	}

	// MTVW-585: retryCount 3 -> 1
	_fetchDataAsync = flow(function* (oSteps = {}, retryCount = 1, currentTry = 0) {
		try {
			let reqXml = null,
				reqHash = null,
				respHash = null,
				isReady = false

			// only when cache on
			if (this._oCache.cache === true) {
				if (oSteps.beforeCacheWaitForAuth) {
					// remove ready from request when wait for profile to avoid stale data
					if (this._oCache.showStale === false && isReady === false && this.isReady === true) {
						this.isReady = false
					}
					console.info("@@_fetchDataAsync(oStep.beforeCacheWaitForAuth) -> ._oCache=%o callback=%o", this._oCache, {
						callback: oSteps.beforeCacheWaitForAuth.toString()
					})

					yield oSteps.beforeCacheWaitForAuth()

					console.info("@@_fetchDataAsync(oStep.beforeCacheWaitForAuth) <- ._oCache=%o callback=%o", this._oCache, {
						callback: oSteps.beforeCacheWaitForAuth.toString()
					})
				}

				reqXml = this.getXmlReq()
				reqHash = this._getHashReq(() => reqXml)

				// only if cache store
				if (this._oCache.store === true) {
					const oCacheResp = yield this._getCacheAsync(reqHash)
					// MTV-2133: check also oCacheResp.respData
					if (oCacheResp && oCacheResp.respData) {
						try {
							this._setParseResp(this._getParseResp(tryJsonParse(oCacheResp.respData)))

							this._oResp.hash = oCacheResp.respHash
							if (this.Data) {
								// change for mobx 6.3 which is more strict
								//this.Data.isReady = true
								// MTVW-636, avoid potential excpetion
								this.Data?.setReady?.(true)
								// MTV-2133:
								isReady = true
							}
							// MTV-2133:
							//isReady = true
							this._oStatus.setResolve()
						} catch (e) {
							console.error("caught in _fetchDataAsync", e)
						}
					}
				}
			}

			// remove ready from request when send to backend to avoid stale data
			if (this._oCache.showStale === false && isReady === false && this.isReady === true) {
				// change for mobx 6.3 which is more strict
				//this.Data.isReady = false
				// MTVW-636, avoid potential excpetion
				this.Data?.setReady?.(false)
			}

			if (oSteps.afterCacheWaitForAuth) {
				console.info("@@_fetchDataAsync(oStep.afterCacheWaitForAuth) -> ._oCache=%o callback=%o", this._oCache, {
					callback: oSteps.afterCacheWaitForAuth.toString()
				})

				yield oSteps.afterCacheWaitForAuth()

				console.info("@@_fetchDataAsync(oStep.afterCacheWaitForAuth) <- ._oCache=%o callback=%o", this._oCache, {
					callback: oSteps.afterCacheWaitForAuth.toString()
				})
			}

			if (reqXml === null) {
				reqXml = this.getXmlReq()
			}

			// headers map
			let headers = {}
			// MTV-2904: Enable X-Quickline headers for traxis
			// MTVW-157
			Object.assign(headers, getQlHeaders())
			/*
			if (this._oVars._objectName === "GetChannelLists") {
				headers['pragma'] = 'no-cache'
				headers['cache-control'] = 'no-cache'
				console.debug("traxis name", this._oVars._objectName)
			}
			*/

			// SeacToken into header
			if (this.tokenId !== false) {
				headers.Authorization = `SeacToken token="${this.tokenId}"`
			}

			/* MTV-2132, remove JSON object in dev
			// dev extra options
			if (import.meta.env.MODE === "development") {
				headers["traxis-request-name"] = JSON.stringify({ ...this._oVars, ...{} })
			} else if (import.meta.env.VITE_SHOW_TRAXIS_REQ_NAME === "true") {
				headers["traxis-request-name"] = this._oVars._objectName
			}
			*/
			// MTV-2904: Use "X-Quickline-Function" instead of "traxis-request-name"
			if (import.meta.env.VITE_SHOW_TRAXIS_REQ_NAME === "true") {
				headers["X-Quickline-Function"] = "MTVW-" + this._oVars._objectName
			}

			let resp
			let aborted = false
			try {
				// cancel token
				// MTVW-633: cancelToken is deprecated, use AbortController
				//this._cancelToken = TraxisAjaxCancel.source()
				const controller = new AbortController()

				const reqParam = {
					headers,
					//cancelToken: this._cancelToken.token,
					signal: controller.signal,
					data: reqXml
				}

				//console.debug("-> TraxisAjax.request(%o)", this._getParseReq(reqParam))

				//resp = yield TraxisAjax.request(this._getParseReq(reqParam))
				appStats.reqSent++
				//resp = yield TraxisAjax.request(this._getParseReq(reqParam)).catch((e) => { if (!shouldRetry(resp?.status, retryCount)) { throw e } })
				const _timeout = setTimeout(() => {
					controller.abort()
					aborted = true
				}, 40000)
				resp = yield TraxisAjax.request(this._getParseReq(reqParam))
				clearTimeout(_timeout)

				//console.debug("<- TraxisAjax.request(%o)=%o", this._getParseReq(reqParam), resp)

				//this._cancelToken.token = null

				// maybe the same req is currently resolved so no need make mess, cache must be on
				if (this._oCache.cache === true) {
					respHash = md5(JSON.stringify(resp.data)).toString()

					if (respHash === this._oResp.hash) {
						console.info("@@_fetchDataAsync() - from current item so no update resp.data=%i reqHash=%o respHash=%o", Math.round(resp?.data?.length / 1024, 2), reqHash, respHash)

						// update some times but without data
						if (reqHash !== null && this._oCache.store === true) {
							this._setCacheAsync(reqHash, respHash, null)
						}
						appStats.reqReceived++
						appStats.reqCacheReturns++
						updateMemoryStats()
						return resp
					}
				}
				resp.json = tryJsonParse(resp.data)
			} catch (e) {
				//console.error("_fetchDataAsync catch=", e, e.message, e.code)
				let error = null
				if (axios.isCancel(e)) {
					// MTVW-633: shouldn't be necessary
					//this._oStatus.setReject(e)
					error = ErrorBase.CreateError(["timeout", e?.config?.headers["X-Quickline-Function"], e], e, this, "traxis")
					console.error("Request canceled 1 (timeout) %o, %s, %o, %s", error, retryCount, e, e.message, e.config.headers["X-Quickline-Function"], error.isError
					)
				}
				else {
					error = TraxisError.tryCreateErrorFromJson(e?.response?.data, e, this, "error")
					console.warn("tryCreateErrorFromJson %o msg %o", error, error?.e?.message)
				}
				// MTV-3180: add retry count, fix existing token expiration, retry in case of network error
				if (shouldRetry(resp?.status, retryCount)) {
					//console.debug("retry?", error.isError, error.isNetworkError, error)
					if (error.isError === true) {
						//console.debug("caught axios %o", e)
						this.error = error
						let retry = null

						// priv section, what's the intention???
						retry = yield this._handleTraxisError()
						//console.debug("retry=", retry)
						if (retry === true) {
							appStats.reqRetries++
							appStats.reqSent--
							// MTVW-585: backoff
							const val = backoff(currentTry + 1)
							const start = performance.now()
							yield sleep(val)
							console.debug("backoff tr1 %s, sleep %s, stat %s, err %o", val, performance.now() - start, resp?.status, this.error)
							return yield this._fetchDataAsync(oSteps, retryCount - 1, currentTry + 1)
						}

						// global section if priv not false
						if (retry !== false && this.error instanceof TraxisError) {
							// check for expired token
							//console.debug("calling handleError", this.error)
							retry = yield this.error?.handleError?.()
							//console.debug("called handleError")
							if (retry === true) {
								console.warn("token expiration, retryCount = %s", retryCount)
								appStats.reqRetries++
								appStats.reqSent--
								// MTVW-585: backoff
								const val = backoff(currentTry + 1)
								const start = performance.now()
								yield sleep(val)
								console.debug("backoff tr2 %s, sleep %s, stat %s, err %o", val, performance.now() - start, resp?.status, this.error)
								return yield this._fetchDataAsync(oSteps, retryCount - 1, currentTry + 1)
							}
						}
					}

					// ajax retry (only on network error)
					if (error.isNetworkError === true || aborted) {
						console.warn("%s: network error or timeout, retryCount = %s, %o", timeNow(), retryCount, oSteps)
						appStats.reqRetries++
						appStats.reqSent--
						// MTVW-585: backoff
						const val = backoff(currentTry + 1)
						const start = performance.now()
						yield sleep(val)
						console.debug("backoff tr3 %s, sleep %s, stat %s, err %o, aborted %s", val, performance.now() - start, resp?.status, error, aborted)
						return yield this._fetchDataAsync(oSteps, retryCount - 1, currentTry + 1)
					}
				}
				// report traxis error to user or reject if retries were not successful
				if (!error.isNetworkError || !shouldRetry(resp?.status, retryCount)) {
					appStats.reqReceived++
					//console.debug("throw 1", error)
					throw error
				}
			}

			// check json is object
			if (resp.json && typeof resp.json === "object") {
				console.info("@@_fetchDataAsync() - fetched new item resp.data=%i reqHash=%o respHash=%o", Math.round(resp?.data?.length / 1024, 2), reqHash, respHash)

				// preprocessing json with try/catch without making unnecessary if's
				try {
					resp.jsonParsed = this._getParseResp(resp.json)
					// eslint-disable-next-line no-empty
				} catch (e) { console.error("caught jsonParsed", e) }

				// cache data
				if (this._oCache.cache === true) {
					this._oResp.hash = respHash

					// cache only if store enabled
					if (reqHash !== null && this._oCache.store === true) {
						this._setCacheAsync(reqHash, respHash, resp.data)
					}
				}
				this._setParseResp(resp.jsonParsed)
				//console.debug("resp.jsonParsed", resp.jsonParsed)
				if (this.Data) {
					// change for mobx 6.3 which is more strict
					//this.Data.isReady = true
					// MTVW-636, avoid potential excpetion
					//this.Data.setReady(true)
					this.Data?.setReady?.(true)
				}
			}
			appStats.reqReceived++
			updateMemoryStats()
			return resp
		} catch (e) {
			appStats.reqErrors++
			updateMemoryStats()
			if (e instanceof TraxisError) {
				// throw up to caller
				console.error("reject, throw up to caller %s %o %s", retryCount, e, e?.message)
				this._oStatus.setReject(e)
				//console.debug("throw 2", e)
				throw e
			} else {
				console.error("reject with error and swallow %o, %s, %o, %s", e?.constructor?.name, e, retryCount, e, e?.message)
				//console.debug("throw 3", e)
				//throw e
			}
		}
	})

	// eslint-disable-next-line require-yield
	_handleTraxisError = flow(function* () {
		return null
	})

	_getCacheAsync = flow(function* (reqHash) {
		// MTV-3180: Quick hack for legacy QuotaExceededError.
		// TODO: expiraton / validUntil should be properly applied and multiple cache items for the same request should be fixed
		//console.debug("adapter %o", this._cacheAdapter)
		const CACHE_CLEAR_TIME = 2 * 3600 * 1000 // 2 hours
		if (this._cacheAdapter._dbInfo) {
			if (appStats.reqCacheFetches === 0 || (Date.now() - appStats.cacheClearTime) > CACHE_CLEAR_TIME) {
				if (appStats.reqCacheFetches === 0) {
					appStats.initialDbSize = this._cacheAdapter._dbInfo?.size
					yield this._cacheAdapter?.length().then(function (numberOfKeys) {
						appStats.initialDbKeys = numberOfKeys
					}).catch(function (err) {
					})
				}
				appStats.cacheClearTime = Date.now()
				yield this._cacheAdapter.clear()
			}
			appStats.reqCacheFetches++
		}

		const dataJson = yield this._cacheAdapter.getItem("@" + reqHash)
		const data = tryJsonParse(dataJson)
		if (!data) {
			return null
		}
		const oCache = new TraxisCacheItem().applyData(data)
		yield oCache.fetchCacheContent(this._cacheAdapter)
		return oCache
	})

	_setCacheAsync = flow(function* (reqHash, respHash, resp) {
		const oCache = new TraxisCacheItem()
		oCache.id = reqHash
		oCache.validUntil = rootStore.time.getTimeStamp()
		oCache.respHash = respHash
		oCache.profileId = this._oVars._profileId !== false ? (this._oVars._profileId === true ? this.profileId : this._oVars._profileId) : null

		yield Promise.all([
			// meta data
			this._cacheAdapter.setItem("@" + reqHash, oCache.getJson()),
			// body
			resp !== null ? this._cacheAdapter.setItem("^" + respHash, resp) : Promise.resolve()
		])
	})

	_delCacheAsync = flow(function* (key) {
		yield this._cacheAdapter.removeItem(key)
	})
}
