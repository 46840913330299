// l10n status: done
import { Grid } from "@mui/material"
import { Typography, TextField, Button, Snackbar, Card, Divider, MenuItem, MenuList, Popper, Tooltip, Checkbox, ListItemIcon } from "@mui/material"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import ListIcon from "components/welcomeModal/icons/channel-list.svg"
import ClearIcon from "@mui/icons-material/Clear"
import ChannelFilterIcon from "img/channel-filter.svg"
import DeleteIcon from "img/delete.svg"
import CheckIcon from 'img/icons/check-white.svg'
import { InputAdornment } from "@mui/material"
import React, { useRef, useEffect, useState } from "react"
import { CreateComponent } from "components/utils/CreateComponent"
//import { BottomMenu } from "components/BottomMenu"
import { rootStore } from "store/RootStore"
//import fallback from "img/fallback/empty.png"
import ChannelList from "./ChannelList"
import { ChannelGrid } from "scenes/ChannelListEditor/ChannelGrid"
import { useHistory, useParams } from "react-router"
import NavigationPrompt from "react-router-navigation-prompt"
//import { fade } from "@mui/material/styles"
import { ModalMessage } from "components/ModalMessage"
import { withStyles } from '@mui/styles'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { /*isIpad,*/ isTouchAvailable, browserName, browserOs } from "utils/Utils"
import { l10n } from "store/lang/L10n"

export const CleConfig = {
	leftColumnWidth: 330,
	listCellHeight: 80,
	listNumberingWidth: 30,
	listLogoWrapperWidth: 100,
	listLogoWidth: 44,
	listDataWidth: 180,
	listCellMarginBottom: 15,

	// MTVW-464: No HD badge
	//gridCellHeigth: 180,
	gridCellHeigth: 166,
	gridCellWidth: 134,
	gridLogoWidth: 57,
	gridCellMarginBottom: 15,

	windowHeightUsed: 352,
	listWindowHeightIncrease: -2,
	gridWindowHeightIncreae: 0,

	inputWidth: 280,
	//channelNumToPreGen: 20

	tmpIdPrefix: "tmp_",
	listElemIdPrefix: "list_data_",
	gridElemIdPrefix: "grid_data_",
	listItemId: 0,
	gridItemId: 1,
	listItemType: "LIST_CHANNEL",
	gridItemType: "GRID_CHANNEL"
}

const styles = theme => ({
	CleHeader: { display: "flex", /*width: theme.themeDefaultWidth,*/ margin: "40 auto", flexDirection: "column" },
	CleHeaderWrapper: { paddingTop: 90, paddingBottom: 15, width: "65%" },
	CleTitle: { paddingBottom: 10 },
	CleBodycontainer: { marginLeft: 40 },
	CleGridWrapper: { /*width: theme.themeDefaultWidth,*/ margin: "0 auto", marginTop: 0 },
	CleNameRow: { display: "flex", flexDirection: "row", justifyContent: "flex-start" },
	CleLeftColumn: {
		width: "31%", // intial value, will be overwritten in calcContainerDimensions
		//display: "flex",
		//flexDirection: "column",
		//justifyContent: "space-between",
		paddingLeft: 0,
		paddingTop: 10,
		//backgroundColor: "black !important"
	},
	CleRightColumn: {
		width: "69%", // inital value, will be overwritten in calcContainerDimensions
		paddingTop: 10,
	},
	CleSelectAllOrNone: {
		//order: 0,
		//flex: "0 1 auto",
		//alignSelf: "auto",
		fontSize: 11,
		paddingTop: "3px",
		paddingBottom: "8px"
	},
	CleTextField: { margin: 0, flexGrow: 0, display: "flex", width: CleConfig.inputWidth },
	CleTextFieldFont: {
		// MTV-2637: Min. font size 16 to prevent input zoom
		fontSize: 16
	},
	CleBottomMenu: { /*width: theme.themeExtendedWidth,*/ width: window.innerWidth, background: theme.palette.secondary.main, bottom: "0px !important", left: "0px !important" },
	CleBottomMenuWrapper: { display: "flex", justifyContent: "center", flex: 1, padding: "13px 0" },
	CleBottomMenuButton: { width: 254, margin: "0 12px" },
	CleBottomMenuButtonPrimary: {
		width: 254,
		margin: "0 12px",
		background: theme.palette.primary.main + "!important",
		color: "white !important",
		"&:hover": {
			background: theme.palette.primary.hover + "!important",
		},
	},
	BottomMenuWrapper: { width: theme.themeDefaultWidth },

	CleGridTop: { display: "flex", flexDirection: "row", justifyContent: "space-between" },
	CleListSelector: { display: "flex", zIndex: 100, justifyContent: "flex-end" },
	CleListSelectorWrapper: { zIndex: 100 },
	CleArrowIcon: { padding: 0, marginTop: "-4px" },

	CleListMenuContainer: {
		left: "24px", /*top: "-45px",*/ zIndex: 1,
		//maxHeight: "400px",	// calculated dynamically
		overflowY: "scroll",
		scrollbarWidth: "none",
		"&::-webkit-scrollbar": { display: "none" }
	},
	CleListSelectorHeaderItem: { margin: "0 0 0 16px", textTransform: "none", fontSize: "14px", color: "#FFFFFF", opacity: 1, letterSpacing: 0.4, fontWeight: "normal" },
	CleListSelectorHeaderButton: { "&:hover": { textDecoration: "none" } },

	CleListSelectorItems: {
		padding: "5px 0 10px",
		zIndex: 1500,
		borderRadius: "4px",
		boxShadow: "0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)",
		outline: "0 solid",
		background: "#3F3F3F",
	},
	CheckIconContainer: { minWidth: "40px" },
	CleListSelectorEntry: { fontSize: "13px", padding: "4px 16px", minWidth: "200px" },
	Icons: {
		paddingRight: "10px", marginTop: "0px"
	},
})

const LightTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 11,
	},
}))(Tooltip)

const getUsableContainerHeight = () => {
	const listTop = document.getElementById("cle_list_marker")?.getBoundingClientRect()?.bottom
	let snackTop = document.getElementById("cle_snack_bar")?.getBoundingClientRect()?.top
	snackTop = (snackTop) ? snackTop : window.innerHeight
	//console.debug("inner %s used %s usable %s list top %s snack top %s", window.innerHeight, CleConfig.windowHeightUsed, window.innerHeight - CleConfig.windowHeightUsed, listTop, snackTop)
	return (listTop && snackTop) ? snackTop - listTop : window.innerHeight - CleConfig.windowHeightUsed
}

const getUsableMenuHeight = () => {
	const listTop = document.getElementById("cle_selector_current")?.getBoundingClientRect()?.bottom
	let snackTop = document.getElementById("cle_snack_bar")?.getBoundingClientRect()?.top
	snackTop = (snackTop) ? snackTop : window.innerHeight
	//console.debug("inner %s used %s usable %s list top %s snack top %s", window.innerHeight, CleConfig.windowHeightUsed, window.innerHeight - CleConfig.windowHeightUsed, listTop, snackTop)
	return (listTop && snackTop) ? snackTop - listTop : window.innerHeight - CleConfig.windowHeightUsed
}

const CleListSelector = CreateComponent(styles, false, false, ({ classes, page }) => {
	const allChannels = { id: null, name: l10n.featureChannelListsAllSenders }
	const [openState, setOpenState] = useState(false)
	const [currentList, setCurrentList] = useState(allChannels)
	const anchorEl = useRef(null)
	//const channelLists = rootStore.api.GetChannelLists()
	//const channelLists = page.channelListRepo.ChannelLists
	// MTVW-657, MTVW-686: Predefined channel lists
	const channelLists = rootStore.page.singleton.channelLists.DataArray.ChannelList

	/*
	useEffect(() => {
		//channelLists.fetchData()
	}, []) // Pass an empty array to run only callback on mount only.
	*/

	const handleToggle = (e) => {
		e.preventDefault()
		// MTV-3583: e.stopPropagation required with React 17
		e.stopPropagation()
		setOpenState(!openState)
	}

	const handleChangeChannelList = (channelList) => {
		setCurrentList(channelList)
		page.setCurrentChannelListId(channelList.id)
		setOpenState(false)
	}

	const handleClose = (e = null, prevent = false) => {
		setOpenState(false)
		// on mobile, a touch event on the close button would also create a 2nd event on the Senderliste button that would invoke handleToggle
		if (prevent) {
			e.preventDefault()
		}
	}

	return (
		<div className={classes.CleListSelectorWrapper}>
			<LightTooltip title={l10n.featureChannelListsTitle} arrow>
				<Button id="cle_selector_current" aria-haspopup="true" onClick={(e) => { handleToggle(e) }}
					className={`${classes.CleListSelectorHeaderButton} ${classes.CleListSelectorHeaderItem}`} ref={anchorEl}
					data-test="cle_selector_current" style={{ textTransform: "none", marginTop: "1px", color: "#fff" }} >
					<img src={ListIcon} className={classes.Icons} alt="Sender" />
					{/*<span className="SenderlistenResponsive">{currentList.name}</span>*/}
					<label className="SenderlistenResponsive" style={{ cursor: "pointer" }}>{currentList.name}</label>
					<KeyboardArrowDownIcon className={classes.CleArrowIcon} />
				</Button>
			</LightTooltip>

			{/* MTVW-696: commented + " PopperItemResponsive" to address iPad 7 issue, added getUsableMenuHeight*/}
			<Popper open={openState} anchorEl={anchorEl.current} placement="bottom-start" disablePortal={true} style={{ maxHeight: getUsableMenuHeight() + "px" }} className={classes.CleListMenuContainer/* + " PopperItemResponsive"*/}>
				<Card>
					<ClickAwayListener onClickAway={(e) => { handleClose(e, true) }}>
						<MenuList className={classes.CleListSelectorItems}>
							<MenuItem key={null} onClick={() => handleChangeChannelList(allChannels)} className={classes.CleListSelectorEntry + " CleListSelectorEntryResponsive"} data-test="cle_selector_item">
								{currentList.id === null ?
									<ListItemIcon className={classes.CheckIconContainer}>
										<img src={CheckIcon} alt="check icon" />
									</ListItemIcon>
									:
									<div className={classes.CheckIconContainer}></div>
								}
								{l10n.featureChannelListsAllSenders}
							</MenuItem>

							{channelLists?.map(i => (
								<div key={i.id}>
									{/* MTVW-657: divider before first predefined channel list*/}
									<div>
										{i.firstPredefined ?
											<Divider />
											: null
										}
									</div>
									<MenuItem /*key={i.id}*/ onClick={() => handleChangeChannelList({ "id": i.id, "name": i.Name })} className={classes.CleListSelectorEntry} data-test="cle_selector_item">
										{currentList.id === i.id ?
											<ListItemIcon className={classes.CheckIconContainer}>
												<img src={CheckIcon} alt="check icon" />
											</ListItemIcon>
											:
											<div className={classes.CheckIconContainer}></div>
										}
										{i.Name}
									</MenuItem>
								</div>
							))}
							{/*<hr style={{ border: ".5px solid grey" }} />*/}
							{/*<Divider />*/}

						</MenuList>
					</ClickAwayListener>
				</Card>
			</Popper>
		</div>
	)
})

const CleBody = CreateComponent(styles, false, true, ({ classes, store, page, calcContainerDimensions, containerHeight, rColWidth }) => {
	const [searchString, setSearchString] = useState("")
	const [isDirty, setIsDirty] = useState(true)
	const [isMessageActive, setIsMessageActive] = useState(false)
	const [isInvalid, setIsInvalid] = useState(false)
	const [shouldDelete, setShouldDelete] = useState(false)
	// update does not work when just using page.listName -> use state
	const [channelListName, setChannelListName] = useState(page.listName)
	// update does not work when just using page.isPersonalList -> use state
	const [isPersonal, setIsPersonal] = useState(true)
	const [params, setParams] = useState(null)
	const [isListCreator, setIsListCreator] = useState(false)

	const isSameParams = (lastParams, cParams) => {
		if (!lastParams) return false
		return (lastParams.type === cParams.type) && (lastParams.id === cParams.id)
	}

	//page.setRouteParams(useParams())
	const cParams = useParams()

	// MTVW-169: Make sure page.setRouteParams does not discard changes by calling this._init()
	//if (params !== cParams) {
	if (!isSameParams(params, cParams)) {
		//console.debug("params %o, cParams %o", params, cParams)
		setParams(cParams)
		// The callback is used because otherwise it would not work on page reload
		page.readyCallback = (listName, isPersonal, isCreator) => {
			//console.debug("callback %s", listName)
			setChannelListName(listName)
			setIsPersonal(isPersonal)
			setIsListCreator(isCreator)
			calcContainerDimensions()
		}
		page.setRouteParams(cParams)
	}

	/*
	useEffect(() => {
		//if (page.isReady) {
		//page._setupListAndName()
		//console.debug("useEffect ready state %s listName", page.isReday, page.listName)
		//page.readyCallback = (listName) => { console.debug("callback %s", listName); setChannelListName(listName) }
		//setChannelListName(page.listName)
		//}
	}, [])
	*/

	const handleChangeIsPersonal = () => {
		page.isPersonalList = !page.isPersonalList
		setIsPersonal(page.isPersonalList)
	}

	// useHistory cannot be called from inside of an event handler
	const history = useHistory()
	const save = () => {
		// MTVW-312: check for empty channel list
		if (page?.listChannelIds?.length === 0) {
			setShouldDelete(true)
			return
		}
		if (page.validateChannelListName() === true && page.isUniqueChannelListName() === true) {
			page.saveChannelList().then((info) => {
				//console.debug("save info %o", info)
				setIsInvalid(false)
				setIsDirty(false)
				// MTVW-696: with a production link, goBack on Safari doesn't work without setTimeout
				setTimeout(() => {
					history.goBack()
				}, 50)
			}).catch((reason) => {
				console.debug("catch reason %o", JSON.parse(reason.e.response.data).Error.Message)
				setIsInvalid(true)
			})
		}
		else {
			setIsInvalid(true)
		}
	}
	const discard = () => {
		setIsInvalid(false)
		setIsMessageActive(false)
		history.goBack()
	}

	const discardMessage = {
		title: l10n.featureChannelListsDiscardTitle,
		content: l10n.featureChannelListsDiscardDescription,
		button1: l10n.commonActionCancel,
		button2: l10n.commonActionDiscard
	}
	const invalidNameMessage = {
		title: l10n.featureChannelListsInvalidNameTitle,
		content: l10n.featureChannelListsInvalidNameDescription.replaceAll("\\n", "\n"),
		button1: null,
		button2: l10n.commonActionOk
	}
	const deleteMessage = {
		title: l10n.featureChannelListsDeleteTitle,
		content: l10n.featureChannelListsDeleteDescription.format(channelListName),
		//content: `Möchten Sie die Senderliste '${channelListName}' wirklich löschen?`,
		button1: l10n.commonActionCancel,
		button2: l10n.commonActionDelete
	}

	const introTxt = () => {
		if (page.editListId === null) {
			if (isTouchAvailable()) {
				return {
					title: l10n.featureChannelListsCreateTouchTitle,
					content: l10n.featureChannelListsCreateTouchDescription
				}
			} else {
				return {
					title: l10n.featureChannelListsCreateMouseTitle,
					content: l10n.featureChannelListsCreateMouseDescription
				}
			}
		}
		else {
			if (isTouchAvailable()) {
				return {
					title: l10n.featureChannelListsEditTouchTitle,
					content: l10n.featureChannelListsEditTouchDescription
				}
			} else {
				return {
					title: l10n.featureChannelListsEditMouseTitle,
					content: l10n.featureChannelListsEditMouseDescription
				}
			}
		}
	}

	return page.isReady && (
		//<div className="senderliste-container">
		<div className={classes.CleBodycontainer}>
			<NavigationPrompt
				when={isDirty}
				afterCancel={(clb) => { setIsMessageActive(false) }}>
				{({ isActive, onConfirm, onCancel }) => {
					//console.debug("isActive %s, onConfirm %o, onCancel %o", isActive, onConfirm, onCancel)
					if (isActive) {
						setIsMessageActive(isActive)
						return (
							<ModalMessage
								show={isDirty}
								msgInfo={discardMessage}
								when={isDirty}
								onCancel={() => {
									// MTVW-727b: stop playerLiveTvVo in case it was in PiP before, otherwise play would still be on but not visible
									//console.debug("discard", rootStore.page.Player, rootStore.page.Player.playerLiveTvVo)
									//rootStore.page.Player.playerLiveTvVo.setRefElemContainer(null, true)
									rootStore.page.Player.playerLiveTvVo.stopInstance()
									onCancel()
								}}
								onConfirm={() => {
									setIsMessageActive(false)
									onConfirm()
									setTimeout(() => {
										const location = window.location
										//console.debug("location=", location, document.getElementById("channelListDiscard"))
										if (document.getElementById("channelListDiscard")) {
											// MTVW-397, MTV-657: page not left. Hack, go to liveTv
											window.location = location.origin
											//window.location = location.href
										}
									}, 500)
								}}
							/>
						)
					}
				}}
			</NavigationPrompt>

			<div className={classes.CleHeader + " CleHeaderResponsive"}>
				<div className={classes.CleHeaderWrapper /*+ " CleHeaderWrapperResponsive"*/}>
					<Typography variant="h5" className={classes.CleTitle}>{introTxt().title}</Typography>
					<Typography variant="caption">{introTxt().content}</Typography>
				</div>
			</div>

			<Grid container alignContent="flex-start" spacing={0} className={classes.CleGridWrapper /*+ " CleGridWrapperResponsive"*/}>
				<Grid item className={classes.CleLeftColumn} style={{ width: CleConfig.leftColumnWidth }}>
					<Grid className={classes.CleNameRow}>
						{/* color="primary" results in red underline on focus, color="secondary" removes underline on focus */}
						<TextField id="channel-list-name" color="primary" type="text" value={channelListName}
							label={l10n.featureChannelListsNameLabel} size="small" variant="filled"
							className={classes.CleTextField}
							InputProps={{
								classes: { root: classes.CleTextFieldFont },
							}}
							InputLabelProps={{
								classes: { root: classes.CleTextFieldFont },
							}}
							onChange={(event) => {
								page.validateChannelListName(event.target.value)
								setChannelListName(page.listName)
							}}
							data-test="channelInputNameField"
						/>
						{page.editListId !== null && (
							<Button aria-label="" className={classes.CleIcon} style={{ minWidth: 10 }} onClick={() => setShouldDelete(true)} data-test="delete_channel_list">
								{/* // icon does not scale when zooming on Safari
									<DeleteIcon style={{ fontSize: 18 }} />
									*/}
								<LightTooltip title={l10n.featureChannelListsDeleteTitle}>
									<img src={DeleteIcon} style={{ width: 22 }} alt="delete"></img>
								</LightTooltip>
							</Button>
						)}
						<ModalMessage
							show={shouldDelete}
							msgInfo={deleteMessage}
							onConfirm={() => {
								page.deleteChannelList(page.editListId)
								setIsDirty(false)
								history.goBack()
							}}
							onCancel={() => setShouldDelete(false)}
						/>
					</Grid>
					<Grid style={{ height: 30, visibility: isListCreator ? "visible" : "hidden" }}>
						<Checkbox
							color="primary"
							checked={!isPersonal}
							onChange={() => { handleChangeIsPersonal() }}
							size="small"
						//style={{ height: 18, marginTop: 6 }}
						//inputProps={{ width: "80%", height: "80%" }}
						//style={{ minWidth: 10 }}
						/>
						<Typography style={{ marginTop: 12 }} variant="caption">{l10n.featureChannelListsAvailableInAllProfiles}</Typography>
					</Grid>
					<div id="cle_list_marker" />
					<ChannelList /*style={{ position: "absolute" }}*/ page={page} windowDimensions={containerHeight + CleConfig.listWindowHeightIncrease} />
					{/*<BottomMenu store={store} />*/}
				</Grid>
				<Grid item className={classes.CleRightColumn /*+ " CleRightColumnResponsive"*/} style={{ width: rColWidth }} >
					<div className={classes.CleGridTop}>
						<TextField id="search-channel" color="primary" placeholder="Sender" type="text"
							value={searchString} size="small" variant="filled" className={classes.CleTextField} /*type="search"*/
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<img src={ChannelFilterIcon} alt="" style={{ width: 20 }}></img>
									</InputAdornment>
								),
								endAdornment: (
									<InputAdornment position="start">
										{(searchString?.length > 0) && (
											<ClearIcon style={{ /*paddingBottom: 0,*/ fontSize: 16, cursor: "hand" }} onClick={() => { setSearchString(""); page.filterChannels("") }} />
										)}
									</InputAdornment>
								),
								// MTV-2637: Min. font size 16 to prevent input zoom
								style: { fontSize: 16, height: 56, paddingBottom: 12, paddingRight: 0, display: "flex" /*, flexDirection: "column", justifyContent: "center"*/ }
							}}
							/*
							InputLabelProps={{
								classes: { root: classes.CleTextFieldFont },
							}}
							*/
							onChange={(event) => { setSearchString(event.target.value); page.filterChannels(event.target.value) }}
						/>

						<CleListSelector page={page} className={classes.CleListSelector} />
					</div>

					<div className={classes.CleSelectAllOrNone}>
						<Button size="small" style={{ marginRight: 20 }} onClick={() => { page.selectAll(page.currentChannels) }}>
							{l10n.commonActionSelectAll}
						</Button>
						<Button size="small" onClick={() => { page.removeAll(page.currentChannels) }}>
							{l10n.commonActionSelectNone}
						</Button>
					</div>

					{(searchString?.length > 0) && (page?.currentChannels?.length === 0) && (
						<>
							<Typography variant="h5" className={classes.CleTitle}></Typography>
							{/* text with newline(s) */}
							<Typography variant="caption" style={{ whiteSpace: "pre-line" }}>
								{l10n.featureChannelListsNoSearchResults.replaceAll("\\n", "\n")}
							</Typography>
						</>
					)}

					<ChannelGrid page={page} windowDimensions={containerHeight + CleConfig.gridWindowHeightIncreae} />

				</Grid>
			</Grid>

			{/*<BottomMenu store={store} />*/}
			{!isMessageActive && !isInvalid && !shouldDelete && (
				<Snackbar className={classes.CleBottomMenu + " CleBottomMenuWrapperResponsive"} open={true} style={{ width: window.innerWidth }}>
					<div id="cle_snack_bar" className={classes.CleBottomMenuWrapper}>
						<Button id="channelListDiscard" color="primary" size="medium" className={classes.CleBottomMenuButton} onClick={() => { discard() }} data-test="new_channel_list_discard_button">
							{l10n.commonActionCancel}
						</Button>
						{/* eslint-disable-next-line jsx-a11y/no-autofocus */}
						<Button  /*disabled={deleteNumber === 0} autoFocus */ size="medium" className={classes.CleBottomMenuButtonPrimary} onClick={(e) => { e.preventDefault(); e.stopPropagation(); save() }} data-test="new_channel_list-confirm-button">
							{l10n.commonActionSave}
						</Button>
					</div>
				</Snackbar>
			)}
			{isInvalid && (
				<ModalMessage
					show={true}
					msgInfo={invalidNameMessage}
					onConfirm={() => { setIsInvalid(false) }}
				/>
			)}
		</div>
	)
})

export const ChannelListEditor = CreateComponent(styles, false, false, ({ classes }) => {
	const page = rootStore.page.ChannelListEditor

	const [containerHeight, setContainerHeight] = useState(null)
	const [gridWidth, setGridWidth] = useState(1000)

	const calcContainerDimensions = () => {
		// MTVW-785: set width for pageWrapper (missed in MTVW-754)
		const pageWrapper = document.getElementById("pageWrapper")
		if (pageWrapper) pageWrapper.style.width = "100vw"
		//console.debug("pageWrapper is", pageWrapper)
		let height = getUsableContainerHeight()
		//console.debug("inner heigth %s usable %s", window.innerHeight, height)
		let rColWidth = window.innerWidth - CleConfig.leftColumnWidth - 60 // consider margins
		/* CAUTION: Applying a change to below breakpoint value requires also the same change in global-responsive.css below the marker "UNIQUE_MARKER: GRID" */
		if (window.innerWidth <= 840) {
			// adjust height on breakpoint, consider that channel search and ALLE / KEINE is moving down
			height = Math.floor(height / 2) - 90 / 2
			rColWidth = window.innerWidth - 60 // consider margins
		}
		setContainerHeight(height)
		setGridWidth(rColWidth)
		//console.debug("inner width %s height %s", window.innerWidth, height)
		//console.debug("width l %s, width r %s, inner ", CleConfig.leftColumnWidth - 60, rColWidth, window.innerWidth)
	}

	const handleResize = () => {
		calcContainerDimensions()
	}

	/* replaced with Utils.isTouchAvailable()
	const touchBackend = ((browserOs() === "iOS") || (browserOs() === "iPadOS") || isIpad() || (browserOs() === "Android OS")) ? true : false
	*/


	useEffect(
		...page.getMountBody(
			() => {
				window.addEventListener("resize", handleResize)
				window.addEventListener("orientationchange", handleResize)
				calcContainerDimensions()
			},
			() => {
				window.removeEventListener("resize", handleResize)
				window.removeEventListener("orientationchange", handleResize)
			}
		)
	)

	return (
		<>
			{/*
			{console.debug("OS %s browser %s touch %s", browser.os, browser.name, isTouchAvailable)}
			*/}
			{isTouchAvailable() === true ?
				// delay for allow scrolling on touch devices
				<DndProvider backend={TouchBackend} options={{ delay: 100 }}>
					{console.debug("OS %s %s Touch", browserOs(), browserName())}
					<CleBody page={page} calcContainerDimensions={calcContainerDimensions} containerHeight={containerHeight} rColWidth={gridWidth} />
				</DndProvider>
				:
				<DndProvider backend={HTML5Backend}>
					{console.debug("OS %s %s HTML5", browserOs(), browserName())}
					<CleBody page={page} calcContainerDimensions={calcContainerDimensions} containerHeight={containerHeight} rColWidth={gridWidth} />
				</DndProvider>
			}
		</>
	)
})
