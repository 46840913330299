// l10n status: done
import { Button, IconButton, Dialog, DialogContent, Checkbox, FormControlLabel, FormControl } from "@mui/material"
import classnames from "classnames"
import { CreateComponent } from "components/utils/CreateComponent.js"
import React, { useRef, useState, useEffect } from "react"
import { rootStore } from "store/RootStore"
import CloseButton from "img/player/close.svg"
import CloseButtonHover from "img/player/close-hover.svg"
// TVUX-110
//import PersonIcon from "img/person.svg"
import PersonIcon from "img/account-circle.svg"
import logo from "img/Quickline_logo.svg"

import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import ErrorIcon from '@mui/icons-material/Error'

//import Info from '@mui/icons-material/Info'
import infoIcon from 'img/icons/info.svg'
import errorIconBlack from 'img/icons/errorBlack.svg'
import "./loginStyle.css"
import { isMobileOrTablet, browserOs, sleep } from "utils/Utils"

import { PinResetDialog } from "./PinReset/PinReset"
import { appFeatures } from "../../AppFeatures"
import { l10n } from "store/lang/L10n"


const styles = theme => ({
	notchedOutlineError: {
		borderColor: theme.palette.primary.main,
		backgroundColor: "rgba(176, 0, 32, 0.1)"
	},
	//Profile
	mainProfilePage: {
		background: "#191919",
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	profilesContainer: {
		maxWidth: 500, //420 to have max 3 coloumn
		//minWidth: 380,
		width: "fit-content",
		padding: "48px",
		borderRadius: 8,
		maxHeight: "100%",
		"-webkit-overflow-scrolling": "touch",
	},
	profilesContainerForFour: {
		maxWidth: 421, // to have max 2 coloumn
		minWidth: 250,
		padding: "48px",
		borderRadius: 8,
		maxHeight: "100%",
		overflow: "auto",
		"-webkit-overflow-scrolling": "touch",
	},
	profilesTitle: {
		color: "#FFFFFF",
		fontSize: "24px",
		fontFamily: "EuropaBold",
		textAlign: "center",
		//flex: "0 0 auto",
		marginTop: 16,
		marginBottom: 16,
		minHeight: 32,
	},
	profilesBody: {
		//flex: "0 0 auto",
		display: "flex",
		flexWrap: "wrap",
		minHeight: 244,
		//justifyContent: "space-between", MTVW-191
		//maxWidth: "81%",
		margin: "auto"
	},
	profilesBodyForFour: {
		justifyContent: "center",
	},
	profileContainer: {
		width: 130,
		textAlign: "center",
		display: "flex",
		flexDirection: "column",
		justifyContent: "start",
		alignItems: "center",
		flexWrap: "nowrap",
		padding: "15px 15px 0px 15px",
		//marginRight: "auto",
		//marginLeft: "auto",
		cursor: "pointer",
		"-webkit-transition": "all 0.3s",
		"-webkit-transitionTimingFunction": "cubic-bezier(.18, .66, .42, .98)",
		transition: "all 0.3s",
		transitionTimingFunction: "cubic-bezier(.18, .66, .42, .98)",
		"&:hover": {
			"-webkit-transform": "scale(1.1)",
			transform: "scale(1.1)",
		//transitionTimingFunction: "cubic-bezier(.18, .66, .42, .98)"
		},
		"&:active": {
			"-webkit-transform": "scale(1.1)",
			transform: "scale(1.1)",
			//transitionTimingFunction: "cubic-bezier(.18, .66, .42, .98)"
		}
	},
	profileName: {
		width: "100%",
		overflow: "hidden",
		overflowWrap: "break-Word",
		lineHeight: "1.8em",
		fontSize: "1rem",
	},
	profileIconInProfile: {
		// background: "#363636",
		backgroundColor: "rgba(176, 0, 32, 0.8)",
		height: 80,
		width: 80,
		borderRadius: "50%",
		border: "2px solid #5A0E21",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		"&:hover": {
			backgroundColor: "rgba(176, 0, 32, 1)",
			border: "none",
		},
	},
	profilesFooter: {
		//flex: "0 0 auto",
		alignSelf: "stretch",
		display: "flex",
		alignItems: "flex-end",
		justifyContent: "center",
		minHeight: 72,
	},
	profileLogOutButton: {
		background: theme.palette.primary.main,
		color: "#FFFFFF",
		"&:hover": {
			background: theme.palette.primary.hover,
		}
	},
	rootDialog: {
		zIndex: "200000 !important",
		top: "150px !important",
		height: "80%",
	},
	root: {
		background: "#191919",
		display: "flex ",
		alignItems: "center",
		justifyContent: "center",
		// MTVW-157: avoid border around dialog if fullScreen is not specified in dialog
		padding: "0px 0px 0px 0px !important",
		// TRIAL Android
		//margin: "0px 0px 0px 0px !important",
		//overflowY: "scroll",
	},
	dialog: {
		//display: "block",
		position: "absolute",
		//top: "-232px !important",
		//overflowY: "scroll",
		height: "100%",
		padding: "0px 0px 0px 0px",
		margin: "0px 0px 0px 0px",
	},
	dialogContainer: {
		// Set to 0 for Android tablet
		//padding: " 3px 3px 30px 3px",
		position: "relative",
		//top: "100px !important",
		padding: "0px 0px 0px 0px",
		//overflowY: "scroll",
		background: "#232323",
		height: 512,
		// TRIAL Android
		//minHeight: 512,
		width: 375,
		borderRadius: 24,
		//margin: " 0px 0px 0px 0px",
		// TRIAL Android
		//overflowY: "scroll",
	},
	pinEnterRowClose: {
		display: "flex",
		justifyContent: "Flex-end",
		width: "100%",
	},
	pinEnterRow: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "100% ",
		flex: "1 1 auto",
	},
	hidePinTxt: {
		opacity: 0,
		pointerEvents: "none",
		height: 0,
	},
	// added important for the issue in mobile mode with 2 class
	pinRowSpace: {
		marginBottom: "50px !important",
		width: "180px !important"
	},
	profileIconContainer: {
		background: theme.palette.primary.main,
		height: 96,
		width: 96,
		borderRadius: "50%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	profileIcon: {
		height: "100%",
		width: "100%",
		opacity: 0.8,
		"&:hover": {
			opacity: 1
		}
	},
	startMaskEnterPin: {
		background: "white",
		borderRadius: "50%",
		height: 8,
		width: 8,
		transition: "all 1.2s",
		transitionTimingFunction: "cubic-bezier(.18, .66, .42, .98)",
		"-webkit-transition": "all 1.2s",
		"-webkit-transitionTimingFunction": "cubic-bezier(.18, .66, .42, .98)"
	},
	endMaskEnterPin: {
		background: theme.palette.primary.main,
		borderRadius: "50%",
		height: 8,
		width: 8,
		transition: "all 1.2s",
		transform: "scale(2)",
		transitionTimingFunction: "cubic-bezier(.18, .66, .42, .98)",
		"-webkit-transition": "all 1.2s",
		"-webkit-transform": "scale(2)",
		"-webkit-transitionTimingFunction": "cubic-bezier(.18, .66, .42, .98)"
	},
	maskEnterPinContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-evenly",
		height: 32,
		marginTop: 5
	},
	pinDotContainer: {
		height: 32,
		width: 32,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	pinForgotContainer: {
		height: 36,
		width: 166,
		color: theme.palette.primary.main,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	pinForgotBackGroundError: {
		background: theme.palette.primary.main,
		color: "#FFFFFF",
		"&:hover": {
			background: theme.palette.primary.hover,
		}
	},
	pinErrorContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		paddingTop: 4,
	},
	pinErrorMessageContainer: {
		height: 32,
		width: 180,
		borderRadius: 3,
		//backgroundColor: "#4C4C4C",
		backgroundColor: "#F7B500",
		textAlign: "center",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		color: "#1a1a1a",
	},
	hide: {
		visibility: " hidden"
	},
	pinEntryDialogContent: {
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		alignItems: "center"
	},

	pinEntryDialogButtons: { marginBottom: 15 },
	closeButton: {
		width: 48,
		height: 48,
		background: `url("${CloseButton}") no-repeat`,
		backgroundPosition: "center",
		backgroundSize: "32px 32px",
		"&:hover": {
			background: `url("${CloseButtonHover}") no-repeat`,
			backgroundPosition: "center",
		},
	},
	margin: {
		margin: theme.spacing(1),
	},
	textField: {
		width: '100%',
	},
	inputNoAutoFill: {
		/* No capitalization impact on Safari
		textTransform: "none",
		autoCapitalize: "off",
		*/
		"&:-webkit-autofill": {
			WebkitBoxShadow: "none",
			WebkitBackgroundClip: "text",
		}
	},
	rootCheckBox: {
		//"&$checked": {
		"&.MuiCheckbox": {
			color: "white"
		}
	},
	checked: {
		color: "white"
	}
})

const handleLogOut = () => {
	rootStore.sso.handleLogOffAsync()
}

// MTVW-157: restore original page position after closing pin entry dialog
// used for avoiding asynchronous useState issues, especially with storedPin
const UNDEFINED = -10000
const peState = {
	scrollPos: UNDEFINED,
	dialogTopPos: UNDEFINED,
	closed: false,
}

export const PinEntryDialog = CreateComponent(styles, false, true, ({ classes, page, open, onPinDone, onClose, profileName, info, flags }) => {
	const isMultiProfile = rootStore.sso.profileOrder.length > 1 ? true : false
	const maskPin = useRef()
	const [numOfTry, setNumOfTry] = useState(0)
	const pinInput = useRef()
	const [pinReset, setPinReset] = useState(false)
	//console.debug("PinEntryDialog", open, profileName, info)

	useEffect(() => {
		window.addEventListener("resize", handleResize)
		return (() => {
			window.removeEventListener("resize", handleResize) // when unload
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setTimeout(() => {
			// apply position at next render
			fixDialogPos()
		})
		setTimeout(() => {
			focusToShowKeyboard()
		}, 500)
		//console.debug("in useEffect SCROLLPOS %s DLGPOS %s", peState.scrollPos, peState.dialogTopPos)
		peState.closed = false
	})

	const closePinResetDialog = () => {
		setPinReset(false)
	}

	const handleResize = () => {
		// Android resizes when virtual keyboard state changes, iOS does not
		// NOTE: Undefined visualViewport in FireFox
		//console.debug("RESIZE inner %s, viewport %s", window.innerHeight, window.visualViewport?.height)
		/**/
		if (browserOs().indexOf("Android") >= 0) {
			//setTimeout(() => {
			// request detection of position
			fixDialogPos(true)
			//}, 500)
		}
		/**/
	}

	const updateNumOfTry = (valueToAdd) => {
		setNumOfTry(numOfTry + valueToAdd) //could be negative for decrease
	}

	const handleClose = (event, reason) => {
		// Material UI 4.12: deprecated disableBackdropClick, we need to handle the reason
		if (reason === "backdropClick") return
		// MTVW-157: clear wrong pin entry on close
		if (!flags.fullScreen && numOfTry !== 0) {
			setNumOfTry(0)
		}
		onClose()
		restoreDialogPos()
	}

	const fixDialogPos = (resize = false) => {
		// adjust position on mobile and pads, otherwise the pin entry field would be hidden by the keyboard
		const content = document.getElementById("dialogContent")
		const dialog = document.getElementById("pinDialog")
		const container = document.getElementById("dialogContainer")
		const portrait = window.innerHeight > window.innerWidth
		//console.debug("inner %s, isMobileOrTablet %s, resize %s", window.innerHeight, isMobileOrTablet(), resize)
		//console.debug("flagFs %s, peState.dialogTopPos %s, windowY", flags.fullScreen, peState.dialogTopPos, window.scrollY)
		if (content) {
			//console.debug("POS CONTENT %s, CONTAINER %s", content.getBoundingClientRect().top, container.getBoundingClientRect().top)
		}
		else {
			//console.debug("NO CONTENT NO, NO DLG POS!")
		}

		if (content && dialog && container && ((peState.dialogTopPos === UNDEFINED) || resize)) {
			const rect = container.getBoundingClientRect()
			peState.dialogTopPos = rect.top
			//console.debug("VAL top %s, height %s, innerH %s", rect.top, rect.height, window.innerHeight)
			// heuristic approach to find a resonable position
			//const portrait = window.innerHeight > window.innerWidth
			const keyboardFraction = portrait ? 0.3 : 0.5
			//const minVisiblePinEntryDlg = 0.8
			//const minVisbileBottomPos = rect.top + minVisiblePinEntryDlg * rect.height
			//const shift = (1 - keyboardFraction) * window.innerHeight - minVisbileBottomPos
			//console.debug("kbFract %s, minVisbileBottomPos %s, SHIFT %s", keyboardFraction, minVisbileBottomPos, shift)

			let idealPos = (window.innerHeight * (1 - keyboardFraction) - rect.height) / 2
			//console.debug("kbFract %s, idealPos %s", keyboardFraction, idealPos)
			idealPos = idealPos < 0 ? 0 : idealPos
			let relPos = -rect.top + idealPos
			// condition for small landscape height on iPhone
			if (window.innerHeight <= 300) relPos = 0
			//console.debug("idealPos %s, relPos %s", idealPos, relPos)
			//console.debug("kbFract %s, minVBottomPos %s, idealPos %s, top %s", keyboardFraction, minVisbileBottomPos, idealPos, rect.top)

			if (!flags.fullScreen && isMobileOrTablet() /*&& browserOs().indexOf("Android") < 0*/) {
				// in case of popup without full screen flag we need to adjust the dialog top position

				//if (relPos !== rect.top) {
				if (resize && browserOs().indexOf("Android") >= 0) {
					// setTimeout kept for testing purposes
					//setTimeout(() => {
					// attempt to remove border on top of modal
					if (portrait) {
						//console.debug("PORTRAIT innerHeight %s", window.innerHeight)
						if (window.innerHeight <= 512) {
							dialog.style.top = "-40px"
							container.style.top = "40px"
							dialog.style.height = "512px"
							container.style.height = "512px"
						}
						else {
							//dialog.style.top = "-100px"
							dialog.style.top = "0px"
						}
						//container.style.top = "0px"
					} else {
						//console.debug("LANDSCAPE innerHeight %s", window.innerHeight)
						// only if top negative, menu height is 85 pixels
						//dialog.style.top = "-140px"
						//container.style.top = "145px"
						// do not reposition on phone
						if (window.innerHeight >= 300) {
							dialog.style.top = "-40px"
							container.style.top = "40px"
							dialog.style.height = "512px"
							container.style.height = "512px"
							//dialog.scrollTo(512)  // generates error message on Android!
						} // else keep position
						else {
							dialog.style.top = "0px"
						}
					}
					//}, 0)
					return
				}
				//console.debug("APPLY %s", relPos)
				dialog.style.top = relPos.toString() + "px"
				// only necessary for negative values
				// container.style.top = idealPos.toString + "px"
				//content.scrollTo(0, 220) // 100: 0, 200: 40, 300: 140, 400: 220

				/*
				// test
				const tgPos = -315 // rect.top = 115
				console.debug("APPLY %s", tgPos)
				setTimeout(() => {
					dialog.style.top = tgPos.toString() + "px"
					container.style.top = "200px"
					//content.scrollTo(0, 220) // 100: 0, 200: 40, 300: 140, 400: 220
				}, 1000)
				*/
			}
			else if (isMobileOrTablet()) {
				// in case of pin entry with full screen flag applied in the dialog, we have to scroll the window (page)
				//const scrollPos = window.innerHeight >= 800 ? 400 : 100
				if (browserOs().indexOf("Android") >= 0) {
					// setTimeout kept for testing purposes
					//setTimeout(() => {
					if (!portrait) {
						//container.style.top = (-100).toString() + "px"
						container.style.top = "90px"
						//container.style.top = (100).toString() + "px"
					}
					//}, 0)
					return
				}
				// iOS works
				//console.debug("APPLY scrollPos %s, relPos %s", scrollPos, relPos)
				container.style.top = relPos.toString() + "px"
				// Android adjusts the window position
				//if (browserOs().indexOf("Android") < 0) window.scrollTo(0, scrollPos)
			}
		}
	}

	const restoreDialogPos = () => {
		if (peState.scrollPos !== UNDEFINED) {
			// Weird workaround for iPhone and iPad. When the dialog is closed while the keyboard is shown,
			// the page is not left at the same scroll position as it was before
			setTimeout(() => {
				//const dialog = document.getElementById("dialogContent")
				//if (dialog) dialog.style.top = peState.dialogTopPos + "px"
				//console.debug("RESTORE to %s", peState.scrollPos)
				if (browserOs().indexOf("Android") < 0) {
					if (flags.restorePos && isMobileOrTablet()) window.scrollTo(0, peState.scrollPos)
				}
				peState.closed = true
				peState.scrollPos = UNDEFINED
				peState.dialogTopPos = UNDEFINED
			}, 500)
		}
	}

	const focusToShowKeyboard = () => {
		if (pinInput.current && !peState.closed) {
			pinInput.current.focus()
			pinInput.current.click()
			// MTVW-157
			if (peState.scrollPos === UNDEFINED) {
				// capture original page position
				//console.debug("capture")
				peState.scrollPos = window.pageYOffset
			}
			//console.debug("focusToShowKeyboard %s", peState.scrollPos)
			// MTV-3154, MTVW-157
			if (!peState.closed) {
				fixDialogPos()
			}
			//document.body.scrollTop = 100
		}
	}
	/**MTV-2142
	 * this is the component used to display pin insertion with animation
	 * @ props.updateNumOfTry update the state of parent PinEntryDialog
	 * input is necessary to display the keyboard for the mobile
	 * */

	const PinMask = React.forwardRef((props, ref) => {
		const [pin, setPin] = useState([])

		useEffect(() => {
			if (pin?.length === 4) {
				setTimeout(() => {
					//rootStore.sso.profile.handleAuthProfileAsync(pin.join(""))
					//restoreDialogPos()
					onPinDone(pin.join(""))
						.then(() => {
							restoreDialogPos()
						})
						.catch(async () => {
							for (let i = 0; i <= 3; i++) {
								let _pin = pin
								// animation to reset the pin Mask
								// WORKAROUND FOR: Error: C:\git\quickline\tmp4\tv-web\src\store\page\repo\MsEpg.js:
								// Internal Babel error: path is not in loop.Please report this as a bug.
								/*
								setTimeout(() => {
									_pin.pop()
									setPin([..._pin])
								}, i * 100)
								*/
								_pin.pop()
								setPin([..._pin])
								await sleep(100)
							}
							if (pinInput?.current) pinInput.current.value = ""
							props.updateNumOfTry(1)
						})
				}, 300)
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [pin])

		const onPofilePinChange = (value) => {
			// if (value >= 0 && value <= 9) {
			if (pin?.length < 4) {
				setPin([...pin, value])
			}
			// }
			// else
			if (value === null) {
				if (pin?.length > 0) {
					let _pin = pin
					_pin.pop()
					setPin([..._pin])
				}
			}
		}

		const _pinMaskToRender = ["pin1", "pin2", "pin3", "pin4"]
		return (
			<div>
				<div className={classes.pinEnterRow}>
					<div className={classes.hidePinTxt}>
						<input ref={pinInput}
							id="input-pin-access-hide"
							type="text"
							inputMode="decimal"
							// eslint-disable-next-line jsx-a11y/no-autofocus
							autoFocus
							tabIndex={0}
							maxLength="4"
							pattern="[0-9]*"
							autoComplete="off"
							style={{ height: "0px" }}
							onChange={(e) => {
								onPofilePinChange(e.nativeEvent.data)
							}}
						>
						</input>
					</div>
				</div>

				<div className={classes.maskEnterPinContainer}>
					{
						_pinMaskToRender.map((item, index) => {
							return (
								<div id={item} key={item}
									className={classnames(classes.startMaskEnterPin, { [classes.endMaskEnterPin]: (pin?.length > index) })}>
								</div>
							)
						})
					}
				</div>
			</div>
		)
	})

	return (
		<Dialog
			className={classes.dialog}
			//scroll="body"
			// MTVW-696 issue: 'Behind the login form'
			maxWidth={"xl"} //md
			open={open}
			onClose={handleClose}
			BackdropProps={{ style: { backgroundColor: "rgba(48,48,48,0.8)" } }}
			id={"pinDialog"}
			// MTVW-157: no full screen for popup case
			fullScreen={flags.fullScreen ? true : false}
			// Material UI 4.12: deprecated disableBackdropClick
			//disableBackdropClick={true}
			onClick={() => {
				focusToShowKeyboard()
			}}
		>
			<DialogContent id={"dialogContent"}
				classes={{
					root: classes.root,
				}}
			>
				<div className={classes.dialogContainer} id="dialogContainer">
					<div className={classes.pinEnterRowClose}>
						<IconButton aria-label="Close"
							disableRipple={true}
							disableFocusRipple={true}
							className={classnames(classes.closeButton, { [classes.hide]: (!isMultiProfile && flags.enableLogoff) })}
							onClick={() => {
								handleClose()
							}}
							data-test="pinEnter_close">
						</IconButton>
					</div>
					<div className={classes.pinEnterRow}>
						<div className={classes.profileIconContainer}>
							<img className={classes.profileIcon} src={PersonIcon} alt="Profil"></img>
						</div>
					</div>

					<div className={classes.pinEnterRow}>
						<h2>{profileName}</h2>
					</div>

					<div className={classes.pinEnterRow}>
						<h3>{l10n.featureLoginEnterPin}</h3>
					</div>

					<div className={classes.pinEnterRow}  >
						<PinMask ref={maskPin} updateNumOfTry={updateNumOfTry} />
					</div>

					<div className={classes.pinEnterRow}>
						<div
							id="pinErrorContainer"
							className={classnames(classes.pinErrorContainer, { [classes.hide]: (numOfTry === 0) })}
						>
							<div id="pinErrorMessageContainer" className={`${classes.pinErrorMessageContainer} ${classes.pinRowSpace}`}>
								<div id="pinErrorMessagePIN" className={classes.pinErrorMessagePIN}>{l10n.featureLoginIncorrectPin}</div>
							</div>
						</div>
					</div>

					<div className={classes.pinEnterRow}>
						{rootStore.sso.profile && <PinResetDialog openDialog={pinReset} closeDialog={closePinResetDialog} email={rootStore.sso.EMail} phone={rootStore.sso.MobileNumber} />}
						<Button
							size="medium"
							disabled={!rootStore.sso.profile}
							className={classnames(classes.pinForgotContainer, { [classes.pinForgotBackGroundError]: (numOfTry > 1) })}
							onClick={() => {
								// MTVW-434
								if (appFeatures.persistedPinReset) setPinReset(true)
								else window.open(l10n.featurePinRecoveryUpdateProfileUrl.format(rootStore.sso?.profile?.id), "_blank")
							}}
						>
							{l10n.featurePinRecoveryTitle}
						</Button>
					</div>

					{flags.enableLogoff && (
						<div className={classes.pinEnterRow}>
							<Button
								size="medium"
								className={classes.pinForgotContainer}
								onClick={() => { handleLogOut() }}
								data-test="pin_entry_logout"
							>
								{l10n.commonActionSignOut}
							</Button>
						</div>
					)}
				</div>
			</DialogContent>
		</Dialog>
	)
})

export const Login = CreateComponent(styles, false, true, ({ classes, page, openPinEntry = false }) => {
	const isMultiProfile = rootStore?.sso?.profileOrder?.length > 1 ? true : false
	// this controls the display of the PIN dialog
	const [open, setOpen] = React.useState(openPinEntry)
	// this prevents intermediate display of Profiles when loading and changing profiles
	const [openProfiles, setOpenProfiles] = React.useState(false)
	const [forcePinEntry, setForcePinEntry] = React.useState(false)

	const handleSubmit = (event) => {
		// prevent performing a page reload on form submit. Without this, /#/ would change to /?#/ in the url!
		event.preventDefault();
	}

	const QLogin = (props) => {
		const [loginData, setloginData] = useState({
			password: '',
			showPassword: false,
			username: '',
			validPassword: true,
			validUsername: true
		})
		const [openInfoUsernameDialog, setOpenInfoUsernameDialog] = useState(false)
		const [loginResponse, setLoginResponse] = useState(true)
		const [keepSignedIn, setKeepSignedIn] = useState(rootStore.sso.keepSignedIn)
		//const userField = useRef(null)
		//const pwField = useRef(null)

		useEffect(() => {
			//console.debug("login with username= %s and password =%s", loginData.validUsername, loginData.validPassword)
			setLoginResponse(true)

			const form = document.getElementById('loginForm');
			form.addEventListener('submit', handleSubmit)

			return (() => {
				window.removeEventListener("submit", handleSubmit) // on unload
			})
		}, [loginData.validPassword, loginData.validUsername])

		/*
		useEffect(() => {
			let interval = setInterval(() => {
				if (pwField.current) {
					setloginData({ ...loginData, password: pwField.current.value, })
					//setloginData(password: pwField.current.value)
					//do the same for all autofilled fields
					clearInterval(interval)
				}
			}, 100)
		})
		*/

		const handleChangeUsername = () => (event) => {
			//console.debug("handleChangeUsername")
			setloginData({ ...loginData, username: event.target.value, validUsername: !event.target.value === "" })
		}

		const handleChangePassword = () => (event) => {
			//console.debug("handleChangePassword")
			setloginData({ ...loginData, password: event.target.value, validPassword: !event.target.value === "" })
		}

		const handleClickShowPassword = () => {
			setloginData({ ...loginData, showPassword: !loginData.showPassword })
		}
		const handleClickShowInfoUsername = () => {
			setOpenInfoUsernameDialog(true)
		}

		const handleClose = () => {
			setOpenInfoUsernameDialog(false)
		}

		const toggleKeepSignedIn = () => {
			rootStore.sso.keepSignedIn = !rootStore.sso.keepSignedIn
			setKeepSignedIn(rootStore.sso.keepSignedIn)
		}

		const handleLogin = () => {
			//console.debug("login with username= %s and password =%s", loginData.username, loginData.password)
			setloginData({ ...loginData, validUsername: loginData.username !== "", validPassword: loginData.password !== "" })
			// TODO
			rootStore.sso.authenticateAsync(loginData.username, loginData.password).then(() => {
				//console.debug("login ok")
				setLoginResponse(true)
			}
			).catch((e) => {
				//console.debug("login error", e)
				setLoginResponse(false)
			})
		}

		const labelUsernameExample = <div className="labelUsernameExample">{l10n.featureLoginLabelUsernameSample}</div>
		const labelUsernameError = <div className="labelError"> <ErrorIcon style={{ fontSize: "16px", marginRight: 4 }} />
			{l10n.featureLoginUsernameRequired}
		</div>
		const LabelPasswordError = <div className="labelError"> <ErrorIcon style={{ fontSize: "16px", marginRight: 4 }} />{l10n.featureLoginPasswordRequired}</div>
		const LabelPasswordNoError = <div className="labelNoError"></div>

		const ErrorMessage = loginResponse ? null : <div className="loginErrorMessageContainer">
			<img src={errorIconBlack} alt="error icon" />
			<span className="loginErrorMessageText">{l10n.featureLoginCheckLoginDetails}</span>
		</div>

		return (
			<form className="loginFormContainer" id="loginForm" /*autoComplete="off"*/>
				<div className="QLogoContainer">
					<img src={logo} className={"quickLineLogo"} alt="Quickline"></img>
				</div>

				<div className="loginTitle">
					<h3>{l10n.featureLoginTitle}</h3>
				</div>
				{ErrorMessage}
				<div className="usernameInput">
					<FormControl className={`${classes.margin} ${classes.textField}`} variant="outlined">
						<InputLabel htmlFor="outlined-adornment-username">{l10n.commonLabelUsername}</InputLabel>
						<OutlinedInput
							//ref={userField}
							id="outlined-adornment-username"
							type='text'
							autoComplete="username"
							// eslint-disable-next-line jsx-a11y/no-autofocus
							//autoFocus="true"
							inputProps={{ className: classes.inputNoAutoFill }}
							classes={{ notchedOutline: loginData.validUsername || loginData.username !== "" ? null : classes.notchedOutlineError }}
							value={loginData.username}
							onChange={handleChangeUsername()}
							startAdornment={<InputAdornment position="start"></InputAdornment>}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										className="iconButton"
										aria-label="info user name"
										onClick={handleClickShowInfoUsername}
										onMouseDown={(e) => { e.preventDefault() }}
										edge="end"
									>
										<img src={infoIcon} alt="info username" />
									</IconButton>
								</InputAdornment>
							}
							//labelWidth={100}
							label={l10n.commonLabelUsername}
						/>
					</FormControl>
					{loginData.validUsername || loginData.username !== "" ? labelUsernameExample : labelUsernameError}
				</div>

				<div className="passwordInput">
					<FormControl className={`${classes.margin} ${classes.textField}`} variant="outlined">
						<InputLabel htmlFor="outlined-adornment-password">{l10n.commonLabelPassword}</InputLabel>
						<OutlinedInput
							//ref={pwField}
							id="outlined-adornment-password"
							type={loginData.showPassword ? 'text' : 'password'}
							autoComplete="current-password"
							inputProps={{ className: classes.inputNoAutoFill }}
							value={loginData.password}
							classes={{ notchedOutline: loginData.validPassword || loginData.password !== "" ? null : classes.notchedOutlineError }}
							onChange={handleChangePassword()}
							startAdornment={<InputAdornment position="start"></InputAdornment>}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										className="iconButton"
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={(e) => { e.preventDefault() }}
										edge="end"
									>
										{loginData.showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							}
							//labelWidth={70}
							label={l10n.commonLabelPassword}
						/>
					</FormControl>
					{loginData.validPassword || loginData.password !== "" ? LabelPasswordNoError : LabelPasswordError}
				</div>

				<div className="submitButton">
					<Button
						type="submit"
						variant="contained"
						className="submitLogin"
						onClick={handleLogin}>
						{l10n.featureLoginActionAuthenticate}
					</Button>
				</div>
				<div className="rememberCheckContainer">
					<FormControlLabel
						control={
							<Checkbox
								classes={{
									root: classes.rootCheckBox,
									checked: classes.checked
								}}
								checked={keepSignedIn}
								onChange={() => { toggleKeepSignedIn() }}
								size="medium"
								style={{ padding: "0px 4px 0px 0px" }}
							/>
						}
						label={l10n.featureLoginStaySignedIn}
					/>
				</div>
				<div className="forgotedPassword">
					<Button
						size="small"
						className="forgotPasswordLink"
						onClick={() => {
							window.open(import.meta.env.VITE_URL_COCKPIT_PWD_RECOVERY, "_blank")
						}}
						data-test="forgot_password_link"
					>
						{l10n.featureLoginActionForgotPassword}
					</Button>
				</div>

				<Dialog
					// classes={{ root: "rootDialogInfoUsername" }}
					onClose={handleClose}
					aria-labelledby="simple-dialog-title"
					open={openInfoUsernameDialog}>
					{/* <DialogTitle id="simple-dialog-title">Set backup account</DialogTitle> */}
					<div className="dialogInfoUsernameContainer">
						<h3>{l10n.commonLabelUsername}</h3>
						{/* text with newline(s) */}
						<div style={{ whiteSpace: "pre-line" }}>{l10n.featureLoginUsernameDescription}</div>
						<Button
							variant="contained"
							className="submitLogin"
							onClick={() => { setOpenInfoUsernameDialog(false) }}>
							{l10n.commonActionClose}
						</Button>
					</div>
				</Dialog>
			</form>
		)
	}


	const handleClose = () => {
		setOpen(false)
		setOpenProfiles(true)
	}

	const needPinForFirstInstall = () => {
		return !rootStore.sso.profile?.FirstInstallationDone || import.meta.env.VITE_FORCE_FIRSTINSTALL === "true" || window.location.href.toLowerCase().indexOf("?firstinstall") > -1
	}

	const isCorrectPin = (pin) => {
		const result = rootStore.sso.profile.tryPinCode(pin).then(() => {
			// remember pin
			rootStore.sso.setPin(pin)
			if (needPinForFirstInstall()) {
				rootStore.sso.profile.FirstInstallationDone = false
			}
			rootStore.sso.deferAuthentication(false)
			setOpen(false)
		})
		return result
	}

	const onPinEntered = (pin) => {
		let result = null
		if (forcePinEntry) {
			result = isCorrectPin(pin)
		}
		else {
			result = rootStore.sso.profile.handleAuthProfileAsync(pin).then(() => {
				result = isCorrectPin(pin)
			})
		}
		// return promise for handling errors
		return result
	}

	useEffect(() => {
		if (rootStore.sso.profile) {
			// prevent intermediate display of profiles
			setOpenProfiles(false)
			selectProfile(rootStore.sso.profile?.id)
		}
		else {
			// enable display of profiles
			setOpenProfiles(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const selectProfile = (id) => {
		rootStore.sso.selectProfile(id)
		// Execution after login handling continues in the App component
		// once the condition rootStore.sso.isAuthenticatedProfile is true.
		// In case of firstInstall we need to delay returning isAuthenticatedProfile
		// so that the pin can be entered (we need to request the pin also for profiles that not require a pin at login)
		rootStore.sso.deferAuthentication(true)
		if (!rootStore.sso.profile?.isProfileAuthPinRequired) {
			// login with pin that might be empty
			rootStore.sso.profile.handleAuthProfileAsync(rootStore.sso.pin).then(() => {
				if (needPinForFirstInstall()) {
					//rootStore.sso.profile.FirstInstallationDone = false
					// request pin entry for first installation
					setForcePinEntry(true)
					setOpen(true)
				}
				else {
					// no pin entry required (empty pin), defer not needed
					rootStore.sso.deferAuthentication(false)
				}
			}).catch((e) => {
				console.error("CAUGHT %o", e)
				// might be a profile where login fails (see QLMS-515)
				rootStore.sso.handleLogOffAsync()
			})
		} else {
			setOpen(true)
		}
		setOpenProfiles(false)
	}

	const Profile = (props) => {
		const { name, id } = props
		return (
			<div>
				<div className={classes.profileContainer} onClick={() => {
					selectProfile(id)
				}}>
					<div className={classes.profileIconInProfile}>
						<img className={classes.profileIcon} src={PersonIcon} alt="Profil"></img>
					</div>
					<div className={classes.profileName}>
						{name}
					</div>
				</div>
			</div>
		)
	}

	const Profiles = () => {
		const [windowDimensions, setWindowDimensions] = useState(window.innerHeight)

		const handleResize = () => {
			const body = document.getElementById("profilesBody")
			// added below condition because it was crashing on Firefox / iPad
			if (body) {
				const bodyDimension = body.offsetHeight + 200
				const newHight = window.innerHeight < bodyDimension ? bodyDimension : window.innerHeight
				setWindowDimensions(newHight)
			}
		}
		useEffect(() => {
			handleResize()
			if (!isMultiProfile) selectProfile(rootStore.sso.profileOrder[0].id)
		}, [])

		useEffect(() => {
			window.addEventListener("resize", handleResize)
			return (() => {
				window.removeEventListener("resize", handleResize) // when unload
			})
		}, [windowDimensions])

		if (isMultiProfile) {
			return (
				<div className={classes.mainProfilePage} style={{ height: windowDimensions + "px" }}
				>
					<div
						//MTV-2142 only in case  profiles=4 UI would be 2x2 alse if profiles > 4 allways 3x ....
						className={classnames(classes.profilesContainer, { [classes.profilesContainerForFour]: (rootStore?.sso?.profileOrder?.length === 4) }) + " profilesContainerResponsive"}
					>
					<div className="QLogoContainerProfile">
						<img src={logo} className={"quickLineLogoProfile"} alt="Quickline"></img>
					</div>
						<div className={classes.profilesTitle}>{l10n.featureLoginProfilesTitle}</div>
						<div id="profilesBody" className={classnames(classes.profilesBody, { [classes.profilesBodyForFour]: (rootStore?.sso?.profileOrder?.length === 4) }) + " profilesBodyResponsive"}>

							{rootStore.sso.profileOrder.map(i => (
								<Profile key={i?.id} name={i?.Nickname} id={i?.id} />)
							)}
						</div>
						<div className={classes.profilesFooter}>
							<Button
								size="medium"
								className={classes.pinForgotContainer}
								onClick={() => { handleLogOut() }}
								data-test="pin_entry_logout"
							>
								{l10n.commonActionSignOut}
							</Button>
						</div>
					</div>
				</div>
			)
		} else {
			// MTVW-435: 
			// Moved below code into useEffect to avoid an error in console
			//selectProfile(rootStore.sso.profileOrder[0].id)
			return (
				<div className={classes.pinEntryDialogButtons}>
					{rootStore.sso.profileOrder[0]?.Nickname}
				</div>
			)
		}
	}

	//console.debug("rootStore.sso.isAuthenticatedProfile is %s", rootStore.sso.isAuthenticatedProfile)
	return (
		<>
			{/*console.debug("PINENTRY !changeProfile %s %s", !rootStore.sso.changeProfile, rootStore.sso.profileOrder.length)*/}
			{!rootStore?.sso?.isAuthenticatedProfile && rootStore?.sso?.profileOrder?.length === 0 && (
				<QLogin />
			)
			}
			<div className={classes.pinEntryDialogContent}>
				{/*{!rootStore.sso.profile && rootStore.sso.profileOrder.length > 0 && ( */}
				{openProfiles && !rootStore?.sso?.isAuthenticatedProfile && rootStore?.sso?.profileOrder?.length > 0 && (
					<Profiles data-test="select profile section" />
				)}
				{!rootStore.sso.changeProfile && (
					<PinEntryDialog open={open} onPinDone={onPinEntered} onClose={handleClose} profileName={rootStore.sso.profile?.Nickname} info={"Login"} flags={{ enableLogoff: true, fullScreen: true, restorePos: true }} data-test="pin enter section" />
				)}
			</div>
		</>
	)
})
