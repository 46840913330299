import { getCpeId } from "store/api/mixin/TraxisCpeIdMixin"
import { rootStore } from "store/RootStore"

export const getQlHeaders = (() => {
   const headers = {}
   const cpeid = getCpeId()

   headers['X-Quickline-Deviceid'] = cpeid ? cpeid : 'undefined'
   headers['X-Quickline-Userid'] = rootStore?.sso?.contractId ? rootStore?.sso?.contractId : rootStore.sso.browserStore.getContractId()
   let profileId = rootStore?.sso?.profile?.id ? rootStore.sso.profile.id : rootStore.sso.browserStore.currentProfileId
   // MTVW-609: avoid backend errors with missing header properties
   // profileId might be temporarily null after profile deletion, use main profile in this case
   if (!profileId && rootStore.sso.profileOrder?.length > 0) {
      const mainProfile = rootStore.sso.profileOrder.filter(i => {
         return (i?.IsMainProfile === true)
      })
      if (mainProfile.length === 1) {
         profileId = mainProfile[0].id
         //console.debug("mainProfile", mainProfile)
      }
   }
   headers['X-Quickline-Profileid'] = profileId
   return headers
})
