import React, { forwardRef, useEffect, useRef, useState, useImperativeHandle } from "react"
import "./../../../css/hoursDay.css"
import "./../../../css/tvGuide.css"
import { moment, DayInMs, adjustUtc, adjustUtcStartDay } from "store/util/moment"
import Velocity from "velocity-animate"
import "velocity-animate/velocity.ui.min.js"
//import { CreateComponent } from "components/utils/CreateComponent"
import { observer } from "mobx-react"
import { stopWatch } from "utils/TestSupport"
import { browserOs } from "utils/Utils"
import throttle from "lodash/throttle"
import { rootStore } from "store/RootStore"

const easingOption = [0, 0.75, 0, 1]

const config = {
	windowCenter: 0,
	hourBarDayWidth: 42 * 24,
	hourBarHourCellWidth: 42, // value in css class hour

	// start point for the offset day 
	startPointTimeLineBar: 0,
	startPointHoursBar: 0,

	// limits of scroll daysBefore daysAfter
	startValidScrollHoursBar: 0,
	endValidScrollHoursBar: 0,
	startValidScrollTimeBar: 0,
	endValidScrollTimeBar: 0,

	// pixel values
	timeLineHour: 360,
	timeLineFiveMinutes: 30,
	timeLineMinute: 6,
	timeLineDay: 360 * 24,
	timeLineZeroPoint: 0,
	timeLineBarUnitScale: 0,
}

/*
const startHour = 0
const startMinute = 0
const endHour = 23
const endMinute = 59
*/

let count = 0
const isAndroid = browserOs().indexOf("Android") >= 0
const adjustLeftAlways = !isAndroid
const useTouchDrag = true
const enableTimeBar = true

// smoother scrolling on Android with non passive event listeners
const cfgPassive = isAndroid ? { passive: false } : { passive: true } // config for passive event listeners
// global state to survive component refreshes / re-renders

let mouseMoving = false
// Used during animation to prevent bouncing back and forth

const TIME_UNDEFINED = -1

const S_NOT_ACTIVE = 0
const S_ACTIVE = 1
const S_DISABLED = 2

const MOUNT_STATE_INIT = 0
const MOUNT_STATE_DEFERRED = 1

let mouseUp = true
// TODO: still needed?
//let mouseOnHoursBar = false
let scrollHoursState = S_NOT_ACTIVE
let scrollEpgState = S_NOT_ACTIVE

const gPosHours = {
	startPositionDrag: 0,
	startPositionX: 0,
	endPositionX: 0,
	startPositionY: 0,
	endPositionY: 0,
	distance: 0,
	startTime: 0,
	lastPos: 0,
	eventDeltaX: 0
}

const gPosTimeline = {
	startPositionDrag: 0,
	startPositionX: 0,
	endPositionX: 0,
	startPositionY: 0,
	endPositionY: 0,
	distance: 0,
	startTime: 0,
	lastPos: 0,
	eventDeltaX: 0
}

let resizeTimer = null

const dayHours = [...Array(24).keys()]

const HoursInDayMask = React.memo(() => {
	return (
		<div className={"hoursDayContainer  hoursDayContainerMasked"}>
			{dayHours.map((item, index) => {
				return (
					<div className={"hourContainer  hourMasked"} key={index}>
						<div className="hour">{item}</div>
					</div>
				)
			})}
		</div>
	)
})

// React.memo is equivalent to PureComponent, used for better performance
const HoursInDayMain = React.memo((props) => {
	const { handleClickHourBar, isDisableDay } = props
	return (
		<div className={`hoursDayContainer ${isDisableDay ? "disableDaysHoursSelector" : ''}`} >
			{dayHours.map((item, index) => {
				return (
					<div className={"hourContainer"}
						key={index}
						// used onMouseUp and not onClick to differentiate the click management from the mouseMove management
						//onClick={() => { handleClickHourBar(item, props.day) }}
						onMouseUp={() => {
							if (mouseMoving) return
							//console.debug("calling %s, %s", item, props.day)
							handleClickHourBar(item, props.day)
						}}
					>
						<div className="hour">{item}</div>
					</div>
				)
			})}
		</div>
	)
})

const HourContainerInTimeBar = React.memo((props) => {
	const { hour } = props
	const halfHourList = []

	for (let index = 0; index < 6; index++) {
		halfHourList.push(<FiveMinutesInTimeBar key={index} />)
	}

	return (
		<div className="hourContainerInTimeBar"  >
			<div className="firstHalfHours">
				<div className="hourValue">{hour}</div>
				{halfHourList}
			</div>
			<div className="secondHalfHours">
				<div className="ovalHalfTime">{'\u2022'}</div>
				{halfHourList}
			</div>
		</div>
	)
})

const FiveMinutesInTimeBar = React.memo(() => {
	const fiveMinutesTimeBar = []

	for (let index = 0; index < 4; index++) {
		fiveMinutesTimeBar.push(<div key={index} className="minuteTimeBar"></div>)
	}

	return (
		<div className="fiveMinutesTimeBar" >
			{fiveMinutesTimeBar}
		</div>
	)
})

const TimeIndicator = forwardRef((props, ref) => {
	const [minutes, setMinutes] = useState(TIME_UNDEFINED)
	const [hours, setHours] = useState(0)

	function handleChangeTime(hour, minute) {
		setHours(hour)
		setMinutes(minute)
		props.page.setSelectedDayTs(null, hour, minute)
	}

	function getHours() {
		return hours
	}

	function getMinutes() {
		return minutes
	}

	function getDayMinutes() {
		return getHours() * 60 + getMinutes()
	}

	function setDayMinutes(dayMinutes) {
		setHours(Math.trunc(dayMinutes / 60))
		setMinutes(dayMinutes % 60)
		// would take additional time
		//props.page.setSelectedDayTs(null, this.hour, this.minute)
	}

	function getDisplayString() {
		if (minutes === TIME_UNDEFINED) return "-- : --"
		else return `${hours?.toString().padStart(2, '0')} : ${minutes?.toString().padStart(2, '0')}`
	}

	useImperativeHandle(ref, () => {
		return { handleChangeTime, getHours, getMinutes, getDayMinutes, setDayMinutes, hours, minutes }
	})

	return (
		<div id="timeIndicator" className="timeIndicator"> {getDisplayString()}</div>
	)
})

export const HoursSelector = observer((props) => {
	stopWatch.lap("HoursSelector")
	const { daysBefore, daysAfter, page, windowSize } = props
	//const selectedDate = page.iDateTs
	//console.debug("in HoursSelector %o", props)

	// the two bar with hours
	const refMainHoursBar = useRef(null)
	const refSecondHoursBar = useRef(null)

	// time line bar
	const refTimeLineBar = useRef(null)
	// show the time 
	const refTimeIndicator = useRef(null)
	const firstLoad = useRef(true)

	const daysPlusForOffset = 5
	// Big screen needs more days for the overflow offset  
	// daysAfterArray needs +1 because it includes today
	const daysBeforeArray = [...Array(daysBefore + daysPlusForOffset).keys()].map((num) => -num - 1).reverse()
	const daysAfterArray = [...Array(daysAfter + daysPlusForOffset + 1).keys()]
	const allDaysArray = [...daysBeforeArray, ...daysAfterArray]
	//console.debug("before %o, after %o, all %o", daysBeforeArray, daysAfterArray, allDaysArray)

	const updateConfig = (winSize) => {
		config.windowCenter = winSize / 2

		config.startPointHoursBar = config.hourBarDayWidth * daysPlusForOffset - config.windowCenter

		// The 0 point is the middle of the screen and it is necessary to know the number of hours in the first half of the screen for both bars
		const originalStartTime = config.windowCenter / config.timeLineHour
		const originalStartHour = config.windowCenter / config.hourBarHourCellWidth
		// To align the timeBar with the hourBar
		config.timeLineZeroPoint = (originalStartHour - originalStartTime) * config.timeLineHour
		//console.debug("zero %s, stHour %s, stTime %s, tlHour %s", config.timeLineZeroPoint, originalStartHour, originalStartTime, config.timeLineHour)
		// The scrolling of the timeBar must be in scale to that of the hourBar
		config.timeLineBarUnitScale = config.timeLineHour / config.hourBarHourCellWidth

		config.startValidScrollHoursBar = daysPlusForOffset * config.hourBarDayWidth - config.windowCenter
		config.endValidScrollHoursBar = config.startValidScrollHoursBar + (daysBefore + daysAfter + 1) * config.hourBarDayWidth

		config.startValidScrollTimeBar = (daysPlusForOffset * config.timeLineDay - config.windowCenter) + config.timeLineMinute / 2 - 1
		config.endValidScrollTimeBar = config.startValidScrollTimeBar + (daysBefore + daysAfter + 1) * config.timeLineDay - config.timeLineMinute - 1
		// start point of TimeBar is 00:01  and  end is 00:00 Needed  -1 to have 00:00 and 23:59 
	}

	function velocityAnimation(elem, left, duration, onComplete = null, progress = null) {
		Velocity(elem, { scrollLeft: left + "px" }, {
			duration: duration,
			easing: easingOption,
			complete: function (val) {
				if (onComplete) onComplete()
			},
			progress: function (elements, complete, remaining, start, tweenValue) {
				if (progress) progress(complete)
			}
		})
	}

	// This will be invoked after setVisibleTime() is called.
	// setVisisbletime() sets the target time / position, hence we have to move from the current position
	const setBarsOnCurrentDay = (daysDiff = null, deltaTs = null, animationDuration = 400) => {
		stopWatch.lap("setBarsOnCurrentDay")
		///* TEST */ if (browserOs().indexOf("Android") >= 0) animationDuration = 0
		//console.debug("hour %s, min %s", page.getDateHour, page.getDateMinute)
		// don't interrupt active animation
		console.debug("isAndroid %s, daysDiff %s, deltaTs %s, duration %s", isAndroid, daysDiff, deltaTs, animationDuration)
		if (!page?.refScroll || !refMainHoursBar?.current || !refTimeIndicator?.current || scrollHoursState === S_ACTIVE || scrollEpgState === S_ACTIVE) return
		// TODO: removeScrollHandlers doesn't seem to work
		//removeScrollHandlers(refMainHoursBar)
		scrollHoursState = S_DISABLED
		scrollEpgState = S_DISABLED
		if (!daysDiff) {
			// Determine how many days are passed from today to the selectedDate.
			// MTVW-414 
			//const toDay = moment().utc().startOf('day')
			//const selectedDate = moment(page.getSelectedDayTs).utc().startOf('day')
			const toDay = adjustUtcStartDay()
			const selectedDate = adjustUtcStartDay(page.getSelectedDayTs)
			daysDiff = selectedDate.diff(toDay, 'days')
			//console.debug("today %s, selected %s, diff", toDay.toString(), selectedDate.toString(), daysDiff)
		}
		//console.debug("setBarsOnCurrentDay h %s, m %s, diff %s", page.getDateHour, page.getDateMinute, daysDiff)
		const offsTimeLineValue = (daysPlusForOffset + daysBefore + daysDiff) * config.timeLineDay +
			page.getDateHour * config.timeLineHour + page.getDateMinute * config.timeLineMinute - config.windowCenter
		const offsHourValue = (daysPlusForOffset + daysBefore + daysDiff) * config.hourBarDayWidth + (page.getDateHour +
			page.getDateMinute / 60) * config.hourBarHourCellWidth - config.windowCenter
		//console.debug("offsHourValue %s, current %s, offsTimeLineValue %s, offsHourValue, refMainHoursBar.current.scrollLeft, offsTimeLineValue)

		//const startHour = refTimeIndicator.current.getDayMinutes()
		const startHour = page.getDateHour * 60 + page.getDateMinute
		// for optimization
		const scrollHDiff = (offsHourValue - refMainHoursBar.current.scrollLeft) / config.hourBarHourCellWidth * 60
		const startEpg = page.refScroll.scrollLeft
		const endEpg = page.getDayOffsetInPx
		if (!deltaTs) deltaTs = (page.getDayOffsetInPx - startEpg) / page.pixelsPerMinute * 60 * 1000
		// for optimization
		const deltaPx = deltaTs / 1000 / 60 * page.pixelsPerMinute
		const dayInPx = 24 * 60 * page.pixelsPerMinute
		const endHour = page.getDateHour
		const endMinute = page.getDateMinute
		const currTs = moment(page.getSelectedDayTs).add(page.getDateHour, 'h').add(page.getDateMinute, 'm').valueOf()
		const startTs = currTs - deltaTs
		//console.debug("currTs %s, startTs %s", moment(currTs).format(), moment(startTs).format())

		//console.debug("epg now %s, target %s, diffPx %s", page.refScroll.scrollLeft, page.getDayOffsetInPx, diffPx)
		stopWatch.lap("start anim")
		if (!adjustLeftAlways) page.resetLeftEpgPaddings()
		stopWatch.lap("start anim adjusted")
		velocityAnimation(refMainHoursBar.current, offsHourValue, animationDuration,
			() => { // complete
				// set end time in order to have precise time display
				//refTimeIndicator.current.handleChangeTime(page.getDateHour, page.getDateMinute)

				// set end position of EPG and relieve S_DISABLED state
				stopWatch.lap("setBars anim complete")
				// in case the tab has been switched, refs might not be valid anymore
				if (page?.refScroll && refMainHoursBar?.current && refTimeIndicator?.current) {
					page.refScroll.scrollLeft = endEpg
					//page.setSelectedDayTs(currTs, moment(currTs).utc().hour(), moment(currTs).minute())
					page.setSelectedDayTs(currTs, endHour, endMinute)
					refTimeIndicator.current.handleChangeTime(endHour, endMinute)
					page.adjustLeftEpgPaddings()
					// should be ok here (or move into setTimeout?)
					page.setVisibleTimeDone()
					// attempt to update live indicator
					page.requestUpdate()
				}
				stopWatch.lap("setBars done")
				setTimeout(() => {
					// make sure hours bar position are updated in case handleScrollHoursBar is triggered
					// in case the tab has been switched, refMainHoursBar.current might not be valid anymore
					if (refMainHoursBar?.current?.scrollLeft) initGPositions(gPosHours, refMainHoursBar.current.scrollLeft, refMainHoursBar.current.scrollLeft)
					scrollHoursState = S_NOT_ACTIVE
					scrollEpgState = S_NOT_ACTIVE
					stopWatch.lap("setBars timeout done")
					// TODO: removeScrollHandlers doesn't seem to work
					//installScrollHandlers()
				}, 500)  // this timeout is critical, test very carefully after any change (otherwise the end time might be wrong)
			},
			(progress) => { // progress update
				// in case the tab has been switched, refs might not be valid anymore
				if (animationDuration > 0 && page?.refScroll && refMainHoursBar?.current && refTimeIndicator?.current) {
					// MTVW-320: update time display
					stopWatch.lap("start progress")
					let cTime = Math.round((startHour + progress * scrollHDiff) % (24 * 60))
					if (cTime < 0) cTime += 24 * 60
					if (startHour !== TIME_UNDEFINED) refTimeIndicator.current.setDayMinutes(cTime)

					// MTVW-320: animate EPG
					let cScroll = Math.round((startEpg + progress * deltaPx) % dayInPx)
					if (cScroll < 0) cScroll += dayInPx
					//console.debug("cScroll %s", cScroll)
					page.refScroll.scrollLeft = cScroll

					// update ts for displaying all visible programs and current now marker position
					/* TEST */ let ts = startTs + progress * deltaTs
					// distances >= 1 day causes problems when the EPG is loaded
					// MTVW-414
						/* TEST  if (startHour !== TIME_UNDEFINED && Math.abs(deltaTs) < DayInMs) page.setSelectedDayTs(ts, moment(ts).utc().hour(), moment(ts).minute())*/
					/* TEST */ if (startHour !== TIME_UNDEFINED && Math.abs(deltaTs) < DayInMs) page.setSelectedDayTs(ts, adjustUtc(ts).hour(), moment(ts).minute())
					//if (startHour !== TIME_UNDEFINED) page.setSelectedDayTs(null, Math.trunc(cTime / 60), cTime % 60)
					// shouldn't be necessary during animation (paddings have been reset above before the animation)
					if (adjustLeftAlways) page.adjustLeftEpgPaddings()
					stopWatch.lap("end progress")
				}
			})
		velocityAnimation(refTimeLineBar.current, offsTimeLineValue, animationDuration, () => {
		})
		velocityAnimation(refSecondHoursBar.current, offsHourValue, animationDuration, () => {
		})
	}

	const handleClickHourBar = (hour, day) => {
		// MTVW-317: fix daysDiff when clicking on hour bar
		// MTVW-414
		//const selectedDate = moment().utc().startOf('day').add(day, 'd')
		//const currentDate = moment().utc().startOf('day')
		const selectedDate = adjustUtcStartDay().add(day, 'd')
		//console.debug("handleClickHourBar hour %s, day %s, sel %s", hour, day, selectedDate.toString())
		const currentDate = adjustUtcStartDay()

		// disable immediate positioning, animation will be used
		scrollHoursState = S_DISABLED
		scrollEpgState = S_DISABLED
		// MTVW-414
		//page.setSelectedDayTs(moment(selectedDate).utc().valueOf(), hour, 0)
		page.setSelectedDayTs(adjustUtc(selectedDate).valueOf(), hour, 0)
		//refTimeIndicator.current.handleChangeTime(hour, 0)

		const daysDiff = selectedDate.diff(currentDate, 'days')
		//console.debug("daysDiff %s", daysDiff, selectedDate.toString(), currentDate.toString())
		setBarsOnCurrentDay(daysDiff)
	}

	/* time line bar is too tiny for touch scroll
	function handleScrollTimelinebar(ref, event) {
	}
	*/

	function initGPositions(gPos, dragPos, startPosX, startPosY = 0) {
		gPos.distance = 0
		gPos.lastPos = dragPos
		gPos.startPositionDrag = dragPos
		gPos.endPositionX = dragPos
		gPos.startPositionX = startPosX
		gPos.endPositionY = startPosY
		gPos.startPositionY = startPosY
		//gPos.startTime = refTimeIndicator.current.getDayMinutes()
		gPos.startTime = page.getDateHour * 60 + page.getDateMinute
		//console.debug("gPos.startTime", gPos.startTime)
	}

	let handleScrollEpgTimer = null

	// called from "scroll" event handler installed in the EPG
	function handleScrollEpg(event) {
		event.stopPropagation()
		if (!cfgPassive.passive) event.preventDefault()
		const gPos = gPosTimeline
		// latch epg position
		const epgPos = event.srcElement.scrollLeft

		//console.debug("handleScrollEpg, mMoving %s, scrollHoursState %s", mouseMoving, scrollHoursState)

		//console.debug("scrollH %s, scrollE %s, mMoving %s", scrollHoursState, scrollEpgState, mouseMoving)
		if (mouseMoving || scrollHoursState === S_ACTIVE || scrollEpgState === S_DISABLED) return
		if (scrollEpgState !== S_ACTIVE) {
			// happens after timeout if the user stops while touch still down
			initGPositions(gPos, epgPos, epgPos)
			//console.debug("initG %s", gPos.startTime)
			scrollEpgState = S_ACTIVE
			if (!adjustLeftAlways) page.resetLeftEpgPaddings()
		}
		//console.debug("handleScrollEpg")
		//return
		//handleMouseMoveTimeBar(true)
		const delta = gPos.lastPos - epgPos
		//console.debug("DIST %s, %s, %s, %s", gPos.distance, delta, gPos.lastPos, epgPos)
		gPos.distance = (gPos.distance - delta) % (24 * 60 * 6)
		gPos.lastPos = epgPos
		gPos.endPositionX = refTimeLineBar.current.scrollLeft - delta
		/*
		count++
		console.debug("count %s, dist %s, end %s", count, gPos.distance, gPos.endPositionX)
		*/
		// MTVW-318: Clip only when not just initialized to enable switching to another hour at first / last day boundarries
		if (delta !== 0) {
			//console.debug("scrollLeft %s, delta %s, gPos.lastPos %s, endX %s", epgPos, delta, gPos.lastPos, gPos.endPositionX)
			gPos.endPositionX = clipMoveTimlinBar(gPos, gPos.endPositionX)
			// TODO: simplify?
			// MTVW-318
			if (gPos.endPositionX <= config.startValidScrollTimeBar) {
				//console.debug("left limit %s", page.getOffesetForTimeInPx(0, 0))
				event.srcElement.scrollLeft = page.getOffesetForTimeInPx(0, 0)
				gPos.lastPos = page.getOffesetForTimeInPx(0, 0)
			}
			else if (gPos.endPositionX >= config.endValidScrollTimeBar) {
				//console.debug("right limit %s", page.getOffesetForTimeInPx(23, 59))
				event.srcElement.scrollLeft = page.getOffesetForTimeInPx(23, 59)
				gPos.lastPos = page.getOffesetForTimeInPx(23, 59)
			}
		}
		refTimeLineBar.current.scrollLeft = gPos.endPositionX
		const leftPx = (gPos.endPositionX - config.timeLineZeroPoint) / config.timeLineBarUnitScale
		//console.debug("leftPx %s, mainLeft %s", leftPx, refMainHoursBar.current.scrollLeft)
		refMainHoursBar.current.scrollLeft = leftPx
		refSecondHoursBar.current.scrollLeft = leftPx
		// TRIAL: do before changeTimeIndicator()
		//page.adjustLeftEpgPaddings()
		// Display the time while scrolling
		changeTimeIndicator(gPos, 1 / config.timeLineMinute, true)
		//page.requestUpdate()
		// faster with method below, above statement does not fix the flicker issue on Safari
		if (adjustLeftAlways) page.adjustLeftEpgPaddings()
		if (handleScrollEpgTimer) clearTimeout(handleScrollEpgTimer)
		handleScrollEpgTimer = setTimeout(() => {
			//console.debug("scroll EPG done")
			scrollEpgState = S_NOT_ACTIVE
			///* TEST  if (!adjustLeftAlways) */ page.adjustLeftEpgPaddings()
			// make sure that we are in sync when crossing a day boundary
			// scrollEpg will also call page.adjustLeftEpgPaddings()
			//scrollEpg(true)
			//setBarsOnCurrentDay(null, null, 0)
			const epg = page.refScroll
			const epgPos = page.getDayOffsetInPx
			epg.scrollLeft = epgPos
			gPos.lastPos = epgPos
			// timeout of 100 on Android was critical
		}, 200)
	}

	let scrollEpgTimer = null

	// called from setSelectedDayTs()
	function scrollEpg(force = false) {
		const epg = page.refScroll
		const gPos = gPosTimeline
		const sEpgState = scrollEpgState
		if (scrollHoursState === S_DISABLED) return
		if (epg && (scrollEpgState !== S_ACTIVE || force || scrollHoursState === S_ACTIVE)) {
			//console.debug("scrollEpg")
			// MTVW-319: temporarily ignore epg scroll events
			if (force && sEpgState === S_ACTIVE) scrollEpgState = S_DISABLED
			epg.scrollLeft = page.getDayOffsetInPx
			gPos.lastPos = page.getDayOffsetInPx
			//page.requestUpdate()
			// faster with method below
			/* TEST */ if (adjustLeftAlways) page.adjustLeftEpgPaddings()
			if (scrollEpgTimer) clearTimeout(scrollEpgTimer)
			scrollEpgTimer = setTimeout(() => {
				// MTVW-319: re-enable epg scroll events
				if (force && sEpgState === S_ACTIVE) scrollEpgState = S_ACTIVE
				/* TEST if (!adjustLeftAlways) */ page.adjustLeftEpgPaddings()
			}, 50)
		}
	}

	let epgWheelTimer = null

	function handleWheelEpg(event) {
		const STEP = 60 // 10 minutes
		event.stopPropagation()
		if (!refTimeLineBar?.current) return
		//if (!cfgPassive.passive) event.preventDefault()
		//console.debug("wheel %s, %s, %s, %o", event.deltaX, event.deltaY, Math.sign(event.deltaY), event)
		const gPos = gPosTimeline
		// Browsers don't behave the same way in the event data!
		const stepDeltaX = Math.sign(event.deltaX) * STEP
		const stepDeltaY = Math.sign(event.deltaY) * STEP
		if (!useTouchDrag) {
			gPos.eventDeltaX = stepDeltaY
			if (event.shiftKey && scrollEpgState !== S_ACTIVE) {
				//console.debug("wheel on Epg %o", event)
				// mouse wheel horizontal scroll
				handleTouchStartEpg(event)
			}
		} else {
			const isDirX = Math.abs(stepDeltaX) > Math.abs(stepDeltaY) ? true : false
			let newPosition = refTimeLineBar.current.scrollLeft
			let delta = 0
			if (event.shiftKey) {
				// SHIFT + mouse wheel
				// deltaX on macOs, deltaY on Windows
				delta = 3 * (isDirX ? stepDeltaX : stepDeltaY)
				//console.debug("SHIFT %s, %s, %s, %o", delta, event.deltaX, event.deltaY, event)
				//mouseMoving = true
			}
			else {
				// trackpad
				delta = stepDeltaX
				//console.debug("DELTA %s, %s, %s", stepDeltaX, event.deltaX, event.deltaY)
				/* NOTE: event.preventDefault() will eliminate the issue with Chrome --disable-features=TouchpadOverscrollHistoryNavigation
				 * https://winaero.com/disable-chrome-backward-and-forward-navigation-with-touchpad-scroll/
				 * For vertical scroll we must not call event.preventDefault(), it would be blocked
				*/
				if (isDirX) event.preventDefault()
				mouseMoving = true
				mouseUp = true
			}
			initGPositions(gPos, newPosition, newPosition)
			// apply delta position
			//console.debug("delta=", delta, stepDeltaX, event.deltaX)
			gPos.eventDeltaX = delta
			newPosition += delta
			gPos.distance += delta
			gPos.endPositionX = newPosition
			handleMouseMoveTimeBar(gPos)

			if (epgWheelTimer) clearTimeout(epgWheelTimer)
			epgWheelTimer = setTimeout(() => {
				setBarsOnCurrentDay(null, null, 0)
				mouseMoving = false
				mouseUp = true
			}, 300)
		}
	}

	function handleWheelHoursBar(refBar, gPos, event) {
		const STEP = 21 // 30 minutes
		event.stopPropagation()
		//console.debug("wheel on hours bar %o, %s", event, event.deltaX)
		gPos.eventDeltaX = Math.sign(event.deltaY) * STEP
		if (event.shiftKey && scrollHoursState !== S_ACTIVE) {
			handleTouchStartHours(refBar, gPos, event)
		}
	}


	const [mountState, setMountState] = useState(MOUNT_STATE_INIT)

	let listenerHoursBar = null
	let listenerTimelineBar = null
	function removeScrollHandlers(hoursBar) {
		//console.debug("IHANDLER R %o", hoursBar.current)
		//if (hoursBar?.current) hoursBar.current.removeEventListener("scroll", handleScrollHoursBar.bind(this, hoursBar.current, gPosHours), cfgPassive)
		if (hoursBar?.current) hoursBar.current.removeEventListener("scroll", listenerHoursBar, cfgPassive)
		const epg = page?.refScroll
		//savedTimeRef.removeEventListener("scroll", handleScrollTimelinebar)
		if (epg) {
			if (!useTouchDrag) {
				epg.removeEventListener("scroll", handleScrollEpg, cfgPassive)
			}
			else {
				epg.removeEventListener("touchmove", listenerTimelineBar, { passive: false })
			}
			epg.removeEventListener("touchstart", handleTouchStartEpg, cfgPassive)
			epg.removeEventListener("touchend", handleTouchEndEpg, cfgPassive)
			epg.removeEventListener("wheel", throttleWheel, useTouchDrag ? { passive: false } : { passive: true })
		}
		// MTVW-745
		document.removeEventListener("keydown", handleKeyDown, false)
	}

	// prevent executing too many times, necessary on macOs
	const throttleWheel = throttle(handleWheelEpg, 100)

	function installScrollHandlers() {
		//console.debug("IHANDLER I")
		const epg = page?.refScroll
		if (epg) {
			// NOTE: using non passive event listeners (passive: false) does reduce the lag on Android / Chrome while scrolling
			const style = document.getElementsByClassName("innerTvGuideChannels")?.[0]?.style
			if (!useTouchDrag) {
				if (style) style.overflowX = "auto"
				epg.addEventListener("scroll", handleScrollEpg, cfgPassive)
			}
			else {
				if (style) style.overflowX = "hidden"
				// does not improve performance on Android
				//listenerTimelineBar = throttle(handleMouseMove.bind(this, refTimeLineBar, gPosTimeline), 20)
				listenerTimelineBar = handleMouseMove.bind(this, refTimeLineBar, gPosTimeline)
				epg.addEventListener("touchmove", listenerTimelineBar, { passive: false })
			}
			epg.addEventListener("touchstart", handleTouchStartEpg, cfgPassive)
			epg.addEventListener("touchend", handleTouchEndEpg, cfgPassive)
			epg.addEventListener("wheel", throttleWheel, useTouchDrag ? { passive: false } : { passive: true })
		}
		listenerHoursBar = handleScrollHoursBar.bind(this, refMainHoursBar.current, gPosHours)
		refMainHoursBar.current.addEventListener("scroll", listenerHoursBar, cfgPassive)
		// MTVW-745
		document.addEventListener("keydown", handleKeyDown, false)
	}

	useEffect(() => {
		// make sure epgWidth in page is defined, otherwise there might be a shift of a few minutes
		// (seen in now marker) mainly when sitching tabs
		page.calcEpgWidth()
		installScrollHandlers()
		// register scroll function
		page.setScrollEpg(scrollEpg)

		const savedHoursRef = refMainHoursBar.current
		/* time line bar is too tiny for touch scroll
		const savedTimeRef = refTimeLineBar.current
		refTimeLineBar.current.addEventListener("scroll", (event) => {
				handleScrollTimelinebar(savedTimeRef, event)
			})
			*/
		/*
		// disable immediate positioning, animation will be used
		scrollHoursState = S_DISABLED
		scrollEpgState = S_DISABLED
		*/
		updateConfig(refMainHoursBar.current.clientWidth)
		const now = moment()
		page.setSelectedDayTs(null, now.hour(), now.minute())
		refTimeIndicator.current.handleChangeTime(now.hour(), now.minute())

		// defer slow rendering of HourContainerInTimeBar
		setTimeout(() => {
			setMountState(MOUNT_STATE_DEFERRED)
		}, 20)
		// don't call setBarsOnCurrentDay() because rendering HourContainerInTimeBar is deferred for quicker display update on tab change
		//setBarsOnCurrentDay()

		return (() => {
			removeScrollHandlers(savedHoursRef)
		})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (!firstLoad.current) {
			// disable immediate positioning, animation will be used
			scrollHoursState = S_DISABLED
			scrollEpgState = S_DISABLED
			// set bars with computed deltaTS
			/*
			setBarsOnCurrentDay(null, page.getSelectedDayTs - page.refreshVisibleTIme.time.previousDayTs)
			*/
			const currenTs = page.getSelectedDayTs
			// trigger useEffect in DateSelector
			page.setSelectedDayTs(page.refreshVisibleTIme.time.dayTs, page.refreshVisibleTIme.time.dateHour, page.refreshVisibleTIme.time.dateMinute)
			// scroll hour bars
			setBarsOnCurrentDay(null, page.getSelectedDayTs - currenTs)
			stopWatch.lap("after setBarsOnCurrentDay")
		}
		firstLoad.current = false

		///scrollEpg()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page.refreshVisibleTIme])

	useEffect(() => {
		//console.debug("GOT %s", windowSize)
		// MTVW-321: wait until manual sizing has settled
		if (resizeTimer) clearTimeout(resizeTimer)
		resizeTimer = setTimeout(() => {
			//page.setVisibleTime(null, hour, min)
			// disable immediate positioning, animation will be used
			scrollHoursState = S_DISABLED
			scrollEpgState = S_DISABLED
			updateConfig(windowSize)
			setBarsOnCurrentDay(null, null, 0)
		}, 200)

		//scrollEpg()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [windowSize])

	function handleTouchStartEpg(event) {
		/* TEST */ count = 0
		/* TEST */ //console.debug("COUNT %s", count)
		event.stopPropagation()
		//if (!cfgPassive.passive) event.preventDefault()
		scrollEpgState = useTouchDrag ? S_NOT_ACTIVE : S_ACTIVE
		scrollHoursState = S_NOT_ACTIVE
		if (!adjustLeftAlways) page.resetLeftEpgPaddings()
		const gPos = gPosTimeline
		const epg = page.refScroll
		//gPos.startPositionDrag = refTimeLineBar.current.scrollLeft
		//console.debug("handleTouchStartEpg %o", event)
		const startPosX = event.type === "touchstart" ? event.targetTouches[0].clientX : /* wheel start */ event.clientX
		const startPosY = event.type === "touchstart" ? event.targetTouches[0].clientY : /* wheel start */ event.clientY
		if (!useTouchDrag) {
			initGPositions(gPos, epg.scrollLeft, startPosX, startPosY)
		}
		else {
			initGPositions(gPos, refTimeLineBar.current.scrollLeft, startPosX, startPosY)
			mouseUp = event.type === "wheel" ? true : false
		}
		//console.debug("handleTouchStartEpg")
		//console.debug("touch startPositionDrag %s, %s", gPos.startPositionDrag, gPos.startPositionX)
	}

	function handleMouseDown(refBar, gPos, event) {
		//console.debug("handleMouseDown ref %o, gPos %o, event %o", refBar, gPos, event)
		event.stopPropagation()
		if (!refBar) return
		if (event.type !== "touchstart") {
			if (event.type !== "wheel") event.preventDefault()
			mouseUp = false
		}

		const startPosX = event.type === "touchstart" ? event.targetTouches[0].clientX : event.clientX
		const startPosY = event.type === "touchstart" ? event.targetTouches[0].clientY : event.clientY
		initGPositions(gPos, refBar.current.scrollLeft, startPosX, startPosY)
		//console.debug("mouse startPositionDrag %s, %s", gPos.startPositionDrag, gPos.startPositionX)
	}

	function handleTouchStartHours(refBar, gPos, event) {
		//console.debug("handleTouchStartHours")
		event.stopPropagation()
		if (!adjustLeftAlways) page.resetLeftEpgPaddings()
		scrollHoursState = S_ACTIVE
		scrollEpgState = S_NOT_ACTIVE

		const startPos = event.type === "touchstart" ? gPos.startPositionX = event.targetTouches[0].clientX : event.clientX
		initGPositions(gPos, refBar.current.scrollLeft, startPos)
	}

	function handleMouseUp(refBar, gPos, event) {
		event.stopPropagation()
		//console.debug("handleMouseUp")
		if (event.type !== "touchend") event.preventDefault()
		//else /* TEST */ if (!adjustLeftAlways) page.adjustLeftEpgPaddings()
		page.adjustLeftEpgPaddings()

		mouseUp = true
		if (mouseMoving) {
			//page.setSelectedDayTs(null, parseInt(refTimeIndicator.current.hours), parseInt(refTimeIndicator.current.minutes))
		}
		/* dont do here, done in handleMouseDown
		gPos.endPositionX = event.type === "touchend" ? event.changedTouches[0].clientX : event.clientX
		gPos.distance = 0
		gPos.startTime = refTimeIndicator.current.getDayMinutes()
		*/
		mouseMoving = false
		// TODO: required?
		if (refBar === refMainHoursBar) {
			//console.debug("setting not active")
			//scrollHoursState = S_NOT_ACTIVE
			//mouseOnHoursBar = false
		}
		//console.debug("handleMouseUp")
	}

	function handleTouchEndEpg(event) {
		event.stopPropagation()
		/* TEST  if (!adjustLeftAlways) */ page.adjustLeftEpgPaddings()
		//if (!cfgPassive.passive) event.preventDefault()
		//scrollEpgState = S_NOT_ACTIVE
		// TODO: issue on Android
		if (useTouchDrag /*&& !isAndroid*/) {
			mouseUp = true
			mouseMoving = false
		}
		/* TEST */ //console.debug("COUNT %s", count)
	}

	function handleTouchEndHours(refBar, gPos, event) {
		//console.debug("handleTouchEndHours")
		event.stopPropagation()
		///* TEST  if (!adjustLeftAlways) */ page.adjustLeftEpgPaddings()
		//scrollHoursState = S_NOT_ACTIVE
	}

	function handleMouseLeave(refBar, gPos, event) {
		event.stopPropagation()
		event.preventDefault()
		if (refBar === refMainHoursBar) {
			//console.debug("handleMouseLeave")
			scrollHoursState = S_NOT_ACTIVE
			//mouseOnHoursBar = false
		}
		if (mouseUp) return
		else /*if (!mouseUp)*/ {
			handleMouseUp(refBar, gPos, event)
		}
	}

	// called on "onMouseMove" event on hours bar and time line bar
	function handleMouseMove(refBar, gPos, event) {
		/* TEST */ count++
		event.stopPropagation()

		// special case for touchbar scroll on hours bar without a mouse down event
		/*
		if (refBar === refMainHoursBar && mouseUp && scrollHoursState === S_NOT_ACTIVE) {
			mouseOnHoursBar = true
		}
		*/

		if (mouseUp) return
		if (!refBar) return
		//console.debug("EVENT %o", event)

		const xPosition = event.type === "touchmove" ? event.changedTouches[0].clientX : event.clientX
		const yPosition = event.type === "touchmove" ? event.changedTouches[0].clientY : event.clientY
		const newPositionX = gPos.startPositionDrag + gPos.startPositionX - xPosition
		const deltaX = newPositionX - gPos.endPositionX
		const deltaY = yPosition - gPos.endPositionY
		gPos.endPositionY = yPosition

		// in case of touchmove do not prevent vertical scroll!
		//if (useTouchDrag && !(event.type === "touchmove" && event.cancelable && Math.abs(deltaY) > Math.abs(deltaX))) event.preventDefault()
		if (useTouchDrag && event.type === "touchmove") {
			if (event.cancelable && Math.abs(deltaX) > Math.abs(deltaY)) event.preventDefault()
			else return // vertical scroll
		}
		// apply delta position
		gPos.distance += deltaX
		mouseMoving = true
		gPos.endPositionX = newPositionX
		//console.debug("DIST %s, xPos %s, newPos %s", gPos.distance, xPosition, newPosition)

		if (refBar === refTimeLineBar) {
			handleMouseMoveTimeBar(gPos)
		} else if (refBar === refMainHoursBar) {
			handleMouseMoveHourBar(gPos)
		}
	}

	// MTVW-745: Horizontal scroll, vertical scroll is handled in TvGuide
	function handleKeyDown(event) {
		//console.debug("handleKeyDown", event.keyCode)

		// generate an artificial wheel event
		if (event.keyCode === 37) {
			//console.debug("left")
			event.preventDefault()
			event.deltaX = -1 // only sign matters, handleWheelEpg will use 10 minutes
			event.deltaY = 0
			handleWheelEpg(event)
		}
		else if (event.keyCode === 39) {
			//console.debug("right")
			event.preventDefault()
			event.deltaX = 1 // only sign matters, handleWheelEpg will use 10 minutes
			event.deltaY = 0
			handleWheelEpg(event)
		}
	}

	function clipMoveHoursBar(gPos, pos) {
		if (pos < config.startValidScrollHoursBar) {
			pos = config.startValidScrollHoursBar
			// MTVW-318
			//console.debug("clipped <")
			gPos.startTime = 0
			gPos.distance = 0
			gPos.endPositionX = pos
		} else if (pos > config.endValidScrollHoursBar) {
			pos = config.endValidScrollHoursBar
			// MTVW-318
			//console.debug("clipped >")
			gPos.startTime = 23 * 60 + 59
			gPos.distance = 0
			gPos.endPositionX = pos
		}
		return pos
	}

	// adjust / sync the 2nd hours bar
	function moveSecondAndTimeLineBar(gPos, pos) {
		if (!refSecondHoursBar?.current || !refTimeLineBar?.current) return
		//console.debug("moveSecondAndTimeLineBar")
		refSecondHoursBar.current.scrollLeft = pos
		const timeLeft = config.timeLineZeroPoint + pos * config.timeLineBarUnitScale
		refTimeLineBar.current.scrollLeft = timeLeft
		changeTimeIndicator(gPos)
	}

	// called when dragging the hours bar with the mouse
	function handleMouseMoveHourBar(gPos) {
		if (!refMainHoursBar?.current) return
		//console.debug("handleMouseMoveHourBar")
		gPos.endPositionX = clipMoveHoursBar(gPos, gPos.endPositionX)
		//velocityAnimation(refMainHoursBar.current, gPos.endPositionX, 0) //5
		refMainHoursBar.current.scrollLeft = gPos.endPositionX
		moveSecondAndTimeLineBar(gPos, gPos.endPositionX)
	}

	let handleScrollHoursBarTimer = null

	// called from "scroll" event handler installed on the hours bar
	function handleScrollHoursBar(ref, gPos, event) {
		event.stopPropagation()
		if (!cfgPassive.passive) event.preventDefault()
		//console.debug("handleScrollHoursBar moving %s, scrollHoursState %s, ev %o, gPos %o", mouseMoving, scrollHoursState, event, gPos)
		//if (scrollHoursState !== S_ACTIVE && !mouseOnHoursBar /*&& scrollEpgState === S_ACTIVE*/) return
		//if (scrollHoursState === S_DISABLED || (scrollHoursState !== S_ACTIVE && !mouseOnHoursBar)) return
		if (!ref || mouseMoving || scrollEpgState === S_ACTIVE || scrollHoursState === S_DISABLED) return
		//console.debug("handleScrollHoursBar %s", scrollHoursState)

		// MTVW-318: assign clipped position also to ref.scrollLeft to stop scrolling
		//const cPos = ref.scrollLeft = clipMoveHoursBar(gPos, ref.scrollLeft)
		const cPos = ref.scrollLeft = clipMoveHoursBar(gPos, event.srcElement.scrollLeft)
		if (scrollHoursState !== S_ACTIVE && mouseUp) {
			// scroll case with trackpad
			scrollHoursState = S_ACTIVE
			//scrollEpgState = S_NOT_ACTIVE
			//console.debug("initG %o, %s, %s", event, cPos, refMainHoursBar.current.scrollLeft, gPos.eventDeltaX)
			initGPositions(gPos, cPos, cPos)
			gPos.distance = gPos.eventDeltaX
		}

		//console.debug("cPos %s, delta %s", cPos, gPos.endPositionX - cPos)
		// apply delta position
		gPos.distance += cPos - gPos.endPositionX
		gPos.endPositionX = cPos
		//console.debug("DISTANCE %s", gPos.distance)
		/*
		count++
		console.debug("count %s, dist %s, end %s", count, gPos.distance, gPos.endPositionX)
		*/

		moveSecondAndTimeLineBar(gPos, cPos)

		if (handleScrollHoursBarTimer) clearTimeout(handleScrollHoursBarTimer)
		handleScrollHoursBarTimer = setTimeout(() => {
			scrollHoursState = S_NOT_ACTIVE
			/* TEST if (!adjustLeftAlways) */ page.adjustLeftEpgPaddings()
			// timeout of 100 on Android was critical
			//scrollEpg(true)
			setBarsOnCurrentDay(null, null, 0)
		}, 300)
	}

	function clipMoveTimlinBar(gPos, pos) {
		if (pos < config.startValidScrollTimeBar) {
			pos = config.startValidScrollTimeBar
			// MTVW-318
			gPos.startTime = 0
			gPos.distance = 0
			gPos.endPositionX = pos
			refTimeIndicator.current.handleChangeTime(0, 0)
		} else if (pos > config.endValidScrollTimeBar) {
			pos = config.endValidScrollTimeBar
			gPos.startTime = 23 * 60 + 59
			// MTVW-318
			gPos.distance = 0
			gPos.endPositionX = pos
			refTimeIndicator.current.handleChangeTime(23, 59)
		}
		return pos
	}

	// called when dragging the time bar with the mouse
	function handleMouseMoveTimeBar(gPos, disableEpgScroll = false) {
		//console.debug("handleMouseMoveTimeBar")
		if (!refTimeLineBar?.current || !refMainHoursBar?.current || !refSecondHoursBar?.current) return
		gPos.endPositionX = clipMoveTimlinBar(gPos, gPos.endPositionX)

		refTimeLineBar.current.scrollLeft = gPos.endPositionX
		const leftPx = (gPos.endPositionX - config.timeLineZeroPoint) / config.timeLineBarUnitScale
		refMainHoursBar.current.scrollLeft = leftPx
		refSecondHoursBar.current.scrollLeft = leftPx
		// Display the time while scrolling
		changeTimeIndicator(gPos, 1 / config.timeLineMinute, disableEpgScroll)
	}

	const daysFromToday = (selectedDate) => {
		// MTVW-414
		//const currentDate = moment().utc().startOf('day')
		//return moment(selectedDate).utc().startOf('day').diff(currentDate, 'days')
		const currentDate = adjustUtcStartDay()
		return adjustUtcStartDay(selectedDate).diff(currentDate, 'days')
	}

	function changeTimeIndicator(gPos, minutePerPixel = 2 * config.hourBarHourCellWidth / 60, disableEpgScroll = false) {
		let targetTime = Math.round(gPos.startTime + gPos.distance * minutePerPixel)
		let targetHour = Math.trunc(targetTime / 60)
		let targetMinute = targetTime % 60
		let dayChanged = false
		//console.debug("startTime %s, dist %s, tgHour %s, tgMin %s", gPos.startTime, gPos.distance, targetHour, targetMinute)
		if (targetTime < 0) {
			if (daysFromToday(page.getSelectedDayTs) <= -daysBefore) {
				// MTVW-318: clip at boundary
				targetHour = 0
				targetMinute = 0
			} else {
				console.debug("DEC DAY %s -> %s, dist %s", targetTime, targetTime + 24 * 60, gPos.distance)
				console.debug("start %s, minPerPx %s, stack %o", gPos.startTime, minutePerPixel, "-" /*new Error().stack*/)
				targetTime += 24 * 60
				targetHour = Math.trunc(targetTime / 60)
				targetMinute = targetTime % 60
				gPos.startTime = targetTime
				//gPos.endPositionX = gPos.endPositionX + gPos.distance
				//gPos.lastPos = gPos.endPositionX
				gPos.distance = 0
				page.setSelectedDayTs(page.getSelectedDayTs - DayInMs, targetHour, targetMinute)
				dayChanged = true
			}
		}
		else if (targetTime > 23 * 60 + 59) {
			if (daysFromToday(page.getSelectedDayTs) >= daysAfter) {
				// MTVW-318: clip at boundary
				targetHour = 23
				targetMinute = 59
			} else {
				console.debug("INC DAY %s -> %s, dist %s", targetTime, targetTime - 24 * 60, gPos.distance)
				console.debug("start %s, minPerPx %s, stack %o", gPos.startTime, minutePerPixel, "-" /*new Error().stack*/)
				targetTime -= 24 * 60
				targetHour = Math.trunc(targetTime / 60)
				targetMinute = targetTime % 60
				gPos.startTime = targetTime
				//gPos.endPositionX = gPos.endPositionX + gPos.distance
				//gPos.lastPos = gPos.endPositionX
				gPos.distance = 0
				page.setSelectedDayTs(page.getSelectedDayTs + DayInMs, targetHour, targetMinute)
				dayChanged = true
			}
		}

		//console.debug("target %s:%s, page %s:%s", targetHour, targetMinute, page.getDateHour, page.getDateMinute)
		refTimeIndicator.current.handleChangeTime(targetHour, targetMinute)
		// force epg scroll even when scrolling in the epg
		if (dayChanged) {
			//scrollEpg(true)
			const epg = page.refScroll
			const epgPos = page.getDayOffsetInPx
			epg.scrollLeft = epgPos
			gPos.lastPos = epgPos
		}
	}

	stopWatch.lap("HoursSelector before render")
	return (
		<div className="HoursSelectorsContainer" >
			<div ref={refMainHoursBar}
				// className={`daysHoursSelectorContainer ${firstLoad.current ? '' : 'scrollAnimation'}`}
				id="mainHourBar"
				className={`daysHoursSelectorContainer scrollAnimation`}
				// eslint-disable-next-line no-restricted-globals
				onMouseDown={handleMouseDown.bind(this, refMainHoursBar, gPosHours)}
				onMouseMove={throttle(handleMouseMove.bind(this, refMainHoursBar, gPosHours), 50)}
				onMouseUp={handleMouseUp.bind(this, refMainHoursBar, gPosHours)}
				onMouseLeave={handleMouseLeave.bind(this, refMainHoursBar, gPosHours)}
				onTouchStart={handleTouchStartHours.bind(this, refMainHoursBar, gPosHours)}
				onTouchEnd={handleTouchEndHours.bind(this, refMainHoursBar, gPosHours)}
				onWheel={handleWheelHoursBar.bind(this, refMainHoursBar, gPosHours)}
			/* replaced by scroll event which is much smoother
			onTouchEnd={handleMouseUp}
			onTouchMove={handleMouseMove.bind(this, refMainHoursBar)}
			*/
			>
				{allDaysArray.map((day, index) => {
					let disableDay = (day < -daysBefore) || (day > daysAfter)
					//console.debug("days is %s and is disable =%s", day, disableDay)
					return mountState !== MOUNT_STATE_DEFERRED ? null : (
						<HoursInDayMain day={day} key={index} handleClickHourBar={handleClickHourBar} isDisableDay={disableDay} />
					)
				})}
			</div>

			<div ref={refTimeLineBar}
				// className={`TimeSelectorBarContainer  ${firstLoad.current ? '' : 'scrollAnimation'}`}
				id="timeLineBar"
				className={`TimeSelectorBarContainer scrollAnimation`}
				onMouseDown={handleMouseDown.bind(this, refTimeLineBar, gPosTimeline)}
				onMouseMove={throttle(handleMouseMove.bind(this, refTimeLineBar, gPosTimeline), 50)}
				onMouseUp={handleMouseUp.bind(this, refTimeLineBar, gPosTimeline)}
				onMouseLeave={handleMouseLeave.bind(this, refTimeLineBar, gPosTimeline)}
			/* replaced by scroll event which is much smoother
			onTouchStart={handleMouseDown.bind(this, refTimeLineBar)}
			onTouchMove={handleMouseMove.bind(this, refTimeLineBar)}
			onTouchEnd={handleMouseUp}
			*/
			>
				{allDaysArray.map((day, indexDay) => {
					let disableDay = (day < -daysBefore) || (day > daysAfter)

					// defer render of HourContainerInTimeBar (which takes very long) for quicker screen update on tab change
					// MTVW-361: added rootStore.page conditions to increase the speed of opening modals in Safari 
					return !enableTimeBar || mountState !== MOUNT_STATE_DEFERRED || rootStore.page.OverlayEventDetails.isShow || rootStore.page.MiniEpg.isShow ?
						(
							<div key={indexDay}>
								<div
									className={`dayInTimeBar ${disableDay ? 'disableDayInTimeBar' : ''}`}
									style={{ width: "8640px", height: "18px" }}
								>
								</div>
							</div>
						) : (
							<div
								className={`dayInTimeBar ${disableDay ? 'disableDayInTimeBar' : ''}`}
								key={indexDay}
							>
								{dayHours.map((item, indexHours) => {
									return mountState !== MOUNT_STATE_DEFERRED ? null : (
										<HourContainerInTimeBar hour={item} key={indexHours} />
									)
								})}
							</div>
						)
				})}
			</div>

			<div className="daysHoursMaskContainer">
				<div ref={refSecondHoursBar} id="secondHoursBar"
					// className={`daysHoursSelectorContainer2  ${firstLoad.current ? '' : 'scrollAnimation'}`} 
					className={`daysHoursSelectorContainer2 scrollAnimation`}
				>
					{allDaysArray.map((day, index) => {
						return mountState !== MOUNT_STATE_DEFERRED ? null : (
							<HoursInDayMask key={index} />
						)
					})}
				</div>
			</div>

			<div className="highlighterRectangleContainer ">
				<div className="highlighterRectangle">
					<div className="pointerRectangle"></div>
					<TimeIndicator ref={refTimeIndicator} page={page} />
				</div>
			</div>

			{stopWatch.lap("HoursSelector after render")}
		</div>
	)
})
