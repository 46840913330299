import { observable, action, makeObservable } from "mobx"

/*
export class PauseAd {
   url = null
   close = false

   constructor() {
      makeObservable(this, {
         url: observable,
         close: observable
      })
   }
}
*/

export class ReplayAd {
   countdown = 0

   constructor() {
      makeObservable(this, {
         countdown: observable,
         setCountdown: action
      })
   }

   setCountdown(countdown) {
      this.countdown = countdown
   }
}

export class FastFwdAd {
   skip = false
   promoCountdown = 0
   adNumber = 0
   adCount = 0

   constructor(skip, promoCountdown, adNumber, adCount) {
      this.skip = skip
      this.promoCountdown = promoCountdown
      this.adNumber = adNumber
      this.adCount = adCount
      makeObservable(this, {
         skip: observable,
         promoCountdown: observable,
         adNumber: observable,
         adCount: observable,
         setValues: action
      })
   }

   setValues(skip, promoCountdown, adNumber, adCount) {
      this.skip = skip
      this.promoCountdown = promoCountdown
      this.adNumber = adNumber
      this.adCount = adCount
   }
}

export class LinearAd {
   skip = false
   //promoCountdown = 0

   constructor(skip = false) {
      this.skip = skip
      makeObservable(this, {
         skip: observable,
         //promoCountdown: observable,
         setValues: action
      })
   }

   setValues(skip /*, promoCountdown*/) {
      this.skip = skip
      //this.promoCountdown = promoCountdown
   }
}

export class AdState {
   //pause = null
   replay = null
   fastFwd = null
   linear = null

   constructor() {
      makeObservable(this, {
         //pause: observable.ref,
         replay: observable.ref,
         fastFwd: observable.ref,
         linear: observable.ref,
      })
   }

   get isAdActive() {
      return this.replay !== null || this.fastFwd !== null //|| this.linear!==null
   }
}

/*
export const AdType = Object.freeze({
   none: Symbol("none"),
   pause: Symbol("pause"),
   linear: Symbol("linear"),
   fastFwd: Symbol("fastFwd"),
   replay: Symbol("replay")
})

export class AdInfo {
   adType = AdType.none
   skip = false
   countdown = 0 // for promo
   adNumber = 0 // for fastFwd ad
   acCount = 0 // for fastFwd ad
   url = null

   constructor() {
      makeObservable(this, {
         adType: observable,
         skip: observable,
         countdown: observable,
         adNumber: observable,
         acCount: observable,
         url: observable,
      })
   }
}

const adUiInfo = {
   pause: { url: null, close: false },
   replay: { countdown: 0 },
   fastFwd: { skip: false, promoCountDown: 0, adNumber: 0, adCount: 0 }
}
*/
