import { types as t } from "mobx-state-tree"
import { urlFixHttpProtocolMismatch } from "store/util/urlFixHttpProtocolMismatch"

export const PICTURES_TYPES = {
	Logo: "Logo",
	BoxCover: "BoxCover"
}

export const Picture = t.model("Picture", {
	Value: t.string,
	type: t.identifier
})

export const Pictures = t
	.model("Pictures", {
		Picture: t.array(Picture)
	})
	.views(self => ({
		get getLogo() {
			return self.getType(PICTURES_TYPES.Logo)
		},
		get getBoxCover() {
			return self.getType(PICTURES_TYPES.BoxCover)
		},
		getType(type) {
			const picture = self.Picture.find(i => i.type === type)
			if (picture === undefined || !picture.Value) {
				return null
			}
			// if via http:// try make this make the same like a domain
			return urlFixHttpProtocolMismatch(picture.Value)
		}
	}))
