/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-01
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-10
 */

import { TraxisAbstract } from "store/api/TraxisAbstract"
import { SoftLinks } from "store/model/traxis/SoftLink"

export class GetCategorySoftlinks extends TraxisAbstract {
	//_onStartCreate = true
	//_onStartRemove = true

	get _model() {
		return SoftLinks
	}

	_getParseResp(data) {
		return data.Categories.Category[0].SoftLinks
	}

	_getHashReq(data) {
		return `GetCategorySoftlinks`
	}

	getXmlReq() {
		return `<?xml version="1.0" encoding="utf-8"?>
<Request xmlns="urn:eventis:traxisweb:1.0">
	<RootRelationQuery relationName="RootCategories">
		<Options>
			<Option type="Props">SoftLinks</Option>
			<Option type="Filter"><![CDATA[SoftLinks.type==ToCategory]]></Option>
		</Options>
	</RootRelationQuery>
</Request>`
	}
}
