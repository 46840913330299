// l10n status: done
import { Button } from "@mui/material"
import { CreateComponent } from "components/utils/CreateComponent.js"
import React, { useRef } from "react"
import { RecordingsManager } from "scenes/Recordings/components/RecordingsManager"
import { RECORDING_OPTIONS } from "store/page/recordings/RecordingsManager"
import { rootStore } from "store/RootStore"
import { computed } from "mobx"
import { ButtonRecord, ButtonDelete, ButtonCancel } from "scenes/components/ButtonsStock"
import { l10n } from "store/lang/L10n"

const styles = theme => ({
	RecordingButtons: {
		background: theme.palette.primary.main + " !important",
		color: "white !important",
		margin: "16px 24px 16px 0px !important",
		"&:hover": {
			background: theme.palette.primary.hover + " !important",
		}
	},
	RecordingButtonsLargeWidth: {
		minWidth: "250px !important"
	}
})

export const RecordingButtons = CreateComponent(styles, false, true, ({ classes, oEvent, origin }) => {
	// MTVW-518: use computed to reflect changes in recordings events
	const eventManager = computed(() => rootStore.page.Recordings.Manager.getRecordingEvent(oEvent)).get()

	const buttons = eventManager.buttonsLists

	console.info("eventManager=%o buttons=%o buttons=%o", eventManager, buttons, Object.keys(buttons))

	const managerRef = useRef()

	// GT12: MTVW-500, MTVW-460, MTVW-502: Channels MS flags
	const buttonsEnabled = computed(() => rootStore.page.MsEpg?.isRecordingAllowed(oEvent?.ChannelId, oEvent?.AvailabilityStartDateTs, oEvent?.AvailabilityEndDateTs)).get()
	//const buttonsEnabled = true
	//console.debug("RecordingButtons %s, %o, %o", buttonsEnabled ? "ALLOWED" : "NOT ALLOWED", oEvent, eventManager)
	//console.debug("RecordingButtons single %s, series %s, planned %s", eventManager.isRecordedAsSingle, eventManager.isRecordedAsSeries)
	return (
		<>
			{/* no recording yet */}
			{(buttons[RECORDING_OPTIONS.series_episode_record] ||
				buttons[RECORDING_OPTIONS.series_record]) && buttonsEnabled && (
					/* MTVW-811: new buttons
						<Button className={classes.RecordingButtons} onClick={() => void managerRef.current.setTypeRecording(Object.keys(buttons))} data-test="record">
							{l10n.commonActionRecord}
						</Button>
						*/
					<ButtonRecord bId={`buttonRecord_${origin}`} label={l10n.commonActionRecord}
						onClick={() => void managerRef.current.setTypeRecording(Object.keys(buttons))} data-test="record" />
				)
			}
			{/* no series only one episode */}
			{buttons[RECORDING_OPTIONS.episode_record] && buttonsEnabled && (
				/*
				<Button className={classes.RecordingButtons} onClick={() => void managerRef.current.setTypeRecording([RECORDING_OPTIONS.episode_record])} data-test="record">
					{l10n.commonActionRecord}
				</Button>
				*/
				<ButtonRecord bId={`buttonRecordEpisode_${origin}`} label={l10n.commonActionRecord}
					onClick={() => void managerRef.current.setTypeRecording([RECORDING_OPTIONS.episode_record])} data-test="record" />
			)}
			{/* only an episode is alredy recorded of a series, and this start record all series*/}
			{buttons[RECORDING_OPTIONS.series_record_add] && buttonsEnabled && (
				/*
				<Button className={`${classes.RecordingButtons} ${classes.RecordingButtonsLargeWidth}`} onClick={() => void managerRef.current.setTypeRecording([RECORDING_OPTIONS.series_record_add])} data-test="record">
					{l10n.dialogStartSeriesRecordingAction}
				</Button>
				*/
				<ButtonRecord bId={`buttonRecordSeries_${origin}`} label={l10n.dialogStartSeriesRecordingAction}
					onClick={() => void managerRef.current.setTypeRecording([RECORDING_OPTIONS.series_record_add])} data-test="record" />
			)}

			{buttons[RECORDING_OPTIONS.episode_cancel] && (
				/*
				<Button className={`${classes.RecordingButtons} ${classes.RecordingButtonsLargeWidth}`} onClick={() => void managerRef.current.setTypeDelete(RECORDING_OPTIONS.episode_cancel)} data-test="abbrechen">
					{l10n.dialogCancelRecordingAction}
				</Button>
				*/
				<ButtonCancel bId={`buttonCancelEpisode_${origin}`} label={l10n.dialogCancelRecordingAction}
					onClick={() => void managerRef.current.setTypeDelete(RECORDING_OPTIONS.episode_cancel)} data-test="abbrechen" />
			)}
			{buttons[RECORDING_OPTIONS.episode_delete] && (
				/*
				<Button className={`${classes.RecordingButtons} ${classes.RecordingButtonsLargeWidth}`} onClick={() => void managerRef.current.setTypeDelete(RECORDING_OPTIONS.episode_delete)} data-test="loschen">
					{l10n.dialogDeleteRecordingAction}
				</Button>
				*/
				<ButtonDelete bId={`buttonDeleteEpisode_${origin}`} label={l10n.dialogDeleteRecordingAction}
					onClick={() => void managerRef.current.setTypeDelete(RECORDING_OPTIONS.episode_delete)} data-test="loschen" />
			)}
			{buttons[RECORDING_OPTIONS.series_episode_cancel] && (
				/*
				<Button className={`${classes.RecordingButtons} ${classes.RecordingButtonsLargeWidth}`} onClick={() => void managerRef.current.setTypeDelete(RECORDING_OPTIONS.series_episode_cancel)} data-test="abbrechen">
					{l10n.dialogCancelRecordingAction}
				</Button>
				*/
				<ButtonCancel bId={`buttonCancelSeriesEpisode_${origin}`} label={l10n.dialogCancelRecordingAction}
					onClick={() => void managerRef.current.setTypeDelete(RECORDING_OPTIONS.series_episode_cancel)} data-test="abbrechen" />
			)}
			{buttons[RECORDING_OPTIONS.series_episode_delete] && (
				/*
				<Button className={`${classes.RecordingButtons} ${classes.RecordingButtonsLargeWidth}`} onClick={() => void managerRef.current.setTypeDelete(RECORDING_OPTIONS.series_episode_delete)} data-test="loschen">
					{l10n.dialogDeleteSingleRecordingAction}
				</Button>
				*/
				<ButtonDelete bId={`buttonDeleteSeriesEpisode_${origin}`} label={l10n.dialogDeleteSingleRecordingAction}
					onClick={() => void managerRef.current.setTypeDelete(RECORDING_OPTIONS.series_episode_delete)} data-test="loschen" />
			)}
			{buttons[RECORDING_OPTIONS.series_delete] && (
				/*
				<Button className={`${classes.RecordingButtons} ${classes.RecordingButtonsLargeWidth}`} onClick={() => void managerRef.current.setTypeDelete(RECORDING_OPTIONS.series_delete)} data-test="serienaufnahme_loschen">
					{l10n.dialogDeleteSeriesRecordingAction}
				</Button>
				*/
				<ButtonDelete bId={`buttonDeleteSeries_${origin}`} label={l10n.dialogDeleteSeriesRecordingAction}
					onClick={() => void managerRef.current.setTypeDelete(RECORDING_OPTIONS.series_delete)} data-test="serienaufnahme_loschen" />
			)}
			{buttons[RECORDING_OPTIONS.series_cancel] && (
				/*
				<Button className={`${classes.RecordingButtons} ${classes.RecordingButtonsLargeWidth}`} onClick={() => void managerRef.current.setTypeDelete(RECORDING_OPTIONS.series_cancel)} data-test="serienaufnahme_abbrechen">
					// MTVW-639: use proper phrase key*
					{l10n.dialogCancelSeriesRecordingAction}
				</Button >
				*/
				<ButtonCancel bId={`buttonCancelSeries_${origin}`} label={l10n.dialogCancelSeriesRecordingAction}
					onClick={() => void managerRef.current.setTypeDelete(RECORDING_OPTIONS.series_cancel)} data-test="serienaufnahme_abbrechen" />
			)}
			<RecordingsManager ref={managerRef} oEvent={oEvent} />
		</>
	)
})
