// l10n status: partial
//import { Typography } from "@mui/material"
import { CreateComponent } from "components/utils/CreateComponent"
import fallback from "img/fallback/empty.png"
//import { untracked } from "mobx"
import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import { observer } from "mobx-react"
import "./css/tvGuide.css"
// importing styles from a css file requires a filename with 'ComponentName.module.css'
import styles from "./css/TvGuide.module.css"
import "./css/channelList.css"
import { /*DayInMs, HourInMs,*/ moment, adjustUtc, adjustUtcStartDay } from "store/util/moment"
import { DataSelectorBars } from "./components/dateSelectors/DateSelectorBars"
import { Img } from "components/Img"
import { MiniEpg } from "scenes/components/miniEpg/MiniEpg"
// import { DynamicFeedSharp } from "@mui/icons-material"
import { CALLING_CONTEXT_TYPE } from "store/page/OverlayEventDetails"

//import { LiveTvConfig } from "scenes/LiveTv/LiveTv"
//import { TvGuideHorizontalScroll } from "scenes/TvGuide/components/TvGuideHorizontalScroll.js"
import { TvGuideNowMarker } from "scenes/TvGuide/components/TvGuideNowMarker"
//import { TvGuideNowMarkerText } from "scenes/TvGuide/components/TvGuideNowMarkerText"
import { TvGuidePrograms } from "scenes/TvGuide/components/TvGuideProgram"
//import { TvGuideTimeListRuler } from "scenes/TvGuide/components/TvGuideTimeListRuler"
import { TvGuideConf } from "store/page/TvGuide.js"
import { rootStore } from "store/RootStore"
//import { ROUTE_NAMES } from "site-ql/RouteConst"
//import { useHistoryRouteAdd } from "components/utils/RouteUtils"
// import { DatePicker } from "./components/dayTimeLinePicker/DatePicker"
import { TvGuideChannelFilter } from '../components/filterChannelInput/FilterChannelInput'
import { ChannelListMenu } from "components/ChannelListMenu"
import { logResourceLoadFailure } from "utils/BugsnagReporter"
import { subscribe, isSupported } from "on-screen-keyboard-detector"
import { ErrorMessage } from "components/ErrorMessage"
import { l10n } from "store/lang/L10n"

// const styles = theme => ({})
/* MTVW-440
const styles = theme => ({
	ErrorInfoWrapper: { display: "flex", position: "absolute", left: '50%', top: '50%', paddingTop: 450, transform: 'translate(-50%, -50%)', zIndex: 20000 },
})
*/

function getFilteredChannel(strFilterChannel, page) {
	return strFilterChannel === "" ?
		page.ChannelsFromList
		:
		(page.ChannelsFromList.filter(channel => {
			return channel.Name.toLowerCase().replace(/ /gi, "").indexOf(strFilterChannel.toLowerCase().replace(/ /gi, "")) !== -1;
		}))

}

const TvGuideProgramEpg = CreateComponent(null, false, true, ({ strFilterChannel, page }) => {
	const refScroll = useRef(null)
	const filteredChannels = getFilteredChannel(strFilterChannel, page)
	//page.clearLeftEpgPaddingsMap()
	page.setFilteredChannels(filteredChannels)

	useEffect(() => {
		// TODO: does not work in HoursSelector.addEventListener ???
		//page.setRefScroll(refScroll.current)
		page.setRefScroll(document.getElementsByClassName("innerTvGuideChannels")[0])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const firstUpdate = useRef(true)
	useLayoutEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false
			return
		}
		// added setTimeout to fix problem of no scrolling if the TV Guide is reloaded
		setTimeout(() => {
			page.scrollEpg()
		}, 200)

	})

	/*
	if (filteredChannels?.length === "undefined" || !page?.bufferSize) {
		console.error("TvGuideProgramEpg filteredChannels %o, len %s, bufferSize %o", filteredChannels, filteredChannels?.length, page?.bufferSize)
		return null
	}
	*/

	return (
		<>
			{/*<div className={classes.ErrorPosition} >*/}
			{/* MTVW-440 */}
			<ErrorMessage error={rootStore.page.Player.error} isPlayerError={true} classes={{ ErrorInfoWrapper: styles.TvGuideErrorInfoWrapper }} page={page} id="player" />
			{/*</div>*/}
			<div id="tv-program" className="innerTvGuide"
			// //MTVW-302
			// //style={{ marginLeft: 100 - untracked(() => page.getWrapperLeftInPx) + "px" }}
			>
				<TvGuideNowMarker page={page} />
				<div className="innerTvGuideChannels" /*style={{ width: TvGuideConf.dayHourPeriod * page?.getPixelsPerMinute * 60 }}*/

				// ref={el =>
				// 	page.setRefElemScroll(el, "bLeft", (refElem, page) => {
				// 		//MTVW-302
				// 		// eslint-disable-next-line no-debugger
				// 		debugger
				// 		refElem.scrollLeft = 100 - page.getWrapperLeftInPx + "px"
				// 	})
				// }

				>
					<div className="innerTvGuideChannelsOverflow" style={{ height: TvGuideConf.rowHeightInPx * filteredChannels?.length, width: page.bufferSize }} /*ref={el =>
					page.setRefElemScroll(el, "bLeft", (refElem, page) => {
						//refElem.scrollLeft = 360 + "px"
						console.debug("in callback")
					})
				}*/
						ref={refScroll}
					>
						{page.isReady && filteredChannels?.map?.((ch, k) => <TvGuidePrograms className="innerTvGuideChannelsChannel" /*style={{ width: TvGuideConf.dayHourPeriod * page?.getPixelsPerMinute * 60 }}*/ k={k} key={ch.id} ch={ch} page={page} id={ch.id} />)}
					</div>
				</div>
			</div>
		</>
	)
})

const TvGuideChannelList = CreateComponent(null, false, true, ({ page, strFilterChannel }) => {
	const miniEpgPage = rootStore.page.MiniEpg
	//const historyRouteAdd = useHistoryRouteAdd()
	//console.debug("number of subscribed channels: %s", filteredChannels.length)
	return (
		<>
			<MiniEpg />
			<ul className={"tvGuideChannelList"}>
				{page?.ChannelsFromList &&
					getFilteredChannel(strFilterChannel, page).map(ch => {
						return (
							<li
								className={page.isSelectedChannel(ch.id) ? "tvGuideChannelSelected" : ""}
								key={ch.id}
								id={ch.id}
								data-test={ch.Name}
								onClick={() => {
									//rootStore.page.LiveTv.handlePlayChannelLive(ch.id, LiveTvConfig.cellHeight + LiveTvConfig.cellMarginBottom)								
									// MTVW-316: why should we need below statement?
									//window.scrollTo(0, 0)
									//historyRouteAdd(ROUTE_NAMES.LiveTv)
									miniEpgPage.setShow(true, ch, CALLING_CONTEXT_TYPE.TvGuide)
								}}
							>
								{/* MTVW-251*/}
								{/*
									{ch.Pictures && <Img src={ch.Pictures.getLogo} params="mode=box&w=480&h=270" onError={e => { logResourceLoadFailure(ch.Pictures.getLogo + "?mode=box&w=480&h=270", e) }} fallback={fallback} alt={ch.Name} className={classes.TvGuideChannelLogo} />}
									*/}
								{/* MTVW-831: "mode=box&w=144" -> "mode=box&w=480&h=270" */}
								{ch.logoUrl && <Img src={ch.logoUrl} params="mode=box&w=480&h=270" onError={e => { logResourceLoadFailure(ch.logoUrl + "?mode=box&w=480&h=270", e) }} fallback={fallback} alt={ch.Name} />}
								{/* <div className={page.isSelectedChannel(ch.id) ? "tvGuide" : ""}></div> */}
							</li>
						)
					})}
			</ul>
		</>
	)
})


const PrimeTimeButton = observer(({ page, handleChangeNowOrPrimeTime }) => {
	const [label, setLabel] = useState("")

	useEffect(() => {
		setLabel(page.isDateCurrTs ? l10n.commonLabelToday.toUpperCase() : moment(page.getSelectedDayTs).format('dd '))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page.getSelectedDayTs])

	return (
		<div className="TvGuideButtonPrimeTime" onClick={handleChangeNowOrPrimeTime(false)}>
			<div className="TvGuideLabelFootRoundButton"><span className="labelPrimeTimeDay">{label}</span>  <span>20:15</span> </div>
		</div>
	)
})

let unsubscribe = null
export const TvGuide = () => {
	const refMainTvGuideContainer = useRef(null)
	const refTvGuideBody = useRef(null)
	const page = rootStore.page.TvGuide
	// TODO: check windowSize management and move it out
	const [windowSize, setWindowSize] = useState(null)
	const [strFilterChannel, setStrFilterChannel] = useState("")
	const [kbdVisible, setKbdVisible] = useState(false)

	function handleResizeTvGuide() {
		//setWindowSize(window.innerWidth || window.clientWidth)
		setWindowSize(refMainTvGuideContainer?.current?.innerWidth || refMainTvGuideContainer?.current?.clientWidth)
		//console.debug("resize inner %s, client %s, %s, %s. %s", window.innerWidth, window.clientWidth, refMainTvGuideContainer.current.innerWidth, refMainTvGuideContainer.current.clientWidth, document.getElementById("TvGuideMainContainer").clientWidth)
	}

	// MTVW-745: Vertical scroll, horizontal scroll is handled in HoursSelector
	function handleKeyDown(event) {
		//console.debug("TvGuide handleKeyDown", refTvGuideBody?.current.scrollTop, event)
		if (event.keyCode === 38) {
			//console.debug("up")
			event.preventDefault()
			refTvGuideBody.current.scrollTop -= TvGuideConf.rowHeightInPx
		}
		if (event.keyCode === 40) {
			//console.debug("down")
			event.preventDefault()
			refTvGuideBody.current.scrollTop += TvGuideConf.rowHeightInPx
		}

	}

	function changeFilterForChannelSearch(filterStr) {
		setStrFilterChannel(filterStr)
	}

	useEffect(
		...page.getMountBody(
			() => {
				// MTVW-673: on-screen-keyboard-detector might generate "undefined is not an object (evaluating 'screen.orientation.type')"
				try {
					window.addEventListener("resize", handleResizeTvGuide)
					// MTVW-745
					document.addEventListener("keydown", handleKeyDown, false)
					// 	//need to resetting the body height to not have 2 scrollbars.
					// 	//TODO: to be checked globally in the application if it is possible to standardize
					//let body = document.getElementsByTagName("body")
					document.getElementsByTagName("body")[0].style["height"] = "100vh"

					// MTV-2765: move to JETZT position and display content
					if (!page.firstMountDone) {
						// obsolete with new TV Guide
						//page.setMoveTimeRollerAsync()
						page.setFirstMountDone(true)
					}
					//page.setSelectedDayTs(page?.iDateTs)
					const now = moment()
					// MTVW-414 
					//page.setSelectedDayTs(now.utc().startOf('day').valueOf(), now.hour(), now.minute())
					page.setSelectedDayTs(adjustUtcStartDay().valueOf(), now.hour(), now.minute())

					if (isSupported()) {
						unsubscribe = subscribe(visibility => {
							if (visibility === "hidden") {
								setKbdVisible(false)
							}
							else { // visibility === "visible"
								setKbdVisible(true)
							}
						})
					}
				}
				catch (e) {
					console.error("TVGuide useEffect start", e)
				}
			},
			() => {
				try {
					window.removeEventListener("resize", handleResizeTvGuide)
					// MTVW-745
					document.removeEventListener("keydown", handleKeyDown, false)
					//need to resetting the body height or will it will present an issue in TvLive
					//let body = document.getElementsByTagName("body")
					document.getElementsByTagName("body")[0].style["height"] = ""
					if (unsubscribe && isSupported()) {
						unsubscribe()
					}
				}
				catch (e) {
					console.error("TVGuide useEffect end", e)
				}
			}
		), []
		//setCurrentWidth(page?.epgWidth)
	)
	useLayoutEffect(() => {
		// scroll the active channel on top when changing the tab
		const index = rootStore.page?.ChannelListRepo?.getIndexChannelOnList(rootStore.page.ChannelListRepo.activeChannelId)
		refTvGuideBody?.current?.scrollTo(0, index * TvGuideConf.rowHeightInPx)
		//console.debug("scrollTop=", refTvGuideBody.current.scrollTop)
	})

	useEffect(() => {
		handleResizeTvGuide()
	}, [windowSize])
	// TODO: check windowSize management and move it out

	const goNowOrPrimeTime = (goToNow = true) => event => {
		if (goToNow) {
			// MTVW-414 
			//page.setVisibleTime(moment().utc().valueOf(), moment().hour(), moment().minute())
			page.setVisibleTime(adjustUtc().valueOf(), moment().hour(), moment().minute())
		} else {
			page.setVisibleTime(page.getSelectedDayTs, 20, 15)
		}
	}

	return (
		<div ref={refMainTvGuideContainer} className="TvGuideMainContainer" id="TvGuideMainContainer" >
			<div className="tvGuideHead">
				<div className="channelFilterContainer">
					<TvGuideChannelFilter page={page} showLabel={true} changeFilterForChannelSearch={changeFilterForChannelSearch} />
					<ChannelListMenu placement="bottom-start" />
				</div>
				<DataSelectorBars page={page} windowSize={windowSize} />
			</div>
			<div className="tvGuideBody" ref={refTvGuideBody} id="TvGuideBody">
				<div className="TvGuideRedMarkerNowTime"></div>
				<TvGuideChannelList page={page} strFilterChannel={strFilterChannel} />
				{!kbdVisible ?
					<div className="tvGuideFootButtonContainer">
						<div className="tvGuideNowButton" onClick={goNowOrPrimeTime(true)}>
							<div className="TvGuideLabelFootRoundButton">{l10n.commonLabelNow.toUpperCase()}</div>
						</div>
						<PrimeTimeButton page={page} handleChangeNowOrPrimeTime={goNowOrPrimeTime} />
					</div>
					: <> </>}
				<TvGuideProgramEpg page={page} strFilterChannel={strFilterChannel} />
			</div>
			{/* <SelectedDay /> */}
			{/* className={classes. + " tvGuideChannelListResponsive"} */}
			{/* className = { page.isSelectedChannel(ch.id) ? classes.tvGuideProgramSelected : null } */}

		</div>
	)
}
