/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-02
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-10
 */

import { flow, observable, makeObservable } from "mobx"
import React from "react"
import { TraxisStore } from "store/api/TraxisStore"
import { ClassAbstract } from "store/ClassTools"
import { Sso } from "store/model/sso/Sso"
import { Time } from "store/model/Time"
import { lazyObject } from "store/ModelTools"
import { PageStore } from "store/PageStore"
import { SingletonStore } from "store/SingletonStore"
import { Wait } from "store/util/Wait"
import { AdsService } from "store/qlapi/AdsService"
import { ChannelsService } from "store/qlapi/ChannelsService"
import { EpgService } from "store/qlapi/EpgService"
import { LogService } from "store/qlapi/LogService"
import { LoginService } from "store/qlapi/LoginService"
import { MediaService } from "store/qlapi/MediaService"
import { OnboardingService } from "store/qlapi/OnboardingService"
import { SearchService } from "store/qlapi/SearchService"
import { msVersions } from "utils/TestSupport"
import { AppError } from "utils/AppError"
import { AppSession } from "../AppSession"
import { setLanguage } from "store/lang/L10n"
import { dynamicPlayerImport } from "store/page/player/Player"
import { initAppStats } from "utils/TestSupport"

class RootStore extends ClassAbstract {
	isLoading = true;

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			isLoading: observable
		})
	}

	/**
	 * @returns {TraxisStore}
	 */
	get api() {
		return lazyObject(this, "api", TraxisStore)
	}

	/**
	* @returns {AdsService}
	*/
	get adsService() {
		return lazyObject(this, "adsService", AdsService)
	}

	/**
	* @returns {ChannelsService}
	*/
	get channelsService() {
		return lazyObject(this, "channelsService", ChannelsService)
	}

	/**
	* @returns {EpgService}
	*/
	get epgService() {
		return lazyObject(this, "epgService", EpgService)
	}

	/**
	* @returns {LogService}
	*/
	get logService() {
		return lazyObject(this, "logService", LogService)
	}

	/**
	* @returns {LoginService}
	*/
	get loginService() {
		return lazyObject(this, "loginService", LoginService)
	}

	/**
	* @returns {MediaService}
	*/
	get mediaService() {
		return lazyObject(this, "mediaService", MediaService)
	}

	/**
	* @returns {OnboardingService}
	*/
	get onboardingService() {
		return lazyObject(this, "onboardingService", OnboardingService)
	}

	/**
	 * @returns {SearchService}
	 */
	get searchService() {
		return lazyObject(this, "searchService", SearchService)
	}

	/**
	 * @returns {SingletonStore}
	 */
	get singleton() {
		return lazyObject(this, "singleton", SingletonStore)
	}

	/**
	 * @returns {Sso}
	 */
	get sso() {
		return lazyObject(this, "sso", Sso)
	}

	/**
	 * @returns {PageStore}
	 */
	get page() {
		return lazyObject(this, "page", PageStore)
	}

	/**
	 * @returns {Time}
	 */
	get time() {
		return lazyObject(this, "time", Time)
	}

	/**
	 * @returns {Wait}
	 */
	get waitForTimeSync() {
		return lazyObject(this, "waitForTimeSync", Wait)
	}

	/**
	 * @returns {Wait}
	 */
	waitForAuth = new Wait(this, "waitForAuth").setStart()

	/**
	 * @returns {AppError}
	 */
	get appError() {
		return lazyObject(this, "appError", AppError)
	}

	// MTVW-471
	/**
	 * @returns {AppSession}
	  */
	get appSession() {
		return lazyObject(this, "appSession", AppSession)
	}

	_init() {
		console.info("@")

		/*
		onSnapshot(this.page, snapshot => {
			console.info("%s.afterCreate() this.page.onSnapshot(%o) this=%o", snapshot, this)
			localStorage.set("store.page", { ...snapshot, ...{ iTime: this.time.getTimeStamp() } }, true)
		})*/
		/*
		onSnapshot(this.sso, snapshot => {
			console.info("%s.afterCreate() self.sso.onSnapshot(%o) this=%o", snapshot, this)
			localStorage.set("store.sso", snapshot, true)
		})*/

		console.debug("VITE MODE", import.meta.env.MODE, import.meta.env.VITE_VERSION)
		const dummy = () => { }
		dummy(this.time, this.api, this.sso, this.page, this.waitForTimeSync, this.waitForProfile, this.page.LiveTv, this.page.TvGuide)

		this._initAsync()
		initAppStats()

		return this
	}

	_initAsync = flow(function* () {
		//  MTVW-435:
		yield setLanguage("de")
		yield dynamicPlayerImport()
		// MTVW-607: moved to sso.mainUserContactInfo to avoid traxis call before login
		/*
		// time sync from server at start
		yield this.time.handleSyncDateFromServerAsync()
		this.waitForTimeSync.setResolve()
		*/
		// MTVW-157: entry point for authentication
		yield this.sso.handleAuthenticateAsync()
		//this.appError._init()
		// apply MS versions to appStats
		// TEST: comment for checkVersions()
		//yield msVersions()
	})

	version = import.meta.env.VITE_VERSION_DATE
}

// main instance

export const rootStore = new RootStore(null, "root")
rootStore._init()

// DEV
/*
if (import.meta.env.MODE === "development") {
	if (import.meta.env.VITE_WHY_DID_YOU_UPDATE) {
		const { whyDidYouUpdate } = require("why-did-you-update")
		whyDidYouUpdate(React)
	}

	if (import.meta.env.VITE_MOBX_MAKE_INSPECTABLE) {
		const makeInspectable = require("mobx-devtools-mst")
		makeInspectable(rootStore)
	}

	window.store = rootStore
}
*/
window.rootStore = rootStore

export const RootStoreContext = React.createContext()

export const useRootStore = () => {
	return React.useContext(RootStoreContext)
}

console.info("const rootStore = RootStore.create()=%o", rootStore)
