/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-08
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-11
 */

import { runInAction } from "mobx"
import { ClassAbstract } from "store/ClassTools"

export class Timeout extends ClassAbstract {
	_id = null

	get isTimeout() {
		return !!this._id
	}

	setTimeout(callback, timeout) {
		console.info("@(callback=%o, timeout=%o) id=%o", callback, timeout, this._id)
		this.removeTimeout()
		if (timeout !== null) {
			this._id = setTimeout(() => {
				console.info("@@setTimeout -> callback=%o id=%o", callback, this._id)
				// MTV-3583: mobx 6 removed 'name' in 'runInAction' parameters
				runInAction(() => callback())
				console.info("@@setTimeout <- callback=%o id=%o", callback, this._id)
			}, timeout)
		}
		console.info("@() callback=%o id=%o", callback, this._id)
		return this
	}

	removeTimeout() {
		console.info("@() id=%o", this._id)
		if (this.isTimeout) {
			clearTimeout(this._id)
			this._id = null
		}
		return this
	}
}
