/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-05
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-10
 */

import { TraxisAbstract } from "store/api/TraxisAbstract"
import { Products } from "store/model/traxis/Product"

export class GetProducts extends TraxisAbstract {
	//_onStartCreate = true
	//_onStartRemove = true

	get _model() {
		return Products
	}

	_getParseResp(data) {
		return data.Products
	}

	getXmlReq() {
		return `<?xml version="1.0" encoding="utf-8"?>
<Request xmlns="urn:eventis:traxisweb:1.0">
	<Identity>
		<ProfileId>${this.profileId}</ProfileId>
	</Identity>
	<RootRelationQuery relationName="Products">
		<Options>
			<Option type="Props">Type,Aliases,Pictures,Name,Currency,ListPrice,OfferPrice,EntitlementState,EntitlementEnd,RentalPeriod,ChannelCount</Option>
			<Option type="Filter"><![CDATA[type==subscription&&EntitlementState==Entitled&&EntitlementEnd>now()]]></Option>
			<Option type="Limit">1000</Option>
		</Options>
	</RootRelationQuery>
</Request>`
	}
}
