import { action, computed, flow, observable, makeObservable } from "mobx"
import { Observe } from "store/page/mixin/ObserveMixin"
import { Timeout } from "store/util/Timeout"
import { PageAbstract } from "./PageAbstract"
import { MinuteInMs, moment } from "store/util/moment"
import { EventEmitter } from "components/utils/EventEmitter"
import { rootStore } from "store/RootStore"
import { timeNow } from "utils/Utils"

const oEpgTabs = {
	CURR: "epgCurr",
	PREV: "epgPrev",
	NEXT: "epgNext"
}

// MTV-2133: NO EPG TABS ANYMORE (disabled some time ago)
//const tEpgTabs = Object.values(oEpgTabs)

export class LiveTv extends PageAbstract {
	// MTVW-246
	//epgCurr = this._root.api.GetChannelsWithEvents({ _tags: ["LiveTvEpg"], _idCache: oEpgTabs.CURR }).fillData()
	epgNow = null
	/* NO EPG TABS ANYMORE
	@observable.shallow epgPrev = this._root.api.GetChannelsWithEvents({ _tags: ["LiveTvEpg"], _idCache: oEpgTabs.PREV, bPrev: true })
	@observable.shallow epgNext = this._root.api.GetChannelsWithEvents({ _tags: ["LiveTvEpg"], _idCache: oEpgTabs.NEXT, bNext: true })
	*/

	epgTabName = oEpgTabs.CURR

	scrollTop = 0

	_refElemScroll = null

	_epgWatchTimer = new Timeout(this, "_epgWatchTimer")

	_observer = new Observe(this, "_observer")

	_bFetchElseData = false

	_autoStartDone = false
	_currentChannel = null
	_currentId = null
	// MTV-3012
	memoSelectedEpg = null	// captured EPG of active channel for synchronization with end of playback

	isTextExpanded = false

	eventActive = null

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			// MTVW-246
			//epgCurr: observable.shallow,
			epgNow: observable.shallow,
			epgTabName: observable,
			scrollTop: observable,
			isTextExpanded: observable,
			channelListRepo: computed,
			// MTV-3583: do not make it computed here, mobx 6 generates an error if it's already registered in the base class
			//isReady: computed,
			epgArray: computed,
			isReplayDone: computed,
			setEpgTabName: action,
			handlePlayChannelAsync: action,
			_handlePlayEventAsync: action,
			_fetchElseData: action,
			setEpgListPos: action,
			setVerticalScroll: action,
			setTextExpanded: action,
			setScrollTop: action,
			reloadChannelEpg: action,
			getProgramsForNow: action
		})

		EventEmitter.subscribe("inactive", (event) => { this._isInactive(event) })
		EventEmitter.subscribe("active", (event) => { this._isActive(event) })
	}

	initAsync = flow(function* () {
		// NOTE: gets called at startup to load the channelListRepo
		yield this.channelListRepo.waitAsync()
		// MTVW-246
		//const channels = 
		yield rootStore.page.MsEpg.getChannelsAsync()
		// MTVW-536, MTVW-584: commented to mitigate epg failures
		//yield this.getProgramsForNow()
		//console.debug("CHANNELS %o", channels)
		yield this.reloadChannelEpg(!this.isVisible)
		this._setObserve()
		// MTVW-831: "?mode=box&w=" + 44 * 4 -> "?mode=box&w=480&h=270"
		this.channelListRepo.preloadChannelPictures("?mode=box&w=480&h=270")
	})

	_onMount() {
		this.setEpgTabName(oEpgTabs.CURR)
		this._autoStartDone = false
	}

	_onUnmount() {
	}

	get player() {
		return this._root.page.Player
	}

	get channelListRepo() {
		return this._pageStore.ChannelListRepo
	}

	get isReady() {
		//console.debug("NOW isReady, epg %s, clRepo %o", this.epgNow !== null, this.channelListRepo?.isReady)
		// MTVW-246
		//return this[this.epgTabName]?.Data?.isReady && this.channelListRepo?.isReady
		return this.epgNow && this.channelListRepo?.isReady
	}

	getChannelEpg(id) {
		//const result = this.epgNow ? this.epgNow.find(i => i.channelId === id) : null
		const result = (this.epgNow && typeof this.epgNow.find === "function") ? this.epgNow.find(i => i.channelId === id) : null
		//if (result) result.Events[0].Title = { SeasonNum: "1", EpisodeNum: "2", EpisodeName: "Unhail" }
		// for compatibility with traxis model
		return result ? { Channel: rootStore.page.MsEpg.getChannel(id), Event: result.Events[0] } : null
	}

	getChannelsEpg(channels) {
		return channels?.map(ch => this.getChannelEpg(ch.id)).filter(i => i && i.Event)
	}

	get epgArray() {
		// MTVW-246
		//return this.isReady ? this[this.epgTabName].Data.getChannelsEpg(this.channelListRepo.ChannelsFromList) : []
		//console.debug("msArray %o,", this.isReady ? this.getChannelsEpg(this.channelListRepo.ChannelsFromList) : [])
		//return this.isReady ? this.getChannelsEpg(this.channelListRepo.ChannelsFromList) : []
		return this.isReady ? this.getChannelsEpg(rootStore.page.MsEpg.getChannelsFromList(rootStore.page.ChannelListRepo.ChannelList)) : []
	}

	get isReplayDone() {
		//console.debug(this.player.source?.type)
		if (this.isReady && (this.player.source?.type === "ChannelReplay")) {
			//console.debug("CHANNEL %s, ID %", this.player.source?.id, this.player.source?.Event?.id)
			if ((this._currentChannel === this.player.source?.id) && (this._currentId !== this.player.source?.Event?.id)) {
				//this._currentChannel = null
				//this._currentId = null
				return true
			}
			this._currentChannel = this.player.source?.id
			this._currentId = this.player.source?.Event?.id
		}
		return false
	}

	get _getAvailabilityEndMinDate() {
		if (!this.isReady) {
			return null
		}
		return Math.floor(
			Math.min.apply(
				Math,
				// MTVW-246
				//this.epgCurr.Data.getChannelsEpg(this.channelListRepo.ChannelsFromList).map(i => i.Event.AvailabilityEndDateTs)
				//this.getChannelsEpg(this.channelListRepo.ChannelsFromList).map(i => i.Event.AvailabilityEndDateTs)
				this.getChannelsEpg(rootStore.page.MsEpg.getChannelsFromList(rootStore.page.ChannelListRepo.ChannelList)).map(i => i.Event.AvailabilityEndDateTs)
			) / 1000
		)
	}

	_setObserve() {
		this._observer.add("ChannelListRepoChannelsFromList.ChannelsFromList", this._root.page.ChannelListRepo, "ChannelsFromList", changes => {
			//console.debug("_observer ChannelsFromList %o", changes)
			this.reloadChannelEpg(true)
		})
		this._observer.add("this.isVisible", this, "isVisible", changes => {
			console.debug("_observer isVisible %s", changes.newValue, this.player.source.type, this._autoStartDone, this.player)
			if (changes.newValue === true && this._bFetchElseData) {
				this._fetchElseData()
			}
			if (changes.newValue === true) {
				// MTVW-217: restore Live TV state if necessary - DISABLED in PlayerVo
				//console.debug("Back in Live TV: player active %s, pause state %s, inactive reason %s, inactive channel %s", this.player.player.isPlayerActive, this.player.player.activePauseState, this.player.player.inactiveReason, this.player.player.inactiveChannelId)
				if (this.player.player.inactiveReason === "reasonStopped" && this.player.player.inactiveChannelId === rootStore.page.ChannelListRepo.activeChannelId) {
					// restore state
					this.player.player._isActive()
				}
				else {
					// regular case
					//console.debug("live tv regular")
					// MTVW-727b: could be an option to go back to live TV without starting new stream
					//if (!this.player.player.isInPip) this.handlePlayChannelAsync()
					//console.debug("calling handlePlayChannelAsync _setObserve")
					console.debug("calling handlePlayChannelAsync observer")
					this.handlePlayChannelAsync()
				}
			}
		})
		this._observer.add("this.isReplayDone", this, "isReplayDone", changes => {
			if (changes.newValue === true) {
				//console.debug("switching to current tab")
				this.setEpgTabName(oEpgTabs.CURR)
			}
		})
	}

	_isInactive(event) {
		//console.debug("LiveTv inactive")
		// nothing to do yet
	}

	_isActive(event) {
		//console.debug("LiveTv active")
		// reload of EPG done in PlayerAbstract
	}

	getProgramsForNow = flow(function* () {
		const progs = yield rootStore.page.MsEpg.getProgramsForNow().catch(e => {
			// ignore
			return
		})
		if (progs?.length > 0) this.epgNow = progs
	})

	reloadChannelEpg = flow(function* (bCheckOnly = false) {
		console.info("@@reloadChannelEpg(bCheckOnly=%o)", bCheckOnly)
		console.debug("%s: RELOAD reloadChannelEpg", timeNow())

		if (bCheckOnly !== true) {
			// always preload last used tab
			// MTVW-246
			//yield this[this.epgTabName].fetchDataAsync()
			yield this.getProgramsForNow()
			// MTV-1770, MTV-1771, MTV-1772: call handlePlayChannelAsync only 1 time after _onMount
			if (this.isVisible && !this._autoStartDone) {
				//console.debug("reloadChannelEpg: calling handlePlayChannelAsync", this.player.source.type)
				console.debug("calling handlePlayChannelAsync reloadChannelEpg")
				yield this.handlePlayChannelAsync()
				// MTV-3012: set _autoStartDone in handlePlayChannelAsync to avoid play when the app is loaded
				// with TV Guide active and then playing a channel in Live TV
				//this._autoStartDone = true
			}

			// @todo
			this._root.api.GetRecordingsMarkers().fetchDataAsync()

			// current tab next
			if (this.epgTabName !== oEpgTabs.CURR) {
				// MTVW-246
				//yield this.epgCurr.fetchDataAsync()
				yield this.getProgramsForNow()
			}

			// preload else tabs if visible else load later
			if (this.isVisible) {
				yield this._fetchElseData()
			} else {
				this._bFetchElseData = true
			}
		} else {
			// MTVW-246
			//this.epgCurr.fillData()
			yield this.getProgramsForNow()
			/* NO EPG TABS ANYMORE
			this.epgPrev.fillData()
			this.epgNext.fillData()
			*/
		}

		const timeout = this._getAvailabilityEndMinDate
		// reload EPG 3s after the calculated time to avoid asynchronity of clocks
		this._epgWatchTimer.setTimeout(() => this.reloadChannelEpg(), timeout !== null ? Math.max(1000, timeout * 1000 - this._root.time.getTimeStamp()) + 3000 : MinuteInMs)
		// MTVW-246: refresh every 30 seconds
		//this._epgWatchTimer.setTimeout(() => this.reloadChannelEpg(), 30 * 1000)
	})

	isEpgEvent(oEvent) {
		return computed(() => {
			//console.log(oEvent)
			//oEvent.Channel.id === self._root.page.ChannelListRepo.activeChannelId &&

			//return oEvent.Event.id === this.player.source?.Event?.id

			// make sure the active channel in the EPG is highlighted upon EPG change
			// MTVW-246
			//return oEvent.id === this._root.page.ChannelListRepo?.activeChannelId
			return oEvent?.Channel?.id === this._root.page.ChannelListRepo?.activeChannelId
		}).get()
	}

	setRefElemScroll(refElem) {
		this._refElemScroll = refElem
		if (refElem !== null) {
			this._refElemScroll.scrollTop = this.scrollTop
		}
	}

	setEpgTabName(sTabName) {
		if (this.epgTabName === sTabName && this._refElemScroll !== null) {
			// MTV-1646: do not reset
			//this._refElemScroll.scrollTop = 0
		} else if (this.epgTabName !== sTabName) {
			this.epgTabName = sTabName
			this[this.epgTabName].fillData()
		}
		return this
	}

	handlePlayChannelAsync = flow(function* (channelId = null) {
		console.log("@(channelId=%o)", channelId)
		if (channelId !== null) {
			this.channelListRepo.setActiveChannel(channelId)
		}
		// MTVW-246
		/*
		yield this[this.epgTabName].waitAsync()
		const currEvent = this[this.epgTabName].Data.getChannelEpg(this.channelListRepo.activeChannelId)
		*/
		const currEvent = this.getChannelEpg(this.channelListRepo.activeChannelId)
		// MTV-1750: set bForce true
		console.debug("handlePlayChannelAsync source %s, paused %o", this.player.source.type, this.player.playerLiveTvVo.isPausedUser, this.player.player)
		yield this._handlePlayEventAsync(currEvent?.Event?.id, true)
		// MTV-3012: set _autoStartDone here
		this._autoStartDone = true
	})

	setEpgListPos(channelId, height = null) {
		if (height !== null) {
			const index = this.channelListRepo.getIndexChannelOnList(channelId)
			this.scrollTop = index > 0 ? index * height : 0
		}
		this.setVerticalScroll()
	}

	// called from TvGuide when clicked on the channel logo
	handlePlayChannelLive(channelId, height = null) {
		console.log("@(channelId=%o)", channelId)
		// MTV-1646: prevent the player to use the previous channel _onMount
		// MTV-1673: Prevent only if different channel
		if (channelId !== this.channelListRepo.activeChannelId) {
			this._pageStore.Player._tLastUsage = []
			this.channelListRepo.setActiveChannel(channelId)
		}
		this.setEpgTabName(oEpgTabs.CURR)
		this.setEpgListPos(channelId, height)
	}

	_handlePlayEventAsync = flow(function* (eventId, bForce = null) {
		console.log("@@_handlePlayEventAsync(eventId=%o)", eventId)
		/* MTVW-272
		if (this.eventActive !== null) {
			this.eventActive.abortReq()
		}
		*/
		this.eventActive = null
		if (!eventId) return
		try {
			/* MTVW-272
			this.eventActive = this._root.api.GetEventById({ EventId: eventId })

			yield this.eventActive.fetchDataAsync()
			*/
			this.eventActive = yield rootStore.page.MsEpg.getEventDetailsAsync(eventId)

			// MTVW-577: stop keep alive
			//console.debug("_handlePlayEventAsync", this, this._pageStore.Player)
			//this._pageStore.Player.player.setClose()

			//const event = this.eventActive.Data
			const event = this.eventActive
			if (event) {
				//console.debug("_handlePlayEventAsync type %s, source %o, player %o", event.getCurrentEventType, this.player.source, this.player)
				if (event.getCurrentEventType === "c") {
					// GT12: prevent displaying adSkipInfo when switching from TV Guide
					yield this._pageStore.OverlayEventDetails.setEpgEventById(eventId)
					yield this._pageStore.Player.setPlayChannelLiveAsync(this.channelListRepo.activeChannelId, event, bForce)
				} else if (event.getCurrentEventType === "p") {
					console.debug("_handlePlayEventAsync setPlayChannelReplayAsync")
					yield this._pageStore.Player.setPlayChannelReplayAsync(this.channelListRepo.activeChannelId, event.AvailabilityStart, event, bForce)
				} else {
					yield this._pageStore.OverlayEventDetails.setEpgEventById(eventId)
					this._pageStore.OverlayEventDetails.setComponentType('event').setShow(true)
				}
			}
		} catch (e) {
			// to do
		}
	})

	_fetchElseData() {
		this._bFetchElseData = false
		/* NO EPG TABS ANYMORE
		return Promise.all(
			tEpgTabs
				.filter(i => i !== this.epgTabName && i !== oEpgTabs.CURR)
				.map(i => this[i])
				.map(i => i.fetchDataAsync())
		)
		*/
	}

	setVerticalScroll() {
		console.log("@()")
		if (this._refElemScroll !== null) {
			this.scrollTop = Math.max(0, this._refElemScroll.scrollTop)
		}
		return this
	}

	setTextExpanded(isTextExpanded = null) {
		this.isTextExpanded = isTextExpanded === null ? !this.isTextExpanded : isTextExpanded
		return this
	}
	setScrollTop(value) {
		this.scrollTop = value
		return this
	}
}
