import { TraxisCpeIdMixin } from "store/api/mixin/TraxisCpeIdMixin"
import { platformDescription } from "store/util/platform"
import { TraxisModelAbstract } from "store/api/TraxisModelAbstract"
import { observable, makeObservable } from "mobx";

class AddCpeIdModel extends TraxisModelAbstract {
	Cpe = null

	constructor() {
		super()
		makeObservable(this, {
			Cpe: observable
		})
	}
}

export class AddCpeId extends TraxisCpeIdMixin {
	get _model() {
		return AddCpeIdModel
	}

	_getParseResp(data) {
		return data
	}

	getXmlReq() {
		return `<?xml version="1.0" encoding="utf-8"?>
<Request xmlns="urn:eventis:traxisweb:1.0">
	<Identity>
		<ProfileId>${this.profileId}</ProfileId>
	</Identity>
	<RootActionQuery actionName="AddCpeToCustomer">
		<Arguments>
			<Argument name="Id">${this._getCpeId}</Argument>
			<Argument name="Name">${platformDescription}</Argument>
			<Argument name="Aliases">
				<Alias type="VMX3">${this._getCpeId}</Alias>
			</Argument>
		</Arguments>
	</RootActionQuery>
</Request>`
	}
}
