/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-02
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-10
 */

import { ClassAbstract } from "store/ClassTools"
import { lazyCallback, lazyObject } from "store/ModelTools"
import { LiveTv } from "store/page/LiveTv"
import { OverlayEventDetails } from "store/page/OverlayEventDetails"
import { Player } from "store/page/player/Player"
import { Recordings } from "store/page/Recordings"
import { ChannelListRepo } from "store/page/repo/ChannelListRepo"
import { MsEpg } from "store/page/repo/MsEpg"
import { TvGuide } from "store/page/TvGuide"
import { ChannelListEditor } from "store/page/ChannelListEditor"
import { EpgChannelRelatedContent } from "store/page/EpgChannelRelatedContent"
import { SingletonStore } from "store/SingletonStore"
import { Search } from "store/page/Search"
import { MiniEpg } from "store/page/MiniEpg"
import { Onboarding } from "store/page/Onboarding"
import { Settings } from "store/page/Settings"

export class PageStore extends ClassAbstract {
	/**
	 * @returns {SingletonStore}
	 */
	get singleton() {
		return lazyObject(this, "singleton", SingletonStore)
	}

	/**
	 * @returns {ChannelListRepo}
	 */
	get ChannelListRepo() {
		//console.debug("ChannelListRepo %o", new Error().stack)
		return lazyCallback(this, "ChannelListRepo", () => ChannelListRepo.create())
	}

	/**
	* @returns {MsEpg}
	*/
	get MsEpg() {
		return lazyObject(this, "MsEpg", MsEpg)
	}

	/**
	 * @returns {LiveTv}
	 */
	get LiveTv() {
		return lazyObject(this, "LiveTv", LiveTv)
	}

	/**
	 * @returns {ChannelListRepo}
	 */
	get TvGuide() {
		return lazyCallback(this, "TvGuide", () => TvGuide.create())
	}

	/**
	 * @returns {OverlayEventDetails}
	 */
	get OverlayEventDetails() {
		return lazyCallback(this, "OverlayEventDetails", () => OverlayEventDetails.create())
	}

	/**
	 * @returns {Recordings}
	 */
	get Recordings() {
		return lazyObject(this, "Recordings", Recordings)
	}

	/**
	 * @returns {Player}
	 */
	get Player() {
		return lazyObject(this, "Player", Player)
	}

	/**
	 * @returns {ChannelListEditor}
	 */
	get ChannelListEditor() {
		return lazyObject(this, "ChannelListEditor", ChannelListEditor)
	}

	/**
	 * @returns {EpgChannelRelatedContent}
	 */
	get EpgChannelRelatedContent() {
		return lazyObject(this, "EpgChannelRelatedContent", EpgChannelRelatedContent)
	}

	/**
	 * @returns {EpgChannelRelatedContentOverlay}
	 */
	get EpgChannelRelatedContentOverlay() {
		return lazyObject(this, "EpgChannelRelatedContentOverlay", EpgChannelRelatedContent)
	}

	/**
	 * @returns {Search}
	 */
	get Search() {
		return lazyObject(this, "Search", Search)
	}

	/**
	 * @returns {MiniEpg}
	 */
	get MiniEpg() {
		return lazyCallback(this, "MiniEpg", () => MiniEpg.create())
	}

	/**
	 * @returns {Onboarding}
	 */
	get Onboarding() {
		return lazyObject(this, "Onboarding", Onboarding)
	}

	/**
	 * @returns {Settings}
	 */
	get Settings() {
		return lazyObject(this, "Settings", Settings)
	}
}
