export class AppError {

	errQueue = []

	constructor() {
		// MTVW-633: 3rd parameter 'useCapture' set to true
		window.addEventListener("error", this._onError, true)
		window.addEventListener("unhandledrejection", this._onRejection, true)
		console.debug("AppError handlers installed")
	}

	set error(error) {
		this._enqueue(error)
	}

	get error() {
		return this._dequeue()
	}

	_enqueue(item) {
		this.errQueue.push(item)
	}

	_dequeue() {
		return this.errQueue.shift()
	}

	_onError = (e) => {
		console.error("AppError: onError %o, stack %s", e, e.error?.stack)
		e.preventDefault()
		//e.stopPropagation()
	}

	// Note that throw inside an async function is a Promise.reject as async functions are wrapped inside Promise
	_onRejection = (e) => {
		console.error("AppError: onRejection %o, stack %s", e, e.error?.stack)
		e.preventDefault()
		//e.stopPropagation()
		//e.stopImmedatePropagation()
	}

	getStackTrace = function () {
		var obj = {}
		// maintain proper error stack trace(available only on JS V8 engine)
		if (Error.captureStackTrace) {
			Error.captureStackTrace(obj, this.getStackTrace)
		}
		return obj.stack;
	}

	_init() {
		console.debug("_init appError")
		/*
		console.trace()
		console.debug(this.getStackTrace().toString())
		*/
		//throw (new Error("trial"))
	}
}
