import { types as t } from "mobx-state-tree"
import { TraxisAbstract } from "store/api/TraxisAbstract"

const CreateBookmarkModel = t.model({})

export class CreateBookmark extends TraxisAbstract {
	//_onStartCreate = true
	//_onStartRemove = true

	get _model() {
		return CreateBookmarkModel
	}

	_getParseResp(data) {
		return data
	}

	//_getHashReq(data) {
	//	return ``
	//}

	getXmlReq() {
		return `<?xml version="1.0" encoding="utf-8"?>
<Request xmlns="urn:eventis:traxisweb:1.0">
	<Identity>
		<ProfileId>${this.profileId}</ProfileId>
	</Identity>
	<ActionQuery resourceType="${this._oVars.Type}" resourceId="${this._oVars.Id}" actionName="SetPersonalBookmark">
		<Arguments>
			<Argument name="Bookmark">${Math.floor(this._oVars.durationInSeconds ?? 0)}</Argument>
		</Arguments>
	</ActionQuery>
</Request>`
	}
}
