import { TraxisAbstract } from "./TraxisAbstract"

export const MANAGE_RESOURCE_TYPE = {
	Recording: "Recording",
	Event: "Event"
}

export class ManageAbstract extends TraxisAbstract {
	_getXmlActionResourceType() {
		throw Error("_getXmlActionResourceType this should be implemented")
	}

	_getXmlActionArguments() {
		if (!this._oVars.Arguments) {
			return ""
		}
		return `\n<Arguments>\n${Object.entries(this._oVars.Arguments)
			.map(([k, v]) => `<Argument name="${k}">${v}</Argument>\n`)
			.join("")}</Arguments>\n`
	}

	_getXmlPartSingleOrMulti() {
		// multi
		if (Array.isArray(this._oVars?.Ids) && this._oVars?.Ids?.length > 1) {
			return `<ActionsQuery resourceType="${this._getXmlActionResourceType()}" actionName="${this._oVars.ActionName}">${this._getXmlActionArguments()}
				<Resources>
					${this._oVars.Ids.map(v => `<Resource id="${v}" />`).join("")}
				</Resources>
			</ActionsQuery>`
		}

		//single
		return `<ActionQuery resourceType="${this._getXmlActionResourceType()}" resourceId="${Array.isArray(this._oVars.Ids) ? this._oVars.Ids[0] : this._oVars.Ids}" actionName="${this._oVars.ActionName
			}">${this._getXmlActionArguments()}</ActionQuery>`
	}

	getXmlReq() {
		return `<?xml version="1.0" encoding="utf-8"?>
<Request xmlns="urn:eventis:traxisweb:1.0">
	<Identity>
		<ProfileId>${this.profileId}</ProfileId>
	</Identity>
   ${this._getXmlPartSingleOrMulti()}
</Request>`
	}
}
