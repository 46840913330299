import { action, flow, makeObservable } from "mobx";
import { ClassAbstract } from "store/ClassTools"

export const WAIT_ABORT = "WAIT_ABORT"

export class Wait extends ClassAbstract {
	promise = null
	resolve = null
	reject = null
	isDone = null
	isStarted = false

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			setReset: action,
			_setPromise: action,
			setStart: action,
			setRestart: action,
			setResolve: action,
			setReject: action
		})

		//console.debug("props %o", props)
		this._setPromise()
	}

	/**
	 * restart promise and reject current if is settled
	 */
	setReset() {
		console.info("@")
		if (this.promise && this.isDone === null && this.isStarted === true) {
			this.setReject(WAIT_ABORT)
		}

		this.promise = null
		this.resolve = null
		this.reject = null
		this.isDone = null
		this.isStarted = false

		this._setPromise()

		return this
	}

	/**
	 * @private
	 */
	_setPromise() {
		this.promise = new Promise((resolve, reject) => {
			this.resolve = resolve
			this.reject = reject
		})
		return this
	}

	setStart() {
		console.info("@")
		this.isStarted = true
		return this
	}

	setRestart() {
		console.info("@")
		if (this.isStarted === true || this.isDone !== null) {
			this.setReset()
		}
		this.isStarted = true
		return this
	}

	waitPromise() {
		if (this.isDone !== null) {
			console.info("@ -> already done")
			return this.promise
		}
		this.isStarted = true
		console.info("@ -> wait for promise")
		return this.promise
	}

	waitAsync = flow(function* () {
		if (this.isDone !== null) {
			console.info("@@waitAsync -> already done .isDone=%o", this.isDone)
			return yield this.promise
		}
		this.isStarted = true
		console.info("@@waitAsync -> wait start")
		const ret = yield this.promise
		console.info("@@waitAsync <- wait done")
		return ret
	})

	waitLockAsync = flow(function* () {
		if (this.isStarted === true) {
			console.info("@@waitLockAsync -> already locked - wait")
			yield this.promise
			console.info("@@waitLockAsync <- already locked - release")
		}
		this.setRestart()
		console.info("@@waitLockAsync -> start")
	})

	setResolve(resolveVal = null) {
		if (this.isDone !== null) {
			console.info("@(resolveVal=%o) - already done=%o this=%o", resolveVal, this.isDone)
			return this
		}
		console.info("@(resolveVal=%o)", resolveVal)
		this.isDone = true
		this.isStarted = false
		this.resolve(resolveVal)
		return this
	}

	setReject(rejectVal = null) {
		if (this.isDone !== null) {
			console.info("Wait %s.setReject(rejectVal=%o) - %s -> already done=%o this=%o", this._path !== null ? this._path : void 0, rejectVal, this.name, this.isDone, this)
			return this
		}
		console.info("Wait %s.setReject(rejectVal=%o) - %s this=%o", this._path !== null ? this._path : void 0, rejectVal, this.name, this)
		this.promise.catch(e => {
			console.info("Wait %s.setReject.catch(rejectVal=%o,e=%o) - %s this=%o", this._path !== null ? this._path : void 0, rejectVal, e, this.name, this)
		})
		this.isDone = false
		this.isStarted = false
		this.reject(rejectVal)
		return this
	}
}

export class WaitQueue extends ClassAbstract {
	tQueue = []
	iQueue = 0

	getLock = flow(function* () {
		const i = this.iQueue++
		console.info("@@WaitQueue %s.getLock() - %s -> start", this._path !== null ? this._path : void 0, this.name, this)
		const wait = new Wait(`${this._name}[${i}]`, this._path).setStart()
		this.tQueue[i] = wait
		if (this.tQueue?.length > 1) {
			yield this.tQueue[i - 1].waitAsync()
			if (this.tQueue[i - 2]) {
				delete this.tQueue[i - 2]
			}
		}
		console.info("@@WaitQueue %s.getLock() - %s <- lock settled", this._path !== null ? this._path : void 0, this.name, this)
		return wait
	})
	waitAsync = flow(function* () {
		console.info("@@WaitQueue %s.waitAsync() - %s -> start", this._path !== null ? this._path : void 0, this.name, this)
		if (this.tQueue?.length > 1) {
			yield this.tQueue[this.tQueue.length - 1].waitAsync()
		}
		console.info("@@WaitQueue %s.waitAsync() - %s <- lock settled", this._path !== null ? this._path : void 0, this.name, this)
	})
}
