import { Grid } from "@mui/material"
import { Typography, Button, Tooltip } from "@mui/material"
// icon does not scale when zooming on Safari
//import DeleteIcon from "@mui/icons-material/Delete"
//import DragIcon from "@mui/icons-material/DragHandle"
import DeleteIcon from "img/delete.svg"
import DragIcon from "img/drag-handle.svg"
import HdIcon from "img/tv_hd.svg"
import { ListItem } from "@mui/material"
import React, { Component } from "react"
//import { rootStore } from "store/RootStore"
import { Img } from "components/Img"
import fallback from "img/fallback/empty.png"
import { alpha } from "@mui/material/styles"
import { CleConfig } from "scenes/ChannelListEditor/ChannelListEditor"
import { withStyles } from '@mui/styles'
import { useDrag, useDrop } from 'react-dnd'
import flow from 'lodash/flow'
import { findDOMNode } from 'react-dom'
//import { observer } from "mobx-react"
import scrollHelper from "./ScrollHelper"
import { logResourceLoadFailure } from "utils/BugsnagReporter"
import { CreateComponent } from "components/utils/CreateComponent"
import { theme as _theme } from "../../site-ql/theme"


const styles = theme => ({
	CleListItem: {
		padding: "0px 0px 15px",
	},
	CleChannelData: {
		display: "flex 1",
		//flexFlow: "row wrap",
		//flexDirection: "row",
		width: CleConfig.listDataWidth,
		padding: "8px 10px",
		height: CleConfig.listCellHeight,
		cursor: "pointer",
		backgroundColor: "#2A2A2A",
	},
	CleChannelData1: { /*alignSelf: "flex-start",*/ width: "100%" },
	CleChannelData1Wrapper: { display: "flex", justifyContent: "space-between" },
	CleChannelData2: { /*flexDirection: "row",*/ /*alignSelf: "flex-end",*/ width: "100%" },
	CleChannelData2Wrapper: { display: "flex", flexDirection: "column", flexWrap: "wrap", alignContent: "flex-end",/*alignContent: "flex-end", justifyContent: "space-between", marginBottom: 2*/ },
	CleNumbering: {
		width: CleConfig.listNumberingWidth,
		//paddingLeft: -CleConfig.listNumberingWidth,
		height: CleConfig.listCellHeight,
		//lineHeight: CleConfig.listCellHeight,
		lineHeight: "5em",
		//alignItems: "center",
		textAlign: "right",
		paddingRight: 8,
		justifyContent: "flex-end",
	},
	CleChannelLogoWrapper: {
		width: CleConfig.listLogoWrapperWidth,
		//height: 70,
		alignItems: "center",
		display: "flex",
		borderRight: "1px solid #000",
		cursor: "pointer",
		backgroundColor: alpha(theme.palette.secondary[700], 0.8)
	},
	CleChannel: {
		// disable long touch
		"-webkit-touch-callout": "none !important"
	},
	CleChannelLogo: {
		width: CleConfig.listLogoWidth,
		marginLeft: "auto",
		marginRight: "auto",
		opacity: 0.7,
	},
	CleIcon: {
		display: "inline-block", // for scaling on zoom
		overflow: "hidden", // for scaling on zoom
		padding: "0 0 0 0",
		//margin: "0 0 0 0",
		//minWidth: 20,
		minWidth: "unset !important",
		width: 20,
		height: 20,
		//justifyContent: "space-between"
		//order: 1,
		//flex: "0 1 auto",
		//alignSelf: "auto",
		//alignSelf: "flex-start"
	},
	// copy needed for DndPreview
	CleListWrapper: {
		//position: "relative",
		display: "flex",
		marginLeft: -CleConfig.listNumberingWidth
	},
	CleListWrapperScrollable: {
		//position: "fixed",
		overflowY: "scroll",
		scrollbarWidth: "none",
		marginTop: 6,
		"&::-webkit-scrollbar": { display: "none" },
		// TODO: remove? NOT working
		//paddingBottom: 64 - 15
	},
})

const LightTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 11,
	},
}))(Tooltip)

// wrapper class to pass hook to ListChannel since classes cannot use hooks
const ListChannelWithHook = CreateComponent(styles, false, true, ({ ...props }) => {
	const { item, page } = props
	//console.debug("in ListChannelWithHook %o", props)
	/**/
	//item.listId = 0
	const [collected, drag, dragPreview] = useDrag({
		//type: ["LIST_CHANNEL", "GRID_CHANNEL"],
		//item: item,
		item: monitor => {
			scrollHelper.addScrollMonitor("channelListId")
			console.debug("DRAG BEGIN LIST %o", item, monitor)
			item.dragIndex = item.index
			return item
		},
		end: (item, monitor) => {
			page.setDragChannel(-1)// deregister event handler
			scrollHelper.removeScrollMonitor()
			console.debug("DRAG END LIST %o", item, monitor)
		},
		type: CleConfig.listItemType,
		collect: monitor => ({
			isDragging: !!monitor.isDragging(),
		})
	})
	/**/

	//console.debug("dragPreview list %o", dragPreview, collected)

	console.debug("isDragging %s", collected.isDragging)
	return (
		<ListChannel {...props} isDragging={collected.isDragging} dragRef={drag} previewRef={dragPreview} />
	)
})

const ListChannel = withStyles(styles)(
	class extends Component {
		constructor(props) {
			super(props)
			this.state = {}
		}
		componentDidMount() {
			//console.debug("mounted now")
			// disable automatic snapshot with HTML5Backend (which can produce ghost images), instead we are now using react-dnd-preview
			/*
			const img = new Image(1, 1)
			// 1x1 pixel image with opacity 0
			img.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
			//img.onload = () => this.props.connectDragPreview(img)
			*/
		}

		render() {
			const { item, classes, page, dragRef, previewRef, isDragging, canDrop /*, connectDragSource, connectDropTarget*/ } = this.props
			//console.debug("render: %o, %o", item, this.props)
			//let refElem = null
			let opacity = isDragging ? 0 : 1
			if (item?.id === page.dragChannel) {
				opacity = 0.5
			}

			//console.debug("OPACITY %s, %s", opacity, isDragging)
			//console.debug("REFS %s, %s, %o, %o, %o", opacity, canDrop, item.dragIndex, item.index, item, dragRef, previewRef)
			return (
				/* connectDragSource(
					connectDropTarget(
				*/
				<div ref={dragRef}>
					{/*<ListItem ref={previewRef}> </ListItem>*/}
					<ListItem
						//ref={refElem}
						className={classes.CleListItem}
						data-test="channel_list_entry"
						//divider={divider}
						id={`${(CleConfig.listElemIdPrefix)}${item.index}`}
						style={{ opacity, /*top,*/ position: item.index > 0 ? /*"absolute"*/ "relative" : "relative" /*, paddingBottom: k === page.channelList.length - 1 ? 0 : 15*/ }}
					//data-test-selected={page.isCleEvent(i) ? true : false}
					>
						<Grid container justifyContent="center" className={classes.CleChannel}>
							<div container className={classes.CleNumbering} /*sx={{ width: "40px" }}*/>
								{console.debug("check", canDrop && item.dragIndex === item.index, item.dragIndex, item.index)}
								{canDrop && item.dragIndex === item.index ? "" : item.index + 1}
							</div>
							<div item className={classes.CleChannelLogoWrapper} data-test="cle_channel_icon">
								{/* MTVW-831: "mode=box&w=176" -> "mode=box&w=480&h=270" */}
								{item?.logoUrl && <Img src={item?.logoUrl} params="mode=box&w=480&h=270" onError={e => { logResourceLoadFailure(item?.logoUrl + "?mode=box&w=480&h=270", e) }} fallback={fallback} alt={item?.Name} className={classes.CleChannelLogo} />}
							</div>
							<div container className={classes.CleChannelData}>
								<div item className={classes.CleChannelData1}>
									<div className={classes.CleChannelData1Wrapper}>
										<Typography variant="subtitle2" data-test="cle_channel_name" style={{ fontSize: 13, fontFamily: "EuropaBold", paddingRight: 5, WebkitUserSelect: "none !important" }}>
											{/*{import.meta.env.VITE_DEBUG && `${i.LogicalChannelNumber} - `}*/}
											{item?.Name}
										</Typography>
										<Button aria-label="" className={classes.CleIcon} style={{ marginTop: -4 }} >
											{/* // icon does not scale when zooming on Safari
												<DragIcon style={{ fontSize: 18, paddingBottom: 2 }} />
												*/}
											<LightTooltip title="Sender verschieben">
												<img src={DragIcon} style={{ width: 22, marginTop: 8 }} alt="drag"></img>
											</LightTooltip>
										</Button>
									</div>
								</div>
								<div item className={classes.CleChannelData2}>
									<div className={classes.CleChannelData2Wrapper}>
										{/* MTVW-464: No HD badge
										<img className={classes.CleIcon} src={HdIcon} alt="" style={{ width: 22, visibility: item?.IsHD === true ? "visible" : "hidden" }} >
										</img>
										*/}
										<img className={classes.CleIcon} src={HdIcon} alt="" style={{ width: 22, visibility: "hidden" }} >
										</img>
										<Button aria-label="" className={classes.CleIcon} /*style={{ marginTop: -0 }}*/ onClick={() => page.remove(item.index)} data-test="list_channel_delete_channel">
											{/* // icon does not scale when zooming on Safari
												<DeleteIcon style={{ fontSize: 18 }} />
												*/}
											<LightTooltip title="Sender löschen">
												<img src={DeleteIcon} style={{ width: 22, marginBottom: -4, marginRight: -4 }} alt="delete"></img>
											</LightTooltip>
										</Button>
									</div>
								</div>
							</div>
						</Grid>
					</ListItem>
				</div>
			)/*)*/
		}
	}
)

export const ListChannelPreview = CreateComponent(styles, false, true, ({ ...props }) => {
	const { item, classes, page, isDragging } = props
	//const opacity = isDragging ? 0 : 1
	const opacity = 0.5
	console.debug("preview: item %o, item.index %o, classes %o, page %o, isdragging %o", item, item.index, classes, page, isDragging)

	return (
		<div>
			<ListItem
				//ref={refElem}
				className={classes.CleListItem}
				data-test="channel_list_entry"
				//divider={divider}
				id={`${(CleConfig.listElemIdPrefix)}${item.index}`}
				style={{ opacity, /*top,*/ position: item.index > 0 ? /*"absolute"*/ "relative" : "relative" /*, paddingBottom: k === page.channelList.length - 1 ? 0 : 15*/ }}
			//data-test-selected={page.isCleEvent(i) ? true : false}
			>
				<Grid container justifyContent="center" className={classes.CleChannel}>
					<div container className={classes.CleNumbering} /*sx={{ width: "40px" }}*/>
						{/* No number in preview
						{item.index + 1}
            					*/}
					</div>
					<div item className={classes.CleChannelLogoWrapper} data-test="cle_channel_icon">
						{/* MTVW-503: Use Channels MS
						{item?.Pictures && <Img src={item?.Pictures.getLogo} params="mode=box&w=480&h=270" onError={e => { logResourceLoadFailure(item?.Pictures.getLogo + "?mode=box&w=480&h=270", e) }} fallback={fallback} alt={item?.Name} className={classes.CleChannelLogo} />}
						*/}
						{/* MTVW-831: "mode=box&w=176" -> "mode=box&w=480&h=270" */}
						{item?.logoUrl && <Img src={item?.logoUrl} params="mode=box&w=480&h=270" onError={e => { logResourceLoadFailure(item?.logoUrl + "?mode=box&w=480&h=270", e) }} fallback={fallback} alt={item?.Name} className={classes.CleChannelLogo} />}
					</div>
					<div container className={classes.CleChannelData}>
						<div item className={classes.CleChannelData1}>
							<div className={classes.CleChannelData1Wrapper}>
								<Typography variant="subtitle2" data-test="cle_channel_name" style={{ fontSize: 13, fontFamily: "EuropaBold", paddingRight: 5, WebkitUserSelect: "none !important" }}>
									{/*{import.meta.env.VITE_DEBUG && `${i.LogicalChannelNumber} - `}*/}
									{item?.Name}
								</Typography>
								<Button aria-label="" className={classes.CleIcon} style={{ marginTop: -4 }} >
									{/* // icon does not scale when zooming on Safari
												<DragIcon style={{ fontSize: 18, paddingBottom: 2 }} />
												*/}
									<LightTooltip title="Sender verschieben">
										<img src={DragIcon} style={{ width: 22, marginTop: 8 }} alt="drag"></img>
									</LightTooltip>
								</Button>
							</div>
						</div>
						<div item className={classes.CleChannelData2}>
							<div className={classes.CleChannelData2Wrapper}>
								{/* MTVW-464: No HD badge
											<img className={classes.CleIcon} src={HdIcon} alt="" style={{ width: 22, visibility: item?.IsHD === true ? "visible" : "hidden" }} >
											</img>
											*/}
								<img className={classes.CleIcon} src={HdIcon} alt="" style={{ width: 22, visibility: "hidden" }} >
								</img>
								<Button aria-label="" className={classes.CleIcon} /*style={{ marginTop: -0 }}*/ onClick={() => page.remove(index)} data-test="list_channel_delete_channel">
									{/* // icon does not scale when zooming on Safari
												<DeleteIcon style={{ fontSize: 18 }} />
												*/}
									<LightTooltip title="Sender löschen">
										<img src={DeleteIcon} style={{ width: 22, marginBottom: -4, marginRight: -4 }} alt="delete"></img>
									</LightTooltip>
								</Button>
							</div>
						</div>
					</div>
				</Grid>
			</ListItem>
		</div>
	)
})

const channelSource = {
	beginDrag(props, monitor, component) {
		//console.debug("beginDrag ix %s id %s ch %o mon %o comp %o", props.index, props.listId, props.channel, monitor, component)
		// enable scrolling while dragging in channel list
		scrollHelper.addScrollMonitor("channelListId")
		return {
			index: props.index,
			listId: props.listId,
			channel: props.channel,
			page: props.page,
			item: props.item
		}
	},

	endDrag(props, monitor, component) {
		const item = monitor.getItem()
		const dropResult = monitor.getDropResult()

		// deregister event handler
		scrollHelper.removeScrollMonitor()

		if (dropResult && dropResult.listId !== item.listId) {
			//props.removeChannel(item.index)
		}
	},

	isDragging(props, monitor) {
		//console.debug("isDragging props %s item %s type %s", props.channel, monitor.getItem().channel, monitor.getItemType())
		return props.channel === monitor.getItem().channel
	}
}

const channelTarget = {
	hover(props, monitor, component) {
		//console.debug("hover props %o monitor %o comp %o item %o", props, monitor, component, monitor.getItem())
		const item = monitor.getItem()
		//console.debug("item %o", item)
		let dragIndex = item.index
		let hoverIndex = props.index
		const sourceListId = item.listId

		//console.debug("listId %s sourceId %s page %o", props.listId, sourceListId, props.page)
		// check whether we drag from the grid
		if (sourceListId !== 0) {
			// add a temporary channel, if it does not exist yet
			props.page.addChannel(item.channel)
			dragIndex = props.page.getIndex(item.channel)
			monitor.getItem().index = dragIndex
			props.page.setDragChannel(item.channel)
			//monitor.getItem().isDragging = true
			//monitor.isDragging = true
		}

		// Don't replace items with themselves
		if (dragIndex === hoverIndex) {
			return
		}

		const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()//.top + window.scrollY
		//console.debug("top %s height %s width %s dom %o byId", hoverBoundingRect.top, hoverBoundingRect.height, hoverBoundingRect.width, hoverBoundingRect, document.getElementById(`${(CleConfig.listElemIdPrefix)}${(dragIndex)}`))

		// Get vertical middle
		const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

		// Determine mouse position
		const clientOffset = monitor.getClientOffset()

		// Get pixels to the top
		const hoverClientY = clientOffset.y - hoverBoundingRect.top

		// Only perform the move when the mouse has crossed half of the item's height
		// When dragging downwards, only move when the cursor is below 50%
		// When dragging upwards, only move when the cursor is above 50%

		// Dragging downwards
		if (dragIndex < hoverIndex && hoverIndex - dragIndex < 1 && hoverClientY < hoverMiddleY) {
			return
		}

		// Dragging upwards
		if (dragIndex > hoverIndex && dragIndex - hoverIndex < 1 && hoverClientY > hoverMiddleY) {
			return
		}

		// Time to actually perform the action
		if ((props.listId === sourceListId) || (sourceListId === 1)) {
			//console.debug("listId %s id %s page %o", sourceListId, item.channel, props.page)
			props.moveChannel(dragIndex, hoverIndex)
			// Note: we're mutating the monitor item here! Generally it's better to avoid mutations,
			// but it's good here for the sake of performance to avoid expensive index searches.
			monitor.getItem().index = hoverIndex
		}
	},
	drop(props, monitor, component) {
		//console.debug("dropped")
		props.page.setDragChannel(-1)
	}
}

/*
export default flow(
	DropTarget(["LIST_CHANNEL", "GRID_CHANNEL"], channelTarget, (connect, monitor) => ({
		connectDropTarget: connect.dropTarget()
	})),
	DragSource("LIST_CHANNEL", channelSource, (connect, monitor) => ({
		connectDragSource: connect.dragSource(),
		connectDragPreview: connect.dragPreview(),
		isDragging: monitor.isDragging()
	}))
)(ListChannel)
*/
//export default ListChannel
export default ListChannelWithHook
