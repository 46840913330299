/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-09
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-11
 */

import { TraxisAbstract } from "store/api/TraxisAbstract"
import { ChannelLists } from "store/model/traxis/ChannelList"
import { rootStore } from "store/RootStore"

export class GetChannelLists extends TraxisAbstract {
	//_onStartCreate = true
	//_onStartRemove = true

	get _model() {
		return ChannelLists
	}

	_getHashReq(data) {
		return `GetChannelLists_${this.profileId}`
	}

	// MTVW-657: predefined channellists
	_addPredefinedLists(data) {
		// marker in order that we can generate a divider before the first predefined channel list
		let firstPredefined = true
		rootStore.sso.authResult?.settings?.channelLists.forEach(item => {
			//console.debug("_addPredefinedLists", data.ChannelLists, data, rootStore.sso.authResult?.settings?.channelLists, rootStore.page.MsEpg.channels)
			const channels = []
			// include only activated channel lists
			if (item.activated) {
				for (let i = 0; i < item?.channelIds?.length; i++) {
					if (rootStore.page.MsEpg.getChannel(item.channelIds[i]))
						channels.push({ id: item.channelIds[i] })
					//else console.debug("NOT subscribed", item.channelIds[i])
				}
				console.debug("predefined channels=", item?.channelIds?.length, channels.length)
				if (channels.length > 0) {
					try {
						data.ChannelLists.ChannelList.push({ id: item.id, Name: item.name, ProfileId: "predefined", IsPersonal: false, Channels: { resultCount: channels.length, Channel: channels }, firstPredefined: firstPredefined })
						data.ChannelLists.resultCount++
						firstPredefined = false
					}
					catch (e) {
						console.error("_addPredefinedLists", e)
					}
				}
			}
		})
	}

	_getParseResp(data) {
		// MTVW-657: predefined channellists
		this._addPredefinedLists(data)
		return data.ChannelLists
	}

	getXmlReq() {
		return `<?xml version="1.0" encoding="utf-8"?>
<Request xmlns="urn:eventis:traxisweb:1.0">
	<Identity>
		<ProfileId>${this.profileId}</ProfileId>
	</Identity>
	<RootRelationQuery relationName="ChannelLists">
		<Options>
			<Option type="Props">Name,ProfileId,IsPersonal</Option>
			<Option type="Sort">Name</Option>
		</Options>
		<SubQueries>
			<SubQuery relationName="Channels">
				<Options>
				   <Option type="Props">PersonalChannelNumber,CustomProperties,IsViewableOnCpe</Option>
					<Option type="Sort">PersonalChannelNumber</Option>
					<Option type="Filter"><![CDATA[IsAdult==false]]></Option>
				</Options>
			</SubQuery>
		</SubQueries>
	</RootRelationQuery>
</Request>`
	}
}
