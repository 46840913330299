// l10n status: partial
import { Button, Link, Snackbar, Typography, CircularProgress } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { CreateComponent } from "components/utils/CreateComponent"
import { RouteLink } from "components/utils/RouteUtils"
import React, { useEffect, useState } from "react"
import { MovieTile } from "scenes/Recordings/components/MovieTile"
import { ROUTE_NAMES } from "site-ql/RouteConst"
import { rootStore } from "store/RootStore"
import { RecordingsSpaceInfo } from "./components/RecordingsSpaceInfo"
import { useParams } from "react-router"
// MTVW-76 import { RecordingsEmpty } from "scenes/Recordings/components/RecordingsEmpty"
//import { fade } from "@mui/material/styles"
import { useHistory, useLocation } from "react-router-dom"
import { l10n } from "store/lang/L10n"

// MTVW-181
import './recordingList.css'

// eslint-disable-next-line no-unused-vars
let lastSection = ""

const infoRecordingSections = {
	"oRecSingle": {
		name: "ALLE EINZELAUFNAHMEN",
		route: ROUTE_NAMES.Recordings,
	},
	"oRecSeries": {
		name: "ALLE SERIENAUFNAHMEN",
	},
	"oRecPlanned": {
		name: "ALLE GEPLANTE AUFNAHMEN",
	},
}

const styles = theme => ({
	// MTVW-181 only MaterialUI classes
	/* classes below are dedicated to delete recordings page*/
	rootSnackbar: {
		//width: theme.themeExtendedWidth,
		width: "100%",
		background: theme.palette.secondary.main,
		bottom: 0,
		[theme.breakpoints.down("xs")]: {
			right: 0,
			left: 0,
			bottom: 0,
			width: "100%",
			//height: "90px",
			alignItems: "flex-end"
		},
	},
	rootButton: {
		margin: "0 12px",
	},
	backButtonText: {
		padding: 0,
	},
})

//MTVW-181 ok
const RecordingsDeleteNav = CreateComponent(null, false, false, ({ classes, isDelete, selectAll }) => (
	<div className={"recordingsDeleteNav"}>
		{isDelete &&
			<Button size="medium" className={"selectAllRecordingButton"} onClick={() => selectAll()}>
				{l10n.commonActionSelectAll}
			</Button>
		}
		{!isDelete &&
			<RouteLink Component={Link} params={{ isDelete: "delete" }}>
				<Button className={"recordingsIconDelete"} data-test="confirm">
					BEARBEITEN
				</Button>
			</RouteLink>}
	</div>
))

//MTVW-181 ok
const RecordingsDeleteSnackbar = CreateComponent(null, false, false, ({ classes, deleteNumber, handleCancelButton, handleOkButton }) => {
	return (
		<Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			//MTVW-181 className={classes.recordingsBottomMenu + " CleBottomMenuWrapperResponsive"}
			classes={{ root: classes.rootSnackbar }}
			open={true}>
			<div className={"recordingsBottomMenuWrapper "}>
				<div className={"recordingDeleteBottomButtons"} >
					{/* MTV-3691: Safari issue. Don't need RouteLink, routing is managed in handleCancelButton with browser history  */}
					{/* <RouteLink Component={Link} params={{ isDelete: void 0 }}> */}
					<Button size="large"
						classes={{ root: classes.rootButton }}
						className={"recordingsBottomMenuButton"}
						onClick={handleCancelButton}>
						{l10n.commonActionCancel}
					</Button>

					<Button
						disabled={deleteNumber === 0}
						// eslint-disable-next-line jsx-a11y/no-autofocus
						autoFocus variant="contained"
						classes={{ root: classes.rootButton }}
						className={"recordingsBottomMenuButtonPrimary"}
						onClick={handleOkButton}>
						{l10n.commonActionDelete}
					</Button>
				</div>
				<div className={"recordingDeleteBottomMessage"} >
					<Typography variant="subtitle1" className={"recordingsBottomMenuItem"}>
						{deleteNumber === 1 && `${deleteNumber} Aufnahme ausgewählt`}
						{deleteNumber > 1 && `${deleteNumber} Aufnahmen ausgewählt`}
					</Typography>
				</div>
			</div>
		</Snackbar>
	)
})

//MTVW-181 ok
const RecordingsLoading = CreateComponent(null, false, true, ({ classes, source }) => (
	<>
		{source.isLoading && (
			<div className={"recordingsLoadingWrapper"}>
				<CircularProgress size={100} />
			</div>
		)}
	</>
))

// MTVW-212
let isRecordDeleted = false

const RecordingsBody = CreateComponent(styles, false, true, ({ classes, page }) => {
	const { type, isDelete, serieId } = useParams()
	const [tDeleteMark, setDeleteMark] = useState([])

	let history = useHistory();
	let location = useLocation()

	const toggleDeleteMark = id => {
		tDeleteMark.includes(id) ? setDeleteMark(tDeleteMark.filter(i => i !== id)) : setDeleteMark([...tDeleteMark, id])
	}
	// MTVW-124 scroll on top only at first loading
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	// fix issue in MTV-3691 but fixed in MTV-2768a
	// useEffect(() => {
	// 	removeDeleteMarks()
	// }, [isDelete])

	// MTV-212 added for a better refresh of deleting of selected element
	// the selection is removed only after the refresh of the page
	// to better synchronize the deletion of the records with the deselection
	// fixes a weird ui behavior
	useEffect(() => {
		if (isRecordDeleted) {
			removeDeleteMarks()
		}
	})

	const removeDeleteMarks = () => {
		// fix issue in MTV-3691 but fixed in MTV-2768a
		if (isDelete) {
			history.goBack()
		}
		setDeleteMark([])
		isRecordDeleted = false
	}

	const isRecList = page.recList?.isReady && page.recList?.DataArr?.length === 0 ? false : page.recList?.isReady ? true : null
	const isSeriesContainer = serieId !== void 0

	const selectAll = () => {
		if (tDeleteMark?.length === page.recList?.DataArr?.length) {
			setDeleteMark([])
		} else {
			let _recList = page.recList?.DataArr?.map(i => i.id)
			setDeleteMark([..._recList])
		}
	}

	const handleDeleteRec = () => {
		rootStore.page.Recordings.Manager.recordingManager.handleDeleteRecordings({ Ids: tDeleteMark }).then(() => {

			// MTVW-212 commented and added isRecordDeleted for a better refresh of the page 
			// page.refreshData()
			// removeDeleteMarks()

			isRecordDeleted = true
			page.refreshData() // the refresh data reload the page
		})
	}

	// MTVW-76 
	const goBackForEmptySection = () => {
		/**  MTVW-212 
		 * Need to check the path to know whether delete is active 
		 * 1) episodes of a series then the path is like http://localhost:3000/#/recordings/series/series_details/b81595bd-a2d1-45ee-be54-3ef78526f6e3
		 * 2) or recordings in a section then the path is like http://localhost:3000/#/recordings/series/
		*/
		let mainPath = location.pathname.substr(0, (location.pathname.indexOf(type) + type.length + 1))

		if (location.pathname !== mainPath) {
			// MTVW-212 refer to the comment above if case 1 go back to /recordings/series/
			// the url for the page should no longer be on the stack because the page doesn't exist anymore.
			history.replace(mainPath)
			history.goBack()
		} else {
			mainPath = location.pathname.substr(0, (location.pathname.indexOf(type)))
			history.push(mainPath);
		} 	// MTVW-212 no need to replace: refer to the comment above, if case 2 go to /recordings/
	}

	const actualSectionInfo = page.listName ? infoRecordingSections[page.listName] : infoRecordingSections[page.routeParams.type]

	return (
		<div className={"recordingsBody"}>
			{/* MTVW-181 NEVER-USED TO VERIFY: is this junk code? */}
			{/* <RecordingsFilter isFilterVisible={true} />
         <RecordingsSorting isSortVisible={page.isSortVisible} classes={classes} /> */}

			<div className={"recordingsGridListNarrow"}>
				<div className={"recordingsGridListHeader"}>
					<Typography variant="h6" onClick={() => {
						history.goBack()
					}}>
						<Button aria-label="" classes={{ text: classes.backButtonText }} data-test="recording_back">
							<ArrowBackIcon /><span className={"backLinkTxt"}>{l10n.commonActionBack}</span>
						</Button>
					</Typography>
					{/* MTV-2171 added title in the bar */}
					<div className={"recordingTitleSection"}>
						<Typography className="recordingTitleText" noWrap variant="h6">{isSeriesContainer ? page.recList.DataMain?.Name : (actualSectionInfo.name)}</Typography>
					</div>

					<RecordingsDeleteNav classes={classes} isDelete={isDelete} selectAll={selectAll} />
				</div>
				<div className={"recordingsGridContainer"}>
					{isRecList === false ? (
						goBackForEmptySection()
					) : (
						<div id="gridContainer" className={"recordingsGrid"}>
							{page.recList.DataArr.map(i => (
								<MovieTile
									movie={i}
									key={i.id}
									isShowEpisodeTitle={isSeriesContainer}
									isDelete={isDelete}
									isDeleteSelected={isDelete && tDeleteMark.includes(i.id)}
									//MTV-3138 for have the same result from the overview on planned series and expanded planned recordings on the same series
									// added routeProps for not to lose the planned state from expanded planned section
									routeProps={page.routeParams}
									deleteMarkCallback={isDelete ? () => toggleDeleteMark(i.id) : null}
								/>
							))}
						</div>
					)}
					<RecordingsSpaceInfo />
				</div>
			</div>
			{isDelete && <RecordingsDeleteSnackbar classes={classes} deleteNumber={tDeleteMark?.length} handleCancelButton={removeDeleteMarks} handleOkButton={handleDeleteRec} />}
			<RecordingsLoading classes={classes} source={rootStore.page.Recordings.Manager.recordingManager} />
		</div>
	)
})

export const RecordingsList = CreateComponent(null, false, false, () => {
	const page = rootStore.page.Recordings.ListPage.setRouteParams(useParams())

	useEffect(() => {
		if (page.listName) {
			lastSection = page.listName
		}
	}, [page.listName])

	useEffect(...page.getMountBody())
	return <RecordingsBody page={page} />
})
