import { Paper } from "@mui/material"
// MTV-2145
// import Tab from "@mui/material/Tab"
// import Tabs from "@mui/material/Tabs"
// import classnames from "classnames"
import { CreateComponent } from "components/utils/CreateComponent"
import React, { useState } from "react"
import { EpgList } from "./EpgList"
import { TvGuideChannelFilter } from '../../components/filterChannelInput/FilterChannelInput'
import { ChannelListMenu } from "components/ChannelListMenu"
import { useEffect } from "react"
import { rootStore } from "store/RootStore"


const styles = theme => ({
	Epg: {
		/* CHECK_MERGE */
		// position: "absolute",
		width: 380,
		minWidth: 380,
		flex: "1 1 auto",
		display: "flex",
		flexDirection: "column",
		padding: "5px 5px 0px 0px"
	},
	epgListWrapperScrollable: {
		flex: "1 1 auto",
		overflowY: "scroll",
		scrollbarWidth: "none",
		marginTop: 5,
		"&::-webkit-scrollbar": { display: "none" },
		height: "100%"
	},
	// MTV-2145
	// epgListWrapper: { color: theme.palette.text.primary, position: "relative", zIndex: 1, boxShadow: theme.shadows[5], backgroundColor: theme.themeSurfaceColor2 },
	epgListWrapper: {
		color: theme.palette.text.primary,
		position: "relative",
		// zIndex: 1, MTV-3063 Recording pop-up window was positioned for some windows size  behind the toolbar
		boxShadow: theme.shadows[5],
		backgroundColor: theme.themeSurfaceColor2,
		flex: "0 0 auto",
		display: "flex",
		height: "48px",


	},
	FilterChannel: {
		//paddingTop: "10px",
		flex: "1 1 ",
		display: "flex",
		backgroundColor: "transparent !important",
		cursor: "auto",
		borderRight: "2px solid #1a1a1a",
		maxWidth: 100,
	},
	epgChanneListMenu: {
		flex: "1 0 auto",
		backgroundColor: "transparent !important",
		cursor: "auto",
		textAlign: "right",
		alignSelf: "center",

	},
	NavBarItem: { margin: "0 0 0 16px", textTransform: "none", fontSize: "1rem", color: "#FFFFFF", opacity: 1, letterSpacing: 0.4, fontWeight: "normal" },
	NavBarItemSettings: { margin: 0 },
	NavBarItemLink: { "&:hover": { textDecoration: "none" } },
	ChannelListMenuContainerPosition: {
		left: "-42px", top: "-45px"
	}


	// epgListWrapperTabs: {
	// 	width: "100%", minWidth: 0,
	// },
	// MTV-2145
	// tab: { flex: "0 0 22%", minWidth: "22%", height: 50 },
	// FilterChannel: {
	// 	paddingTop: "10px",
	// 	flex: "0 0 32%",
	// 	minWidth: "32%",
	// 	backgroundColor: "transparent !important",
	// 	cursor: "auto"
	// }
})

export const EpgListWrapper = CreateComponent(styles, false, true, ({ page, classes, windowDimensions }) => {
	const [strFilterChannel, setStrFilterChannel] = useState("")
	const [filteredChannels, setFilteredChannels] = useState(null)

	//MTV-2401  the function is used from children epgList for re render if the channel in not visible in the list
	// eslint-disable-next-line no-unused-vars
	const [topPositionList, setTopPositionList] = useState(0)

	const changeFilterForChannelSearch = (filterStr) => {
		setStrFilterChannel(filterStr)
	}

	// MTVW-636: setFilteredChannels
	useEffect(() => {
		//console.debug("in useEffect", strFilterChannel, _filteredChannels(), page.epgArray)
		setFilteredChannels(_filteredChannels())
	}, [page.epgArray, strFilterChannel, rootStore.page.ChannelListRepo.ChannelLists, rootStore.page.ChannelListRepo.ChannelList])

	// MTVW-636: convert to function instead of const
	const _filteredChannels = () => {
		return strFilterChannel === "" ?
			page.epgArray
			:
			(page.epgArray.filter(channel => {
				return channel.Channel.Name.toLowerCase().replace(/ /gi, "").indexOf(strFilterChannel.toLowerCase().replace(/ /gi, "")) !== -1;
			}))
	}

	return (
		// (MTVW-754: height: "100vh")
		<Paper id="listAndFilterComponent" className={classes.Epg + " EpgListWrapper"} /*style={{ height: "100vh" }}*/ >

			<div id="filterList" className={classes.epgListWrapper} >
				<div id="filter_channel" className={classes.FilterChannel} data-test="livetv_filter">
					<TvGuideChannelFilter page={page} changeFilterForChannelSearch={changeFilterForChannelSearch} />
				</div>
				<div className={classes.epgChanneListMenu}>
					<ChannelListMenu classNames={classes} placement="bottom-end" />
				</div>
			</div>

			{
				page.isReady && (
					<div
						className={classes.epgListWrapperScrollable}
						/* CHECK_MERGE */
						//style={{ height: /* windowDimensions - 40 */ 'calc(100vh - 145px)' }}
						ref={el => page.setRefElemScroll(el)}
						onScroll={() => page.setVerticalScroll()}
						data-test="livetv_channellist"
					>

						{/* {page.epgTabName === "epgPrev" && <EpgList filteredChannels={filteredChannels} page={page} key="prev" divider={false} type="p" />} */}
						<EpgList filteredChannels={filteredChannels} page={page} key="curr" divider={true} type="c"
							setTopPositionList={setTopPositionList} windowDimensions={windowDimensions} />
						{/* {page.epgTabName === "epgNext" && <EpgList filteredChannels={filteredChannels} page={page} key="next" divider={false} type="n" />} */}
					</div>
				)
			}
		</Paper>
	)
})
