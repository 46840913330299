/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-09
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-10
 */

import { action, computed, observable, makeObservable } from "mobx"
import { ClassAbstract } from "store/ClassTools"
import { MinuteInMs, moment } from "store/util/moment"
import { rootStore } from "store/RootStore"
import { SourceTypeList } from "store/page/player/source/SourceTypeList"
//import { TIME_FORMAT_BACKEND } from "store/model/Time"

//MTVW-509
// const STEPS = 300
// from material UI documentation: We recommend (max - min) to be evenly divisible by the step.  
const STEPS = 400

const helperFormatTime = time => time.format("h:*mm:ss", { trunc: true, stopTrim: "*" })
const nDash = '\u2013'

export class PlayerProgressBar extends ClassAbstract {
	positionStep = 0
	positionDone = true
	currenPositionMoment = 0
	loadingTimer = null

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			positionStep: observable,
			positionDone: observable,
			currentPlayTimeStamp: computed,
			eventStartTimeStamp: computed,
			durationInMinutes: computed,
			isVisible: computed,
			positionTimeStamp: computed,
			textBefore: computed,
			textAfter: computed,
			progressValue: computed,
			progressValueInPercents: computed,
			progressValueBufferInPercents: computed,
			progressValueLiveMarker: computed,
			scrollProps: computed,
			setPosition: action,
			setPositionDone: action,
			setState: action,
		})
	}

	/**
	 * @returns {import("store/page/player/source/SourceAbstract").SourceAbstract}
	 */
	get source() {
		// MTVW-444: display values for playerFg while playerBg loading
		if (this._parent.player.__parent.playerFg) return this._parent.player.__parent.playerFg._source
		else return this._parent
	}

	/**
	 * @returns {import("store/page/player/visualon/PlayerVo").PlayerVo}
	 */
	get player() {
		// MTVW-444: display values for playerFg while playerBg loading
		if (this._parent.player.__parent.playerFg) return this._parent.player.__parent.playerFg
		else return this._parent.player
	}

	get currentPlayTimeStamp() {
		if (this.positionDone === false) {
			//console.debug("currentPlayTimeStamp a %s", moment(this.eventStartTimeStamp + (this.durationInMinutes * MinuteInMs * this.positionStep) / STEPS).valueOf())
			return moment(this.eventStartTimeStamp + (this.durationInMinutes * MinuteInMs * this.positionStep) / STEPS).valueOf()
		}
		//console.debug("currentPlayTimeStamp b %s", this.player.positionTimeStamp)
		return this.player.positionTimeStamp
	}

	get eventStartTimeStamp() {
		return this.source.Event?.AvailabilityStartDate
		// GT12 decision was to use the epg data (https://wiki.qlgroup.ch/pages/viewpage.action?spaceKey=TVS&title=2022.04.05)
		//console.debug("event sessionStart %s, %o", this.source.sessionStart, this.source.Event?.AvailabilityStartDate)
		//return this.source.sessionStart ? this.source.sessionStart : this.source.Event?.AvailabilityStartDate
	}

	get durationInMinutes() {
		return moment.duration(this.source.Event?.AvailabilityDurationInMinutes ?? 0, "minutes")
	}

	// GT12: MTVW-460, MTVW-502: Channels MS flags
	get isVisible() {
		// GT12
		//return this.source.Event != null && this.player?.isStreamLoaded === true
		// MTVW-444: display values for playerFg while playerBg loading
		if (this._parent.player.__parent.playerFg) return true
		if (!this.source.Event) return false
		const channel = rootStore.page.MsEpg.getChannel(this.source.Event?.ChannelId)
		//console.debug("isVisible", this.source.Event, this.source, channel, this.source.type, this.source.Event?.ChannelId)
		const positionMoment = this.currenPositionMoment !== 0 ? this.currenPositionMoment : this.source.Event?.AvailabilityEndDateTs
		const allowedSince = this.source.type === SourceTypeList.ContentPlaylist ? 0 :
			-10000 + rootStore.page.MsEpg?.replayOrRecordingAllowedSince(this.source.Event?.ChannelId, this.source.Event?.AvailabilityStartDateTs, this.source.Event?.AvailabilityEndDateTs, this.source.type === SourceTypeList.ContentPlaylist)
		if (channel?.hasForwardRewind && positionMoment > allowedSince) {
			return this.player?.isStreamLoaded === true
		}
		else if (channel?.hasForwardRewind && positionMoment <= allowedSince) {
			if (this.player.isLoading === false) {
				this.positionMoment = allowedSince
			}
			return false
		}
		else return false
	}

	get positionTimeStamp() {
		return moment(this.currentPlayTimeStamp)
			.diff(this.eventStartTimeStamp)
			.valueOf()
	}

	get textBefore() {
		if (this.player.isStreamLoaded) {
			return helperFormatTime(moment.duration(this.positionDone === false ? (this.durationInMinutes * this.positionStep) / STEPS : this.positionTimeStamp))
		} else {
			//MTV-3384 don't show data until the event is started
			return `${nDash}${nDash}:${nDash}${nDash}`
		}
	}

	get textAfter() {
		if (this.player.isStreamLoaded) {
			return helperFormatTime(this.durationInMinutes)
		} else {
			//MTV-3384 don't show data until the event is started
			return `${nDash}${nDash}:${nDash}${nDash}`
		}
	}

	get progressValue() {
		return Math.max(0, (this.currentPlayTimeStamp - this.eventStartTimeStamp) / this.durationInMinutes ?? 0)
	}

	get progressValueInPercents() {
		return this.getValueInPercents(this.progressValue)
	}

	getValueInPercents(value) {
		return Math.round(value * 100 * 100) / 100
	}

	get progressValueBufferInPercents() {
		//MTV-3478: We do not need the condition below. The value is in any case calculated and will provide proper values.
		// if (this.player.isStreamGrowing !== false) {

		// MTVW-103: Calculate the time difference between the beginning of the event and now
		const timeDiffStartToNow = moment().diff(moment(this.eventStartTimeStamp))

		// MTVW-103: If the time difference between the start
		// of the event and now is greater than the duration of the event, the event is in the past
		// 40000 is the offset time of 40 sec
		if ((timeDiffStartToNow - 40000 > this.durationInMinutes)) return 100


		return this.getValueInPercents(
			Math.min(this.player.rangeTimeStampEnd - this.eventStartTimeStamp, this._root.time.getTimeStamp() - 40000 - this.eventStartTimeStamp) / this.durationInMinutes ?? 0)


		// MTVW-103: The calculations in the code commented below produced an error for events longer than 12h
		// TODO: need to investigate to understand the reason for the error after 12 hours
		// return Math.min(
		// 	Math.max(
		// 		0,
		// 		this.getValueInPercents(
		// 			Math.min(this.player.rangeTimeStampEnd - this.eventStartTimeStamp, this._root.time.getTimeStamp() - 40000 - this.eventStartTimeStamp) / this.durationInMinutes ?? 0
		// 		)
		// 	),
		// 	100
		// )
		// }
		// return 100
	}

	get progressValueLiveMarker() {
		const timeDiffStartToNow = moment().diff(moment(this.eventStartTimeStamp))

		if ((timeDiffStartToNow - 40000 > this.durationInMinutes)) return 0

		return (Math.min(this.player.rangeTimeStampEnd - this.eventStartTimeStamp, this._root.time.getTimeStamp() - 40000 - this.eventStartTimeStamp) / this.durationInMinutes) * STEPS
	}

	get scrollProps() {
		let props = {
			max: STEPS,
			min: 0,
			step: 0.25
		}
		if (!isNaN(this.progressValue)) {
			if (this.positionDone === false) {
				props.value = this.positionStep
			} else {
				props.value = Math.round(this.progressValue * STEPS)
			}
		} else {
			props.value = 0
		}
		return props
	}

	setPosition(positionStep, positionDone = false) {
		console.log("@(positionStep=%o,positionDone=%o) .positionDone=%o", positionStep, positionDone, this.positionDone)
		// MTVW-444, MTVW-788, MTVW-787: choose source
		const cSource = this._parent.player.__parent.playerFg ? this._parent.player._source : this.source
		if (!cSource) return

		// GT12: MTVW-460, MTVW-502: Channels MS flags
		const allowedSince = cSource.type === "ContentPlaylist" ? 0 :
		/*-10000 +*/ rootStore.page.MsEpg?.replayOrRecordingAllowedSince(cSource.Event?.ChannelId, cSource.Event?.AvailabilityStartDateTs, cSource.Event?.AvailabilityEndDateTs, cSource.type === "ContentPlaylist")
		//console.debug("setPosition", moment(this.eventStartTimeStamp + (this.durationInMinutes * positionStep) / STEPS).utc().format(TIME_FORMAT_BACKEND), moment(allowedSince).utc().format(TIME_FORMAT_BACKEND))
		this.positionStep = positionStep
		if (moment(this.eventStartTimeStamp + (this.durationInMinutes * positionStep) / STEPS).utc().valueOf() <= allowedSince) {
			this.positionStep = (allowedSince - this.eventStartTimeStamp) * STEPS / this.durationInMinutes
		}
		this.setPositionDone(false)
		this.currenPositionMoment = moment(this.eventStartTimeStamp + (this.durationInMinutes * this.positionStep) / STEPS)
		//console.debug("currentPositionMoment", this.currenPositionMoment.utc())
		if (positionDone) {
			//console.debug("handleSetPosition positionStep", this.player.isLoading, this.positionStep)
			const positionMoment = moment(this.eventStartTimeStamp + (this.durationInMinutes * this.positionStep) / STEPS)

			//console.debug("PlayerProgressBar", cSource)
			if (this.player.isLoading === false) {
				cSource.handleSetPosition(positionMoment).then(() => {
					//console.debug("handleSetPosition done!")
					// TODO: handleSetPosition might return prematurely in case of setPositionTimeStamp for replay streams
				}).catch((err) => {
					console.error("PlayerProgressBar setPosition", err)
				}).finally(() => {
					this.setPositionDone(true)
					//console.debug("PlayerProgressBar finally")
				})
			}
			else {
				// MTVW-665: it happened if skip 10s is done multiple times followed by a drag of the slider while the player is still loading
				if (!this.loadingTimer) this.loadingTimer = setInterval(() => {
					if (this.player.isLoading === false) {
						console.debug("PlayerProgressBar handleSetPosition")
						cSource.handleSetPosition(positionMoment).then(() => {
							//console.debug("HIT loadingTimer")
							console.debug("PlayerProgressBar then")
						}).catch((err) => {
							console.error("PlayerProgressBar setPosition", err)
						}).finally(() => {
							this.setPositionDone(true)
							clearInterval(this.loadingTimer)
							this.loadingTimer = null
							//console.debug("PlayerProgressBar finally")
						})
					}
				}, 1000)
			}
		} else {
			this.setPositionDone(false)
		}
		return false
	}

	setPositionDone(value) {
		this.positionDone = value
	}

	getTimeFormattedFromDuration() {
		//time => time.format("h:*mm:ss", { trunc: true, stopTrim: "*" })
	}

	getState() {
		return {
			positionStep: this.positionStep,
			positionDone: this.positionDone
		}
	}

	setState(state) {
		Object.keys(state).forEach(k => {
			this[k] = state[k]
		})
		return this
	}
}
