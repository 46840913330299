/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-07
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-09
 */

export const SourceTypeList = {
	ChannelLive: "ChannelLive",
	ChannelReplay: "ChannelReplay",
	ContentPlaylist: "ContentPlaylist",
	AdPlaylist: "AdPlaylist",
	Empty: "Empty"
}
