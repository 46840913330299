import { Typography, IconButton } from "@mui/material"
import LinearProgress from "@mui/material/LinearProgress"
import { ListItem } from "@mui/material"
import { CreateComponent } from "components/utils/CreateComponent"
import React, { useEffect, useState } from "react"
import { Img } from "components/Img"
import { EpgChannelDetailsToolsTitleSecondary } from "scenes/components/EpgChannelDetailsTools"
import fallback from "img/fallback/empty.png" // MTV-3740
import { RecordingIndicator } from "scenes/components/RecordingIndicator"
// import { logResourceLoadFailure } from "utils/BugsnagReporter"   MTV-3740
import infoIcon from 'img/icons/info.svg'

import { rootStore } from "store/RootStore"
import { SourceTypeList } from "store/page/player/source/SourceTypeList"
import { CALLING_CONTEXT_TYPE } from "store/page/OverlayEventDetails"
//import { flow, computed } from "mobx"

// MTVW-218
import "./epgChannel.css"
// MTVW-218: all css is in epgChannel.css. styles is not used anymore, it
// was passed as first parameter in CreateComponent for the EpgChannel
//const styles = theme => ({})

const LocalDurationPart = CreateComponent(null, false, true, ({ i, type }) => (
	/* MTVW-246
	<>{type === "n" ? `in ${i.Event.CurrentDurationToStartInMinutes} Min. ` : i.Title.DurationInMinutes !== null && `${i.Event.DurationInMinutes} Min. `}</>
	*/
	<>{type === "n" ? `in ${i.Event.CurrentDurationToStartInMinutes} Min. ` : i.Event.DurationInMinutes !== null && `${i.Event.DurationInMinutes} Min. `}</>
))

// commented in MTV-3580, put below LinearProgress component directly inside the main component for  
// completing the fix of the problem in MTV-3012
// const LocalLinearProgress = CreateComponent(null, false, true, ({ i }) => <LinearProgress variant="determinate" value={i.Event.CurrentDurationInPercents} data-test="livetv_progressbar" />)

export const EpgChannel = CreateComponent(null, false, true, ({ i, divider, classes, type, page, top }) => {
	//console.debug(">>> %s", i.Channel?.id)
	//let channelSelect = page.player.source.id === i.id && page.isEpgEvent(i) //MTV-3580 added page.isEpgEvent for deep control

	// MTV-3580
	// the channelList and the player with info are not yet synchronized
	// the new channel is selected in the ChannelList but the data in the 
	// player and in the details are still those of the previous channel
	// start a spinner so that the user sees that an action has beens started
	//MTV-3836 comment the code below to eliminate warning. Use useEffect as suggested from react documentation
	// if ((page.player.source.id !== i.id) && page.isEpgEvent(i)) {
	// 	page.player.player.setIsLoading(true)
	// }

	const miniEpgPage = rootStore.page.MiniEpg

	useEffect(() => {
		// MTVW-246
		if ((page.player.source.id !== i.Channel?.id) && page.isEpgEvent(i)) {
			page.player.player.setIsLoading(true, "EpgChannel useEffect")
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page.player.source.id, i.Channel?.id, page.isEpgEvent(i)]);


	// true if the channel in the player is the active channel and is in the list
	// MTVW-795: converted to function
	const channelSelect = () => {
		//if (isSelected) console.debug("channelSelect", isSelected, i.Channel?.id)
		//return computed(() => {
		const isSelected = page.isEpgEvent(i) && !page.player.fastChannelId || (page.player.fastChannelId === i.Channel?.id)
		return isSelected
		//}).get()
	}
	//const [lastChange, setLastChange] = useState({ ch: "", t: Date.now() })

	// MTVW-795: converted to function
	const isChannelSelected = () => {
		//if (page.player.fastChannelId) console.debug("fastChannelId", page.player.fastChannelId)
		//if (page.player.fastChannelId === i.Channel?.id) console.debug("fastChannelId", page.player.fastChannelId)
		//return (page.player.fastChannelId === i.Channel?.id) ? true : (rootStore.page.Player.source.type === SourceTypeList.ChannelReplay) && (page.player.source.id === i.Channel?.id)
		//console.debug("isChannelSelected", (rootStore.page.Player.source.type === SourceTypeList.ChannelReplay) && (page.player.source.id === i.Channel?.id), (page.player.source.id === i.Channel?.id), channelSelect())
		//return computed(() => {
		//if (page.player.source.id === i.Channel?.id) console.debug("isChannelSelected", (rootStore.page.Player.source.type === SourceTypeList.ChannelReplay) && (page.player.source.id === i.Channel?.id), channelSelect(), classForLogoContainer())
		return (rootStore.page.Player.source.type === SourceTypeList.ChannelReplay) && (page.player.source.id === i.Channel?.id)
		//}).get()
	}

	const nDash = '\u2013'

	//console.debug("====================== load channel name= %o at position top=%o% is selected =%o", i.Channel.Name, top, page.isEpgEvent(i))
	// MTVW-218
	const classForLogoContainer = () => {
		//return computed(() => {
		return "ChannelListLogoContainer " + (channelSelect() ?
			rootStore.page.Player.source.type === SourceTypeList.ChannelReplay ? " EpgChannelSelected " : ""
			: "")
		//}).get()
	}

	let result = (
		<ListItem
			data-test="livetv_channel_entry"
			test-channelname={i.Channel?.Name}
			divider={divider}
			style={{ top }}
			key={i.Channel?.id}
			id="EpgChannelWrapper" // need to use id to overwrite the MaterialUI property
			className={channelSelect() ?
				isChannelSelected() ? "EpgChannelNotSelected" : "EpgChannelSelected"
				: "EpgChannelNotSelected"}

			// className={`${classes.EpgChannelWrapper}
			// ${channelSelect ?
			// 		// MTV-3580 added && (page.player.source.id === i.id) 
			// 		// fixed the problem when changing channel in case the previous channel is in replay 
			// 		(rootStore.page.Player.source.type === SourceTypeList.ChannelReplay) && (page.player.source.id === i.id) ? "" : classes.EpgChannelSelected
			// 		: ""} 
			// `}
			// data-test-selected={page.isEpgEvent(i) ? true : false}
			data-test-selected={channelSelect()}
		>
			{/*** MTV-2401 
				TO CECK : still need EpgChannelUnavailable,IsViewableOnCpe or dead code?
				class for ListItem:
				
				className={`${classes.EpgChannelWrapper} 
						${i.Channel.IsViewableOnCpe ? "" : classes.EpgChannelUnavailable} 
						${page.isEpgEvent(i) ?
						rootStore.page.Player.source.type === SourceTypeList.ChannelReplay ? "" : classes.EpgChannelSelected
						: ""} 
						${classes["EpgChannelType_" + type]
					}`} */}


			<div className={classForLogoContainer()}
				// rootStore.page.Player.source.type === SourceTypeList.ChannelReplay ? classes.EpgChannelSelected : ""
				// : ""} 
				// }
				data-test={i.Channel.Name}
				onClick={(e) => {
					e.stopPropagation()
					miniEpgPage.setShow(true, i.Channel, CALLING_CONTEXT_TYPE.TvLive)
					//page.player.handleChangeChannel()
				}}>
				{/*console.debug(">>> %s, %s", i.Event.Title.Name, i.Channel.logoUrl)*/}
				{/* MTVW-246: i.Channel.Pictures*/ i.Channel?.logoUrl
					?
					// MTVW-246: <Img src={i.Channel.Pictures.getLogo}
					<Img src={i.Channel?.logoUrl}
						// MTV-3740: commented for fix error on logo in channelList
						// MTVW-246: enabled again, no obvious reason to disable
						// MTVW-831: "mode=box&w=176" -> "mode=box&w=480&h=270"
						params="mode=box&w=480&h=270"
						fallback={fallback}
						//hideBefore={true}
						// {/* onError = { e => {logResourceLoadFailure(i.Channel.Pictures.getLogo + "?mode=box&w=480&h=270", e)}} */}
						alt={i.Channel?.Name} className="EpgChannelLogo" />
					:
					<div className="EpgChannelLogo"></div>}
			</div>

			<div className="ChannelListDetailContainer"
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
					// MTVW-795: Moved handleChangeChannel from this module to Player
					page.player.handleChangeChannel(i.Channel.id)
				}}
			>
				<div className="ChannelListDetailTop">
					<div className="ChannelListDetailTitle">
						<Typography noWrap={true} variant="overline" data-test="livetv_channel_name"  >
							{import.meta.env.VITE_DEBUG && `${i.Channel.LogicalChannelNumber} - `}
							{i.Channel?.Name}
						</Typography>

						{/* eslint-disable-next-line no-constant-condition */}
						{/* MTV-2401 class RecordingIndicator newer used 
						 <Typography variant="body2" className={`${classes.EpgChannelEventTitle} ${false ? "RecordingIndicator" : ""}`} data-test="livetv_title">  */}

						<Typography variant="body2" className="EpgChannelEventTitle" data-test="livetv_title">
							{/* MTVW-246
							{i.Title.Name}
							*/}
							{i.Event.Title.Name}
						</Typography>
					</div>

					<div className="ChannelListDetailInfo"
						onClick={(e) => {
							e.stopPropagation()
							page._pageStore.OverlayEventDetails.setEpgEventById(i.Event.id).then(() => {
								page._pageStore.OverlayEventDetails.setComponentType('event').setShow(true, CALLING_CONTEXT_TYPE.TvInfo)
							})
							//rootStore.page.MsEpg.getEventDetailsAsync(i.Event.id)
						}
						}>
						<IconButton data-test="channel_info" >
							<img src={infoIcon} alt="info program" />
						</IconButton>

					</div>
				</div>

				<div className="ChannelListDetailBody">
					<div className="ChannelListDetailEvent"	>
						<RecordingIndicator eventId={i.Event.id} /* seriesId={i.Title.SeriesId} */ showText={false} />
						<Typography variant="caption" noWrap={true} data-test="livetv_show_episode">
							<EpgChannelDetailsToolsTitleSecondary /*innerWidth={1000} widthBreak={800}*/ oTitle={i.Event.Title} />
						</Typography>
					</div>

					{/* MTV-1796: SeriesID would also display canceled series events */}

					<div className="ChannelListDetailEventTime">
						<Typography variant="caption" data-test="livetv_duration">
							<LocalDurationPart i={i} type={type} />
						</Typography>
						<Typography variant="caption" data-test="livetv_air_time">
							{i.Event.AvailabilityStartDateFormat("HH:mm")}{nDash}{i.Event.AvailabilityEndDateFormat("HH:mm")}
						</Typography>
					</div>
				</div>

				<div className="ChannelListDetailBar">
					{/* TODO: item inside the div probable junk code...check it out
					<div item className={classes.LocalLinearProgressResponsive}></div> */}
					{type === "c" && (
						<div className="LocalLinearProgressResponsive">
							{/* <LocalLinearProgress i={i} /> */}
							<LinearProgress variant="determinate" value={i.Event.CurrentDurationInPercents} data-test="livetv_progressbar" />
						</div>
					)}
				</div>
			</div>
		</ListItem>
	)

	// MTV-3012: Keep a snapshot of the selected EPG item until playback of the current show is finished.
	// Note that Live playback has a typical latency of 40 seconds
	// GT12 MTVW-487: seconds precision
	//if (channelSelect && page.player.player.positionTimeStamp60s) {
	if (channelSelect() && page.player.player.positionTimeStamp) {
		//console.debug("EpgChannel %o %o", page.player.player.positionTimeStamp60s, i.Event?.isTimeInEventAvailability(page.player.player.positionTimeStamp60s))
		//if (page.player.player.positionTimeStamp60s && !i.Event?.isTimeInEventAvailability(page.player.player.positionTimeStamp60s) && (page.memoSelectedEpg !== null)) {
		if (page.player.player.positionTimeStamp && !i.Event?.isTimeInEventAvailability?.(page.player.player.positionTimeStamp) && (page.memoSelectedEpg !== null)) {
			// current playback not yet finished keep saved item to synch EPG with end of playback
			// MTVW-811: only for SourceTypeList.ChannelLive
			if ((rootStore.page.Player.source.type === SourceTypeList.ChannelLive) && (page.player.source.id === i.Channel?.id)) result = page.memoSelectedEpg
		}
		else {
			// make a snapshot of the current selected item
			page.memoSelectedEpg = result
		}
	}

	return result
})
