import { flow, observable, computed, action, makeObservable, runInAction } from "mobx"
import { TraxisModel } from "store/api/TraxisModel"
import { ClassAbstract } from "store/ClassTools"
import { rootStore } from "store/RootStore"
import { moment } from "store/util/moment"
import { PlayerProgressBar } from "../PlayerProgressBar"
//import { MediaService } from "store/qlapi/MediaService"
import { StreamPlayList } from "store/api/StreamPlayList"
import { lazyObject } from "store/ModelTools"
import { TIME_FORMAT_BACKEND } from "store/model/Time"
import { serviceUrls } from "store/qlapi/ServiceUrls"
// avoid circualar dependency when importing SourceAdPlaylist
import { SourceTypeList, SourceAdPlaylist, AdState, ReplayAd, LinearAd, FastFwdAd } from "./InternalImports"
import { logLevel } from "store/qlapi/LogService"
import { appFeatures } from "AppFeatures"
import { cDebug } from "utils/TestSupport"
import { isSafari } from "utils/Utils"
import { controlsTimerHandler } from "scenes/Player/Player"
import { Wait } from "store/util/Wait"
import { ErrorBase } from "store/ErrorBase"

export class SourceAbstract extends ClassAbstract {
	id = null

	oCreatePlaylist = new TraxisModel()
	// MTV-3570: use new StreamPlayList instead of StreamPlayListAbstract, since we don't need
	// traxis' CreatexxxPlaylist anymore since media microservice v008
	//@observable playlist = null
	playlist = lazyObject(this, "playlist", StreamPlayList)

	GetEvent = null

	progressBar = new PlayerProgressBar(this, "progressBar")

	isLoading = true

	// GT12 properties
	sessionId = null
	pauseAd = null
	trueReplaySession = false
	restartDone = false
	replayAdInfo = null
	fastForwardAdInfo = null
	playAfterAd = () => { }
	sessionAdZones = null
	adsService = rootStore.adsService
	playEventActive = false
	adZone = null
	adZoneFetchPosiiton = null
	sessionStart = null
	sessionEnd = null
	adPlayStart = 0
	adStartPosition = 0
	adEndPosition = 0
	adPauseStart = 0
	disablePauseAd = false
	adState = new AdState()
	interactionActive = false
	debounceEvent = false
	markerPosD = null // indicates marker position D, if !null prevent display of buttons
	timeoutId = null
	msEpg = rootStore.page.MsEpg
	contentPlayerName = null
	// MTVW-788, MTVW-787: _player for parent constructors
	_player = null
	// MTVW-444, MTVW-788, MTVW-787
	_waitPreload = new Wait(this, "preload")
	static capturedVideoImage = null

	// invoked after playlist is loaded,
	// allows to modify the stream position before openStreamAsync() of PlayerVo is called
	playlistLoadedCallback = () => { }

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			id: observable,
			oCreatePlaylist: observable,
			playlist: observable,
			GetEvent: observable,
			progressBar: observable,
			isLoading: observable,
			Event: computed,
			_$Channel: observable,
			rangeEventMinTs: computed,
			rangeEventMaxTs: computed,
			setAsLoaded: action,
			// GT12
			pauseAd: observable,
			removePauseAd: action,
			checkPauseAd: action,
			setPauseAd: action,
			replayAdInfo: observable,
			setReplayAdInfo: action,
			setFfwdAdInfo: action,
			fastForwardAdInfo: observable,
			sessionAdZones: observable,
			pauseEvent: action,
			playStartEvent: action,
			playCompleteEvent: action,
			adZone: observable,
			adState: observable,
			skipAd: action,
			checkForLinearAd: action,
			adStampEvent: action,
			errorEvent: action,
			_checkEventAsync: action,
			_openStreamRollingBufferAsync: action
		})
		//console.debug("new SourceAbstact", this, parent)
	}

	// MTV-3570: set a lazy object here
	//mediaService = lazyObject(this, "mediaService", MediaService)
	mediaService = rootStore.mediaService

	// globals helper method
	/**
	 * @returns {import("store/page/player/visualon/PlayerVo.js").PlayerVo}
	 */
	get player() {
		//console.debug("_parent= %o", this._parent, this._parent.player, this._parent.player, this._parent._parent)
		// MTVW-788, MTVW-787: _player in source
		// Commented statement causes ads player issues
		//return this._player ? this._player : this._parent.player
		return this._parent.player
	}

	/**
	 * @returns {import("store/page/player/PlayerSettings").PlayerSettings}
	 */
	get settings() {
		return this._parent.settings
	}

	get Event() {
		// MTVW-487: replace traxis rootStore.api.GetEventByChannelTime with epgMS implementation
		/*
		if (this.GetEvent?.isInApi === true) {
			//console.debug("returning GetEvent.Data %o", this.GetEvent.Data)
			// MTVW-392
			if (this.GetEvent?.Data) {
				// convert to MS EventDetails
				return this.msEpg._convertTraxisEvent(this.GetEvent.Data)
			}
			return this.GetEvent.Data
		}
		//console.debug("returning GetEvent %o", this.GetEvent)
		*/
		return this.GetEvent
	}

	_$Channel = null

	get Channel() {
		return this._$Channel
	}

	set Channel(id) {
		if (id === null) {
			return (this._$Channel = null)
		}
		return (this._$Channel = rootStore.singleton.channelsResolveIdentifier(id))
	}

	// methods
	get isStreamUrl() {
		return !!(this.playlist !== null ? this.playlist.getStreamUrl : false)
	}

	get getStreamUrl() {
		return this.playlist !== null ? this.playlist.getStreamUrl : null
	}

	get getDrmScheme() {
		return this.playlist !== null ? this.playlist.getDrmScheme : null
	}

	get getStreamUrlLicense() {
		return this.playlist !== null ? this.playlist.getStreamUrlLicense : null
	}

	get getStreamUrlCertificate() {
		return this.playlist !== null ? this.playlist.getStreamUrlCertificate : null
	}

	get isPosterUrl() {
		return !!this?.Event?.Title?.Pictures?.getBoxCover
	}

	get getPosterUrl() {
		return this.isPosterUrl ? this.Event.Title.Pictures.getBoxCover : null
	}

	// MTVW-725
	videoSnapshot() {
		// DRM could create SecurityError
		try {
			const canvas = document.createElement('canvas')
			const videoElem = 'vjs_' + this.player.idDomPlayer + '_html5_api'
			console.debug("videoElem=", videoElem, this._parent, this.player.idDomPlayer)
			let video = document.getElementById(videoElem)
			if (import.meta.env.VITE_PLAYER === "vo") {
				//console.debug("vop-video", document.getElementsByClassName("vop-video")[0])
				video = document.getElementsByClassName("vop-video")[0]
			}

			if (video) {
				canvas.width = 1920
				canvas.height = 1080
				const ctx = canvas.getContext('2d')
				ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
				this.setCapturedVideoImage(canvas.toDataURL('image/jpeg'))
				//console.debug("image captured", SourceAbstract.capturedVideoImage = image)
			}
		}
		catch (err) {
			console.error("videoSnapshot", err)
		}
	}

	setCapturedVideoImage(image) {
		SourceAbstract.capturedVideoImage = image
	}

	get capturedVideoImage() {
		return SourceAbstract.capturedVideoImage
	}

	isSourceEqual(source) {
		console.error("@(source=%o) this=%o NEED IMPLEMENTATION", source)
		return null
	}

	// buttons disable state
	get isButtonLiveEnabled() {
		return false
	}

	get isButtonReplayEnabled() {
		return false
	}

	get isButtonJumpFwdEnabled() {
		return false
	}

	get isButtonJumpRwdEnabled() {
		return false
	}

	get isButtonPlayPauseEnabled() {
		return this.player.isStreamLoaded
	}

	get isButtonRecEnabled() {
		return this.player.isStreamLoaded
	}

	// for live, replay and recordings
	get isButtonTrackAudioEnabled() {
		return !this.player.isTracksAudioEmpty && this.player.isStreamLoaded
	}

	// for live, replay and recordings
	get isButtonTrackSubtitleEnabled() {
		return !this.player.isTracksSubtitleEmpty && this.player.isStreamLoaded
	}

	// for live, replay and recordings
	get isButtonFullScreenEnabled() {
		return true
	}

	// buttons visible state
	// GT12: MTVW-460, MTVW-502: Channels MS flags, for live and replay
	get isButtonLiveHidden() {
		return false
	}

	// GT12: MTVW-460, MTVW-502: Channels MS flags, for live and replay
	get isButtonReplayHidden() {
		//console.debug("hasStartOver %o, %o", this.msEpg?.getChannel(this.Event?.ChannelId)?.hasStartOver, this.msEpg?.getChannel(this.Event?.ChannelId))
		return !this.msEpg?.getChannel(this.Event?.ChannelId)?.hasStartOver || !this.msEpg?.isReplayAllowed(this.Event?.ChannelId, this.Event?.AvailabilityStartDateTs, this.Event?.AvailabilityEndDateTs)
	}

	// GT12: MTVW-460: Channels MS flags, for live and replay
	get isButtonJumpFwdHidden() {
		//console.debug("hasForwardRewind %o, %o", this.msEpg?.getChannel(this.Event?.ChannelId)?.hasForwardRewind, this.msEpg?.getChannel(this.Event?.ChannelId))
		return !this.msEpg?.getChannel(this.Event?.ChannelId)?.hasForwardRewind
	}

	// GT12: MTVW-460: Channels MS flags, for live and replay
	get isButtonJumpRwdHidden() {
		return !this.msEpg?.getChannel(this.Event?.ChannelId)?.hasForwardRewind
	}

	// GT12: MTVW-460: Channels MS flags, hasLivePause appies only to live
	get isButtonPlayPauseHidden() {
		return false
	}

	// GT12: MTVW-460: Channels MS flags, for live and replay
	get isButtonRecHidden() {
		//return !(this.msEpg?.getChannel(this.Event?.ChannelId)?.hasNPVR && this.msEpg?.isReplayAllowed(this.Event?.ChannelId, this.Event?.AvailabilityStartDateTs, this.Event?.AvailabilityEndDateTs))
		return !this.msEpg?.isRecordingAllowed(this.Event?.ChannelId, this.Event?.AvailabilityStartDateTs, this.Event?.AvailabilityEndDateTs)
	}

	// for live, replay and recordings
	get isButtonTrackAudioHidden() {
		return false
	}

	// for live, replay and recordings
	get isButtonTrackSubtitleHidden() {
		return false
	}

	get isButtonFullScreenHidden() {
		return false
	}

	get rangeEventMinTs() {
		return this.source.Event?.AvailabilityStartDate ? moment(this.source.Event?.AvailabilityStartDate).valueOf() : 0
	}

	get rangeEventMaxTs() {
		return this.source.Event?.AvailabilityEndDate ? Math.min(moment(this.source.Event?.AvailabilityEndDate).valueOf(), rootStore.time.getTimeStamp() - this.player.streamLiveDelay) : 0
	}

	get MARKER_B() {
		return "start"
	}

	get MARKER_C() {
		return "skipStart"
	}

	get MARKER_D() {
		return "end"
	}

	debounced() {
		// debounce potential multiple clicks
		if (this.debounceEvent) return false
		this.debounceEvent = true
		setTimeout(() => {
			this.debounceEvent = false
		}, 50)
		return true
	}

	isGt12Channel(channel) {
		const result = appFeatures.gt12Enabled && serviceUrls.isAdsMsAvailable && this.msEpg?.getChannel(channel)?.hasAlternativeAds
		//console.debug("isGt12Channel %s, %s", channel, result)
		return result
	}

	// only valid for GT12 channels
	isPremium(channel) {
		const result = appFeatures.gt12Enabled && serviceUrls.isAdsMsAvailable && this.msEpg?.getChannel(channel)?.hasAdSkipping
		return result
	}

	_isZoneInCurrentShow(adZone, marker) {
		return (adZone[marker] >= this.Event?.AvailabilityStartDateTs && adZone[marker] <= this.Event?.AvailabilityEndDateTs)
	}

	_firstZoneInCurrentShow(marker) {
		let firstZone = null
		this.sessionAdZones.adZones.forEach(i => {
			if (this._isZoneInCurrentShow(i, marker)) {
				if (!firstZone) firstZone = i
			}
		})
		return firstZone
	}

	hasAdZone(channel, marker) {
		//console.debug("hasAdZone %o, %o, %o, %o", this.isGt12Channel(channel), channel, this.type, this.sessionAdZones)
		if (this.isGt12Channel(channel) && this.type !== SourceTypeList.ChannelLive && this.sessionAdZones && this.sessionAdZones?.adZones?.length > 0) {
			let inCurrentShow = false
			this.sessionAdZones.adZones.forEach(i => {
				if (this._isZoneInCurrentShow(i, marker)) {
					inCurrentShow = true
					//console.debug("hasAdZone %s, %s", moment(i.start).utc().format(TIME_FORMAT_BACKEND), moment(i.end).utc().format(TIME_FORMAT_BACKEND))
				}
			})
			return inCurrentShow
		}
		else return false
	}

	nearestMarker(marker) {
		//console.debug("MARKER %o", marker)
		const currentPos = this.player?.positionTimeStamp
		let nearestZone = null
		let nearestDiff = Number.MAX_SAFE_INTEGER
		this.sessionAdZones.adZones.forEach(i => {
			if (this._isZoneInCurrentShow(i, marker)) {
				const diff = i[marker] - currentPos
				//console.debug("%o, %o", i, i[marker])
				if (diff > 0 && diff < nearestDiff) {
					nearestDiff = diff
					nearestZone = i
				}
			}
		})
		// TODO: fetch next by using this.sessionAdZones.nextPositionToCheck ?
		//return nearestZone ? nearestZone[marker] : this.sessionAdZones?.adZones[0][marker]
		const firstZone = this._firstZoneInCurrentShow(marker)
		return nearestZone ? nearestZone[marker] : firstZone ? firstZone[marker] : null
	}

	// player controls
	setPlayPause() {
		console.log("@")
	}

	startPauseAd() {
		this.adPauseStart = performance.now()
	}

	removePauseAd(closeInteraction = "resume") {
		if (this.pauseAd && this.adPauseStart !== 0) {
			//console.debug("PAUSE AD DISPLAYED %s", Math.round(performance.now() - this.adPauseStart))
			// GT12: special cases are handled in interactionEvent
			// closeInteraction: [ resume, close, changeAsset, interruption ] or "" for no interaction
			// error: [ adNotFound, other ] or ""  for no error
			this.adsService.sessionTrackingPauseAdAsync(this.sessionId, this.pauseAd.adsViewId, Math.round(performance.now() - this.adPauseStart), closeInteraction, "")
		}
		this.adPauseStart = 0
		this.pauseAd = null
	}

	// check for ad url and pre-fetch it (will be cached by the browser), error handling 
	checkPauseAd(pauseAd) {
		// MTVW-519: test purpose
		//pauseAd.adUrl = "https://quickline.ch/any.jpg"
		//pauseAd.adUrl = null
		//console.debug("checkPauseAd", pauseAd?.adUrl)
		if (!pauseAd?.adUrl) {
			this.pauseAd = null
			console.error(`no pauseAd.adUrl`)
			rootStore.logService.logAsync(logLevel.ERROR, `no pauseAd.adUrl`, "SourceAbstract")
		}
		else {
			fetch(pauseAd.adUrl).then(() => {
				runInAction(() => {
					if (this.player.isPaused) this.pauseAd = pauseAd
				})
			}).catch((e) => {
				runInAction(() => {
					this.pauseAd = pauseAd
				})
				console.error(`pauseAd fetch '${pauseAd.adUrl}' failed`, e)
				rootStore.logService.logAsync(logLevel.ERROR, `pauseAd fetch "${pauseAd.adUrl}" failed`, "SourceAbstract", e)
			})
		}
	}

	setPauseAd() {
		// GT12
		if (!this.__parent.isAdsPlayer && this.isGt12Channel(this.Event?.ChannelId) && this.sessionId) {
			let id = this.Event?.ChannelId
			this.pauseAd = null
			let AvailabilityStartTs = this.Event?.AvailabilityStartDateTs
			this.adsService.sessionPauseAsync(this.sessionId, id, AvailabilityStartTs, this.player?.positionTimeStamp).then((result) => {
				runInAction(() => {
					// MTVW-519
					//this.pauseAd = result?.pauseAd
					this.checkPauseAd(result?.pauseAd)
				})
				//console.debug("PAUSE %o, %o", this.pauseAd, this)
			}).catch((e) => {
				console.error("setPauseAd error %o", e)
				rootStore.logService.logAsync(logLevel.ERROR, "setPauseAd", "SourceAbstract", e)
			})
		}
	}

	setReplayAdInfo(info) {
		this.replayAdInfo = info
		if (!info) this.adState.replay = null
	}

	setFfwdAdInfo(info) {
		//console.trace("setFfwdAdInfo", info)
		this.fastForwardAdInfo = info
		if (!info) this.adState.fastFwd = null
		// MTVW-779: prevent showing fast forward button
		else this.adState.linear = null
	}

	setJumpLive() {
		console.log("@")
	}

	setJumpRwd() {
		console.log("@")
	}

	setJumpFwd() {
		console.log("@")
	}

	setJumpMarker(marker) {
		const markerPos = this.nearestMarker(marker)
		if (marker === this.MARKER_D) this.markerPosD = markerPos
		else this.markerPosD = null
		this.player.setPositionTimeStamp(markerPos)
	}

	setRestart() {
		console.log("@")
	}

	setFullScreen(bState = null) {
		console.log("@(bState=%o)", bState)
		this.player.setFullScreen(bState)
	}

	showPiP(enable) {
		this.player.showPiP(enable)
	}

	// MTV-3570
	_setupStream = flow(function* (urlSuffix, channelId) {
		//console.debug("_setupStream", urlSuffix)
		const jsonStreamReply = yield this.mediaService.setupStream(urlSuffix, channelId)
		//console.debug("jsonStreamReply %o", jsonStreamReply)
		//console.debug("_setupStream", this.player)
		// MTVW-725 session timeout, case of active ads
		const player = this.__parent.isAdsPlayer ? this._player : this.player
		this.playlist.setStreamInfo(jsonStreamReply, this.id, this.mediaService, player)
	})

	_openStreamLiveAsync = flow(function* () {
		cDebug("@@_openStreamLiveAsync() ->")
		if (!this.Event?.ChannelId) {
			console.error("no ChannelId", this.Event)
			return
		}
		yield this._ChannelListRepoWaitAsync()
		// MTV-3570: No need to create traxis playlist anymore
		// fetch BKM URL for Playlist from Traxis (this checks entitlement of user + IP geolocation)
		//yield Promise.all([this._fetchEventAsync(), this._fetchPlaylistChannelAsync(this.Channel?.id)])
		// GT12 TODO: obsolete since Media MS ?
		yield this._fetchEventAsync()
		yield this._setupStream(`live/${this.Event?.ChannelId}`, this.Event?.ChannelId)
		this.playlistLoadedCallback()

		// GT12, could be alternatively in Player.setPlayChannelLiveAsync
		if (this.isGt12Channel(this.Event?.ChannelId)) {
			try {
				const result = yield this.adsService.startLiveAsync(this.Event?.ChannelId, this.Event.AvailabilityStartDateTs, this.Event.AvailabilityEndDateTs)
				this.sessionId = result.sessionId
				//console.debug("startLiveAsync %o, SourceAbstract %o", result, this)
				this.sessionAdZones = yield this.getAdZones(this.Event.AvailabilityStartDateTs, this.Event.AvailabilityEndDateTs, this.player?.positionTimeStamp)
				//yield this.adsService.configurePauseMockAsync(false)
				this.sessionEnd = this.Event.event.AvailabilityEndDateTs
			} catch (e) {
				console.error("_openStreamLiveAsync error %o", e)
				rootStore.logService.logAsync(logLevel.ERROR, "_openStreamLiveAsync", "SourceAbstract", e)
			}
		}

		yield this.player.setSource(this).openStreamAsync()
		console.info("@@_openStreamLiveAsync() <-")
	})

	//enterAdsPlayer() {
	enterAdsPlayer = flow(function* () {
		yield this._parent.activateAdsPlayer()
	})

	//exitAdsPlayer() {
	exitAdsPlayer = flow(function* () {
		yield this.__parent.deactivateAdsPlayer()
		// ensure to update adZones
		setTimeout(() => {
			this.getAdZones(this.Event.AvailabilityStartDateTs, this.Event.AvailabilityEndDateTs, this.player?.positionTimeStamp).then((result) => {
				runInAction(() => { this.sessionAdZones = result; cDebug("exitAdsPlayer NEW AD ZONES %o", this.sessionAdZones) })
			}).catch((e) => {
				console.error("exitAdsPlayer OOPS %o, %o", e, this.Event)
			})
		}, 1000)
	})

	actionBeforeReplayAd = (startPosition) => {
		// preload stream
		this.player.setSource(this).openStreamAsync(true, startPosition)//.then((result) => {
	}

	//actionAfterReplayAd = (error = "", playbackTime = null) => {
	actionAfterReplayAd = flow(function* (error = "", playbackTime = null) {
		//console.debug("DEACTIVATE %o", this, this.__parent)
		try {
			yield this.exitAdsPlayer()
			/*
			this.player.setSource(this).openStreamAsync().then((result) => {
				this.player.setIsPosterVisible(false)
				this.player.setPlayPause()
			})
			*/
			// start preloaded stream
			this.player.setIsPosterVisible(false, "actionAfterReplayAd")
			// PRELOAD: if stream is not yet preloaded we'll start to play in preloadEvent()
			if (this.player.isPaused) yield this.player.setPlay("actionAfterReplayAd")
			// GT12: special cases are handled in interactionEvent
			// closeInteraction: [ changeAsset, interruption ] or "" for no interaction
			// error: [ adNotFound, other ] or ""  for no error
			//console.debug("actionAfterReplayAd %s, %o, %o, %o", error, this.adsService, this.adPlayStart, this)
			const duration = playbackTime === null ? moment().valueOf() - this.adPlayStart : playbackTime
			yield this.adsService.sessionTrackingReplayAdAsync(this.sessionId, this.replayAdInfo?.replayAd?.adsViewId, duration, "", error)
		}
		catch (e) {
			this.setReplayAdInfo(null)
			console.error("actionAfterReplayAd error %o", e)
			try {
				yield rootStore.logService.logAsync(logLevel.ERROR, "actionAfterReplayAd", "SourceAbstract", e)
			}
			catch (err) {
				console.errror("actionAfterReplayAd logAsync", err)
			}
		}
		this.setReplayAdInfo(null)
	})

	// GT12 TODO: factor out common code with _startReplaySession
	_contentReplay = flow(function* () {
		let rsp = null
		let adPlayed = false
		this.trueReplaySession = true
		// MTVW-495
		this.sessionStart = this.Event?.AvailabilityStartDateTs
		// MTVW-725 session timeout, case of active ads
		const player = this.__parent.isAdsPlayer ? this._player : this.player
		try {
			if (/*this.trueReplaySession &&*/ this.isGt12Channel(this.Event?.ChannelId) /*&& !this.sessionId*/) {
				const event = this.Event
				const recInfo = yield this._getRecordingInfo(event?.id, event?.content?.SeriesId)
				// (channelId, recordingId, epgEventStart, isSeriesRecording, replayWindow, bookmarkPosition, bookingTime, retryCount = 1)
				// GT12 TODO: bookmarkPosition ?
				//rsp = yield this.adsService.startRecordingAsync(event?.ChannelId, recInfo?.recordingId, event?.AvailabilityStartDateTs, event?.AvailabilityEndDateTs, recInfo?.isSeries, moment.duration(this.playlist.StreamInfo.stream_lifetime, moment.ISO_8601).valueOf(), 0, recInfo?.bookingTime)
				rsp = yield this.adsService.startRecordingAsync(event?.ChannelId, recInfo?.recordingId, event?.AvailabilityStartDateTs, event?.AvailabilityEndDateTs, recInfo?.isSeries, moment.duration(this.msEpg?.getChannel(this.Event?.ChannelId)?.maxReplayDuration, moment.ISO_8601).valueOf(), 0, recInfo?.bookingTime)
				// MTVW-495
				this.sessionStart = rsp.contentStartPosition
				this.sessionId = rsp.sessionId
				cDebug("_contentReplay startRecordingAsync %o", rsp)
				this.sessionAdZones = yield this.getAdZones(event.AvailabilityStartDateTs, event.AvailabilityEndDateTs, player?.positionTimeStamp)
				this.sessionEnd = event.AvailabilityEndDateTs
			}
			if (this.trueReplaySession && !this.restartDone) {
				rsp = yield this.handleReplayAd(rsp, this.contentReplayActionBeforeReplayAd, this.actionAfterReplayAd)
				adPlayed = true
				//this.trueReplaySession = false
			}
		}
		catch (e) {
			player.setPositionTimeStamp(this.sessionStart)
			console.error("_contentReplay error %o", e)
			rootStore.logService.logAsync(logLevel.ERROR, "_contentReplay", "SourceAbstract", e)
			return
		}
		// rsp will be null in case ads MS doesn't return an ad
		if (!adPlayed || !rsp) {
			// MTVW-495
			// VJS: below statement is needed
			player.setPositionTimeStamp(this.sessionStart)
		}
	})

	_startReplaySession = flow(function* (startTime = null, endTime = null, sessionId = null, playReplayAd = true) {
		//console.trace("_startReplaySession=")
		// MTVW-725 session timeout, case of active ads
		const player = this.__parent.isAdsPlayer ? this._player : this.player
		try {
			//console.debug("trueReplaySession %s, isAdsMsAvailable %s, sessionId %s", this.trueReplaySession, this.isGt12Channel(this.Event?.ChannelId), this.sessionId)
			// GT12
			let rsp = null
			let adPlayed = false
			this.sessionStart = startTime
			// NO startReplayAsync when switching from live with positioning, only on restart
			if (this.trueReplaySession && this.isGt12Channel(this.Event?.ChannelId) && !sessionId) {
				rsp = yield this.adsService.startReplayAsync(this.Event?.ChannelId, moment(startTime).utc().valueOf(), moment(endTime).utc().valueOf())
				this.sessionId = rsp.sessionId
				cDebug("startReplayAsync %o, SourceAbstract %o, trueReplaySession %s", rsp, this, this.trueReplaySession)
				this.sessionAdZones = yield this.getAdZones(moment(startTime).utc().valueOf(), moment(endTime).utc().valueOf(), this.player?.positionTimeStamp)
				// MTVW-495: use contentStartPosition for starting stream
				this.sessionStart = rsp.contentStartPosition
				this.sessionEnd = moment(endTime).utc().valueOf()
			}
			cDebug("---trueReplaySession %o, restartDone %o, adZones %o, %o, %o", this.trueReplaySession, this.restartDone, this.sessionAdZones, this, this.Event)
			if (this.trueReplaySession && !this.restartDone && playReplayAd) {
				rsp = yield this.handleReplayAd(rsp, this.actionBeforeReplayAd, this.actionAfterReplayAd)
				adPlayed = true
				//this.trueReplaySession = false
			}
			// rsp will be null in case ads MS doesn't return an ad
			if (!adPlayed || !rsp) {
				//this.exitAdsPlayer()
				if (playReplayAd) {
					this.setReplayAdInfo(null)
					/**/
					// MTVW-495
					//yield this.player.setSource(this).openStreamAsync()
					yield player.setSource(this).openStreamAsync(true, this.sessionStart)
					//if (this.player.isPaused) yield this.player.setPlay("playReplayAd")
					// TODO VJS: recheck if needed
					/* MTVW-725: commented
					if (import.meta.env.VITE_PLAYER === "vo") {
						this.player.setPositionTimeStamp(this.sessionStart)
					}
					*/
					player.setPositionTimeStamp(this.sessionStart)
					/**/
				}
			}
			cDebug("_startReplaySession GOT %o, %o", rsp, this._parent, this, this.player)
		}
		catch (e) {
			console.error("_startReplaySession error %o", e)
			this.setReplayAdInfo(null)
			yield player.setSource(this).openStreamAsync()
			rootStore.logService.logAsync(logLevel.ERROR, "_startReplaySession", "SourceAbstract", e)
		}
	})

	// eventTime: event start, startTime: replay start position, endTime: event end
	_openStreamRollingBufferAsync = flow(function* (eventTime = null, startTime = null, endTime = null) {
		console.info("@@_openStreamRollingBufferAsync(eventTime=%o, startTime=%o) eventTime.dump()=%o startTime.dump()=%o ->", eventTime, startTime, moment(eventTime).dump(), moment(startTime).dump())

		console.debug("_openStreamRollingBufferAsync eventTime %o, startTime %o, Event %o, GetEvent %o", eventTime, startTime, this.Event, this.GetEvent)

		const dbgInitial = { eventTime: eventTime, startTime: startTime, endTime: endTime }
		yield this._ChannelListRepoWaitAsync()

		// check that current event is the eventTime
		// moment(null) -> "Invalid date"
		// moment(undefined) -> now
		// momment("undefined") -> "Invalid date"
		if (eventTime !== undefined && moment(eventTime).isValid()) {
			// MTVW-822, MTVW-823: use getEventByChannelTime instead
			//yield this._checkEventAsync(eventTime)
			this.GetEvent = yield this.msEpg?.getEventByChannelTime(this.Channel?.id, moment(eventTime).utc().valueOf())
			console.debug("_openStreamRollingBufferAsync called getEventByChannelTime %o", this.Event)
		} else {
			// will define this.GetEvent if null with time now
			yield this._fetchEventAsync()
			console.debug("_openStreamRollingBufferAsync called _fetchEventAsync %o", this.Event)
		}

		// fight with 1 hour extra time and 10s for buffer init (legacy app)
		//if (startTime === null || startTime === undefined || startTime === "undefined") {
		if (startTime === undefined || !moment(startTime).isValid()) {
			startTime = this.Event?.AvailabilityStartDate
			console.debug("_openStreamRollingBufferAsync startTime = this.Event?.AvailabilityStartDate %o", startTime)
		}
		// MTVW-822, MTVW-823: Could be past the currently assigned event
		else {
			this.GetEvent = yield this.msEpg?.getEventByChannelTime(this.Channel?.id, moment(startTime).utc().valueOf())
			// MTVW-822, MTVW-823: set endTime
			endTime = this.Event?.AvailabilityEndDate
			console.debug("_openStreamRollingBufferAsync endTime = this.Event?.AvailabilityEndDate %o", endTime)
		}

		if (!startTime) {
			//	throw new Error("Cannot calculate time for event")
			console.error("_openStreamRollingBufferAsync Cannot calculate time for event")
			return false
		}
		// MTV-3570
		//if (endTime === null || endTime === undefined || endTime === "undefined") {
		if (endTime === undefined || !moment(endTime).isValid()) {
			endTime = this.Event?.AvailabilityEndDate
			console.debug("_openStreamRollingBufferAsync endTime = this.Event?.AvailabilityEndDate %o", endTime)
		}

		// MTV-3570: Need to format time
		startTime = moment(startTime).utc().format(TIME_FORMAT_BACKEND)
		endTime = moment(endTime).utc().format(TIME_FORMAT_BACKEND)
		// MTVW-822, MTVW-823: commented
		//const originalStartTime = startTime

		// VO PLAYER SYNC
		// ATTENTION: this causes issues whith playCompleted (buffer_end) events (on VO)
		// with long replays when positioning the stream after 4 hours.
		// In this case the buffer_end event is not handled correctly
		/*
		if (import.meta.env.VITE_PLAYER === "vjs") {
			// OPTIMIZATION for SourceChannelReplay.setReplay (currently only for non GT12 channels)
			if (!this.isGt12Channel(this.Channel?.id && eventTime)) {
				eventTime = moment(eventTime).utc().format(TIME_FORMAT_BACKEND)
				startTime = eventTime
			}
		}
		*/

		//const firstChunkOffestSec = 10
		//startTime = startTime.add(firstChunkOffestSec, "seconds")  // 10s offset borrowed from old apps
		//console.debug("_openStreamRollingBufferAsync fixed startTime=%o ->", startTime.dump())

		// fetch BKM URL for Playlist from Traxis (this checks entitlement of user + IP geolocation)
		// Traxis RollingBuffer streams have an extra hour prepended

		// MTV-3570: No need to create traxis playlist anymore
		// yield this._fetchPlaylistRollingBufferAsync(this.Channel?.RollingBuffers?.getBufferId, startTime)

		// propagate potential media service exception (see BDD test replay Zermatt HD, otherwise wrong message is displayed)
		// GT12: MTVW-460, MTVW-502: Channels MS flags
		const allowedSince = moment(this.msEpg?.replayOrRecordingAllowedSince(this.Event?.ChannelId, this.Event?.AvailabilityStartDateTs, this.Event?.AvailabilityEndDateTs, false)).utc().format(TIME_FORMAT_BACKEND)
		if (allowedSince > moment(startTime).utc().valueOf()) {
			// MTVW-822: caused errors, why was there 1 hour added?
			// -> see 'Traxis RollingBuffer streams have an extra hour prepended' above, not anymore with mediaMS
			//startTime = moment(allowedSince + 3600 * 1000).utc().format(TIME_FORMAT_BACKEND)
			startTime = allowedSince
			console.debug("_openStreamRollingBufferAsync allowedSince startTime %o", startTime)
		}
		//console.debug("allowedSince", allowedSince, startTime, this.Event?.AvailabilityStartDateTs)
		//console.debug("start %o, end %o, dur %o", moment(startTime).utc().valueOf(), moment(endTime).utc().valueOf(), moment(endTime).utc().valueOf() - moment(startTime).utc().valueOf())

		const dbgComputed = { eventTime: eventTime, startTime: startTime, endTime: endTime, allowedSince: allowedSince }
		try {
			const channel = this.msEpg?.getChannel(this.Event?.ChannelId)
			console.debug("_openStreamRollingBufferAsync channel %s, hasReplay %s, maxReplay %o, rollingBuffer %o", this.Event?.ChannelId, channel?.hasReplay, channel?.maxReplayDuration, channel?.rollingBufferId)
			console.debug("_openStreamRollingBufferAsync initial %o, computed %o, event %o", dbgInitial, dbgComputed, this.Event)
			// MTVW-822, MTVW-823: last resort, generate error message
			if (moment(startTime).utc().valueOf() >= moment(endTime).utc().valueOf() || moment(startTime).utc().valueOf() > moment().utc().valueOf()) {
				rootStore.page.Player.error = ErrorBase.CreateError([`Ein unerwartetes Problem ist aufgetreten. Wir arbeiten daran, dies schnellstmöglich zu beheben. (s ${startTime}, e ${endTime}, a ${allowedSince})`], null, this, "_openStreamRollingBufferAsync")
				return
			}
			yield this._setupStream(`replay/${this.Event?.ChannelId}/start/${startTime}/end/${endTime}`, this.Event?.ChannelId)
		}
		catch (err) {
			console.error("_openStreamRollingBufferAsync initial %o, computed %o, event %o, err %o", dbgInitial, dbgComputed, this.Event, err)
			// would be ErrorBaseEvent if not thrown without showing text from MS
			throw err
		}
		this.playlistLoadedCallback()

		// GT12
		// _startReplaySession sets this.sessionStart
		// VO PLAYER SYNC
		// MTVW-822, MTVW-823: commented
		/*
		if (import.meta.env.VITE_PLAYER === "vjs") {
			// OPTIMIZATION for SourceChannelReplay.setReplay (currently only for non GT12 channels)
			if (!this.isGt12Channel(this.Channel?.id && eventTime)) startTime = originalStartTime
		}
		*/
		yield this._startReplaySession(moment(startTime).utc().valueOf(), moment(endTime).utc().valueOf(), this.sessionId, true)

		// move one hour without 10s
		//yield this.player.setSource(this).openStreamAsync()
		console.info("@@_openStreamRollingBufferAsync(eventTime=%o, startTime=%o)", moment(eventTime).dump(), moment(startTime).dump())
	})

	_getRecordings(props) {
		return this._root.api.GetRecordings({ ...{ isRecorded: true, isPlanned: true, iLimit: 2000, isChildRecordings: true, isSortDesc: false }, ...props })
	}

	_getRecordingInfo = flow(function* (eventId, seriesId) {
		const marker = rootStore.page.Recordings.Manager.getMarker(eventId, seriesId)
		//console.debug("getRecordings %o, eId %o, sId %o, marker %o", this, eventId, seriesId, marker)
		if (marker) {
			const recs = this._getRecordings({ Ids: marker.id })
			//console.debug("isRecordedAsSingle", rootStore.page.Recordings.IndexPage.isRecordedAsSingle(eventId))
			yield recs.fetchDataAsync()
			//console.debug("RECORDINGS isSeriesD %s, isSeriesM %s, %o, %o, %o", recs.DataMain.isSeries, marker.isSeries, recs, recs.DataMain.CreateTime, moment(recs.DataMain.CreateTime).utc().valueOf())
			// MTVW-507: Use marker.isSeries instead of recs.DataMain.isSeries
			// MTV-555 NOTE: if a series is started for a pre-scheduled single recording the single recording will belong to the series
			//return { recordingId: marker.id, isSeries: recs.DataMain.isSeries, bookingTime: moment(recs.DataMain.CreateTime).utc().valueOf() }
			/*
			if (moment(recs.DataMain.CreateTime).utc().valueOf() < moment(recs.DataMain.StartTime).utc().valueOf()) {
				console.debug("potentially prescheduled")
			}
			*/
			return { recordingId: marker.id, isSeries: marker.isSeries, bookingTime: moment(recs.DataMain.CreateTime).utc().valueOf() }
		}
		return null
	})

	_openStreamContentAsync = flow(function* () {
		console.info("@@_openStreamContentAsync() ->")
		yield this._ChannelListRepoWaitAsync()
		// MTV-3570: No need to create traxis playlist anymore
		//yield Promise.all([this._fetchEventAsync(), this._fetchPlaylistContentAsync(this.id)])
		yield this._fetchEventAsync()
		cDebug("_openStreamContentAsync event %o, %o", this.Event, this)
		yield this._setupStream(`recording/${this.id}`, this.Event?.ChannelId)
		this.playlistLoadedCallback()

		// GT12
		/**/
		let rsp = null
		let adPlayed = false
		// MTVW-495
		this.sessionStart = this.Event?.AvailabilityStartDateTs
		// MTVW-725 session timeout, case of active ads
		const player = this.__parent.isAdsPlayer ? this._player : this.player
		try {
			if (/*this.trueReplaySession &&*/ this.isGt12Channel(this.Event?.ChannelId) && !this.sessionId) {
				const event = this.Event
				const recInfo = yield this._getRecordingInfo(event?.id, event?.content?.SeriesId)
				// GT12 TODO: bookmarkPosition ?
				// playlist.begin considers event_prefix (eg. 5PT5M), playlist.end considers event_postfix (eg. 5PT5M)
				// MTVW-494: Don't start recording at epg event (before it was at pre-padding)
				//rsp = yield this.adsService.startRecordingAsync(event?.ChannelId, recInfo?.recordingId, this.playlist.begin * 1000, this.playlist.end * 1000, recInfo?.isSeries, moment.duration(this.playlist.StreamInfo.stream_lifetime, moment.ISO_8601).valueOf(), 0, recInfo?.bookingTime)
				//rsp = yield this.adsService.startRecordingAsync(event?.ChannelId, recInfo?.recordingId, event.AvailabilityStartDateTs, event.AvailabilityEndDateTs, recInfo?.isSeries, moment.duration(this.playlist.StreamInfo.stream_lifetime, moment.ISO_8601).valueOf(), 0, recInfo?.bookingTime)
				rsp = yield this.adsService.startRecordingAsync(event?.ChannelId, recInfo?.recordingId, event.AvailabilityStartDateTs, event.AvailabilityEndDateTs, recInfo?.isSeries, moment.duration(this.msEpg?.getChannel(this.Event?.ChannelId)?.maxReplayDuration, moment.ISO_8601).valueOf(), 0, recInfo?.bookingTime)
				this.sessionId = rsp.sessionId
				cDebug("startRecordingAsync %o, SourceAbstract %o", rsp, this)
				this.sessionAdZones = yield this.getAdZones(event.AvailabilityStartDateTs, event.AvailabilityEndDateTs, player?.positionTimeStamp)
				// MTVW-495
				this.sessionStart = rsp.contentStartPosition
				this.sessionEnd = event.AvailabilityEndDateTs
			}
			// MTVW-725 session timeout, case of active ads, do not call handleReplayAd
			if (this.trueReplaySession && !this.__parent.isAdsPlayer) {
				rsp = yield this.handleReplayAd(rsp, this.actionBeforeReplayAd, this.actionAfterReplayAd)
				adPlayed = true
				//this.trueReplaySession = false
			}
		}
		catch (e) {
			this.setReplayAdInfo(null)
			yield player.setSource(this).openStreamAsync()
			console.error("_openStreamContentAsync error %o", e)
			rootStore.logService.logAsync(logLevel.ERROR, "_openStreamContentAsync", "SourceAbstract", e)
			return
		}
		// rsp will be null in case ads MS doesn't return an ad
		if (!adPlayed || !rsp) {
			//this.exitAdsPlayer()
			this.setReplayAdInfo(null)
			// MTVW-494: Don't start recording at epg event (before it was at pre-padding)
			//yield player.setSource(this).openStreamAsync()
			// MTVW-495
			// MTVW-633: condition for this.time added
			if (this.time) player.setSource(this).openStreamAsync(true, this.time)
			else player.setSource(this).openStreamAsync(true, this.sessionStart)
			//if (player.isPaused) yield player.setPlay("!adPlayed")
			//console.debug("pos= sessionStart", this.sessionStart)
			// TODO VJS: recheck if needed
			/* MTVW-725: commented
			if (import.meta.env.VITE_PLAYER === "vo") {
				player.setPositionTimeStamp(this.sessionStart)
			}
			*/
		}
		cDebug("_openStreamContentAsync GOT %o, %o", rsp, this._parent)

		//yield this.player.setSource(this).openStreamAsync()
		console.info("@@._openStreamContentAsync() <-")
	})

	/* MTVW-577: UNUSED OLD TRAXIS IMPLEMENTATION
	_fetchPlaylistChannelAsync = flow(function* (channelId) {
		console.debug("@@_fetchPlaylistChannelAsync(channelId=%o) ->", channelId)
		const playlistFetcher = rootStore.api.CreateChannelPlaylist({ channelId })
		if (this.oCreatePlaylist) {
			this.oCreatePlaylist.abortReq()
		}
		this.playlist = null
		this.oCreatePlaylist = playlistFetcher
		yield playlistFetcher.fetchDataAsync()
	
		if (this.oCreatePlaylist === playlistFetcher) {
			this.playlist = playlistFetcher.Data
		}
		return this.playlist
	})
	
	_fetchPlaylistRollingBufferAsync = flow(function* (rollingBufferId, startDate) {
		console.debug(
			"@@_fetchPlaylistRollingBufferAsync(rollingBufferId=%o startDate=%o %o %o) <-",
			rollingBufferId,
			startDate,
			moment(startDate).dump(),
			moment(startDate)
				.utc()
				.dump()
		)
	
		const playlistFetcher = rootStore.api.CreateRollingBufferPlaylist({ rollingBufferId, startDate })
		if (this.oCreatePlaylist && this.oCreatePlaylist !== playlistFetcher) {
			this.oCreatePlaylist.abortReq()
		}
		this.playlist = null
		this.oCreatePlaylist = playlistFetcher
		yield playlistFetcher.fetchDataAsync()
	
		if (this.oCreatePlaylist === playlistFetcher) {
			this.playlist = playlistFetcher.Data
		}
		return this.playlist
	})
	
	_fetchPlaylistContentAsync = flow(function* (contentId) {
		console.debug("@@_fetchPlaylistContentAsync(contentId=%o) ->", contentId)
		const playlistFetcher = rootStore.api.CreateContentPlaylist({ contentId })
		if (this.oCreatePlaylist) {
			this.oCreatePlaylist.abortReq()
		}
		this.playlist = null
		this.oCreatePlaylist = playlistFetcher
		yield playlistFetcher.fetchDataAsync()
	
		if (this.oCreatePlaylist === playlistFetcher) {
			this.playlist = playlistFetcher.Data
		}
		return this.playlist
	})
	*/

	// called perodically from page/player/Player when timestamp changes
	_checkEventAsync = flow(function* (eventTime) {
		console.info(
			"@@_checkEventAsync(eventTime=%o %o) - this.Event=%o ...isTimeInEventAvailability=%o",
			eventTime,
			moment(eventTime).dump(),
			this.Event,
			this.Event?.isTimeInEventAvailability?.(eventTime)
		)
		// MTVW-838: exclude SourceTypeList.ContentPlaylist)
		if (this.type === SourceTypeList.ContentPlaylist) return
		// MTVW-717: Added condition, if the player 'isLoading', the eventTime could be garbage
		if (this.playEventActive && this.player.isStreamLoaded && !this.player.isLoading && this.Channel?.id && eventTime !== null && !this.Event?.isTimeInEventAvailability?.(eventTime)) {
			// MTVW-487: replace traxis rootStore.api.GetEventByChannelTime with epgMS implementation
			/*
			this.GetEvent = rootStore.api.GetEventByChannelTime({ ChannelId: this.Channel?.id, time: eventTime }).abortReq()
			yield this._fetchEventAsync()
			*/
			this.GetEvent = yield this.msEpg?.getEventByChannelTime(this.Channel?.id, eventTime)
			//console.debug("_checkEventAsync %o, %o, %o", eventTime, this.GetEvent, this.Event)

			//console.debug("_checkEventAsync %o, %s", this.Event, this.Event.id)
			// GT12: prevent displaying adSkipInfo when switching from end of Live TV show to next one
			if (this.Event) {
				yield rootStore.page.OverlayEventDetails.setEpgEventById(this.Event.id)
				//this.sessionAdZones = yield this.getAdZones(this.Event.AvailabilityStartDateTs, this.Event.AvailabilityEndDateTs, this.player?.positionTimeStamp)
				//this.sessionEnd = this.Event.AvailabilityEndDateTs
				//console.debug("this.type %s, restart %s", this.type, this.restartDone)
				// MTVW-486: switching to live show from previous show
				//if ((this.Event.AvailabilityEndDateTs < moment().utc().valueOf()) && this.type === SourceTypeList.ChannelReplay) {
				if (/*(eventTime < moment().utc().valueOf()) &&*/ this.type === SourceTypeList.ChannelReplay) {
					cDebug("SWITCHING to trueReplaySession", eventTime, moment(eventTime).utc().format(TIME_FORMAT_BACKEND), this.Event?.event?.channelId, this.Event.AvailabilityStartDate, this.Event, this)
					// (MTVW-678) below could be an alternative solution to reload the stream for each show start
					// check eventTime vs. this.Event.AvailabilityStartDateTs
					//this.trueReplaySession = true
					//yield this.__parent.setPlayChannelReplayAsync(this.Event.event.channelId, eventTime, this.Event)
					this.trueReplaySession = true
					yield this._startReplaySession(this.Event.AvailabilityStartDateTs, this.Event.AvailabilityEndDateTs, null, false)
				}
				// MTVW-486
				//else {
				else if (this.type === SourceTypeList.ChannelLive) {
					// live
					if (!this.restartDone) {
						this.trueReplaySession = false
					}
					else {
						yield this._startReplaySession(this.Event.AvailabilityStartDateTs, this.Event.AvailabilityEndDateTs, null, false)
					}
				}
				//console.debug("CHANGE DETECTED %o %o %o", this.Event, this.player.__parent.source, this)
				//this.dumpAdZones()
			}
		}
		return false
	})

	_fetchEventAsync = flow(function* (time = null) {
		// MTVW-487: replace traxis rootStore.api.GetEventByChannelTime with epgMS implementation
		/*
		if (this.GetEvent === null) {
			// GT12 TODO: Traxis GetEventByChannelTime should be replaced
			// time version
			this.GetEvent = rootStore.api.GetEventByChannelTime({ ChannelId: this.Channel?.id, time: null }).abortReq()
			yield this.GetEvent.waitAsync()
		} else if (this.GetEvent.isInApi === true) {
			yield this.GetEvent.waitAsync()
		}
		return true
		*/
		//console.debug("_fetchEventAsync getEventByChannelTime", this.GetEvent)
		if (this.GetEvent === null) {
			if (!time) time = moment().utc().valueOf()
			this.GetEvent = yield this.msEpg?.getEventByChannelTime(this.Channel?.id, time)
		}
	})

	_ChannelListRepoWaitAsync = flow(function* (channelId) {
		yield rootStore.page.ChannelListRepo.waitAsync()
	})

	handleSetPosition = flow(function* (positionMoment) {
		this.removePauseAd()
		this.player.setPositionTimeStamp(positionMoment)
		yield Promise.resolve()
	})

	setAsLoaded() {
		this.isLoading = false
		return this
	}

	handleStreamOnStart() {
		console.log("@()")
	}

	handleStreamOnEnd(positionTimeStamp) {
		console.log("@()")
	}

	// GT12
	continueAt = flow(function* (position = null) {
		/*
		this._parent._parent.setPlayChannelReplayAsync(this.id, this.fastForwardAdInfo.jumpTo, this.Event).then(() => {
			this.player.setPositionTimeStamp(this.fastForwardAdInfo.jumpTo)
			//this.setPause()
		})
		*/
		this.playlistLoadedCallback = () => {
			this.playlist.StreamInfo.event_start = moment(position).utc().format(TIME_FORMAT_BACKEND)
		}
		yield this.openStreamAsync()
	})

	// GT12
	// NOTE: sometimes there are 2 events during fast forward ad
	// TODO: recheck, now called from VO_OSMP_CB_PLAY_STARTED (before from VO_OSMP_CB_PLAY_PLAYING)
	playStartEvent() {
		/*
		if (this.player.__path === "playerAds" && this.adPlayStart === 0) {
			document.getElementById("tvPlayer").style.display = "none"
			document.getElementById("adsPlayer").style.display = "block"
		}
		*/
		this.player.setIsPosterVisible(false, "playStartEvent", false, 300)

		// MTVW-754: Safari, make sure that adjustLiveTvSize is updated when returning to live tab
		controlsTimerHandler(rootStore.page.Player)
		setTimeout(() => {
			console.debug("playStartEvent adjustPlayerStyles")
			rootStore.page.Player.adjustPlayerStyles()
		}, 500)

		if (this.playEventActive) {
			// prevent multiple calls
			return
		}
		console.debug("playStartEvent=", this.player, this.__parent, this)
		/**/
		// MTVW-444
		if (this.__parent.playerFg) {
			this.playEventActive = false
			return
		}
		/**/
		this.playEventActive = true
		this.adPlayStart = moment().valueOf()
		this.__parent.adPlayStart = this.adPlayStart
		cDebug("SOURCE PLAY EVENT %o, %o, %o, %s", this, this.player._refElemContainer, this.player, moment(this.adPlayStart).format(TIME_FORMAT_BACKEND))
		// remove pause ad
		this.removePauseAd()
	}

	// MTVW-481
	//playCompleteEvent(positionTimeStamp) {
	playCompleteEvent = flow(function* (positionTimeStamp) {
		const parent = this.__parent
		console.debug("playCompleteEvent %o, %o, %o", this, this.playAfterAd, parent.replayAdInfo)
		if (parent.replayAdInfo) {
			console.debug("playCompleteEvent calling playAfterAd")
			//console.debug("STOP %o", this)
			yield parent.playAfterAd()
			parent.playEventActive = false
			parent.setReplayAdInfo(null)
		}
		if (this.player.__path !== "playerAds") {
			yield this.handleStreamOnEnd(positionTimeStamp)
		}
	})

	// GT12
	pauseEvent() {
		cDebug("SOURCE PAUSE EVENT %o, %o", this, this.adState)
		if (!(this.adState.fastFwd || this.adState.replay || this.disablePauseAd)) this.setPauseAd()
		this.disablePauseAd = false
	}

	// GT12
	//preloadEvent() {
	preloadEvent = flow(function* (ignore = false) {
		cDebug("PRELOAD EVENT %o, %o, %o", this, this.Event, this.player.__path, this.player.isPausedUser)
		// PRELOAD: in case we couldn't start playing already
		// MTVW-493: preloadEvent can be generated for content stream while ads player is playing
		//this.player.setIsPosterVisible(false, "preloadEvent")
		/**/
		// MTVW-444, MTVW-788, MTVW-787
		if (this.__parent.playerFg) {
			//this.player.setIsPosterVisible(false, "preloadEvent")
			if (import.meta.env.VITE_DISPOSE_LIVE_PLAYER === "true") {
				//console.debug("preload swap")
				yield this.__parent.swapPlayers()
				// now playerFg has been cleared
				if (!this.player.isPausedUser) this.player.setPause()
			}
			this._waitPreload.setResolve()
			return
		}
		/**/
		if (import.meta.env.VITE_PLAYER === "vjs") {
			// MTVW-444
			if (this.player.__path !== "playerAds" && this.player.isPausedUser && !ignore) yield this.player.setPlay("preloadEvent")
		}
		else if (import.meta.env.VITE_PLAYER === "vo") {
			if (this.player.__path !== "playerAds" && this.player.isPaused) yield this.player.setPlay("preloadEvent")
		}
	})

	//replayAdError(e, errorInfo = "other") {
	replayAdError = flow(function* (e, errorInfo = "other") {
		try {
			yield this.exitAdsPlayer()
			console.error("handleReplayAd error %o, %o", e, errorInfo, this.playAfterAd)
			yield this.playAfterAd(errorInfo, 0)
			this.setReplayAdInfo(null)
			this.playAfterAd = () => { }
			rootStore.logService.logAsync(logLevel.ERROR, "handleReplayAd", "SourceAbstract", e)
		}
		catch (e) {
			console.error("replayAdError exception", e)
		}
	})

	//ffAdError(e) {
	ffAdError = flow(function* (e) {
		try {
			yield this.exitAdsPlayer()
			this.player.setPositionTimeStamp(this.adEndPosition)
			this.setFfwdAdInfo(null)
			if (this.player.isPaused) yield this.player.setPlay("ffAdError")
			console.error("handleFastForwardAd error %o", e)
			this.playAfterAd = () => { }
			yield rootStore.logService.logAsync(logLevel.ERROR, "handleFastForwardAd", "SourceAbstract", e)
			// TODO: reporting ?
		}
		catch (err) {
			console.error("ffAdError exception", err)
		}
	})

	// generated after PlayerVo.openStreamAsync has completed
	errorEvent = flow(function* (error) {
		cDebug("type", this.type, this, this.__parent)
		const instance = this.type === SourceTypeList.AdPlaylist ? this.__parent : this
		//const instance = this
		cDebug("errorEvent", error, instance.adState.fastFwd, instance.adState.replay, instance)
		if (instance.adState.fastFwd) {
			instance.ffAdError(error)
		}
		else if (instance.adState.replay) {
			instance.replayAdError(error)
		}
		else {
			cDebug("no adState")
			rootStore.page.Player.error = error
			//this.exitAdsPlayer()
			// stop player
			//yield this.player?.setRefElemContainer(null)
			yield this.player?.stopInstance()
			if (import.meta.env.VITE_PLAYER === "vjs") {
				//yield this.__parent?.playerAds?.setRefElemContainer(null)
				yield this.__parent?.playerAds?.stopInstance()
			}
		}
	})

	// GT12
	interactionEvent = flow(function* (interaction, positionTimeStamp = null) {
		//interactionEvent(interaction, positionTimeStamp = null) {
		// pause ad: resume, close, changeAsset, interruption (background)
		// replay ad: changeAsset, interruption (background)
		// ff ad: changeAsset, interruption (background), skipPromo
		// GT12 TODO: how to track "skipPromo" in linear ad?
		cDebug("interactionEvent %s, %s, %o, %o", interaction, this.interactionActive, this, this.type, this._parent, this.__parent)
		// Avoid potential recursion if PlayerVo._killPlayer is called
		if (this.interactionActive) return
		this.interactionActive = true
		if (this.type === SourceTypeList.AdPlaylist) {
			// MTVW-588: setPlayCompleted
			this.player.setPlayCompleted()
			return
		}
		if (this.__parent.isAdsPlayer) {
			//console.debug("!!!interactionEvent %s, %s, %o", interaction, channelId, this)
			yield this.exitAdsPlayer()
			//console.debug("interaction ads replay %o, ff %o", this.replayAdInfo, this.fastForwardAdInfo)
			if (this.replayAdInfo) {
				cDebug("track replay")
				// adPlayStart might be null if an error occured (no playEvent)
				const duration = this.adPlayStart ? moment().valueOf() - this.adPlayStart : 0
				try {
					yield this.adsService.sessionTrackingReplayAdAsync(this.sessionId, this.replayAdInfo.replayAd.adsViewId, duration, interaction, "")
				}
				catch (e) {
					console.error("interactionEvent track replay error %o", e)
					try {
						rootStore.logService.logAsync(logLevel.ERROR, "interactionEventRreplayAd", "SourceAbstract", e)
					}
					catch (e) {
						console.error("CAUGHT interactionEvent", e)
					}
				}
				finally {
					this.setReplayAdInfo(null)
					this.interactionActive = false
				}
			}
			else if (this.fastForwardAdInfo) {
				cDebug("track ff ad")
				// adPlayStart might be null if an error occured (no playEvent)
				const duration = this.adPlayStart ? moment().valueOf() - this.adPlayStart : 0
				try {
					yield this.adsService.sessionTrackingFastForwardAdAsync(this.sessionId, this.fastForwardAdInfo.fastForwardAd.adsViewId, duration, interaction, "")
				}
				catch (e) {
					console.error("interactionEvent track ffw ad error %o", e)
					try {
						rootStore.logService.logAsync(logLevel.ERROR, "interactionEventFfwAd", "SourceAbstract", e)
					}
					catch (e) {
						console.error("CAUGHT interactionEvent", e)
					}
				}
				finally {
					this.setFfwdAdInfo(null)
					this.interactionActive = false
				}
			}
		}
		else if (this.pauseAd) {
			//console.debug("!!!interactionEvent %s, %s, %o", interaction, channelId, this)
			cDebug("track pause ad")
			try {
				yield this.adsService.sessionTrackingPauseAdAsync(this.sessionId, this.pauseAd.adsViewId, Math.round(performance.now() - this.adPauseStart), interaction, "")
			}
			catch (e) {
				console.error("interactionEvent track pause ad error %o", e)
				try {
					rootStore.logService.logAsync(logLevel.ERROR, "interactionEventPauseAd", "SourceAbstract", e)
				}
				catch (e) {
					console.error("CAUGHT interactionEvent", e)
				}
			}
			finally {
				this.pauseAd = null
				this.interactionActive = false
			}
		}
		else this.interactionActive = false
	})

	// GT12
	handleReplayAd = flow(function* (adResponse, beforeAd, playAfterAd) {
		this.contentPlayerName = this._parent.player.__path
		//console.debug("serviceUrls %o", serviceUrls, this.contentPlayerName)
		let result = null
		this.playAfterAd = () => { }
		this.markerPosD = null
		this.adPlayStart = null
		// MTVW-725 session timeout, case of active ads
		const player = this.__parent.isAdsPlayer ? this._player : this.player
		if (this.isGt12Channel(this.Event?.ChannelId)) {
			//result = yield this.adsService.startReplayAsync(channelId, epgEventStart, epgEventEnd, bookmarkPosition)
			cDebug(">>>handleReplayAd %o, %o, %o", adResponse, this.trueReplaySession, this.restartDone)
			if (adResponse?.replayAd?.adUrl) {
				//adResponse.replayAd.adUrl = "http://89.236.171.48/processed/a1155e20-1d41-45f2-8a20-e24ecbf8874f/master.m3u8"
				//adResponse.replayAd.adUrl = "https://services.qltv.stage.quickline.ch/ads/v001/sessions/" + this.sessionId + "/playlists/fe73318c-e196-4076-a5c9-35c40aa8b7dc/master.m3u8"
				//this.setPlayPause()
				//adSource.replayAdInfo = adResponse
				this.setReplayAdInfo(adResponse)
				//adSource.playAfterAd = playAfterAd
				this.playAfterAd = playAfterAd
				beforeAd(adResponse.contentStartPosition)
				// try to fetch the playlist in order we can handle potential errors
				let errorInfo = "adNotFound"
				try {
					//yield fetch(adResponse.replayAd.adUrl)
					yield this.enterAdsPlayer()
					const adSource = SourceAdPlaylist.create(this, adResponse.replayAd.adUrl)
					// source is used for progress bar
					//this._parent.source = adSource
					result = adResponse
					errorInfo = "other"
					// MTVW-725: use this.player !
					if (!this.restartDone) yield this.player.setSource(adSource).openStreamAsync()
					// GT12 TODO: don't report
					else yield playAfterAd(errorInfo, 0)
					// test purpose
					//rootStore.logService.logAsync(logLevel.INFO, "handleReplayAd", "SourceAbstract", result /*{ a: 1, b: 2, c: 3 }*/)
				}
				catch (e) {
					/*
					// MTVW-512: Playlist not found error handling
					this.exitAdsPlayer()
					console.error("handleReplayAd error %o, %o", e, errorInfo)
					playAfterAd(errorInfo, 0)
					this.setReplayAdInfo(null)
					this.playAfterAd = () => { }
					rootStore.logService.logAsync(logLevel.ERROR, "handleReplayAd", "SourceAbstract", e)
					*/
					this.replayAdError(e, errorInfo)
					return null
				}
				//console.debug("PLAYER NOW %o, %o", this.player, this._parent)
			}
			else {
				// returned result is null
			}
		}
		//if (!result) this._parent.handlePlaySourcePreserveProgressBarAsync(source)
		//if (!result) playAfterAd()
		this.restartDone = true
		return result
	})

	// GT12
	handleFastForwardAd = flow(function* (from, to, playAfterAd) {
		this.contentPlayerName = this._parent.player.__path
		//console.debug("handleFastForwardAd", this.isGt12Channel(this.Event?.ChannelId), this.sessionId, this.trueReplaySession, playAfterAd)
		this.playAfterAd = () => { }
		this.adEndPosition = to
		this.adPlayStart = null
		if (this.isGt12Channel(this.Event?.ChannelId) && this.sessionId && this.trueReplaySession) {
			const AvailabilityStartTs = this.Event?.AvailabilityStartDateTs
			const AvailabilityEndTs = this.Event?.AvailabilityEndDateTs

			//const from = this.player?.positionTimeStamp
			// sessionId, epgEventStart, epgEventEnd, from, to
			// TODO: sometimes after ffwad and then starting replay AvailabilityStartTs and AvailabilityEndTs are missing
			try {
				// MTVW-521: reset markerPosD
				this.markerPosD = null
				const inAdZone = this.checkAdZones(from) /*|| this.checkAdZones(to)*/ ? true : false
				const adResponse = yield this.adsService.sessionVerifyJumpAsync(this.sessionId, this.Event?.ChannelId, AvailabilityStartTs, AvailabilityEndTs, from, to, inAdZone)
				//console.debug("sessionVerifyJumpAsync %o, from %s, inAdZone %s", adResponse, from, inAdZone)
				cDebug("adResponse %o. adState %o", adResponse, this.adState)
				if (adResponse?.fastForwardAd?.adUrl) {
					// TEST with specific playlist
					//adResponse.fastForwardAd.adUrl = "https://services.qltv.stage.quickline.ch/ads/v001/sessions/823017f9-b7b7-4a89-b297-4c80017836d3/playlists/fe73318c-e196-4076-a5c9-35c40aa8b7dc/master.m3u8"
					//adResponse.fastForwardAd.adUrl = "https://services.qltv.stage.quickline.ch/ads/v001/sessions/" + this.sessionId + "/playlists/fe73318c-e196-4076-a5c9-35c40aa8b7dc/master.m3u8"
					// internal pause, don't request pause ad
					this.disablePauseAd = true
					// MTVW-506: pre-position to D marker (for improved smoothness) and pause content stream
					this.player.setPositionTimeStamp(adResponse.jumpTo)
					if (!this.player.isPaused) this.setPlayPause()
					this.adStartPosition = this.player?.positionTimeStamp
					yield this.enterAdsPlayer()
					const adSource = SourceAdPlaylist.create(this, adResponse.fastForwardAd.adUrl)
					//adSource.fastForwardAdInfo = adResponse
					this.setFfwdAdInfo(adResponse)
					//adSource.playAfterAd = playAfterAd
					this.playAfterAd = playAfterAd
					// source is used for progress bar
					//this._parent.source = adSource
					yield this.player.setSource(adSource).openStreamAsync()
				}
				else {
					//console.debug("NO AD")
					if (adResponse.action === "allow") {
						console.warn("JUMP allowed")
						yield this.player.setPositionTimeStampAsync(adResponse.jumpTo)
						if (adResponse.jumpTo === this.adZone?.end) {
							console.warn("JUMP D Marker")
							this.markerPosD = this.adZone?.end
						}
						// MTVW-534: Disable. Kurwerbung button would appear insted of skip when forwarding in adZone
						//this.markerPosD = adResponse.jumpTo
						// MTVW-649: disable notifyJump
						//yield this.adsService.sessionNotifyJumpAsync(this.sessionId, AvailabilityStartTs, AvailabilityEndTs, from, adResponse.jumpTo)
					}
					else if (adResponse.action === "deny") {
						yield this.player.setPositionTimeStampAsync(to)
						console.error("JUMP DENIED")
						rootStore.logService.logAsync(logLevel.ERROR, "handleFastForwardAd", "SourceAbstract", adResponse)
					}
				}
			}
			catch (e) {
				/*
				// MTVW-512: Playlist not found error handling
				this.exitAdsPlayer()
				this.player.setPositionTimeStamp(to)
				if (this.player.isPaused) yield this.player.setPlay("catch handleFastForwardAd")
				console.error("handleFastForwardAd error %o", e)
				this.playAfterAd = () => { }
				rootStore.logService.logAsync(logLevel.ERROR, "handleFastForwardAd", "SourceAbstract", e)
				// TODO: reporting ?
				*/
				yield this.ffAdError(e)
			}
		}
		else {
			//console.debug("DEFAULT CASE", from, to)
			yield this.player.setPositionTimeStampAsync(to)
		}
		//console.debug("this.playAfterAd", this.playAfterAd)
		return
	})

	// GT12
	getAdZones = flow(function* (start, end, playbackPosition) {
		if (this.isGt12Channel(this.Event?.ChannelId) && this.sessionId) {
			try {
				if (!playbackPosition) playbackPosition = start
				const result = yield this.adsService.sessionAdZonesAsync(this.sessionId, start, end, playbackPosition)
				cDebug("aZ: %o, s: %s, e: %s, nP %s", result, moment(start).utc().format(TIME_FORMAT_BACKEND), moment(end).utc().format(TIME_FORMAT_BACKEND), moment(result?.nextPositionToCheck).utc().format(TIME_FORMAT_BACKEND))
				this.dumpAdZones(result)
				this.adZoneFetchPosiiton = playbackPosition
				return result
			}
			catch (e) {
				console.error("getAdZones error %o", e)
				rootStore.logService.logAsync(logLevel.ERROR, "getAdZones", "SourceAbstract", e)
			}
		}
		return null
	})

	dumpAdZones(zones = null) {
		if (!zones) zones = this.sessionAdZones
		if (zones) {
			zones.adZones.forEach(i => {
				cDebug("s: %s, sk: %s, e: %s", moment(i.start).utc().format(TIME_FORMAT_BACKEND), moment(i.skipStart).utc().format(TIME_FORMAT_BACKEND), moment(i.end).utc().format(TIME_FORMAT_BACKEND))
			})
		}
	}

	// GT12
	timeStampEvent(timeStamp, offsetSeconds, playCompleted) {
	}

	// GT12
	checkForLinearAd(timeStamp, offsetSeconds) {
		// TEST purpose
		//const lastSkipState = linear.skip
		//const adjustedStamp = Math.round((timeStamp) / 1000) * 1000
		const adjustedStamp = timeStamp
		// MTVW-535: avoid button display artifacts when jumping to D marker
		if (this.markerPosD) {
			const SAMPLE_RATE = 1000
			if (adjustedStamp < this.markerPosD - 2 * SAMPLE_RATE) {
				this.adState.linear = null
				this.setFfwdAdInfo(null)
				cDebug("cond a")
			}
			else if (adjustedStamp <= this.markerPosD + SAMPLE_RATE) {
				this.adState.linear = null
				this.setFfwdAdInfo(null)
				cDebug("cond b")
				return
			}
			else if (adjustedStamp > this.markerPosD + SAMPLE_RATE) {
				this.adState.linear = null
				this.setFfwdAdInfo(null)
				this.markerPosD = null
				cDebug("cond c")
			}
		}
		this.checkAdZones(timeStamp, offsetSeconds)
		//console.debug("checkForLinearAd %o, %s, %o", this.adZone, this.debounceEvent, this.sessionAdZones)
		if (!this.adState.fastFwd) {
			//this.checkAdZones(timeStamp, offsetSeconds)

			//const promoDuration = 7000
			const adZone = this.adZone
			if (adZone) {
				let skip = false
				if (adjustedStamp >= adZone.skipStart && adjustedStamp < adZone.end /*- 2000*/) {
					//promoCountdown = 0
					// MTVW-544: suppress "Überspringen" after ffad
					if (!this.markerPosD) skip = true
				}
				//let promoCountdown = 0
				// MTVW-534: Avoid visual intermittent Kurzwerbung button when complete adZone can be skipped. Provide skip in constructor.
				if (!this.adState.linear) this.adState.linear = new LinearAd(skip)
				const linear = this.adState.linear

				if (adjustedStamp >= adZone.end) {
					this.adState.linear = null
				}
				linear.setValues(skip /*, promoCountdown*/)
				//console.debug("AD ZONE %s, %o", timeStamp, moment(timeStamp).utc().format(TIME_FORMAT_BACKEND), adZone)
				// TEST purpose
				/*
				if (!lastSkipState && skip) {
					console.debug("SKIP NOW")
					this.skipAd()
				}
				*/
			}
			else this.adState.linear = null
		}
		//if (this.markerPosD) console.debug("markerPosD ff %o, %s", this.adState.fastFwd, this.markerPosD)
	}

	// GT12
	// for replay and content streams: replay ad and fast forward ad
	//adStampEvent(timeStamp, playTime, playCompleted) {
	adStampEvent = flow(function* (timeStamp, playTime, playCompleted) {
		// MTVW-588: Safari is quite accurate
		const SAMPLING_RATE = isSafari() ? 0 : 100
		//console.debug("AD STAMP %s, %s, PLAY TIME %s, %o, %o, %o", timeStamp, moment(timeStamp).utc().format(TIME_FORMAT_BACKEND), playTime, this.adZone, this.replayAdInfo, this)
		if (this.replayAdInfo) {
			playTime *= 1000
			if (!this.adState.replay) this.adState.replay = new ReplayAd()
			const replayAd = this.replayAdInfo.replayAd
			//console.debug("adStampEvent %o, %s", replayAd, playTime)
			if (playTime >= replayAd?.countdownDelay) {
				// MTVW-588: shift by 1 second, cap minium to 1 second
				let countDown = 1000 + replayAd.countdownDuration + replayAd.countdownDelay - playTime
				countDown = countDown >= 1000 ? countDown : 0
				//console.debug("REPLAY AD %s, %s", countDown, moment().valueOf())
				this.adState.replay.setCountdown(countDown)
			}
			//console.debug("adStampEvent %o, %o", this, this.playAfterAd)
			// MTVW-481: Handled in playCompleteEvent
			/*
			if (playTime >= replayAd?.countdownDelay + replayAd?.countdownDuration) {
				//console.debug("STOP %o", this)
				this.playAfterAd()
				this.playEventActive = false
				this.setReplayAdInfo(null)
			}
			*/
		}
		else if (this.fastForwardAdInfo) {
			// MTVW-588: workaround to mitigate issue with times from jumpVerify / replay ad and effective play time
			//playTime *= 990
			playTime = playTime * 1000// + SAMPLING_RATE

			//console.debug("STAMP FFW %s, %o", playTime, this.fastForwardAdInfo)
			//if (!this.adState.fastFwd) this.adState.fastFwd = new FastFwdAd()
			//if (this.markerPosD) return
			const ffwdAd = this.fastForwardAdInfo.fastForwardAd
			//console.debug("ffwdAd %o, tStamp %s, playTime %s, nowStamp %s, jumpTo %s", ffwdAd, this.adStartPosition, playTime, this.adStartPosition + playTime, this.fastForwardAdInfo.jumpTo)
			let skip = false
			//const lastSkipState = this.adState.fastFwd.skip
			if (ffwdAd.adsStart) {
				let adCount = ffwdAd.adsStart?.length
				let adNumber = 0
				let promoCountdown = 0
				for (let i = 0; i < adCount; i++) {
					if (playTime - 4 * SAMPLING_RATE >= ffwdAd.adsStart[i]) adNumber = i + 1
				}
				// MTVW-496, MTVW-539: Consider adsEnds information
				if (ffwdAd.adsEnds && playTime - 4 * SAMPLING_RATE >= ffwdAd.adsEnds[adCount - 1]) {
					adNumber = 0
				}
				// MTVW-588: adjust by 1100 to avoid rounding / sampling issues
				const ADJUST = 1100
				if (ffwdAd.promoStart && playTime >= ffwdAd.promoStart + ADJUST) {
					adCount = 0
					adNumber = 0
					promoCountdown = Math.round((ffwdAd.promoStart + ADJUST + ffwdAd.promoCountdown - playTime) / 1000) * 1000
					if (playTime >= ffwdAd.promoStart + ADJUST + ffwdAd.promoCountdown /*&& !this.markerPosD*/) {
						//console.debug("SKIP playTime %s, promStart %s, countdown %s, promoCountdown %s", playTime, ffwdAd.promoStart, promoCountdown, ffwdAd.promoCountdown)
						promoCountdown = 0
						skip = true
					}
					//else console.debug("playTime %s, promStart %s, countdown %s, promoCountdown %s", playTime, ffwdAd.promoStart, promoCountdown, ffwdAd.promoCountdown)
				}
				if (!this.adState.fastFwd) this.adState.fastFwd = new FastFwdAd(skip, promoCountdown, adNumber, adCount)
				else this.adState.fastFwd.setValues(skip, promoCountdown, adNumber, adCount)
			}
			// GT12 TODO: this.adStartPosition not needed

			if (playCompleted && this.fastForwardAdInfo /*|| this.adStartPosition + playTime >= this.fastForwardAdInfo.jumpTo*/) {
				console.debug("adStampEvent calling playAfterAd")
				yield this.playAfterAd()
				this.playEventActive = false
				this.setFfwdAdInfo(null)
			}
			// TEST purpose
			//if (!lastSkipState && skip) this.skipAd()
		}
	})

	// GT12
	checkAdZones(timeStamp) {
		if (this.sessionAdZones) {
			if (this.markerPosD) {
				this.adZone = null
				return null
			}
			//console.debug("checkAdZones", this.Event)
			//this.dumpAdZones()

			// check for update of adZones
			let fetchAdZones = false
			if (timeStamp && this.adZoneFetchPosiiton && timeStamp < this.adZoneFetchPosiiton) {
				cDebug("update adZones: ts %s < fp %s", timeStamp, this.adZoneFetchPosiiton)
				fetchAdZones = true
			}
			if (timeStamp && timeStamp >= this.sessionAdZones.nextPositionToCheck) {
				cDebug("update adZones: %s >= %s, end %s", timeStamp, this.sessionAdZones.nextPositionToCheck, this.sessionEnd)
				fetchAdZones = true
			}
			if (fetchAdZones && timeStamp && this.Event) {
				try {
					this.getAdZones(this.Event.AvailabilityStartDateTs, this.Event.AvailabilityEndDateTs, timeStamp).then((result) => {
						runInAction(() => { this.sessionAdZones = result; cDebug("NEW AD ZONES %o", this.sessionAdZones) })
					})
				}
				catch (e) {
					console.error("checkAdZones OOPS %o, %o", e, this.Event)
				}
			}

			let adZone = null
			this.sessionAdZones.adZones.forEach(i => {
				//console.debug("checkAdZones %s, %s, %s", moment(i.start).utc().format(TIME_FORMAT_BACKEND), moment(i.end).utc().format(TIME_FORMAT_BACKEND), moment(timeStamp).utc().format(TIME_FORMAT_BACKEND))
				if ((timeStamp >= i.start) && (timeStamp < i.end)) {
					//console.debug("adZone=", i)
					adZone = i
				}
			})

			/*
			if (this.markerPosD && timeStamp >= this.markerPosD - 2000) {
				if (this.adState.linear) this.adState.linear = null //this.adState.linear.skip = false
				if (this.adState.fastFwd) this.setFfwdAdInfo(null)
				this.adZone = null
			} else this.adZone = adZone
			*/
			this.adZone = adZone
			//console.debug("timestamp %s, %s", timeStamp, moment(timeStamp).utc().format(TIME_FORMAT_BACKEND))
		}
		else this.adZone = null
		/*
		if (!this.adZone && this.markerPosD && !this.timeoutId) this.timeoutId = setTimeout(() => {
			//console.debug("setTimeout")
			//this.markerPosD = null
			// MTVW-521: we could be again in an adZone meanwhile
			if (!this.adZone && this.markerPosD) this.setFfwdAdInfo(null)
			this.timeoutId = null
		}, 5000)
		*/
		return this.adZone
	}

	// GT12
	//skipAd() {
	skipAd = flow(function* () {
		if (!this.debounced()) return

		//console.debug("skipAd %o, %o", this.adState, this)
		if (this.adState.fastFwd !== null) {
			cDebug("skip to content after skipStart", this.adZone)
			this.adState.linear = null
			this.markerPosD = this.adZone?.end
			yield this.playAfterAd("skipPromo")
			this.playEventActive = false
			this.setFfwdAdInfo(null)
		}
		else if (this.adState.linear !== null) {
			if (!this.adState.linear.skip) {
				this.adState.linear = null
				this.setFfwdAdInfo(null)
				cDebug("skip to ff ad")
				// MTVW-486
				//this.setJumpFwd()
				if (this.adZone) yield this.handleSetPosition(moment(this.adZone.end).utc())
				//this.markerPosD = moment(this.adZone.end).utc()
				//this.player.setPositionTimeStamp(this.adZone.end)
			}
			else {
				cDebug("skip to D marker")
				if (this.adZone) {
					yield this.player.setPositionTimeStampAsync(this.adZone.end)
					this.markerPosD = this.adZone?.end
				}
			}
		}
	})
}
