import playSvg from "img/player/play_arrow.svg"
import restartSvg from "img/player/play_restart.svg"
import deleteSvg from "img/player/delete.svg"
import deleteWhiteSvg from "img/player/delete_white.svg"
import cancelSvg from "img/player/cancel.svg"
import cancelWhiteSvg from "img/player/cancel_white.svg"
import ffAd_icon from "img/player/ffAd_icon.svg"
import React from "react"

import "./ButtonsStock.css"

// MTVW-811

const getVariant = (bId) => {
	//return computed(() => {
	let variant = "secondary"
	const buttonRect = document.getElementById(bId)?.getBoundingClientRect() ?? null
	//const containerRect = document.getElementById("recordInfoContainer")?.getBoundingClientRect() ?? null
	const containerRect = document.getElementById(bId)?.parentElement?.getBoundingClientRect() ?? null
	// why are all values of the rects sometimes all 0 in the mobile view???
	if (containerRect && buttonRect) variant = containerRect.width > 0 && Math.abs(containerRect.x - buttonRect.x) < 10 && Math.abs(containerRect.y - buttonRect.y) < 10 ? "primary" : "secondary"
	console.debug("boundingRects= %s, %s, cRect %o, bRect %o", bId, variant, containerRect, buttonRect)
	return variant
	//}).get()
}

export const ButtonPlay = (({ bId, label, onClick }) => {
	// TODO: do we need variant?
	//getVariant(bId)
	return (
		<div id={bId} className="buttonBody colorRed" onClick={onClick}>
			<div className="buttonIconContainer">
				<img className="buttonSvg" style={{
					/*borderRadius: 8, overflow: 'hidden', justifyContent: 'flex-start', alignItems: 'center', gap: 1, display: 'inline-flex'*/
				}} src={playSvg}></img>
			</div>
			<div className="buttonLabel labelWhite">{label}</div>
		</div>)
})

export const ButtonLive = (({ bId, label, onClick }) => {
	// TODO: do we need variant?
	//getVariant(bId)
	return (
		<div id={bId} className="buttonBody colorRed" onClick={onClick} style={{ paddingLeft: "unset", paddingRight: "unset" /*, justifyContent: "center"*/ }}>
			<div className="liveFrame1">
				<div className="liveFrame2">
					<div className="buttonIconContainer">
						<div className="liveEllipse" />
					</div>
					<div className="buttonLabel labelWhite">{label?.toUpperCase()}</div>
				</div>
				<div className="liveProgessBarContainer">
					<div className="liveProgressBackground" />
					<div className="liveProgress" />
				</div>
			</div>
		</div>
	)
})

/*
<div className="PlayButton" style={{width: 140, height: 56, background: '#D40037', borderRadius: 8, justifyContent: 'center', alignItems: 'center', gap: 1, display: 'inline-flex'}}>
  <div className="Frame127" style={{paddingTop: 13, paddingBottom: 4, paddingLeft: 4, paddingRight: 4, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 5, display: 'inline-flex'}}>
	 <div className="Frame1" style={{alignSelf: 'stretch', paddingLeft: 32, paddingRight: 24, justifyContent: 'center', alignItems: 'center', gap: 4, display: 'inline-flex'}}>
		<div className="Icon" style={{width: 24, height: 24, position: 'relative'}}>
		  <div className="Ellipse4" style={{width: 16, height: 16, left: 4, top: 4, position: 'absolute', background: '#FFC107', borderRadius: 9999}} />
		</div>
		<div className="Label" style={{color: '#F3F3F3', fontSize: 24, fontFamily: 'Europa-Bold', fontWeight: '700', wordWrap: 'break-word'}}>LIVE</div>
	 </div>
	 <div className="ProgessBar" style={{width: 132, height: 4, position: 'relative'}}>
		<div className="Background" style={{width: 132, height: 4, left: 0, top: 0, position: 'absolute', background: 'rgba(255, 255, 255, 0.30)', borderRadius: 2}} />
		<div className="Progress" style={{width: 49.50, height: 4, left: 0, top: 0, position: 'absolute', background: '#F3F3F3', borderRadius: 2}} />
	 </div>
  </div>
</div>
*/
export const ButtonRestart = (({ bId, label, onClick }) => {
	// TODO: do we need variant?
	//getVariant(bId)
	return (
		<div id={bId} className="buttonBody colorWhite" onClick={onClick}>
			<div className="buttonIconContainer">
				<img className="buttonSvg" src={restartSvg}></img>
			</div>
			<div className="buttonLabel labelBlack">{label}</div>
		</div>
	)
})

export const ButtonRecord = (({ bId, label, onClick }) => {
	const [variant, setVariant] = React.useState("secondary")
	React.useEffect(() => {
		setVariant(getVariant(bId))
	}, [document.getElementById(bId)?.parentElement?.getBoundingClientRect()])

	const bodyColorClass = variant === "primary" ? "colorRed" : "colorWhite"
	const labelColorClass = variant === "primary" ? "labelWhite" : "labelBlack"
	const shapeStyle = variant === "primary" ?
		{ width: 16, height: 16, left: 3, top: 4, position: 'absolute', background: '#C91010', borderRadius: 9999, border: '1.60px white solid' } :
		{ width: 20, height: 20, left: 2, top: 2, position: 'absolute', background: '#C91010', borderRadius: 9999, border: '2px white solid' }

	return (
		<div id={bId} className={`buttonBody ${bodyColorClass}`} style={{ gap: 4 }} onClick={onClick}>
			<div className="buttonIconContainer" >
				<div className="Shape" style={shapeStyle} />
			</div>
			<div className={`buttonLabel ${labelColorClass}`}>{label}</div>
		</div>)
})

export const ButtonDelete = (({ bId, label, onClick }) => {
	const [variant, setVariant] = React.useState("secondary")
	React.useEffect(() => {
		setVariant(getVariant(bId))
	}, [document.getElementById(bId)?.parentElement?.getBoundingClientRect()])

	const bodyColorClass = variant === "primary" ? "colorRed" : "colorWhite"
	const labelColorClass = variant === "primary" ? "labelWhite" : "labelBlack"
	const svg = variant === "primary" ? deleteWhiteSvg : deleteSvg
	const shapeStyle = variant === "primary" ?
		{ width: 16, height: 16, left: 3, top: 4, position: 'absolute', /*background: '#C91010', borderRadius: 9999, border: '1.60px white solid'*/ } :
		{ width: 20, height: 20, left: 2, top: 2, position: 'absolute', /*background: '#C91010', borderRadius: 9999, border: '2px white solid'*/ }

	return (
		<div id={bId} className={`buttonBody ${bodyColorClass}`} onClick={onClick}>
			<div className="buttonIconContainer">
				<img className="buttonSvg" src={svg} style={shapeStyle} ></img>
			</div>
			<div className={`buttonLabel ${labelColorClass}`}>{label}</div>
		</div>
	)
})

export const ButtonCancel = (({ bId, label, onClick }) => {
	const [variant, setVariant] = React.useState("secondary")
	React.useEffect(() => {
		setVariant(getVariant(bId))
	}, [document.getElementById(bId)?.parentElement?.getBoundingClientRect()])

	const bodyColorClass = variant === "primary" ? "colorRed" : "colorWhite"
	const labelColorClass = variant === "primary" ? "labelWhite" : "labelBlack"
	const svg = variant === "primary" ? cancelWhiteSvg : cancelSvg
	const shapeStyle = variant === "primary" ?
		{ width: 20, height: 20, left: 2, top: 2, position: 'absolute', /*background: '#F3F3F3', borderRadius: 9999, border: '1.60px white solid'*/ } :
		{ width: 20, height: 20, left: 2, top: 2, position: 'absolute', /*background: '#C91010', borderRadius: 9999, border: '2px white solid'*/ }

	return (
		<div id={bId} className={`buttonBody ${bodyColorClass}`} onClick={onClick}>
			<div className="buttonIconContainer">
				<img className="buttonSvg" src={svg} style={shapeStyle} ></img>
			</div>
			<div className={`buttonLabel ${labelColorClass}`}>{label}</div>
		</div>
	)
})

export const ButtonPremium = (({ bId, label, onClick }) => {
	// MTVW-849: comment backdropFilter, caused black screen on Chrome/macOS
	return (
		<div id={bId} className="buttonBody colorDark" onClick={onClick}
			style={{ /*width: 241,*/ paddingLeft: 16, paddingRight: 16, paddingTop: 13, paddingBottom: 13, /*background: 'rgba(255, 255, 255, 0.10)',*/ borderRadius: 99999, overflow: 'hidden', border: '1px rgba(255, 255, 255, 0.30) solid', /*backdropFilter: 'blur(4px)', WebkitBackdropFilter: 'blur(4px)', mozbackdropFilter: 'blur(4px)',*/ justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'inline-flex' }}>
			{/*<div className="Icon" style={{ width: 24, height: 24, position: 'relative' }}>
				<div className="BoundingBox" style={{ width: 24, height: 24, left: 0, top: 0, position: 'absolute', background: '#D9D9D9' }} />*/}
			<div className="buttonIconContainer" > {/* style={{ width: 20, height: 20, left: 2, top: 2, position: 'absolute' }} */}
				<img className="buttonSvg" src={ffAd_icon} ></img> {/* style={{ width: 20, height: 20, left: 0, top: 0, position: 'absolute', background: '#F3F3F3' }} */}
			</div>
			{/*</div>*/}
			<div className="buttonLabel labelWhite" >{label}</div>
		</div>
	)
})
