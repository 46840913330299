import { action, computed, /*flow,*/ observable, makeObservable, runInAction } from "mobx"
import { PageAbstract } from "./PageAbstract"
import { rootStore } from "store/RootStore"
import { urlFixHttpProtocolMismatch } from "store/util/urlFixHttpProtocolMismatch"

export class EpgChannelRelatedContent extends PageAbstract {
	req = null

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			req: observable,
			// MTV-3583: do not make it computed here, mobx 6 generates an error if it's already registered in the base class
			//isReady: computed,
			relatedContent: computed,
			loadRelatedContent: action,
			_init: action
		})
	}

	get isReady() {
		//console.debug("isReady", this.req !== null)
		return this.req !== null
	}


	get relatedContent() {
		return this.isReady ? this.req : []
	}

	loadRelatedContent(event) {
		// MTVW-629: use search MS
		const MAX_RESULTS = 12
		//const MAX_RESULTS = 100
		this.req = []
		const filtered = []
		//console.debug("loadRelatedContent", event, event.content.Genres, event.content.OriginalLanguage, rootStore.page.Search)
		if (event?.content?.Genres?.length > 0) {
			rootStore.page.Search.infoGenre(event?.content?.Genres[event.content.Genres.length - 1] + "?lang=" + event?.content?.OriginalLanguage).then((result) => {
				runInAction(() => {
					//console.debug("GOT", result.contents)
					result?.contents?.map((item) => {
						if (item.type === "event") {
							// filter same id
							const isSameId = event?.event?.id === item.data?.event.id
							const isSubscribed = rootStore.page.MsEpg.getChannel(item.data.event.channelId) !== null
							//console.debug("isSameId", isSameId, event?.event?.id, item.data?.event.id)
							if (isSubscribed && !isSameId && filtered.length < MAX_RESULTS) filtered.push(item)

						}
						else if (item.type === "series") {
							//console.debug("channels are", item.data.channels)
							// remove unsubscribed channels
							const fChannels = item.data.channels.filter(i => rootStore.page.MsEpg.getChannel(i))
							if (fChannels.length > 0 && filtered.length < MAX_RESULTS) {
								item.data.channels = fChannels
								//console.debug("pushing", item.data.channels)
								filtered.push(item)
							}
						}
					})
					this.req = filtered
					//console.debug("related result %o, events %o", result, filtered, this)
				})
			}).catch((e) => {
				console.error("loadRelatedContent", e)
			})
		}
	}

	channelId(item) {
		if (item.type === "event") return item?.data.event?.channelId
		else if (item.type === "series") return item?.data?.channels[0]
	}

	channelName(item) {
		if (item.type === "event") return rootStore.page.MsEpg.getChannelName(item?.data?.event?.channelId)
		else if (item.type === "series") {
			let channels = rootStore.page.MsEpg.getChannelName(item?.data?.channels[0])
			for (let i = 1; i < item?.data?.channels?.length; i++) {
				channels += ", " + rootStore.page.MsEpg.getChannelName(item?.data?.channels[i])
			}
			return channels
		}
	}

	title(item) {
		if (item.type === "event") return item?.data?.content?.Title
		else if (item.type === "series") return item?.data?.Title
	}

	id(item) {
		if (item.type === "event") return item?.data?.event?.id
		else if (item.type === "series") return item?.data?.id
	}

	episodeName(item) {
		if (item.type === "event") return item?.data?.content?.EpisodeName
		else if (item.type === "series") return null
	}

	poster(item, tvImg, seriesImg) {
		if (item.type === "event") return item?.data?.content?.posterUrl ? urlFixHttpProtocolMismatch(item?.data?.content?.posterUrl + "?mode=box&w=480&h=270")
			: this.episodeName(item) ? seriesImg : tvImg
		else if (item.type === "series") return item?.data?.posterUrl ? urlFixHttpProtocolMismatch(item?.data?.posterUrl + "?mode=box&w=480&h=270") : seriesImg
	}

	startTs(item) {
		if (item.type === "event") return item?.data.event?.AvailabilityStartTs
		else if (item.type === "series") return null
	}

	endTs(item) {
		if (item.type === "event") return item?.data.event?.AvailabilityEndTs
		else if (item.type === "series") return null
	}

	date(item) {
		if (item.type === "event") return item?.data?.event?.dateAndTime
		else if (item.type === "series") return null
	}

	episodeCount(item) {
		if (item.type === "event") return null
		else if (item.type === "series") return item?.data?.episodeCount
	}

	eventType(item) {
		if (item.type === "event") return item?.data?.event?.eventType
		else if (item.type === "series") return null
	}

	episode(item) {
		if (item.type === "event") return item?.data?.content?.Episode
		else if (item.type === "series") return null
	}

	season(item) {
		if (item.type === "event") return item?.data?.content?.Season
		else if (item.type === "series") return null
	}

	/*
	_initAsync = flow(function* () {
	})
	*/

	_onMount() {
	}

	_init(id) {
		//console.debug("_init %s", id)
		//this._initAsync()
	}
}
