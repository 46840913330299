/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-09
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-11
 */

import { action, computed, observable, makeObservable } from "mobx";
import { TraxisModel } from "store/api/TraxisModel"
import { lazyCallback, lazyObject } from "store/ModelTools"
import { RecordingsManager } from "store/page/recordings/RecordingsManager"
import { PageAbstract } from "./PageAbstract"

const oSettings = {
	single: {
		paramsCallback: param => ({ ...{ _name: "event", isSingle: true, isRecorded: true, isNotEmpty: true, iLimit: 20, isSortDesc: true }, ...param }),
		varName: "oRecSingle"
	},
	series: {
		paramsCallback: param => ({ ...{ _name: "series", isSeries: true, isRecorded: true, isNotEmpty: true, iLimit: 20, isChildRecordingsCount: true, isSortDesc: true }, ...param }),
		varName: "oRecSeries"
	},
	planned: {
		paramsCallback: param => ({ ...{ _name: "planned", isSeries: true, isSingle: true, isPlanned: true, isNotEmpty: true, iLimit: 20, isChildRecordingsCount: true, isSortDesc: true }, ...param }),
		varName: "oRecPlanned"
	},
	series_details: {
		paramsCallback: param => ({
			...{ _name: "series_details", isSeries: true, isSingle: false, isNotEmpty: true, iLimit: 20, isChildRecordings: true, isSortDesc: false },
			...param
		})
	}
}

class RecordingsPageAbstract extends PageAbstract {
	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			currentSettings: computed,
			refreshData: action
		})
	}

	get currentSettings() {
		if (this.routeParams.serieId) {
			return oSettings.series_details
		}
		return oSettings[this.routeParams.type] ?? oSettings.single
	}

	refreshData() { }

	_onMount() {
		this.refreshData()
	}
}

class RecordingsFilter extends PageAbstract {
	isFilterVisible = false
	isSortVisible = false

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			isFilterVisible: observable,
			isSortVisible: observable,
			setMenuFilterShow: action
		})
	}

	setMenuFilterShow(bState = null) {
		console.log("@(bState=%o) .isFilterVisible=%o", bState, !this.isFilterVisible)
		if (bState === null) {
			bState = !this.isFilterVisible
		}
		this.isFilterVisible = bState
		this.isSortVisible = false
		return this
	}
}

class RecordingsIndexPage extends RecordingsPageAbstract {

	//MTV-2881 need show max 7 but need to know if are more than 7 
	oRecSingle = this._root.api.GetRecordings(oSettings.single.paramsCallback({ iLimit: 20 }))

	oRecSeries = this._root.api.GetRecordings(oSettings.series.paramsCallback({ iLimit: 20 }))

	oRecPlanned = this._root.api.GetRecordings(oSettings.planned.paramsCallback({ iLimit: 20 }))

	constructor(parent, path) {
		super(parent, path)
		// MTVW-500
		setInterval(() => this.refreshData(), 30000)
	}

	refreshData() {
		this.oRecSingle.fetchData()
		this.oRecSeries.fetchData()
		this.oRecPlanned.fetchData()

		if (this._parent?.oRecCustomer) this._parent.oRecCustomer._api.fetchData()
		// MTVW-754
		const pageWrapper = document.getElementById("pageWrapper")
		// MTVW-767: check if called from Live TV context for creating a recording
		if (pageWrapper && !rootStore.page.Player.isInLiveTv) pageWrapper.style.width = "100vw"
	}

	// MTVW-500
	/*
	isRecordedAsSingle(seriesId) {
		this.oRecSingle.fetchData()
		const isSingle = this.oRecSingle.DataArr.filter(i => i.SeriesId === seriesId)?.length > 0 ? true : false
		console.debug("isRecordedAsSingle id %s, recorded %o", seriesId, isSingle, this.oRecSingle.DataArr)
		return isSingle
	}
	*/
	//
	/* MTVW-518: comment unused functions
	/*
	isRecordedAsSingle(eventId) {
		//this.oRecSingle.fetchData()
		this.refreshData()
		const isSingle = this.oRecSingle.DataArr.filter(i => i.EventId === eventId)?.length > 0 ? true : false
		//console.debug("isRecordedAsSingle id %s, recorded %o", eventId, isSingle, this.oRecSingle.DataArr)
		return isSingle
	}

	isRecordedAsSeries(seriesId) {
		//this.oRecSeries.fetchData()
		this.refreshData()
		const isRecorded = this.oRecSeries.DataArr.filter(i => i.SeriesId === seriesId)?.length > 0 ? true : false
		//console.debug("isRecordedAsSeries id %s, recorded %o", seriesId, isRecorded, this.oRecSeries.DataArr)
		//return this.oRecPlanned.DataArr.filter(i => i.SeriesId === seriesId)?.length > 0 ? true : false
		return isRecorded
	}

	isPlanned(seriesId) {
		//this.oRecPlanned.fetchData()
		this.refreshData()
		const isPlanned = this.oRecPlanned.DataArr.filter(i => i.SeriesId === seriesId)?.length > 0 ? true : false
		//console.debug("isPlanned id %s, planned %o", seriesId, isPlanned, this.oRecPlanned.DataArr)
		//return this.oRecPlanned.DataArr.filter(i => i.SeriesId === seriesId)?.length > 0 ? true : false
		return isPlanned
	}
	*/
}

class RecordingsList extends RecordingsPageAbstract {
	_recList = null
	listName

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			recList: computed
		})
	}

	get isSerie() {
		return !!this.routeParams.serieId
	}

	get recList() {
		if (this.routeParams.serieId) {
			const isPlanned = this.routeParams.type === "planned"
			this._recList = this._root.api.GetRecordings(this.currentSettings.paramsCallback({ Ids: this.routeParams.serieId, isPlanned, isRecorded: !isPlanned, isSortDesc: !isPlanned })).fillData()
			this.listName = this.currentSettings.varName
		} else if (this._recList === null || this.currentSettings.varName !== this.listName) {
			// create correct item list if not exists
			this._recList = this._root.api.GetRecordings(this.currentSettings.paramsCallback({ iLimit: 1000 })).fillData()
			this.listName = this.currentSettings.varName
		}
		// fetch correct item
		if (this._recList !== null && this._recList.isReady) {
			return this._recList
		}
		// maybe we can speed up
		if (this._parent.IndexPage?.[this.currentSettings.varName]?.isReady) {
			return this._parent.IndexPage?.[this.currentSettings.varName]
		}
		// empty list until we fetch sth
		return new TraxisModel()
	}

	refreshData() {
		if (this._recList) {
			this._recList.fetchData()
		}
		this._parent.oRecCustomer._api.fetchData()
	}
}

// store of all subclass
export class Recordings extends RecordingsPageAbstract {
	RecordingsFilter = lazyObject(this, "RecordingsFilter", RecordingsFilter)

	/**
	 * @returns {GetCustomerModel}
	 */
	get oRecCustomer() {
		return lazyCallback(this, "oRecCustomer", () => this._root.page.singleton.customer.Data)
	}

	/**
	 * @returns {RecordingsIndexPage}
	 */
	get IndexPage() {
		return lazyObject(this, "IndexPage", RecordingsIndexPage)
	}

	/**
	 * @returns {RecordingsList}
	 */
	get ListPage() {
		return lazyObject(this, "ListPage", RecordingsList)
	}

	/**
	 * @returns {RecordingsManager}
	 */
	get Manager() {
		return lazyObject(this, "Manager", RecordingsManager)
	}
}
