/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-08
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-11
 */

import { computed, makeObservable } from "mobx"
import { types as t } from "mobx-state-tree"
import { TraxisConditionAnd, TraxisConditionOr } from "store/api/TraxisCondition"
import { Pictures } from "store/model/traxis/Picture"
import { Titles_Collection, Title_Genres_Part, Title_SeriesCollection_Collection } from "store/model/traxis/Title"
import { rootStore } from "store/RootStore"
import { moment } from "store/util/moment"
import { TraxisAbstract } from "store/api/TraxisAbstract"

const Recording_Titles_Collection = Titles_Collection("", {
	_Title: t.compose(
		"_Title",
		Title_Genres_Part,
		Title_SeriesCollection_Collection,
		t.model({
			id: t.identifier,
			Name: t.string,
			PersonalBookmark: t.number,
			EpisodeName: t.maybeNull(t.string),
			IsPersonallyViewedCompletely: t.boolean,
			Pictures: t.maybeNull(Pictures)
		})
	)
})

const GetRecordingsModel_Base = t
	.model("Base", {
		id: t.identifier,
		Name: t.string,
		ProfileId: t.maybeNull(t.string),
		IsPersonal: t.boolean,
		GuardTimeStart: t.string,
		GuardTimeEnd: t.string,
		// MTVW-500
		SeriesId: t.maybeNull(t.string),
		CreateTime: t.maybeNull(t.string),
	})
	.views(self => ({
		get childRecordingsCount() {
			return self?.ChildRecordings?.resultCount ?? null
		},
		get BookmarkProgress() {
			return 0
		},
		get hasProfileNickname() {
			return !!rootStore.sso.getProfile(self.ProfileId)
		},
		get ProfileNickname() {
			return rootStore.sso.getProfile(self.ProfileId)?.Nickname
		},
		get isCurrentProfile() {
			return self.ProfileId === rootStore.sso.profile?.id ?? false
		},
		get Recordings() {
			return []
		}
	}))

const GetRecordingsModel_Event = GetRecordingsModel_Base.named("Event")
	.props({
		Type: t.literal("Event"),
		Titles: Recording_Titles_Collection,
		Channels: t.maybeNull(
			// MTVW-516: added above t.maybeNull
			t.model({
				resultCount: t.number,
				Channel: t.array(
					t.model({
						id: t.string,
						Name: t.maybeNull(t.string),
						Pictures: t.maybeNull(Pictures),
						NetworkRecordingEncodingProfiles: t.model({
							NetworkRecordingEncodingProfile: t.array(t.model({ Value: t.string, compatible: t.string }))
						}),
						Products: t.maybeNull(
							t.model({
								resultCount: t.number,
								Product: t.array(t.model({ id: t.string, EntitlementState: t.string }))
							})
						)
					})
				)
			})
		),
		StartTime: t.string,
		EndTime: t.string,
		CreateTime: t.string,
		DurationInSeconds: t.number,
		State: t.string,
		EventId: t.string,
		TitleId: t.string,
		ParentRecordings: t.maybeNull(
			t
				.model({
					resultCount: t.number,
					Recording: t.array(
						t.model({
							id: t.string
						})
					)
				})
				.views(self => ({
					get id() {
						return self?.Recording?.[0]?.id ?? null
					}
				}))
		),
		Contents: t.maybeNull(
			t.model({
				resultCount: t.number,
				Content: t.array(
					t.model({
						id: t.string,
						Products: t.model({
							resultCount: t.number,
							Product: t.array(t.model({ id: t.string, RelationAvailabilityEnd: t.string }))
						}),
						IsHD: t.boolean
					})
				)
			})
		)
	})
	.volatile(self => ({
		isSeries: false
	}))
	.views(self => ({
		get Channel() {
			// MTVW-516: Channels might not be present
			return self.Channels?.Channel?.[0]
		},
		get Title() {
			return self.Titles?.Title?.[0]
		},
		get Content() {
			return self.Contents?.Content?.[0]
		},
		StartTimeDateFormat(format) {
			return moment(self.StartTime).format(format)
		},
		EndTimeDateFormat(format) {
			return moment(self.EndTime).format(format)
		},
		CreateTimeDateFormat(format) {
			return moment(self.CreateTime).format(format)
		},
		get BookmarkProgress() {
			return self.DurationInSeconds && self.Title.PersonalBookmark ? Math.max(0, Math.min(Math.round((self.Title.PersonalBookmark / self.DurationInSeconds) * 100), 100)) : 0
		}
	}))

const GetRecordingsModel_Series = GetRecordingsModel_Base.named("Series")
	.props({
		Type: t.literal("Series"),
		SeriesCollection: t.model({
			resultCount: t.number,
			Series: t.array(
				t.model({
					id: t.string,
					Name: t.string,
					NumberOfEpisodes: t.maybeNull(t.number),
					ParentSeriesCollection: t.maybeNull(
						t.model({
							resultCount: t.number,
							Series: t.array(
								t.model({
									id: t.string,
									RelationOrdinal: t.maybeNull(t.number)
								})
							)
						})
					)
				})
			)
		}),
		ChildRecordings: t.maybeNull(
			t.model({
				resultCount: t.number,
				Recording: t.array(GetRecordingsModel_Event)
			})
		)
	})
	.volatile(self => ({
		isSeries: true
	}))
	.views(self => ({
		get Channel() {
			// MTVW-646: adult recordings are not requested, as a consequence Channels are not returned,
			// but recordings are still returned and now filtered out in MovieTile
			//return self?.ChildRecordings?.Recording[0]?.Channels.Channel?.[0]
			return self?.ChildRecordings?.Recording[0]?.Channels?.Channel?.[0]
		},
		get Title() {
			return self?.ChildRecordings?.Recording[0]?.Titles?.Title?.[0]
		},
		get Recordings() {
			return self?.ChildRecordings?.Recording ?? []
		}
	}))

const RecordingsModel = t
	.model({
		resultCount: t.number,
		moreResources: t.maybeNull(t.boolean),
		Recording: t.array(t.union(GetRecordingsModel_Event, GetRecordingsModel_Series)),
		isReady: t.maybeNull(t.boolean)
	})
	// added for mobx 6.3 which is more strict
	.actions(self => ({
		setReady(value) {
			self.isReady = value
		}
	}))

// EventGone, Recording,, Complete, Pending, Scheduled, NotYetRecording, PartiallyRecording, Failed, Partial, Deleted, ChannelGone
// planned: EventGone, Recording, Pending, Scheduled, NotYetRecording, Failed, ChannelGone
// done: Complete, PartiallyRecording, Partial,
// else:	Deleted
export const filterStatePlanned = TraxisConditionOr()
	.add("State==EventGone")
	.add("State==Recording")
	.add("State==Pending")
	.add("State==Scheduled")
	.add("State==NotYetRecording")
	.add("State==Failed")
	.add("State==ChannelGone")

export const filterStateRecorded = TraxisConditionOr()
	.add("State==Complete")
	.add("State==PartiallyRecording")
	.add("State==Partial")

export class GetRecordingsAbstract extends TraxisAbstract {
	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			isById: computed,
			isByIds: computed,
			DataArr: computed
		})
	}

	get _model() {
		return RecordingsModel
	}

	_getParseResp(data) {
		return data.Recordings
	}

	get _RecordingProps() {
		return []
	}

	//_getHashReq(data) {
	//	return ``
	//}

	get isById() {
		return this._oVars.Ids !== null
	}

	get isByIds() {
		return this._oVars.Ids !== null && Array.isArray(this._oVars.Ids)
	}

	get DataArr() {
		if (this.isById && !this.isByIds) {
			return this.isReady && this.Data && this.Data.Recording?.[0]?.Recordings ? this.Data.Recording[0].Recordings : []
		}
		return this.isReady && this.Data && this.Data.Recording ? this.Data.Recording : []
	}

	get DataMain() {
		return this.isReady ? this.Data?.Recording?.[0] : null
	}

	_getSortBy() {
		return this._oVars.isSeries ? "~CreateTime" : this._oVars.isSortDesc ? "~StartTime" : "StartTime"
	}

	_getFilterRecordingsMain() {
		return (
			TraxisConditionAnd()
				.setAsFilterOption()
				.add(
					//maybe Event | TimeBased | Series | RecurringTimeBased
					TraxisConditionOr()
						.setState(!this._oVars.SeriesId && !this._oVars.EventId)
						// for Event | TimeBased - single rec
						.add(
							TraxisConditionAnd()
								.setState(!!this._oVars.isSingle)
								// MTVW-132
								//.add("Channels>0")
								.add(TraxisConditionOr(["Type==Event", "Type==TimeBased"]))
								.add("ParentRecordingCount==0")
								//.add("(State==Scheduled||State==Failed)", !!this._oVars.isPlanned)
								//.add("(State==Complete||State==Failed)", !!this._oVars.isRecorded)
								.add(
									TraxisConditionOr()
										.add(filterStatePlanned, !!this._oVars.isPlanned)
										.add(filterStateRecorded, !!this._oVars.isRecorded)
								)
								.add(this._oVars.timeFilter ?? null)
								.add("Titles>0", !!this._oVars.isNotEmpty)
						)
						// for Series | RecurringTimeBased
						.add(
							TraxisConditionAnd()
								.setState(!!this._oVars.isSeries)
								.add(TraxisConditionOr(["Type==Series", "Type==RecurringTimeBased"]))
								.add("ChildRecordings>0", !!this._oVars.isNotEmpty)
							//.add("(State==Scheduled||State==Failed)", !!this._oVars.isPlanned)
							//.add("(State==Complete||State==Failed)", !!this._oVars.isRecorded)
						)
				)
				//.add(TraxisConditionOr(["Type==Series", "Channels>0"]))
				.add(
					TraxisConditionOr()
						.add("IsPersonal==false")
						.add(`ProfileId=="${this.profileId}"`)
				)
				.add(`ProfileId==${this.profileId}`, !!this._oVars.isOnlyMine)
				.add(`SeriesId=="${this._oVars.SeriesId}"`, !!this._oVars.SeriesId)
				.add(`EventId=="${this._oVars.EventId}"`, !!this._oVars.EventId)
		)
	}

	_getFilterRecordingsChild() {
		return (
			TraxisConditionAnd()
				.setAsFilterOption()
				// MTVW-132
				//.add("Channels>0")
				.add(
					TraxisConditionOr()
						.add(filterStatePlanned, !!this._oVars.isPlanned)
						.add(filterStateRecorded, !!this._oVars.isRecorded)
				)
				//.add("(State==Scheduled||State==Failed)", !!this._oVars.isPlanned)
				//.add("(State==Complete||State==Failed)", !!this._oVars.isRecorded)
				.add("Titles>0", !!this._oVars.genreFilter)
				.add(this._oVars.timeFilter ?? null)
		)
	}

	_getFilterTitles() {
		return TraxisConditionAnd()
			.setAsFilterOption()
			.getClone()
			.add(
				TraxisConditionOr()
				// MTVW-526: don't filter adult titles (it would generate a runtime error when no Titles are returned)
				//.add("IsAdult==false")
				//.add("IsAdult==true", !!this._oVars.isAdult)
			)
			.add(this._oVars.genreFilter ?? null)
	}

	getXmlReq() {
		const sortBy = this._getSortBy()
		const recordingsFilter = this._getFilterRecordingsMain()
		const childRecordingsFilter = this._getFilterRecordingsChild()
		const titlesFilter = this._getFilterTitles()

		return `<?xml version="1.0" encoding="utf-8"?>
  <Request xmlns="urn:eventis:traxisweb:1.0">
	  <Identity>
		  <ProfileId>${this.profileId}</ProfileId>
	  </Identity>
	  ${this.isById
				? `<ResourcesQuery resourceType="Recording"><ResourceIds>
					  ${this.isByIds ? this._oVars.Ids.map(v => `<ResourceId>${v}</ResourceId>`).join("") : `<ResourceId>${this._oVars.Ids}</ResourceId>`}
				  </ResourceIds>`
				: `<RootRelationQuery relationName="Recordings">`
			}
		<Options>
		  <Option type="Props">${this._recordingProps.join(",")}</Option>
		  ${sortBy !== null && !this.isById ? `<Option type="Sort">${sortBy}</Option>` : ""}
		  ${!this.isById ? recordingsFilter : ""}
		  ${!this.isById ? `<Option type="Paging">${this._oVars.iOffset ?? 0},${this._oVars.iLimit ?? 1000},rc</Option>` : ""}
		</Options>
		<SubQueries>
			<SubQuery relationName="ParentRecordings">
			 <Options>
			 </Options>
		  </SubQuery>
  
		  <SubQuery relationName="Contents">
			 <Options>
				<Option type="Props">IsHD</Option>
				<Option type="Filter"><![CDATA[IsViewableOnCpe==true&&Products>0]]></Option>
			 </Options>
			 <SubQueries>
				<SubQuery relationName="Products">
				  <Options>
					 <Option type="Props">RelationAvailabilityEnd</Option>
					 <Option type="Filter"><![CDATA[RelationAvailabilityEnd>now()]]></Option>
				  </Options>
				</SubQuery>
			 </SubQueries>
		  </SubQuery>
  
		  <SubQuery relationName="Channels">
			 <Options>
				<Option type="Props">Name,Pictures,NetworkRecordingEncodingProfiles</Option>
				<Option type="Filter"><![CDATA[IsAdult==false&&Products>0]]></Option>
			 </Options>
			 <SubQueries>
				  <SubQuery relationName="Products">
						<Options>
							 <Option type="Props">EntitlementState</Option>
							 <Option type="Filter"><![CDATA[EntitlementState==Entitled]]></Option>
						</Options>
				  </SubQuery>
			 </SubQueries>
		  </SubQuery>
  
		  <SubQuery relationName="Titles">
			 <Options>
				<Option type="Props">PersonalBookmark,Name,EpisodeName,IsPersonallyViewedCompletely,Pictures,Genres</Option>
				${titlesFilter}
			 </Options>
		  </SubQuery>
  
		  <SubQuery relationName="SeriesCollection">
			 <Options>
				${/* Requires name property to extract season number */ ""}
				<Option type="Props">Name,RelationOrdinal,NumberOfEpisodes</Option>
			 </Options>
			 <SubQueries>
				<SubQuery relationName="ParentSeriesCollection">
				  <Options>
					 ${/* RelationOrdinal == Season Number */ ""}
					 <Option type="Props">RelationOrdinal</Option>
				  </Options>
				</SubQuery>
			 </SubQueries>
		  </SubQuery>
		  
		  ${this._oVars.isChildRecordings || this._oVars.isChildRecordingsCount
				? `
		  <SubQuery relationName="ChildRecordings">
			  <Options>
			  <Option type="Props">${this._recordingProps.join(",")}</Option>
			  ${sortBy !== null ? `<Option type="Sort">${this._oVars.isSortDesc ? "~StartTime" : "StartTime"}</Option>` : ""}
			  ${childRecordingsFilter}
			  ${this._oVars.isChildRecordingsCount === true
					? `
			  <Option type="Propset">none</Option>
			  <Option type="Paging">0,1,tc</Option>
			  `
					: ""
				}
			  </Options>
			  <SubQueries>
				  <SubQuery relationName="Contents">
					  <Options>
						  <Option type="Props">IsHD</Option>
						  <Option type="Filter"><![CDATA[IsViewableOnCpe==true&&Products>0]]></Option>
					  </Options>
					  <SubQueries>
						  <SubQuery relationName="Products">
							  <Options>
							  <Option type="Props">RelationAvailabilityEnd</Option>
							  <Option type="Filter"><![CDATA[RelationAvailabilityEnd>now()]]></Option>
							  </Options>
						  </SubQuery>
					  </SubQueries>
				  </SubQuery>
				  <SubQuery relationName="Titles">
					  <Options>
					  <Option type="Props">PersonalBookmark,Name,EpisodeName,IsPersonallyViewedCompletely,Pictures,Genres</Option>
					  ${titlesFilter}
					  </Options>
					  <SubQueries>
						  <SubQuery relationName="SeriesCollection">
							  <Options>
								  ${/* Requires name property to extract season number */ ""}
								  <Option type="Props">Name,RelationOrdinal,NumberOfEpisodes</Option>
							  </Options>
						  </SubQuery>
					  </SubQueries>
				  </SubQuery>
				  <SubQuery relationName="Channels">
					  <Options>
						  <Option type="Props">Name,Pictures,NetworkRecordingEncodingProfiles</Option>
						  <Option type="Filter"><![CDATA[(Blocked==false||isnull(Blocked))&&IsAdult==false]]></Option>
					  </Options>
					  <SubQueries>
						  <SubQuery relationName="Products">
							  <Options>
								  <Option type="Props">EntitlementState</Option>
								  <Option type="Filter"><![CDATA[EntitlementState==Entitled]]></Option>
							  </Options>
						  </SubQuery>
					  </SubQueries>
				  </SubQuery>
			 </SubQueries>
		  </SubQuery>`
				: ""
			}
		</SubQueries>
		${this.isById ? "</ResourcesQuery>" : " </RootRelationQuery>"}
	
  </Request>`
	}
}

export class GetRecordings extends GetRecordingsAbstract {
	get _recordingProps() {
		return [
			"Name",
			"StartTime",
			"EndTime",
			"CreateTime",
			"DurationInSeconds",
			"Interval",
			"Type",
			"State",
			"EventId",
			"TitleId",
			"Bookmark",
			"ProfileId",
			"IsPersonal",
			"GuardTimeStart",
			"GuardTimeEnd",
			"AvailabilityStart",
			"AvailabilityEnd",
			"IsDeleteProtected",
			"IsPinProtected",
			"Overbooked",
			"SeriesId",
			"ChildRecordingCount",
			"FactoryState"
		]
	}
}
