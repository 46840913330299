/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-08
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-11
 */

import { action, computed, flow, observable, makeObservable, runInAction } from "mobx"
// MTV-2172: disable screenfull
//import screenfull from "screenfull"
import { ErrorBase } from "store/ErrorBase"
import { lazyObject } from "store/ModelTools"
import { Observe } from "store/page/mixin/ObserveMixin"
import { PageAbstract } from "store/page/PageAbstract"
import { PlayerFake } from "store/page/player/PlayerAbstract"
import { PlayerSettings } from "store/page/player/PlayerSettings"
import { SourceChannelEmpty } from "store/page/player/source/SourceChannelEmpty"
import { SourceChannelLive } from "store/page/player/source/SourceChannelLive"
import { SourceChannelReplay } from "store/page/player/source/SourceChannelReplay"
import { SourceContentPlaylist } from "store/page/player/source/SourceContentPlaylist"
//import { PlayerVo } from "store/page/player/visualon/PlayerVo"
//import { PlayerVjs } from "store/page/player/videojs/PlayerVjs"
import { rootStore } from "store/RootStore"
import { IMPUT_CHANNEL_LIST_FILTER_ID } from "scenes/components/filterChannelInput/FilterChannelInput"
import { browserName, browserOs, isAndroid, isDesktop, isIphone, isIpad, isSafari, isMobileScreenSize, screenInfo, sleep } from "utils/Utils"
import { EventEmitter } from "components/utils/EventEmitter"
import { Wait } from "store/util/Wait"
import { moment } from "store/util/moment"
import { ROUTE_NAMES } from "site-ql/RouteConst"
import { useHistoryRouteAdd } from "components/utils/RouteUtils"
import debounce from "lodash/throttle"
import { Mutex } from 'async-mutex'

let PlayerImpl = null
// called at early initialization in RootStore
export const dynamicPlayerImport = flow(function* () {
	if (PlayerImpl) return
	console.debug("dynamicPlayerImport")
	if (import.meta.env.VITE_PLAYER === "vjs") {
		const module = yield import("store/page/player/videojs/PlayerVjs")
		PlayerImpl = module.PlayerVjs
	}
	else if (import.meta.env.VITE_PLAYER === "vo") {
		const module = yield import("store/page/player/visualon/PlayerVo")
		PlayerImpl = module.PlayerVo
	}
	console.debug("dynamicPlayerImport done")
})

//let userAgentPlayerSet = false

export class Player extends PageAbstract {
	// could change so direct start no lazy
	source = new SourceChannelEmpty(this, "source")
	error = null
	// MTVW-727b
	pipPlayer = null

	/**
	 * @returns {PlayerImpl}
	 */
	//playerLiveTvVo = lazyObject(this, "playerLiveTvVo", PlayerVo)
	playerLiveTvVo = lazyObject(this, "playerLiveTvVo", PlayerImpl)

	/**
	 * @returns {PlayerImpl}
	 */
	//playerDetailPageVo = lazyObject(this, "playerDetailPageVo", PlayerVo)
	playerDetailPageVo = lazyObject(this, "playerDetailPageVo", PlayerImpl)
	playerFake = lazyObject(this, "playerFake", PlayerFake)

	//playerAds = lazyObject(this, "playerAds", PlayerVo)
	playerAds = lazyObject(this, "playerAds", PlayerImpl)

	// MTVW-444
	playerFg = null
	playerBg = lazyObject(this, "playerBg", PlayerImpl)

	settings = lazyObject(this, "settings", PlayerSettings)

	_observer = new Observe(this, "_observer")

	_tLastUsage = []
	_isLastUsed = false
	isMouseOver = false
	isAdsPlayer = false
	//refElem = null

	remount = false
	_waitDispose = null
	fsMainCtrlStyle = null
	fsCtrlStyle = null
	_adStyleInfo = {
		// NOTE: valid in the observable / computed struct did not work reliably on orientationchange with iPad/iPhone
		// -> separate observable adStyleValidCounter
		//valid: false,
		videoRight: null,
		videoWidth: null,
		videoTop: null,
		videoBottom: null,
		videoBottomOffset: null
	}
	adStyleValidCounter = 0

	activePlayer = null
	inactivePlayer = null
	tvRef = null
	bgRef = null
	lastChange = { ch: "", t: Date.now() }
	// MTVW-795: for multiple live TV channel switching
	fastChannelId = null
	// MTVW-795: to discard old entries when fast switching multiple live TV channels
	channelQueue = []
	// MTVW-795: mutexes for proper synchronization and session / keep alive handling
	mutexChangeChannel = new Mutex()
	mutexPlayChannel = new Mutex()

	lastTimeStamp = 0
	historyRouteAdd = useHistoryRouteAdd()

	constructor(parent, path) {
		//console.debug("Player constructor", new Error().stack)
		super(parent, path)
		makeObservable(this, {
			remount: observable,
			remountPlayerComponent: action,
			source: observable.ref,
			error: observable.ref,
			isMouseOver: observable,
			isAdsPlayer: observable,
			playerFg: observable,
			pipPlayer: observable,
			fastChannelId: observable,
			source: observable,
			isControlsVisible: computed,
			isPiPButtonEnabled: computed,
			_parseKeyDown: action.bound,
			_parseKeyUp: action.bound,
			setMouseOver: action,
			activateAdsPlayer: action,
			deactivateAdsPlayer: action,
			setPlayerFg: action,
			initPlayerControls: action,
			adjustPlayerStyles: action,
			_adjustPlayerStyles: action,
			_setSource: action,
			_init: action,
			// MTVW-733: ultrawidescreen support
			// https://tarun-kalra.medium.com/a-simple-guide-to-mobx-practical-examples-ed0e8279e884
			_adStyleInfo: observable,
			adStyleValidCounter: observable,
			adStyleInfo: computed.struct,
			orientationChange: action,
			setPiPPlayer: action,
			pipCloseEvent: action,
			handleChangeChannel: action
		})
		EventEmitter.subscribe("disposedEvent", (event) => {
			console.debug("received disposedEvent", event, this.player)
			//if (event === "playerLiveTvVo")
			this._waitDispose?.setResolve()
		})
	}

	remountPlayerComponent = flow(function* () {
		console.debug("remountPlayerComponent", this.player)
		// MTVW-727b: canResetPlayer condition, prevent player stop when changing tabs in the app
		// Safari has some issues in PiP when changing channels in live TV
		if (!this.player.canResetPlayer() || (this.player.isInPip && ((isSafari() /*|| isIpad()*/) && this.player.__path === "playerLiveTvVo"))) {
			//console.debug("returning")
			// Safari: inform the player about live chnannel change
			this.player.liveChannelChange = true
			return
		}
		// autoplay muted issue on iPhone
		/*
		if (isIphone() || isIpad() || isSafari()) {
			this.remount = true
			yield sleep(20)
			this.remount = false
			yield sleep(20)
		}
		else {*/
		this._waitDispose = new Wait(this, "waitDispose")
		this._waitDispose.setReset()
		//if (!isIphone()) {
		// MTVW-444, MTVW-788, MTVW-787
		/*
		this.setPlayerFg(null)
		yield this.player.setRefElemContainer(null, true)
		yield this.playerBg.setRefElemContainer(null, true)
		*/
		if (this.playerFg) {
			yield this.inactivePlayer.stopInstance()
			console.debug("wait= playerFg")
			yield this._waitDispose.waitAsync()
			this.setPlayerFg(null)
			this._waitDispose.setReset()
		}
		yield this.playerAds.stopInstance()
		console.debug("wait= playerAds")
		yield this._waitDispose.waitAsync()
		this._waitDispose.setReset()
		console.debug("wait= player")
		yield this.player.stopInstance()
		///yield this.playerAds.setRefElemContainer(null, true)
		//}
		///this.remount = true
		yield this._waitDispose.waitAsync()
		///this.remount = false
		//}
	})

	_setSource(source, info) {
		//console.trace("setting source", info, source)
		this.source = source
	}

	// MTVW-795: Moved from EpgChannel.jsx
	handleChangeChannel = flow(function* (channelId) {
		// sometimes a click from the trackpad (on Mac) is fired twice
		// https://stackoverflow.com/questions/50819162/why-is-my-function-being-called-twice-in-react

		const tDiff = Date.now() - this.lastChange.t
		const lastCh = this.lastChange.ch
		console.debug("player handleChangeChannel ch %s, last %o, tDiff %s", channelId, this.lastChange, tDiff)
		this.lastChange = { ch: channelId, t: Date.now() }
		if (lastCh === channelId && tDiff < 1000) return
		//const currEvent = page.getChannelEpg(i.Channel.id)
		this.fastChannelId = channelId
		//console.debug("fastChannelId", this.fastChannelId)
		//console.debug("currEvent", currEvent, page._pageStore)
		//yield page._pageStore.OverlayEventDetails.setEpgEventById(currEvent?.Event?.id, false)

		// if the live TV page is loaded, this.setPlayChannelLiveAsync() is invoked,
		// wait until it has been finished for proper session / keep alive handling
		const relPlayChannel = yield this.mutexPlayChannel.acquire()
		relPlayChannel()

		if (this.mutexChangeChannel.isLocked()) {
			console.debug("channelQueue push", channelId)
			this.channelQueue.push(channelId)
		}
		const release = yield this.mutexChangeChannel.acquire()
		//console.debug("channelQueue acquire", this.channelQueue)
		if (this.channelQueue.length > 1) {
			// optimization not all queued channel changes need to be executed
			this.channelQueue.shift()
			release()
			//console.debug("returning")
			return
		}
		else if (this.channelQueue.length > 0) this.fastChannelId = this.channelQueue.pop()
		if (this.channelQueue.length > 0) console.warn("channelQueue > 0")
		try {
			// MTV-3580: fix problem caused by MTV-3012. Don't make snapshot on channel change and reset it
			// hide controls
			this.playerLiveTvVo.setIsPosterVisible(true, "handleChangeChannel", true)
			// avoid display of close button and player controls for transient playerDetailPageVo
			this.playerDetailPageVo.setIsPosterVisible(true, "handleChangeChannel", true)
			rootStore.page.LiveTv.memoSelectedEpg = null
			// done in player (MTV-1532)
			//if (page.player.player.isPausedUser) { yield page.player.player.setPlay("handleChangeChannel") }
			// MTVW-275: Make sure channel is unpaused, see also MTVW-213
			this.playerLiveTvVo.setIsPausedUser(false)
			if (channelId !== null) {
				rootStore.page.ChannelListRepo.setActiveChannel(channelId)
			}
			// MTVW-577: stop keep alive
			if (import.meta.env.VITE_PLAYER === "vjs") {
				yield this.remountPlayerComponent()
				//page.player.playerLiveTvVo.setClose()
				// VJS: added for switching channels in liveTv
				// interactionEvent will be called
				//yield this.playerLiveTvVo.setRefElemContainer(null, true)
				//yield this.playerAds.setRefElemContainer(null, true)
			}
			else if (import.meta.env.VITE_PLAYER === "vo") {
				yield this.playerLiveTvVo.setClose()
			}
			//console.debug("handleChangeChannel=", channelId)
			// will call this.setPlayChannelLiveAsync()
			//console.debug("calling handlePlayChannelAsync handleChangeChannel")
			const elem = document.getElementById("tvLiveAndDetailsContainer")
			if (elem) elem.scrollTop = 0
			yield rootStore.page.LiveTv.handlePlayChannelAsync(channelId)
			this.fastChannelId = null
			release()
		}
		catch (e) {
			console.error("handleChangeChannel", e)
			this.fastChannelId = null
			this.channelQueue = []
			release()
		}
	})

	get player() {
		//console.debug("store player %o, %s", this, this.isAdsPlayer)
		let player = null
		if (this.isAdsPlayer) player = this.playerAds
		///else if (this.playerFg) player = this.playerFg
		else if (this.playerFg) player = this.inactivePlayer
		else if (this.activePlayer) player = this.activePlayer
		else if (this.playerLiveTvVo.isPlayerActive) player = this.playerLiveTvVo
		else player = this.playerDetailPageVo ? this.playerDetailPageVo : this.playerFake
		return player
	}

	// MTVW-788, MTVW-787: tvRef, bgRef
	setTvRef(tvRef) {
		this.tvRef = tvRef
		//console.debug("tvRef", this.tvRef)
	}

	setBgRef(bgRef) {
		this.bgRef = bgRef
		//console.debug("bgRef", this.bgRef)
	}

	// MTVW-727b
	get isInLiveTv() {
		return document.getElementById("listAndFilterComponent") !== null
	}

	// MTVW-727b
	setPiPPlayer(player) {
		// reset only pipPlayer, leave pipPlayer.isInPip when starting show in overlay
		//if (!player && this.pipPlayer) this.pipPlayer.isInPip = false
		this.pipPlayer = player
	}

	// MTVW-727b
	get isInLiveTv() {
		return document.getElementById("listAndFilterComponent") !== null
	}

	// MTVW-727b
	pipCloseEvent() {
		if (this.player?.__path === "playerLiveTvVo" && !this.isInLiveTv) {
			//this.player.setRefElemContainer(null, true)
			this.player.stopInstance()
		}
		//this.remountPlayerComponent()
		// Need to close MiniEpg, otherwise we cannot go back to the current tab (cannot push same path)
		if (document.getElementById("headMiniEpg")) rootStore.page.MiniEpg.setShow(false)
		setTimeout(() => {
			if (this.pipPlayer?.__path === "playerLiveTvVo" && !this.isInLiveTv) this.historyRouteAdd(ROUTE_NAMES.LiveTv)
			runInAction(() => { this.pipPlayer = null })
		}, 50)
		//if (this.pipPlayer?.__path === "playerLiveTvVo" && !this.isInLiveTv) this.historyRouteAdd(ROUTE_NAMES.LiveTv)
	}

	// MTVW-808: Added amd commented check for closeLayer for enabling pushActivity (back button)
	get isPlayerOnOverlay() {
		return !this.playerLiveTvVo.isPlayerActive // || document.getElementsByClassName("closeLayer")?.length > 0
	}

	/*
	// MTVW-444
	getIsPlayerVisible(player = null) {
		const _player = player ? player : this.player
		const elems = _player.playerToDomId
		//console.debug("getIsPlayerVisible", _player, elems)
		const domElem = document.getElementById(elems[_player.__path])
		const visible = domElem && domElem?.style?.display === "block" ? true : false
		//console.debug("getIsPlayerVisible", visible, _player.__path, elems[_player.__path], domElem)
		return visible
	}
	*/

	get isControlsVisible() {
		let visible = !this.player.isPosterVisible && this.pipPlayer === null && !this.source.adState.isAdActive && (this.isMouseOver || this.player.isControlsVisible)
		// MTVW-444: don't hide controls if playerBg is in the background
		visible = visible || (this.playerFg !== null)
		console.debug("isControlsVisible: visible %s, !poster %s, !pipPlayer %o, !adState %s, mouse %s,  playerControlsVisible %s, onOverlay, %s, !fs %s", visible, !this.player.isPosterVisible, this.pipPlayer === null, !this.source.adState.isAdActive, this.isMouseOver, this.player.isControlsVisible, this.isPlayerOnOverlay, !this.player.isFullScreen)
		return visible
	}

	get isPiPButtonEnabled() {
		console.debug("browser is %o, %o", browserName(), browserOs(), document.pictureInPictureElement)
		// firefox does not support the PiP Api on Desktops and Android
		if (isMobileScreenSize() || (browserName().toLowerCase().indexOf("firefox") >= 0 &&
			!isIpad() && !isIphone() /*&& !isAndroid()*/)) return false
		return document.pictureInPictureEnabled
	}

	// MTVW-733
	orientationChange = flow(function* (event) {
		console.debug("orientationChange", event.target.type)
		//this._adStyleInfo.valid = false
		this.adStyleValidCounter = 0
		if (this.isAdsPlayer && this.player.isFullScreen && (isIphone() || isIpad())) {
			// Hack for AdCounter update on iPhone and iPad
			console.debug("toggling fs")
			yield this.player.setFullScreen(false)
			yield sleep(500)
			yield this.player.setFullScreen(true)
		}
		/*
		this._adStyleInfo.videoRight = null
		this._adStyleInfo.videoWidth = null
		this._adStyleInfo.videoTop = null
		this._adStyleInfo.videoBottom = null
		this._adStyleInfo.videoBottomOffset = null
		*/
		//this.settings.isFullScreen = !this.settings.isFullScreen
		//this.settings.isFullScreen = !this.settings.isFullScreen
	})

	get adStyleInfo() {
		//return this._adStyleInfo
		return Object.assign({}, this._adStyleInfo)
	}

	// MTVW-733
	orientationChange = flow(function* (event) {
		console.debug("orientationChange", event.target.type)
		//this._adStyleInfo.valid = false
		this.adStyleValidCounter = 0
		if (this.isAdsPlayer && this.player.isFullScreen && (isIphone() || isIpad())) {
			// Hack for AdCounter update on iPhone and iPad
			console.debug("toggling fs")
			yield this.player.setFullScreen(false)
			yield sleep(500)
			yield this.player.setFullScreen(true)
		}
		/*
		this._adStyleInfo.videoRight = null
		this._adStyleInfo.videoWidth = null
		this._adStyleInfo.videoTop = null
		this._adStyleInfo.videoBottom = null
		this._adStyleInfo.videoBottomOffset = null
		*/
		//this.settings.isFullScreen = !this.settings.isFullScreen
		//this.settings.isFullScreen = !this.settings.isFullScreen
	})

	get adStyleInfo() {
		//return this._adStyleInfo
		return Object.assign({}, this._adStyleInfo)
	}

	getPlayerByName(playerName) {
		//console.debug("@gt12 playerName %s, %s", playerName, this.isAdsPlayer)
		//if (this.isAdsPlayer) return this.playerAds
		return this[playerName]
	}

	getVideoElem() {
		let videos = null
		if (import.meta.env.VITE_PLAYER === "vjs") {
			videos = document.getElementsByClassName("vjs-tech")
		}
		else if (import.meta.env.VITE_PLAYER === "vo") {
			videos = document.getElementsByClassName("vop-video")
		}

		for (let i = 0; i < videos.length; i++) {
			//console.debug("width=", videos[i].clientWidth)
			if (videos[i].clientWidth > 0) return videos[i]
		}
		return videos[0]
	}

	// MTVW-733
	calcVideoDimensions(isFullScreen) {
		const video = this.getVideoElem()
		if (!video) return null
		if (!isFullScreen) {
			return { width: video.clientWidth, height: video.clientHeight }
		}
		else {
			const defaultAspectNumerator = 16
			const defaultAspectDenominator = 9
			const defaultAspectRatio = defaultAspectNumerator / defaultAspectDenominator
			const sInfo = screenInfo()
			const videoWidth = sInfo.iHeight / defaultAspectDenominator * defaultAspectNumerator
			const videoHeight = sInfo.iWidth / defaultAspectNumerator * defaultAspectDenominator
			const dimensions = {
				width: videoWidth,
				height: videoHeight,
				ratio: videoWidth / videoHeight
			}
			if (sInfo.iWidth / sInfo.iHeight > defaultAspectRatio + 0.05) { // avoid rounding problems
				// full height, border at left and right side
				const ratio = videoWidth / sInfo.iWidth
				dimensions.width = ratio * sInfo.iWidth
				dimensions.height = sInfo.iHeight
			}
			if (sInfo.iWidth / sInfo.iHeight < defaultAspectRatio - 0.05) { // avoid rounding problems
				// full width, border at top and bottom
				const ratio = videoHeight / sInfo.iHeight
				dimensions.width = sInfo.iWidth
				dimensions.height = ratio * sInfo.iHeight
			}
			return dimensions
		}
	}

	// limit update rates to prevent white screen on Firefox
	adjustPlayerStyles = debounce(this._adjustPlayerStyles, 20)

	updatingStyles = false
	// MTVW-452
	_adjustPlayerStyles() {
		//console.trace("trace adjustPlayerStyles")
		if (this.updatingStyles) {
			console.debug("updatingStyles returning")
			return
		}
		const isFullScreen = this.player.settings.isFullScreen
		//if (this.player.isInPip) return
		this.updatingStyles = true

		const sInfo = screenInfo()
		const tvPlayer = document.getElementById(this.player.domElementId.TV_PLAYER)
		const bgPlayer = document.getElementById(this.player.domElementId.BG_PLAYER)
		const adsPlayer = document.getElementById(this.player.domElementId.ADS_PLAYER)
		const uiPlayer = document.getElementById("uiPlayers")
		const playerContainer = document.getElementById("ql_player_container")

		// MTVW-754
		function adjustLiveTvSize(parent, isFullScreen) {
			if (isDesktop() && playerContainer && !isFullScreen) {
				//if (!parent.player?.isStreamLoaded || parent.player?.isLoading /*|| parent.isAdsPlayer*/) return
				const pageWrapper = document.getElementById("pageWrapper")
				if (pageWrapper && document.getElementById("listAndFilterComponent")) { // Live TV
					const infoTitle = document.getElementById("infoTitle")
					const infoRect = infoTitle?.getBoundingClientRect()
					if (!infoTitle || !infoRect || infoRect.height < 1) return
					const minDeltaH = 1	// hysteresis
					const topOffest = 20 //document.getElementById("navbarContainer")?.getBoundingClientRect().height - 60 //74.8
					const deltaH = infoRect?.bottom - (sInfo.iHeight - topOffest)
					// MTVW-767: use playerContainer for tvRect
					//const tvRect = tvPlayer.getBoundingClientRect()
					const tvRect = playerContainer.getBoundingClientRect()
					//console.debug("tvRect", tvRect)
					// inhibit resize to full width while scrolling down
					// tvRect.height can be 0 during channel change due to redraws
					if (!tvRect || tvRect.y < 1 || tvRect.height < 1) return
					const pageRect = pageWrapper.getBoundingClientRect()
					const ratio = tvRect.width / sInfo.iHeight
					//console.debug("infoTitle dH %s, iRect %o, tvRect %o, pRectW %s, sInfoW %s sInfoH %s, wScrollY %s, sInfo %o", deltaH, infoRect, tvRect, pageRect.width, sInfo.sWidth, sInfo.iHeight, window.scrollY, sInfo)
					if (pageWrapper && pageRect) {
						if (deltaH > minDeltaH) {
							//console.debug("tvPlayer", tvRect)
							//console.debug("adjustment deltaH > %s", minDeltaH, deltaH, ratio, ratio * deltaH/*, pageWrapper.style.width, pageWrapper*/)
							const nWidth = Math.trunc(Math.max(pageRect.width - ratio * deltaH, 768)) + "px"
							//console.debug("adjustment", pageRect.width, nWidth)
							pageWrapper.style.width = nWidth
							//console.debug("after adjustment", deltaH, pageRect.width, pageWrapper)
						}
						else if (deltaH < minDeltaH) {
							//console.debug("adjustment deltaH < %s", minDeltaH, deltaH, pageRect.width < sInfo.iWidth, sInfo.iHeight > infoRect?.bottom - topOffest, pageRect.width, sInfo.iWidth, sInfo.iHeight, infoRect?.bottom - topOffest)
							if ((pageRect.width < sInfo.iWidth) && (sInfo.iHeight > infoRect?.bottom - topOffest)) {
								const nWidth = Math.trunc(Math.min(pageRect.width - ratio * deltaH, sInfo.iWidth)) + "px"
								//console.debug("adjustment pWidth < sInfo.iWidth", nWidth, deltaH, sInfo.iHeight - topOffest, infoRect?.bottom)
								pageWrapper.style.width = nWidth
							}
							else /*if (pageRect.width < sInfo.iWidth)*/ {
								//console.debug("adjustment 100vw", deltaH, pageRect.width, sInfo.iWidth, sInfo.iHeight, infoRect?.bottom - topOffest)
								// (MTVW-767: commented) MTVW-785: set width otherwise epg size on the right might be truncated
								pageWrapper.style.width = "100vw"
							}
						}
						//parent.updatingStyles = false
					}
				}
				// MTVW-767: commented
				//else if (pageWrapper) pageWrapper.style.width = "100vw"
			}
		}

		// MTVW-754
		adjustLiveTvSize(this, isFullScreen)


		//let height = isFullScreen ? "auto" : "100%"
		//const height = isFullScreen ? "100vh" : "100%"
		let height = isFullScreen ? "100%" : "100%"
		const widthIpad = isFullScreen ? "100vw" : "100%"
		if ((isIpad() || isIphone()) && tvPlayer) console.debug("iPad/Iphone= fs %o, w %o, h %o", isFullScreen, tvPlayer.style.width, tvPlayer.style.height)
		if (import.meta.env.VITE_PLAYER === "vo") {
			height = isIpad() ? "100%" : isFullScreen ? "100vh" : "100%"
		}
		if (tvPlayer) {
			tvPlayer.style.height = height
			if (isIpad() || isIphone()) {
				tvPlayer.style.width = widthIpad
				//if (isFullScreen) tvPlayer.style.height = "100vh"
			}
		}
		if (bgPlayer) {
			bgPlayer.style.height = height
			if (isIpad() || isIphone()) {
				bgPlayer.style.width = widthIpad
				//if (isFullScreen) tvPlayer.style.height = "100vh"
			}
		}
		if (adsPlayer) {
			adsPlayer.style.height = height
			if (isIpad() || isIphone()) {
				adsPlayer.style.width = widthIpad
				//if (isFullScreen) adsPlayer.style.height = "100vh"
			}
		}
		if (uiPlayer) {
			uiPlayer.style.height = height
			if (isIpad() || isIphone()) {
				uiPlayer.style.width = widthIpad
				//if (isFullScreen) uiPlayer.style.height = "100vh"
			}
		}
		if (isIphone()) {
			//console.debug("adjustPlayerStyles iPhone")
			//return // special handling for iPhone, we don't use enterFullscreen / exitFullscreen
		}
		// MTVW-733: ultrawidescreen support
		const mainCtrls = document.getElementById("mainControllerContainer")
		const imscContainer = document.getElementById("imscContainer")
		let ctrls = document.getElementById("playerControls-1") // vjs
		if (!ctrls) ctrls = document.getElementById("playerControls") // vo and vjs non fullscreen
		// responsive subtitles, mute, ...
		const responsiveRow1 = document.getElementById("PlayerControlsResponsive_row1")
		// PlayerControlsRow2Responsive, skip, pause, ...
		const responsiveRow2 = document.getElementById("PlayerControlsResponsive_row2")
		// PlayerControlsRow3Responsive progress bar
		const responsiveRow3 = document.getElementById("PlayerControlsResponsive_row3")
		const testButtons = document.getElementById("testButtons")
		let video = null
		if (import.meta.env.VITE_PLAYER === "vjs") {
			const videos = document.getElementsByClassName("vjs-tech")
			console.debug("vjs videos=", videos.length, videos)
			video = videos[0]
			const rect = video?.getBoundingClientRect()
			console.debug("vjs video= cW %o, cH %o, rW %o rH %o, rX %o, rY %o, rect %o", video?.clientWidth, video?.clientHeight, rect?.width, rect?.height, rect?.x, rect?.y, rect)
		}
		else if (import.meta.env.VITE_PLAYER === "vo") {
			const videos = document.getElementsByClassName("vop-video")
			const height = isFullScreen ? "100vh" : "100%"
			if (videos) {
				for (var i = 0; i < videos.length; i++) videos[i].style.height = height
				const rect = videos[0]?.getBoundingClientRect()
				console.debug("vo video= cW %o, cH %o, rW %o rH %o, rX %o, rY %o, rect %o", videos[0]?.clientWidth, videos[0]?.clientHeight, rect?.width, rect?.height, rect?.x, rect?.y, rect)
			}
		}

		// MTVW-733: calculate controls position and width for ultrawidescreen support
		// since we don't know the real video aspect ratio, assume 16 / 9 as default
		const defaultAspectNumerator = 16
		const defaultAspectDenominator = 9
		const defaultAspectRatio = defaultAspectNumerator / defaultAspectDenominator
		const videoDimensions = this.calcVideoDimensions(isFullScreen)
		// DON'T return, checks have been added (otherwise fullscreen controls would not be adjusted and black player would appear when switching channels)
		//if (!videoDimensions) return

		function adjustResponsive(isFullScreen, usingWidth, usingHeight) {
			//runInAction(() => {
			// CAUTION: 768 is the current breakpoint for responsive design used in Player.jsx and css (search for "CAUTION")
			// Use window.innerWidth instead of screen.width (Android reports smaller value for window.innerWdith)
			if (isFullScreen && videoDimensions?.width < 768 /*&& usingWidth < 768*/) {
				// Responsive controls
				if (ctrls && mainCtrls && videoDimensions) {
					//console.debug("adjustPlayerStyles < 768, sInfo %o", sInfo, ctrls, mainCtrls)
					//const videoHeight = usingWidth / defaultAspectNumerator * defaultAspectDenominator
					const videoHeight = videoDimensions.height
					console.debug("adj responive ORG top %o, height %o, padding %o", ctrls.style.top, ctrls.style.height, mainCtrls?.style?.paddingBottom)
					if (usingWidth / usingHeight > defaultAspectRatio + 0.05) { // avoid rounding problems
						// full height, border at left and right side
						//const videoWidth = usingHeight / defaultAspectDenominator * defaultAspectNumerator
						const videoWidth = videoDimensions?.width
						if (responsiveRow1) responsiveRow1.style.paddingLeft = (usingWidth - videoWidth) / 2 + "px"
						ctrls.style.top = ""
						ctrls.style.height = ""
						mainCtrls.style.marginLeft = "0px"
						if (responsiveRow2) {
							responsiveRow2.style.width = videoDimensions?.width + "px"
							responsiveRow2.style.marginLeft = (usingWidth - videoWidth) / 2 - 30 + "px"
						}
						if (testButtons) testButtons.style.marginLeft = (usingWidth - videoWidth) / 2 - 10 + "px"
					}
					else {
						if (responsiveRow1) responsiveRow1.style.paddingLeft = ""
						if (responsiveRow2) {
							responsiveRow2.style.width = ""
							responsiveRow2.style.marginLeft = ""
						}
						if (testButtons) testButtons.style.marginLeft = ""
						ctrls.style.top = (usingHeight - videoHeight) / 2 + "px"
						//if (isIphone()) ctrls.style.top = (screen.height - videoHeight) / 2 + "px"
						ctrls.style.height = videoHeight + "px"
					}
					mainCtrls.style.paddingBottom = "0px"
					console.debug("adj responive NOW top %o, height %o, padding %o", ctrls.style.top, ctrls.style.height, mainCtrls?.style?.paddingBottom)
				}
				return true
			}
			else {
				if (ctrls && mainCtrls) {
					if (responsiveRow1) responsiveRow1.style.paddingLeft = ""
					ctrls.style.top = ""
					ctrls.style.height = ""
					mainCtrls.style.paddingBottom = "20px"
				}
				return false
			}
			//})
		}

		function adjustAdButtons(parent, isFullScreen, usingWidth, usingHeight) {
			runInAction(() => {
				const adTop = document.getElementById("adTopControlsContainer")
				const adFf = document.getElementById("fastForwardIcon")
				const adSkip = document.getElementById("linearSkip")
				//parent._adStyleInfo.valid = false // Force update on Safari when orientation changes
				//parent._adStyleInfo.adStyleValidCounter = 0
				if (adTop || adFf || adSkip) {
					// adsPlayer
					if (isAndroid() && browserName().indexOf("chrome") >= 0) {
						// chrome on Android reports different sizes than Firefox and Edge
						console.debug("special case chrome Android")
						usingWidth = sInfo.iWidth
						usingHeight = sInfo.iHeight
					}
					//console.debug("adjustPlayerStyles ads fs %o, sInfo %o", isFullScreen, sInfo)
					if (isFullScreen && videoDimensions) {
						/*
						const videoWidth = usingHeight / defaultAspectDenominator * defaultAspectNumerator
						const videoHeight = usingWidth / defaultAspectNumerator * defaultAspectDenominator
						*/
						const videoWidth = videoDimensions?.width
						const videoHeight = videoDimensions?.height
						console.debug("videoWidth %o, videoHeight %o, usingWidth %o, usingHeight %o, ratio %o", videoWidth, videoHeight, usingWidth, usingHeight, usingWidth / usingHeight)
						if (usingWidth / usingHeight > defaultAspectRatio + 0.05) { // avoid rounding problems
							// full height, border at left and right side
							const ratio = videoWidth / usingWidth
							parent._adStyleInfo.videoRight = (usingWidth - videoWidth) / 2
							//parent._adStyleInfo.videoWidth = ratio * usingWidth
							parent._adStyleInfo.videoWidth = videoWidth
							parent._adStyleInfo.videoTop = 0
							parent._adStyleInfo.videoBottom = usingHeight
							parent._adStyleInfo.videoBottomOffset = 0
							//parent._adStyleInfo.valid = true
							parent.adStyleValidCounter++
							console.debug("adj > 1.77 aspect ratio %o, w %o, h %o, vW %o, vH %o, counter %o, adStyle %o", usingWidth / usingHeight, usingWidth, usingHeight, videoWidth, videoHeight, parent.adStyleValidCounter, parent.adStyleInfo)
							return true
						}
						if (usingWidth / usingHeight < defaultAspectRatio - 0.05) { // avoid rounding problems
							// full width, border at top and bottom
							parent._adStyleInfo.videoRight = 0
							// do not set adTopMarginLeft
							//adStyleInfo.videoWidth = usingWidth
							parent._adStyleInfo.videoWidth = videoWidth
							parent._adStyleInfo.videoTop = (usingHeight - videoHeight) / 2
							parent._adStyleInfo.videoBottom = usingHeight - (usingHeight - videoHeight) / 2
							parent._adStyleInfo.videoBottomOffset = (usingHeight - videoHeight) / 2
							//parent._adStyleInfo.valid = true
							parent.adStyleValidCounter++
							console.debug("adj < 1.77 aspect ratio %o, w %o, h %o, vW %o, vH %o, counter %o, adStyle %o", usingWidth / usingHeight, usingWidth, usingHeight, videoWidth, videoHeight, parent.adStyleValidCounter, parent.adStyleInfo)
							return true
						}
						//parent._adStyleInfo.valid = false
						console.debug("reset ads 1")
						return false
					}
					else {
						console.debug("reset ads 2")
						//parent._adStyleInfo.valid = false
						parent.adStyleValidCounter = 0
						return false
					}
				}
			})
		}

		function adjustPlayerControls(parent, isFullScreen, usingWidth, usingHeight, mainCtrls, ctrls) {
			if (isFullScreen && mainCtrls && imscContainer) {
				if (!parent.fsMainCtrlStyle) parent.fsMainCtrlStyle = mainCtrls?.style
				if (!parent.fsCtrlStyle) parent.fsCtrlStyle = ctrls?.style
				//console.debug("ctrlStyles", parent.fsMainCtrlStyle)
				//console.debug("adjustPlayerStyles in fs, sInfo %o", sInfo)
				console.debug("ratio", 16 / 9, 16 / 10, sInfo.sWidth / sInfo.sHeight, sInfo.iWidth / sInfo.iHeight)
				let paddingBottom = 20
				// special case iPad, see MTVW-710
				if (isIpad() && videoDimensions) {
					//const videoHeight = sInfo.iWidth / defaultAspectNumerator * defaultAspectDenominator
					const videoHeight = videoDimensions.height
					console.debug("landscape", mainCtrls.style.paddingBottom)
					paddingBottom = (sInfo.iHeight - videoHeight) / 2
					//if (import.meta.env.VITE_PLAYER === "vo") {
					//	paddingBottom += 70
					//}
					mainCtrls.style.paddingBottom = paddingBottom + "px"
					parent.updatingStyles = false
					imscContainer.style.paddingBottom = paddingBottom + "px"
					return
				}

				mainCtrls.style.width = "100%"
				if (videoDimensions && usingWidth / usingHeight < defaultAspectRatio - 0.05) { // avoid rounding problems
					// full width, border at top and bottom
					//const videoHeight = usingWidth / defaultAspectNumerator * defaultAspectDenominator
					const videoHeight = videoDimensions.height
					paddingBottom = (usingHeight - videoHeight) / 2 + paddingBottom
					console.debug("adj < 1.77 ORG paddingBottom", mainCtrls.style.paddingBottom)
					mainCtrls.style.paddingBottom = paddingBottom + "px"
					console.debug("adj < 1.77 NOW paddingBottom %o, vHeight %o", mainCtrls.style, videoHeight)
					parent.updatingStyles = false
					imscContainer.style.paddingBottom = paddingBottom + "px"
					return
				}
				if (videoDimensions && usingWidth / usingHeight > defaultAspectRatio + 0.05) { // avoid rounding problems
					// full height, border at left and right side
					const videoWidth = usingHeight / defaultAspectDenominator * defaultAspectNumerator
					const ratio = 100 * videoWidth / usingWidth // Math.trunc(100 * videoWidth / usingWidth)
					console.debug("adj > 1.77 ORG width %o, paddinBottom %o", mainCtrls.style.width, mainCtrls.style.paddingBottom)
					mainCtrls.style.width = ratio + "%"
					// margin is now set in PlayerControlsMainWrapperFullScreen
					//mainCtrls.style.margin = "auto"
					//const videoHeight = usingWidth / defaultAspectNumerator * defaultAspectDenominator
					const videoHeight = videoDimensions.height
					mainCtrls.style.paddingBottom = "0px"
					console.debug("adj > 1.77 NOW width %o, paddingBottom %o", mainCtrls.style.width, mainCtrls.style.paddingBottom)
					console.debug("vWidth=%o, vHeight=%o, ratio=%o", videoWidth, videoHeight, ratio)
					parent.updatingStyles = false
					imscContainer.style.paddingBottom = "0px"
					return
				}
			}
			else {
				// restore original style
				//if (parent.fsCtrlStyle || parent.fsMainCtrlStyle) console.debug("adj restore top %o, height %o, padding %o", parent.fsCtrlStyle?.style?.top, parent.fsCtrlStyle?.style?.height, parent.fsMainCtrlStyle?.style?.paddingBottom)
				// TODO remove fsMainCtrlStyle and fsCtrlStyle, restoring them doesn't work
				//console.debug("adjustPlayerStyles not in fs, sInfo %o", sInfo)
				if (ctrls && parent.fsCtrlStyle) {
					//ctrls.style = parent.fsCtrlStyle
					ctrls.style.top = ""
					ctrls.style.height = ""
					parent.fsCtrlStyle = null
				}
				if (mainCtrls && parent.fsMainCtrlStyle) {
					//mainCtrls.style = parent.fsMainCtrlStyle
					mainCtrls.style.paddingBottom = ""
					mainCtrls.style.width = ""
					parent.fsMainCtrlStyle = null
					imscContainer.style.paddingBottom = ""
				}
				if (responsiveRow1) responsiveRow1.style.paddingLeft = ""
			}
			parent.updatingStyles = false
		}


		// Trial with innerWidth
		let usingWidth = sInfo.iWidth
		let usingHeight = sInfo.iHeight
		adjustAdButtons(this, isFullScreen, usingWidth, usingHeight)
		if (adjustResponsive(isFullScreen, sInfo.iWidth, sInfo.iHeight)) {
			this.updatingStyles = false
			return
		}
		adjustPlayerControls(this, isFullScreen, usingWidth, usingHeight, mainCtrls, ctrls)
	}

	// MTVW-452
	initPlayerControls(domIdPlayer) {
		const indexElementInDom = domIdPlayer === this.player.domElementId.TV_PLAYER ? 0 : 1
		//const videos = document.getElementsByClassName("vjs-tech")
		//console.debug("initPlayerControls videos", videos)

		// NOTE VJS: not really needed, re-check
		if (import.meta.env.VITE_PLAYER === "vjs_ignored") {
			const videos = document.getElementsByClassName("vjs-tech")
			//const videos = document.getElementById("vjs_playerDetailPageVo_html5_api")
			if (videos) {
				// MTVW-549: enables cors
				//for (let i = 0; i < videos?.length; i++)
				//	videos[i].setAttribute("crossorigin", "anonymous") //"use-credentials"
			}
			const video = videos[indexElementInDom]
			if (video) {
				//video.style.height = 'auto'
				// NOTE VJS: video would be below container
				//video.style.position = 'relative'
				//const videoContainer = document.getElementsByClassName("vop-video-container")[indexElementInDom]
				const videoContainer = document.getElementsByClassName("video-js")[indexElementInDom]
				if (videoContainer) {
					videoContainer.style.height = 'auto'
					videoContainer.style.position = 'relative'
				}
				else console.warn('getElementsByClassName("video-js")[%s] not found', indexElementInDom)
				//const htmlVideoContainer = document.getElementsByClassName("html5-video-player")[indexElementInDom]
				const htmlVideoContainer = document.getElementsByClassName("myWrap")[indexElementInDom]
				if (htmlVideoContainer) {
					htmlVideoContainer.style.height = 'auto'
				}
				else console.warn('getElementsByClassName("myWrap")[%s] not found', indexElementInDom)
			}
			else console.warn('getElementsByClassName("vjs-tech")[%s] not found', indexElementInDom)
		}
		else if (import.meta.env.VITE_PLAYER === "vo") {
			const videos = document.getElementsByClassName("vop-video")
			if (videos) {
				// MTVW-549: ensbles cors, important for Safari
				/**/
				for (let i = 0; i < videos?.length; i++)
					videos[i].setAttribute("crossorigin", "anonymous") //"use-credentials"
				/**/
			}
			const video = videos[indexElementInDom]
			if (video) {
				video.style.height = 'auto'
				video.style.position = 'relative'
				const videoContainer = document.getElementsByClassName("vop-video-container")[indexElementInDom]
				if (videoContainer) {
					videoContainer.style.height = 'auto'
					videoContainer.style.position = 'relative'
				}
				else console.warn('getElementsByClassName("vop-video-container")[%s] not found', indexElementInDom)
				const htmlVideoContainer = document.getElementsByClassName("html5-video-player")[indexElementInDom]
				if (htmlVideoContainer) {
					htmlVideoContainer.style.height = 'auto'
				}
				else console.warn('getElementsByClassName("html5-video-player")[%s] not found', indexElementInDom)
			}
			else console.warn('getElementsByClassName("vop-video")[%s] not found', indexElementInDom)
		}
		this.adjustPlayerStyles()
	}

	activateAdsPlayer = flow(function* () {
		if (this.player.__path === "playerAds") return
		const muteState = this.player.isMuted
		//console.debug("activateAdsPlayer %o, muted %s", this.player, muteState)
		const elemTv = document.getElementById(this.player.domElementId.TV_PLAYER)
		if (elemTv) elemTv.style.display = "none"
		const elemBg = document.getElementById(this.player.domElementId.BG_PLAYER)
		if (elemBg) elemBg.style.display = "none"
		const elemAd = document.getElementById(this.player.domElementId.ADS_PLAYER)
		if (elemAd) {
			elemAd.style.display = "block"
			//console.debug("adsPlayer elem %o", elemAd)
			//yield this.playerAds.setRefElemContainer(elemAd)
		}
		this.initPlayerControls(this.player.domElementId.ADS_PLAYER)
		this.isAdsPlayer = true
		try {
			this.player.setMuteState(muteState)
		}
		catch (e) {
			// could fail if the ads player request was failing before (e.g. replay ad failed with CORS)
			console.error("setMuteState on adsPlayer", e)
		}
	})

	deactivateAdsPlayer = flow(function* () {
		if (this.player.__path !== "playerAds") return
		//const muteState = this.player.isMuted
		//console.debug("deactivateAdsPlayer %o, muted %s", this.player, muteState)
		const elemAd = document.getElementById(this.player.domElementId.ADS_PLAYER)
		if (elemAd) elemAd.style.display = "none"
		// MTVW-506: setting refelemContainer to null, causes ff ad issues on Safari! Pause instead.
		//this.playerAds.setRefElemContainer(null)
		if (import.meta.env.VITE_PLAYER === "vo") {
			this.playerAds.setPause()
		}
		// MTVW-588: setPlayCompleted
		this.playerAds.setPlayCompleted()
		if (import.meta.env.VITE_PLAYER === "vjs") {
			//yield this.playerAds.setRefElemContainer(null, isIphone() ? false : true)
			//yield this.playerAds.setRefElemContainer(null, true)
			yield this.player.stopInstance()
		}
		this.isAdsPlayer = false
		const elemTv = document.getElementById(this.player.domElementId.TV_PLAYER)
		if (import.meta.env.VITE_PLAYER === "vo") {
			this.player.setRefElemContainer(elemTv)
		}
		//this.player.setMuteState(muteState)
		console.debug("deactivateAdsPlayer", this.player._refElemContainer.id, this.player.idDomPlayer)
		const cElem = document.getElementById(this.player._refElemContainer.id)
		if (cElem) cElem.style.display = "block"
	})

	// MTVW-788, MTVW-787:
	_createPlayerDiv = ((playerName) => {
		const newDiv = document.createElement("div")
		newDiv.style.height = "100%"
		newDiv.style.paddingTop = "0px"
		newDiv.setAttribute("data-vjs-player", "")
		//newDiv.innerHTML = '<video id = "vjs_' + this.inactivePlayer.idDomPlayer + '" className="video-js"/>'
		const child = document.createElement("video")
		child.id = "vjs_" + playerName
		child.className = "video-js"
		newDiv.appendChild(child)
		console.debug("newDiv activate", newDiv)
		return newDiv
	})

	// MTVW-444, MTVW-788, MTVW-787
	activateBgPlayer = flow(function* () {
		//this.setPlayerFg(this.playerLiveTvVo.isPlayerActive ? this.playerLiveTvVo : this.playerDetailPageVo)
		if (!this.activePlayer) {
			console.debug("no activePlayer")
			this.activePlayer = this.playerLiveTvVo.isPlayerActive ? this.playerLiveTvVo : this.playerDetailPageVo
			this.inactivePlayer = this.playerBg
		}
		this.setPlayerFg(this.activePlayer)
		console.debug("activePlayer activate", this.activePlayer.__path, this.activePlayer.idDomPlayer)
		console.debug("inactivePlayer activate", this.inactivePlayer.__path, this.inactivePlayer.idDomPlayer)
		const muteState = this.player.isMuted
		//console.debug("activateAdsPlayer %o, muted %s", this.player, muteState)
		const elemTv = document.getElementById(this.player.domElementId.TV_PLAYER)
		const elemBg = document.getElementById(this.player.domElementId.BG_PLAYER)
		console.debug("activateBgPlayer elemBg %o, activeRef %o, elemTv %o, inactiveRef %o", elemBg, this.activePlayer?._refElemContainer, elemTv, this.inactivePlayer?._refElemContainer)

		let curRef = null
		if (elemTv) {
			const displayStyle = this.activePlayer._refElemContainer?.id === "tvPlayer" ? "block" : "none"
			elemTv.style.display = displayStyle
			if (displayStyle === "none") curRef = this.tvRef
			else curRef = this.bgRef
			//console.debug("curRef", curRef)
		}
		if (elemBg) {
			const bgId = this.inactivePlayer._refElemContainer?.id
			console.debug("bgId", bgId, this.inactivePlayer.idDomPlayer, this.inactivePlayer.__path)
			const displayStyle = this.activePlayer._refElemContainer?.id === "tvPlayer" ? "none" : "block"
			elemBg.style.display = displayStyle
			let elemVideo = document.getElementById('vjs_' + this.inactivePlayer.idDomPlayer)
			if (!elemVideo) elemVideo = document.getElementById('vjs_' + this.inactivePlayer.idDomPlayer + '_html5_api')
			console.debug("activate Bg elemVideo=== %o, %o, %o", elemVideo, elemVideo?.parentElement, this.inactivePlayer.idDomPlayer)
			if (elemVideo) {
				const newDiv = this._createPlayerDiv(this.inactivePlayer.idDomPlayer)

				//debugger
				/*
				yield this.inactivePlayer.setRefElemContainer(null, true)
				curRef.current.firstChild.replaceWith(newDiv)
				yield this.inactivePlayer.setRefElemContainer(curRef.current, true)
				*/
			}
		}
		//this.setPlayerFg(this.activePlayer)
		//this.initPlayerControls(this.player.domElementId.BG_PLAYER)
		try {
			this.player.setMuteState(muteState)
		}
		catch (e) {
			// could fail if the ads player request was failing before (e.g. replay ad failed with CORS)
			console.error("setMuteState on bgPlayer", e)
		}
	})

	// MTVW-444, MTVW-788, MTVW-787
	deactivateBgPlayer = flow(function* () {
		const playerFg = this.playerFg
		if (!playerFg) return
		const elemTv = document.getElementById(this.player.domElementId.TV_PLAYER)
		const elemBg = document.getElementById(this.player.domElementId.BG_PLAYER)
		console.debug("deactivateBgPlayer id", playerFg._refElemContainer?.id, playerFg, this.inactivePlayer)
		let curRef = null
		if (elemTv) {
			const displayStyle = playerFg._refElemContainer?.id === "tvPlayer" ? "block" : "none"
			elemTv.style.display = displayStyle
			if (displayStyle === "none") curRef = this.tvRef
			else curRef = this.bgRef
			console.debug("curRef", curRef)
		}
		else console.error("SourceChannelReplay no elemTv")
		if (elemBg) {
			const displayStyle = playerFg._refElemContainer?.id === "tvPlayer" ? "none" : "block"
			elemBg.style.display = displayStyle
		}
		else console.error("SourceChannelReplay no elemBg")

		let elemVideo = document.getElementById('vjs_' + this.inactivePlayer.idDomPlayer)
		if (!elemVideo) elemVideo = document.getElementById('vjs_' + this.inactivePlayer.idDomPlayer + '_html5_api')
		console.debug("deactivate Bg elemVideo=== %o, %o, %o", elemVideo, elemVideo?.parentElement, this.inactivePlayer.idDomPlayer)
		if (elemVideo) {
			const newDiv = this._createPlayerDiv(this.inactivePlayer.idDomPlayer)
			yield this.inactivePlayer.setRefElemContainer(null, true)
			curRef.current.firstChild.replaceWith(newDiv)
			yield this.inactivePlayer.setRefElemContainer(curRef.current, true)
		}

		// NEEDED?
		//playerFg.setIsPaused(false, false)
		// MTVW-725: commented
		//this.source = playerFg._source
		this.setPlayerFg(null)
		console.debug("SourceChannelReplay, id ref", this.inactivePlayer._refElemContainer?.id)
	})

	// MTVW-444, MTVW-788, MTVW-787
	swapPlayers = flow(function* () {
		//let player1 = this.playerLiveTvVo.isPlayerActive ? this.playerLiveTvVo : this.playerDetailPageVo
		let player1 = this.activePlayer
		let player2 = this.inactivePlayer

		//console.debug("pauseAds", player1._source.pauseAd, player2._source.pauseAd)
		if (player1._source.pauseAd) {
			player2._source.pauseAd = player1._source.pauseAd
			player2._source.adPauseStart = player1._source.adPauseStart
			//player1._source.pauseAd = null
		}
		console.debug("before swap", player1, player1._source.type, player1._refElemContainer, player2, player2._source.type, player2._refElemContainer)
		const fgId = player1._refElemContainer?.id
		console.debug("playerFg= %o, fgId %s", player1, fgId)
		console.debug("playerFg= %s, %s", player1.__path, player1.idDomPlayer)
		let elemVideo = document.getElementById("vjs_" + player1.idDomPlayer)
		if (!elemVideo) elemVideo = document.getElementById("vjs_" + player1.idDomPlayer + "_html5_api")
		console.debug("swap elemVideo===", elemVideo, player1.idDomPlayer, document.getElementById("vjs_" + player1.idDomPlayer + "_html5_api"), player2.idDomPlayer, document.getElementById("vjs_" + player2.idDomPlayer + "_html5_api"))

		const elemTv = document.getElementById(this.player.domElementId.TV_PLAYER)
		const elemBg = document.getElementById(this.player.domElementId.BG_PLAYER)
		let curRef = null
		//debugger
		if (elemTv) {
			//elemTv.style.display = elemTv.style.display === "block" ? "none" : "block"
			const displayStyle = player1._refElemContainer?.id === "tvPlayer" ? "none" : "block"
			elemTv.style.display = displayStyle
			if (displayStyle === "none") curRef = this.tvRef
			else curRef = this.bgRef
			//console.debug("curRef", curRef)
		}
		else console.error("swapPlayers no elemTv")
		if (elemBg) {
			//elemBg.style.display = elemBg.style.display === "block" ? "none" : "block"
			const displayStyle = player1._refElemContainer?.id === "tvPlayer" ? "block" : "none"
			elemBg.style.display = displayStyle
		}
		else console.error("swapPlayers no elemBg")
		if (elemVideo) {
			const newDiv = this._createPlayerDiv(player1.idDomPlayer)
			yield player1.setRefElemContainer(null, true)
			curRef.current.firstChild.replaceWith(newDiv)
			//console.debug("querySelectorAll", document.querySelectorAll(".video-js"))
			yield player1.setRefElemContainer(curRef.current, true)
		}

		const player1Saved = {
			playerRef: player1,
			path: player1.__path,
			refElem: player1._refElemContainer,
			idDomElement: player1.idDomElement,
			idDomPlayer: player1.idDomPlayer,
			//sessionId: player1._source.playlist.StreamInfo.session_id,
			//source: player1._source
		}
		const player2Saved = {
			playerRef: player2,
			path: player2.__path,
			refElem: player2._refElemContainer,
			idDomElement: player2.idDomElement,
			idDomPlayer: player2.idDomPlayer,
			//sessionId: player2._source.playlist.StreamInfo.session_id,
			//source: player2._source
		}
		player1.__path = player2Saved.path
		//player1._source.playlist.player.path = player2Saved.path
		// MTVW-725: commented
		//player1._source.playlist.player = player2Saved.playerRef
		//player1._refElemContainer = player2Saved.refElem
		//player1.idDomElement = player2Saved.idDomElement
		//player1.idDomPlayer = player2Saved.idDomPlayer

		player2.__path = player1Saved.path
		//player2._source.playlist.player.path = player1Saved.path
		// MTVW-725: commented
		//player2._source.playlist.player = player1Saved.playerRef
		//player2._refElemContainer = player1Saved.refElem
		//player2.idDomElement = player1Saved.idDomElement
		//player2.idDomPlayer = player1Saved.idDomPlayer

		if (this.playerLiveTvVo.isPlayerActive) this.playerLiveTvVo = player2Saved.playerRef
		else this.playerDetailPageVo = player2Saved.playerRef
		this.playerBg = player1Saved.playerRef

		//player1 = this.playerLiveTvVo.isPlayerActive ? this.playerLiveTvVo : this.playerDetailPageVo
		console.debug("after swap", player1, player1._source.type, player1._refElemContainer, player2, player2._source.type, player2._refElemContainer)
		this.setPlayerFg(null)

		this.inactivePlayer = player1
		this.activePlayer = player2
		console.debug("activePlayer swap", this.activePlayer.__path, this.activePlayer.idDomPlayer)
		console.debug("inactivePlayer swap", this.inactivePlayer.__path, this.inactivePlayer.idDomPlayer)
		// settle time
		yield sleep(200)
	})

	setPlayerFg(value) {
		this.playerFg = value
		if (value) console.debug("set playerFg=", value.__path, value.idDomPlayer)
		else console.debug("set playerFg= null")
	}

	mountEvent() {
		this.setPlayerFg(null)
		this.activePlayer = null
		this.inactivePlayer = null
	}

	/* pre MTV-2172
	_init() {
		console.log("@")
		// full screen
		if (screenfull.isEnabled) {
			screenfull.on("change", () => {
				this.settings.isFullScreen = screenfull.isFullscreen
			})
		}
		this._initAsync()
	}
	*/

	// MTV-2172
	_init() {
		console.log("@")
		// full screen
		this.settings.isFullScreen = false
		//this.player.setFullScreen(false)
		this._initAsync()
	}

	_initAsync = flow(function* () {
		yield this._ChannelListRepoWaitAsync()
		// GT12 MTVW-487: seconds precision
		//this._addObservePlayer("positionTimeStamp60s", position => {
		this._addObservePlayer("positionTimeStamp", position => {
			const timeDiff = Math.trunc(Math.abs(position - this.lastTimeStamp))
			// prevent multiple _checkEventAsync calls if position changes fast
			if (position && timeDiff > 1000) {
				//console.debug("position %s, last %s, diff %s", position, this.lastTimeStamp, timeDiff)
				// NOTE: this will update the EPG event details if the program changes (rewind or next program)
				this.lastTimeStamp = position
				this.source._checkEventAsync(position)
			}
			/* MTV-2195: user agent cannot be modified
			if (!userAgentPlayerSet) {
				const qlInfo = ` Quickline-TV/${import.meta.env.VITE_VERSION} OSMP+ ${this.playerLiveTvVo.version}`
				let ua = window.navigator.userAgentSS
				window.navigator.__defineGetter__('userAgent', () => ua + qlInfo)
				console.debug("user-agent %s", window.navigator.userAgent)
				userAgentPlayerSet = true
			}
			*/
		})
	})

	_onMount() {
		if (this._tLastUsage?.length) {
			// MTV-1763: If tLastUsage is set and a recording was played, the recording would be played in Live TV!
			// Execute only in case of setPlayChannelLiveAsync!
			if (this._tLastUsage[0] === "setPlayChannelLiveAsync") {
				// MTV-2205: This would cause 2 calls to setPlayChannelLiveAsync
				//this[this._tLastUsage[0]](...this._tLastUsage[1], !this._isLastUsed)
			}
			this.tLastUsage = []
		}
		document.addEventListener("keydown", this._parseKeyDown, false)
		document.addEventListener("keyup", this._parseKeyUp, false)
	}

	_onUnmount() {
		this._isLastUsed = false
		document.removeEventListener("keydown", this._parseKeyDown, false)
		document.removeEventListener("keyup", this._parseKeyUp, false)
	}

	_addObservePlayer(nameVar, callback) {
		// MTV-1883: Need to add observers for both playerLiveTvVo and playerDetailPageVo
		this._observer.add("_addObservePlayer", this.playerLiveTvVo, nameVar, changes => {
			console.info("@@_addObservePlayer(nameVar=%s,callback=%o) %o => %o", nameVar, { callback }, changes.oldValue, changes.oldValue, this, { changes })
			callback(changes.newValue, changes.oldValue)
		})
		this._observer.add("_addObservePlayer", this.playerDetailPageVo, nameVar, changes => {
			console.info("@@_addObservePlayer(nameVar=%s,callback=%o) %o => %o", nameVar, { callback }, changes.oldValue, changes.oldValue, this, { changes })
			callback(changes.newValue, changes.oldValue)
		})
		// MTVW-444
		this._observer.add("_addObservePlayer", this.playerBg, nameVar, changes => {
			console.info("@@_addObservePlayer(nameVar=%s,callback=%o) %o => %o", nameVar, { callback }, changes.oldValue, changes.oldValue, this, { changes })
			callback(changes.newValue, changes.oldValue)
		})
		return this
	}

	// check if space bar is pressed in channel filter input, if not start and pause the player		
	_parseKeyDown(e) {
		console.info("@(e=%o)->", e)
		if ((e.keyCode === 32) && (e.target.id !== IMPUT_CHANNEL_LIST_FILTER_ID)) {
			e.preventDefault()
		}
	}

	/*
	fullScreenChange(bState) {
		console.debug("got fullScreenChange", bState)
		//if (!bState) this.player.setFullScreen(false)
	}
	*/

	_parseKeyUp(e) {
		console.info("@(e=%o)->", e)
		if ((e.keyCode === 32) && (e.target.id !== IMPUT_CHANNEL_LIST_FILTER_ID)) {
			this.source.setPlayPause()
			e.preventDefault()
		}
		//Esc
		//&& this.player.isFullScreen
		/* NOTE VJS: commented */
		else if (import.meta.env.VITE_PLAYER === "vo") {
			if (e.keyCode === 27) {
				if (this.isControlsVisible && this.player.isFullScreen) {
					// MTV-2172: ESC key is now handled by PlayerVo
					this.player.setFullScreen(false)
					e.preventDefault()
				}
			}
		}
	}

	setMouseOver(bState) {
		console.info("@(bState=%o)", bState)
		//if (!bState) return
		this.isMouseOver = bState
	}

	handlePlaySourceAsync = flow(function* (source, startPosition = null) {
		console.debug("@@handlePlaySourceAsync(source=%o, player paused=%s) -> ", source, this.player.isPausedUser)
		this.error = null
		try {
			this._setSource(source, "handlePlaySourceAsync")

			// if GetEvent is callback exec them
			if (source.GetEvent && typeof source.GetEvent === "function") {
				this.source.GetEvent = source.GetEvent()
			}
			//this._pageStore.ChannelListRepo.setActiveChannel(source.id)

			yield this.source.openStreamAsync(startPosition)
		} catch (e) {
			this.error = ErrorBase.getErrorIfInstanceOf(e)
			// MTV-2205: Throwing here would cause an UnhandledRejection
			//throw this.error
		}
		console.log("@@handlePlaySourceAsync(source=%o) <- ", source)
	})

	handlePlaySourcePreserveProgressBarAsync = flow(function* (source, callback = null) {
		console.info("@@handlePlaySourcePreserveProgressBarAsync(source=%o,this.source=%o,callback=%o) -> .source.progressBar", source, this.source, callback, this.source.progressBar)
		this.source.progressBar.setPositionDone(false)
		if (this.source && source) {
			source.progressBar.setState(this.source.progressBar.getState())
		} else if (this.source) {
			this.source.progressBar.setPositionDone(false)
		}
		if (callback !== null) {
			yield callback(source)
		} else {
			yield this.handlePlaySourceAsync(source)
		}
		if (source) {
			source.progressBar.setPositionDone(true)
			this._setSource(source, "handlePlaySourcePreserveProgressBarAsync")
		} else if (this.source) {
			this.source.progressBar.setPositionDone(true)
		}
		console.info("@@handlePlaySourcePreserveProgressBarAsync(source=%o,callback=%o) <- .source.progressBar", source, callback, this.source.progressBar)
	})

	setPlayChannelLiveAsync = flow(function* (channelId, oEventFull = null, bForceLoad = false, restartDone = false) {
		console.debug("@@setPlayChannelLiveAsync(channelId=%o,oEventFull=%o,bForceLoad=%o) ->", channelId, oEventFull, bForceLoad, restartDone)
		let release = null
		// if called from the live TV page when it is loaded acquire the mutexPlayChannel
		// if called from handleChangeChannel mutexChangeChannel will be locked
		/*if (!this.mutexChangeChannel.isLocked())*/ release = yield this.mutexPlayChannel.acquire()
		try {
			this._tLastUsage = ["setPlayChannelLiveAsync", [channelId, null]]

			if (this.source && this.player.positionTimeStamp > 0) {
				console.debug("calling interactionEvent")
				yield this.source.interactionEvent("changeAsset", this.player.positionTimeStamp)
			}

			this._pageStore.ChannelListRepo.setActiveChannel(channelId)

			//console.debug("setPlayChannelLiveAsync oEventFull %o", oEventFull)
			// make sure that the EPG event details are updated
			if (oEventFull?.id === void 0) {
				// in case of call from OverlayEventDetails
				const newSource = SourceChannelLive.create(this, channelId, oEventFull, restartDone)
				yield this._checkOpenStream(newSource, bForceLoad)
				this._setSource(newSource, "setPlayChannelLiveAsync 1")
			}
			else {
				const newSource = SourceChannelLive.create(this, channelId, oEventFull, restartDone)
				yield this._checkOpenStream(newSource, bForceLoad)
				// this will update the EPG details in Live TV
				this._setSource(newSource, "setPlayChannelLiveAsync 2")
				yield rootStore.page.OverlayEventDetails.setEpgEventById(oEventFull.id)
			}

			console.info("@@setPlayChannelLiveAsync(channelId=%o,oEventFull=%o,bForceLoad=%o) <-", channelId, oEventFull, bForceLoad, restartDone)
		}
		catch (e) {
			console.error("setPlayChannelLiveAsync", e)
		}
		finally {
			if (release) release()
		}
		return this
	})

	// GT12: trueReplaySession
	setPlayChannelReplayAsync = flow(function* (channelId, bufferTimeStart, oEventFull = null, bForceLoad = false, trueReplaySession = true, sessionId = null, restartDone = false) {
		console.debug("@@setPlayChannelReplayAsync(channelId=%o,bufferTimeStart=%o,oEventFull=%o,bForceLoad=%o,trueReplaySession=%o) ->", channelId, bufferTimeStart, oEventFull, bForceLoad, trueReplaySession)
		this._tLastUsage = ["setPlayChannelReplayAsync", [channelId, bufferTimeStart, oEventFull]]

		// we start stream for first time or changed stream
		yield this._checkOpenStream(SourceChannelReplay.create(this, channelId, oEventFull, bufferTimeStart, trueReplaySession, sessionId, restartDone), bForceLoad)

		// update once replay moves to the next movie
		//rootStore.page.OverlayEventDetails.setEpgEventById(oEventFull.id)

		console.info("@@setPlayChannelReplayAsync(channelId=%o,bufferTimeStart=%o,oEventFull=%o,bForceLoad=%o) <-", channelId, bufferTimeStart, oEventFull, bForceLoad)
	})

	setPlayContentAsync = flow(function* (contentId, oEventFull = null, bForceLoad = false) {
		console.info("@@setPlayContentAsync(contentId=%o,oEventFull=%o,bForceLoad=%o) ->", contentId, oEventFull, bForceLoad)
		this._tLastUsage = ["setPlayContentAsync", [contentId, oEventFull]]

		// we start stream for first time or changed stream
		// GT12: trueReplaySession
		yield this._checkOpenStream(SourceContentPlaylist.create(this, contentId, oEventFull, true), bForceLoad)

		console.info("@@setPlayContentAsync(contentId=%o,oEventFull=%o,bForceLoad=%o) <-", contentId, oEventFull, bForceLoad)
	})

	_checkOpenStream = flow(function* (source, bForceLoad = false) {
		console.info("@@_checkOpenStream(source=%o, bForceLoad = %o) ->", source, bForceLoad)
		this.error = null
		/* MTV-2654: disable
		if (source.Channel?.NetworkRecordingEntitlementState === "NotEntitled") {
			// update details EPG information in live TV
			if (!rootStore.page.OverlayEventDetails.isVisible) {
				this.source = source
			}
			this.error = ErrorBase.CreateError(["NetworkRecordingEntitlementState NotEntitled"], null, null)
			throw this.error
		}
		*/
		if (bForceLoad === true || !this.source.isSourceEqual(source)) {
			this._isLastUsed = true
			//console.debug("handlePlaySourceAsync %o", source, source.time, moment(source.time).utc().valueOf())
			if (import.meta.env.VITE_PLAYER === "vjs") {
				// OPTIMIZATION for SourceChannelReplay.setReplay (currently only for non GT12 channels)
				yield this.handlePlaySourceAsync(source, source.time ? moment(source.time).utc().valueOf() : null)
			}
			else if (import.meta.env.VITE_PLAYER === "vo") {
				// VO PLAYER SYNC
				// yield this.handlePlaySourceAsync(source)
				yield this.handlePlaySourceAsync(source, source.time ? moment(source.time).utc().valueOf() : null)
			}
			this.source.isLoading = false
			console.info("@@_checkOpenStream(source=%o, bForceLoad = %o) <- this.source=%o", source, bForceLoad, this.source)
		}
	})

	setRegisterDeviceAsync = flow(function* () {
		this.error = null
		try {
			yield this._root.api.AddCpeId().fetchDataAsync()
			this.source.GetEvent = null
			yield this._checkOpenStream(this.source, true)
		} catch (e) {
			this.error = ErrorBase.getErrorIfInstanceOf(e)
		}
		return this
	})

	_ChannelListRepoWaitAsync = flow(function* () {
		yield this._root.page.ChannelListRepo.waitAsync()
		/*
		try {
			yield this._root.page.ChannelListRepo.waitAsync()
		}
		catch (e) {
			console.error("caught in _ChannelListRepoWaitAsync", e)
		}
		*/
	})
}
