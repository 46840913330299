// l10n status: not applicable
import React from "react"
import { makeStyles } from "@mui/styles"
import Modal from "@mui/material/Modal"
//import { CreateComponent } from "components/utils/CreateComponent"
import { rootStore } from "store/RootStore"

const useStyles = makeStyles(theme => ({
   rootDialog: {
      zIndex: "250000 !important"
   },
   paper: {
      display: "flex",
      margin: "auto",
      marginTop: "50px", //5%
      width: "100%",
      maxWidth: "696px",
      //overflow: "sroll",
      //height: "80%",  // -> paperStyle
      //maxHeight: "700px", //added
      //backgroundColor: theme.palette.background.paper,
      //boxShadow: theme.shadows[5],
      //padding: theme.spacing(2, 4, 3),
      background: "#272726",
      borderRadius: "16px",
      //boxShadow: "2px 1px 10px rgba(192,192,192,0.4), -1px -1px 10px rgba(192,192,192,0.4) ",
      // from Michael
      //boxShadow: "0 2px 50px 0 rgba(0,0,0,0.5)",
      // right (https://stackoverflow.com/questions/5115427/how-can-i-add-a-box-shadow-on-one-side-of-an-element),
      // bottom (https://stackoverflow.com/questions/4561097/css-box-shadow-bottom-only)
      boxShadow: "10px 0 15px -2px rgba(0,0,0,0.5), 0 10px 15px -2px rgba(0,0,0,0.5)",
      //border: "none"
      outline: 0  // no blue border on Safari
   },
   container: {
      display: "flex",
      //margin: "auto",
      marginTop: 0,
      width: "100%",
      overflow: "sroll",
      //maxWidth: "500px", //added
      //height: "100%", //added
      //maxHeight: "700px", //added
      border: "none",
      borderRadius: "16px",
      outline: 0  // no blue border on Safari
   },
}))

const DEFAULT_P_HEIGHT = 800
const DEFAULT_P_TOPMARGIN = -2000 // avoid showing before iframe is loaded

//export const OnboardingModal = CreateComponent(styles, false, true, () => {
export function OnboardingModal() {
   const classes = useStyles()
   const [paperStyle, setPaperStyle] = React.useState({ height: DEFAULT_P_HEIGHT + "px", marginTop: DEFAULT_P_TOPMARGIN + "px" })
   // the element id 'onbPage' can be accessed only when opened
   const [open, setOpen] = React.useState(false)
   //const [page, setPage] = React.useState(null)

   const store = rootStore.page.Onboarding
   // FIX: moved to useEffect below, otherwise a warning will be generated
   // "Warning: Cannot update a component (`observerComponent`) while rendering a different component (`OnboardingModal`)."
   //if (store.state === store.INIT) store.checkAnnouncements({ height: DEFAULT_P_HEIGHT, marginTop: DEFAULT_P_TOPMARGIN })

   React.useEffect(() => {
      if (store.state === store.INIT) store.checkAnnouncements({ height: DEFAULT_P_HEIGHT, marginTop: DEFAULT_P_TOPMARGIN })
   }, [store.state])

   React.useEffect(() => {
      // 'onbPage' is only visible when the modal is open
      const onbPage = document.getElementById("onbPage")
      if (onbPage) onbPage.onload = () => {
         handleResize()
      }
      //if (onbPage) onbPage.innerHTML = "I have changed!"

      window.addEventListener("message", (event) => {
         //console.debug("ORIGIN", event.origin, event.data)
         if (event?.data?.request === "continue") store.announcementSeen()
         else if (event?.data?.request === "openModal") setOpen(true)
         else if (event?.data?.featureHeight) {
            //console.debug("height is ", event, event?.data?.featureHeight)
            store.iframeProps.height = event?.data?.featureHeight.replace("px", "")
            // increase by 1 pixel since design change to avoid scroll bars
            store.iframeProps.height = parseInt(store.iframeProps.height) + 1
            handleResize()
         }
         else if (event?.data?.request === "openStreamDone") store.playerActive()
      }, false)

      window.addEventListener("resize", handleResize)

      // callback before unmount
      return (() => {
         window.removeEventListener("resize", handleResize)
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [/*page*/])

   //React.useLayoutEffect(() => {
   //})

   const handleResize = () => {
      // detect content height of html page
      const onbPage = document.getElementById("onbPage")
      if (onbPage) {
         // could generate cross-domain iframe issues
         //let pHeight = onbPage.contentWindow.document.body.scrollHeight
         let pHeight = store.iframeProps.height
         let marginTop = getComputedStyle(document.getElementById("onbPaper")).marginTop.replace("px", "")
         console.debug("marginTop", marginTop, pHeight)
         if (pHeight > window.innerHeight) {
            pHeight = window.innerHeight
            marginTop = 0
         }
         else {
            marginTop = (window.innerHeight - pHeight) / 2
         }
         const pStyle = { height: pHeight + "px", marginTop: marginTop + "px" }
         setPaperStyle(pStyle)
         console.debug("iframe content loaded", pHeight, window.innerHeight, pHeight, marginTop, pStyle)
         let elemButton = document.querySelectorAll('.continueBtn')
         console.debug("onbPage: ", store.state, onbPage, elemButton)
         //if (elemButton.length > 0) elemButton[0].addEventListener('click', handleClose)
      }
   }

   const handleClose = (event, reason) => {
      event.preventDefault()
      event.stopPropagation()
      // Material UI 4.12: deprecated disableBackdropClick, we need to handle the reason
      if (reason === "backdropClick" && store.blockingAnnouncement) return
      setOpen(false)
      store.modalDone()
   }

   //let data = 'lorem <b>ipsum</b>'

   //fetch(`sample_campaign.htm`).then((res) => {
   //fetch(`https://quickline.ch/`).then((res) => {
   //fetch(`https://quickline.ch/agb`).then((res) => {
   /*
   // does not find assets!
   //fetch(`campaign/sample_campaign.htm`).then((res) => {
   //fetch(`http://127.0.0.1:4443/sample_campaign.htm`).then((res) => {
   //fetch(`https://127.0.0.1:4443/sample_campaign.htm`).then((res) => {
   fetch(`https://canary-preprod.qltv.quickline.ch/onboarding/v002/contracts/39067/announcements/2022_07_08_sample_campaign/sample_campaign.htm`).then((res) => {
      res.text().then((page) => {
         data = page
         setPage(page)
         console.debug("Onboarding response", data)
         //document.getElementById("demo").innerHTML = "I have changed!"
         //setOpen(true)
      }).catch((err) => {
         console.error("GOT 1", err)
      })
   }).catch((err) => {
      console.error("GOT 2", err)
   })
   */

   // innerhtml: struggle to intercept button
   // iframe: struggle to intercept button
   return (
      <Modal id="onbModal"
         aria-labelledby="simple-modal-title"
         aria-describedby="simple-modal-description"
         open={open}
         onClose={handleClose}
         closeAfterTransition
         className={classes.rootDialog}
         disableAutoFocus={true}
         disablePortal={true} // needed for getElementById of iframe
         BackdropProps={{ style: { backgroundColor: "rgba(48,48,48,0.8)" } }}
      >

         <div id="onbPaper" style={paperStyle} className={classes.paper}>
            {/*
            <div id="onbPage" className={classes.container} dangerouslySetInnerHTML={{ __html: page }}>
            </div>
            <div id="onbPage" className={classes.container}>
            </div>
            <iframe id="onbPage" className={classes.container} src="https://canary-preprod.qltv.quickline.ch/onboarding/v002/contracts/39067/announcements/2022_07_08_sample_campaign/sample_campaign.htm" title="empty title"></iframe>
           */}
            <iframe id="onbPage" /*onLoad={iframeLoaded()}*/ className={classes.container} src={store.announcementLink} title="empty title"></iframe>
         </div>
      </Modal>
   )
}
