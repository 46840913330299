import React from "react"
import { CreateComponent } from "components/utils/CreateComponent"
import info_language from "img/player/info_language.svg"
import info_age_rating from "img/player/info_age_rating.svg"
import info_country from "img/player/info_country.svg"
import { CALLING_CONTEXT_TYPE } from "store/page/OverlayEventDetails"

import "./EpgChannelDetailsInfo.css"

const helperFilterElem = i => i !== null && i !== false && i !== "" && i !== void 0

// MTVW-808: prefix and other parameters are not used
//export const EpgChannelDetailsToolsInfo = CreateComponent(null, false, true, ({ oEvent, origin, prefix = "", suffix = "", joinTxt = " | ", empty = null }) => {
export const EpgChannelDetailsToolsInfo = CreateComponent(null, false, true, ({ oEvent, origin }) => {
	const [innerWidth, setInnerWidth] = React.useState(window.innerWidth)

	React.useEffect(() => {
		window.addEventListener("resize", handleResize, false)
		handleResize()
		return (() => {
			window.removeEventListener("resize", handleResize, false)
		})
	}, [])

	const handleResize = () => {
		setInnerWidth(window.innerWidth)
	}

	let genresArray = oEvent?.Title?.Genres?.filter(helperFilterElem) ?? []
	if (oEvent?.isSeries === true && !genresArray.includes("Serien")) genresArray.unshift("Serien")
	// remove potential duplicates
	genresArray = genresArray.filter((item, index) => genresArray.indexOf(item) === index);
	const loc = [oEvent?.Title?.ProductionLocationId]?.filter(helperFilterElem).join(" ")
	let location = loc
	const regionNamesInGerman = new Intl.DisplayNames(['de'], { type: 'region' })
	if (location?.length > 0) {
		// use just the 1st one
		location = location.split(',')[0]
		location = regionNamesInGerman.of(location)
	}
	console.debug("genres=", oEvent?.Title?.Genres, genresArray, loc, location)
	//console.debug("regionName", regionNamesInGerman.of('DE'), location.split(',')[0])

	const widthBreakTablet = 800
	const widthBreakMobile = 540

	const InfoYear = () => {
		return oEvent?.Title?.ProductionDate !== null && (
			<div className="metaInfoYear">
				{oEvent?.Title?.ProductionDate}
			</div>
		)
	}

	const InfoGenres = () => {
		return (
			<div className="metaInfoGenreContainer">
				{genresArray.map((item, idx) => (
					<div key={origin + idx} >
						{idx > 0 ? <span className="metaInfoGenreSeparator">•</span> : <></>}
						<span className="metaInfoGenre"
							onClick={(e) => {
								e.preventDefault()
								e.stopPropagation()
								console.debug("callingContext", rootStore.page.OverlayEventDetails.callingContext)
								// no back button if in LiveTv
								//if (rootStore.page.Player.isPlayerOnOverlay) {
								if (rootStore.page.OverlayEventDetails.isVisible) {
									rootStore.page.OverlayEventDetails.pushActivity({ type: 'related', id: oEvent?.id })
								}
								rootStore.page.OverlayEventDetails.setGenre(item).setComponentType(rootStore.page.Search.CATEGORY_GENRES).setShow(true, CALLING_CONTEXT_TYPE.TvGuide)
							}}
						>{item}</span>
					</div>
				)
				)}
			</div>
		)
	}

	const InfoLanguage = () => {
		return oEvent?.Title?.OriginalLanguageName !== null && (
			<div className="metaInfoLanguage">
				<div className="metaInfoIconContainer">
					<img src={info_language} alt="" className="metaInfoIcon"></img>
				</div>
				<div className="metaInfoLanguageLabel">
					{oEvent?.Title?.OriginalLanguageName}
				</div>
			</div>
		)
	}

	const InfoCountry = () => {
		return location?.length > 0 && (
			<div className="metaInfoCountry">
				<div className="metaInfoIconContainer">
					<img src={info_country} alt="" className="metaInfoIcon"></img>
				</div>
				<div className="metaInfoCountyLabel">
					{location}
				</div>
			</div>
		)
	}

	const InfoRating = () => {
		return oEvent?.Title?.Rating && oEvent?.Title?.Rating !== "FSK0" && (
			<div className="metaInfoAgeRating">
				<div className="metaInfoIconContainer">
					<img src={info_age_rating} alt="" className="metaInfoIcon"></img>
				</div>
				<div className="metaInfoAgeRatingLabel">
					{`${oEvent?.Title?.Rating}`}
				</div>
			</div>
		)
	}

	const DesktopLayout = [
		{ component: () => <InfoYear /> },
		{ component: () => <InfoGenres /> },
		{ component: () => <InfoLanguage /> },
		{ component: () => <InfoCountry /> },
		{ component: () => <InfoRating /> },
	]

	const TabletLayout = [
		{ component: () => { return (<InfoGenres />) } },
		// remaining items 2nd line
		{ component: () => { return (<><div className="metaInfoDividerLight" /><div className="metaInfoGenreContainer"><InfoYear /><InfoLanguage /><InfoCountry /><InfoRating /></div> </>) } },
		// else return nothing
		{ component: () => { return (<></>) } },
		{ component: () => { return (<></>) } },
		{ component: () => { return (<></>) } },
	]

	const MobileLayout = [
		{ component: () => { return (<InfoGenres />) } },
		// 2nd line
		{ component: () => { return (<><div className="metaInfoDividerLight" /><div className="metaInfoGenreContainer"><InfoYear /><InfoRating /></div> </>) } },
		// rd line
		{ component: () => { return (<><div className="metaInfoDividerLight" /><div className="metaInfoGenreContainer"><InfoLanguage /><InfoCountry /></div> </>) } },
		// else return nothing
		{ component: () => { return (<></>) } },
		{ component: () => { return (<></>) } },
	]

	const detailsInfo = (ix) => {
		let layout = DesktopLayout
		if (innerWidth <= widthBreakTablet) layout = TabletLayout
		if (innerWidth <= widthBreakMobile) layout = MobileLayout
		return layout[ix]
	}

	return (
		<>
			{/* MTVW-808: tweaks, style={{ marginTop: 18 }} */}
			{innerWidth <= widthBreakTablet && (<div style={{ marginTop: 18 }} className="metaInfoDivider" />)}
			<div className="metaInfoContainer" data-test="detail_info_tech_description">
				{detailsInfo(0).component()}
				{detailsInfo(1).component()}
				{detailsInfo(2).component()}
				{detailsInfo(3).component()}
				{detailsInfo(4).component()}
			</div>
			{/* MTVW-808: tweaks, style={{ marginBottom: 10 }} */}
			{innerWidth <= widthBreakTablet && (<div style={{ marginBottom: 10 }} className="metaInfoDivider" />)}
		</>
	)
})


// MTVW-810
export const EpgChannelDetailsToolsTitleSecondary = CreateComponent(null, false, true, ({ innerWidth, widthBreak, oTitle, prefix = "", suffix = "", joinTxt = ", ", empty = null }) => {
	const t = [oTitle?.SeasonNum ? `Staffel ${oTitle?.SeasonNum}` : null, oTitle?.EpisodeNum ? `Episode ${oTitle?.EpisodeNum} • ` : null, oTitle?.EpisodeName ? oTitle?.EpisodeName : null]?.filter(
		helperFilterElem
	)

	const padding = innerWidth <= widthBreak ? "27px" : "unset"
	let details = t.filter(helperFilterElem).join(joinTxt).replace("• ,", "• ")
	if (details.indexOf(" • ") === details.length - 3) details = details.replace(" • ", "")
	return t.length > 0 ? (
		// use <span> for usage in EpgChannel, otherwise ellipsis will not shown
		<span style={{ paddingBottom: padding }}>
			{prefix}
			{details}
			{suffix}
		</span>
	) : (
		<div style={{ paddingBottom: "unset" }} />
	)
})
