
import { action, flow, observable, makeObservable } from "mobx"
//import { SourceAbstract } from "store/page/player/source/SourceAbstract"
//import { SourceTypeList } from "store/page/player/source/SourceTypeList"
import { SourceTypeList, SourceAbstract } from './InternalImports'
import { moment } from "store/util/moment"
import { TIME_FORMAT_BACKEND } from "store/model/Time"
import { rootStore } from "store/RootStore"
import { logLevel } from "store/qlapi/LogService"
import { cDebug } from "utils/TestSupport"

export class SourceContentPlaylist extends SourceAbstract {
	type = SourceTypeList.ContentPlaylist
	time = null

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			time: observable,
			getClone: action,
			setPlayPause: action,
			setJumpLive: action,
			setJumpRwd: action,
			setJumpFwd: action,
			setReplay: action
		})
		//console.debug("constructor SourceContentPlaylist %o, %o", parent.player, path)
		this._player = parent.player
	}

	/**
	 * @returns {SourceContentPlaylist}
	 */
	static create(parent, playList, oEventFull, trueReplaySession = true) {
		console.log("@@create(parent=%o, playList=%o, oEventFull=%o)", parent, playList, oEventFull)
		const source = new SourceContentPlaylist(parent, "source")
		source.id = playList
		source.GetEvent = oEventFull
		source.trueReplaySession = trueReplaySession
		return source
	}

	/**
	 * @returns {SourceContentPlaylist}
	 */
	getClone() {
		const source = new SourceContentPlaylist(this._parent, "source")
		source.id = this.id
		source.GetEvent = this.GetEvent
		source.trueReplaySession = this.trueReplaySession
		source.isLoading = false
		return source
	}

	isSourceEqual(source) {
		return this.type === source.type && this.id === String(source.id)
	}

	// buttons disable state
	get isButtonLiveEnabled() {
		// only when we are not in live range
		return false
	}

	get isButtonReplayEnabled() {
		// TODO joachim: temporary hack until we solve anvato positioning
		if (this.player.isAnvatoSource) {
			return false
		}
		return this.player.isStreamLoaded
	}

	get isButtonJumpFwdEnabled() {
		// TODO joachim: temporary hack until we solve anvato positioning
		if (this.player.isAnvatoSource) {
			return false
		}
		return this.player.isJumpFwdPossibleSec(this.settings.jumpFwdSec)
		//return false
	}

	get isButtonJumpRwdEnabled() {
		// TODO joachim: temporary hack until we solve anvato positioning
		if (this.player.isAnvatoSource) {
			return false
		}
		return this.player.isJumpRwdPossibleSec(this.settings.jumpRwdSec)
		//return false
	}

	get isButtonPlayPauseEnabled() {
		// TODO joachim: temporary hack until we solve anvato positioning
		if (this.player.isAnvatoSource) {
			return false
		}
		return this.player.isStreamLoaded
	}

	get isButtonRecEnabled() {
		return false
	}

	// buttons visible state
	// GT12: MTVW-460 channels MS flags do not apply to recordings
	get isButtonLiveHidden() {
		return true
	}

	get isButtonReplayHidden() {
		return false
	}

	get isButtonJumpFwdHidden() {
		return false
	}

	get isButtonJumpRwdHidden() {
		return false
	}

	get isButtonPlayPauseHidden() {
		return false
	}

	get isButtonRecHidden() {
		return true
	}

	openStreamAsync = flow(function* () {
		console.info("@@openStreamAsync() -> ")
		yield this._openStreamContentAsync()
		console.info("@@openStreamAsync() <- ")
	})

	handleStreamOnStart() {
		console.log("@()")
		//this._openStreamRollingBufferAsync(this.player.positionTimeStamp - 2 * 3600, this.player.positionTimeStamp)
	}

	handleStreamOnEnd(positionTimeStamp) {
		console.log("@()")
		//this._openStreamRollingBufferAsync(this.player.positionTimeStamp, this.player.positionTimeStamp)
	}

	//setPlayPause() {
	setPlayPause = flow(function* () {
		//if (!this.debounced()) return
		if (!this.player.isPaused) {
			this.player.setPause()
		} else {
			this.removePauseAd()
			yield this.player.setPlay("setPlayPause")
		}
		return this
	})

	setJumpLive() {
		console.info("@")
		this.removePauseAd()
		//this.player.setJumpLive()
		//this._parent.setPlayChannelLiveAsync(this.Channel?.id, this.GetEvent, false, this.restartDone)
	}

	//actionAfterFastForwardAd = (closeInteraction = "") => {
	actionAfterFastForwardAd = flow(function* (closeInteraction = "") {
		try {
			// MTVW-544: suppress "Überspringen" after ffad
			this.markerPosD = this.adZone?.end
			if (this.adState?.linear) this.adState.linear.skip = false
			yield this.exitAdsPlayer()
			cDebug("JUMPTO %s", moment(this.fastForwardAdInfo.jumpTo).utc().format(TIME_FORMAT_BACKEND))
			const from = this.player.positionTimeStamp
			yield this.player.setPositionTimeStampAsync(this.fastForwardAdInfo.jumpTo)
			if (this.player.isPaused) yield this.player.setPlay("actionAfterFastForwardAd")
			this.player.setIsPosterVisible(false, "actionAfterFastForwardAd")
			// GT12 TODO: special cases
			// closeInteraction: [ changeAsset, interruption, skipPromo ] or "" for no interaction
			// error: [ adNotFound, other ] or ""  for no error
			//console.debug("AFTER FASTFORWARD", moment(this.adPlayStart).utc().format(TIME_FORMAT_BACKEND), moment().utc().format(TIME_FORMAT_BACKEND))
			rootStore.adsService.sessionTrackingFastForwardAdAsync(this.sessionId, this.fastForwardAdInfo.fastForwardAd.adsViewId, moment().valueOf() - this.adPlayStart, closeInteraction, "")
			// MTVW-649: disable notifyJump
			/*
			if (from !== this.fastForwardAdInfo.jumpTo)
				rootStore.adsService.sessionNotifyJumpAsync(this.sessionId, this.Event?.AvailabilityStartDateTs, this.Event?.AvailabilityEndDateTs, from, this.fastForwardAdInfo.jumpTo)
			*/
		}
		catch (e) {
			console.error("actionAfterFastForwardAd", e)
			rootStore.logService.logAsync(logLevel.ERROR, "actionAfterFastForwardAd", "SourceContentPlaylist", e)
		}
		this.setFfwdAdInfo(null)
	})

	//setJumpRwd() {
	setJumpRwd = flow(function* () {
		console.info("@")
		if (this._checkStreamRwd()) {
			//this.removePauseAd()
			cDebug("positionTimeStamp %s, %o", this.player.positionTimeStamp, this.player)
			//this.player.setJumpRwd()
			yield this.handleFastForwardAd(this.player.positionTimeStamp, this.player.positionTimeStamp - this.settings.jumpRwdSec * 1000, this.actionAfterFastForwardAd)
		}
	})

	//setJumpFwd() {
	setJumpFwd = flow(function* () {
		console.info("@")
		//this.removePauseAd()
		// this.player.setJumpFwd()
		yield this.handleFastForwardAd(this.player.positionTimeStamp, this.player.positionTimeStamp + this.settings.jumpFwdSec * 1000, this.actionAfterFastForwardAd)
	})

	contentReplayActionBeforeReplayAd = (startPosition) => {
		this.player.setPositionTimeStamp(startPosition)
		this.setPlayPause()
		//console.debug("_contentReplay BEFORE %s, %s", startPosition, this.player.isPaused)
	}

	setReplay() {
		console.info("@", this.Event?.AvailabilityStartDateTs)
		//this.removePauseAd()
		//this.player.setPositionTimeStamp(this.Event?.AvailabilityStartDate)
		// _contentReplay moved to SourceAbstract
		this._contentReplay()
		this.markerPosD = null
	}

	// eslint-disable-next-line require-yield
	handleSetPosition = flow(function* (positionMoment) {
		//this.removePauseAd()
		//this.player.setPositionTimeStamp(positionMoment)
		yield this.handleFastForwardAd(this.player.positionTimeStamp, moment(positionMoment).utc().valueOf(), this.actionAfterFastForwardAd)
	})

	timeStampEvent(timeStamp, offsetSeconds, playCompleted) {
		this.checkForLinearAd(timeStamp, offsetSeconds)
	}

	/* MTVW-838: Caused "Cannot assign to read only property '_checkEventAsync'" probably after mobx update
	// eslint-disable-next-line require-yield
	_checkEventAsync = flow(function* (eventTime) {
		return false
	})
	*/

	_checkStreamRwd() {
		//console.debug("PLAYER %o, %s, %s, %s", this.player, this.player.position, this.player.minimalPositionTimestamp, moment(this.player.minimalPositionTimestamp).utc().valueOf())
		// MTVW-495: this.player.position is not defined
		//if (this.player.position < 0) {
		if (this.player.positionTimeStamp < moment(this.player.minimalPositionTimestamp).utc().valueOf() + this.settings.jumpRwdSec * 1000) {
			return false
		}
		return true
	}
}
