import { types as t } from "mobx-state-tree"
//import { Pictures } from "./Picture"
import { ProductsRef } from "./Product"
import { RollingBuffers } from "./RollingBuffer"
import { CustomProperties } from "./CustomProperty"
import { Pictures } from "store/model/traxis/Picture"

export const Channel = t.model("Channel", {
	id: t.identifier,
	LogicalChannelNumber: t.maybeNull(t.number),
	Name: t.string,
	IsAdult: t.boolean,
	IsNetworkRecordingAllowed: t.boolean,
	Blocked: t.boolean,
	Pictures: t.maybeNull(Pictures),
	TstvEventCount: t.number,
	IsViewableOnCpe: t.boolean,
	IsOnRecordingOptinList: t.boolean,
	CustomProperties: t.maybeNull(CustomProperties),
	Products: t.maybeNull(ProductsRef),
	RollingBuffers: t.maybeNull(RollingBuffers),
	IsPersonalBlockedChannel: t.maybeNull(t.boolean),
	NetworkRecordingEntitlementState: t.string,
	IsHD: t.boolean,
})

export const ChannelRef = t.model("ChannelRef", {
	Channel: t.snapshotProcessor(t.reference(Channel), {
		preProcessor(i) {
			return i[0].id
		},
		postProcessor(i) {
			return [{ id: i }]
		}
	})
})

export const ChannelMixin = t
	.model("ChannelMixin", {
		Channels: ChannelRef
	})
	.views(self => ({
		get Channel() {
			return self.Channels.Channel
		}
	}))

export const Channels = t
	.model("Channels", {
		resultCount: t.number,
		Channel: t.array(Channel),
		isReady: t.maybeNull(t.boolean)
	})
	.views(self => ({
		get items() {
			return self.Channel
		},
		get getLogicalChannels() {
			return self.items.filter(i => i.LogicalChannelNumber)
		},
		get getFirstChannel() {
			return self.items.find(i => i.LogicalChannelNumber)
		}
	}))
	// added for mobx 6.3 which is more strict
	.actions(self => ({
		setReady(value) {
			self.isReady = value
		}
	}))

export const ChannelsRef = Channels.named("ChannelsRef").props({
	Channel: t.array(
		t.snapshotProcessor(t.safeReference(Channel), {
			preProcessor(i) {
				return i.id
			},
			postProcessor(i) {
				return { id: i }
			}
		})
	)
})
