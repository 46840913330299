import throttle from "lodash/throttle"

const OFFSET = 50 // This is the top/bottom offset you use to start scrolling in the div.
const PX_DIFF = 12 //6

let scrollIncrement = 0
let isScrolling = false
let scrollViewElement = null
let gridItemElement = null
let scrollHeight = 0
let clientRectBottom = 0
let clientRectTop = 0


// Scroll up in the scroll view.
const goUp = () => {
	scrollIncrement -= PX_DIFF
	scrollViewElement.scrollTop = scrollIncrement

	if (isScrolling && scrollIncrement >= 0) {
		window.requestAnimationFrame(goUp)
	}
}

// Scroll down in the scroll view.
const goDown = () => {
	scrollIncrement += PX_DIFF
	scrollViewElement.scrollTop = scrollIncrement

	if (isScrolling && scrollIncrement <= scrollHeight) {
		window.requestAnimationFrame(goDown)
	}
}

const onDragOver = event => {
	event.preventDefault()
	const clientY = (event.type === "touchmove") ? event.changedTouches[0]?.clientY : event.clientY
	scrollIncrement = scrollViewElement.scrollTop
	const isMouseOnTop =
		scrollIncrement >= 0 &&
		clientY >= clientRectTop &&
		clientY < clientRectTop + OFFSET
	const isMouseOnBottom =
		scrollIncrement <= scrollHeight &&
		clientY > clientRectBottom - OFFSET &&
		clientY <= clientRectBottom

	//console.debug("scrollTop %s top %s bottom %s cY %s ev %o", scrollViewElement.scrollTop, clientRectTop, clientRectBottom, clientY, event)

	if (!isScrolling && (isMouseOnTop || isMouseOnBottom)) {
		isScrolling = true
		scrollIncrement = scrollViewElement.scrollTop

		if (isMouseOnTop) {
			//console.debug("goUp")
			window.requestAnimationFrame(goUp)
		} else {
			//console.debug("goDown")
			window.requestAnimationFrame(goDown)
		}
	} else if (!isMouseOnTop && !isMouseOnBottom) {
		//console.debug("nothing")
		isScrolling = false
	}
}

// prevent executing too many times
const throttleOnDragOver = throttle(onDragOver, 100)

const addScrollMonitor = (elementId, gridItemId = null) => {
	// In Chrome the scrolling works.
	//if (navigator.userAgent.indexOf("Chrome") === -1) {
	scrollViewElement = document.getElementById(elementId)
	scrollHeight = scrollViewElement.scrollHeight
	const clientRect = scrollViewElement.getBoundingClientRect()
	clientRectTop = clientRect.top
	clientRectBottom = clientRect.bottom

	scrollIncrement = scrollViewElement.scrollTop
	//console.debug("scrollViewElement %o scrollHeight %o clientRectTop %s clientRectBottom %s", scrollViewElement, scrollHeight, clientRectTop, clientRectBottom)

	scrollViewElement.addEventListener("dragover", throttleOnDragOver)
	scrollViewElement.addEventListener("touchmove", throttleOnDragOver)
	// we need also to listen to touchmove events of a grid item that is being dragged into the channel list
	if (gridItemId) {
		gridItemElement = document.getElementById(gridItemId)
		gridItemElement.addEventListener("touchmove", throttleOnDragOver)
	}
	//}
}

const removeScrollMonitor = () => {
	isScrolling = false

	if (scrollViewElement) {
		scrollViewElement.removeEventListener("dragover", throttleOnDragOver)
		scrollViewElement.removeEventListener("touchmove", throttleOnDragOver)
		//scrollViewElement = null
	}
	if (gridItemElement) {
		gridItemElement.removeEventListener("touchmove", throttleOnDragOver)
		gridItemElement = null
	}
}

const scrollIntoView = (scollViewId, elementId) => {
	const scrollView = document.getElementById(scollViewId)
	//const scrollViewHeight = scrollView.scrollHeight
	const scrollRect = scrollView.getBoundingClientRect()
	const scrollTop = scrollRect.top
	const scrollBottom = scrollRect.bottom

	const elem = document.getElementById(elementId)
	const elemRect = elem.getBoundingClientRect()
	const elemTop = elemRect.top
	const elemBottom = elemRect.bottom
	//console.debug("sTop %s, sBottom %s, eTop %s, eBottom %s", scrollTop, scrollBottom, elemTop, elemBottom)

	if (elemBottom > scrollBottom) {
		scrollView.scrollTop += elemBottom - scrollBottom + 20
	} else if (elemTop < scrollTop) {
		scrollView.scrollTop -= (scrollTop - elemTop + 0)
	}
}

export default {
	addScrollMonitor,
	removeScrollMonitor,
	scrollIntoView,
}
