/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-06
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-11
 */

import { TraxisAbstract } from "store/api/TraxisAbstract"
import { localStorage } from "store/util/LocalStorage"
//import { uuid } from "uuidv4"
import { v4 as uuid } from "uuid"

const uuid_session = uuid()

export const getCpeId = () => {
	if (import.meta.env.VITE_FAKE_CPEID) {
		return import.meta.env.VITE_FAKE_CPEID
	}
	if (localStorage.has("store.cpeId")) {
		return localStorage.get("store.cpeId")
	}
	localStorage.set("store.cpeId", uuid_session, true)
	return uuid_session
}

export class TraxisCpeIdMixin extends TraxisAbstract {
	get _getCpeId() {
		if (this._oVars.cpeId) {
			return this._oVars.cpeId
		}
		return getCpeId()
	}
}
