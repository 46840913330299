// l10n status: done
import { Button, Divider, Menu, MenuItem, Tooltip, ListItemIcon, IconButton } from "@mui/material"
import { CreateComponent } from "components/utils/CreateComponent"
import React, { useEffect, useState } from "react"
import { rootStore } from "store/RootStore"
import ListIcon from "components/welcomeModal/icons/channel-list.svg"
import { observable, makeObservable, action, flow } from "mobx"
import classnames from "classnames"

//import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import ArrowDown from "img/arrow-down.svg"
import CheckIcon from 'img/icons/check-white.svg';
import AddIcon from 'img/icons/add-white.svg';
import EditIcon from 'img/icons/edit-white.svg';
import { ROUTE_NAMES } from "site-ql/RouteConst"
import { useHistoryRouteAdd } from "components/utils/RouteUtils"
import { EventEmitter } from "components/utils/EventEmitter"
import { isMobileScreenSize } from "utils/Utils"
import { ModalMessage } from "components/ModalMessage"

import "./css/channelListMenu.css"
import { l10n } from "store/lang/L10n"

const styles = theme => ({
	NavBarArrowIcon: { padding: 0, marginTop: "-4px" },
	ChannelListMenuList: {
		//padding: "5px 0 10px",
		zIndex: 1500,
		borderRadius: "4px",
		boxShadow: "0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)",
		outline: "0 solid",
		background: "#3F3F3F !important",
	},
	ChannelListMenuListItem: {
		display: "flex",
		justifyContent: "flex-start",
		fontSize: "14px",
		padding: "4px 16px",
		//minWidth: "300px",
		height: "2.5rem"
	},
	Icons: {
		paddingRight: "10px", marginTop: "0px"
	},

	ChannelListSelected: { background: "rgba(0,0,0,0.08)" },
	EditIconEnabled: { opacity: 1.0, cursor: "default", pointerEvent: "none", borderRadius: "100%" },
	CheckIconContainer: { minWidth: "40px" },
	MenuColumn: { display: "flex", flex: "1 1 auto", justifyContent: "flex-start" },
	//ChannelListMenuButton: { margin: "1px 5px 1px 0px !important" }
})

// MTVW-33
class ChannelListInfo {
	id = null
	name = l10n.featureChannelListsAllSenders

	constructor() {
		makeObservable(this, {
			id: observable,
			name: observable,
			setId: action,
			setName: action
		})
	}

	setId(id) {
		this.id = id
	}

	setName(name) {
		this.name = name
	}
}

const channelListInfo = new ChannelListInfo()
let cbRegistered = false

export const ChannelListMenu = CreateComponent(styles, false, true, ({ classes, classNames, placement }) => {
	// const anchorEl = useRef(null)
	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)
	// MTVW-636: Traxis GetChannelLists didn't always return the changes applied in ChannelListEditor
	// -> use rootStore.page.ChannelListRepo.ChannelLists
	const [channelLists, setChannelLists] = React.useState(rootStore.page.singleton.channelLists.DataArray.ChannelList)

	//const channelLists = rootStore.api.GetChannelLists()
	//const [openState, setOpenState] = useState(false)
	const allChannels = { id: null, Name: l10n.featureChannelListsAllSenders }
	const isMobile = isMobileScreenSize()

	const cbSubscribe = (event) => {
		//function cbSubscribe(event) {
		//console.debug("reloadChannelLists event %o", event)
		setTimeout(() => {
			reloadChannelLists(event)
		}, 100)
		/*
		setTimeout(() => {
			reloadChannelLists(event)
		}, 500)
		*/
	}

	useEffect(() => {
		//EventEmitter.release("reloadChannelLists", cbSubscribe)

		if (!cbRegistered) EventEmitter.subscribe("reloadChannelLists", cbSubscribe)
		cbRegistered = true
		//console.debug("useEffect menu")
		reloadChannelLists("")
		/*
		EventEmitter.subscribe("activeChannelListDeleted", (event) => {
			console.debug("event is: %o", event)
			handleChangeChannelList(allChannels, false)
		})
		*/
		return (() => {
			//EventEmitter.release("reloadChannelLists", cbSubscribe)
		})
	}, [])

	const reloadChannelLists = flow(function* (event) {
		// MTVW-33
		const channelListId = rootStore.sso.browserStore.getChannelListId()
		let chId = allChannels.id
		let chName = allChannels.Name
		// MTVW-636: use rootStore.page.ChannelListRepo.ChannelLists
		//const test = yield rootStore.api.GetChannelLists().fetchData()
		// MTVW-657: predefined channellists, make sure that the MS channels are loaded!
		if (!rootStore.page.MsEpg.channels?.length > 0) yield rootStore.page.MsEpg.getChannelsAsync()
		const test = yield rootStore.page.singleton.channelLists.fetchDataAsync()
		//console.debug("test is", rootStore.page.singleton.channelLists.DataArray.isReady, rootStore.page.singleton.channelLists.DataArray, rootStore.page.singleton.channelLists.DataArray.ChannelList)
		//yield rootStore.page.ChannelListRepo.fetchDataAsync(false)
		//const chLists = rootStore.page.ChannelListRepo.ChannelLists
		const chLists = rootStore.page.singleton.channelLists.DataArray.ChannelList
		//console.debug("ChannelListRepo", rootStore.page.singleton.channelLists.isReady, rootStore.page.ChannelListRepo?.isReady, chLists)
		if (chLists) {
			for (let elem of chLists) {
				//console.debug("elem", elem.Channels.Channel.length, elem.Name, elem.id, channelListId)
				// MTVW-33
				//console.debug("Name=", elem.Name)
				if (elem.id === channelListId) {
					//console.debug("HIT %s ", elem.Name)
					chName = elem.Name
					chId = elem.id
					break
				}
			}
			setChannelLists(chLists)
			channelListInfo.setId(chId)
			channelListInfo.setName(chName)
			rootStore.page.ChannelListRepo.setActiveChannelList(chId, false)
			//const test = rootStore.page.ChannelListRepo.ChannelLists
			//console.debug("ChannelLists", rootStore.page.ChannelListRepo.isReady, chLists)
			// channelListRepo is updated upon a change in the ChannelListEditor
			if (event === "deleted") handleChangeChannelList(allChannels, false)
		}
	})

	const handleToggle = (e) => {
		setAnchorEl(e.currentTarget);
		if (anchorEl) {

			// refresh channel lists
			// MTVW-636: useEffect will be called
			//channelLists.fetchData()
		}
		e.preventDefault()
		// MTV-3583: e.stopPropagation required with React 17
		e.stopPropagation()
	}

	const handleClose = (e = null, prevent = false) => {
		setAnchorEl(null);
		// on mobile, a touch event on the close button would also create a 2nd event on the Senderliste button that would invoke handleToggle
		if (prevent) {
			e.preventDefault();
		}
	}

	const handleChangeChannelList = (channelList, doClose = true) => {
		//console.debug("New list: %o, %s", channelList, channelList.id)
		channelListInfo.setId(channelList.id)
		channelListInfo.setName(channelList.Name)
		// MTVW-33
		rootStore.sso.browserStore.setChannelList(channelList.id, channelList.Name)

		rootStore.page.ChannelListRepo.setActiveChannelList(channelList.id, true)
		//console.debug("activeChannelId %s", rootStore.page.ChannelListRepo.activeChannelId)
		//rootStore.page.LiveTv._autoStartDone = false


		//MTV-2807 
		//change the channel list don't must stop to play the current channel 
		//const channelIdBefore = rootStore.page.ChannelListRepo.activeChannelId
		// might be 'allChannels' or empty channel list, prevent restarting current play
		// if (channelList.id && channelList.Channels?.Channel?.length > 0) {
		// 	const channelIdBeforeInNewList = channelList.Channels?.Channel?.filter(i => i.id === channelIdBefore).length > 0
		// 	if (!channelIdBeforeInNewList) {
		// 		rootStore.page.LiveTv.handlePlayChannelAsync()
		// 	}
		// }
		if (doClose) handleClose()
	}

	// MTVW-657: error if more than 20 channel lists per profile
	const DisabledMessage = {
		show: false, info: {}, onCancel: null, onConfirm: null
	}
	const [modalMessage, setModalMessage] = useState(DisabledMessage)

	const historyRouteAdd = useHistoryRouteAdd()

	const confirmError = (modalMessage) => {
		setModalMessage(modalMessage => ({ ...modalMessage, show: false }))
	}
	const TooManyChannelListsMessage = {
		show: true,
		info: {
			title: l10n.featureChannelListsTooManyChannelListsTitle,
			content: l10n.featureChannelListsTooManyChannelListsDescription,
			button1: null,
			button2: l10n.commonActionOk
		},
		onCancel: null, onConfirm: confirmError
	}

	const handleCreateChannelList = () => {
		// MTVW-657: check number of channel lists created by the current profile
		const MAX_LISTS = 20
		let nOwnLists = 0
		for (let i = 0; i < channelLists?.length; i++) {
			if (channelLists[i].ProfileId === rootStore.sso.profile?.id) nOwnLists++
		}
		//console.debug("channelLists=", channelLists, channelLists?.length, nOwnLists)
		if (nOwnLists >= MAX_LISTS) {
			handleClose()
			setModalMessage(TooManyChannelListsMessage)
		}
		else {
			historyRouteAdd(ROUTE_NAMES.ChannelListEditor, { type: "create", id: null })
			handleClose()
		}
	}

	const handleEditChannelList = (channelList) => {
		historyRouteAdd(ROUTE_NAMES.ChannelListEditor, { type: "edit", id: channelList.id })
		handleClose()
	}

	// MTVW-636: observe channelLists
	return channelLists && (
		<div>
			{/* MTVW-657: error message */}
			<ModalMessage
				show={modalMessage.show}
				msgInfo={modalMessage.info}
				onCancel={() => { if (modalMessage.onCancel) modalMessage.onCancel(modalMessage) }}
				onConfirm={() => { if (modalMessage.onConfirm) modalMessage.onConfirm(modalMessage) }}
			/>
			{/* <Tooltip title="Senderlisten" arrow > */}
			<Button
				id="channelListMenuButton"
				data-test="channel_menu_current"
				aria-controls={open ? 'customized-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={(e) => { handleToggle(e) }}
			>
				<img src={ListIcon} className={classes.Icons} alt="Sender" />
				<label style={{ cursor: "pointer" }} data-test="active_channel_list_name">{channelListInfo.name}</label>
				<img src={ArrowDown} className={classes.NavBarArrowIcon} alt=""></img>
				{/* <KeyboardArrowDownIcon className={classes.NavBarArrowIcon} /> */}
			</Button>
			{/* </Tooltip> */}

			<Menu
				id="customized-menu"
				aria-labelledby="customized-menu"
				anchorEl={anchorEl}
				elevation={1}
				//getContentAnchorEl={null}
				classes={{
					paper: `${classes.ChannelListMenuList} PopperItemResponsive`
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center"
				}}

				// keepMounted
				open={open}
				onClose={handleClose}
				data-test="list_channelList"
				disablePortal={false}
			>
				<MenuItem key={"change"} onClick={() => handleChangeChannelList(allChannels)}
					className={classnames(classes.ChannelListMenuListItem, { [classes.ChannelListSelected]: channelListInfo?.id === null })}
					data-test="channel_menu_item">
					{channelListInfo?.name === allChannels.Name ?
						<ListItemIcon className={classes.CheckIconContainer}>
							<img src={CheckIcon} alt="check icon" />
						</ListItemIcon>
						:
						<div className={classes.CheckIconContainer}></div>
					}
					{allChannels.Name}
				</MenuItem>

				{channelLists?.map(i => (
					<div key={i.id}>
						{/* MTVW-657: divider before first predefined channel list*/}
						<div>
							{i.firstPredefined ?
								<Divider />
								: null
							}
						</div>
						<MenuItem //key={i.id}
							onClick={() => handleChangeChannelList(i)}
							className={classnames(classes.ChannelListMenuListItem, { [classes.ChannelListSelected]: channelListInfo?.id === i.id })}
						>
							<div className={classes.MenuColumn}>
								{channelListInfo?.id === i.id ?
									<ListItemIcon className={classes.CheckIconContainer}>
										<img src={CheckIcon} alt="check icon" />
									</ListItemIcon>
									:
									<div className={classes.CheckIconContainer}></div>
								}
								{i.Name}
							</div>
							<IconButton style={{ visibility: (i.ProfileId === rootStore.sso.profile?.id) && !isMobile ? "visible" : "hidden" }} className={classes.EditIconEnabled} data-test="channel_menu_edit" onClick={(e) => {
								handleEditChannelList(i)
								e.stopPropagation() // for not change the channelList
							}}>
								<img src={EditIcon} alt="edit icon" />
							</IconButton>
						</MenuItem>
					</div>
				))}
				{/* MTVW-121 hide the menu item to open the channelList editor on mobile devices */}
				{!isMobile &&
					<div>
						<Divider />
						<Tooltip
							// MTVW-121 title={!isMobile ? "Neue Senderliste erstellen." : "Nur auf Tablet und Desktop verfügbar"} arrow]
							title={l10n.featureChannelListsCreateNewList} arrow
							placement={"left"}
							PopperProps={{
								popperOptions: {
									modifiers: {
										offset: {
											enabled: true,
											offset: '-15px, -320px',
										},
									},
								},
							}}

						>
							<span>
								<MenuItem key={"create"} onClick={handleCreateChannelList}
									className={classes.ChannelListMenuListItem}
								// MTVW-121 disabled={isMobile}
								>
									<ListItemIcon className={classes.CheckIconContainer}>
										<img src={AddIcon} alt="add icon" />
									</ListItemIcon>
									{l10n.featureChannelListsNewList}
								</MenuItem>
							</span>
						</Tooltip>
					</div>}
			</Menu>

		</div>
	)
})
