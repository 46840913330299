// VITE BUILD: require.context is a webpack feature
/*
const imagesContext = require.context("img/fallback/", false)

// WEBPACK old implementation
export const fallbackImage = (name, fallback = null) => {
	try {
		return imagesContext("./" + name)
	} catch (e) {
		if (fallback !== null) {
			return fallbackImage(fallback)
		}
	}
}
*/

// VITE: prepare array with fallback image enties (async!)
const imagesContext = import.meta.glob('/src/img/fallback/*.png')
//console.debug("imagesContext", imagesContext, typeof imagesContext)
const fallbackEntries = []
for (const path in imagesContext) {
	imagesContext[path]().then((mod) => {
		fallbackEntries.push(mod)
	})
}

const fallbackImage = (name, fallback = null) => {
	//console.debug("fallbackImage %o, %o, %o", name, typeof name, fallback, imagesContext, fallbackEntries)
	for (const mod of fallbackEntries) {
		//console.debug("mod=", mod, typeof mod)
		if (mod.default.includes(name.replace(".png", ""))) {
			//console.debug("env", import.meta.env)
			//return import.meta.env.MODE === "development" ? path : path.replace("/src/", "")
			//console.debug("returning", mod.default)
			return mod.default
			//return "/src/img/fallback/tv_category_all.png"
		}
	}
	//console.debug("fallback case", fallback)
	if (fallback !== null) return fallbackImage(fallback)
}

export const fallbackImageCategory = type => fallbackImage(`tv_category_${type}.png`, "tv_category_all.png")

export const emptyPng = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
