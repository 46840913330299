import { observable, makeObservable } from "mobx"
import { tryJsonParse } from "store/util/jsonUtils"
import { ErrorBase } from "store/ErrorBase"

export class MicroServiceError extends ErrorBase {
	action = null
	detail = null
	hint = null
	internal = null
	source = null
	status = null
	title = null
	requestId = null
	url = null
	timeoutDuration = null

	constructor(requestId, e) {
		super()
		makeObservable(this, {
			action: observable,
			detail: observable,
			hint: observable,
			internal: observable,
			source: observable,
			status: observable,
			title: observable,
			requestId: observable,
			url: observable,
			timeoutDuration: observable
		})
		this.requestId = requestId
		//console.debug("ARGS %o, requestId %o, e %o", arguments, this.requestId, e)
	}
	/**
	 * @returns {MicroServiceError}
	 */
	static tryCreateErrorFromJson(codes, response, jsonString, e, ...paramsConstructor) {
		if (MicroServiceError.isInstanceOf(e)) {
			return e
		}
		//console.debug("response %o, json %o, e %o, params %o", response, jsonString, e, paramsConstructor)
		const self = new MicroServiceError(...paramsConstructor)
		// avoid circular structure when using JSON.stringify
		if (typeof e === "string") self.e = e
		codes.forEach(code => { self.codes.add(code) })
		const json = typeof jsonString !== "object" ? tryJsonParse(jsonString) : jsonString
		self.applyData(response)
		if (json !== null) {
			// MTVW-272
			self.applyData(json)
			// TODO: msResponse duplicates the information
			self.msResponse = json
		}
		return self
	}

	static createErrorFromException(codes, e, ...paramsConstructor) {
		const self = new MicroServiceError(...paramsConstructor)
		// avoid circular structure when using JSON.stringify
		if (typeof e === "string") self.e = e
		codes.forEach(code => { self.codes.add(code) })
		return self
	}

	applyData(error) {
		//console.debug("@(error=%o)", error)
		this.action = error?.action
		this.detail = error?.detail
		this.hint = error?.hint
		this.internal = error?.internal
		this.source = error?.source
		this.status = error?.status
		this.title = error?.title
		this.url = error?.url
		this.timeoutDuration = error?.timeoutDuration
		return this
	}
}
