import React from "react"
import { Card, DialogContent, CircularProgress } from "@mui/material"
import { Img } from "components/Img"
import { fallbackImageCategory } from "store/util/fallbackImage"
import IconButton from "@mui/material/IconButton"
import { rootStore } from "store/RootStore"
import { EpgChannelDetailsInfo, ProgramDetailsInfo, PROGRAM_DETAILS_MODE, PROGRAM_ORIGIN } from "scenes/components/EpgChannelDetailsInfo"
//import { EpgChannelDetailsToolsTitleSecondary } from "scenes/components/EpgChannelDetailsTools"
import { EpgChannelRelatedContent } from "../../LiveTv/components/EpgChannelRelatedContent"
import { Player } from "scenes/Player/Player"
import defaultCoverImg from "img/cover-no-photo.gif"
//import { useHistoryRouteAdd } from "components/utils/RouteUtils"
//import { ROUTE_NAMES } from "site-ql/RouteConst"
//import { ErrorMessage } from "components/ErrorMessage"
//import { isPhoneSize, isSafari } from "utils/Utils"
import { observer } from "mobx-react"
//import { CreateComponent } from "components/utils/CreateComponent"

import "./../OverlayEventDetails.css"

export const EventComponent = observer(({ page }) => {
	//const historyRouteAdd = useHistoryRouteAdd()

	let pageRelatedContent = rootStore.page.EpgChannelRelatedContentOverlay

	const closeButtonOverlay = <div className="closeLayer">
		<IconButton aria-label="Close"
			id="closeOverlay"
			disableRipple={true}
			disableFocusRipple={true}
			className="closeButton" onClick={(event) => {
				event.stopPropagation()
				// MTVW-624: combined activityStack for related content and search
				page.resetActivity()
				page.setShow(false)
				page.setIsPlayerPresent(false)
			}} data-test="overlay_close">
		</IconButton>
	</div>
	const resetOverlayDetailsForRelatedContent = () => {
		// MTVW-708: Enable Ansehen button
		page.setPlayEventStarted(false)
		if (page.isPlayerPresent) {
			page.setIsPlayerPresent(false)
		}
	}

	return (
		<div id="eventComponent">
			{page.isPlayerPresent === true && (
				<>
					<Player defaultCoverImg={defaultCoverImg} playerName="playerDetailPageVo" />
					{(rootStore.page.Player.source.adState?.replay || rootStore.page.Player.source.adState?.fastFwd) && closeButtonOverlay}

					<DialogContent className="movieDetailsContainer  movieDetailsContainerResponsive" data-test="movie_details_container_dialog">
						{rootStore.page.Player.source.Event ? (
							<div className="relatedContentContainer">
								{console.debug("overlay GOT EVENT %o, source %o", rootStore.page.Player.source.Event, rootStore.page.Player.source)}
								<EpgChannelDetailsInfo oEventData={rootStore.page.Player.source.Event} page={page} origin={PROGRAM_ORIGIN.overlay} />
							</div>
						) : <div className="spinnerInfo"><CircularProgress size={100} data-test="" /></div>}
						<div className="relatedContentContainer">
							<EpgChannelRelatedContent
								oEventData={rootStore.page.Player.source.Event}
								page={pageRelatedContent}
								resetOverlay={resetOverlayDetailsForRelatedContent} />
						</div>
					</DialogContent>
				</>
			)}
			{page.isPlayerPresent === false && page.isReady && (
				<div>
					<Card className="moviePreviewWrapper moviePreviewWrapperResponsive" data-test="overlay_poster_image">
						<Img
							src={page.oEvent.Title?.BoxCover}
							// MTVW-831
							params="mode=box&w=1920&h=1080"
							/* no error triggered for invalid picture (e.g. HTML page)
							onError={(e) => {
								e.stopPropagation()
								console.errpr("no image", e)
							}}
							*/
							fallback={fallbackImageCategory(page.oEvent.Title?.MovieType)}
							hideBefore={true}
							alt={page.oEvent.Title?.Name}
							className="movieCover"
						/>
						{closeButtonOverlay}
						{/* MTVW-810: desktop, full info on cover*/}
						{console.debug("FROM EventComponent 1")}
						<ProgramDetailsInfo page={page} oEventData={page.oEvent} programDetailsMode={PROGRAM_DETAILS_MODE.desktop} origin={PROGRAM_ORIGIN.overlay} />
						{/* MTVW-624: combined activityStack for related content and search */}
						{page.activityStack.length > 0 &&
							<div className="backLayer">
								<IconButton aria-label="Back"
									// img is in css class
									disableRipple={true}
									disableFocusRipple={true}
									className="backContentItemButton"
									// MTVW-624: combined activityStack for related content and search
									onClick={(e) => { e.preventDefault(); e.stopPropagation(); page.restoreActivity() }}
									data-test="overlay_back">
								</IconButton>
							</div>}
						{/* MTVW-810: no central play button anympre
						{page.isPlayButtonEnabled && (
							<IconButton
								className={isPhoneSize() ? "moviePlayButtonMobile" : "moviePlayButton"}
								//sx={{ transition: isSafari() ? "0ms ease-in-out !important" : "250ms ease-in-out !important" }}
								data-test="play_button"
								disableRipple={true}
								disableFocusRipple={true}
								onClick={(event) => {
									//event.nativeEvent.stopImmediatePropagation()
									event.stopPropagation()
									event.preventDefault()
									page.setPlayEvent()
									rootStore.page.LiveTv.memoSelectedEpg = null //MTV-3740 restore the snapshot for the channelList used in EpgChannel.js
									// MTVW-727b: use isInLiveTv
									if (!rootStore.page.Player.isInLiveTv) { // was rootStore.page.Player.playerLiveTvVo.isPlayerActive === false
										page.setIsPlayerPresent(true)
									} else {
										page.setShow(false)
										rootStore.page.MiniEpg.setShow(false)
										// MTVW-624: combined activityStack for related content and search
										page.resetActivity()
										historyRouteAdd(ROUTE_NAMES.LiveTv)
										setTimeout(() => {
											//window.scrollTo(0, 0)
											const elem = document.getElementById("tvLiveAndDetailsContainer")
											if (elem) elem.scrollTop = 0
										}, 100)
									}
								}}
							>
							</IconButton>
						)}
						*/}
					</Card>
					{/* MTVW-810: full info below cover */}
					{console.debug("FROM EventComponent 2")}
					<ProgramDetailsInfo page={page} oEventData={page.oEvent} programDetailsMode={PROGRAM_DETAILS_MODE.tabletOrMobile} origin={PROGRAM_ORIGIN.overlay} />
				</div>
			)}
			{page.isPlayerPresent === false && (
				// active when clicking in TV Guide on a program item
				<DialogContent className="movieDetailsContainer movieDetailsContainerResponsive" data-test="movie_details_container_dialog">
					{page.isReady &&
						<div className="relatedContentContainer">
							<EpgChannelDetailsInfo oEventData={page.oEvent} page={page} origin={PROGRAM_ORIGIN.overlay} />
							<EpgChannelRelatedContent
								oEventData={page.oEvent}
								page={pageRelatedContent}
								resetOverlay={resetOverlayDetailsForRelatedContent} />
						</div>}
				</DialogContent>
			)}
		</div>
	)
})
