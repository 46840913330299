export const ROUTE_NAMES = {
	LiveTv: "LiveTv",
	TvGuide: "TvGuide",
	Movies: "Movies",
	Sport: "Sport",
	Settings: "Settings",
	FirstInstall: "FirstInstall",
	CreateProfile: "CreateProfile",
	Recordings: "Recordings",
	RecordingsList: "RecordingsList",
	RecordingsSeriesList: "RecordingsSeriesList",
	ChannelListEditor: "ChannelListEditor",
	Search:"Search",
	Internals: "Internals",
	NotFound: "NotFound"
}
