// l10n status: done
import { LinearProgress, Typography } from "@mui/material"
import { alpha } from "@mui/material/styles"
import CheckCircle from "@mui/icons-material/CheckCircle"
import RadioButtonUnchecked from "@mui/icons-material/RadioButtonUnchecked"
import { Img } from "components/Img"
import { CreateComponent } from "components/utils/CreateComponent.js"
import { useHistoryRouteAdd } from "components/utils/RouteUtils"
import seriesLogo from "img/series-folder.svg"
import React from "react"
import { ROUTE_NAMES } from "site-ql/RouteConst"
import { rootStore } from "store/RootStore"
import { emptyPng, fallbackImageCategory } from "store/util/fallbackImage"
import { theme as _theme } from "../../../site-ql/theme"

import { CALLING_CONTEXT_TYPE } from "store/page/OverlayEventDetails"

import "./recordingComponentsStyles.css"
import { l10n } from "store/lang/L10n"

// MTVW - 206 these classes override the default material UI classes
const styles = theme => ({
	MoviesProgressBar: { background: alpha(theme.palette.primary[50], 0.24) },
	bar1Determinate: { background: theme.palette.primary.main }
})

export const MovieTile = CreateComponent(styles, false, true, ({ classes, movie, isDelete, isDeleteSelected, deleteMarkCallback, onClickCallback = null, routeProps, isShowEpisodeTitle }) => {
	const imageCategory = fallbackImageCategory(movie.Title?.Genres?.itemFirst?.type, "tv_category_all.png")
	const historyRouteAdd = useHistoryRouteAdd()
	const nDash = '\u2013'

	const handleOnClick = () => {

		if (onClickCallback !== null) {
			onClickCallback(movie)
		}
		// serie
		else if (movie.isSeries) {
			historyRouteAdd(ROUTE_NAMES.RecordingsSeriesList, { ...routeProps, ...{ serieId: movie.id } })
		}
		// single
		else {
			rootStore.page.OverlayEventDetails.setEpgEventById(movie.EventId).then(res => {
				// MTV-3570: Need movie.id for media microservice instead of movie.Content?.id
				rootStore.page.OverlayEventDetails.setTypeContentPlaylist(movie.id)
					.setComponentType('event')
					.setShow(true, CALLING_CONTEXT_TYPE.Recording)
			})
		}
	}
	const handleMovieCardSelection = () => {
		if (isDelete) {
			deleteMarkCallback()
		} else
			handleOnClick()
	}

	// MTVW-646: for adult movies Channel will not be set by Traxis because we filter them out in the GetRecordings request
	// -> suppress content with no valid Channel
	//return (
	return !movie?.Channel ? null : (
		<div className="MoviesGrid"
			style={isDelete && isDeleteSelected ? { border: `2px solid ${_theme.palette.primary["main"]}` } : {}}
			data-test={movie.isSeries ? "series" : "episode"}>
			{isDeleteSelected && isDelete && <CheckCircle className="MoviesDeleteIcon" onClick={handleMovieCardSelection} />}
			{isDelete && <RadioButtonUnchecked className="MoviesDeleteIcon" onClick={handleMovieCardSelection} />}

			<div className="MoviesPosterWrapper" style={{ backgroundImage: `url(${imageCategory})` }} onClick={handleMovieCardSelection}>
				<Img src={movie.Title?.Pictures?.getBoxCover} params="mode=box&w=480&h=270" hideBefore={true} fallback={emptyPng} alt={movie.Name} className="MoviesPoster" />
				{movie.isSeries ? (
					<img src={seriesLogo} className="MovieTypeLogo" alt="Serie" />
				) : (
					<div className="MoviesProgressBarWrapper">
						<LinearProgress
							variant="determinate"
							value={movie.BookmarkProgress ?? 0}
							className={classes.MoviesProgressBar}
							classes={{
								bar1Determinate: classes.bar1Determinate
							}}
						/>
					</div>
				)}
			</div>
			<div className="MoviesGridTextWrapper" onClick={handleMovieCardSelection}>
				<Typography variant="overline" className="MoviesChannel" noWrap={true}>
					{movie?.Channel?.Name}
				</Typography>
				<Typography variant="body2" className="MoviesTitle" data-test="movie_title_recording">
					{/* 
						if the episode number and the title of the episode are not present we insert the title of the series as title
						if the episode number is present but not the title of the episode, we insert only the episode number as title of the episode
						if the episode number and the title of the episode are present, we use both as titles
					*/}

					{isShowEpisodeTitle === true ?
						`${movie.Title.EpisodeNum !== null ?
							//`Episode ${movie.Title.EpisodeNum}  ${movie.Title.EpisodeName === null ? "" : movie.Title.EpisodeName}`
							`${l10n.featureDetailsEpisode} ${movie.Title.EpisodeNum}  ${movie.Title.EpisodeName === null ? "" : movie.Title.EpisodeName}`
							:
							`${movie.Title.EpisodeName === null ? movie.Name : movie.Title.EpisodeName}`
						}` :
						movie.Name
					}

					{/* 
					before MTV-2103
					{isShowEpisodeTitle === true && (movie.Title?.EpisodeNum !== null || movie.Title.EpisodeName !== null) ?
						`Episode ${movie.Title.EpisodeNum}
						: 
						${movie.Title.EpisodeName}` : movie.Name} */}

				</Typography>
				<Typography variant="caption" noWrap={true} className="MoviesDetails">
					{/* {movie.IsPersonal ? "Privat" : movie.isCurrentProfile ? "Für alle Profile" : movie.hasProfileNickname && "Aufgenommen von " + (movie.ProfileNickname ?? "")} */}
					{movie.IsPersonal ? `${l10n.featureRecordingsProfilePersonal}` : movie.isCurrentProfile ? `${l10n.featureRecordingsProfileAll}` : movie.hasProfileNickname && `${l10n.featureRecordingsRecordedBy.format(movie.ProfileNickname ?? "")}`}
				</Typography>

				<Typography variant="caption" noWrap={true} data-test="recording_time_info">
					{movie.isSeries
						//? `${movie.childRecordingsCount} ${movie.childRecordingsCount === 1 ? "Episode" : "Episoden"}`
						? movie.childRecordingsCount === 1 ? `${l10n.featureRecordingsNumberSingleEpisode.format(movie.childRecordingsCount)}` : `${l10n.featureRecordingsNumberOfEpisodes.format(movie.childRecordingsCount)}`
						: `${movie?.StartTimeDateFormat("DD.MM.YYYY")}, ${movie?.StartTimeDateFormat("HH:mm")}${nDash}${movie?.EndTimeDateFormat("HH:mm")}`}
				</Typography>
			</div>
		</div>
	)
})


// MTVW-206 code abow is never used?
// export const MovieTileEmpty = CreateComponent(styles, false, false, ({ classes }) => <Grid item className={classes.MoviesGrid} />)
