/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-09
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-11
 */

import { flow, observable, makeObservable } from "mobx"
import { rootStore } from "store/RootStore"
import { tryJsonParse } from "store/util/jsonUtils"
import { moment } from "store/util/moment"
import { ErrorBase } from "store/ErrorBase"

export class TraxisError extends ErrorBase {
	Timestamp = null
	Source = null
	OriginalUri = null
	InternalError = null
	Message = null
	AdditionalInfo = null

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			Timestamp: observable,
			Source: observable,
			OriginalUri: observable,
			InternalError: observable,
			Message: observable,
			AdditionalInfo: observable,
			// MTV-3583: do not make it computed here, mobx 6 generates an error if it's already registered in the base class
			//message: computed
		})
	}
	/**
	 * @returns {TraxisError}
	 */
	static tryCreateErrorFromJson(jsonString, e, ...paramsConstructor) {
		if (ErrorBase.isInstanceOf(e)) {
			return e
		}
		const self = new TraxisError(...paramsConstructor)
		self.e = e
		const json = typeof jsonString !== "object" ? tryJsonParse(jsonString) : jsonString
		if (json !== null) {
			self.applyData(json)
		}
		return self
	}

	applyData(error) {
		console.info("@(error=%o)", error)
		this.Timestamp = error?.Error?.Timestamp
		this.Source = error?.Error?.Source
		this.OriginalUri = error?.Error?.OriginalUri
		this.InternalError = error?.Error?.InternalError
		this.Message = error?.Error?.Message ?? null
		this.AdditionalInfo = error?.Error?.AdditionalInfo ?? null
		return this
	}

	isInternalErrorAny(t) {
		return t.find(i => String(this.InternalError).indexOf(i) > -1) !== void 0
	}

	isInternalErrorAll(t) {
		return t.find(i => String(this.InternalError).indexOf(i) === -1) === void 0
	}

	isMessageContainsAny(t) {
		return t.find(i => String(this.Message).indexOf(i) > -1) !== void 0
	}

	isMessageContainsAll(t) {
		return t.find(i => String(this.Message).indexOf(i) === -1) === void 0
	}

	isAdditionalInfoContainsAny(t) {
		return t.find(i => String(this.AdditionalInfo).indexOf(i) > -1) !== void 0
	}

	isAdditionalInfoContainsAll(t) {
		return t.find(i => { return String(this.AdditionalInfo).indexOf(i) === -1 }) === void 0
	}

	get errorDate() {
		return moment(this.Timestamp)
	}

	get isError() {
		return this.Message !== null
	}

	// MTV-3180: added, retry axios error doesn't make much sense since the request will usually return the same result again
	get isNetworkError() {
		//console.debug("CODE %s MESSAGE %s %o ", this.e?.code, this.e?.message, this.e)
		// MTV-2133: add ECONNABORTED which signals timeout
		return /*this.e?.isAxiosError ||*/ this.e?.code === "ECONNABORTED" || this.e?.message?.toLowerCase().indexOf("network error") > -1
	}

	get message() {
		return this.Message
	}

	handleError = flow(function* (e) {
		// MTV-3180: provide return value in any case
		let handled = false

		//console.debug("handleError %o, %o", this, e)
		if (this.isErrorTokenAuth) {
			console.debug("handleError refresh token")
			yield rootStore.sso.profile.refreshTokens()
			return true
		}

		/* MTVW-157: commented
		if (this.isErrorTokenAuth || this.isErrorInvalidProfileId) {
			console.info("@@handleError(e=%e) isErrorTokenAuth=true", e)
			// if sth currently doing wait for that
			if (rootStore.waitForAuth.isStarted === false) {
				console.info("@@handleError(e=%e) waitForAuth.setRestart()")
				rootStore.waitForAuth.setRestart()

				if (this.isErrorInvalidProfileId) {
					// revalidate profile
					handled = yield rootStore.sso.handleAuthenticateAsync()
				} else {
					// refresh tokens
					handled = yield rootStore.sso.handleTokenVerificationAsync()
				}
			}

			if (rootStore.waitForAuth.isStarted === true) {
				console.info("@@handleError(e=%e) -> waitForAuth.waitAsync()", e)
				yield rootStore.waitForAuth.waitAsync()
				console.info("@@handleError(e=%e) <- waitForAuth.waitAsync()", e)
				return rootStore.waitForAuth.isDone === true
			}
		}
		*/
		return handled
	})

	get isErrorTokenAuth() {
		/*
			InternalError: "ESsoClient"
			Message: "Error logging into SSO. Please check the credentials in the configuration. Error message: 'expired_token'."
		*/
		return this.InternalError === "ESsoClient" && this.isMessageContainsAny(["expired_token", "invalid_token"])
	}

	get isErrorInvalidProfileId() {
		/*
			InternalError: "EInvalidProfileId"
			Message: "Invalid parameter 'ProfileId', value 'xyz'"
		*/
		return this.InternalError === "EInvalidProfileId"
	}

	get isErrorNotMutationLeft() {
		/*
			InternalError : "EPolicyDenied",
			Message : "Customer has no available mutations left, so it is not allowed to mutate cpe.",
		*/
		return this.InternalError === "EPolicyDenied" && this.isMessageContainsAny(["no available mutations left"])
	}

	get isErrorMaximumMutationReached() {
		/*
			InternalError : "EPolicyDenied",
			Message : "Not allowed to register cpe. Maximum registrations reached.",
		*/
		return this.InternalError === "EPolicyDenied" && this.isMessageContainsAny(["Not allowed to register cpe"])
	}

	get isErrorInvalidStbId() {
		return this.isErrorInvalidStbId_OnOtherDeviceRegistered || this.isErrorInvalidStbId_NotRegistered
	}

	get isErrorInvalidStbId_OnOtherDeviceRegistered() {
		/*
			InternalError : "ENotAuthorized",
			Message : "Profile 'ca75cb03-ea9f-444f-a6cc-d9d43b0d6187' does not belong to customer '3fd56fb9-70aa-43a3-960f-a6d3cd61c0c2'"}}
		*/
		return this.InternalError === "ENotAuthorized" && this.isMessageContainsAll([" does not belong to customer ", "Profile "])
	}

	get isErrorInvalidStbId_NotRegistered() {
		/*
			InternalError : "EInvalidSTBId",
			Message : "Invalid parameter 'StbId', value '7HbmoxH5A3vzOkAmft90KnoD5PY=AA'
		*/
		return this.InternalError === "EInvalidSTBId" && this.isMessageContainsAny(["Invalid parameter 'StbId'"])
	}

	get isErrorNotAuthorized() {
		return this.isErrorNotAuthorized_RollingBuffer || this.isErrorNotAuthorized_Channel
	}

	get isErrorNotAuthorized_RollingBuffer() {
		/*
			InternalError: "ENotAuthorized"
			Message: "Customer 'cea07da8-d5af-4950-87b0-4d6c92f0f73f' is not authorized to view rollingbuffer '28cc9eb6-c79b-4321-9a4a-b0e41fafde4b'"
		*/
		return this.InternalError === "ENotAuthorized" && this.isMessageContainsAny(["is not authorized to view rollingbuffer"]) && this.isAdditionalInfoContainsAny(["=EPolicyDenied.GeoBlock"])
	}

	get isErrorRecordingManagementNotEntitled() {
		/*
			InternalError: "ERecordingManagementNotEntitled",
			Message: "Exceeded rolling buffer duration or opt-in date in past for customer '57f739b6-7ee5-4b05-9c9d-d57fcdddd0f5'   and channel '2293'"
		*/
		return this.InternalError === "ERecordingManagementNotEntitled" && this.isMessageContainsAny(["Exceeded rolling buffer duration or opt-in date in past for customer"])
	}

	//MTVW-186 Modified to distinguish in case of record not found to the next one for event id not found
	get isErrorRestServiceRecordedNotFound() {
		/*
			InternalError:"isErrorRestServiceRecordedNotFound"
			Message:"No resource found of type 'Recording' with id 'undefined'"
		*/
		return this.InternalError === "ERestServiceResourceNotFound" && this.isMessageContainsAny(["No resource found of type 'Recording' with id 'undefined"])
	}
	//MTVW-186 Modified to distinguish in case  event id not found
	get isErrorRestServiceResourceNotFound() {
		/*
			InternalError:"ERestServiceResourceNotFound"
			Message:"No resource found of type 'Event' with id"
		*/
		return this.InternalError === "ERestServiceResourceNotFound" && this.isMessageContainsAny(["No resource found of type 'Event' with id"])
	}


	get isErrorNotAuthorized_RollingBuffer_OutsidePlayableWindow() {
		/*
			AdditionalInfo: "InitialStartDateTime=EOutsidePlayableWindow"
			InternalError: "ENotAuthorized"
			Message: "Customer '4eb77d44-0ff0-464d-9ac1-8dc96ad19950' is not authorized to view rollingbuffer '28cc9eb6-c79b-4321-9a4a-b0e41fafde4b'"
		*/
		return this.InternalError === "ENotAuthorized" && this.isAdditionalInfoContainsAny(["InitialStartDateTime=EOutsidePlayableWindow"])
	}

	get isErrorNotAuthorized_Channel() {
		/*
			AdditionalInfo: "crid://schange.com/0106f68a-9071-4833-9b1f-c29cbe05aefe=ENotEntitled|crid://schange.com/a0d493cd-bde2-4150-9f70-f74ebf13b0d6=EPolicyDenied.GeoBlock"
			InternalError: "ENotAuthorized"
			Message: "Customer 'cea07da8-d5af-4950-87b0-4d6c92f0f73f' is not authorized to view channel '2021'"
		*/
		return this.InternalError === "ENotAuthorized" && this.isMessageContainsAny(["is not authorized to view channel"]) && this.isAdditionalInfoContainsAny(["=ENotEntitled|crid:"])
	}

	get isErrorRecordingNotAuthorized_Channel() {
		/*
			"InternalError": "ERecordingManagementNotEntitled",
			"Message": "No valid entitlement found for customer '6e9d93a2-0de7-4225-9a13-d59e3d852886' and channel '2550'"

		*/
		return this.InternalError === "ERecordingManagementNotEntitled" && this.isMessageContainsAny(["No valid entitlement found for customer"])
	}

	get isErrorNotAuthorized_GeoBlock() {
		/*
		AdditionalInfo: "crid://schange.com/783618c9-b43b-4154-86d0-fa61f827eca6=EPolicyDenied.GeoBlock"
		InternalError: "ENotAuthorized"
		Message: "Customer 'cea07da8-d5af-4950-87b0-4d6c92f0f73f' is not authorized to view content '1d44aa53-f584-4a48-a2fa-dec5398c600f'"
		OriginalUri: "https://apps-traxis-api.quickline.ch/traxis/web?output=json&language=de&req_name_debug=CreateContentPlaylist"
		Source: "OSC-TXFE01"
		Timestamp: "2019-11-03T18:26:12Z"
		*/
		return this.InternalError === "ENotAuthorized" && this.isMessageContainsAny(["is not authorized to view channel"]) && this.isAdditionalInfoContainsAll(["=ENotEntitled|crid:", "=EPolicyDenied.GeoBlock"])
	}

	get isErrorNotAuthorized_Replay_GeoBlock() {
		/*
		InternalError": "ENotAuthorized",
		"Message": "Customer '6e9d93a2-0de7-4225-9a13-d59e3d852886' is not authorized to view rollingbuffer '22b1846a-7c1d-4535-a2bf-88c674536c16'",
			"AdditionalInfo": "crid://schange.com/e3d503f7-7842-4b84-9ef5-c904ca9a2cb7=EPolicyDenied.GeoBlock"
		*/
		return this.InternalError === "ENotAuthorized" && this.isMessageContainsAny(["is not authorized to view rollingbuffer"]) && this.isAdditionalInfoContainsAll(["crid:", "=EPolicyDenied.GeoBlock"])
	}

	get isErrorRecordingQuotaExceeded() {
		/*
			InternalError: "EQuotaExceeded"
			Message: "Recording quota of customer '4eb77d44-0ff0-464d-9ac1-8dc96ad19950' exceeded"
		*/
		return this.InternalError === "EQuotaExceeded" && this.isMessageContainsAny(["Recording quota of customer"])
	}

	get isErrorRecordedAlready() {
		/*
			InternalError: "ERecordingManagement"
			Message: "Event 'crid://media-press.tv/de/54111477,imi:0010000004179009' is already recorded with id '13f34472-053f-42e8-821b-5de50ac5f905'"

			"Message": "Series 'crid://media-press.tv/base/de/12472955' is already recorded 'c3f21817-0ea2-41ba-9797-51eecdd3f31a'"
		*/
		return this.InternalError === "ERecordingManagement" && this.isMessageContainsAny(["is already recorded"])
	}

	get getRecordedId() {
		const msg = String(this.Message)

		if (msg.indexOf("is already recorded with id '") > -1) {
			return msg.slice(msg.indexOf("is already recorded with id '") + "is already recorded with id '".length, -1)
		}

		if (msg.indexOf("is already recorded '") > -1) {
			return msg.slice(msg.indexOf("is already recorded '") + "is already recorded '".length, -1)
		}
		return null
	}
}
