import { observable, makeObservable } from "mobx"
import { TraxisAbstract } from "store/api/TraxisAbstract"
import { TraxisModelAbstract } from "store/api/TraxisModelAbstract"
import { makeMapCollection, ModelAbstract } from "store/ModelTools"
import { moment } from "store/util/moment"

export class GetCustomerModelAlias extends ModelAbstract {
	Value = null
	authority = null
	type = null

	constructor() {
		super()
		makeObservable(this, {
			Value: observable,
			authority: observable,
			type: observable
		})
	}
}

export class AvailableCpeRegistrations extends ModelAbstract {
	Value = null
	cpeClassification = null

	constructor() {
		super()
		makeObservable(this, {
			Value: observable,
			cpeClassification: observable
		})
	}
}

class GetCustomerModel extends TraxisModelAbstract {
	id = null

	/**
	 * Whether the customer is marked barred (old)
	 * @type {bool}
	 */
	IsBarred = null

	/**
	 * The nPVR quota of this customer
	 * @type {int}
	 */
	NpvrQuota = null

	get NpvrQuotaInMinutes() {
		return Math.floor(this.NpvrQuota / 60)
	}

	/**
	 * The nPVR quota remaining of this customer
	 * @type {int}
	 */
	NpvrQuotaRemaining = null

	get NpvrQuotaRemainingInMinutes() {
		return Math.round(this.NpvrQuotaRemaining / 60)
	}

	/**
	 * Date of the customer opt-in. Set by adding a channel to the Recording Opt-in Channel List Absent when the customer did not yet opt-in to any channel.
	 * @type {DateTime}
	 */
	RecordingOptInDate = null

	/**
	 * Default guard time before the start that will be used as guard time for recordings.
	 * @type {DateTime}
	 */
	DefaultGuardTimeStart = null

	get DefaultGuardTimeStartMomentDuration() {
		return moment.duration(this.DefaultGuardTimeStart)
	}

	/**
	 * Default guard time after the end that will be used as guard time for recordings.
	 * @type {DateTime}
	 */
	DefaultGuardTimeEnd = null

	get DefaultGuardTimeEndMomentDuration() {
		return moment.duration(this.DefaultGuardTimeEnd)
	}

	/**
	 * Shows the available cpe registrations left to use.
	 * @type {int}
	 */
	_$AvailableCpeRegistration = null

	set AvailableCpeRegistrations(v) {
		this._$AvailableCpeRegistration = v.AvailableCpeRegistration[0]?.Value
	}

	get AvailableCpeRegistrations() {
		return this._$AvailableCpeRegistration
	}

	/**
	 * Shows the available cpe mutations left to use.
	 * @type {DateTime}
	 */
	AvailableCpeMutations = null

	_$Aliases = new Map()

	set Aliases(v) {
		makeMapCollection(
			this._$Aliases,
			v.Alias,
			i => i.type,
			() => new GetCustomerModelAlias()
		)
		return this._$Aliases
	}

	get Aliases() {
		return this._$Aliases
	}

	DefaultProfiles = null

	CustomerData = null

	IsRecommendedRecordingsEnabled = null

	/**
	 * Personal recommendations setting
	 * @type {bool}
	 */
	PersonalizationOptIn = null

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			id: observable,
			IsBarred: observable,
			NpvrQuota: observable,
			NpvrQuotaRemaining: observable,
			RecordingOptInDate: observable,
			DefaultGuardTimeStart: observable,
			DefaultGuardTimeEnd: observable,
			_$AvailableCpeRegistration: observable,
			AvailableCpeMutations: observable,
			_$Aliases: observable.shallow,
			DefaultProfiles: observable,
			CustomerData: observable,
			IsRecommendedRecordingsEnabled: observable,
			PersonalizationOptIn: observable
		})
	}
}

export class GetCustomer extends TraxisAbstract {
	_onStartCreate = true

	get _model() {
		return GetCustomerModel
	}

	_getParseResp(data) {
		return data.Customers.Customer[0]
	}

	getXmlReq() {
		return `<?xml version="1.0" encoding="utf-8"?>
<Request xmlns="urn:eventis:traxisweb:1.0">
	<Identity>
		<ProfileId>${this.profileId}</ProfileId>
	</Identity>
	<RootRelationQuery relationName="Customers">
		<Options>
			<Option type="Props">Aliases,IsBarred,NpvrQuota,NpvrQuotaRemaining,RecordingOptInDate,CustomerData,DefaultGuardTimeStart,DefaultGuardTimeEnd,AvailableCpeRegistrations,AvailableCpeMutations,DefaultProfiles,IsRecommendedRecordingsEnabled,PersonalizationOptIn</Option>
			<Option type="Limit">1</Option>
		</Options>
	</RootRelationQuery>
</Request>`
	}
}
