// l10n status: partial
import React, { forwardRef, useEffect, useRef, useState } from "react"
import { DateSelector } from "scenes/TvGuide/components/dateSelectors/selectors/DateSelector";
import { Dialog, InputBase, CircularProgress } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close'
import { rootStore } from "store/RootStore"
import IconButton from "@mui/material/IconButton"
import searchIcon from "img/icons/search.svg"
import { adjustUtcStartDay, moment } from "store/util/moment"
// import { CreateComponent } from "components/utils/CreateComponent"
import { observer } from "mobx-react"
import { logResourceLoadFailure } from "utils/BugsnagReporter"
import LiveIndicator from "img/icons/liveInSearch.svg"
import LinearProgress from "@mui/material/LinearProgress"
import Velocity from "velocity-animate"
import "velocity-animate/velocity.ui.min.js"
import useScrollListener from "./miniEpgUseScrollListener"
import { Img } from "components/Img"
import { useHistory, useLocation } from "react-router-dom";
import "./miniEpg.css"
import { l10n } from "store/lang/L10n"


//const styles = theme => ({})

// global variable
let isAutoScrolling = false
let savedPositionForList = null // Save the scroll position for a correct repositioning after the search
const scrollListProp = {
	listMiddlePosition: 0,
	titleHeight: 0,
	listStartTop: 0,
	offsetValue: 0,
}

let scrollValueCurrentEvent = null


let statusHiddenNowButton = {
	startSearchMode: false,
	setValueAfterSearch: false
}

const resetGlobalVariable = () => {
	for (let key in scrollListProp) {
		scrollListProp[key] = 0
	}
	savedPositionForList = null
	isAutoScrolling = false
	scrollValueCurrentEvent = null
	statusHiddenNowButton.startSearchMode = false
	statusHiddenNowButton.setValueAfterSearch = false
}

const NowButton = observer(({ page, handleGoNow }) => {
	useEffect(() => { }, [page.selectedDayTs]) // Needed to refresh and update the button when the observer changes
	return (
		// Visibility handled in css
		<div className={`miniEpgNowButton ${!page.hideNowButton ? 'miniEpgFade-in' : 'miniEpgFade-out'}`} onClick={handleGoNow}>
			<div className="miniEpgLabelFootRoundButton">{l10n.commonLabelNow.toUpperCase()}</div>
		</div>
	)
})

const HeadMiniEpg = observer(({ page, searchTxt, setSearchTxt, refList }) => {
	const channelSelected = page.getSelectedChannel
	const [size, setSize] = useState()
	const [showTitle, setShowTitle] = useState(true)

	//need to handle the resize for the DataSelector
	const handleResize = () => {
		setSize(document.getElementById("miniEpgDialog")?.offsetWidth)
	}

	useEffect(() => {
		if (!showTitle) {
			setTimeout(() => {
				window.scrollTo(0, 0)
			}, 500)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showTitle])

	useEffect(() => {
		window.addEventListener("resize", handleResize)
		return (() => {
			window.removeEventListener("resize", handleResize)
		})
	}, [])

	useEffect(() => {
		handleResize()
	}, [size])

	return (
		<div id="headMiniEpg" className={`miniEpgHeadMainContainer ${showTitle ? "miniEpgHeadMainContainerVisible" : "miniEpgHeadMainContainerHidden"} `}>
			<div className={`${showTitle ? "miniEpgComponentVisible" : "miniEpgComponentHidden"} miniEpgLogoDataContainer`}>
				<div className="miniEpgCloseLogo">
					<IconButton aria-label="Close"
						disableRipple={true}
						disableFocusRipple={true}
						className="miniEpgCloseButton"
						onClick={(e) => {
							e.stopPropagation()
							page.setShow(false)
						}}
						data-test="overlay_close">
					</IconButton>
				</div>
				<div className="miniEpgHeadLogo">
					{/* MTVW-831: "mode=box&w=144" -> "mode=box&w=480&h=270" */}
					{channelSelected.logoUrl && <Img src={channelSelected.logoUrl} params="mode=box&w=480&h=270" onError={e => {
						logResourceLoadFailure(channelSelected.logoUrl + "?mode=box&w=480&h=270", e)
					}} alt={channelSelected.Name} className="miniEpgImgLogo" />}
				</div>

				<DateSelector daysBefore={page.daysBefore} daysAfter={page.daysAfter} page={page} windowSize={size} />
			</div>
			<div className={`${page.listProgramForChannel?.length === 0 && !page.inSearch ?
				'miniEpgSearchContainerDisable'
				: 'miniEpgSearchContainerEnable'} miniEpgSearchContainer`}
			>
				<div className={`${showTitle ? "miniEpgSearchBackHidden" : "miniEpgSearchBackVisible"} miniEpgSearchBack`}>
					<IconButton aria-label="Back"
						disableRipple={true}
						disableFocusRipple={true}
						className="miniEpgSearchBackButton"
						data-test="miniEpg_search_back"
						onClick={(e) => {
							e.stopPropagation()
							setShowTitle(true)
							setSearchTxt("")
							page.setInSearchMode(false)
							statusHiddenNowButton.startSearchMode = false
							page.setHideNowButton(statusHiddenNowButton.setValueAfterSearch)
						}}
					>
					</IconButton>
				</div>

				<div className="miniEpgSearchBar">
					<div className="miniEpgIconSearch">
						<img src={searchIcon} alt="search" />
					</div>
					<InputBase
						id="miniEpgSearchInput"
						className="miniEpgSearchInput"
						placeholder={`Suchen in ${channelSelected.Name}`}
						autoComplete='off'
						data_test="search_input_box"
						value={searchTxt}
						onClick={(e) => {
							e.stopPropagation()
							setShowTitle(false)
							statusHiddenNowButton.startSearchMode = true
							statusHiddenNowButton.setValueAfterSearch = page.hideNowButton
							page.setHideNowButton(true)

						}}
						onKeyDown={(e) => {
							if (e.target.value === "") savedPositionForList = refList.current.scrollTop
						}}
						onChange={(e) => {
							e.stopPropagation()
							page.setInSearchMode(e.target.value !== "")
							setSearchTxt(e.target.value)
						}}
					/>
					{searchTxt !== "" && <div className="miniEpgResetSearch"
						onClick={(e) => {
							e.stopPropagation()
							page.setInSearchMode(false)
							setSearchTxt("")
						}}>
						<CloseIcon />
					</div>}
				</div>
			</div>
		</div>
	)
})


const MiniEpgDateRow = forwardRef((props, ref) => {
	const { row, index, page, dayInList, prevDayInList } = props
	const refNow = useRef(null)

	useEffect(() => {
		if (refNow?.current !== null) {
			scrollValueCurrentEvent = refNow.current.getBoundingClientRect().top
		}
		return (() => {
			scrollValueCurrentEvent = null
		})
		// MTVW-634: }, []) -> }), do at every update
	})

	return (
		<div key={`${index}-date-row`}>
			{prevDayInList !== dayInList && <div ref={ref} className="miniEpgDateRow">
				<div className="miniEpgHourContainer"></div>
				<div className="miniEpgTitleContainer">
					<h2 className="miniEpgDayTitleText">{dayInList}</h2>
				</div>
			</div>}
			<div className={`miniEpgListEventRow ${page.isSelectedChannel(page.getSelectedChannel.id) && row.isCurrentEvent ? "miniEpgListEventRowCurrent" : ""}`}
				data-test={`mini_epg_row_event ${row.AvailabilityStartDateFormat("DD-MM-yy")}`}
				key={`${index}-event`}
				ref={row.isCurrentEvent ? refNow : null}
				onClick={async (e) => {
					e.stopPropagation()
					await page.openEventDetails(row)
				}}>
				<div className="miniEpgHourContainer">
					<div className="miniEpgHourEvent" data-test="mini_epg_hour_event">{row.AvailabilityStartDateFormat("HH:mm")}</div>
					{row.isCurrentEvent ?
						<img src={LiveIndicator} className={"miniEpgLiveIndicator"} data_test="miniEpg_live_icon" alt="" />
						: null
					}
				</div>
				<div className="miniEpgTitleContainer">
					<div className="miniEpgTitleEvent miniEpgTextCut" data-test='mini_epg_title_event'>{row.Title.Name}</div>
					{row.Title.EpisodeName && <div className="miniEpgSubTitle miniEpgTextCut">{row.Title.EpisodeName}</div>}
					{row.isCurrentEvent ?
						<div className="miniEpgLinearProgress">
							<LinearProgress variant="determinate"
								value={row.CurrentDurationInPercents}
								classes={{
									bar: "miniEpgBarColorPrimary"
								}}
								data-test="miniEpg_event_progressBar" />
						</div>
						: null
					}
				</div>
			</div>
		</div>
	)
})

const MiniEpgProgramList = observer(({ closeModal, page, refList }) => {
	let prevDayInList = ""
	let dayInList = ""
	page.setRefDaysHead(useRef([]))
	const isFirstLoad = useRef(true)
	const savedNowPosition = useRef(null)

	useEffect(() => {
		page.loadMiniEpgProgramsForChannel()
		return (() => {
			resetGlobalVariable()
			page.refDaysHead.current = []
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {

		if (page.refDaysHead?.current?.length === 0) return
		//setTimeout(() => {
		if (!page.inSearch) {
			scrollListProp.listMiddlePosition = refList.current?.getBoundingClientRect().height / 2
			scrollListProp.titleHeight = page.refDaysHead?.current[0]?.getBoundingClientRect().height
			scrollListProp.offsetValue = page.refDaysHead?.current[0]?.offsetTop
		}
		//}, 0)
		if (!page._refreshTimeDone) {
			page.setSelectedDayTs(page.refreshVisibleTIme?.time?.dayTs)
			scrollListToSelectedDay()
		} else if (isFirstLoad.current) {
			scrollListToSelectedDay()
		}

		if (isFirstLoad.current) savedPositionForList = null
		// reset the list in the position that was before the search filter
		if (!page.inSearch && savedPositionForList !== null) {
			refList.current.scrollTop = savedPositionForList
		} else if (page.inSearch) {
			refList.current.scrollTop = 0
		}
		isFirstLoad.current = false

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page.refreshVisibleTIme, page.refDaysHead.current, page.startNewRequest])

	const easingOption = [0, 0.75, 0, 1]
	function velocityAnimation(elem, top, duration) {
		isAutoScrolling = true

		Velocity(elem, { scrollTop: top + "px" }, {
			duration: isFirstLoad.current ? 0 : duration,
			easing: easingOption,
			complete: function (val) {
				page.setVisibleTimeDone()
				setTimeout(() => {
					if (page._refreshTimeDone) {
						// The click on DateSelector causes the scroll. Only when scrolling is completed 'isAutoScrolling' is set to false
						isAutoScrolling = false
					}
				}, 100)
			}
		})
	}
	const scrollListToSelectedDay = () => {
		// MTVW-414
		//const toDay = moment().utc().startOf('day')
		//const selectedDate = moment(page.getSelectedDayTs).utc().startOf('day')
		const toDay = adjustUtcStartDay()
		const selectedDate = adjustUtcStartDay(page.getSelectedDayTs)
		const daysDiff = selectedDate.diff(toDay, 'days')
		scrollListProp.listStartTop = page.refDaysHead.current[0]?.getBoundingClientRect().top

		if (!page.refDaysHead.current[page.daysBefore + daysDiff]) return

		const newPosition = daysDiff === 0 ?
			scrollValueCurrentEvent - scrollListProp.listMiddlePosition
			:
			page.refDaysHead.current[page.daysBefore + daysDiff].getBoundingClientRect().top

		velocityAnimation(refList.current, newPosition - scrollListProp.listStartTop, 300)

		if (!page.inSearch) savedPositionForList = newPosition - scrollListProp.listStartTop

		if (daysDiff === 0) {
			page.setHideNowButton(true)
			savedNowPosition.current = savedPositionForList
		} else {
			page.setHideNowButton(false)
		}
	}

	const handleScrollList = (e) => {
		if (isAutoScrolling || (!page.isShow)) return // Return if the component is still scrolling or the modal is not visible
		e.stopPropagation()
		e.preventDefault()

		const scrollNow = refList.current.scrollTop

		// The last element of filteredArray must be the day displayed in the middle of the MiniEpgProgramList.
		// The last element of filteredArray is the day that must be selected.
		const filteredArray = page.refDaysHead.current.filter((element) => {
			return (
				// The commented code above can be added after  scrollListProp.offsetValue  to change the day in the middle of the title
				//+ scrollListProp.titleHeight / 2
				((element?.offsetTop - scrollListProp.offsetValue) <= (scrollNow + scrollListProp.listMiddlePosition))
			)
		})
		// MTVW-414
		//const newSelectedDayTs = moment().add(-page.daysBefore + (filteredArray?.length - 1), 'd').utc().startOf('day').valueOf()
		const newSelectedDayTs = adjustUtcStartDay(moment().add(-page.daysBefore + (filteredArray?.length - 1), 'd')).valueOf()

		if (page.selectedDayTs !== newSelectedDayTs && !page.inSearch) {
			// page.setVisibleTime(newSelectedDayTs) 
			// The scroll updates the selected day directly without using the queue, so 'setVisibleTime' is not called.
			// That avoids re-render of the component and prevents the jump of date during the scroll.
			page.setSelectedDayTs(newSelectedDayTs)
		}
		if (!page.inSearch) savedPositionForList = scrollNow

		// If the search is started the "Jetzt button" must be hidden.
		// When the search is finished the "Jetzt button" must be hidden if the current event is on the screen.
		if (!page.isCurrentDaySelected()) {

			page.setHideNowButton((statusHiddenNowButton.startSearchMode || false))
			statusHiddenNowButton.setValueAfterSearch = false
		} else {
			if ((refList.current.scrollTop > savedNowPosition.current + refList.current.getBoundingClientRect().height / 2) ||
				(refList.current.scrollTop < savedNowPosition.current - refList.current.getBoundingClientRect().height / 2)) {
				page.setHideNowButton(statusHiddenNowButton.startSearchMode || false)
				statusHiddenNowButton.setValueAfterSearch = false
			} else {
				page.setHideNowButton(true)
				statusHiddenNowButton.setValueAfterSearch = true
			}
		}
	}

	const handleGoNow = (e) => {
		e.preventDefault()
		page.setHideNowButton(true)
		page.setToDayAsSelectedDay()
	}
	// Custom hook handles the scroll event
	useScrollListener(refList, handleScrollList, 50);

	page.refDaysHead.current = []
	return (
		<div className="miniEpgListProgramMainContainer"
			ref={refList}
		>
			{page.startNewRequest &&
				<div className="miniEpgLoadingWrapper"
				// style={{height: `${refList.current?.getBoundingClientRect().height}px`}}
				>
					<CircularProgress size={100} data-test="miniEpg_list_loading" />
				</div>}
			{page?.listProgramForChannel?.map((row, index) => {
				prevDayInList = dayInList;
				dayInList = row.AvailabilityStartDateFormat("ddd DD MMMM")
				return <MiniEpgDateRow key={index} row={row} index={index} page={page} dayInList={dayInList} prevDayInList={prevDayInList}
					// Create an array of refs
					ref={element => {
						if (element) page.addElementToRef(element)
					}}
				/>
			})}
			<NowButton page={page} handleGoNow={handleGoNow} />
		</div>
	)
})

export const MiniEpg = observer(() => {
	const page = rootStore.page.MiniEpg
	const [searchTxt, setSearchTxt] = useState("")
	const refList = useRef()

	let history = useHistory();
	let location = useLocation();

	const closeModalForBack = () => {
		history.push(location.pathname)
		page.setShow(false)
	}

	useEffect(() => {
		if (page.isShow) window.addEventListener("popstate", closeModalForBack)
		return (() => {
			window.removeEventListener("popstate", closeModalForBack)
			setSearchTxt("")
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page.isShow])

	useEffect(() => {
		page.filterProgramList(searchTxt)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTxt])

	return (
		<Dialog
			id="miniEpgDialog"
			classes={{
				paper: "miniEpgDialog"
			}}
			open={page.isShow}
			onClose={page.closeModal}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		// disableBackdropClick="true"
		// disableEscapeKeyDown="true"
		>
			<HeadMiniEpg page={page} searchTxt={searchTxt} setSearchTxt={setSearchTxt} refList={refList} />
			<MiniEpgProgramList page={page} refList={refList} />
		</Dialog>
	)
})
