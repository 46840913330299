import { Paper } from "@mui/material"
import classnames from "classnames"
import React, { useEffect, useState } from "react"
import { CreateComponent } from "components/utils/CreateComponent"
//import { rootStore } from "store/RootStore"
import GridChannel, { GridChannelPreview } from "./GridChannel"
import { CleConfig } from "scenes/ChannelListEditor/ChannelListEditor"
import { usePreview } from 'react-dnd-preview';
import { Grid } from "@mui/material"
import { isTouchAvailable } from "utils/Utils"

const styles = theme => ({
	CleGridWrapper: { position: "relative" },
	CleGridWrapperScrollable: {
		overflowY: "scroll",
		scrollbarWidth: "none",
		marginTop: 0,
		"&::-webkit-scrollbar": { display: "none" },
		// TODO: remove?
		//paddingBottom: 64 - 15,
		paddingBottom: 3
	},
	//CleRightColumn: {},
})

//let previewFix = null
const CleGridWrapper = CreateComponent(styles, false, true, ({ page, classes, windowDimensions }) => {
	const channels = page.currentChannels
	//console.debug("Channels %o", Channels)

	const DndPreview = () => {
		const { display, itemType, item, style } = usePreview()
		if (!display || itemType !== CleConfig.gridItemType) {
			//previewFix = null
			return null;
		}
		//console.debug("DndPreview %o, %o, %o, %o", display, itemType, item, style)
		//return <div class="item-list__item" style={style}>{itemType}</div>;
		//const { item, index, classes, page, isDragging, } = props
		/*
		if (!previewFix) {
			const elem = document.getElementById(CleConfig.gridElemIdPrefix + item.index)
			const clRect = elem.getBoundingClientRect()
			//console.debug("info %o", clRect)
			//style.left = clRect.x - document.getElementById(CleConfig.gridElemIdPrefix + "0").getBoundingClientRect().x
			//style.top = clRect.y - document.getElementById(CleConfig.gridElemIdPrefix + "0").getBoundingClientRect().y
			previewFix = {
				left: clRect.x - document.getElementById(CleConfig.gridElemIdPrefix + "0").getBoundingClientRect().x,
				top: clRect.y - document.getElementById(CleConfig.gridElemIdPrefix + "0").getBoundingClientRect().y
			}
		}
		style.left = previewFix.left
		style.top = previewFix.top
		*/

		//const { item, index, classes, page, isDragging, }
		//console.debug("disp %o, type %o, item %o, style %o", display, itemType, item, style)
		return <div className="item-list__item" style={style}>
			{/*
			<GridChannelPreview  {...item} />
			*/}
			<GridChannelPreview item={item} classes={classes} page={page} isDragging={true} />
		</div>

		//return <GridChannelImage class="item-list__item" style={style}  {...item} />
	}

	//let itemsPerRow = useRef(null)
	const [itemsPerRow, setItemsPerRow] = useState(0)
	//const [xs, setXs] = useState("auto")

	const handleResize = () => {
		const elem = document.getElementById("gridListId")
		// consider CleConfig.gridCellWidth and margins
		const nbItems = Math.floor(elem?.getBoundingClientRect().width / (CleConfig.gridCellWidth + 6 + 2))
		setItemsPerRow(nbItems)
		/* trial to justify left in the last row
		let cXs = elem?.getBoundingClientRect().width / 12
		console.debug("xs %s %s row %s", cXs, 0.5 + ((CleConfig.gridCellWidth + 6 + 2) / cXs), nbItems)
		switch (nbItems) {
			case 5:
			case 4:
				cXs = 3
				break
			default:
				cXs = 2
		}
		setXs(cXs)
		*/
	}

	useEffect(
		...page.getMountBody(
			() => {
				window.addEventListener("resize", handleResize)
				handleResize()
			},
			() => {
				window.removeEventListener("resize", handleResize)
			}
		)
	)

	// With the responsive implementation of MTV-2511, the items of the last column in the channel grid might be misaligned if not all items in the last column are populated. The Material-UI Grid component does not behave like it should when using  "flex-start". Add invisible items in this case.
	const gridUiFixup = () => {
		let chToFill = []
		if (channels?.length % itemsPerRow !== 0) {
			//console.debug("items %s, length %s, per row %s,", itemsPerRow - channels.length % itemsPerRow, channels.length, itemsPerRow)
			for (let i = 0; i < itemsPerRow - channels.length % itemsPerRow; i++) { chToFill.push(channels[0]) }
			return (<> {chToFill.map((item, ix) => (
				<Grid item xs key={"_g1_" + ix} /* spacing={2}  xs ={xs} */ /*sm md lg xl*/>
					<GridChannel key={item.id} classes={classes} page={page} item={Object.assign({ listId: CleConfig.gridItemId, index: ix, hoverIndex: ix }, item)} hidden={true} />
				</Grid>
			))} </>)
		}
	}

	return (
		<>
			<Paper className={classnames(classes.CleRightColumn, classes.CleGridWrapper)}>
				<div id="gridListId"
					className={classes.CleGridWrapperScrollable}
					style={{ height: windowDimensions }}
					//ref={el => page.setRefElemScroll(el)}
					//onScroll={() => page.setVerticalScroll()}
					data-test="cle_grid"
				>
					<Grid sx={{ flexGrow: 1 }} container spacing={12} alignItems="flex-start" alignContent="flex-start" justifyContent="flex-start">
						{page.isReady && (
							<>
								{channels?.map((item, ix) => (
									<Grid item xs key={"_g2_" + item.id} /*sm md lg xl*/>
										{/*console.debug("case 1 %s %s %s", ix, itemsPerRow, Math.floor(channels.length / itemsPerRow) * itemsPerRow)*/}
										<GridChannel key={item.id} page={page} item={Object.assign({ listId: CleConfig.gridItemId, index: ix, hoverIndex: ix }, item)} hidden={false} />
									</Grid>
								))}
								{gridUiFixup()}
							</>
						)}
					</Grid>
				</div>
			</Paper>
			{isTouchAvailable() && (
				<DndPreview />
			)}
		</>
	)
})

export const ChannelGrid = CreateComponent(styles, false, false, ({ page, classes, windowDimensions }) => {
	return <CleGridWrapper page={page} classes={classes} windowDimensions={windowDimensions} />
})
