import { types as t } from "mobx-state-tree"
import { TraxisAbstract } from "store/api/TraxisAbstract"

const UpdateCustomerPropertyModel = t.model({})

export class UpdateCustomerProperty extends TraxisAbstract {
	//_onStartCreate = true
	//_onStartRemove = true

	get _model() {
		return UpdateCustomerPropertyModel
	}

	_getParseResp(data) {
		return data
	}

	//_getHashReq(data) {
	//	return ``
	//}

	getXmlReq() {
		return `<?xml version="1.0" encoding="utf-8"?>
<Request xmlns="urn:eventis:traxisweb:1.0">
	<Identity>
		<CustomerId>${this._oVars.ingestId}</CustomerId>
	</Identity>
	<ActionQuery resourceType="Customer" resourceId="${this._oVars.ingestId}" actionName="Update" aliasType="IngestId">
		<Arguments>
			<Argument name="${this._oVars.property}">${this._oVars.newValue}</Argument>
		</Arguments>
	</ActionQuery>
</Request>

`
	}
}
