import { types as t } from "mobx-state-tree"
import { observe, observable, action, makeObservable } from "mobx"
import { ClassAbstract } from "store/ClassTools"

export const ObserveMixin = t
	.model("ObserveMixin", {})
	.volatile(self => ({
		_tObserve: []
	}))
	.actions(self => ({
		_setObserveStopAll() {
			self._tObserve.forEach(i => i())
			self._tObserve = []
			return self
		},
		_addObserve(...args) {
			self._tObserve.push(observe(...args))
			return self
		}
	}))

export class Observe extends ClassAbstract {
	_tObserve = new Set()

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			_tObserve: observable.shallow,
			run: action
		})
	}

	clear() {
		this._tObserve.forEach(i => i())
		this._tObserve.clear()
		return this
	}

	add(name, target, property, callback, invokeImmediately = null) {
		if (property === null) {
			this._tObserve.add(observe(target, this.run.bind(this, name, callback), invokeImmediately))
		} else {
			this._tObserve.add(observe(target, property, this.run.bind(this, name, callback), invokeImmediately))
		}
		return this
	}

	run(name, callback, ...args) {
		console.log("@(name=%o,callback,...args=%o)", name, args)
		return callback.bind(this._parent, ...args)()
	}
}
