import { browserName, browserVersion, browserOs } from "utils/Utils"
import { rootStore } from "store/RootStore"
import { serviceUrls } from "store/qlapi/ServiceUrls"
import { flow } from "mobx"
import { appFeatures } from "AppFeatures"
import { checkMSE, isFairPlaySupported, isWidevineSupported } from "./Utils"

export const appVersion = () => {
	return getStage(true) === "INTERNAL" ? import.meta.env.VITE_DEV_VERSION : import.meta.env.VITE_VERSION
}

export var appStats = {
	startTime: Date.now(),
	gt12Enabled: false,
	ads: "",
	bookmarks: "",
	channels: "",
	epg: "",
	log: "",
	login: "",
	media: "",
	onboarding: "",
	search: "",
	player: "",
	vSworker: "",
	browser: "",
	mse: "",
	fairplaySupport: false,
	widevineSupport: false,
	screenWidth: 0,
	screenHeight: 0,
	dpr: 0,
	traxisObjects: 0,
	reqSent: 0,
	reqReceived: 0,
	reqRetries: 0,
	reqErrors: 0,
	reqCacheFetches: 0,
	reqCacheReturns: 0,
	initialDbSize: 0,
	initialDbKeys: 0,
	cacheClearTime: Date.now(),
	heapSizeLimit: 0,
	heapSizeTotal: 0,
	heapSizeUsed: 0,
	backToOnline: 0,
	playerWatchdog: 0,
	restartAfter403: 0,
	swRewrites: 0,
	logEnabled: false
}
export const initAppStats = () => {
	appStats.gt12Enabled = appFeatures.gt12Enabled
	//appStats.player = rootStore?.page?.Player?.player?.version  // DONE LATER!

	appStats.browser = browserName() + " " + browserVersion() + " -  " + browserOs()
	// eslint-disable-next-line no-restricted-globals
	appStats.screenWidth = screen.width
	// eslint-disable-next-line no-restricted-globals
	appStats.screenHeight = screen.height
	appStats.dpr = window.devicePixelRatio
}

export function updateMemoryStats() {
	if (window.performance?.memory) {
		appStats.heapSizeLimit = window.performance.memory.jsHeapSizeLimit
		appStats.heapSizeTotal = window.performance.memory.totalJSHeapSize
		appStats.heapSizeUsed = window.performance.memory.usedJSHeapSize
	}
	if (appStats.logEnabled) {
		console.debug(
			"UpTime %s ms, browser %s, player %s, media %s, epg %s, login %s, screen width %s, screen height %s, traxis objects %s, sent %s, recvd %s, retries %s, errors %s, cache returns %s, cache fetches %s, initial db size %s, initial db keys %s, heap limit %s, heap total %s, heap used %s, back to online, %s player wd %s, restarts 403 %, sw rewrites %s, sw worker %s",
			Date.now() - appStats.startTime,
			appStats.gt12Enabled,
			appStats.player,
			appStats.ads,
			appStats.bookmarks,
			appStats.channels,
			appStats.epg,
			appStats.log,
			appStats.login,
			appStats.media,
			appStats.onboarding,
			appStats.search,
			appStats.browser,
			appStats.mse,
			appStats.fairplaySupport,
			appStats.widevineSupport,
			appStats.screenWidth,
			appStats.screenHeight,
			appStats.dpr,
			appStats.traxisObjects,
			appStats.reqSent,
			appStats.reqReceived,
			appStats.reqRetries,
			appStats.reqErrors,
			appStats.reqCacheReturns,
			appStats.reqCacheFetches,
			appStats.initialDbSize,
			appStats.initialDbKeys,
			appStats.heapSizeLimit,
			appStats.heapSizeTotal,
			appStats.heapSizeUsed,
			appStats.backToOnline,
			appStats.playerWatchdog,
			appStats.restartAfter403,
			appStats.swRewrites,
			appStats.vSworker
		)
	}
}

export function addToWrapper(name, item) {
	window.tcSupport[name] = item
}


export function getStage(logReleaseTrack = false) {
	// log release track: ['INTERNAL', "ALPHA", "BETA", "PRODUCTION"]
	const url = window.location.href
	let stage = logReleaseTrack ? "INTERNAL" : "development"
	if (url.toLowerCase().indexOf("quicklineweb") > -1 || url.toLowerCase().indexOf("webtv") > -1) {
		stage = logReleaseTrack ? "PRODUCTION" : "production"
	}
	else if (url.toLowerCase().indexOf("beta") > -1) {
		stage = logReleaseTrack ? "BETA" : "beta"
	}
	else if (url.toLowerCase().indexOf("alpha") > -1) {
		stage = logReleaseTrack ? "ALPHA" : "alpha"
	}
	else if (url.toLowerCase().indexOf("localhost") > -1 || url.toLowerCase().indexOf("127.0.0.1") > -1) {
		stage = logReleaseTrack ? "INTERNAL" : "localhost"
	}
	return stage
}

export const ISDEV = getStage() === "development" || getStage() === "localhost"
export function cDebug() {
	if (ISDEV)
		console.debug(...arguments)
}

// Safari DOESN'T support BroadcastChannel
/*
const msgChannel = new BroadcastChannel('sw-notification')
msgChannel.onmessage = (message) => { 
	console.debug("message from sw %s %s", message.data.version, message.data.msg)
	appStats.vSworker = message.data.version
	if (message.data.msg === "m3u8") appStats.swRewrites++
}
*/

// apply MS versions to appStats
export const msVersions = flow(function* () {
	try {
		appStats.media = yield rootStore?.mediaService?.version()
		appStats.epg = yield rootStore?.epgService?.version()
		appStats.login = yield rootStore?.loginService?.version()
		if (serviceUrls.isAdsMsAvailable) {
			appStats.ads = yield rootStore?.adsService?.version()
		}
		/*
		  if (serviceUrls.isBookmarksMsAvailable) {
			  try {
				  appStats.bookmarks = yield rootStore?.bookmarksService?.version()
			  }
			  catch (e) {
				  console.error("msVersions")
				  serviceUrls.disableBookmarks()
			  }
		  }
		*/
		appStats.channels = yield rootStore?.channelsService?.version()
		appStats.log = yield rootStore?.logService?.version()
		appStats.onboarding = yield rootStore?.onboardingService?.version()
		appStats.search = yield rootStore?.searchService?.version()

		const [mse, formatted] = checkMSE()
		appStats.mse = formatted
		appStats.fairplaySupport = yield isFairPlaySupported()
		appStats.widevineSupport = yield isWidevineSupported()
	}
	catch (e) { console.error("CAUGHT in msVersions", e) }
})

const msgChannel = new MessageChannel()
// Initialize the channel by sending the port to the Service Worker (this also transfers the ownership of the port)
// service worker needs time until started
const timerId = setInterval(checkServiceWorker, 100)
function checkServiceWorker() {
	if (navigator.serviceWorker?.controller) {
		//console.debug("service worker ready")
		navigator.serviceWorker.controller.postMessage({
			type: 'INIT_PORT',
		}, [msgChannel.port2])
		clearInterval(timerId)
	}
}

export function setQlWorkerHeaders(headers) {
	if (navigator.serviceWorker?.controller) {
		//console.debug("service worker ready")
		navigator.serviceWorker.controller.postMessage({
			type: 'QL_HEADERS',
			headers: headers
		})
		//clearInterval(timerId)
	}
}

// Receive messages from service worker
msgChannel.port1.onmessage = (message) => {
	//console.debug("message from sw %s, %s, %o", message.data.version, message.data.msg, message)
	if (message.data.version) appStats.vSworker = message.data.version
	if (message.data.msg === "m3u8") appStats.swRewrites++
	if (message.data.msg === "dateTimeString") {
		window.postMessage({ dateTime: message.data.value })
	}
}

/*
// Further messages to the sw can be sent like this
navigator.serviceWorker.controller.postMessage({
	type: 'INCREASE_COUNT',
})
*/

// make values accessible to test cases and debug console
// @ts-ignore
window.tcSupport = { appStats: appStats }

const SWatchEnabled = false
export class StopWatch {
	startTime = performance.now()
	lapTime = performance.now()

	start(message) {
		if (!SWatchEnabled) return
		const now = performance.now()
		console.debug("SWatch START %s: diff %s ms", message, now - this.startTime)
		this.startTime = now
		this.lapTime = this.startTime
	}

	stop(message) {
		if (!SWatchEnabled) return
		const timeDiff = performance.now() - this.startTime
		console.debug("SWatch STOP %s: %s ms", message, timeDiff)
	}

	lap(message) {
		if (!SWatchEnabled) return
		const now = performance.now()
		console.debug("SWatch LAP %s: %s ms, diff %s ms", message, now - this.startTime, now - this.lapTime)
		this.lapTime = now
	}
}

export const stopWatch = new StopWatch()
