// l10n status: done
import React from "react";
import "./ffAdAnimatedIcon.css"
import ffAd_RedCircle from "img/player/ffAd_RedCircle.svg"
import ffAd_icon from "img/player/ffAd_icon.svg"
import { l10n } from "store/lang/L10n"
import { observer } from "mobx-react"

// MTVW-733: Added adStyleInfo

export const FFAdAnimatedIcon = observer(({ page, adStyleValidCounter, adStyleInfo, source, isControlsVisible, isFullScreen }) => {
	//const adStyleInfo = player?.__parent?.adStyleInfo
	//console.debug("FFAdAnimatedIcon %o, %o", player?.__parent?.adStyleInfo?.videoTop + 22, player?.__parent)

	// MTVW-727: don't show if in PiP
	if (page.player.isInPip) return null

	return (
		// MTVW-453
		// <div className="fastForwardContainer" onClick={(e) => {
		<div id="fastForwardIcon"
			style={isFullScreen && adStyleValidCounter > 0 ? { right: adStyleInfo?.videoRight + 14, bottom: isControlsVisible ? adStyleInfo?.videoBottomOffset + 96 : adStyleInfo?.videoBottomOffset + 22 } : {}}
			className={`fastForwardContainer ${isControlsVisible ? isFullScreen ? "fastForwardContainerControlsOnFullscreen" : "fastForwardContainerControlsOn" : ""}`}
			onClick={(e) => {
				e.stopPropagation()
				source.skipAd()
			}}
			onTouchEnd={(e) => {
				e.stopPropagation()
				e.preventDefault()
				source.skipAd()
			}}
		>
			<div className="fastForwardText">
				{l10n.featurePlayerShortAds}
			</div>
			<div className="fastForwardRedCircle">
				<img src={ffAd_RedCircle} alt="" className={"ffAd_RedCircle"}></img>
				<div className="fastForwardWhiteIcon">
					<img src={ffAd_icon} alt="" className={"ffAd_WhiteIcon"}></img>
				</div>
			</div>
		</div>
	)
})

export const LinearSkipAd = observer(({ page, adStyleValidCounter, adStyleInfo, source, isControlsVisible, isFullScreen }) => {
	//const adStyleInfo = player?.__parent?.adStyleInfo

	// MTVW-727: don't show if in PiP
	if (page.player.isInPip) return null

	return (
		// MTVW-453
		// <div className="fastForwardContainer" onClick={(e) => {
		<div id="linearSkip" style={isFullScreen && adStyleValidCounter > 0 ? { right: adStyleInfo?.videoRight + 10, bottom: isControlsVisible ? adStyleInfo?.videoTop + 96 : adStyleInfo?.videoTop + 22 } : {}}
			className={`linearSkipContainer ${isControlsVisible ? isFullScreen ? "fastForwardContainerControlsOnFullscreen" : "fastForwardContainerControlsOn" : ""}`}
			onClick={(e) => {
				e.stopPropagation()
				source.skipAd()
			}}
			onTouchEnd={(e) => {
				e.stopPropagation()
				e.preventDefault()
				source.skipAd()
			}}
		>
			<div className="linearSkipText">
				{l10n.featurePlayerSkipAds}
			</div>
			<div className="fastForwardRedCircle">
				<img src={ffAd_RedCircle} alt="" className={"ffAd_RedCircle"}></img>
				<div className="fastForwardWhiteIcon">
					<img src={ffAd_icon} alt="" className={"ffAd_WhiteIcon"}></img>
				</div>
			</div>
		</div>
	)
})
