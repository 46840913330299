import { observable, toJS, makeObservable } from "mobx";
import { TraxisAbstract } from "store/api/TraxisAbstract"
import { TraxisModelAbstract } from "store/api/TraxisModelAbstract"

class GetProfileModel extends TraxisModelAbstract {
	id = null

	/**
	 * Opt-in for personal recommendatons
	 * @type {bool}
	 */
	PersonalizationOptIn = null

	/**
	 * Viewing periods
	 * @type {string}
	 */
	ViewingPeriods = null

	/**
	 * Named properties
	 *  @type {NamedProperties}
	 */
	NamedProperties = null

	/**
	 * Pin
	 * @type {string}
	 */
	Pin = null

	/**
	 * Name
	 * @type {string}
	 */
	Name = null

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			id: observable,
			PersonalizationOptIn: observable,
			ViewingPeriods: observable,
			NamedProperties: observable,
			Pin: observable,
			Name: observable
		})
	}
}
export class GetProfile extends TraxisAbstract {
	//_onStartCreate = true
	//_onStartRemove = true

	get _model() {
		return GetProfileModel
	}

	_getParseResp(data) {
		// fix issue with NamedProperties
		const jsObject = toJS(data.Profile.NamedProperties)
		const reduced = jsObject.Property.reduce((result, { name, Value }) => {
			result[name] = { Value }
			return result
		}, {})
		data.Profile.NamedProperties = reduced
		return data.Profile
	}

	_getHashReq(data) {
		return `GetProfile_${this.profileId}`
	}

	getXmlReq() {
		return `<?xml version="1.0" encoding="utf-8"?>
<Request xmlns="urn:eventis:traxisweb:1.0">
	<Identity>
		<ProfileId>${this.profileId}</ProfileId>
	</Identity>
	<ResourceQuery resourceType="Profile" resourceId="${this.profileId}">
		<Options>
			<Option type="Props">PersonalizationOptIn,ViewingPeriods,AudioLanguagePreferences,SubtitleLanguagePreferences,NamedProperties,Name, Pin</Option>
		</Options>
	</ResourceQuery>
</Request>`
	}
}
