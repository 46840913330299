/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-08
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-11
 */

import { action, flow, computed, makeObservable } from "mobx"
//import { moment } from "store/util/moment"
//import { TIME_FORMAT_BACKEND } from "store/model/Time"
//import { SourceAbstract } from "store/page/player/source/SourceAbstract"
//import { SourceTypeList } from "store/page/player/source/SourceTypeList"
//import { SourceChannelReplay } from "store/page/player/source/SourceChannelReplay"
import { SourceTypeList, SourceAbstract } from './InternalImports'

export class SourceAdPlaylist extends SourceAbstract {
	type = SourceTypeList.AdPlaylist

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			getClone: action,
			setPlayPause: action,
			setJumpLive: action,
			setJumpRwd: action,
			setJumpFwd: action,
			setReplay: action,
			isButtonReplayEnabled: computed,
			isButtonJumpFwdEnabled: computed,
			isButtonJumpRwdEnabled: computed
		})
		//console.debug("constructor SourceAdPlaylist %o, %o", parent.player, path)
		this._player = parent.player
	}

	/**
	 * @returns {SourceAdPlaylist}
	 */
	static create(parent, url) {
		const source = new SourceAdPlaylist(parent, "source")
		source.playlist.StreamInfo = {
			event_type: "ad",
			//event_type: "recording",
			//"stream_url": "https://drmstreamer3.cdn.quickline.ch:443/bpk-token/kqd0smek5aaaaaaaasgctydaaaaaaaaaaaaidbzgekoke2eg/0001931/GlobalManifest.m3u8",
			stream_url: url,
			//"keep_alive_url": "https://drmstreamer3.cdn.quickline.ch:443/bpk-token/kqd0smek5aaaaaaaasgctydaaaaaaaaaaaaidbzgekoke2eg/0001931/keepalive",
			keep_alive_interval: "PT20S"
		}
		/*
		source.Channel = channelId
		source.id = channelId
		source.GetEvent = oEventFull
		*/
		return source
	}

	/**
	 * @returns {SourceAdPlaylist}
	 */
	getClone() {
		const source = new SourceAdPlaylist(this._parent, "source")
		/*
		source.Channel = this.Channel.id
		source.id = this.id
		source.GetEvent = this.GetEvent
		*/
		source.isLoading = false
		return source
	}

	get isReplayAvailable() {
		return false
	}

	isSourceEqual(source) {
		return this.type === source.type && this.id === String(source.id)
	}

	// buttons disable state
	get isButtonLiveEnabled() {
		return false
	}

	get isButtonReplayEnabled() {
		return false
	}

	get isButtonJumpFwdEnabled() {
		return false
	}

	get isButtonJumpRwdEnabled() {
		return false
	}

	get isButtonPlayPauseEnabled() {
		return this.player.isStreamLoaded && this.isReplayAvailable
	}

	get isButtonRecEnabled() {
		return false
	}

	openStreamAsync = flow(function* () {
		console.info("@@openStreamAsync() -> ")
		yield this._openStreamLiveAsync()
		//yield this._openStreamRollingBufferAsync("Wed, 02 Oct 2019 07:30:00 +0200")
		console.info("@@openStreamAsync() <- ")
	})

	setPlayPause() {
		//if (!this.debounced()) return
		/*
		console.debug("setPlayPause SourceAdPlaylist")
		if (!this.player.isPaused) {
			this.player.setPause()
		} else {
			this.player.setPlay("SourceAdPlaylist.setPlayPause")
		}
		return this
		*/
	}

	setJumpLive() {
	}

	setJumpRwd() {
	}

	/* UNSED
	_handlePlayChannelReplayAsync = flow(function* () {
		console.info("@@openStreamAsync() -> ")
		yield this._openStreamLiveAsync()
		//yield this._openStreamRollingBufferAsync("Wed, 02 Oct 2019 07:30:00 +0200")
		console.info("@@openStreamAsync() <- ")
	})
	*/

	handleSetPosition = flow(function* (positionMoment) {
	})

	setJumpFwd() {
	}

	// MTVW-287
	//setReplay() {
	setReplay = flow(function* () {
	})

	timeStampEvent(timeStamp, offsetSeconds, playCompleted) {
		/*
		// adjust adPlayStart
		let diff = timeStamp - this.adPlayStart
		if (diff <= 0) {
			console.debug("DIFF ADJUST %s", diff)
			this.adPlayStart += diff
			diff = 0
		}
		// diff corresponds to const offsetSeconds in PlayerVo
		console.debug("DIFF %s, OFFS %s", diff, offsetSeconds)
		*/
		// offsetSeconds needs to be rounded for displaying count down time in replay ad
		if (this.adPlayStart > 0) this._parent.adStampEvent(timeStamp, offsetSeconds, playCompleted)
	}
}
