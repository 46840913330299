import { TextField } from "@mui/material"
import React, { useEffect, useRef } from "react"
import "./PinEntryTextFields.css"


// storedPin is the user's current pin
// setNewPin: callback for updating the new pin used in Settings.js (in handleSetNewPin called from Speichern button)
// setIsValidPin: callback used in settings.js for indicating whether the full pin is entered or not (used in the UI to enable or disable the Speichern button)
// hidePin: hides the pin  after the user has typed it.
// showPinInStepper: the pin should be visible in stepper for firstInstall and create profile
export const PinEntryTextFields = (props) => {
	const { storedPin, setNewPin, setIsValidPin, hidePin, showPinInStepper } = props
	const pinRef = useRef()

	let backspacePressed = false
	let deletePreviousOne = false
	// arrayNewPin is used to check the insert of a new pin in validateEdit
	let arrayNewPin = []

	// change the string in an array 
	const arrayCurrentPin = storedPin === "" ? Array(4).fill(undefined) : [...storedPin]

	useEffect(() => {
		// In firstInstall and CreateProfile the pin value should be displayed.
		// the following cycle loads the data if returning to the pin step
		if (showPinInStepper) {
			// The 2 lines below are necessary for the management of setIsValidPin in validateEdit in case of pin change.
			// need to be updated each render (when moving from step)
			// eslint-disable-next-line react-hooks/exhaustive-deps
			arrayNewPin = arrayCurrentPin

			// in the passage between step 0 and step 1
			// a copy of the component is created (possible bug of the stepper)
			// it is necessary to use the pinRef in order to reload the values in the correct component
			const myElements = pinRef.current.getElementsByClassName("pinField")
			if (myElements) {
				arrayCurrentPin.map((item, index) =>
					myElements[index].firstElementChild.firstElementChild.value = item === undefined ? "" : item
				)
			}
			// to disable the button next in firstinstall when load first time 
			if ((arrayCurrentPin?.length === 4) && (!arrayCurrentPin.includes(undefined))) {
				setIsValidPin(true)
			} else {
				setIsValidPin(false)
			}
		} else {
			//to disable the button next in setting
			setIsValidPin(false)
		}

		// this works always  when moving between steps in firstInstall
		handleFocusInPinFields()
	}, [])

	const handleFocus = (event, index) => {
		if (deletePreviousOne && backspacePressed) {
			event.target.value = ""
			arrayNewPin[index] = undefined
		}
		// necessary to always put the cursor at the end of the entered string 
		// for a better user experience when deleting
		setTimeout(() => {
			event.target.select()
		}, 0)
	}

	const handleInsertValuesInPin = (event, index) => {
		backspacePressed = false
		deletePreviousOne = false

		const previusCell = index !== 0 ? document.getElementById(`pinField${index - 1}`) : document.getElementById(`pinField${index}`)

		switch (event.key) {
			case "Backspace":
			case "Delete":
				backspacePressed = true
				if (event.target.value === "") {
					// If the current cell has a value: delete the value and remain in focus.
					// If the current cell has no value: move the focus to the previous cell and delete the value.
					// Check also handleFocus
					deletePreviousOne = true
					setTimeout(() => {
						previusCell.focus()
					}, 10) //  can be 0 but setTimeout is necessary to delay the event
				}
				break
			default:
		}
	}

	const validateEdit = (e, index) => {
		let value = ""
		if (backspacePressed) {
			deletePreviousOne = false
			backspacePressed = false
		}

		value = e.target.value.replace(/[^0-9]/g, "")
		if (value !== "") {
			// only one digit
			value = parseInt(value.slice(0, 1))
		}

		e.target.value = value
		if (value !== "") {
			arrayNewPin[index] = e.target.value

			// apply a dot to hide the digit in setting but not in first install
			if (hidePin) {
				setTimeout(() => {
					if (e.target.value !== "") {
						e.target.value = '\u25CF'
					}
				}, 500)
			}

			if (index < 3) {
				let id = "pinField" + (index + 1)
				document.getElementById(id).focus()
			}
		} else {
			arrayNewPin[index] = undefined
			e.target.value = ""
		}

		if ((arrayNewPin?.length === 4) && (!arrayNewPin.includes(undefined))) {
			setNewPin(arrayNewPin.join(''))
			setIsValidPin(true)
		} else {
			setIsValidPin(false)
		}
	}

	const handleFocusInPinFields = () => {
		// necessary to use pinRef (see comment in useEffect)
		const myElements = pinRef.current.getElementsByClassName("pinField")

		if (myElements) {
			for (let index = 0; index <= 3; index++) {
				const cell = myElements[index].firstElementChild.firstElementChild
				if (cell) {
					if (cell?.value === "") {
						cell.focus()
						break
					}
					if (index === 3) {
						cell.focus()
					}
				}
			}
		}
	}

	return (
		<div className="inputField">
			<div className="pinFields" ref={pinRef}
				onClick={(e) => {
					handleFocusInPinFields()
					// click in the section sets the focus in the first empty cell or in the last one
					e.stopPropagation()
				}}
			>
				{
					arrayCurrentPin.map((item, index) => {
						return (
							// the click is disabled in css
							<TextField
								index={index}
								key={index}
								id={`pinField${index}`}
								type="decimal"
								inputProps={{ inputMode: 'numeric', tabIndex: index === 0 ? "0" : "-1" }}
								variant="outlined"
								onChange={(e) => {
									e.stopPropagation()
									validateEdit(e, index)
								}}
								onKeyDown={(e) => {
									// MTVW-627: allow propagation for handling of Continue button
									//e.stopPropagation()
									handleInsertValuesInPin(e, index)
								}}
								onFocus={(e) => {
									e.stopPropagation()
									e.preventDefault()
									handleFocus(e, index)
								}}
								min={0}
								className="pinField"
							/>
						)
					})
				}
			</div>
		</div>
	)
}
