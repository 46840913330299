import { types as t } from "mobx-state-tree"
import { TraxisAbstract } from "store/api/TraxisAbstract"

const UpdateChannelListModel = t.model({})

export class UpdateChannelList extends TraxisAbstract {
	get _model() {
		return UpdateChannelListModel
	}

	_getParseResp(data) {
		return data
	}

	//_getHashReq(data) {
	//	return ``
	//}

	_getXmlPartChannelList() {
		//console.debug("channelList %o length %s", this._oVars?.channelList, this._oVars?.channelList?.length)
		if (Array.isArray(this._oVars?.channelList) && this._oVars?.channelList?.length > 0) {
			return `
				${this._oVars.channelList.map((ch, o) => `<Channel ordinal="${o}">${ch}</Channel>\n`).join("")}`
		}
		else return ``
	}

	getXmlReq() {
		return `<?xml version="1.0" encoding="utf-8"?>
				<Request xmlns="urn:eventis:traxisweb:1.0">
					<Identity>
						<ProfileId>${this.profileId}</ProfileId>
					</Identity>
					<ActionQuery resourceType="ChannelList" resourceId="${this._oVars.channelListId}" actionName="Update">
						<Arguments>
							<Argument name="Name">${this._oVars.channelListName}</Argument>
							<Argument name="Ordinal">${this._oVars.ordinal}</Argument>
							<Argument name="Channels">
								${this._getXmlPartChannelList()}
							</Argument>
						</Arguments>
					</ActionQuery>
				</Request>`
	}
}
//${this._getXmlPartChannelList()}

/*
<Channel ordinal="1">2021</Channel>
<Channel ordinal="2">2023</Channel>
*/
