/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-09
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-11
 */

import { runInAction, action, makeObservable } from "mobx";

export class ModelAbstract {
	constructor() {
		makeObservable(this, {
			setData: action
		})
	}

	setData(v) {
		console.log("@(v)", v)
		//console.debug("setData")
		applyDataToObject(this, v)
		return this
	}

	//getNewObject() {
	//	return new ModelAbstract()
	//}
}

export const mixinClasses = (target, ...sources) => {
	target = target.prototype

	sources.forEach(i => {
		i = i.prototype
		Object.getOwnPropertyNames(i).forEach(function (name) {
			if (name !== "constructor") Object.defineProperty(target, name, Object.getOwnPropertyDescriptor(i, name))
		})
	})
}

// MTV-3583: mobx 6 removed 'name' in 'runInAction' parameters
export const applyDataToObject = (store, newVal, emptySelf = null) => {
	runInAction(() => {
		if (emptySelf !== null) {
			Object.assign(store, new emptySelf(), newVal)
		} else if (store.getNewObject !== void 0) {
			//Object.assign(this, { ...this.getNewObject(), ...v })
			Object.assign(store, store.getNewObject(), newVal)
		} else {
			Object.assign(store, newVal)
		}
	})
}

export const makeObject = (store, valName, newVal, childModel) => {
	runInAction(() => {
		if (newVal === null) {
			store[valName] = null
		} else if (store[valName]) {
			Object.assign(store[valName], newVal)
		} else {
			store[valName] = Object.assign(new childModel(), newVal)
		}
		if (store[valName]._init) {
			store[valName]._init()
		}
	})
	return store[valName]
}

export const makeMapCollection = (storeVal, newVal, keyCallback, childModelCreator) => {
	runInAction(() => {
		const storeSize = storeVal.size
		const oPreserveKeys = storeSize ? new Set() : void 0

		const emptyModel = childModelCreator()
		newVal.forEach((v, i) => {
			const key = keyCallback(v, i)
			let val
			if (storeSize && storeVal.has(key)) {
				Object.assign((val = storeVal.get(key)), { ...emptyModel, ...v })
			} else {
				storeVal.set(key, (val = Object.assign(childModelCreator(v), v)))
			}
			if (val._init) {
				val._init()
			}
			if (storeSize !== 0) {
				oPreserveKeys.add(key)
			}
		})
		if (storeSize !== 0) {
			if (oPreserveKeys.size) {
				storeVal.forEach((v, i) => {
					const key = keyCallback(v, i)
					if (!oPreserveKeys.has(key)) {
						storeVal.delete(key)
					}
				})
			} else {
				storeVal.clear()
			}
		}
	})
	return storeVal
}

export const makeObjectCollection = (storeVal, newVal, keyCallback, childModelCreator) => {
	runInAction(() => {
		const storeSize = storeVal === {} ? 0 : 1
		const oPreserveKeys = storeSize ? new Set() : void 0
		if (newVal === null) {
			Object.keys(storeVal).forEach(i => {
				delete storeVal[i]
			})
			return storeVal
		}
		const emptyModel = childModelCreator()
		newVal.forEach((v, i) => {
			const key = keyCallback(v, i)
			if (storeSize && storeVal[key] !== void 0) {
				Object.assign(storeVal[key], { ...emptyModel, ...v })
			} else {
				storeVal[key] = Object.assign(childModelCreator(v), v)
			}
			if (storeVal[key]._init) {
				storeVal[key]._init()
			}
			if (storeSize !== 0) {
				oPreserveKeys.add(key)
			}
		})
		if (storeSize !== 0) {
			if (oPreserveKeys.size) {
				Object.keys(storeVal).forEach(i => {
					if (!oPreserveKeys.has(i)) {
						delete storeVal[i]
					}
				})
			} else {
				storeVal = {}
			}
		}
	})
	return storeVal
}

export const makeArrayCollection = (storeVal, newVal, childModelCreator) => {
	runInAction(() => {
		const storeSize = storeVal?.length
		const emptyModel = childModelCreator()
		if (newVal === null) {
			storeVal?.splice(0)
			return storeVal
		}
		newVal?.forEach((v, key) => {
			if (storeSize && storeSize > key) {
				storeVal[key] = Object.assign(storeVal[key], { ...emptyModel, ...v })
			} else {
				storeVal.push(Object.assign(childModelCreator(v), v))
			}
			if (storeVal[storeVal?.length - 1]._init) {
				storeVal[storeVal?.length - 1]._init()
			}
		})
		if (storeSize > newVal?.length) {
			storeVal.splice(newVal?.length)
		}
	})
	return storeVal
}

export const makeArray = (storeVal, newVal) => {
	runInAction(() => {
		const storeSize = storeVal?.length
		if (newVal === null) {
			storeVal?.splice(0)
			return storeVal
		}
		newVal.forEach((v, key) => {
			if (storeSize && storeSize > key) {
				if (storeVal[key] !== v) {
					storeVal[key] = v
				}
			} else {
				storeVal.push(v)
			}
		})
		if (storeSize > newVal?.length) {
			storeVal.splice(newVal?.length)
		}
	})
	return storeVal
}

export const lazyObject = (self, varName, classPrototype, path = null) => {
	console.info("lazyObject(self=%o varName=%o classPrototype=%o path=%o)", self, varName, classPrototype, path)
	runInAction(() => {
		Object.defineProperty(self, varName, {
			value: new classPrototype(self, path === null ? varName : path),
			writable: true,
			// // MTV-3583: TODO CHECK again below added property
			configurable: true
		})
		if (self[varName]._init) {
			console.info("(varName=%o, classPrototype=%o, path=%o) -> _init()", varName, classPrototype, path)
			self[varName]._init()
		}
	})
	return self[varName]
}

export const lazyCallback = (self, varName, createCallback, afterCreateCallback = null) => {
	console.info("lazyCallback(self=%o varName=%o createCallback=%o afterCreateCallback=%o)", self, varName, createCallback, afterCreateCallback)
	runInAction(() => {
		Object.defineProperty(self, varName, {
			value: createCallback(self, varName),
			writable: true
		})
		if (afterCreateCallback) {
			console.info("@(varName=%o, createCallback=%o, afterCreateCallback=%o) -> afterCreateCallback", varName, createCallback, afterCreateCallback)
			afterCreateCallback(self[varName])
		}
	})
	return self[varName]
}
