/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef } from "react"
import ClosePauseAdIcon from "img/player/ClosePauseAd.svg"
import { isMobileScreenSize, isMobileOrTablet } from "utils/Utils"
import { CardMedia } from "@mui/material"
import { CreateComponent } from "components/utils/CreateComponent"
import "./pauseAd.css"
import { controlsTimerHandler } from "../Player"

let timerPauseAd = null
let timerCloseButton = null
export const PauseAd = CreateComponent(null, false, true, ({ page, classes }) => {
	const [showCloseButton, setShowCloseButton] = useState(false)
	const [showPauseAd, setShowPauseAd] = useState(false)
	const refClosePauseAdButton = useRef()

	function tapOrClick(event) {
		event.preventDefault()
		if (event.type === "touchend") setShowPauseAd(false)
		event.stopPropagation()
	}

	// MTVW-788, MTVW-787: determine pauseAd source
	// PauseAd was not displayed when pausing live stream
	// With the introduction of a background player in MTVW-444 the page.source in rootStore.page.player
	// changes when starting the background player.
	const activeSource = (() => {
		let source = page?.playerFg?._source
		if (!source?.pauseAd) source = page.source
		//console.debug("activeSource", page, source.pauseAd, source)
		return source
	})

	const verifyShowCloseButton = useCallback(() => {
		const source = activeSource()
		if (!source?.pauseAd) setShowCloseButton(false)
		if (showPauseAd) {
			timerCloseButton = setTimeout(() => {
				setShowCloseButton(true)
			}, source?.pauseAd?.closeButtonAfter)
		} else {
			clearTimeout(timerCloseButton)
			timerCloseButton = null
			source?.removePauseAd("close")
			setShowCloseButton(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showPauseAd])

	//const style = visibilityHelper(page.source.pauseAd)
	//console.debug("visibilityHelper %o, props %o", style, props)

	// GT12-JPEG
	const verifyShowPauseAd = useCallback(() => {
		const source = activeSource()
		if (source?.pauseAd !== null) {
			timerPauseAd = setTimeout(() => {
				setShowPauseAd(true)
				source?.startPauseAd()
			}, source?.pauseAd?.delayDuration)
		} else {
			clearTimeout(timerPauseAd)
			clearTimeout(timerCloseButton)
			timerPauseAd = null
			timerCloseButton = null
			setShowPauseAd(false)
		}
	}, [activeSource().pauseAd /*page.source.pauseAd, page.playerFg?._source?.pauseAd*/])

	useEffect(() => {
		verifyShowCloseButton()
	}, [verifyShowCloseButton]);

	useEffect(() => {
		verifyShowPauseAd()
	}, [verifyShowPauseAd]);

	useEffect(() => {
		if (showPauseAd) setTimeout(() => {
			page.setMouseOver(false)
		}, 100);
		if (refClosePauseAdButton.current) {
			refClosePauseAdButton.current.addEventListener("touchstart", tapOrClick, false)
			refClosePauseAdButton.current.addEventListener("touchmove", tapOrClick, false)
			refClosePauseAdButton.current.addEventListener("touchend", tapOrClick, false)

		}
		return (() => {
			// eslint-disable-next-line no-unused-expressions
			refClosePauseAdButton.current?.removeEventListener("touchstart", tapOrClick, false)
			// eslint-disable-next-line no-unused-expressions
			refClosePauseAdButton.current?.removeEventListener("touchmove", tapOrClick, false)
			// eslint-disable-next-line no-unused-expressions
			refClosePauseAdButton.current?.removeEventListener("touchend", tapOrClick, false)
		})
	})

	useEffect(() => {
		return (() => {
			activeSource()?.removePauseAd("changeAsset")
		})
	}, [])

	return (
		<div>
			{showPauseAd ?
				<div className="PauseAdContainer"
					onMouseMove={(e) => {
						controlsTimerHandler(page)
						e.preventDefault()
						e.stopPropagation()
					}}
				>
					<div id={"pauseAdCardMedia"} className="PauseAd">
						{showCloseButton ?
							<div id="closePauseAdButton" ref={refClosePauseAdButton} className="closePauseAd"
								onClick={(e) => {
									e.stopPropagation()
									setShowPauseAd(false)
								}}>
								<img src={ClosePauseAdIcon} className={page.player.isFullScreen ? "closePauseAdIconFullScreen" : "closePauseAdIcon"}
									alt=""
									style={page.player.isFullScreen && (!isMobileScreenSize()) && (!isMobileOrTablet()) ? { height: "32px" } : {}} />
							</div> :
							null
						}
						<CardMedia src={activeSource()?.pauseAd?.adUrl ? activeSource().pauseAd.adUrl : null} component="img" data-test="pause_ad" />
					</div>
				</div> :
				null}
		</div>
	)
})
