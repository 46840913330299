//import "core-js/es6"
//import "core-js/es6/map"
//import "core-js/es6/set"
//import "core-js/es6/promise"

import { Provider } from "mobx-react"
import React from "react"
//import ReactDOM from "react-dom"
import * as serviceWorker from "./serviceWorker"
import { rootStore, RootStoreContext, appStats, appVersion, getStage, moment, appFeatures } from "./"
import { App } from "./App"
import "./site-ql/style.css"
import "mobx-react-lite/batchingForReactDom"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
//import { flow } from "mobx"
//import { StyledEngineProvider } from '@mui/material/styles'
import { createRoot } from 'react-dom/client'
//import { checkVersions } from "store/qlapi/ServiceUrls"

//import { Beforeunload } from "react-beforeunload"

/*WebFont.load({
	google: {
	families: ['Titillium Web:300,400,700', 'sans-serif']
	}
})*/

// NOTE: Custom messages in onbeforeunload dialogs are deprecated since chrome-51
// see https://stackoverflow.com/questions/38879742/is-it-possible-to-display-a-custom-message-in-the-beforeunload-popup
// Some browsers require a user gesture before displaying the message,
// for Chrome: Blocked attempt to show a 'beforeunload' confirmation panel for a frame that never had a user gesture since its load.
// https://www.chromestatus.com/feature/5082396709879808

const TIME_FORMAT = "YYYY-MM-DD[T]HH:mm:ss.ms[Z]"

// results in CORS issue
/*
var publicIp = null
const defineIp = flow(function* (url, options = {}) {
	try {
		let rsp = yield fetch(url, options)
		if (rsp?.ok) {
			rsp = yield rsp.json()
			publicIp = rsp?.IPv4
			return rsp
		}
	}
	catch (error) {
		console.error("failed to fetch IP, %o", error);
	}
})

defineIp("https://geoip-db.com/json", { mode: "no-cors" })
*/

// below init would prevent Bugsnag from getting unhandled errors and rejections
//rootStore.appError._init()

Bugsnag.start({
	apiKey: '39ea2368b27390f92693be3d38c65d96',
	plugins: [new BugsnagPluginReact()],
	appVersion: appVersion(),
	onError: function (event) {
		event.setUser(rootStore?.sso?.profile?.id ?? "", rootStore?.sso?.profile?.EMail ?? "", rootStore?.sso?.profile?.Nickname ?? "")
		event.addMetadata("MainUser", {
			"mainUserName": rootStore?.sso?.UserName,
			"eMail": rootStore?.sso?.EMail,
			"mobileNumber": rootStore?.sso?.MobileNumber
		})
		//event.device.clientIp = publicIp
		// clientIp seems to be added by the server
		//event.device.clientIp = event.request?.clientIp

		event.device.devicePixelRatio = window.devicePixelRatio
		// MTV-3180: screen size and stage settings
		// eslint-disable-next-line no-restricted-globals
		event.device.screenWidth = screen.width
		// eslint-disable-next-line no-restricted-globals
		event.device.screenHeight = screen.height
		event.app.releaseStage = getStage()
		event.app.upTimeMs = Date.now() - appStats.startTime
		event.app.time = event.device.time
		event.app.player = rootStore?.page?.Player?.player?.version

		const stats = {
			"appStartTime": moment(appStats.startTime).utc().format(TIME_FORMAT),
			"gt12Enabled" : appFeatures.gt12Enabled,
			"vAds": appStats.ads,
			"vBookmarks" : appStats.bookmarks,
			"vChannels" : appStats.channels,
			"vEpg": appStats.epg,
			"vLog": appStats.log,
			"vLogin": appStats.login,
			"vMedia": appStats.media,
			"vOnboarding": appStats.onboarding,
			"vSearch": appStats.search,
			"vPlayer": appStats.player,
			"vSworker": appStats.vSworker,
			"browser": appStats.browser,
			"mse": appStats.mse,
			"fairplaySupport": appStats.fairplaySupport,
			"widevineSupport": appStats.widevineSupport,
			"screenWidth": appStats.screenWidth,
			"screenHeight": appStats.screenHeight,
			"appUpTime": Date.now() - appStats.startTime,
			"traxisObjects": appStats.traxisObjects,
			"requestsSent": appStats.reqSent,
			"requestsReceived": appStats.reqReceived,
			"retries": appStats.reqRetries,
			"errors": appStats.reqErrors,
			"cacheFetches": appStats.reqCacheFetches,
			"cacheReturns": appStats.reqCacheReturns,
			"initialDbSize": appStats.initialDbSize,
			"initialDbKeys": appStats.initialDbKeys,
			"backToOnline": appStats.backToOnline,
			"playerWatchdog": appStats.playerWatchdog,
			"restartAfter403": appStats.restartAfter403,
			"swRewrites": appStats.swRewrites,
			"heapSizeLimit": 0,
			"heapSizeTotal": 0,
			"heapSizeUsed": 0
		}
		if (appStats.heapSizeUsed > 0) {
			stats.heapSizeLimit = appStats.heapSizeLimit
			stats.heapSizeTotal = appStats.heapSizeTotal
			stats.heapSizeUsed = appStats.heapSizeUsed
		}
		event.addMetadata("Statistics", stats)
	}
})

//interface pluginInterface { ():any }
const ErrorBoundary: any = Bugsnag?.getPlugin('react')?.createErrorBoundary(React)

// MTVW-205 not active
//rootStore.appError._init()

/* React 17
ReactDOM.render(
	<Provider store={rootStore}>
		<RootStoreContext.Provider value={rootStore}>
			{// <Beforeunload onBeforeunload={() => "Möchten Sie Quickline TV wirklich verlassen?"}> //}
			<ErrorBoundary>
			<App />
			</ErrorBoundary>
			{// </Beforeunload> //}
		</RootStoreContext.Provider>
	</Provider>,
	document.getElementById("root")
)
*/


/* React 18 */
const container = document.getElementById("root")
const root = createRoot(container!)  // createRoot(container) without TypeScript

// TEST: commented
/*
console.debug("checkVersions")
checkVersions()
*/

/*
export default function GlobalCssPriority() {
  return (
    <StyledEngineProvider injectFirst>
    </StyledEngineProvider>
  )
}
*/

root.render(
	<Provider store={rootStore}>
		<RootStoreContext.Provider value={rootStore}>
			{/* <Beforeunload onBeforeunload={() => "Möchten Sie Quickline TV wirklich verlassen?"}> */}
			<ErrorBoundary>
				<App />
			</ErrorBoundary>
			{/* </Beforeunload> */}
		</RootStoreContext.Provider>
	</Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// MTVW-128: enable service worker for the time being
serviceWorker.register()
//serviceWorker.unregister()
