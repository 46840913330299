import { types as t } from "mobx-state-tree"
import { TraxisAbstract } from "store/api/TraxisAbstract"

const CreateChannelListModel = t.model({
	ChannelList: t
		.model({
			id: t.string,
			isReady: t.maybeNull(t.boolean)
		})
		// added for mobx 6.3 which is more strict
		.actions(self => ({
			setReady(value) {
				self.isReady = value
			}
		}))
})

export class CreateChannelList extends TraxisAbstract {
	get _model() {
		return CreateChannelListModel
	}

	_getParseResp(data) {
		return data
	}

	//_getHashReq(data) {
	//	return ``
	//}

	getXmlReq() {
		return `<?xml version="1.0" encoding="utf-8"?>
<Request xmlns="urn:eventis:traxisweb:1.0">
    <Identity>
        <ProfileId>${this.profileId}</ProfileId>
    </Identity>
    <RootActionQuery actionName="AddChannelList">
        <Arguments>
            <Argument name="Name">${this._oVars.channelListName}</Argument>
        </Arguments>
    </RootActionQuery>
</Request>`
	}
}
