import { action, flow, observable, makeObservable, computed, runInAction } from "mobx"
import { ClassAbstract } from "store/ClassTools"
import { PageAbstract } from "store/page/PageAbstract"
import { rootStore } from "store/RootStore"
import { TraxisError } from "store/api/TraxisError"
import { MANAGE_RECORDINGS_ACTIONS } from "store/api/ManageRecordings"

export const RECORDINGS_GUARD_TIME = [0, 2, 5, 10, 15, 60]

//class RecordingsGuardTime extends ClassAbstract {}

export const RECORDING_OPTIONS = {
	// Aufnehmen #8
	episode_record: "episode_record",

	// Aufnahme löschen #6
	episode_delete: "episode_delete",

	// Aufnahme abbrechen #7
	episode_cancel: "episode_cancel",

	// Serienaufnahme starten #1
	series_record: "series_record",

	// Serienaufnahme starten #1
	series_record_add: "series_record_add",

	// Serienaufnahme abbrechen #4b
	series_cancel: "series_cancel",

	// Serienaufnahme löschen #4a @should not be visible on detail page
	series_delete: "series_delete",

	// Aufnahme abbrechen
	series_episode_record: "series_episode_record",

	// Aufnahme abbrechen
	series_episode_cancel: "series_episode_cancel",

	// Aufnahme löschen
	series_episode_delete: "series_episode_delete"
}

class RecordingsManagerEvent extends ClassAbstract {
	oEvent
	oRecordings = null

	isLoading = false
	isDone = false
	error = null

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			isLoading: observable,
			isDone: observable,
			error: observable.ref,
			setEvent: action,
			setLoaderVisible: action,
			setDoneStatus: action,
			resetStatus: action,
			buttonsLists: computed
		})
	}

	setEvent(oEvent) {
		if (this.oEvent !== oEvent) {
			this.resetStatus()
		}
		this.oEvent = oEvent
		return this
	}

	setLoaderVisible(bStatus) {
		this.isLoading = bStatus
		return this
	}

	setDoneStatus(bStatus) {
		this.isDone = bStatus
		return this
	}

	resetStatus() {
		this.isLoading = false
		this.isDone = false
		this.error = null
	}

	getRecordings(props) {
		return this._root.api.GetRecordings({ ...{ isRecorded: true, isPlanned: true, iLimit: 2000, isChildRecordings: true, isSortDesc: false }, ...props })
	}

	manageRecordingsAsync = flow(function* (props) {
		console.log("@@manageRecordingsAsync(props=%o)", props)
		this.isLoading = true
		this.error = null
		try {
			if (Array.isArray(props?.Ids)) {
				for (let i = 0; i < props?.Ids?.length; i += 20) {
					yield rootStore.api.ManageRecordings({ ...props, ...{ Ids: props?.Ids.slice(i, i + 20) } }).fetchDataAsync()
				}
			} else {
				const api = rootStore.api.ManageRecordings(props)
				yield api.fetchDataAsync()
				this.isLoading = false
				return api?.Data?.Recording?.id
			}
			this.isLoading = false
		} catch (e) {
			this.isLoading = false
			this.error = TraxisError.getErrorIfInstanceOf(e)
			// MTV-1774: this.error may be reset when the next request from RecordingsManager is sent,
			// latch error in player and abort further processing by throwing exception
			rootStore.page.Player.error = TraxisError.getErrorIfInstanceOf(e)
			throw this.error
		}
		// MTVW-500
		rootStore.page.Recordings.IndexPage.refreshData()
		rootStore.page.Recordings.ListPage.refreshData()
	})

	manageRecordingsPersonalAsync = flow(function* (recordingId, state) {
		yield this.manageRecordingsAsync({
			ActionName: state === true ? MANAGE_RECORDINGS_ACTIONS.AddToPersonalRecordings : MANAGE_RECORDINGS_ACTIONS.DeleteFromPersonalRecordings,
			Ids: recordingId
		})
	})

	handleEpisodeRecord = flow(function* (props) {
		console.log("@@handleEpisodeRecord(props=%o)", props)
		let recordingId = yield this.manageRecordingsAsync({
			ActionType: "Event",
			ActionName: "Record",
			Ids: this.oEvent?.id,
			Arguments: {
				...{
					Location: "Network",
					GuardTimeStart: "PT10M",
					GuardTimeEnd: "PT10M"
				},
				...props.Arguments
			}
		})

		if (this.error?.isErrorRecordedAlready === true) {
			recordingId = this.error.getRecordedId
			yield this.manageRecordingsAsync({
				ActionName: MANAGE_RECORDINGS_ACTIONS.Update,
				Ids: recordingId,
				Arguments: {
					...{
						GuardTimeStart: "PT10M",
						GuardTimeEnd: "PT10M"
					},
					...props.Arguments
				}
			})
		}
		yield this.manageRecordingsPersonalAsync(recordingId, props?.IsPersonal)

		this.isDone = true
	})

	handleSerieRecord = flow(function* (props) {
		console.log("@@handleSerieRecord(props=%o)", props)
		let recordingId = yield this.manageRecordingsAsync({
			ActionType: "Series",
			ActionName: "Record",
			Ids: this.oEvent?.Title?.SeriesId,
			Arguments: {
				...{
					Location: "Network",
					GuardTimeStart: "PT10M",
					GuardTimeEnd: "PT10M"
				},
				...props.Arguments
			}
		})
		if (this.error?.isErrorRecordedAlready === true) {
			recordingId = this.error.getRecordedId
			yield this.manageRecordingsAsync({
				ActionName: MANAGE_RECORDINGS_ACTIONS.Update,
				Ids: recordingId,
				Arguments: {
					...{
						GuardTimeStart: "PT10M",
						GuardTimeEnd: "PT10M"
					},
					...props.Arguments
				}
			})
			yield this.manageRecordingsAsync({
				ActionName: MANAGE_RECORDINGS_ACTIONS.Resume,
				Ids: recordingId
			})
		}
		yield this.manageRecordingsPersonalAsync(recordingId, props?.IsPersonal)

		this.isDone = true
	})

	handleEpisodeDelete = flow(function* () {
		console.log("@@handleEpisodeDelete()")
		yield this.manageRecordingsAsync({
			ActionName: MANAGE_RECORDINGS_ACTIONS.Delete,
			Ids: this.marker?.id
		})
		this.isDone = true
	})

	handleEpisodeCancel = flow(function* () {
		console.log("@@handleEpisodeCancel()")
		yield this.manageRecordingsAsync({
			ActionName: MANAGE_RECORDINGS_ACTIONS.Cancel,
			Ids: this.marker?.id
		})

		this.isDone = true
	})

	handleSeriesCancel = flow(function* (findProps) {
		console.log("@@handleSeriesCancel()")

		const recs = this.getRecordings({ Ids: this.marker?.id })
		yield recs.fetchDataAsync()

		yield this.manageRecordingsAsync({
			ActionName: MANAGE_RECORDINGS_ACTIONS.Cancel,
			Ids: recs.Data?.Recording?.[0]?.ParentRecordings?.id
		})

		this.isDone = true
	})

	handleSeriesDelete = flow(function* (findProps) {
		console.log("@@handleSeriesDelete()")

		const recs = this.getRecordings({ Ids: this.marker?.id })
		yield recs.fetchDataAsync()

		yield this.manageRecordingsAsync({
			ActionName: MANAGE_RECORDINGS_ACTIONS.Delete,
			Ids: recs.Data?.Recording?.[0]?.ParentRecordings?.id
		})

		this.isDone = true
	})

	handleDeleteRecordings = flow(function* (findProps) {
		console.log("@@handleDeleteRecordings()")
		let tDeleteEvents = []
		let tCancelEvents = []
		let tDeleteSeries = []

		const handleDeleteRecording = i => {
			if (i.isSeries) {
				tDeleteSeries.push(i.id)
			} else if (i.State === "Scheduled" && i.Type === "Series") {
				tCancelEvents.push(i.id)
			} else {
				tDeleteEvents.push(i.id)
			}
			if (i.Recordings?.length > 0) {
				console.error(i.Recordings)
				i.Recordings.forEach(j => handleDeleteRecording(j))
			}
		}
		this.isLoading = true
		try {
			const recs = this.getRecordings(findProps)
			yield recs.fetchDataAsync()
			recs.Data.Recording.forEach(i => handleDeleteRecording(i))
			console.info("@@handleDeleteRecordings(findProps=%o) tDeleteEvents=%o tCancelEvents=%o tDeleteSeries=%o", findProps, tDeleteEvents, tCancelEvents, tDeleteSeries)

			if (tCancelEvents?.length > 0) {
				yield this.manageRecordingsAsync({
					ActionName: MANAGE_RECORDINGS_ACTIONS.Cancel,
					Ids: tCancelEvents
				})
			}
			if (tDeleteEvents?.length > 0) {
				yield this.manageRecordingsAsync({
					ActionName: MANAGE_RECORDINGS_ACTIONS.Delete,
					Ids: tDeleteEvents
				})
			}
			if (tDeleteSeries?.length > 0) {
				yield this.manageRecordingsAsync({
					ActionName: MANAGE_RECORDINGS_ACTIONS.Delete,
					Ids: tDeleteSeries
				})
			}
			yield this._root.page.singleton.customer.fetchData()
			//this.isLoading = false
			this.isDone = true
			return true
		} catch (e) {
			//	this.isLoading = false
			this.error = TraxisError.getErrorIfInstanceOf(e)
			//console.debug(this.error)
			//throw this.error
		}
	})

	/**
	 * @returns {import('store/api/GetRecordingsMarkers').GetRecordingsMarkersItemModel|null}
	 */
	get marker() {
		return this._parent.getMarker(this.oEvent?.id)
	}

	get isEventRecordable() {
		return this.oEvent?.IsNetworkRecordingAllowed === true
	}

	get isEventStarted() {
		return this.oEvent
	}

	get buttonsLists() {
		const t = {}

		//console.debug("buttonsLists", this.isRecorded, this.oEvent?.id, this.oEvent?.content?.Title, this.marker, this.oEvent)
		// modification
		if (this.isRecorded) {
			// MTVW-500: condition for (pre-scheduled?) single recording
			// MTVW-518: comment change made in MTVW-500
			if (this.isRecordedAsSeries /*&& !this.isRecordedAsSingle*/) {
				t[RECORDING_OPTIONS.series_delete] = true
				t[RECORDING_OPTIONS.series_cancel] = true
			}

			// MTVW-500: condition for (pre-scheduled?) single recording
			// MTVW-518: comment change made in MTVW-500
			if (this.isRecordedAsSeries /*&& !this.isRecordedAsSingle*/) {
				t[this.isRecordedComplete ? RECORDING_OPTIONS.series_episode_delete : RECORDING_OPTIONS.series_episode_cancel] = true
			} else {
				t[this.isRecordedComplete ? RECORDING_OPTIONS.episode_delete : RECORDING_OPTIONS.episode_cancel] = true
			}
		}

		if (this.oEvent?.IsNetworkRecordingAllowed === true) {
			// adding
			if (!this.isRecorded) {
				t[this.isEventSeries ? RECORDING_OPTIONS.series_episode_record : RECORDING_OPTIONS.episode_record] = true
			}
			if (this.isEventSeries && !this.isRecordedAsSeries) {
				t[this.isRecorded ? RECORDING_OPTIONS.series_record_add : RECORDING_OPTIONS.series_record] = true
			}
		}
		return t
	}

	get isEventSeries() {
		return this.oEvent?.Title?.isSeries === true
	}

	get isRecorded() {
		return this.marker !== null
	}

	get isPersonal() {
		return this.marker?.IsPersonal !== false
	}

	get isRecordedAsSeries() {
		// MTVW-500
		//console.debug("oEvent=", this.oEvent, rootStore.page.Recordings.IndexPage.isRecordedAsSingle(this.oEvent?.event?.id))
		//return this.marker?.isSeries === true || isPlanned
		//console.debug("isRecordedAsSeries=", this.marker?.isSeries === true /*&& !rootStore.page.Recordings.IndexPage.isRecordedAsSingle(this.oEvent?.event?.id)*/)
		//return this.marker?.isSeries === true /*&& !rootStore.page.Recordings.IndexPage.isRecordedAsSingle(this.oEvent?.event?.id)

		// MTVW-518: revert change
		/*
		// provides more up-to-date information
		return rootStore.page.Recordings.IndexPage.isRecordedAsSeries(this.oEvent?.content?.SeriesId) ||
		rootStore.page.Recordings.IndexPage.isPlanned(this.oEvent?.content?.SeriesId)
		*/
		return this.marker?.isSeries === true
	}

	get isRecordedAsSingle() {
		return this.marker?.isSeries === false
	}

	get isRecordedComplete() {
		return this.marker?.isComplete === true
	}
}

export class RecordingsManager extends PageAbstract {
	/**
	 * @type {import('store/api/GetRecordingsMarkers').GetRecordingsMarkersModel}
	 */
	oRecMarkers = rootStore.api.GetRecordingsMarkers().fetchData().Data

	oEvent = null
	oRecEvent = new RecordingsManagerEvent(this, "getRecordingEvent")

	oRecMarkersTmp = new Map()

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			oRecMarkers: observable.shallow,
			oRecMarkersTmp: observable.shallow,
		})
		// MTVW-500
		setInterval(() => {
			runInAction(() => {
				try {
					this.oRecMarkers = rootStore.api.GetRecordingsMarkers().fetchData().Data
				}
				catch (e) {
					console.error("CAUGHT GetRecordingsMarkers", e)
				}
			})
		}, 30000)
	}

	/**
	 * @returns {import('store/api/GetRecordingsMarkers').GetRecordingsMarkersItemModel}
	 * @param {string} eventId
	 */
	getMarker(eventId, serieId = null) {
		let item = this.oRecMarkers.getById(eventId) ?? null
		if (item !== null) {
			return item.isDeleted ? null : item
		}
		if (serieId !== null) {
			item = this.oRecMarkers.getById(serieId) ?? null
			if (item !== null) {
				return item
			}
		}
		item = this.oRecMarkersTmp.get(eventId) ?? null
		if (item !== null) {
			return item
		}
		if (serieId !== null) {
			return this.oRecMarkersTmp.get(eventId) ?? null
		}
		return null
	}

	/**
	 * @returns {RecordingsManagerEvent}
	 * @param {*} oEvent
	 */
	getRecordingEvent(oEvent) {
		// MTVW-518: Live Tv issue, when switching the channel, the recording buttons did show the status of the previous channel
		// Generate a new RecordingsManagerEvent if the event changes
		if (this.oEvent !== oEvent) {
			this.oRecEvent = new RecordingsManagerEvent(this, "getRecordingEvent")
			this.oEvent = oEvent
		}
		return this.oRecEvent.setEvent(oEvent)
	}

	/**
	 * @returns {RecordingsManagerEvent}
	 * @param {*} oEvent
	 */
	get recordingManager() {
		return this.oRecEvent.setEvent(null)
	}
}
