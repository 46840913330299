/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-09
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-11
 */

import { action, computed, flow, observable, makeObservable } from "mobx"
// MTV-2172: disable screenfull
//import screenfull from "screenfull"
import { ClassAbstract } from "store/ClassTools"
import { makeArrayCollection } from "store/ModelTools"
import { EventEmitter } from "components/utils/EventEmitter"
import { appStats } from "utils/TestSupport"
import { rootStore } from "store/RootStore"
import { moment } from "store/util/moment"
import { TIME_FORMAT_BACKEND } from "store/model/Time"
//import { LiveTvConfig } from "scenes/LiveTv/LiveTv"
import { monitorState } from "components/utils/ConnectionMonitor"
import { isIphone } from "utils/Utils"

export class PlayerTrackAbstractItem extends ClassAbstract {
	id = null

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			id: observable,
			setApplyTrack: action
		})
	}

	get parent() {
		return this._parent
	}

	get player() {
		return this.parent.player
	}

	get labelText() {
		return "labelText"
	}

	get isSelected() {
		console.debug("isSelected() %s, %s, %s, %o, %o", this.parent?.current?.id, this.id, this.parent?.current?.id === this.id, this, this.parent)
		return this.parent?.current?.id === this.id && this.parent?.tTrack?.length > 1
	}

	get isSelectable() {
		console.debug("isSelectable", this.parent?.tTrack?.length > 1)
		return this.parent?.tTrack?.length > 1
	}

	setApplyTrack() {
		console.info("@()")
		return this
	}
}

export class PlayerTrackAbstract extends ClassAbstract {
	//tTrack: t.array(PlayerTrackAbstractItem),
	//current: t.safeReference(PlayerTrackAbstractItem)
	_$current = ""
	_$tTrack = []

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			_$current: observable,
			_$tTrack: observable.shallow,
			current: computed,
			setCurrent: action,
			setTracks: action,
			setTracksFromStream: action
		})
	}

	get _getTrackItem() {
		return PlayerTrackAbstractItem
	}

	get current() {
		if (import.meta.env.VITE_PLAYER === "vjs") {
			// VJS: compare with this._$current?.id instead of this._$current
			//if (this.tTrack instanceof PlayerTrackSubtitleItemVjs) console.debug("is PlayerTrackSubtitleItemVjs")
			console.debug("get Current %o, find %o", this._$current, this.tTrack.find(i => i.id === this._$current) || this.tTrack.find(i => i.id === this._$current?.id, this.tTrack))
			//return this._$current !== null ? this.tTrack.find(i => i.id === this._$current?.id) : null
			return this._$current !== null ? this.tTrack.find(i => i.id === this._$current) || this.tTrack.find(i => i.id === this._$current?.id) : null
		}
		else if (import.meta.env.VITE_PLAYER === "vo") {
			//console.debug("get Current %s, find %o", this._$current, this.tTrack.find(i => i.id === this._$current))
			return this._$current !== null ? this.tTrack.find(i => i.id === this._$current) : null
		}
	}

	set current(value) {
		// MTVW-92
		// special case for subtitle "Aus"
		//if (!value && Object.getPrototypeOf(this).constructor.name === "PlayerTracksSubtitleVo") value = ""
		if (!value) value = ""
		this._$current = value
		return this.current
	}

	set tTrack(t) {
		return makeArrayCollection(this._$tTrack, t, () => new this._getTrackItem(this, "tTrack"))
	}

	get tTrack() {
		return this._$tTrack
	}

	get player() {
		return this._parent
	}

	findByLabelText(labelText) {
		return this.tTrack.find(i => i.labelText === labelText)
	}

	findById(id) {
		return this.tTrack.find(i => i.id === id)
	}

	get items() {
		return this.tTrack
	}

	get isEmpty() {
		return this.tTrack?.length === 0
	}

	setCurrent(track = null) {
		this.current = track !== null ? track : void 0
		return this
	}

	setTracks(tTrack) {
		console.info("@(tTrack=%o) this.tTrack=%o", tTrack, this.tTrack)
		return this
	}

	setTracksFromStream() {
		console.info("@() self.player.getAudioTracks()=%o self=%o", this.player._api.getAudioTracks())
		return this
	}
}

//	audioTracks: PlayerTrackAbstract,
//	subtitleTracks: PlayerTrackAbstract

export class PlayerAbstract extends ClassAbstract {
	/**
	 * @returns {import("store/page/player/source/SourceAbstract.js").SourceAbstract}
	 */
	_source = null
	//@observable _isStreamLoaded = null
	//@observable _isOpened = null
	//@observable playTime = 0

	// autoplay bug and issue
	// MTV-3666: should be static, but mobx does not support static observables!
	isMutedByBrowser = false

	error = null

	activePosition = null
	activePauseState = null
	activeMuteState = null
	// MTVW-217: 2 new properties
	inactiveReason = null
	inactiveChannelId = null
	domElementId = {
		TV_PLAYER: "tvPlayer",
		ADS_PLAYER: "adsPlayer",
		BG_PLAYER: "bgPlayer"
	}
	playerToDomId = {
		playerLiveTvVo: "tvPlayer",
		playerDetailPageVo: "tvPlayer",
		playerAds: "adsPlayer",
		playerBg: "bgPlayer"
	}

	constructor(parent, path) {
		super(parent, path)
		//console.debug("PlayerAbstract %o", path)
		makeObservable(this, {
			_source: observable,
			isMutedByBrowser: observable,
			error: observable.shallow,
			volume: computed,
			isMuted: computed,
			setSource: action,
			setMutedByBrowser: action,
			setFullScreen: action
		})
		if (path !== "playerFake" && path !== "playerAds") {
			EventEmitter.subscribe("inactive", (event) => { this._isInactive(event) })
			EventEmitter.subscribe("active", (event) => { this._isActive(event) })
			//EventEmitter.subscribe("playerWatchdog", (event) => { this._playerWatchdog(event) })
			EventEmitter.subscribe("mediaError403", (event) => { this._mediaError403(event) })
		}

		//document.addEventListener("visibilitychange", this._visibilityChange.bind(this), false)
	}

	// TODO joe: remove
	isSourceEqual(source) {
		return this.type === source.type && this.id === String(source.id)
	}

	/**
	 * @returns {import("store/page/player/PlayerSettings").PlayerSettings}
	 */
	get settings() {
		return this._parent.settings
	}

	get page() {
		return this._parent
	}

	get volume() {
		return this.settings.isMuted ? 0 : this.settings.volume
	}

	get isMuted() {
		return this.settings.isMuted || this.settings.volume === 0
	}

	get isControlsVisible() {
		// return this.isPaused
		//hide the player controls even if is in pause
		return false
	}

	get isPaused() {
		return false
	}

	get isPauseUsed() {
		return false
	}

	/*
	get isPosterVisible() {
		return false
	}
	*/

	get isStreamSequential() {
		return false
	}

	get isTracksAudioEmpty() {
		return this.audioTracks?.isEmpty
	}

	get isTracksSubtitleEmpty() {
		return this.subtitleTracks?.isEmpty
	}

	get isFullScreen() {
		return this.settings.isFullScreen
	}

	setRefElemContainer(refElem) {
		console.error("@@setRefElemContainer() NEED IMPLEMENTATION")
	}

	setIsLoading(isLoading = false) {
		console.error("@@setIsLoading() NEED IMPLEMENTATION")
	}

	get isOnFullScreen() {
		console.error("@@isOnFullScreen() NEED IMPLEMENTATION")
		return false
	}

	isJumpRwdPossibleSec(sec) {
		console.error("@@isJumpRwdPossibleSec() NEED IMPLEMENTATION")
		return true
	}

	setSource(source) {
		console.info("@(source=%o)", source)
		this._isStreamLoaded = false
		this._source = source
		return this
	}

	openStreamAsync = flow(function* () {
		console.error("@@openStreamAsync() NEED IMPLEMENTATION")
		yield Promise.resolve(true)
	})

	setPlay() {
		console.error("@() NEED IMPLEMENTATION")
		return this
	}

	setPause() {
		console.error("@() NEED IMPLEMENTATION")
		return this
	}

	setPlayPause() {
		return this.isPaused ? this.setPlay("setPlayPause") : this.setPause()
	}

	setVolume(volume) {
		console.error("@(volume=%o) NEED IMPLEMENTATION", volume)
		return this
	}

	setMuteState(bState = null) {
		console.error("@(bState=%o) NEED IMPLEMENTATION", bState)
		return this
	}

	setMutedByBrowser(muted) {
		this.setMuteState(muted)
		// MTV-3666: apply to the player instances, since mobx does not support static observables
		this.isMutedByBrowser = muted
		this._parent.playerDetailPageVo.isMutedByBrowser = muted
		this._parent.playerLiveTvVo.isMutedByBrowser = muted
		this._parent.playerAds.isMutedByBrowser = muted
		this._parent.playerBg.isMutedByBrowser = muted
		return this
	}

	setJumpFwd(sec) { }

	setJumpRwd(sec) { }

	setJumpToLiveAsync = flow(function* () {
		return yield Promise.resolve(false)
	})
	// MTVW-788, MTVW-787: flow function
	//_isInactive(event) {
	_isInactive = flow(function* (event) {
		if (this.isPlayerActive && this.__path !== "playerAds") {
			this.activePosition = null
			console.debug("PlayerAbstract ISINACTIVE, event %s, paused %s, muted %s, source %o", event, this.activePauseState !== null ? this.activePauseState : this.isPausedUser, this.activeMuteState !== null ? this.activeMuteState : this.isMuted, this._source)
			try {
				// prevent multiple concurrent updates
				if (this.activePauseState === null) this.activePauseState = this.isPausedUser
				if (this.activeMuteState === null) this.activeMuteState = this.isMuted
				console.debug("activeMuteState %s", this.activeMuteState)
				// MTVW-217: prevent mute in case of restore - DISABLED in PlayerVo
				if (event !== "reasonStopped") this.setMuteState(true)
				if (this._source?.type === "ChannelLive" && this.activePauseState) {
					// activePosition is set in PlayerVo.setPause() and PlayerVjs.setPause(), but reset at the beginning of this method
					this.activePosition = this.positionTimeStamp / 1000
				}
				else if (this._source?.type !== "ChannelLive") {
					//this.activePosition = this._apiGetPosition()
					if (this.positionTimeStamp) {
						this.activePosition = this.positionTimeStamp / 1000
						this._source.playlistLoadedCallback = () => {
							// apply captured current position after disconnect / background state
							//console.debug("playlist %o %o", this._source.playlist.StreamInfo.event_start, this.activePosition)
							/*
							this._source.playlist.StreamInfo.rstv.event_offset = this.activePosition - this._source.playlist.StreamInfo.rstv.time_offset
							console.debug("callback %s", this._source.playlist.StreamInfo.rstv.event_offset)
							*/
							// MTVW-633: recheck
							//this._source.playlist.StreamInfo.event_start = moment(this.activePosition * 1000).utc().format(TIME_FORMAT_BACKEND)
							//this._source.time = moment(this.activePosition * 1000).utc().format(TIME_FORMAT_BACKEND)
							console.debug("event_start", this._source.playlist.StreamInfo.event_start, this._source.time)
						}
					}
				}
				// MTVW-444, MTVW-788, MTVW-787: stopInstance for vjs
				if (import.meta.env.VITE_PLAYER === "vo") {
					yield this.setStop()
					yield this.setClose()
				}
				else if (import.meta.env.VITE_PLAYER === "vjs") {
					if (this.__parent.playerFg) {
						yield this.__parent.inactivePlayer.stopInstance()
						this.__parent.setPlayerFg(null)
					}
					// MTVW-725: case of active ads player
					if (!this.__parent.isAdsPlayer) yield this.stopInstance()
					else yield this._source.playlist.stopKeepAlive()
				}
				this.setIsPosterVisible(true, "_isInactive", true)
				// MTVW-217
				this.inactiveReason = event
				this.inactiveChannelId = rootStore.page.ChannelListRepo.activeChannelId
			}
			catch (e) {
				console.error("caught in PlayerAbstract._isInactive %o, mute state % s", e, this.activeMuteState)
			}
		}
	})

	_isActive = flow(function* (event) {
		if (this.isPlayerActive && this.__path !== "playerAds") {
			console.debug("PlayerAbstract ISACTIVE, event %s, paused %s, muted %s, source %o", event, this.activePauseState, this.activeMuteState, this._source)
			try {
				console.debug("active %s", this.__path)
				// MTVW-444, MTVW-788, MTVW-787: only for vo
				if (import.meta.env.VITE_PLAYER === "vo") {
					yield this.setStop()
					yield this.setClose()
				}
				//this.isPaused = this.activePauseState
				if (this._source?.type === "ChannelLive" && this.activePauseState) {
					yield this.page.setPlayChannelReplayAsync(this._source.id, this.activePosition, this._source.GetEvent)
					console.debug("this.activePosition", this.activePosition)
					//this.setPositionTimeStamp(this.activePosition)
					//this.setPause()
					//if (import.meta.env.VITE_PLAYER === "vjs") {
					/*
					yield this.setPlay("_isActive")
					this.setPause()
					//}
					*/
				}
				else if (this._source?.type === "ChannelLive") {
					//rootStore.page.LiveTv.handlePlayChannelAsync(this._source.id)
					//rootStore.page.LiveTv.handlePlayChannelLive(ch.id, LiveTvConfig.cellHeight + LiveTvConfig.cellMarginBottom)
					rootStore.page.LiveTv.memoSelectedEpg = null
					if (this.inactiveReason !== "validTill") yield rootStore.page.LiveTv.reloadChannelEpg()
					console.debug("starting live channel", this._source)
					yield this.__parent.setPlayChannelLiveAsync(this._source.id, this._source.GetEvent, true)
				}
				else {
					// MTVW-633: added next 2 lines
					console.debug("this._source", this._source, this.activePosition, moment(this.activePosition * 1000).utc().format(TIME_FORMAT_BACKEND))
					if (this.activePosition) {
						this._source.time = moment(this.activePosition * 1000).utc().format(TIME_FORMAT_BACKEND)
					}
					yield this._source.openStreamAsync()
					//if (this.activePauseState) this.setPause()
				}
				console.debug("mute state %s", this.activeMuteState)
				if (!this.activeMuteState) { this.setMuteState(false) }
				//console.debug("setting connected true")
				//monitorState.connected = true
				//this.setIsPosterVisible(false)
				if (this.activePauseState) {
					this.setIsPosterVisible(true, "_isActive")
					if (!this.__parent.isAdsPlayer) yield this.setPlay("_isActive")
					this.setPause()
					this.setIsPosterVisible(false, "_isActive", false, 500)
				}
			}
			catch (e) {
				console.error("caught in PlayerAbstract._isActive %o, mute state %s", e, this.activeMuteState)
				if (!this.activeMuteState) { this.setMuteState(false) }
			}
			finally {
				this.activePauseState = null
				this.activeMuteState = null
				// MTVW-217
				this.inactiveReason = null
				this.inactiveChannelId = null
				this._source?.setCapturedVideoImage(null)
			}
		}
	})

	//_playerWatchdog(event) {
	_playerWatchdog = flow(function* (event) {
		console.debug("PlayerAbstract _playerWatchdog src %o", this.__path, this._source)
		// make s snapshot of the stream position
		// MTVW-255: condition added
		//if (!monitorState.isSuspended) {
		if (monitorState.isActive && this._source) {
			yield this._isInactive(event)
			yield this._isActive(event)
			appStats.playerWatchdog++
		}
	})

	//_mediaError403(event) {
	_mediaError403 = flow(function* (event) {
		console.debug("PlayerAbstract _mediaError403 evt %o, src %o", event, this._source)
		// make s snapshot of the stream position
		// MTVW-255: condition added
		//if (!monitorState.isSuspended) {
		if (monitorState.isActive) {
			this._isInactive(event)
			yield this._isActive(event)
			appStats.restartAfter403++
		}
	})

	/* pre MTV-2172
	@action
	setFullScreen(bState = null) {
		if (bState === null) {
			bState = !this.settings.isFullScreen
		}
		this.settings.isFullScreen = bState
		if (screenfull.isEnabled) {
			screenfull[bState ? "request" : "exit"]().catch(() => { })
		}
		return this
	}
	*/

	// MTV-2172
	//setFullScreen(bState = null) {
	setFullScreen = flow(function* (bState = null) {
		//console.trace("setFullScreen", bState, this._isStreamLoaded)
		// MTVW-424: Allow full screen in ads 
		// if (this.__path === "playerAds") return
		if (bState === null) {
			bState = !this.settings.isFullScreen
		}
		// moved to the end
		//this.settings.isFullScreen = bState
		//console.debug("setFullScreen %s, %o, %s", bState, this, this.__path)

		const uiPlayersElem = document.getElementById("uiPlayers")
		const tvPlayerElem = document.getElementById(this.domElementId.TV_PLAYER)
		// GT12 iPhone: just use this.settings.isFullScreen
		// TODO: hide surrounding elements in full screen on iPhone
		if (uiPlayersElem && tvPlayerElem && !isIphone()) {
			//console.debug("bState %s, elem %o, fs %o, path %s", bState, tvPlayerElem, this.isOnFullScreen(), this.__path)
			if (bState) {
				if (!this.isOnFullScreen()) {
					//console.debug("calling enter %o", this.page.getPlayerByName("playerAds"))
					// MTVW-733: fix for ultrawidescreen
					if (import.meta.env.VITE_PLAYER === "vo") {
						const videos = document.getElementsByClassName("vop-video")
						if (videos) {
							for (var i = 0; i < videos.length; i++) {
								//console.debug("videos", i, videos[i])
								//videos[i].style.height = "100vh"
							}
						}
						//if (video) video.style.height = "100vh"
					}
					// Use uiPlayersElem (not tvPlayerElem) otherwise ads button don't work
					try {
						yield this.enterFullScreen(uiPlayersElem)
					}
					catch (e) { console.error("enterFullScreen", e) }
				}
				if (import.meta.env.VITE_PLAYER === "vjs") {
					/* MTVW-444: commented
					// VJS: for fullscreen we have to move PlayerControls into the vjs wrapper
					const wrappers = document.getElementsByClassName('video-js')
					//const wrappers = document.getElementsByClassName('vjs-tech')
					const controls = document.getElementById("playerControls")
					if (wrappers?.length > 0 && controls) {
						//console.debug("setFullScreen", wrappers, controls)
						controls.id = "playerControls-1"
						const markerDiv = document.createElement("div")
						markerDiv.id = "playerControls-marker"
						// insertBefore has to be called on the parent element
						controls.parentNode.insertBefore(markerDiv, controls)
						wrappers[0].append(controls)
						//wrappers[0].insertBefore(controls)
					}
					*/
				} else if (import.meta.env.VITE_PLAYER === "vo") {
					if (this.__path === "playerLiveTvVo") {
						// in order that the overlay can be displayed on full screen, we need to append it to the tvPlayer element
						const overlayElem = document.getElementById("overlay-dialog")
						if (overlayElem) {
							overlayElem.id = "overlay-dialog-1"
							const markerDiv = document.createElement("div")
							markerDiv.id = "overlay-marker"
							document.body.insertBefore(markerDiv, overlayElem)
							tvPlayerElem.append((overlayElem))
						}
					}
				}

				// MTV-3671: fix black screen at end of playback caused by RecordingsManager (MTV-3398)
				// MTVW-762: RecordingsDialog in full screen for vjs
				//if (import.meta.env.VITE_PLAYER === "vo") {
				const playerRecElem = document.getElementById("player-rec-wrapper")
				const beforeElem = document.getElementById("ql_player_container")
				if (playerRecElem && beforeElem) {
					playerRecElem.id = "player-rec-wrapper-1"
					const markerDiv = document.createElement("div")
					markerDiv.id = "player-rec-marker"
					playerRecElem.parentElement.insertBefore(markerDiv, beforeElem)
					tvPlayerElem.append((playerRecElem))
				}
				//}
			}
			else {
				//console.debug("should be exit")
				if (this.isOnFullScreen()) {
					//console.debug("calling exit")
					// MTVW-733: fix for ultrawidescreen
					if (import.meta.env.VITE_PLAYER === "vo") {
						const videos = document.getElementsByClassName("vop-video")
						if (videos) {
							for (var i = 0; i < videos.length; i++) videos[i].style.height = "100%"
						}
						//if (video) video.style.height = "100%"
					}
					try {
						yield this.exitFullScreen()
					}
					catch (e) { console.error("exitFullScreen", e) }
				}
				if (import.meta.env.VITE_PLAYER === "vjs") {
					/* MTVW-444: commented
					// VJS: now we have to move PlayerControls back to its original place
					const controls = document.getElementById("playerControls-1")
					const markerElem = document.getElementById("playerControls-marker")
					if (controls && markerElem) {
						controls.id = "playerControls"
						//onst markerElem = document.getElementById("playerControls-marker")
						markerElem.parentNode.insertBefore(controls, markerElem)
						markerElem.remove()
					}
					*/
				} else if (import.meta.env.VITE_PLAYER === "vo") {
					if (this.__path === "playerLiveTvVo") {
						// now we need to move the overlay element to its original place
						const overlayElem = document.getElementById("overlay-dialog-1")
						if (overlayElem) {
							overlayElem.id = "overlay-dialog"
							const markerElem = document.getElementById("overlay-marker")
							document.body.insertBefore(overlayElem, markerElem)
							markerElem.remove()
						}
					}
				}
				// MTV-3671: fix black screen at end of playback caused by RecordingsManager (MTV-3398)
				// MTVW-762: RecordingsDialog in full screen for vjs
				//if (import.meta.env.VITE_PLAYER === "vo") {
				const playerRecElem = document.getElementById("player-rec-wrapper-1")
				const beforeElem = document.getElementById("ql_player_container")
				if (playerRecElem && beforeElem) {
					playerRecElem.id = "player-rec-wrapper"
					const markerElem = document.getElementById("player-rec-marker")
					beforeElem.parentElement.insertBefore(playerRecElem, beforeElem)
					markerElem.remove()
				}
				//}
			}
		}
		//setTimeout(() => { this.settings.isFullScreen = bState }, 1000)
		this.settings.isFullScreen = bState
		return this
	})

	get container() {
		//console.debug("got container %o", this.getContainer())
		return this.getContainer()
	}

}

export class PlayerFake extends PlayerAbstract { }
