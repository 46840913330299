// l10n status: done
import React, { useLayoutEffect, useState } from "react";
import { Dialog, FormControl, RadioGroup, FormControlLabel, Radio, Button } from "@mui/material"
import logo from "img/Quickline_logo.svg"
import CallIcon from '@mui/icons-material/Call';
import OpenInNewIcon from '@mui/icons-material//OpenInNew';
import { rootStore } from "store/RootStore"
import { l10n } from "store/lang/L10n"
import "./PinReset.css"

const CONTACT_TYPE = {
	EMAIL: "email",
	PHONE: "sms"
}
const PIN_SENT_STATUS = {
	NOT_SENT: 0,
	SENT: 1,
	ERROR: 2
}

//TO DELETE: t0 and t1 Must be removed, used to display the error message
let t0 = 0
let t1 = 0
export const PinResetDialog = ({ openDialog, closeDialog, email, phone }) => {
	//const [selectedContact, setSelectedContact] = React.useState(email ? email : phone ? phone : null);
	//phone = "41774086184"
	//email = null
	const [selectedContact, setSelectedContact] = React.useState({
		email,
		phone,
		selectedContactType: email ? CONTACT_TYPE.EMAIL : phone ? CONTACT_TYPE.PHONE : null
	});
	const [pinSent, setPinSent] = useState(PIN_SENT_STATUS.NOT_SENT)

	const PinResetTitleText = (pinSent !== PIN_SENT_STATUS.SENT) ? l10n.featurePinRecoveryTitle : l10n.featurePinRecoveryNewPinSentTitle
	const contactText = l10n.featurePinRecoveryForgottenPinDescription
	const contactLink = l10n.featurePinRecoveryForgottenPinNoContactDetailsDescription
	const buttonTextNewPin = `${(pinSent !== PIN_SENT_STATUS.SENT) ? l10n.featurePinRecoveryActionSendNewPin : l10n.featurePinRecoveryActionEnterNewPin}`
	const buttonTextLink = l10n.featurePinRecoveryActionOpenCustomerCentre
	const sentAgainTitleText = l10n.featurePinRecoveryNewPinNotReceivedTitle
	const sentAgainText = l10n.featurePinRecoveryNewPinNotReceivedDescription
	const buttonTextSendAgain = l10n.featurePinRecoveryActionSendPinAgain
	const supportText = l10n.featurePinRecoverySupportHotline
	const pinResetCancelButtonText = l10n.commonActionCancel
	const pinSentText = `${selectedContact.selectedContactType === CONTACT_TYPE.EMAIL ?
		`${l10n.featurePinRecoveryNewPinSentContactMethodEmail.format(email)}.`
		:
		`${l10n.featurePinRecoveryNewPinSentContactMethodSms.format(phone)}.`
		} \n\n${l10n.featurePinRecoveryNewPinSentInstructions}`

	const handleChangeContact = (event) => {
		const selectedContactType = event.target.value
		setSelectedContact({ ...selectedContact, selectedContactType });
	}

	const handleNewPinButton = () => {
		//TO DELETE: the condition below must be deleted used to display the error message
		if (t1 - t0 >= 3000) {
			setPinSent(PIN_SENT_STATUS.ERROR)
			return
		}

		if (pinSent === PIN_SENT_STATUS.SENT) {
			closeDialog()
		} else {
			requestNewPin()
		}
	}
	const requestNewPin = () => {
		if (selectedContact.selectedContactType) {
			console.debug("request new pin with %o", selectedContact)

			rootStore.sso.handleResetPinAsync(selectedContact.selectedContactType).then((rsp) => {
				console.debug("New pin response: %o", rsp)
				setPinSent(PIN_SENT_STATUS.SENT)
			}).catch((e) => {
				console.error("New pin request failed")
				setPinSent(PIN_SENT_STATUS.ERROR)
			})

		} else {
			window.open(l10n.featurePinRecoveryUpdateProfileUrl.format(rootStore.sso?.profile?.id), "_blank")
		}
	}

	useLayoutEffect(() => {
		if (!openDialog) return
		//Reset to the default values.
		setPinSent(PIN_SENT_STATUS.NOT_SENT)
		setSelectedContact({
			email,
			phone,
			selectedContactType: email ? CONTACT_TYPE.EMAIL : phone ? CONTACT_TYPE.PHONE : null
		})
	}, [email, openDialog, phone])

	useLayoutEffect(() => {
		// For a good UX in landscape mode in Mobile: 
		// When the SentPin screen is loaded it scrolls up.
		const element = document.getElementsByClassName("PinResetDialogPaper")[0]
		if (element) element.scrollTo(0, 0)
	}, [pinSent])

	return (
		<Dialog
			id="PinResetDialog"
			classes={{
				root: "PinResetDialogRoot",
				paper: "PinResetDialogPaper"
			}}
			BackdropProps={{
				classes: {
					root: "pinResetBackdropPropsRoot",
				}
			}}
			open={openDialog}
			onClose={() => { closeDialog() }}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			fullScreen={false}
		// disableBackdropClick="true"
		// disableEscapeKeyDown="true"
		>
			<div id="QLogoContainer">
				<img src={logo} className={"quickLineLogo"} alt="Quickline"></img>
			</div>
			<div id="PinResetDialogTitle" className="PinResetDialogTitle">{PinResetTitleText}</div>
			<div id="PinResetDialogText" className="PinResetDialogText">
				{pinSent !== PIN_SENT_STATUS.SENT ?
					selectedContact.selectedContactType ? contactText : contactLink
					:
					pinSentText
				}
			</div>
			{selectedContact.selectedContactType && (pinSent !== PIN_SENT_STATUS.SENT) && <>
				<div id="PinResetDialogContact" className="PinResetDialogContact">
					<FormControl>
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							defaultValue={selectedContact.selectedContactType}
							value={selectedContact.selectedContactType}
							name="radio-buttons-group"
							onChange={handleChangeContact}
						>
							{email && <FormControlLabel value={CONTACT_TYPE.EMAIL}
								control={
									<Radio
										classes={{ root: "PinResetRadioRoot" }}
									/>}
								label={l10n.featurePinRecoveryContactDetailEmail.format(email)}
							/>}
							{phone && <FormControlLabel value={CONTACT_TYPE.PHONE}
								control={
									<Radio
										classes={{ root: "PinResetRadioRoot" }}
									/>}
								label={l10n.featurePinRecoveryContactDetailSms.format(phone)}

							/>}
						</RadioGroup>
					</FormControl>
				</div>
				{pinSent === PIN_SENT_STATUS.ERROR && <div id="pinSentErrorMessageContainer" className="pinSentErrorMessageContainer">
					{l10n.featurePinRecoveryPinChangeFailed}
				</div>}
			</>}
			<div id="PinResetDialogNewPinButton" className={selectedContact.selectedContactType ? "" : "PinResetDialogNewPinButtonLink"} >
				<Button
					disableFocusRipple={true}
					variant="contained"
					classes={{ startIcon: "PinResetNewPinButtonStartIcon" }}
					className={"PinResetDialogNewPinButton"}
					//TO DELETE: onMouseDown, onMouseUp,onMouseUp,onTouchEnd  must be removed,used to display the error message
					onMouseDown={() => {
						t0 = performance.now();
					}}
					onTouchStart={(e) => {
						t0 = performance.now();
					}}
					onMouseUp={() => {
						t1 = performance.now();
					}}
					onTouchEnd={(e) => {
						t1 = performance.now();
						if (t1 - t0 >= 3000) {
							setPinSent(PIN_SENT_STATUS.ERROR)
							return
						}
					}}

					onClick={handleNewPinButton}
					startIcon={selectedContact.selectedContactType ? null : <OpenInNewIcon />}
				>
					{selectedContact.selectedContactType ? buttonTextNewPin : buttonTextLink}
				</Button>
			</div>

			{pinSent === PIN_SENT_STATUS.SENT ?
				<div id="PinResetSendAgainContainer">
					<div id="PinResetSendAgainTitle" className="PinResetDialogTitle">{sentAgainTitleText}</div>
					<div id="PinResetSendAgainText" className="PinResetDialogText">{sentAgainText}</div>
					<div id="PinResetDialogSendAgainButton">
						<Button
							size="medium"
							className={"PinResetDialogSendAgainButton"}
							onClick={requestNewPin}
							data-test="send_pin_again"
						>
							{buttonTextSendAgain}
						</Button>
					</div>
				</div>
				:
				<div id="PinResetDialogCancelButton">
					<Button
						size="medium"
						className={"PinResetDialogCancelButton"}
						onClick={() => { closeDialog() }}
						data-test="cancel_pin_reset"
					>
						{pinResetCancelButtonText}
					</Button>
				</div>
			}
			<div id="PinResetSupportTextContainer" className="PinResetSupportTextContainer">
				<div id="PinResetSupportIcon" className="PinResetSupportIcon"><CallIcon /></div>
				<div id="PinResetSupportText" className="PinResetSupportText">{supportText}</div>
			</div>
		</Dialog>
	)
}
