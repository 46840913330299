import { observable, makeObservable } from "mobx"
import { ClassAbstract } from "store/ClassTools"

export class TraxisModel extends ClassAbstract {
	isReady = false

	Data = new Map()

	DataArr = []

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			isReady: observable,
			Data: observable,
			DataArr: observable
		})
	}

	/**
	 * returns {TraxisModel}
	 */
	static create() {
		return new TraxisModel()
	}

	fetchDataAsync() { }

	fetchData() {
		return this
	}

	fillData() {
		return this
	}

	abortReq() {
		return this
	}
}
