/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-10
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-10
 */

import { ClassAbstract } from "store/ClassTools"
import { observable, computed, makeObservable } from "mobx"
//import { SsoError } from "store/model/sso/SsoError"
//import { TraxisError } from "store/api/TraxisError"

export class ErrorBase extends ClassAbstract {
	e = null
	codes = new Set()
	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			codes: observable,
			message: computed
		})
	}

	/**
	 * @returns {ErrorBase}
	 */
	static CreateError(codes, e, ...paramsConstructor) {
		if (ErrorBase.isInstanceOf(e)) {
			return e
		}
		const self = new ErrorBase(...paramsConstructor)
		self.e = e
		codes.forEach(code => self.codes.add(code))
		return self
	}

	static isInstanceOf(e) {
		// deeply check to avoid some babel problems
		return e instanceof ErrorBase
	}

	static getErrorIfInstanceOf(e) {
		return ErrorBase.isInstanceOf(e) ? e : null
	}

	get httpStatusCode() {
		return this.e?.response?.status ?? null
	}

	handleError() {
		return null
	}

	get message() {
		return Array.from(this.codes.values()).join(", ")
	}

	get isError() {
		return this.codes.size !== 0
	}

	get isErrorPlayer() {
		return this.codes.has("player")
	}

	get isErrorPlayer_DRM() {
		return this.codes.has("drm")
	}
}
