/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-10
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-10
 */

export const tryJsonParse = data => {
	try {
		return JSON.parse(data)
	} catch (e) {
		return null
		/* Ignore */
	}
}
