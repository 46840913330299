/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-08
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-09
 */

//import { SourceAbstract } from "store/page/player/source/SourceAbstract"
//import { SourceTypeList } from "store/page/player/source/SourceTypeList"
import { SourceTypeList, SourceAbstract } from './InternalImports'

export class SourceChannelEmpty extends SourceAbstract {
	type = SourceTypeList.Empty

	isSourceEqual(source) {
		return this.type === source.type
	}
}
