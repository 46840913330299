import { Tab, Tabs } from "@mui/material"
import { withStyles } from "@mui/styles"
import React, { Component } from "react"

const styles = theme => ({
	root: { color: theme.palette.text.primary, flex: 1 },
	moviesMenuTab: { opacity: 0.7 }
})

export const MoviesMenu = withStyles(styles)(
	class extends Component {
		state = { activeIndex: 0 }
		handleChange = (_, activeIndex) => this.setState({ activeIndex })

		render() {
			const { classes } = this.props
			const { activeIndex } = this.state
			return (
				<Tabs centered value={activeIndex} onChange={this.handleChange} className={classes.root}>
					<Tab label="Alle Filme" className={classes.moviesMenuTab} />
					<Tab label="Meine Filme" className={classes.moviesMenuTab} />
					<Tab label="Kinder" className={classes.moviesMenuTab} />
				</Tabs>
			)
		}
	}
)
