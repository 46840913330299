// l10n status: partial
import { Button, Dialog, DialogActions, Fab, FormControl, FormControlLabel, Switch, Typography, Divider } from "@mui/material"
import DialogContent from "@mui/material/DialogContent"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import { CreateComponent } from "components/utils/CreateComponent"
import React, { useState } from "react"

import { rootStore } from "store/RootStore"
import "./recordingComponentsStyles.css"
import { l10n } from "store/lang/L10n"


const style = theme => ({
	// MTV-206 All the following classes override Materil-UI component properties
	RecordingsStartDialogFormControl: {
		display: "flex",
		marginTop: 17,
		paddingRight: 10,
	},
	RecordingsStartDialogPlusMinus: {
		color: "#FFF !important",
		transform: "scale(0.6)",
		backgroundColor: theme.themeSurfaceColor2 + " !important",
		"&:hover": {
			background: theme.themeSurfaceColorHover,
			transform: "scale(0.7)"
		},
	},
	RecordingsStartDialogMinutes: { marginLeft: 4 },
	RecordingsStartDialogInfoLabel: {
		width: 300,
		marginTop: 20,
		//"& span": { fontSize: 12 }
	},
	RecordingsStartDialogActions: { padding: "24px 0px 8px 0px" },
	RecordingsStartDialogRadioLabel: { marginTop: 17, "& span": { fontSize: 16 } },
	RecordingsStartDialogButton: {
		display: "flex",
		justifyContent: "flex-end",
	},
	Divider: {
		marginTop: 17
	}
})

const guardTimes = [0, 2, 5, 10, 15, 60]

export const RecordingsStartDialog = CreateComponent(style, false, true, props => {
	//const [anchorEl, setAnchorEl] = React.useState(null)

	const { classes, onButtonHandlerA, onButtonHandlerB, buttonTextA, buttonTextB, title, additionalInfo, flagSeriesDefaultValue } = props


	const [state, setState] = useState({
		seriesRecording: flagSeriesDefaultValue ?? false,
		hideSeriesRecording: flagSeriesDefaultValue !== null ? { visibility: "hidden" } : { display: "inline-flex" },
		privateRecording: true
	})

	const [guardTimeBefore, setGuardTimeBefore] = useState(5)
	const [guardTimeAfter, setGuardTimeAfter] = useState(5)

	const handleChange = name => event => {
		setState({ ...state, [name]: event.target.checked })
	}

	const setGuardTime = (isUp, isBefore) => {
		const index = guardTimes.indexOf(isBefore === true ? guardTimeBefore : guardTimeAfter)
		if (index > -1) {
			const value = guardTimes[index + (isUp === true ? 1 : -1)]
			if (value !== void 0) {
				if (isBefore === true) {
					setGuardTimeBefore(value)
				} else {
					setGuardTimeAfter(value)
				}
			}
		}
	}

	const prepareTime = time => `PT${time}M`

	return (
		<>
			{/* MTV-2172: disablePortal, stopPropagation */}
			{/* MTV-3063 Recording pop-up window positioned behind the toolbar needs dislayPortal=false if is not on full screen */}
			<Dialog open={true} /*transition */ placement="bottom-end"
				disablePortal={rootStore.page.Player.player.isFullScreen}
				BackdropProps={{ style: { backgroundColor: "rgba(48,48,48,0.8)" } }}
				onClick={(e) => e.stopPropagation()}>
				{/*{({ TransitionProps }) => (
				<Slide {...TransitionProps} timeout={350} direction="up">*/}
				<DialogContent className="RecordingsStartDialog" data-test="hor_window">
					<Typography variant="h6">{title}</Typography>
					{additionalInfo && (
						<Typography variant="body2" classes={{ root: classes.RecordingsStartDialogInfoLabel }}>
							{additionalInfo}
						</Typography>
					)}

					<FormControlLabel
						labelPlacement="start"
						style={state.hideSeriesRecording}
						control={<Switch checked={state.seriesRecording} onChange={handleChange("seriesRecording")} value="seriesRecording" color="primary" data-test="series_recording_flag" />}
						label="Serienaufnahme"
						classes={{ root: classes.RecordingsStartDialogRadioLabel }}
					/>

					<Divider classes={{ root: classes.Divider }} />

					<div>
						<FormControl component="fieldset"
							classes={{ root: classes.RecordingsStartDialogFormControl }} >
							<div className="RecordingsStartDialogWrapper">
								<Typography variant="body2" className="RecordingsStartDialogLabel">
									Vorlaufzeit
								</Typography>
								<div className="RecordingsStartDialogPMGroup">
									<Fab
										size="small"
										aria-label="Remove"
										classes={{ root: classes.RecordingsStartDialogPlusMinus }}
										onClick={(e) => {
											setGuardTime(false, true)
										}}
									>
										<RemoveIcon />
									</Fab>
									<div className="RecordingsStartDialogMinutesWrapper">
										<Typography variant="body2">{guardTimeBefore}</Typography>
										<Typography variant="body2" classes={{ root: classes.RecordingsStartDialogMinutes }}>
											Min.
										</Typography>
									</div>
									<Fab
										size="small"
										aria-label="Add"
										classes={{ root: classes.RecordingsStartDialogPlusMinus }}
										onClick={(e) => {
											setGuardTime(true, true)
										}}
									>
										<AddIcon />
									</Fab>
								</div>
							</div>
						</FormControl>
					</div>

					<div>
						<FormControl component="fieldset" classes={{ root: classes.RecordingsStartDialogFormControl }} >
							<div className="RecordingsStartDialogWrapper">
								<Typography variant="body2" className="RecordingsStartDialogLabel">
									Nachlaufzeit
								</Typography>
								<div className="RecordingsStartDialogPMGroup">
									<Fab
										size="small"
										aria-label="Remove"
										className={classes.RecordingsStartDialogPlusMinus}
										onClick={(e) => {
											setGuardTime(false, false)
										}}
									>
										<RemoveIcon />
									</Fab>
									<div className="RecordingsStartDialogMinutesWrapper">
										<Typography variant="body2">{guardTimeAfter}</Typography>
										<Typography variant="body2" classes={{ root: classes.RecordingsStartDialogMinutes }}>
											Min.
										</Typography>
									</div>
									<Fab
										size="small"
										aria-label="Add"
										className={classes.RecordingsStartDialogPlusMinus}
										onClick={(e) => {
											setGuardTime(true, false)
										}}
									>
										<AddIcon />
									</Fab>
								</div>
							</div>
						</FormControl>
					</div>

					<Divider classes={{ root: classes.Divider }} />

					<FormControlLabel
						labelPlacement="start"
						control={<Switch checked={state.privateRecording} onChange={handleChange("privateRecording")} value="privateRecording" color="primary" data-test="private_recording_flag" />}
						label={l10n.dialogRecordOnlyInThisProfileLabel}
						classes={{ root: classes.RecordingsStartDialogRadioLabel }}
					/>

					<DialogActions classes={{ root: classes.RecordingsStartDialogActions }}>
						{buttonTextA && (
							<Button
								color="primary"
								classes={{ root: classes.RecordingsStartDialogButton }}
								onClick={(e) => {
									onButtonHandlerA({
										...props,
										...{
											recordSeries: state.seriesRecording,
											IsPersonal: state.privateRecording,
											Arguments: {
												GuardTimeStart: prepareTime(guardTimeBefore),
												GuardTimeEnd: prepareTime(guardTimeAfter)
											}
										}
									})
								}}
								data-test="cancel"
							>
								{buttonTextA}
							</Button>
						)}
						{buttonTextB && (
							<Button
								color="primary"
								classes={{ root: classes.RecordingsStartDialogButton }}
								onClick={(e) => {
									onButtonHandlerB({
										...props,
										...{
											recordSeries: state.seriesRecording,
											IsPersonal: state.privateRecording,
											Arguments: {
												GuardTimeStart: prepareTime(guardTimeBefore),
												GuardTimeEnd: prepareTime(guardTimeAfter)
											}
										}
									})
								}}
								data-test="confirm"
							>
								{buttonTextB}
							</Button>
						)}
					</DialogActions>
				</DialogContent>
				{/*</Slide>
			)}*/}
			</Dialog>
		</>
	)
})
