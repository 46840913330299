import { rootStore, ISDEV } from "./"

class AppFeatures {
   // TODO: why does 'readonly' generate an error?
   _gt12Enabled: boolean = false
   _testButtonsEnabled: boolean = true
   _pinResetEnabled: boolean = true

   // MTVW-540
   setGt12EnabledState(enabled: boolean) {
      const persistedState = rootStore?.sso?.browserStore?.gt12State
      if (persistedState !== undefined) this._gt12Enabled = persistedState
      this._gt12Enabled = this._gt12Enabled || enabled
      //console.debug("persisted %s, loginMs %s, result %s", persistedState, enabled, this._gt12Enabled)
	}

   overrideGt12State(enabled: boolean) {
      this._gt12Enabled	= enabled
      rootStore.sso.browserStore.setGt12State(enabled)
	}

   get settingsGt12StateEnabled(): boolean {
      return /*this.gt12Enabled &&*/ ISDEV
   }

   get gt12Enabled(): boolean {
      const persistedState = rootStore?.sso?.browserStore?.gt12State
      //console.debug("persistedState %s, gt12Enabled %s, result %s", persistedState, this._gt12Enabled, persistedState || this._gt12Enabled)
      return persistedState || this._gt12Enabled
   }

   get settingsAdsMockEnabled(): boolean {
      return this.gt12Enabled && ISDEV
   }

   get persistedAdsMock(): boolean {
      return this._gt12Enabled && rootStore.sso.browserStore.adsMock
   }

   set persistedAdsMock(value: boolean) {
      rootStore.sso.browserStore.setAdsMock(value)
   }

   get settingsTestButtonsEnabled(): boolean {
      return this.gt12Enabled && ISDEV
   }

   get persistedTestButtons(): boolean {
      //console.debug("persistedTestButtons", this._gt12Enabled ? rootStore.sso.browserStore.testButtons : false)
      return this._gt12Enabled ? rootStore.sso.browserStore.testButtons : false
   }

   set persistedTestButtons(value: boolean) {
      rootStore.sso.browserStore.setTestButtons(value)
   }

   get settingsAdsTimeoutEnabled(): boolean {
      //console.debug("persistedTestButtons", this._gt12Enabled ? rootStore.sso.browserStore.testButtons : false)
      return this._gt12Enabled && ISDEV
   }

   get persistedAdsTimeout(): number {
      return rootStore.sso.browserStore.adsTimeout
   }

   get isDefaultAdsTimeout(): boolean {
      return rootStore.sso.browserStore.adsTimeout === rootStore.sso.browserStore.DEFAULT_ADSTIMEOUT
   }

   set persistedAdsTimeout(value: number) {
      rootStore.sso.browserStore.setAdsTimeout(value)
   }

   set defaultAdsTimeout(normal: boolean) {
      rootStore.sso.browserStore.setAdsTimeout(normal ? rootStore.sso.browserStore.DEFAULT_ADSTIMEOUT : rootStore.sso.browserStore.HIGH_ADSTIMEOUT)
   }

   // MTVW-593, MTVW-848: NTL
   get settingsNtlEnabled() {
      return ISDEV
   }

   get persistedNtl(): boolean {
      return rootStore.sso.browserStore.ntl
   }

   set persistedNtl(value: boolean) {
      rootStore.sso.browserStore.setNtl(value)
   }

   get settingsOnboardingEnabled(): boolean {
      return this.gt12Enabled && ISDEV
   }

   // for BDD test purpose
   get persistedOnboardingOption() : number {
      return rootStore.sso.browserStore.onboardingOption
   }

   // for BDD test purpose
   set persistedOnboardingOption(value: number) {
      rootStore.sso.browserStore.setOnboardingOption(value)
   }

   get settingsPinResetEnabled(): boolean {
      //return this._pinResetEnabled && ISDEV
      // disabled since v3.2206.01
      return false
   }

   get persistedPinReset(): boolean {
      //return rootStore.sso.browserStore.pinReset
      // enabled since v3.2206.01
      return true
   }

   set persistedPinReset(value: boolean) {
      rootStore.sso.browserStore.setPinReset(value)
   }

   get settingsPlayerLogEnabled(): boolean {
       return ISDEV
   }

   get persistedPlayerLog(): boolean {
      return rootStore.sso.browserStore.playerLog
   }

   set persistedPlayerLog(value: number) {
      rootStore.sso.browserStore.setPlayerLog(value)
   }

}

export const appFeatures = new AppFeatures()
