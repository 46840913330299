import { computed, observable, action, flow, makeObservable } from "mobx"
//import { deepObserve } from "mobx-utils"
import { serializable, serialize } from "serializr"
import { rootStore } from "store/RootStore"
import { localStorage } from "store/util/LocalStorage"
import { ClassAbstract } from "store/ClassTools"
//import { l10n } from "store/lang/L10n"

const STORE_NAME = "store.firstInstall"

class FirstInstallStore extends ClassAbstract {
	showFirstInstall = false
	_firstRun = null
	disableContinue = false

	@serializable profileName = ""
	@serializable pin = ""
	@serializable maskedPin = ""
	@serializable profileAuthentication = false
	@serializable personalizationOptIn = true
	@serializable channelsOptIn = true
	@serializable firstInstallPending = true

	backendError = null
	storedPin = ""
	initDone = false
	// MTVW-405: load new profile after profile creation
	// flags for avoiding intermediate rendering transitions
	profileCreated = false
	creatingProfile = false

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			showFirstInstall: observable,
			_firstRun: observable,
			render: computed,
			isFirstInstall: computed,
			disableContinue: observable,
			profileName: observable,
			setProfileName: action,
			pin: observable,
			setPin: action,
			maskedPin: observable,
			setMaskedPin: action,
			profileAuthentication: observable,
			setProfileAuthentication: action,
			personalizationOptIn: observable,
			setPersonalizationOptIn: action,
			channelsOptIn: observable,
			setChannelsOptIn: action,
			firstInstallPending: observable,
			backendError: observable,
			// MTVW-405: load new profile after profile creation
			profileCreated: observable,
			creatingProfile: observable,
			startNewProfile: action,
			setBackendError: action,
			firstRun: action,
			setDisableContinue: action,
			updateBackend: action,
			initProps: action,
			_init: action
		})
	}

	setProfileName(value) {
		this.profileName = value
	}

	setPin(value) {
		this.pin = value
	}

	setMaskedPin(value) {
		this.maskedPin = value
	}

	setProfileAuthentication(value) {
		this.profileAuthentication = value
	}

	setPersonalizationOptIn(value) {
		this.personalizationOptIn = value
	}

	setChannelsOptIn(value) {
		this.channelsOptIn = value
	}

	setBackendError(value) {
		this.backendError = value
	}

	// MTVW-405: load new profile after profile creation
	startNewProfile(value) {
		this.creatingProfile = value
		this.profileCreated = false
	}

	get render() {
		//console.debug("_firstRun = %s", this._firstRun)
		// wait until firstRun() has been called
		if (this._firstRun === null) return false
		// first run case
		if (this._firstRun) return this.isFirstInstall
		// create profile case
		//return true
		// MTVW-405: flag to avoid intermediate rendering transitions
		return this.creatingProfile
	}

	firstRun(value) {
		//console.debug("set firstRun = %s", value)
		this._firstRun = value
		this.initDone = false
		//this.profileName = this._firstRun ? rootStore.sso?.profile?.Nickname : ""
		this.initProps()
	}

	setDisableContinue(value) {
		this.disableContinue = value
	}

	// eslint-disable-next-line require-yield
	updateBackend = flow(function* () {
		this.backendError = null
		// turn on spinner
		rootStore.isLoading = true
		let result = null

		// MTVW-157
		const profileSettings = {
			name: this.profileName,
			pinCode: this.pin,
			//isLoginPinProtected: this.profileAuthentication.toString(),
			isLoginPinProtected: this.profileAuthentication.toString(),
			//personalizedRecommendationsEnabled: this.personalizationOptIn,
			// MTVW-627: not used anymore, can be removed
			personalizedRecommendationsEnabled: this.personalizationOptIn.toString(),
			// TODO? recordingsOptIn currently still with traxis
			// recordingsOptIn, true,
			//isFirstInstallationDone: true
			isFirstInstallationDone: "true"
		}
		const profileCreate = {
			name: this.profileName,
			pinCode: this.pin,
			isLoginPinProtected: this.profileAuthentication,
			// MTVW-627: not used anymore, can be removed
			personalizedRecommendationsEnabled: this.personalizationOptIn,
			// TODO? recordingsOptIn currently still with traxis
			// recordingsOptIn, true,
			isFirstInstallationDone: true
		}
		//try {
		//console.debug("PATCHING %s", rootStore.sso.pin)
		// TODO: there is some weird behavior when calling rootStore.sso and rootStore.sso.profile methods with yield:
		// the component gets unmounted for a moment (especially when we want to apply an error message) and then mounted again
		// Workaround remove yield and use promise.then()
		if (this._firstRun) {
			result = rootStore.sso.profile.updateProfileDetailsAsync(profileSettings, rootStore.sso.pin)
		}
		else {
			result = rootStore.sso.profile.createProfileAsync(profileCreate, rootStore.sso.pin)
		}
		result.then((res) => {
			// MTVW-157 / QLMS-366: potentially replace with profile property
			// MTVW-728: Use channelsMs -> optInAllChannels
			/*
			if (this._firstRun && this.channelsOptIn) {
				rootStore.api.AddChannelsToRecordingOptInChannelList().fetchDataAsync().then(res => {
					rootStore.api.GetCustomer().fetchData()
				})
			}
			if (this._firstRun && !this.channelsOptIn) {
				const Customer = rootStore.singleton.customer.Data
				rootStore.api.DeleteChannelsFromRecordingOptInChannelList().fetchDataAsync().then(res => {
					rootStore.api.ClearRecordingOptInDate({ customerId: Customer._$Aliases.get("IngestId").Value, resourceId: Customer.id }).fetchDataAsync().then(res => {
						rootStore.api.GetCustomer().fetchData()
					})
				})
			}
			*/
			if (this._firstRun && this.channelsOptIn) {
				rootStore.page.MsEpg.getChannelsArrayAsync().then(res => {
					//console.debug("channelsArray", res)
					rootStore.channelsService.replayOptIn(rootStore?.sso?.contractId, res).then(result => {
						// needs admin rights
						//rootStore.channelsService.replayOptOut(rootStore?.sso?.contractId).then(result => {
						rootStore.api.GetCustomer().fetchData()
						console.debug("replayOptIn", result.status, rootStore.singleton?.customer?.Data?.RecordingOptInDate)
					}).catch(error => {
						console.error("firstInstall optInAllChannels", error)
						this.backendError = error
						//rootStore.page.Player.error = error
						/*
						infoMessage = {
							title: error.title,
							content: error.detail,
							button1: l10n.commonActionDelete, // TODO to verify
						}
						setShowModalMessage(true)
						*/
					})
				})
			}
			if (this._firstRun && !this.channelsOptIn) {
				console.debug("no optIn")
			}

			// reload changed profile (without yield, see note above)
			rootStore.sso.profile?.getProfile?.()
			rootStore.sso.getLoginProfiles()
		}).catch((error) => {
			console.error("ERROR in FirstInstallStore %o", error)
			this.backendError = error
			// gets handled in FirstInstall
			//throw error
		})
		/*}
			catch(error) {
			// TODO: proper error handling
			console.debug("CAUGHT: %o", error)
			this.backendError = error
			// clear pin in local storage
			//rootStore.sso.setPin("")
			this.firstInstallPending = false
			localStorage.set("store.firstInstall", serialize(this))
			// turn off spinner
			rootStore.isLoading = false
			//rootStore.sso.handleLogOffAsync()
		} */

		if (this.backendError === null) {
			this.firstInstallPending = false
			this.saveStorage()
			// remember pin in local storage
			rootStore.sso.setPin(this.pin)
			// MTVW-405: load new profile after profile creation
			if (!this._firstRun) this.profileCreated = true
		}
		// turn off spinner
		rootStore.isLoading = false
		this.removeStorage()
		return result
	})

	/**
	 * @returns {isFirstInstall}
	 */
	get isFirstInstall() {
		if (!this.firstInstallPending) {
			return false
		}
		rootStore.api.GetCustomer().fetchData()
		/*
		console.log("isFirstInstall " + rootStore.singleton.profile.isReady + " " + new Date().getTime())
		if (!rootStore.singleton.profile.isReady) {
			// do not continue until profile loaded
			this.showFirstInstall = false
			return true
		}
		else {*/
		this.initProps()
		//this.showFirstInstall = true
		// MTVW-157 / QLMS-399: pin from local storage
		this.storedPin = rootStore.sso.pin
		if (!this.showFirstInstall && localStorage.has(STORE_NAME)) {
			this.removeStorage()
		}
		return this.showFirstInstall
		//}
	}

	initProps() {
		//console.debug("current profile %o, showFirstInstall %s", rootStore.sso.profile, !rootStore.sso?.profile?.FirstInstallationDone)
		if (!this.initDone) {
			// we use a singleton, re-init
			this._firstRunprofileName = ""
			this.pin = ""
			this.maskedPin = ""
			this.profileAuthentication = false
			this.personalizationOptIn = true
			this.channelsOptIn = true

			// ✅ from loginService
			this.showFirstInstall = !rootStore.sso?.profile?.FirstInstallationDone
			// ✅ from loginService
			this.profileName = (this._firstRun) ? rootStore.sso.profile.Nickname : ""
			this.pin = ""

			if (localStorage.has(STORE_NAME)) {
				const store = localStorage.get(STORE_NAME)
				if (store.firstInstallPending) {
					this.profileName = store.profileName ? store.profileName : ""
					this.pin = store.pin ? store.pin : ""
					this.maskedPin = store.maskedPin ? store.maskedPin : ""
					this.profileAuthentication = store.profileAuthentication ? store.profileAuthentication : false
					this.personalizationOptIn = store.personalizationOptIn ? store.personalizationOptIn : true
					this.channelsOptIn = store.channelsOptIn ? store.channelsOptIn : true

					this.showFirstInstall = true
				}
				if (!this._firstRun) this.firstInstallPending = false
			}
			this.initDone = true
		}
	}

	// time of modification or creation
	//time = rootStore.time.getTimeStamp()

	_init() {
		this.initDone = false
		this.firstInstallPending = true
	}
	//deepObserve(this, () => this.saveStorage())

	saveStorage() {
		console.log("FirstInstallStore.saveStorage()")
		//this.time = rootStore.time.getTimeStamp()

		localStorage.set("store.firstInstall", serialize(this))
	}

	removeStorage() {
		localStorage.remove(STORE_NAME)
	}
}

// main instance

export const firstInstallStore = new FirstInstallStore(null, "firstInstall")
firstInstallStore._init()
