export const moviesslider = [
	{
		poster: "https://poster-api.quickline.ch/PosterISVOD/CrazyRich_homescreens-00a98907-1012-4081-83c8-c88519ee28e8.jpg?mode=box&w=844",
		title: "Crazy Rich",
		url: "https://content.quickline.ch/QuicklineWeb/#/app/vod/movie/detail/crid:~~2F~~2Fschange.com~~2Fhomedia.ch~~2FHOMEDIA_83334"
	},
	{
		poster: "https://poster-api.quickline.ch/PosterISVOD/DieUnglaublichen2_homescreens-0e703e02-1c82-49ae-a96c-f629bba135b0.jpg?mode=box&w=2480&h=698",
		title: "Die Unglaublichen 2",
		url: "https://content.quickline.ch/QuicklineWeb/#/app/vod/movie/detail/crid:~~2F~~2Fschange.com~~2Fhomedia.ch~~2FHOMEDIA_80941"
	},
	{
		poster: "https://poster-api.quickline.ch/PosterISVOD/NightSchool_homescreens-d7007c9f-d466-4dce-b6b4-a2876c676ed8.jpg?mode=box&w=2480&h=698",
		title: "Night School",
		url: "https://content.quickline.ch/QuicklineWeb/#/app/vod/movie/detail/crid:~~2F~~2Fschange.com~~2Fhomedia.ch~~2FHOMEDIA_80941"
	},
	{
		poster: "https://poster-api.quickline.ch/PosterISVOD/Backtrace_homescreens-36201930-8aba-4f4b-811e-099d4ba61c7a.jpg?mode=box&w=2480&h=698",
		title: "Backtrace",
		url: "https://content.quickline.ch/QuicklineWeb/#/app/vod/movie/detail/crid:~~2F~~2Fschange.com~~2Fhomedia.ch~~2FHOMEDIA_84015"
	}
]
