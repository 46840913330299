/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-09
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-10
 */

import { isAlive, resolveIdentifier, types as t } from "mobx-state-tree"
import { rootStore } from "store/RootStore"

export const Product = t.model("Product", {
	id: t.identifier,
	EntitlementState: t.string,
	Type: t.string,
	Aliases: t.model({
		Alias: t.array(
			t.model({
				Value: t.string,
				type: t.string,
				organization: t.string,
				authority: t.string,
				encoding: t.string
			})
		)
	}),
	Name: t.string,
	Currency: t.string,
	ListPrice: t.string,
	OfferPrice: t.model({
		Offer: t.array(
			t.model({
				Value: t.string,
				id: t.string,
				originalOfferPrice: t.string,
				pricePromotionName: t.string,
				personalized: t.string
			})
		)
	}),
	EntitlementEnd: t.string
})

export const Products = t
	.model("Products", {
		resultCount: t.number,
		Product: t.array(Product),
		isReady: t.maybeNull(t.boolean)
	})
	.views(self => ({
		getById(id) {
			return isAlive(self) ? resolveIdentifier(Product, self.Product, id) : null
		}

		// filter(callbackFn) {
		// 	return self.CustomProperty.filter(callbackFn)
		// },
		// find(callbackFn) {
		// 	return self.CustomProperty.find(callbackFn)
		// },
		// filterByHref(hrefFn) {
		// 	return self.CustomProperty.filter(i => hrefFn(i.href) === true)
		// },
		// findByHref(hrefFn) {
		// 	return self.CustomProperty.find(i => hrefFn(i.href) === true)
		// },
		// getByHref(hrefFn) {
		// 	const property = self.CustomProperty.find(i => hrefFn(i.href) === true)
		// 	if (property === undefined) {
		// 		return null
		// 	}
		// 	return property.Value
		// }
	}))
	// added for mobx 6.3 which is more strict
	.actions(self => ({
		setReady(value) {
			self.isReady = value
		}
	}))

export const ProductsRef = Products.named("ProductsRef").props({
	Product: t.array(
		t.snapshotProcessor(
			t.reference(Product, {
				get(identifier) {
					return rootStore.singleton.productsResolveIdentifier(identifier)
				},
				set(value) {
					return value.id
				}
			}),
			{
				preProcessor(i) {
					return i.id
				},
				postProcessor(i) {
					return { id: i }
				}
			}
		)
	)
})
