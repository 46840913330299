import { types as t } from "mobx-state-tree"

export const CustomProperty = t
	.model("CustomProperty", {
		Value: t.maybeNull(t.string),
		href: t.identifier
	})
	.views(self => ({
		get k() {
			return self.href
		},
		get v() {
			return self.Value
		},
		get isTrue() {
			return self.Value === "true" || self.Value === true
		}
	}))

export const CustomProperties = t
	.model("CustomPropertiesHref", {
		CustomProperty: t.array(CustomProperty)
	})
	.views(self => ({
		filter(callbackFn) {
			return self.CustomProperty.filter(callbackFn)
		},
		find(callbackFn) {
			return self.CustomProperty.find(callbackFn)
		},
		filterByHref(hrefFn) {
			return self.CustomProperty.filter(i => hrefFn(i.href) === true)
		},
		findByHref(hrefFn) {
			return self.CustomProperty.find(i => hrefFn(i.href) === true)
		},
		getByHref(hrefFn) {
			const property = self.CustomProperty.find(i => hrefFn(i.href) === true)
			if (property === undefined) {
				return null
			}
			return property.Value
		},
		get Props() {
			let t = {}
			self.CustomProperty.forEach(i => {
				t[i.k] = i.v
			})
			return t
		}
	}))
