// l10n status: done
import { Button, Dialog, DialogActions } from "@mui/material"
import DialogContent from "@mui/material/DialogContent"
import React from "react"
import { l10n } from "store/lang/L10n"

import { rootStore } from "store/RootStore"
//import "./recordingComponentsStyles.css"
import "./skipAdModal.css"

export const infoMsgSkipAd = {
	ALLOWED: "allowed",
	UNAVAILABLE: "unavailable",
	NOT_ALLOWED: "notAllowed"
}

export const SkipAdDialog = ({ open, close, adSkipping }) => {
	return (
		<Dialog open={open}
			placement="bottom-end"
			disablePortal={rootStore.page.Player.player.isFullScreen}
			BackdropProps={{ style: { backgroundColor: "rgba(48,48,48,0.8)" } }}
			onClose={close}
			onClick={(e) => e.stopPropagation()}>
			<DialogContent className="SkipAdModalDialogContent" data-test="SkipAd_container">
				<div className="skipAdTitle">{adSkipping?.adButtonTitle}</div>
				{/* text with newline(s) */}
				<div className="skipAdMessage">{adSkipping?.adButtonText} </div>
				<DialogActions classes={{ root: "SkipAdDialogActions" }}>
					{/* MTVW-459
					{adSkipping?.adSkipping === infoMsgSkipAd.NOT_ALLOWED && <Button
					*/}
					<Button
						color="primary"
						classes={{ root: "SkipAdDialogButtonLater" }}
						data-test="cancel"
						onClick={(e) => {
							e.stopPropagation()
							close()
						}}
					>
						{l10n.featureUpsellGt12ButtonLaterTitle}
					</Button>

					<Button
						color="primary"
						// MTVW-459
						//classes={adSkipping?.adSkipping === infoMsgSkipAd.NOT_ALLOWED ? { root: "SkipAdDialogButtonUpgrade" } :{ root: "SkipAdDialogButtonOk" }}
						classes={{ root: "SkipAdDialogButtonUpgrade" }}
						data-test="confirm"
						onClick={(e) => {
							e.stopPropagation()
							// MTVW-459
							// if (adSkipping?.adSkipping === infoMsgSkipAd.NOT_ALLOWED) window.open(import.meta.env.VITE_URL_COCKPIT_ORDER + rootStore.sso?.profile?.id, "_blank")
							window.open(l10n.featureUpsellGt12Link)
							close()
						}}
					>
						{/* MTVW-459 */}
						{/* {adSkipping?.adSkipping === infoMsgSkipAd.NOT_ALLOWED ? "Upgrade" : "OK"} */}
						{l10n.featureUpsellGt12ButtonTitle}
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>

	)
}
