/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-09
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-09
 */

import { action, observable, makeObservable } from "mobx"
import { ClassAbstract } from "store/ClassTools"

export class TraxisModelAbstract extends ClassAbstract {
	isReady = false

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			isReady: observable,
			setReady: action
		})
	}

	get _api() {
		return this.__parent
	}

	// added for mobx 6.3 which is more strict
	setReady(value) {
		this.isReady = value
	}
}
