import { types as t, flow as flowMst } from "mobx-state-tree"
import localForage from "localforage"
import { flow } from "mobx"
import { applyDataToObject } from "store/ModelTools"

export const TraxisCacheStore = localForage.createInstance({
	name: import.meta.env.VITE_CONF_TRAXIS_CACHE_NAME,
	driver: [localForage.INDEXEDDB, localForage.WEBSQL]
})

export class TraxisCacheItem {
	id
	validUntil
	staleTime
	respHash
	profileId

	respData

	isValid(currTime) { }

	fetchCacheContent = flow(function* (cacheAdapter) {
		this.respData = yield cacheAdapter.getItem("^" + this.respHash)
	})

	applyData(data) {
		applyDataToObject(this, data)
		return this
	}

	getJson() {
		return JSON.stringify({ ...this, respData: null })
	}
}

export const TraxisCacheItem2 = t
	.model("TraxisCacheItem", {
		id: t.identifier,
		validUntil: t.number,
		staleTime: t.maybeNull(t.number),
		respHash: t.string,
		profileId: t.maybeNull(t.string)
	})
	.volatile(self => ({
		respData: null
	}))
	.views(self => ({
		isValid(currTime) { }
	}))
	.actions(self => ({
		fetchCacheContent: flowMst(function* (cacheAdapter) {
			self.respData = yield cacheAdapter.getItem("^" + self.respHash)
		})
	}))
