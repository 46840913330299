import React, { useEffect, useRef, useState } from "react"
import { Button } from "@mui/material"
import "./SearchComponents.css"
import "./../SearchStyle.css"
import goBack from "img/icons/search/arrow_back_ios_white_36dp.svg"
import goForward from "img/icons/search/arrow_forward_ios_white_36dp.svg"


export const FilterBar = ({ page, filterType }) => {
	const [scrollArrow, setScrollArrows] = useState({
		leftArrow: false,
		rightArrow: false,
	})
	const [filterIsOverflowing, setFilterIsOverflowing] = useState(false)
	const refFilterBar = useRef()

	let _filterOrigin = null
	let _filterSelected = null

	if (filterType === page.CATEGORY_ACTORS) {
		_filterOrigin = page?.actor?.person?.genres
		_filterSelected = page.genresSelected
	} else if (filterType === page.CATEGORY_GENRES) {
		_filterOrigin = page?.genre?.genre?.related
		_filterSelected = page.genresSelected
	}
	else if (filterType === page.CATEGORY_SERIES) {
		_filterOrigin = page?.series?.seasons
		_filterSelected = page.seasonSelected
	}

	let _filter = []
	const handleResize = () => {
		const overFlow = refFilterBar?.current?.clientWidth < refFilterBar?.current?.scrollWidth
		setFilterIsOverflowing(overFlow)
		// MTVW-547: update arrow states
		setScrollArrows({
			leftArrow: overFlow ? refFilterBar?.current?.scrollLeft > 0 : false,
			rightArrow: overFlow ? refFilterBar?.current?.scrollLeft === 0 : false
		})
	}

	useEffect(() => {
		if (refFilterBar?.current) {
			refFilterBar.current.scrollLeft = 0
			setScrollArrows({
				leftArrow: filterIsOverflowing ? refFilterBar?.current?.scrollLeft > 0 : false,
				rightArrow: filterIsOverflowing ? refFilterBar?.current?.scrollLeft === 0 : false
			})
		}
	}, [filterIsOverflowing])

	//TO Verify  if this useEffect is necessary
	useEffect(() => {
		setFilterIsOverflowing(refFilterBar?.current?.clientWidth < refFilterBar?.current?.scrollWidth)
		// eslint-disable-next-line react-hooks/exhaustive-deps
		if (filterType === page.CATEGORY_ACTORS || filterType === page.CATEGORY_GENRES) {
			if (page.genresSelected !== page.ALL_GENRES_SELECTED && refFilterBar?.current && page.scrollposSelected) {
				refFilterBar.current.scrollLeft = page.scrollposSelected
				//page.setScrollposSelected(null)
			}
		} else if (filterType === page.CATEGORY_SERIES) {
			if (page.seasonSelected !== page.ALL_SEASON_SELECTED && refFilterBar?.current && page.scrollposSelected) {
				refFilterBar.current.scrollLeft = page.scrollposSelected
				//page.setScrollposSelected(null)
			}
		}
	}, [refFilterBar?.current?.scrollWidth])

	useEffect(() => {
		handleResize()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_filterOrigin])

	useEffect(() => {
		window.addEventListener("resize", handleResize)
		return (() => {
			window.removeEventListener("resize", handleResize) // when unload
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// MTVW-647: show 'Alle' if there is only 1 genre
	if (_filterOrigin?.length > 0) {
		if (filterType === page.CATEGORY_ACTORS || filterType === page.CATEGORY_GENRES) {
			_filter.push(page.ALL_GENRES_SELECTED)
		} else if (filterType === page.CATEGORY_SERIES) {
			_filter.push(page.ALL_SEASON_SELECTED)
		}

		// MTVW-647: show only 'Alle' if there is only 1 genre
		if (_filterOrigin?.length > 1) {
			// eslint-disable-next-line no-unused-expressions, array-callback-return
			_filterOrigin?.map(item => {
				if (filterType === page.CATEGORY_ACTORS || filterType === page.CATEGORY_GENRES) {
					//console.debug("push", item)
					_filter.push(`${item}`)
				} else if (filterType === page.CATEGORY_SERIES) {
					_filter.push(`${item.number}`)
				}
			})
		}

		//console.debug("_filter is", _filter, _filterOrigin, refFilterBar?.current?.clientWidth, refFilterBar?.current?.scrollWidth)

		return (
			<div className="SearchFilterBarContainer">
				<img className={`SearchFilterBarArrow ${!scrollArrow.leftArrow ? 'SearchFilterBarArrowDisabled' : ''}`} src={goBack} alt=" icon"
					onClick={(e) => {
						// MTVW-547:
						//refFilterBar.current.scrollLeft = 0
						let scrollPos = refFilterBar?.current?.scrollLeft - refFilterBar?.current?.clientWidth / 2
						if (scrollPos < 0) scrollPos = 0
						refFilterBar.current.scrollLeft = scrollPos
						setScrollArrows({ leftArrow: scrollPos === 0 ? false : true, rightArrow: true })
					}}
				></img>

				<div className="filterGenresBar" ref={refFilterBar}
					onScroll={() => {
						const maxScrollLeft = Math.floor(refFilterBar?.current?.scrollWidth - refFilterBar?.current?.clientWidth) - 2
						//console.debug("scroll refSearchFilter.current.scrollLeft =%s --- max=%s", refFilterBar.current?.scrollLeft, maxScrollLeft)
						if (refFilterBar?.current?.scrollLeft === 0) {
							setScrollArrows({ leftArrow: false, rightArrow: true })
						} else if (refFilterBar?.current?.scrollLeft > maxScrollLeft) {
							setScrollArrows({ leftArrow: true, rightArrow: false })
						} else {
							setScrollArrows({ leftArrow: true, rightArrow: true })
						}
					}}
				>
					{
						_filter.map((item, index) => {
							const rootSearchFilterButton = _filterSelected === item ? 'searchFilterButtons searchFilterButtonSelected' : 'searchFilterButtons'
							const widthLimiter = (item, style = {}) => {
								//console.debug("limiter", item, parseInt(item))
								return !isNaN(parseInt(item)) ? { maxWidth: 42, color: "#FFF" } : style
							}
							return (
								<Button variant="outlined" style={widthLimiter(item, { color: "#FFF" })}
									onClick={() => {
										page.setFilterEventType(page.FILTER_EVENT_LIVE_REPLAY)
										if (filterType === page.CATEGORY_ACTORS || filterType === page.CATEGORY_GENRES) {
											page.setGenresSelected(item)
											page.setScrollposSelected(refFilterBar?.current?.scrollLeft)
										} else if (filterType === page.CATEGORY_SERIES) {
											page.setSeasonSelected(item)
											page.setScrollposSelected(refFilterBar?.current?.scrollLeft)
										}
										//rootStore.page.OverlayEventDetails.setActivityBackItem(null)
									}}
									classes={{ root: `${rootSearchFilterButton}` }}
									key={`season ${item}`}
								>{item}</Button>
							)
						})
					}
				</div>
				<img className={`SearchFilterBarArrow ${!scrollArrow.rightArrow ? 'SearchFilterBarArrowDisabled' : ''}`} src={goForward} alt=" icon"
					onClick={(e) => {
						// MTVW-547:
						//refFilterBar.current.scrollLeft = refFilterBar.current.scrollWidth
						let scrollPos = refFilterBar?.current?.scrollLeft + refFilterBar?.current?.clientWidth / 2
						if (scrollPos > refFilterBar?.current?.scrollWidth) scrollPos = refFilterBar?.current?.scrollWidth
						refFilterBar.current.scrollLeft = scrollPos
						setScrollArrows({ leftArrow: true, rightArrow: scrollPos === refFilterBar?.current?.scrollWidth ? false : true })
					}}
				></img>
			</div>
		)
	} else {
		return null
	}
}
