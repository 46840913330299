import { Tab, Tabs } from "@mui/material"
import { CreateComponent } from "components/utils/CreateComponent"
import { useRoutePropsUi, useRouteStore } from "components/utils/RouteUtils"
import React from "react"
import { useLocation } from "react-router"

export const RouteTab = ({ value, ...props }) => {
	const routePropsUi = useRoutePropsUi()
	// MTVW-745: disableFocusRipple
	return <Tab disableFocusRipple={true} {...props} {...routePropsUi(value)} />
}

export const RouteTabs = CreateComponent(null, false, true, ({ routeCustomName, ...props }) => {
	const routeStore = useRouteStore()
	const routeMatch = useLocation()
	return <Tabs {...props} value={routeStore.getVar(routeMatch.pathname, routeCustomName, routeMatch.name)} />
})
