/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-02
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-09
 */

import * as ls from "store2"

export const localStorage = import.meta.env.VITE_CONF_LOCALSTORAGE_NAMESPACE ? ls.namespace(import.meta.env.VITE_CONF_LOCALSTORAGE_NAMESPACE) : ls
