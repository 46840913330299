import { flow /*, observable, computed, action */ } from "mobx"
import { rootStore } from "store/RootStore"
import { TraxisCpeIdMixin } from "store/api/mixin/TraxisCpeIdMixin"
// MTVW-585: 1 retry
import { fetchWithRetry, DEFAULT_RETRIES, DEFAULT_TIMEOUT } from "./FetchWithRetry"
import { alias, list, object, /*createModelSchema, getDefaultModelSchema,*/ serializable, deserialize, primitive } from "serializr"
import { moment } from "store/util/moment"
import { urlFixHttpProtocolMismatch } from "store/util/urlFixHttpProtocolMismatch"
import { serviceUrls } from "store/qlapi/ServiceUrls"
import { iso6391 } from "store/util/iso6391"
import { stopWatch } from "utils/TestSupport"
import { getQlHeaders } from "store/qlapi/QlHeaders"
import { l10n } from "store/lang/L10n"

/* Moved to ServiceUrls
export const BASE_EPG_URL = "https://sg1-epg.qltv.quickline.ch/epg/v009/"
*/
const nDash = '\u2013'

class Event {
	@serializable id = null
	@serializable(alias("channel_id", primitive())) channelId = null
	@serializable(alias("content_id", primitive())) contentId = null
	@serializable start = null
	@serializable end = null
	@serializable(alias("duration", primitive())) duration = null
	@serializable(alias("is_hd", primitive())) isHd = null
	// MTVW-508
	@serializable(alias("start_millis", primitive())) AvailabilityStartTs = null
	@serializable(alias("end_millis", primitive())) AvailabilityEndTs = null

	fake = false

	get dateAndTime() {
		return moment(this.start).format(`dd. DD.MM.YYYY, HH:mm`) + nDash + moment(this.end).format(`HH:mm`)
	}
	get eventTime() {
		return moment(this.start).format(`HH:mm`) + nDash + moment(this.end).format(`HH:mm`)

	}
	get eventType() {
		// MTVW-508
		/*
		const timeCurr = rootStore.time.getTimeStampTick(60),
			timeStart = moment(this.start).utc().valueOf(),
			timeEnd = moment(this.end).utc().valueOf()
		//console.debug("timeCurr %s, timeStart %s, timeEnd %s, %s", timeCurr, timeStart, timeEnd, timeStart <= timeCurr && timeEnd > timeCurr ? "c" : timeStart >= timeCurr ? "n" : "p")
		return timeStart <= timeCurr && timeEnd > timeCurr ? "c" : timeStart >= timeCurr ? "n" : "p"
		*/
		const timeCurr = moment().utc().valueOf()
		return this.AvailabilityStartTs <= timeCurr && this.AvailabilityEndTs > timeCurr ? "c" : this.AvailabilityStartTs >= timeCurr ? "n" : "p"
	}

	get durationInPercent() {
		if (this.eventType !== "c") return 0
		const timeCurr = rootStore.time.getTimeStampTick(60),
			timeStart = moment(this.start).utc().valueOf(),
			timeEnd = moment(this.end).utc().valueOf()
		return parseFloat((100 * (timeCurr - timeStart) / (timeEnd - timeStart)).toFixed(2))
	}
}

class Content {
	@serializable(alias("id", primitive())) contentId = null
	@serializable(alias("title", primitive())) Title = null
	@serializable rating = null
	@serializable description = null
	@serializable synopsis = null
	@serializable(alias("poster_url", primitive())) _posterUrl = null
	@serializable(alias("series_name", primitive())) SeriesName = null
	@serializable(alias("series_id", primitive())) SeriesId = null
	@serializable(alias("episode_name", primitive())) EpisodeName = null
	// only for programs/now
	@serializable(alias("season", primitive())) Season = null
	// only for programs/now
	@serializable(alias("episode", primitive())) Episode = null
	@serializable(alias("duration", primitive())) duration = null
	@serializable(list()) genres = []
	@serializable language = null
	@serializable(alias("language_id", primitive())) languageId = null
	@serializable(list()) actors = []
	@serializable(list()) writers = []
	@serializable(list()) directors = []
	@serializable(alias("production_year", primitive())) productionYear = null
	@serializable(alias("production_locations", list())) roductionLocations = null
	@serializable(alias("production_location_ids", list())) roductionLocationIds = null
	@serializable(alias("series_container_id", primitive())) seriesContainerId = null
	@serializable(alias("series_collection_id", primitive())) seriesCollectionId = null
	@serializable(alias("series_recording_id", primitive())) seriesRecordingId = null
	@serializable(alias("season_id", primitive())) primitive = null
	@serializable(alias("program_type", primitive())) programType = null
	@serializable(alias("series_type", primitive())) seriesType = null

	// make sure to return secure url
	get posterUrl() {
		return urlFixHttpProtocolMismatch(this._posterUrl)
	}
}

class EventWithContent {
	@serializable(object(Event)) event
	@serializable(object(Content)) content
}

/* Use Channels MS
class Channel {
	@serializable id = null
	@serializable(alias("name", primitive())) Name = null
	@serializable(alias("is_adult", primitive())) IsAdult = null
	// is_hd is supported in the channels
	@serializable(alias("is_hd", primitive())) IsHd = null
	@serializable(alias("logo_url", primitive())) logoUrl = null
	@serializable(alias("channel_number", primitive())) LogicalChannelNumber = null
	@serializable(alias("replay_into_past", primitive())) ReplayIntoPast = null
	// TODO: Needed? Not returned by MS
	IsOnRecordingOptinList = true
}
*/

class Events {
	@serializable(object(Event)) event
	@serializable(object(Content)) content

	get id() {
		return this.event?.id
	}

	get channelId() {
		return this.event?.channelId
	}

	get AvailabilityDurationInMinutes() {
		// GT12 MTVW-487: seconds precision
		//return moment.duration(this.AvailabilityEndDate.diff(this.AvailabilityStartDate)).asMinutes()
		//return Math.round(moment.duration(this.AvailabilityEndDate - this.AvailabilityStartDate).asMinutes())
		return Math.trunc(this.AvailabilityEndDate / 60000) - Math.trunc(this.AvailabilityStartDate / 60000)
		// content.duration is not reliable
		//return moment.duration(this.content?.duration).asMinutes()
	}

	get AvailabilityDurationInMinutesPrecise() {
		return Math.round(moment.duration(this.AvailabilityEndDate - this.AvailabilityStartDate).asMinutes())
	}

	get AvailabilityEndDate() {
		return moment(this.event?.AvailabilityEndTs)
	}

	AvailabilityEndDateFormat(format) {
		//return moment(this.event?.AvailabilityEnd).format(format)
		return moment(this.AvailabilityEndDateTs).format(format)
	}

	get AvailabilityEndDateTs() {
		return this.event?.AvailabilityEndTs
	}

	get AvailabilityStartDate() {
		return moment(this.event?.AvailabilityStartTs)
	}

	AvailabilityStartDateFormat(format) {
		//return moment(this.event?.AvailabilityStart).format(format)
		return moment(this.AvailabilityStartDateTs).format(format)
	}

	get AvailabilityStartDateTs() {
		return this.event?.AvailabilityStartTs
	}

	get CurrentDurationInMinutes() {
		//console.debug("CurrentDurationInMinutes %o", moment(rootStore.time.getTimeStampTick(30)))
		return moment.duration(moment(rootStore.time.getTimeStampTick(30)).diff(this.AvailabilityStartDate)).asMinutes()
	}

	get DurationInMinutes() {
		//return moment.duration(this.content?.duration).asMinutes()
		return this.AvailabilityDurationInMinutes
	}

	get CurrentDurationToStartInMinutes() {
		return Math.floor(moment.duration(moment(this.AvailabilityStartDate).diff(rootStore.time.getTimeStampTick(60))).asMinutes())
	}

	get CurrentDurationInPercents() {
		//console.debug("CurrentDurationInPercents %s, %s, %s", this.AvailabilityDurationInMinutes, this.CurrentDurationInMinutes, this.AvailabilityDurationInMinutes)
		if (this.AvailabilityDurationInMinutesPrecise) {
			return (this.CurrentDurationInMinutes / this.AvailabilityDurationInMinutesPrecise) * 100
			//return (this.DurationInMinutes / this.AvailabilityDurationInMinutes) * 100
		}
		return 0
	}

	isTimeInEventAvailability(time) {
		return this.AvailabilityStartDate.isSameOrBefore(time) && this.AvailabilityEndDate.isAfter(time)
	}

	get isCurrentEvent() {
		return this.getCurrentEventType === "c"
	}

	get getCurrentEventType() {
		//const timeCurr = Math.floor(moment().utc().valueOf() / 1000 / 60) * 1000 * 60,
		const timeCurr = rootStore.time.getTimeStampTick(60),
			timeStart = this.AvailabilityStartDateTs,
			timeEnd = this.AvailabilityEndDateTs
		return timeStart <= timeCurr && timeEnd > timeCurr ? "c" : timeStart >= timeCurr ? "n" : "p"
	}

	get contentId() {
		return this.content?.contentId
	}

	get Title() {
		//return this.content?.Title
		// MTVW-246: For compatibility with traxis model
		return { Name: this.content?.Title, SeasonNum: this.content?.Season, EpisodeNum: this.content?.Episode, EpisodeName: this.content?.EpisodeName }
	}

	get EpisodeName() {
		return this.content?.EpisodeName
	}
}

class Program {
	@serializable(alias("channel_id", primitive())) channelId = null
	@serializable(alias("events", list(object(Events)))) Events = null
}

class ContentDetails {
	//MTVW-272
	@serializable id = null
	@serializable(alias("title", primitive())) Title = null
	@serializable(alias("description", primitive())) LongSynopsis = null
	@serializable(alias("poster_url", primitive())) BoxCover = null
	@serializable(alias("rating", primitive())) Rating = null
	@serializable series_name = null
	@serializable(alias("series_id", primitive())) SeriesId = null
	@serializable(alias("episode_name", primitive())) EpisodeName = null
	@serializable(alias("season", primitive())) Season = null
	@serializable(alias("episode", primitive())) Episode = null
	@serializable duration = null
	@serializable(alias("genres", list())) Genres = null
	@serializable(alias("synopsis", primitive())) ShortSynopsis = null
	@serializable(alias("language", primitive())) OriginalLanguage = null
	@serializable(alias("production_year", primitive())) ProductionDate = null
	@serializable(alias("production_locations", list())) ProductionLocations = null
	// for episode.content in series request
	@serializable(alias("series_collection_id", primitive())) seriesCollectionId = null
	@serializable(alias("series_container_id", primitive())) seriesContainerId = null
	@serializable(alias("series_recording_id", primitive())) seriesRecordingId = null
	@serializable(alias("season_id", primitive())) seasonId = null
	@serializable language_id = null
	@serializable(alias("production_location_ids", list())) ProductionLocationsIds = null
	@serializable(alias("program_type", primitive())) programType = null
	@serializable(alias("series_type", primitive())) seriesType = null
	@serializable(alias("actors", list())) Actors = null
	@serializable(alias("writers", list())) Writers = null
	@serializable(alias("directors", list())) Directors = null
	@serializable SeriesMoniker = null

	// obsolete
	//@serializable(alias("is_hd", primitive())) IsHD = null
	MovieType = null
}

class Season {
	@serializable number = 0
	//@serializable(list(object(Episode))) episodes = null
	@serializable(list(object(EventWithContent))) episodes = null
}

class DetailedSeries {
	@serializable id = null
	@serializable(alias("title", primitive())) Title = null
	@serializable(alias("poster_url", primitive())) _posterUrl = null
	@serializable language = null
	@serializable rating = null
	@serializable(list()) genres = []
	@serializable(alias("event_count", primitive())) eventCount = null
	@serializable(alias("episode_count", primitive())) episodeCount = null
	@serializable(alias("season_count", primitive())) seasonCount = null
	@serializable(alias("program_type", primitive())) programType = null
	@serializable(alias("series_type", primitive())) programType = null

	@serializable(list(object(Season))) seasons = null

	// make sure to return secure url
	get posterUrl() {
		return urlFixHttpProtocolMismatch(this._posterUrl)
	}
}

export class EventDetails {
	//MTVW-272
	@serializable(object(Event)) event
	@serializable(object(ContentDetails)) content

	get isReady() {
		//return true
		return false
	}

	get OriginalLanguageName() {
		return iso6391.getNameByCode(this.content?.OriginalLanguage)
	}

	// used in RecordingsManager
	get id() {
		return this.event?.id
	}

	// used in RecordingsManager
	get IsNetworkRecordingAllowed() {
		// TODO: not yet supported by MS
		return true
	}

	// MTVW-272: For compatibility with traxis model
	get Title() {
		return { Name: this.content?.Title, BoxCover: urlFixHttpProtocolMismatch(this.content?.BoxCover), SeasonNum: this.content?.Season, EpisodeNum: this.content?.Episode, EpisodeName: this.content?.EpisodeName, Rating: this.content?.Rating, LongSynopsis: this.content?.LongSynopsis, OriginalLanguageName: this.OriginalLanguageName, Credits: { Actors: this.content?.Actors, Directors: this.content?.Directors }, Genres: this.content?.Genres, ProductionLocation: this.content?.ProductionLocations, ProductionLocationId: this.content?.ProductionLocationsIds, ProductionDate: this.content?.ProductionDate, SeriesId: this.content?.SeriesId, isSeries: this.isSeries, MovieType: this.content?.MovieType }
	}

	get AvailabilityStartDate() {
		return moment(this.event?.AvailabilityStartTs)
	}

	get AvailabilityEndDate() {
		return moment(this.event?.AvailabilityEndTs)
	}

	get AvailabilityStartDateTs() {
		return this.event?.AvailabilityStartTs
	}

	get AvailabilityEndDateTs() {
		return this.event?.AvailabilityEndTs
	}

	get getCurrentEventType() {
		const timeCurr = rootStore.time.getTimeStampTick(60),
			timeStart = this.AvailabilityStartDateTs,
			timeEnd = this.AvailabilityEndDateTs
		return timeStart <= timeCurr && timeEnd > timeCurr ? "c" : timeStart >= timeCurr ? "n" : "p"
	}

	get AvailabilityDurationInMinutes() {
		// GT12 MTVW-487: seconds precision
		//return moment.duration(this.AvailabilityEndDate.diff(this.AvailabilityStartDate)).asMinutes()
		//return Math.round(moment.duration(this.AvailabilityEndDate - this.AvailabilityStartDate).asMinutes())
		return Math.trunc(this.AvailabilityEndDate / 60000) - Math.trunc(this.AvailabilityStartDate / 60000)
	}

	// MTVW-810
	get AvailabilityDurationInHoursAndMinutes() {
		const durationMinutes = this.AvailabilityDurationInMinutes
		const hours = Math.trunc(durationMinutes / 60)
		const minutes = durationMinutes % 60
		console.debug("hours=", hours, minutes)
		//return hours > 0 ? hours + " Std. " + (minutes > 0 ? minutes + " Min." : "") : minutes + " Min."
		if (hours > 0 && minutes > 0) return l10n.featureDetailsDurationMetadataForLongShows.format(hours, minutes)
		else if (hours > 0) return l10n.featureDetailsDurationMetadataForLongShowsOnlyHours.format(hours)
		else return l10n.featureDetailsDurationMetadataForShortShows.format(minutes)
	}

	isTimeInEventAvailability(time) {
		return this.AvailabilityStartDate.isSameOrBefore(time) && this.AvailabilityEndDate.isAfter(time)
	}

	get ChannelId() {
		return this.event?.channelId
	}

	get EventId() {
		return this.event?.id
	}

	AvailabilityStartDateFormat(format) {
		return moment(this.event?.AvailabilityStartTs).format(format)
	}

	AvailabilityEndDateFormat(format) {
		return moment(this.event?.AvailabilityEndTs).format(format)
	}

	get isSeries() {
		return this.content?.SeriesId ? true : false
	}

	/* obsolete
	get IsHD() {
		return true
	}
	*/
}

const STD_HEADERS = {
	'Accept': 'application/json',
	// no-cache would not generate 304 on all browsers
	//'pragma': 'no-cache', 'cache-control': 'no-cache'
}
export class EpgService extends TraxisCpeIdMixin {

	// MTVW-536, MTVW-584, QLMS-1836 workaround
	get CACHE_BUSTER() {
		// MTVW-766: Disable cache buster
		//return "?client=web" + Math.floor(Math.random() * 999)
		return ""
	}

	get xHeaders() {
		// make a copy of STD_HEADERS, otherwise STD_HEADERS would be changed!
		const headers = {}
		Object.assign(headers, STD_HEADERS)
		Object.assign(headers, getQlHeaders())
		return headers
	}

	version = flow(function* (retryCount = DEFAULT_RETRIES) {
		const config = {
			url: serviceUrls.epgUrl + "version",
			fetchOptions: {
				method: 'GET',
				headers: this.xHeaders,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["EpgService version"],
			service: "EpgService",
			createException: true,
			displayError: true
		}
		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		return `${resultJson["build_tag"]} (${resultJson["build_date"]})`
	})

	// only used from Search
	seriesAsync = flow(function* (seriesId, retryCount = DEFAULT_RETRIES) {
		const config = {
			url: serviceUrls.epgUrl + `series/${seriesId}` + this.CACHE_BUSTER,
			fetchOptions: {
				method: 'GET',
				headers: this.xHeaders,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["EpgService seriesAsync"],
			service: "EpgService",
			createException: true,
			displayError: false
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		return deserialize(DetailedSeries, resultJson, (err, result) => {
			// amendments could be done here 
			//if (result?.length > 0) result[0].event.newProp = "test prop"; console.debug("cb err %o result: %o", err, result)
		})
	})

	/* Use Channels MS
	channelsAsync = flow(function* (retryCount = DEFAULT_RETRIES) {
		//console.debug("@@channelList() ->")
		//console.debug("url %s", serviceUrls.epgUrl + "channels")


		const [response, exception] = yield fetchRetry(serviceUrls.epgUrl + "channels" + this.CACHE_BUSTER, {
			method: 'GET',
			headers: this.xHeaders,
		}, retryCount)
		//console.debug("recvd %o, %o", response, exception)

		// eslint-disable-next-line no-unused-vars
		const [resultJson, requestId] = yield checkResponse([response, exception], ["EpgService channelsAsync"], "EpgService", true)

		console.debug("@@channelList() <-")

		const result = deserialize(Channel, resultJson, (err, result) => {
			// amendments could be done here 
			//if (result?.length > 0) result[0].event.newProp = "test prop"; console.debug("cb err %o result: %o", err, result)
		})
		//console.debug("EPG channels: %o, %o", resultJson, result)
		return result
	})
	*/

	programsForDayAsync = flow(function* (day, useEtag, retryCount = DEFAULT_RETRIES) {
		stopWatch.lap("load program " + useEtag)
		//console.debug("@@programsForDay() -> day %s, url", day, serviceUrls.epgUrl + `channel/every/epg/${day}`)

		const config = {
			url: serviceUrls.epgUrl + `programs/dates/${day}` + this.CACHE_BUSTER,
			fetchOptions: {
				method: 'GET',
				headers: this.xHeaders,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["EpgService programsForDayAsync"],
			service: "EpgService",
			createException: true,
			displayError: false	// MTVW-536: don't display error
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)

		const etag = response?.headers ? response.headers.get('etag') : null
		//console.debug("@@programsForDay() <- day %s, got etag %s", day, etag)

		// response not changed
		// QLMS-997: When performing a login to canary with a prefix, the origin is still kept at 'content.quickline.ch'
		// and the response header contains 'Vary: Origin'. The missing header "Access-Control-Expores-Headers: ETag" has been
		// added with QLMS-997, allowing now access to the etag in the header when using canary.
		//if (useEtag === etag) return { progs: null, etag: etag }
		if ((useEtag === etag) && (etag !== null)) {
			console.debug("same etag")
			//return { progs: null, etag: etag }
			return Promise.resolve({ progs: null, etag: etag })
		}

		stopWatch.lap("deserialize program")
		const result = deserialize(Program, resultJson, (err, result) => {
			// amendments could be done here 
			//if (result?.length > 0) result[0].event.newProp = "test prop"; console.debug("cb err %o result: %o", err, result)
		})
		stopWatch.lap("deserialize done")
		//console.debug("programs: %o", result)
		return { progs: result, etag: etag }
	})

	// workaround for QLMS-850
	qlms_850(progs, day) {
		if (progs) {
			stopWatch.lap("qlms_850")
			const dayExpectedStart = moment(day).add(-4, 'hour')
			//console.debug("qlms_850 %s, %s, %s", day, dayExpectedStart.toJSON(), dayExpectedStart.valueOf())
			for (let i = 0; i < progs?.length; i++) {
				if (progs[i].Events?.length > 0) {
					if (progs[i].Events[0].AvailabilityStartDateTs > dayExpectedStart.valueOf()) {
						//console.debug("First %o, %s, %o", progs[i].Events[0].event.AvailabilityStart, progs[i].Events[0].AvailabilityStartDateTs, progs[i].Events[0])
						//const event = this._fakeEvent(moment(day).add(-4, 'hour').toJSON(), progs[i].Events[0].event.AvailabilityStart)
						const event = this._fakeEvent(moment(day).add(-4, 'hour'), progs[i].Events[0].event.AvailabilityStartTs)
						//console.debug("INSERT %o", event)
						progs[i].Events.unshift(event)
					}
				}
			}
			stopWatch.lap("qlms_850 done")
		}
	}

	_fakeEvent(startDate, endDate) {
		const events = new Events()
		const content = new Content()
		content.Title = "..."
		// TODO: currently unused, might need to set correctly in the future
		//content.duration = "PT10M"
		events.content = content
		const event = new Event()
		event.fake = true
		// UNUSED
		//event.channelId = channel
		event.AvailabilityStartTs = startDate
		event.AvailabilityEndTs = endDate
		events.event = event
		return events
	}

	_fakeDayEvents(day, channel) {
		const result = []
		let startHour = -4
		while (startHour < 26) {
			//const duration = (Math.random() * 1.75) + 0.25
			const duration = (Math.random() * 1.0) + 0.25
			//const event = this._fakeEvent(moment(day).add(startHour, 'hour').toJSON(), moment(day).add(startHour + duration, 'hour').toJSON())
			const event = this._fakeEvent(moment(day).add(startHour, 'hour').valueOf(), moment(day).add(startHour + duration, 'hour').valueOf())
			startHour = startHour + duration
			result.push(event)
		}
		return result
	}

	fakeProgramsDay(day, channels) {
		const alternate = true // saves considerable time when true
		const progs = []
		if (channels) {
			if (alternate) {
				// we always have to generate events the given day!
				const ch0 = channels?.length > 0 ? this._fakeDayEvents(day, channels[0].id) : []
				const ch1 = channels?.length > 1 ? this._fakeDayEvents(day, channels[1].id) : []
				for (let i = 0; i < channels?.length; i++) {
					const prog = new Program()
					prog.channelId = channels[i].id
					prog.Events = []
					prog.Events = (i % 2 === 0) ? prog.Events.concat(ch0) : prog.Events.concat(ch1)
					progs.push(prog)
				}
			}
			else {
				for (let i = 0; i < channels?.length; i++) {
					const prog = new Program()
					prog.channelId = channels[i].id
					prog.Events = []
					prog.Events = prog.Events.concat(this._fakeDayEvents(day, prog.channelId))
					progs.push(prog)
				}
			}
		}
		return progs
	}

	// MTVW-340
	programsForChannelAsync = flow(function* (channel, day, retryCount = DEFAULT_RETRIES) {
		const config = {
			url: serviceUrls.epgUrl + `programs/dates/${day}/channels/${channel}` + this.CACHE_BUSTER,
			fetchOptions: {
				method: 'GET',
				headers: this.xHeaders,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["EpgService programsForChannelAsync"],
			service: "EpgService",
			createException: true,
			displayError: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)

		const result = deserialize(Events, resultJson, (err, result) => {
			// amendments could be done here
			//if (result?.length > 0) result[0].event.newProp = "test prop"; console.debug("cb err %o result: %o", err, result)
		})
		return result
	})

	// MTVW-246
	programsForNowAsync = flow(function* (retryCount = DEFAULT_RETRIES) {
		const config = {
			url: serviceUrls.epgUrl + `programs/now` + this.CACHE_BUSTER,
			fetchOptions: {
				method: 'GET',
				headers: this.xHeaders,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["EpgService programsForNowAsync"],
			service: "EpgService",
			createException: true,
			displayError: false
		}

		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		const result = deserialize(Program, resultJson, (err, result) => {
			// amendments could be done here 
			//if (result?.length > 0) result[0].event.newProp = "test prop"; console.debug("cb err %o result: %o", err, result)
		})

		return result
	})

	// MTVW-246
	eventDetailsAsync = flow(function* (eventId, retryCount = DEFAULT_RETRIES, displayError = true) {
		//console.debug("@@eventDetailsAsync() ->")

		const config = {
			url: serviceUrls.epgUrl + `events/${eventId}` + this.CACHE_BUSTER,
			fetchOptions: {
				method: 'GET',
				headers: this.xHeaders,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["EpgService eventDetailsAsync"],
			service: "EpgService",
			createException: true,
			displayError: displayError
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)

		const result = deserialize(EventDetails, resultJson, (err, result) => {
			// amendments could be done here 
			//if (result?.length > 0) result[0].event.newProp = "test prop"; console.debug("cb err %o result: %o", err, result)
		})

		return result
	})
}
