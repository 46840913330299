import { Typography, Button } from "@mui/material"
import React from "react"
import { CreateComponent } from "components/utils/CreateComponent"
import { alpha } from "@mui/material/styles"
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

const styles = theme => ({
	ModalRoot: {
		display: "flex",
		position: "absolute",
		//top: "40%",
		backgroundColor: alpha(theme.palette.secondary.main, 0.9) + " !important",
		margin: "0 auto"
	},
	ModalContainer: {
		display: "flex",
		flexDirection: "column",
		margin: "0px auto 0px auto ",
		height: "auto",
		minWidth: 320,
		maxWidth: "400px",
	},
	ModalFooter: {
		bottom: "0",
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		height: "auto",
		textAlign: "center",
		//background: "#332F2F",
	},
	ModalButton: {
		order: 0,
		//flex: "0 1 auto",
		//alignSelf: "auto",
		fontSize: 12
	},
})

export const ModalMessage = CreateComponent(styles, false, false, ({ classes, show, msgInfo, onCancel, onConfirm }) => {
	if (!msgInfo) return null
	const { title, content, button1 = null, button2 = null } = msgInfo

	return (
		<Dialog
			open={show}
			PaperProps={{
				classes: {
					root: classes.ModalRoot
				}
			}}
		>
			<DialogContent className={classes.ModalContainer} data-test="modal_message">
				<Typography variant="subtitle1" paragraph={true}>
					{title}
				</Typography>
				{/* text with newline(s) */}
				<Typography variant="caption" paragraph={true} style={{ whiteSpace: "pre-line" }}>
					{content}
				</Typography>
			</DialogContent>

			<DialogActions className={classes.ModalFooter}>
				{button1 !== null && (
					<Button size="medium" className={classes.ModalButton} onClick={() => { onCancel() }} data-test="modal_message_cancel_button">
						{button1}
					</Button>
				)}
				{button2 !== null && (
					<Button size="medium" className={classes.ModalButton} onClick={() => { onConfirm() }} data-test="modal_message_confirm_button">
						{button2}
					</Button>
				)}
			</DialogActions>

		</Dialog>
	)
})
