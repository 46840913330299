import { flow /*, observable, computed, action */ } from "mobx"
import { TraxisCpeIdMixin } from "store/api/mixin/TraxisCpeIdMixin"
// MTVW-585: 1 retry
import { fetchWithRetry, DEFAULT_RETRIES, DEFAULT_TIMEOUT, NO_RETRIES } from "./FetchWithRetry"
import { serviceUrls } from "store/qlapi/ServiceUrls"
import { getQlHeaders } from "store/qlapi/QlHeaders"
import { isFairPlaySupported, isWidevineSupported } from "utils/Utils"
import { ErrorBase } from "store/ErrorBase"
import { appFeatures } from "../../AppFeatures"
// TODO: Remove, temporary for DRM PoC
import { rootStore } from "store/RootStore"
import { getCpeId } from "store/api/mixin/TraxisCpeIdMixin"
import { getUrlQueryParam } from "utils/Utils"


// MTV-3570: Moved to ServiceUrls
/*
export const BASE_URL_PROD = "https://content.quickline.ch/media/v008/"
//export const BASE_URL_PROD = "https://services-sg0.qltv.quickline.ch/media/v008/"
export const BASE_URL_STAGE = "https://content.stage.quickline.ch/media/v008/"
*/

// MTVW-577: provide cookie
const CREDENTIALS = 'include'
const STD_HEADERS = {
	'Accept': 'application/json',
	// Would cause CORS in keepAlive
	//'pragma': 'no-cache', 'cache-control': 'no-cache'
}

export class MediaService extends TraxisCpeIdMixin {

	get xHeaders() {
		// make a copy of STD_HEADERS, otherwise STD_HEADERS would be changed!
		const headers = {}
		Object.assign(headers, STD_HEADERS)
		Object.assign(headers, getQlHeaders())
		return headers
	}

	setupStream(...args) {
		return this.setupStreamAsync(...args)
	}

	version = flow(function* (retryCount = DEFAULT_RETRIES) {
		const config = {
			url: serviceUrls.mediaUrl + "version",
			fetchOptions: {
				method: 'GET',
				headers: this.xHeaders,
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["MediaService version"],
			service: "MediaService",
			createException: true,
			displayError: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		return `${resultJson["build_tag"]} (${resultJson["build_date"]})`
	})

	versionUrl() {
		return [serviceUrls.mediaUrl + "version", this.xHeaders]
	}

	// MTVW-682
	_checkForExistingManifest = flow(function* (manifestUrl, retryCount = DEFAULT_RETRIES) {
		const config = {
			url: manifestUrl,
			fetchOptions: {
				method: 'GET',
				headers: this.xHeaders,
				//credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["MediaService _checkForExistingManifest"],
			service: "MediaService",
			createException: true,
			displayError: false,
			noJsonResponse: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		//console.debug("_checkForExistingManifest", manifestUrl, response)
	})

	// MTVW-MTVW-707
	_checkWidevineKeyRequestUrl = flow(function* (keyRequestUrl, retryCount = DEFAULT_RETRIES) {
		// MTVW-711: add challenge body
		const challenge = new Uint8Array([8, 4])
		//const blob = new Blob(challenge, { type: "application/octet-stream" })
		const config = {
			url: keyRequestUrl,
			fetchOptions: {
				method: 'POST',
				headers: this.xHeaders,
				// MTVW-711: add challenge body
				body: challenge,
				//credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["MediaService _checkForWidevineLicense"],
			service: "MediaService",
			createException: true,
			displayError: false,
			noJsonResponse: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		//console.debug("_checkForExistingManifest", manifestUrl, response)
	})

	setupStreamAsync = flow(function* (suffix, channelId, retryCount = DEFAULT_RETRIES) {
		/*
		const drmChannels = [
			"2705", // TV5 Monde
			"2245", // WDR Köln HD
			"2826", // Video Italia HD
			"2021", // SRF 1 HD
			"2315", // Nitro HD
		]
		*/
		const hasFairplaySupport = yield isFairPlaySupported()
		const hasWidevineSupport = yield isWidevineSupported()

		/* MTVW-794: trial with contentId
		const contentId = getUrlQueryParam("rec")
		console.debug("contentId", contentId, suffix)
		if (contentId) suffix = "recording/" + contentId
		*/

		let sep = "?"
		if (hasFairplaySupport) {
			suffix += `${sep}drmScheme=fairplay`
			sep = "&"
		}
		// MTVW-659: TOOD support widevine
		// MTVW-731: Open blocking issue, temporarily stop requesting widevine
		else if (hasWidevineSupport) {
			suffix += `${sep}drmScheme=widevine`
			sep = "&"
		}
		// MTVW-593, MTVW-848: NTL
		if (appFeatures.persistedNtl && suffix.includes("live")) suffix += `${sep}ntl=true`
		console.debug("mediaUrl %s", serviceUrls.mediaUrl + suffix, channelId)

		const config = {
			url: serviceUrls.mediaUrl + suffix,
			fetchOptions: {
				method: 'GET',
				headers: this.xHeaders,
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["MediaService setupStreamAsync"],
			service: "MediaService",
			createException: true,
			displayError: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)

		console.debug("stream URL %s, drmscheme %s, json %o", resultJson?.stream_url, resultJson?.drm_scheme, resultJson)

		// MTVW-794
		let overrideUrl = null
		//if (suffix.includes("recording")) {
		overrideUrl = getUrlQueryParam("rec")
		//}
		if (overrideUrl) {
			console.debug("overrideUrl", overrideUrl)
			console.debug("keep_alive_url", overrideUrl?.substring(0, overrideUrl?.indexOf("Global")) + "keepalive")
			resultJson.stream_url = overrideUrl
			resultJson.keep_alive_url = overrideUrl.substring(0, overrideUrl.indexOf("Global")) + "keepalive"
		}

		try {
			// MTVW-682: generate error if manifest cannot be fetched on some canary environments
			// test
			//yield this._checkForExistingManifest(resultJson?.stream_url.replace("/GlobalManifest", "xxx/GlobalManifest"))
			yield this._checkForExistingManifest(resultJson?.stream_url)
		}
		catch (e) {
			console.error("_checkForExistingManifest error", response, e)
			const error = ErrorBase.CreateError(["Manifest: " + e?.e], null, { code: "404?", type: "error.type" })
			throw error
		}

		if (resultJson?.drm_scheme === "widevine") {
			try {
				console.debug("license URL", resultJson?.key_request_url)
				yield this._checkWidevineKeyRequestUrl(resultJson?.key_request_url)
			}
			catch (e) {
				console.error("_checkWidevineKeyRequestUrl error", e)
				const error = ErrorBase.CreateError(["Widevine: " + e?.e], null, { code: "500?", type: "error.type" })
				throw error
			}
		}
		return resultJson
	})

	// MTVW-633: retry 1, timeout 5000
	keepAlive = flow(function* (url, timeout = 5000, retryCount = NO_RETRIES) {
		// called from within periodic timer setInterval, error would not be caught!
		const config = {
			url: url,
			fetchOptions: {
				method: 'GET',
				// X-Quickline headers would cause a CORS issue
				// MTVW-267: Do not include headers
				//headers: STD_HEADERS
				// MTVW-549
				headers: this.xHeaders,
				// MTVW-577: No cookie for keepalive on streamers
				//credentials: CREDENTIALS
			},
			retryCount: retryCount,
			timeout: timeout,
			codes: ["MediaService keepAlive"],
			service: "MediaService",
			createException: true,
			displayError: false
		}
		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		//console.debug("rsp %o, exc %o", response, exception)
	})

	getSessions = flow(function* (timeout = 5000, retryCount = DEFAULT_RETRIES) {
		const config = {
			url: serviceUrls.mediaUrl + `sessions`,
			fetchOptions: {
				method: 'GET',
				headers: this.xHeaders,
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: timeout,
			codes: ["MediaService getSessions"],
			service: "MediaService",
			createException: true,
			displayError: false
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		return resultJson
	})

	// MTVW-633: retry 1, timeout 5000, no retry (would cause error on the 1st retry after timeout)
	keepAliveDelegation = flow(function* (sessionId, enable, timeout = 5000, retryCount = DEFAULT_RETRIES) {
		const config = {
			url: serviceUrls.mediaUrl + `sessions/${sessionId}`,
			fetchOptions: {
				method: 'PUT',
				headers: this.xHeaders,
				credentials: CREDENTIALS,
				body: JSON.stringify({ keep_alive_delegation: enable })
			},
			retryCount: retryCount,
			timeout: timeout,
			codes: ["MediaService keepAliveDelegation"],
			service: "MediaService",
			createException: true,
			displayError: false
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
	})

	deleteSession = flow(function* (sessionId, retryCount = DEFAULT_RETRIES) {
		const config = {
			url: serviceUrls.mediaUrl + `sessions/${sessionId}`,
			fetchOptions: {
				method: 'DELETE',
				headers: this.xHeaders,
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["MediaService deleteSession"],
			service: "MediaService",
			createException: true,
			displayError: false
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
	})
}
