import { ClassAbstract } from "store/ClassTools"
import { makeObservable } from "mobx"
import { serializable } from "serializr"
//import { lazyObject } from "store/ModelTools"
import { msVersions } from "utils/TestSupport"

export class ServiceUrls extends ClassAbstract {
	vAds = "v001/"
	vBookmarks = "v002/"
	vChannels = "v001/"
	vEpg = "v009/"
	vLog = "v001/"
	// MTVW-584: bump version from v001 to v002
	vLogin = "v002/"
	// MTVW-577: bump version from v009 to v010
	vMedia = "v010/"
	vOnboarding = "v002/"
	vSearch = "v001/"
	adsMsEnabled = true
	bookmarksMsEnabled = false

	// MTVW-571: Replace relative URL.
	initalLogin = "https://login.qltv.quickline.ch/login/" + this.vLogin
	//initalLogin = "/login/" + this.vLogin
	@serializable login = null
	@serializable ads = "/ads/"
	@serializable bookmarks = "/bookmarks/"
	@serializable channels = "/channels/"
	@serializable epg = "/epg/"
	@serializable log = "/log/"
	@serializable media = "/media/"
	@serializable onboarding = "/onboarding/"
	@serializable search = "/search/"
	// MTVW-607: Change default for traxis
	@serializable traxis = "https://apps-traxis-api.quickline.ch"

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
		})
	}

	setUrls(urls) {
		this.ads = urls.ads
		this.bookmarks = urls.bookmarks
		this.channels = urls.channels
		this.epg = urls.epg
		this.log = urls.log
		this.login = urls.login
		this.media = urls.media
		this.onboarding = urls.onboarding
		this.search = urls.search
		this.traxis = urls.traxis
		// make sure that we have updated MS versions
		console.debug("ServiceUrls %o", this)
		msVersions()
	}

	get loginUrl() {
		if (!this.login) {
			//console.debug("return initialLogin")
			//return "https://canary-preprod.qltv.quickline.ch/login/v002/"
			//return "https://canary-prod.qltv.quickline.ch/login/v002/"
			return this.initalLogin
			//return "https://content.alpha.quickline.ch/login/v001/"
		} else {
			//console.debug("return serviceUrl %s", this.login + this.vLogin)
			return this.login + this.vLogin
			//return "https://content.alpha.quickline.ch/login/v001/"
		}
	}

	get adsUrl() {
		//return "https://canary-preprod.qltv.quickline.ch/ads/v001/"
		//return "https://services.qltv.stage.quickline.ch/ads/v001/"
		return this.ads + this.vAds
	}

	get bookmarksUrl() {
		//return "https://canary-preprod.qltv.quickline.ch/ads/v001/"
		//return "https://services.qltv.stage.quickline.ch/ads/v001/"
		return this.bookmarks + this.vBookmarks
	}

	get channelsUrl() {
		//return "https://canary-preprod.qltv.quickline.ch/channels/v001/"
		return this.channels + this.vChannels
	}

	get epgUrl() {
		// Need authenticateUntrusted without BasicAuth!
		return this.epg + this.vEpg
		//return "https://sg1-epg.qltv.quickline.ch/epg/v009/"
	}

	get logUrl() {
		//return "https://canary-preprod.qltv.quickline.ch/log/v001/"
		return this.log + this.vLog
	}

	get mediaUrl() {
		// Need authenticateUntrusted without BasicAuth!
		return this.media + this.vMedia
	}

	get onboardingUrl() {
		// Need authenticateUntrusted without BasicAuth!
		// TODO
		//return "https://canary-preprod.qltv.quickline.ch/onboarding/v002/"
		//return "https://canary.qltv.stage.quickline.ch/onboarding/v002/"
		return this.onboarding + this.vOnboarding
	}

	get searchUrl() {
		// Need authenticateUntrusted without BasicAuth!
		return this.search + this.vSearch
	}

	get isAdsMsAvailable() {
		return this.adsMsEnabled && this.ads !== "/ads/"
	}

	get isBookmarksMsAvailable() {
		console.debug("isBookmarksMsAvailable", this.bookmarks)
		return this.bookmarksMsEnabled && this.bookmarks !== "/bookmarks/"
	}

	disableBookmarks() {
		this.bookmarksMsEnabled = false
	}

	get isChannelsMsAvailable() {
		return this.channels !== "/channels/"
	}

	get isLogMsAvailable() {
		return this.log !== "/log/"
	}

	get traxisUrl() {
		return this.traxis + "?output=json&language=de"
		/*
		if (import.meta.env.VITE_ENV_IS_STAGING === "true") {
			return "https://apps-traxis-api.stage.quickline.ch/traxis/web?output=json&language=de"
		}
		else {
			return "https://apps-traxis-api.quickline.ch/traxis/web?output=json&language=de"
		}
		*/
	}

	get environment() {
		return this.ads.replace("/ads/", "")
	}
}

export const serviceUrls = new ServiceUrls()

/* TEST: commented
export const checkVersions = async () => {
	const urls = ["https://sg1-login.qltv.quickline.ch/login/v002/version", "https://sg1-epg.qltv.quickline.ch/epg/v009/version",
		"https://sg1-media.qltv.quickline.ch/media/v010/version", "https://sg1-onboarding.qltv.quickline.ch/onboarding/v002/version",
		"https://sg1-ads.qltv.quickline.ch/ads/v001/version", "https://sg1-channels.qltv.quickline.ch/channels/v001/version",
		"https://sg2-log.qltv.quickline.ch/log/v001/version", "https://sg1-search.qltv.quickline.ch/search/v001/version"
	]

	const headers = {}
	headers['X-Quickline-Deviceid'] = "unset"
	headers['X-Quickline-Userid'] = "unset"
	headers['X-Quickline-Profileid'] = "unset"
	const CREDENTIALS = 'include'
	for (let url = 0; url < urls?.length; url++) {
		console.debug("checkVersions %s", urls[url])
		try {
			await fetch(urls[url], {
				method: 'GET', headers: headers,
				credentials: CREDENTIALS
				//cert: ""
			})
		}
		catch (err) {
			console.debug("CATCH checkVersions, %o", err)
		}
	}
}
*/
