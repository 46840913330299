// l10n status: done
import {
	Divider, FormControlLabel, FormGroup,
	FormControl, FormHelperText, Switch, InputAdornment,
	OutlinedInput, InputLabel, Button, IconButton, /*Link,*/ Slide, Snackbar, SnackbarContent
} from "@mui/material"
import { CreateComponent } from "components/utils/CreateComponent"
import React, { useEffect, useRef, useState } from "react"
import Edit from "@mui/icons-material/Edit"
import { rootStore } from "store/RootStore"
import { PinEntryDialog } from "scenes/components/Login"

import { ModalMessage } from "components/ModalMessage"
import { PinEntryTextFields } from "./PinEntryTextFields"
import "./settings.css"
import { appFeatures } from "../../AppFeatures"
import { appVersion } from "utils/TestSupport"
import { l10n } from "store/lang/L10n"
/*
import { ErrorMessage } from "components/ErrorMessage"
import styles from "./Settings.module.css"
*/

// const styles = theme => ({})

// used for avoiding asynchronous useState issues, especially with storedPin
const sState = {
	// pin while in settings
	storePreviousPin: "",
	storedPin: "",
	storedCallback: null
}

let infoMessage = {}
let onConfirmModalMessage = {}

const SettingsBody = CreateComponent(null, false, true, ({ classes, page }) => {
	const [editProfileName, setEditProfileName] = useState(false) // enable / disable edit of profile name
	const [editPin, setEditPin] = useState(false) // controls enable / disable edit of pin
	const [isGt12, setIsGt12] = useState(appFeatures.gt12Enabled)
	const [isAdsMock, setIsAdsMock] = useState(appFeatures.persistedAdsMock)
	const [hasTestButtons, setTestButtons] = useState(appFeatures.persistedTestButtons)
	const [isDefaultAdsTimeout, setIsDefaultAdsTimeout] = useState(appFeatures.isDefaultAdsTimeout)
	const [isNtl, setIsNtl] = useState(appFeatures.persistedNtl)
	const [hasPinReset, setPinReset] = useState(appFeatures.persistedPinReset)
	const [hasPlayerLog, setPlayerLog] = useState(appFeatures.persistedPlayerLog)

	/* MTVW-728: Use channelsMs -> not needed anymore
	const [channelsOptIn, setChannelsOptIn] = useState(false)
	*/
	// controls display of Dialog for pin entry
	const [openPinDialog, setOpenPinDialog] = useState(false)
	const [profileName, setProfileName] = useState("")
	const Customer = rootStore.singleton.customer.Data
	const Profile = rootStore.sso.profile

	const [showModalMessage, setShowModalMessage] = useState(false)

	const refProfileName = useRef()

	useEffect(() => {
		if (editProfileName) {
			// eslint-disable-next-line no-unused-expressions
			refProfileName.current?.firstElementChild.focus()
		}
	}, [editProfileName])

	/* MTVW-728: Use channelsMs -> not needed anymore
	useEffect(() => {
		//console.debug("useEffect Customer isReady %s", rootStore.singleton.customer.isReady)
		if (rootStore.singleton.customer.isReady) {
			//console.debug("setChannelsOptIn %s, date %s", Customer?.RecordingOptInDate !== null ? true : false, Customer?.RecordingOptInDate)
			setChannelsOptIn(Customer?.RecordingOptInDate !== null ? true : false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rootStore.singleton.customer.isReady])
	*/

	useEffect(() => {
		window.scroll(0, 0)
		rootStore.sso.profile.getProfile()
		rootStore.api.GetCustomer().fetchData()
	}, [])

	useEffect(() => {
		setIsGt12(appFeatures.gt12Enabled)
		setTestButtons(appFeatures.persistedTestButtons)
		setIsAdsMock(appFeatures.persistedAdsMock)
		setIsDefaultAdsTimeout(appFeatures.isDefaultAdsTimeout)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appFeatures.gt12Enabled])

	/* MTVW-728: Use channelsMs -> optInAllChannels
	const toggleAllOptInChannels = () => {
		//console.debug("toggleAllOptInChannels %s", channelsOptIn)
		const value = !channelsOptIn
		setChannelsOptIn(value)
		if (value) {
			rootStore.api.AddChannelsToRecordingOptInChannelList().fetchDataAsync().then(res => {
				rootStore.api.GetCustomer().fetchData()
			})
		} else {
			rootStore.api.DeleteChannelsFromRecordingOptInChannelList().fetchDataAsync().then(res => {
				rootStore.api.ClearRecordingOptInDate({ customerId: Customer._$Aliases.get("IngestId").Value, resourceId: Customer.id }).fetchDataAsync().then(res => {
					rootStore.api.GetCustomer().fetchData()
				})
			})
		}
	}
	*/
	const optInAllChannels = () => {
		/* test purpose
		rootStore.api.DeleteChannelsFromRecordingOptInChannelList().fetchDataAsync().then(res => {
			rootStore.api.ClearRecordingOptInDate({ customerId: Customer._$Aliases.get("IngestId").Value, resourceId: Customer.id }).fetchDataAsync().then(res => {
				rootStore.api.GetCustomer().fetchData()
			})
		})
		return
		*/
		rootStore.page.MsEpg.getChannelsArrayAsync().then(res => {
			//console.debug("channelsArray", res)
			rootStore.channelsService.replayOptIn(rootStore?.sso?.contractId, res).then(result => {
				// test purpose: needs admin rights
				//rootStore.channelsService.replayOptOut(rootStore?.sso?.contractId).then(result => {
				// CAUTION: enabling the statement below will create page refreshes / mutliple sliding snackbars 
				/*
				rootStore.api.GetCustomer().fetchData()
				console.debug("replayOptIn", result.status, Customer?.RecordingOptInDate)
				*/

				// moved from useState to page state to avoid multiple snackbar triggers
				rootStore.page.Settings.setSnackbarMessage(l10n.featureSettingsOptInChannelsMessage)
			}).catch(error => {
				console.error("optInAllChannels", error)
				//rootStore.page.Player.error = error
				infoMessage = {
					title: error.title,
					content: error.detail,
					button1: l10n.commonActionDelete, // TODO to verify
				}
				setShowModalMessage(true)
			})
		})
	}

	const setProperty = (exec) => value => {
		exec.bind(Profile)(value, sState.storedPin)
	}

	const clearRecommendations = () => {
		Profile.clearRecommendations(sState.storedPin)
	}

	const deleteProfile = () => {
		Profile.deleteProfileAsync(Profile.ProfileIdentificationGuid, sState.storedPin)
	}

	const handleClose = () => {
		setOpenPinDialog(false)
	}

	const onPinEntered = (pin) => {
		const result = Profile.tryPinCode(pin).then(() => {
			// remember pin
			sState.storePreviousPin = sState.storedPin
			sState.storedPin = pin

			setOpenPinDialog(false)

			//console.debug("storedCallback %o %s", sState.storedCallback, pin)
			if (sState.storedCallback) {
				sState.storedCallback.afterValidPin(sState.storedCallback.value)
				sState.storedCallback = null
			}
		})
		// return promise for handling errors
		return result
	}

	const pinGuard = (eventPath, afterValidPin) => event => {
		let value = 0
		if (eventPath) value = eventPath.split(".").reduce((o, i) => o[i], event)
		if ((sState.storedPin !== "") || !rootStore.sso.profile.isProfilePinRequired) {
			afterValidPin(value)
			return true
		} else {
			// else: pin entry
			sState.storedCallback = { afterValidPin: afterValidPin, value: value }
			setOpenPinDialog(true)
			return false
		}
	}

	// Start of component for profile name (display and edit)
	const openEditProfile = () => {
		setProfileName(Profile?.Nickname)
		setEditProfileName(true)
	}

	const DeleteProfileMessage = {
		title: l10n.featureSettingsDeleteProfileActionTitle,
		// text with newline(s)
		content: l10n.featureSettingsDeleteProfileDescription.replaceAll("\\n", "\n"),
		button1: l10n.commonActionCancel,
		button2: l10n.commonActionDelete
	}

	/* MTVW-728: Use channelsMs -> not needed anymore
	const ReplayMessage = {
		title: l10n.featureSettingsDeactivateReplayTitle,
		content: <><p>{l10n.featureSettingsDeactivateReplayDescription1}</p><p>{l10n.featureSettingsDeactivateReplayDescription2}</p></>,
		button1: l10n.commonActionCancel,
		button2: l10n.featureSettingsActionDeactivate
	}
	*/

	const BackendErrorMessage = {
		title: l10n.errorGenericTitle, // TODO to verify
		content: "", // content will contain the backend message
		button1: l10n.commonActionDelete, // TODO to verify
	}

	const ProfileNameTextField = () => {
		return (
			<div className="profileNameTextFieldContainer" >
				<FormControl variant="outlined" className="inputField">
					<InputLabel className="labelTextField" htmlFor="setting-Profilname">{l10n.featureSettingsProfileNameTitle}</InputLabel>
					<OutlinedInput
						id="setting-Profilname"
						type='text'
						className="inputTextField"
						disabled={true}
						value={Profile.Nickname}
						label={l10n.featureSettingsProfileNameTitle}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									className="iconButton"
									aria-label="Close"
									data_test="edit_profile_button"
									onClick={() => {
										pinGuard(null, openEditProfile)()
									}
									}>
									<Edit />
								</IconButton>
							</InputAdornment>
						}
					//labelWidth={78}
					/>
					{Profile.IsMainProfile && <FormHelperText id="component-helper-text">{l10n.featureSettingsProfileNameHint}</FormHelperText>}
				</FormControl>
			</div>
		)
	}

	const ProfileNameEditable = () => {
		useEffect(() => {
			refProfileName.current.firstElementChild.value = profileName
		}, [])
		const [errorNewProfile, setErrorNewProfile] = useState("")

		const validateProfileName = (e) => {
			const [validStr, errorString] = Profile.validateProfileName(e.target.value, Profile)
			setErrorNewProfile(errorString)
			//console.debug("validStr %s, error %s", validStr, errorString)
			e.target.value = validStr
		}

		const handleCreateNewProfileName = () => {
			const newProfileName = document.getElementById("Edit-Profilname").value
			Profile.setNickname(newProfileName, sState.storedPin).catch((res) => {
				infoMessage = BackendErrorMessage
				infoMessage.content = res.msResponse.detail
				setShowModalMessage(true)
				console.debug("FAILED: %s", res.msResponse.detail)
			})
			//setProfileName(newProfileName)
		}

		return (
			<div className="profileNameEditableContainer">
				<FormControl variant="outlined" className="inputField">
					<InputLabel htmlFor="Edit-Profilname">{l10n.featureSettingsProfileNameTitle}</InputLabel>
					<OutlinedInput
						id="Edit-Profilname"
						type='text'
						disabled={false}
						ref={refProfileName}
						placeholder={profileName}
						label={l10n.featureSettingsProfileNameTitle}
						onInput={(e) => {
							validateProfileName(e)
						}}
					//labelWidth={78}
					/>
					{Profile.IsMainProfile && <FormHelperText id="component-helper-text">{l10n.featureSettingsProfileNameHint}</FormHelperText>}
				</FormControl>
				{errorNewProfile ? <span className="assistingMsgCreateProfile" data_test="error_profile">{errorNewProfile}</span> : null}

				<div className="EditableTextButtonContainer">
					<Button
						className={"EditableFieldButton "}
						onClick={() => { setEditProfileName(false) }}
						data_test="modify_profile_name_cancel_btn"
					>
						{l10n.commonActionCancel}
					</Button>
					<Button
						variant="contained"
						disabled={errorNewProfile !== ""}
						className={"EditableFieldButton "}
						onClick={() => {
							handleCreateNewProfileName()
							setEditProfileName(false)
						}}
						data_test="modify_profile_name_ok_btn"
					>
						{l10n.commonActionSave}
					</Button>
				</div>
			</div>
		)
	}
	// End of component for profile name

	// Start of component for PIN entry (display and edit)
	const openEditPin = () => {
		// It is necessary to use the previous value of the pin because at this point the value of the storedPin is already inserted.
		if (sState.storePreviousPin === "") {
			// If the verification pin has not been entered yet the PinEntryDialog opens a keyboard for the pin that scrolls the page. 
			// When the modal closes after a time of 500ms, the page is restored to the previous scroll position.
			// At the same time, when opening setEditPin the focus is activated on the first empty cell,
			// and on mobile the keyboard is opened.
			// Therefore, it's necessary to wait for more than 500ms (e.g. 600ms) to avoid the pin insert after the displacement caused by PinEntryDialog, otherwise the pin field would be hidden by the keyboard.
			// Check restoreDialogPos function in Login.js
			setTimeout(() => {
				setEditPin(true)
			}, 600)
		} else {
			setEditPin(true)
		}
	}

	const PinTextField = () => {
		return (
			<div className="PinTextFieldContainer">
				<FormControl variant="outlined" className="inputField">
					<InputLabel className="labelTextField" htmlFor="setting-Pin">{l10n.featureSettingsPinLabel}</InputLabel>
					<OutlinedInput
						className="inputTextField"
						id="setting-Pin"
						type='password'
						disabled={true}
						value={sState.storedPin === "" ? "1234" : sState.storedPin}
						label={l10n.featureSettingsPinLabel}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									className="iconButton"
									aria-label="Close"
									data_test="edit_pin_button"
									onClick={() => { pinGuard(null, openEditPin)() }
									}>
									<Edit />
								</IconButton>

							</InputAdornment>
						}
					//labelWidth={70}
					/>
				</FormControl>
			</div>
		)
	}

	const PinTextFieldEditable = () => {
		const [isValidPin, setIsValidPin] = useState(false)
		const [newPin, setNewPin] = useState("")

		const handleSetNewPin = () => {
			Profile.setPin(newPin, sState.storedPin).catch((res) => {
				infoMessage = BackendErrorMessage
				infoMessage.content = res.msResponse.detail
				setShowModalMessage(true)
				console.debug("FAILED: %s", res.msResponse.detail)
				return
			})
			sState.storePreviousPin = sState.storedPin
			sState.storedPin = newPin
		}

		return (
			<div className="pinEditableContainer" >
				<PinEntryTextFields storedPin={sState.storedPin}
					setNewPin={setNewPin}
					setIsValidPin={setIsValidPin}
					hidePin={false}
					showPinInStepper={false}
				/>
				<div className="EditableTextButtonContainer">
					<Button
						className={"EditableFieldButton "}
						onClick={() => {
							sState.storePreviousPin = sState.storedPin
							setEditPin(false)
						}}
						data_test="modify_pin_cancel_btn"
					>
						{l10n.commonActionCancel}
					</Button>
					<Button
						variant="contained"
						disabled={!isValidPin}
						className={"EditableFieldButton "}
						onClick={() => {
							handleSetNewPin()
							setEditPin(false)
						}}
						data_test="modify_pin_ok_btn"
					>
						{l10n.commonActionSave}
					</Button>
				</div>
			</div>
		)
	}
	// End of component for PIN entry

	const closeModalMessage = () => {
		infoMessage = {}
		setShowModalMessage(false)
	}

	const toggleGt12 = (event) => {
		setIsGt12(event.target.checked)
		// not persisted
		appFeatures.overrideGt12State(event.target.checked)
	}

	const toggleAdsMock = (event) => {
		setIsAdsMock(event.target.checked)
		// persist state
		appFeatures.persistedAdsMock = event.target.checked
	}

	const toggleTestButtons = (event) => {
		setTestButtons(event.target.checked)
		// persist state
		appFeatures.persistedTestButtons = event.target.checked
	}

	const toggleAdsTimeout = (event) => {
		setIsDefaultAdsTimeout(!event.target.checked)
		// persist state
		appFeatures.defaultAdsTimeout = !event.target.checked
	}

	const toggleNtl = (event) => {
		setIsNtl(event.target.checked)
		// persist state
		appFeatures.persistedNtl = event.target.checked
	}

	const togglePinReset = (event) => {
		setPinReset(event.target.checked)
		// persist state
		appFeatures.persistedPinReset = event.target.checked
	}

	const togglePlayerLog = (event) => {
		setPlayerLog(event.target.checked)
		// persist state
		appFeatures.persistedPlayerLog = event.target.checked
	}

	const clearAnnouncements = (event) => {
		// eslint-disable-next-line no-unused-expressions
		rootStore?.onboardingService?.clearAnnouncementsAsync(rootStore?.sso?.contractId)
	}

	const onCloseSnackbar = () => {
		console.debug("onCloseSnackbar")
		page.setSnackbarMessage(null)
	}

	// render settings page
	return (
		<div className="settingsPage">
			<Snackbar open={page.snackbarMessage !== null} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={onCloseSnackbar}
				TransitionComponent={props => <Slide {...props} direction="left" data-test="settings_snackbar" />}>
				<SnackbarContent className="settingsSnackbar" message={<div>{page.snackbarMessage}</div>}></SnackbarContent>
			</Snackbar>

			<PinEntryDialog open={openPinDialog} onPinDone={onPinEntered} onClose={handleClose} profileName={rootStore.sso.profile?.Nickname} info={"Settings"} flags={{ enableLogoff: false, fullScreen: false, restorePos: true }} data-test="pin enter section" />

			{/* MTVW-728: does not work properly, use setShowModalMessage instead
			<ErrorMessage error={rootStore.page.Player.error} isPlayerError={true} classes={{ ErrorInfoWrapper: styles.SettingsErrorInfoWrapper }} page={rootStore.page.Player} id="player" />
			*/}
			{/* text with newline(s) */}
			<ModalMessage style={{ whiteSpace: "pre-line" }}
				show={showModalMessage}
				msgInfo={infoMessage}
				onCancel={closeModalMessage}
				onConfirm={() => {
					pinGuard(onConfirmModalMessage.param1, onConfirmModalMessage.param2)() // pin gard need 2 param 
					setShowModalMessage(false)
				}}
			/>

			<div className="settingContainer">
				<div className="mainTitle">
					<h2>{l10n.featureSettingsProfileSectionTitle}</h2>
				</div>
				<div className="sectionDivider addMarginButton_8 ">
					<Divider />
				</div>

				{/* START PROFILENAME SECTION */}
				<div className="sectionContainer">
					<div className="sectionTitle">
						<h3>{l10n.featureSettingsProfileNameTitle}</h3>
					</div>
					<div className="sectionDetailsContainer">
						{editProfileName ? <ProfileNameEditable /> : <ProfileNameTextField />}
					</div>

				</div>
				<div className="sectionDivider addMarginTop_40">
					<Divider />
				</div>
				{/* END PROFILE NAME SECTION */}

				{/* START Pin SECTION */}
				<div className="sectionContainer">
					<div className="sectionTitle">
						<h3>{l10n.featureSettingsPinTitle}</h3>
					</div>
					<div className="sectionDetailsContainer">
						<div className="fieldDetailsTitle">{l10n.featureSettingsPinDescription}</div>
						{editPin ? <PinTextFieldEditable /> : <PinTextField />}

						<div className="fieldDetailsTitle bold">{l10n.featureSettingsPinActionDescription}</div>
						<div>
							<FormGroup aria-label="position" row>
								<FormControlLabel
									checked={Profile.LoginPinProtected}
									onChange={pinGuard("target.checked", setProperty(Profile.setLoginPinProtected))}
									value="stateLoginPinProtected"
									control={<Switch color="primary" />}
									label={l10n.featureSettingsPinActionSignIn}
									labelPlacement="start"
									data_test="flag_login_pin_protect"
									test_flag_value={`${Profile.LoginPinProtected}`}
								/>
								{/* MTVW-21: TODO enable once functionality is supported
								<FormControlLabel
									checked={Profile.PurchaseAuthentication}
									onChange={pinGuard("target.checked", setProperty(Profile.setPurchaseAuthentication))}
									value="statePurchaseAuthentication"
									control={<Switch color="primary" />}
									label="Für kostenpflichtige Inhalte"
									labelPlacement="start"
								/>
								<FormControlLabel
									checked={Profile.AdultAuthentication}
									onChange={pinGuard("target.checked", setProperty(Profile.setAdultAuthentication))}
									value="stateAdultAuthentication"
									control={<Switch color="primary" />}
									label="Um nicht jugendfreie Inhalte zu betrachten"
									labelPlacement="start"
								/>
								*/}
							</FormGroup>
						</div>

					</div>
				</div>
				<div className="sectionDivider addMarginTop_40">
					<Divider />
				</div>
				{/* END PIN SECTION */}

				{/* START Persönliche Empfehlungen SECTION */}
				{/* MTVW-627: disable personal recommendations
				<div className="sectionContainer">
					<div className="sectionTitle">
						<h3>{l10n.featureSettingsProfileRecommendationsPersonalTitle}</h3>
					</div>
					<div className="sectionDetailsContainer">
						<div className="fieldDetailsTitle addMarginButton_8">
							{l10n.featureSettingsProfileRecommendationsPersonalMessage} <Link underline="hover" href={l10n.configInformationalTermsDefaultUrl} target="_blank">{l10n.configInformationalTermsDefaultUrl}</Link>
						</div>
						<div className="recommendationsFlag">
							<FormControlLabel
								labelPlacement="start"
								control={
									<Switch
										checked={Profile.PersonalizedRecommendationsEnabled}
										onChange={pinGuard("target.checked", setProperty(Profile.setPersonalizedRecommendationsEnabled))}
										value="statePersonalizedRecommendations"
										color="primary"
									/>
								}
								label={l10n.featureSettingsProfileRecommendationsPersonalReceive}
							/>
						</div>

						<Divider />
						<div className="fieldButtonsContainer">
							<div className="textSetting">
								{l10n.featureSettingsProfileRecommendationsDeleteMessage}
							</div>
							<div className="ButtonContainer">
								<Button
									disableFocusRipple={true}
									variant="contained"
									className={"recommendationsButton"}
									onClick={pinGuard(null, clearRecommendations)}
								>
									{l10n.commonActionDelete}
								</Button>
							</div>
						</div>
					</div>
				</div>
				<div className="sectionDivider addMarginTop_40">
					<Divider />
				</div>
				*/}
				{/* END Persönliche Empfehlungen SECTION */}

				{/* START Profil löschen SECTION */}
				{Profile.IsMainProfile ? null :
					<>
						<div className="sectionContainer">
							<div className="sectionTitle">
								<h3>{l10n.featureSettingsDeleteProfileTitle}</h3>
							</div>
							<div className="sectionDetailsContainer">
								{/* <div className="fieldDetailsTitle">
                           Wenn Sie dieses Profil löschen, werden alle Ihre damit verknüpften
                           Daten inklusive persönlicher Aufnahmen gelöscht.
                     </div> */}

								<div className="fieldButtonsContainer">
									<div className="textSetting">
										{/* text with newline(s) */}
										{l10n.featureSettingsDeleteProfileDescription.replaceAll("\\n", "\n")}
									</div>
									<div className="ButtonContainer">
										<Button
											disableFocusRipple={true}
											variant="contained"
											className={"recommendationsButton deleteProfileButton"}
											// TOOD: confirmation dialog
											//onClick={pinGuard(null, deleteProfile)}
											onClick={() => {
												setShowModalMessage(true)
												infoMessage = DeleteProfileMessage
												onConfirmModalMessage = { param1: null, param2: deleteProfile } //used in pinGuard
											}}
										>
											{l10n.featureSettingsDeleteProfileAction}
										</Button>
									</div>
								</div>
							</div>
						</div>
						<div className="sectionDivider addMarginTop_40">
							<Divider />
						</div>
					</>
				}
				{/* END Profil löschen SECTION */}

				{/* PIN RESET disabled since v3.2206.01 */}
				{!appFeatures.settingsPinResetEnabled ? null :
					<>
						<div className="sectionContainer">
							<div className="sectionTitle">
								<h3>Pin Reset</h3>
							</div>
							<div className="sectionDetailsContainer">
								<div className="recommendationsFlag">
									<FormControlLabel
										labelPlacement="start"
										control={
											<Switch
												checked={hasPinReset}
												onChange={togglePinReset}
												value="hasPinReset"
												color="primary"
											/>
										}
										label="Pin Reset aktiviert"
									/>
								</div>
							</div>
						</div>
						<div className="sectionDivider addMarginTop_40">
							<Divider />
						</div>
					</>
				}
				{/* END PIN RESET */}
				{/* GT12 state */}
				{!appFeatures.settingsGt12StateEnabled ? null :
					<>
						<div className="sectionContainer">
							<div className="sectionTitle">
								<h3>GT12 Status</h3>
							</div>
							<div className="sectionDetailsContainer">
								<div className="recommendationsFlag">
									<FormControlLabel
										labelPlacement="start"
										control={
											<Switch
												checked={isGt12}
												onChange={toggleGt12}
												value="gt12"
												color="primary"
											/>
										}
										label="GT12 aktiviert"
									/>
								</div>
							</div>
						</div>
						<div className="sectionDivider addMarginTop_40">
							<Divider />
						</div>
					</>
				}
				{/* END GT12 state */}
				{/* Reset onboarding */}
				{!appFeatures.settingsOnboardingEnabled ? null :
					<>
						<div className="sectionContainer">
							<div className="sectionTitle">
								<h3>Onboarding</h3>
							</div>
							<div className="sectionDetailsContainer">
								<div className="fieldButtonsContainer">
									<div className="textSetting">
										Onboarding kann für Testzwecke reaktiviert werden
									</div>
									<div className="ButtonContainer">
										<Button
											disableFocusRipple={true}
											variant="contained"
											className={"recommendationsButton deleteProfileButton"}
											// TOOD: confirmation dialog
											//onClick={pinGuard(null, deleteProfile)}
											onClick={() => {
												clearAnnouncements()
											}}
										>
											Reaktivieren
										</Button>
									</div>
								</div>
							</div>
						</div>
						<div className="sectionDivider addMarginTop_40">
							<Divider />
						</div>
					</>
				}
				{/* END Reset onboarding */}
				{/* GT12 ADS MOCK */}
				{!appFeatures.settingsAdsMockEnabled ? null :
					<>
						<div className="sectionContainer">
							<div className="sectionTitle">
								<h3>Ads Mock</h3>
							</div>
							<div className="sectionDetailsContainer">
								<div className="recommendationsFlag">
									<FormControlLabel
										labelPlacement="start"
										control={
											<Switch
												checked={isAdsMock}
												onChange={toggleAdsMock} //; appFeatures.persistedAdsMock = event.target.checked
												value="adsMock"
												color="primary"
											/>
										}
										label="Ads Mock aktiviert"
									/>
								</div>
							</div>
						</div>
						<div className="sectionDivider addMarginTop_40">
							<Divider />
						</div>
					</>
				}
				{/* END GT12 ADS MOCK */}
				{/* GT12 MARKER TEST BUTTONS */}
				{!appFeatures.settingsTestButtonsEnabled ? null :
					<>
						<div className="sectionContainer">
							<div className="sectionTitle">
								<h3>Marker Test Buttons</h3>
							</div>
							<div className="sectionDetailsContainer">
								<div className="recommendationsFlag">
									<FormControlLabel
										labelPlacement="start"
										control={
											<Switch
												checked={hasTestButtons}
												onChange={toggleTestButtons}
												value="hasTestButtons"
												color="primary"
											/>
										}
										label="Test Buttons aktiviert"
									/>
								</div>
							</div>
						</div>
						<div className="sectionDivider addMarginTop_40">
							<Divider />
						</div>
					</>
				}
				{/* END GT12 MARKER TEST BUTTONS */}
				{/* GT12 ADS TIMEOUT */}
				{!appFeatures.settingsAdsTimeoutEnabled ? null :
					<>
						<div className="sectionContainer">
							<div className="sectionTitle">
								<h3>Ads Timeout</h3>
							</div>
							<div className="sectionDetailsContainer">
								<div className="recommendationsFlag">
									<FormControlLabel
										labelPlacement="start"
										control={
											<Switch
												checked={!isDefaultAdsTimeout}
												onChange={toggleAdsTimeout}
												value="isDefaultAdsTimeout"
												color="primary"
											/>
										}
										label="Konservativer Timeout"
									/>
								</div>
							</div>
						</div>
						<div className="sectionDivider addMarginTop_40">
							<Divider />
						</div>
					</>
				}
				{/* END GT12 ADS TIMEOUT */}
				{/* MTVW-593, MTVW-848: NTL */}
				{!appFeatures.settingsNtlEnabled ? null :
					<>
						<div className="sectionContainer">
							<div className="sectionTitle">
								<h3>Near To Live</h3>
							</div>
							<div className="sectionDetailsContainer">
								<div className="recommendationsFlag">
									<FormControlLabel
										labelPlacement="start"
										control={
											<Switch
												checked={isNtl}
												onChange={toggleNtl}
												value="isNtl"
												color="primary"
											/>
										}
										label="NTL aktiviert"
									/>
								</div>
							</div>
						</div>
						<div className="sectionDivider addMarginTop_40">
							<Divider />
						</div>
					</>
				}
				{/* END MTVW-593, MTVW-848: NTL */}
				{/* PLAYER LOG */}
				{!appFeatures.settingsPlayerLogEnabled ? null :
					<>
						<div className="sectionContainer">
							<div className="sectionTitle">
								<h3>Player Log</h3>
							</div>
							<div className="sectionDetailsContainer">
								<div className="recommendationsFlag">
									<FormControlLabel
										labelPlacement="start"
										control={
											<Switch
												checked={hasPlayerLog}
												onChange={togglePlayerLog}
												value="hasPlayerLog"
												color="primary"
											/>
										}
										label="Player Log aktiviert"
									/>
								</div>
							</div>
						</div>
						<div className="sectionDivider addMarginTop_40">
							<Divider />
						</div>
					</>
				}
				{/* END PLAYER LOG */}


				{/* START KONTO SECTION */}
				<div className="sectionContainer kontoContainer">
					<div className="sectionTitle">
						<h2>{l10n.featureSettingsAccountSectionTitle}</h2>
					</div>
					{/* <div className="sectionDetailsContainer"></div>  */}
				</div>
				<div className="sectionDivider">
					<Divider />
				</div>
				{/* END KONTO SECTION */}


				{/* START Aufnahmen SECTION */}
				<div className="sectionContainer ">
					<div className="sectionTitle">
						<h3>{l10n.featureSettingsRecordingsTitle}</h3>
					</div>
					<div className="sectionDetailsContainer">
						<div className="fieldDetailsTitle">
							{l10n.featureSettingsStorageDescription1}
						</div>
						<div className="recordingTimeInfoContainer">
							<div>
								<span className="recordingHoursInfoLabel">{l10n.featureSettingsStorageTotal}</span> <span>{Customer?.NpvrQuotaInMinutes} {l10n.featureSettingsHours}</span>
							</div>
							<div>
								<span className="recordingHoursInfoLabel">{l10n.featureSettingsStorageAvailable}</span> <span>{Customer?.NpvrQuotaRemainingInMinutes} {l10n.featureSettingsHours}</span>
							</div>
						</div>
						<div className="textSetting">
							{l10n.featureSettingsStorageDescription2}
						</div>
					</div>
				</div>
				<div className="sectionDivider addMarginTop_40">
					<Divider />
				</div>
				{/* END Aufnahmen SECTION */}

				{/* START Replay SECTION */}
				{!Profile.IsMainProfile ? null :
					<>
						<div className="sectionContainer">
							<div className="sectionTitle">
								<h3>{l10n.featureSettingsReplayTitle}</h3>
							</div>
							<div className="sectionDetailsContainer">
								<div className="fieldDetailsTitle"></div>
								<div className="fieldButtonsContainer">
									<div className="textSetting">
										{/* text with newline(s) */}
										{l10n.featureSettingsReplayDescription.replaceAll("\\n", "\n")}
									</div>
									<div className="ButtonContainer">
										{/* MTVW-728: Use channelsMs
										<Button
											disableFocusRipple={true}
											variant="contained"
											className={channelsOptIn ? "replayButtonInactive" : "replayButtonActive"}
											//onClick={pinGuard(null, toggleAllOptInChannels)}
											onClick={() => {
												if (channelsOptIn) {
													setShowModalMessage(true)
													infoMessage = ReplayMessage
													onConfirmModalMessage = { param1: null, param2: toggleAllOptInChannels } //used in pinGuard
												} else {
													pinGuard(null, toggleAllOptInChannels)()
												}
											}}
										>
											{channelsOptIn ? l10n.featureSettingsActionDeactivate : l10n.featureSettingsActionActivate}
										</Button>
										*/}
										<Button
											disableFocusRipple={true}
											variant="contained"
											//className={"replayButtonActive"}
											className={"replayRefeshButton"}
											onClick={() => {
												pinGuard(null, optInAllChannels)()
											}}
										>
											{/*{l10n.featureSettingsActionActivate}*/}
											{l10n.featureSettingsOptInChannels}
										</Button>
									</div>
								</div>
							</div>
						</div>
						<div className="sectionDivider addMarginTop_40">
							<Divider />
						</div>
					</>}
				{/* END Replay SECTION */}

				{/* START Kontoinformationen SECTION */}
				<div className="sectionContainer">
					<div className="sectionTitle">
						<h3>{l10n.featureSettingsAccountInformationTitle}</h3>
					</div>
					<div className="sectionDetailsContainer">
						<div className="fieldDetailsTitle"></div>
						<div className="accountInfoContainer">
							<div>
								<span className="accountInfoLabel">{l10n.featureSettingsAccount}</span> <span>{Profile.sso.UserName} </span>
							</div>
							<div>
								<span className="accountInfoLabel">{l10n.featureSettingsVersion}</span> <span>{appVersion()}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="sectionDivider addMarginButton_32 addMarginTop_40">
					<Divider />
				</div>
				{/* END Kontoinformationen SECTION */}

			</div>
		</div>
	)
})

export const Settings = CreateComponent(null, false, true, () => {
	const page = rootStore.page.Settings

	//useEffect(...page.getMountBody())
	useEffect(() => {
		page.getMountBody()
		// request pin also if it has been already entered at login
		sState.storedPin = ""
		sState.storePreviousPin = ""
		// trigger fetch of main user contact info
		rootStore.sso.mainUserContactInfo()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []) // Pass an empty array to run only callback on mount only.

	return (
		<>
			<SettingsBody page={page} />
		</>
	)
})
