/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-03
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-11
 */

import { action, computed, flow, observable, makeObservable } from "mobx"
import { ClassAbstract } from "store/ClassTools"
//import { SsoAjax } from "store/model/sso/SsoAjax"
import { SsoBrowserStore } from "store/model/sso/SsoBrowserStore"
import { SsoProfile } from "store/model/sso/SsoProfile"
import { lazyObject, makeMapCollection, applyDataToObject, /*, makeObject*/ } from "store/ModelTools"
import { rootStore } from "store/RootStore"
//import { ErrorBase } from "store/ErrorBase"
//import { SsoToken } from "store/model/sso/SsoToken"
//import { logSsoError } from "utils/BugsnagReporter"
import CryptoJS from "crypto-js"

const passPhrase = "djso_%Q7?329"

export class Sso extends ClassAbstract {
	profileStore = new Map()
	profileOrder = []
	_contractId = null
	_deferAuthentication = false
	// main user contact info
	UserName = null
	EMail = null
	MobileNumber = null
	// MTVW-657: predefined channellists
	authResult = null

	error = null

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			profileStore: observable.shallow,
			profileOrder: observable.shallow,
			_deferAuthentication: observable,
			UserName: observable,
			EMail: observable,
			MobileNumber: observable,
			deferAuthentication: action,
			profile: computed,
			contractId: computed,
			// MTVW-427 unused, Bugsnag 'The same observable object cannot appear twice in the same tree
			//isAuthenticatedAccount: computed,
			isAuthenticatedProfile: computed,
			error: observable.shallow,
			keepSignedIn: computed,
			changeProfile: computed,
			handleChangeProfile: action,
			selectProfile: action,
			closeOtherProfiles: action,
			getLoginProfiles: action,
			pin: computed,
			setPin: action,
		})
	}

	/**
	 * this will be stored in local storage
	 * @returns {SsoBrowserStore}
	 */
	get browserStore() {
		return lazyObject(this, "browserStore", SsoBrowserStore)
	}

	/**
	 * @returns {SsoBrowserStore}
	 */
	// MTVW-427 unused, Bugsnag 'The same observable object cannot appear twice in the same tree
	/*
	get tokenSubscriber() {
		return this.browserStore.tokenSubscriber
	}

	set tokenSubscriber(token) {
		this.browserStore.tokenSubscriber = token
	}
	*/

	/**
	 * @returns {WaitQueue}
	 */
	get waitForAuth() {
		return this._root.waitForAuth
	}

	/**
	 * @returns {SsoProfile}
	 */
	get profile() {
		//console.debug("get profile %o", this.browserStore.currentProfileId)
		return this.getProfile(this.browserStore.currentProfileId)
	}

	get contractId() {
		return this._contractId ? this._contractId : ""
	}

	deferAuthentication(value) {
		this._deferAuthentication = value
	}

	set keepSignedIn(value) {
		this.browserStore.keepSignedIn = value
	}

	get keepSignedIn() {
		//console.debug("get keepSignedIn %o", this.browserStore.keepSignedIn)
		//console.debug("get keepSignedIn %o", this.browserStore.keepSignedIn === null ? true : this.browserStore.keepSignedIn)
		return this.browserStore.keepSignedIn === null ? true : this.browserStore.keepSignedIn
	}

	set changeProfile(value) {
		this.browserStore.changeProfile = value
	}

	get changeProfile() {
		return this.browserStore.changeProfile === null ? true : this.browserStore.changeProfile
	}

	// MTVW-185
	set lastSearch(value) {
		this.browserStore.lastSearch = value
	}

	get lastSearch() {
		return this.browserStore.lastSearch
	}

	setPin(value) {
		//this.browserStore.pin = value
		this.browserStore.pin = CryptoJS.AES.encrypt(value, passPhrase).toString()
	}

	get pin() {
		// traxis pin below commented
		//return rootStore.singleton.profile.Data.Pin

		//return this.browserStore.pin
		const bytes = CryptoJS.AES.decrypt(this.browserStore.pin, passPhrase)
		return bytes.toString(CryptoJS.enc.Utf8)
	}

	setLoggedOff(value) {
		this.browserStore.loggedOff = value
	}

	get loggedOff() {
		return this.browserStore.loggedOff
	}

	/**
	 * @returns {SsoProfile}
	 */
	getProfile(id) {
		//console.debug("getProfile id %s, has %s", id, this.profileStore.has(id))
		return id === null ? null : this.profileStore.has(id) ? this.profileStore.get(id) : null
	}

	// MTVW-427 unused, Bugsnag 'The same observable object cannot appear twice in the same tree
	/*
	get isAuthenticatedAccount() {
		return this.tokenSubscriber
	}
	*/

	get isAuthenticatedProfile() {
		// checks for this.browserStore.currentProfileId
		//console.debug("isAuthenticatedProfile %s, defer %s", this.profile?.isAuthProfile === true, this._deferAuthentication)
		const isAuthenticated = this.profile?.isAuthProfile === true && this._deferAuthentication === false
		//console.debug("isLoggedOff %s, auth %s, loginReslt %o", this.loggedOff, isAuthenticated, this.profile?.loginResult)
		if (isAuthenticated && this.loggedOff && this.profile?.loginResult) {
			// MTVW-656: go to liveTv
			// moved to here from handleLogOffAsync to avoid calling setupStreamAsync which generates error when logged off
			//console.debug("fixUrl")
			this.fixUrl()
			// MTVW-656: moved from authenticateAsync
			// MTVW-391
			this.setLoggedOff(false)
		}
		return isAuthenticated
		//return false
	}

	getLoginProfiles = flow(function* () {
		this.profileOrder = []
		let profiles = null
		try {
			profiles = yield rootStore.loginService.getLoginProfilesAsync()
		}
		catch (e) {
			console.debug("catch in getLoginProfiles %o", e)
			return
		}
		this._updateProfileOrder(profiles)
		//rootStore.sso.profile.refreshTokens()
		//console.debug("getLoginProfiles Profile %o", rootStore.sso.profile)
	})

	_updateProfileOrder(profiles) {
		if (!profiles) return
		const currentProfile = this.profile
		const profileStore = new Map()
		const respMap = new Map()
		profiles?.forEach(i => {
			//console.debug("forEach %o, %o", i, i.ProfileIdentificationGuid)
			respMap.set(i.ProfileIdentificationGuid, i)
		})
		makeMapCollection(
			profileStore,
			respMap,
			i => i.ProfileIdentificationGuid,
			i => new SsoProfile(this, i?.ProfileIdentificationGuid)
		)
		// keep current profile
		if (currentProfile) {
			//profileStore.set(this.profile.ProfileIdentificationGuid, this.profile)
			profileStore.set(currentProfile.ProfileIdentificationGuid, currentProfile)
		}

		// order version
		let profileOrder = []
		let storedProfileId = null
		profiles?.forEach(i => {
			profileOrder.push(this.profileStore.get(i.ProfileIdentificationGuid))
			if (this.browserStore.currentProfileId === i.ProfileIdentificationGuid) storedProfileId = i.ProfileIdentificationGuid
		})
		if (storedProfileId === null) {
			// make sure that we don't use a non existing stored profile id
			this.browserStore.currentProfileId = null
		}
		// apply the new profile store and profile order
		this.profileStore = profileStore
		//MTVW-627: sort profiles
		this._sortProfiles(profileOrder)
		this.profileOrder = profileOrder

		// MTVW-609: avoid backend errors with missing header properties
		rootStore.sso.browserStore.setContractId(this._contractId)
	}

	_setupProfileOrder(profiles) {
		if (!profiles) return
		const respMap = new Map()
		profiles?.forEach(i => {
			//console.debug("forEach %o, %o", i, i.ProfileIdentificationGuid)
			respMap.set(i.ProfileIdentificationGuid, i)
		})
		//console.debug("respMap %o", respMap)
		makeMapCollection(
			this.profileStore,
			respMap,
			i => i.ProfileIdentificationGuid,
			i => new SsoProfile(this, i?.ProfileIdentificationGuid)
		)

		// order version
		this.profileOrder = []
		let storedProfileId = null
		profiles?.forEach(i => {
			this.profileOrder.push(this.profileStore.get(i.ProfileIdentificationGuid))
			if (this.browserStore.currentProfileId === i.ProfileIdentificationGuid) storedProfileId = i.ProfileIdentificationGuid
		})
		if (storedProfileId === null) {
			// make sure that we don't use a non existing stored profile id
			this.browserStore.currentProfileId = null
		}

		//MTVW-627: sort profiles
		this._sortProfiles(this.profileOrder)
		// MTVW-609: avoid backend errors with missing header properties
		rootStore.sso.browserStore.setContractId(this._contractId)
	}

	//MTVW-627: sort profiles
	_sortProfiles(profiles) {
		profiles = profiles.sort((a, b) => {
			//console.debug("a %o, b %o", a?.Nickname, b?.Nickname, a?.Nickname.localeCompare(b?.Nickname))
			return a?.Nickname.localeCompare(b?.Nickname)
		})
		const from = profiles.findIndex(i => i?.IsMainProfile === true)
		const element = profiles.splice(from, 1)[0]
		profiles.splice(0 /*to*/, 0, element)
		//console.debug("_sortProfiles", profiles, from, element)
	}

	// entry point called from Rootstore._initAsync
	handleAuthenticateAsync = flow(function* () {
		// In case we have a valid cookie / tokens we can get the login profiles directly without using authenticateAsync (which requires user credentials).
		// Otherwise authenticateAsync will return the user results if authentication succeeds

		//console.debug("changeProfile %s", this.changeProfile)
		// MTVW-391: check loggedOff
		if ((!this.keepSignedIn && !this.changeProfile) || this.loggedOff === true) {
			rootStore.isLoading = false
			//console.debug("test handleAuthenticateAsync 1")
			return
		}
		//console.debug("test handleAuthenticateAsync 2")
		this.changeProfile = false
		rootStore.isLoading = true

		/* before re-auth suport
		let profiles = null
		try {
			profiles = yield rootStore.loginService.getLoginProfilesAsync()
		}
		catch (e) {
			console.debug("catch in handleAuthenticateAsync %o", e)
			rootStore.isLoading = false
			return
		}

		this._setupProfileOrder(profiles)
		*/

		let authResult = null
		try {
			authResult = yield rootStore.loginService.authenticateAsync(null, null, this.keepSignedIn, true)
		}
		catch (e) {
			console.debug("catch in handleAuthenticateAsync %o", e)
			rootStore.isLoading = false
			// no error display
			rootStore.page.Player.error = null
			return
		}

		//console.debug("authResult %o", authResult)
		this._contractId = authResult.ContractId
		// MTVW-657: predefined channellists
		this.authResult = authResult
		//this.profileOrder = authResult.profiles

		// MTVW-435, need to have main user info for pin reset
		yield this.mainUserContactInfo()
		this._setupProfileOrder(authResult?.profiles)

		//console.debug("profileOrder %o url %s", this.profileOrder, window.location.href)

		//console.debug("profileOrder %o", this.profileOrder)
		//this.waitForAuth.setResolve()
		//this.tokenSubscriber.isValid = true

		//console.debug("handleAuthenticateAsync profile", rootStore.sso.profile)
		rootStore.isLoading = false
	})

	// invoked from login dialog
	authenticateAsync = flow(function* (user, pw) {
		const authResult = yield rootStore.loginService.authenticateAsync(user, pw, this.keepSignedIn, false)
		//console.debug("authResult %o", authResult)
		this._contractId = authResult.ContractId
		// MTVW-657: predefined channellists
		this.authResult = authResult
		//this.profileOrder = authResult.profiles

		this._setupProfileOrder(authResult?.profiles)
		// MTVW-391
		// MTVW-656: moved to condition in isAuthenticatedProfile
		//this.setLoggedOff(false)

		//console.debug("profileOrder %o url %s", this.profileOrder, window.location.href)
		// MTVW-435
		yield this.mainUserContactInfo()

		//this.waitForAuth.setResolve()
		//this.tokenSubscriber.isValid = true

		//rootStore.isLoading = false
	})

	/*
	handleTokenVerificationAsync = flow(function* (isSoftCheck = null) {
		console.log("@@handleTokenVerificationAsync(isSoftCheck=%o)", isSoftCheck)
		// avoid Error 401 so use refresh tokens if possible
		try {
			if (this.tokenSubscriber) {
				yield this.tokenSubscriber.refreshTokenAsync()
				if (!this.tokenSubscriber.isValid) {
					return false
				}
			}
			// current user token
			if (this.profile?.tokenProfile) {
				yield this.profile?.tokenProfile.refreshTokenAsync()
				if (!this.profile.tokenProfile.isValid) {
					return false
				}
			}
			if (this.tokenSubscriber && this.profile?.tokenProfile) {
				this.waitForAuth.setResolve()
				return true
			}
		} catch (e) {
			console.debug("@@handleTokenVerificationAsync() e=%o", e)
			logSsoError(e, "handleTokenVerificationAsync")
			if (isSoftCheck !== true) {
				yield this.handleAuthenticateAsync()
			}
		}
		return false
	})
	*/

	mainUserContactInfo = flow(function* () {
		const result = yield rootStore.loginService.getMainUserContactInfoAsync()
		applyDataToObject(this, result)
		//console.debug("main user contact info %o, %o, %s, %s", result, this, rootStore.sso.EMail, rootStore.sso.MobileNumber)
		// MTVW-607: moved from rootStore._initAsync to avoid traxis call before login
		// time sync from server at start
		yield rootStore.time.handleSyncDateFromServerAsync()
		rootStore.waitForTimeSync.setResolve()
	})

	// MTVW-405: add parameter toLiveTv
	handleChangeProfile(id = null, toLiveTv = true) {
		console.log("@(id=%o)", id)
		this.waitForAuth.setRestart()
		this.selectProfile(id)
		this.closeOtherProfiles()
		// select live TV
		if (toLiveTv) this.fixUrl()
		// MTVW-235: No reload when changing profile
		//this.changeProfile = true
		this.setPin("")
		// MTVW-185
		this.lastSearch = ""
		//this.browserStore.saveStorage()
		// MTVW-235, MTVW-33
		const channelListId = rootStore.sso.browserStore.getChannelListId()
		// set active channel to null so that setActiveChannelList will select the first channel from the channel list
		rootStore.page.ChannelListRepo.setActiveChannel(null)
		rootStore.page.ChannelListRepo.setActiveChannelList(channelListId, true)
		// MTVW-157: A reload will be performed! MTVW-235: No reload anymore.
		//this.handleAuthenticateAsync()
		// MTVW-471
		rootStore.appSession.createNewSessionId()
		//console.debug("setting volume", rootStore.sso.browserStore.getVolume())
		// MTVW-96: Apply volume and mute state
		rootStore.page.Player.settings.volume = rootStore.sso.browserStore.getVolume()
		rootStore.page.Player.settings.isMuted = rootStore.sso.browserStore.getMuted()
		if (import.meta.env.VITE_PLAYER === "vo") {
			rootStore.page.Player.playerLiveTvVo.setClose()
		}
	}

	deleteAllCookies() {
		const cookies = document.cookie.split(";"); for (const cookie of cookies) {
			const eqPos = cookie.indexOf("=");
			const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
		}
	}

	handleLogOffAsync = flow(function* () {
		console.log("@@handleLogOffAsync()")
		try {
			// MTVW-391
			this.setLoggedOff(true)
			this.deleteAllCookies()
			// MTVW-656: invalidate loginResult
			if (this.profile) this.profile.loginResult = null
			yield rootStore.loginService.logoff()
		} catch (e) {
			console.debug("catch in handleLogOffAsync")
		}

		// MTVW-656: moved to condition in isAuthenticatedProfile
		//this.fixUrl()
		// MTVW-185
		this.lastSearch = ""
		/*
		this.waitForAuth.setRestart()
		this.profileStore.clear()
		this.profileOrder = []
		rootStore._init()
		*/
		window.location.reload()
		//this.browserStore.removeStorage()
		//window.location.href = import.meta.env.VITE_URL_SSO_LOGOUT + encodeURI(window.location.href)
	})

	// set the URL to live TV
	fixUrl = () => {
		//console.debug("location %o", window.location)
		// Force start page (Live TV)
		//winow.location.href = window.location.origin + "/?#/"
		let href = window.location.href
		let stripIndex = href.lastIndexOf("/#/")
		if (stripIndex !== -1) stripIndex += 3
		if (stripIndex === -1) {
			// this should no be necessary anymore
			stripIndex = href.lastIndexOf("/?#/")
			if (stripIndex !== -1) {
				href = href.replace("/?#/", "/#/")
				stripIndex += 3
			}
		}
		if (stripIndex !== -1) href = href.substring(0, stripIndex)
		window.location.href = href
		//console.debug("location after %o", window.location)
	}

	selectProfile(id) {
		//console.info("@(id=%o) ->", id)
		//console.debug("selectProfile %o %o", id, this.profile)
		if (this.browserStore.currentProfileId !== id) {
			// forget stored pin
			this.setPin("")
		}
		if (this.profileStore.has(id)) {
			this.browserStore.currentProfileId = id
		}
		return this
	}

	closeOtherProfiles() {
		console.info("@() ->")
		this.profileStore?.forEach(i => {
			if (i.id !== this.profile?.id) {
				i.removeTokens()
			}
		})
		return this
	}

	// MTVW-435
	handleResetPinAsync = flow(function* (contactType) {
		const result = yield rootStore.loginService.resetPinAsync(this.browserStore.currentProfileId, contactType)
		//console.debug("handleResetPinAsync %o", result)
		return result
	})
}
