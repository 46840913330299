/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-06
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-10
 */

import { types as t } from "mobx-state-tree"
import { TraxisAbstract } from "store/api/TraxisAbstract"
import { TIME_FORMAT_BACKEND } from "store/model/Time"
import { EventFull } from "store/model/traxis/Event"
import { rootStore } from "store/RootStore"
import { moment } from "store/util/moment"

const GetEventModel = t
	.compose(
		"GetEventModelAbstract",
		EventFull,
		t.model({
			Channels: t.model({
				Channel: t.array(
					t.model({
						id: t.identifier
					})
				)
			}),
			isReady: t.maybeNull(t.boolean)
		})
	)
	.views(self => ({
		get Channel() {
			return rootStore.singleton.channelsResolveIdentifier(self.Channels.Channel[0].id)
		}
	}))
	// added for mobx 6.3 which is more strict
	.actions(self => ({
		setReady(value) {
			self.isReady = value
		}
	}))

class GetEventAbstract extends TraxisAbstract {
	//_onStartCreate = true
	//_onStartRemove = true

	get _model() {
		return GetEventModel
	}

	_getParseResp(data) {
		return data.Channel.Events.Event[0]
	}

	getXmlReq() {
		return `<?xml version="1.0" encoding="utf-8"?>
		<Request xmlns="urn:eventis:traxisweb:1.0">
			<Identity>
				<ProfileId>${this.profileId}</ProfileId>
			</Identity>
			${this._getXmlBody()}
		</Request>`
	}

	//IsRecorded
	_getXmlBodyOptionProps() {
		return `<Option type="Props">AvailabilityStart,AvailabilityEnd,AspectRatio,HasTstv,Is3D,IsAvailable,IsHD,IsLive,IsRepeat,IsNetworkRecordingViewableOnCpe,IsNetworkRecordingAllowed,OnReminders,CaptionLanguages,AudioMixType,DubbedLanguages,SupplementalAudioDescriptions,SupplementalCaptionLanguages,NetworkRecordingEntitlementState</Option>`
	}

	_getXmlBodySubQueries() {
		return `<SubQueries>
			<SubQuery relationName="Channels"></SubQuery>
			<SubQuery relationName="Titles">
				<Options>
					<Option type="Props">CustomProperties,Name,Pictures,AllGenres,LongSynopsis,MediumSynopsis,ShortSynopsis,EpisodeName,SeriesCount,SeriesCollection,Credits,DurationInSeconds,OriginalLanguages,Ratings,ProductionDate,Studio,ProductionLocations,Genres,PersonalRating,OnWishList</Option>
					<Option type="Limit">1</Option>
				</Options>
				<SubQueries>
					<SubQuery relationName="SeriesCollection">
						<Options>
							<Option type="Props">Pictures,Name,RelationOrdinal,ParentSeriesCount,Type</Option>
							<Option type="Limit">1</Option>
						</Options>
						<SubQueries>
							<SubQuery relationName="ParentSeriesCollection">
								<Options>
									<Option type="Props">RelationOrdinal</Option>
									<Option type="Limit">1</Option>
								</Options>
							</SubQuery>
						</SubQueries>
					</SubQuery>
				</SubQueries>
			</SubQuery>
		</SubQueries>`
	}
}

export class GetEventById extends GetEventAbstract {
	_getParseResp(data) {
		return data.Event
	}

	_getHashReq(data) {
		return `GetEventById_${this.profileId}_${this._oVars.EventId}`
	}

	_getXmlBody() {
		return `<ResourceQuery resourceType="Event" resourceId="${this._oVars.EventId}">
			<Options>
				${this._getXmlBodyOptionProps()}
			</Options>
			${this._getXmlBodySubQueries()}
		</ResourceQuery>`
	}
}

export class GetEventByChannelTime extends GetEventAbstract {
	_getParseResp(data) {
		return data.Channel.Events.Event[0]
	}

	_getHashReq(data) {
		return `GetEventByChannelTime_${this.profileId}_${this._oVars.ChannelId}_${this._getTime()}`
	}

	_getTime() {
		return moment(this._oVars.time ?? rootStore.time.getTimeStampTick(60))
			.utc()
			.format(TIME_FORMAT_BACKEND)
	}

	_getXmlBody() {
		const time = this._getTime()

		return `<ResourceQuery resourceType="Channel" resourceId="${this._oVars.ChannelId}">
				<SubQueries>
					<SubQuery relationName="Events">
						<Options>
                  	${this._getXmlBodyOptionProps()}
                  	<Option type="Filter"><![CDATA[AvailabilityEnd>${time}&&AvailabilityStart<=${time}&&Titles>0]]></Option>
							<Option type="Limit">1</Option>
						</Options>
						${this._getXmlBodySubQueries()}
					</SubQuery>
				</SubQueries>
			</ResourceQuery>`
	}
}
