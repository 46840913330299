import { types as t } from "mobx-state-tree"

export const RefElemMixin = t
	.model("RefElemMixin", {})
	.volatile(self => ({
		_tRefElements: new Map()
	}))
	.views(self => ({
		_runRefElem() {
			self._tRefElements.forEach(i => {
				if (i.refElem) {
					//console.debug("refElem %o  cb %o", i.refElem, i.callback)
					i.callback(i.refElem, self)
				}
			})
		}
	}))
	.actions(self => ({
		setRefElem(refElem, name, callback) {
			self._tRefElements.set(name, { refElem, callback })
			if (refElem !== null) {
				callback(refElem, self)
			}
			return self
		}
	}))
