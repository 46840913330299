import { HoursSelector } from "./selectors/HoursSelector"
import { DateSelector } from "./selectors/DateSelector"
import React from 'react'

export const DataSelectorBars = (props) => {
	const { page, windowSize } = props
	//console.debug("DataSelectorBars windowSize %s", windowSize)
	const DAYS_BEFORE = 7
	const DAYS_AFTER = 7

	return !windowSize ? null : (
		<div>
			<DateSelector daysBefore={DAYS_BEFORE} daysAfter={DAYS_AFTER} page={page} windowSize={windowSize} />
			<HoursSelector daysBefore={DAYS_BEFORE} daysAfter={DAYS_AFTER} page={page} windowSize={windowSize} />
		</div>
	)
}
