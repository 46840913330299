import { Paper, List } from "@mui/material"
// icon does not scale when zooming on Safari
//import DeleteIcon from "@mui/icons-material/Delete"
//import DragIcon from "@mui/icons-material/DragHandle"
import classnames from "classnames"
import React, { useEffect, useLayoutEffect, useState } from "react"
import { CreateComponent } from "components/utils/CreateComponent"
//import { rootStore } from "store/RootStore"
import { CleConfig } from "scenes/ChannelListEditor/ChannelListEditor"
import { useDrop } from 'react-dnd'
import update from 'immutability-helper'
import ListChannelWithHook, { ListChannelPreview } from './ListChannel'
import { usePreview } from 'react-dnd-preview'
import scrollHelper from "./ScrollHelper"
import { isTouchAvailable } from "utils/Utils"

const styles = theme => ({
	CleListWrapper: {
		//position: "relative",
		display: "flex",
		marginLeft: -CleConfig.listNumberingWidth
	},
	CleListWrapperScrollable: {
		//position: "fixed",
		overflowY: "scroll",
		scrollbarWidth: "none",
		marginTop: 6,
		"&::-webkit-scrollbar": { display: "none" },
		// TODO: remove? NOT working
		//paddingBottom: 64 - 15
	},
})

const CleListImpl = CreateComponent(styles, false, true, ({ page, classes, windowDimensions, props }) => {
	//const { canDrop, isOver, connectDropTarget } = props
	//console.debug("CleListImpl props %o", props)

	const [addedIndex, setAddedIndex] = useState(-1)
	useEffect(() => {
		page.channelAddedCallback = (index) => {
			console.debug("addex ix %s", index)
			setAddedIndex(index)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useLayoutEffect(() => {
		if (addedIndex > -1) {
			// scroll the added channel into the view
			const elem = document.getElementById(`${(CleConfig.listElemIdPrefix)}${(addedIndex)}`)
			if (elem !== null) {
				elem.scrollIntoView()
			}
			setAddedIndex(-1)
		}
	})

	const setChannels = (param) => {
		//console.debug("list setChannels %o", param)
		page.setListChannelIds(param)
	}

	const moveChannel = (dragIndex, hoverIndex) => {
		const dragChannel = page.listChannelIds[dragIndex]
		console.debug("moveCh ix %s, %s, ch %s, %o", dragIndex, hoverIndex, dragChannel, props)
		setChannels(
			update(page.listChannelIds, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, dragChannel],
				],
			}),
		)
	}

	const dropHandler = (item, monitor) => {
		console.debug("in dropHandler %o, %s, %o, %o", item, item.Name, monitor, monitor.getDifferenceFromInitialOffset())
		scrollHelper.removeScrollMonitor()
	}

	const hoverHandler = (item, monitor) => {
		//console.debug("hover props %o monitor %o comp %o item %o", props, monitor, component, monitor.getItem())
		//const item = monitor.getItem()
		//console.debug("item %o", item)
		let dragIndex = item.index
		let hoverIndex = item.hoverIndex
		if (hoverIndex > page.listChannelIds.length - 1) hoverIndex = page.listChannelIds.length - 1
		const sourceListId = item.listId
		const hoverBoundingRect = document.getElementById(`${(CleConfig.listElemIdPrefix)}${(hoverIndex)}`)?.getBoundingClientRect()
		//console.debug("hoverHandler", CleConfig.listElemIdPrefix, hoverIndex)
		if (!hoverBoundingRect) {
			//console.debug("no hoverBoundingRect", CleConfig.listElemIdPrefix, hoverIndex)
			return
		}
		const boundary = monitor.getInitialSourceClientOffset().y + monitor.getDifferenceFromInitialOffset().y
		//console.debug("drag: %s, hover %s, %s", dragIndex, hoverIndex, item.Name, hoverBoundingRect.top + CleConfig.listCellHeight / 2, boundary, monitor.getDifferenceFromInitialOffset().y)

		if (boundary >= hoverBoundingRect.top + (CleConfig.listCellHeight + CleConfig.listCellMarginBottom) / 2) {
			console.debug("below middle")
			hoverIndex++
			item.hoverIndex = hoverIndex
		}
		else if (hoverIndex > 0 && boundary < hoverBoundingRect.top - (CleConfig.listCellHeight + CleConfig.listCellMarginBottom) / 2) {
			console.debug("above middle")
			hoverIndex--
			item.hoverIndex = hoverIndex
		}

		//console.debug("listId %s sourceId %s page %o", props.listId, sourceListId, props.page)
		// check whether we drag from the grid
		if (sourceListId !== CleConfig.listItemId) {
			// add a temporary channel, if it does not exist yet
			console.debug("ADD")
			page.addChannel(item.id)
			dragIndex = page.getIndex(item.id)
			item.index = dragIndex
			page.setDragChannel(item.id)
			//monitor.getItem().isDragging = true
			//monitor.isDragging = true
		}

		// Don't replace items with themselves
		if (dragIndex === hoverIndex || hoverIndex > page.listChannelIds.length - 1) {
			return
		}

		//const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()//.top + window.scrollY
		//const hoverBoundingRect = document.getElementById(`${(CleConfig.listElemIdPrefix)}${(hoverIndex)}`).getBoundingClientRect()
		//console.debug("top %s height %s width %s dom %o byId", hoverBoundingRect.top, hoverBoundingRect.height, hoverBoundingRect.width, hoverBoundingRect, document.getElementById(`${(CleConfig.listElemIdPrefix)}${(dragIndex)}`))

		// Get vertical middle
		const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

		// Determine mouse position
		const clientOffset = monitor.getClientOffset()

		// Get pixels to the top
		const hoverClientY = clientOffset.y - hoverBoundingRect.top

		console.debug("hoverHandler: y %o, m %o, diffIx %s", hoverClientY, hoverMiddleY, hoverIndex - dragIndex)

		// Only perform the move when the mouse has crossed half of the item's height
		// When dragging downwards, only move when the cursor is below 50%
		// When dragging upwards, only move when the cursor is above 50%

		// Dragging downwards
		if (dragIndex < hoverIndex && hoverIndex - dragIndex < 1 && hoverClientY < hoverMiddleY) {
			return
		}

		// Dragging upwards
		if (dragIndex > hoverIndex && dragIndex - hoverIndex < 1 && hoverClientY > hoverMiddleY) {
			return
		}

		// Time to actually perform the action
		if ((item.listId === sourceListId) || (sourceListId === 1)) {
			//console.debug("listId %s id %s page %o", sourceListId, item.channel, props.page)
			moveChannel(dragIndex, hoverIndex)
			//collectedProps.dragIndex = -1 //??
			// Note: we're mutating the monitor item here! Generally it's better to avoid mutations,
			// but it's good here for the sake of performance to avoid expensive index searches.
			//monitor.getItem().index = hoverIndex
			item.index = hoverIndex
		}
	}

	const nhoverHandler = (item, monitor) => {
		//console.debug("in hoverHandler %o, %s, %o, %o, %o", item, item.Name, monitor, monitor.getDifferenceFromInitialOffset(), props)
		//console.debug("ix %o, %o, %o", Math.trunc(monitor.getDifferenceFromInitialOffset().y / CleConfig.listCellHeight), monitor.getItemType(), monitor.getClientOffset(), monitor.getItem())
		const topBoundingRect = document.getElementById(`${(CleConfig.listElemIdPrefix)}${(0)}`).getBoundingClientRect()
		console.debug("hoverHandler %o, %o, %o, %o", topBoundingRect.top, Math.trunc(monitor.getClientOffset().y), Math.trunc(monitor.getInitialSourceClientOffset().y), Math.trunc(monitor.getDifferenceFromInitialOffset().y))
		//console.debug("item %o", item)
		//const item = monitor.getItem()
		//let dragIndex = item.index
		let dragIndex = item.index
		//let hoverIndex = dragIndex + Math.trunc(monitor.getDifferenceFromInitialOffset().y / CleConfig.listCellHeight)
		//let hoverIndex = Math.trunc((monitor.getClientOffset().y - topBoundingRect.top) / (CleConfig.listCellHeight + CleConfig.listCellMarginBottom))
		let hoverIndex = item.hoverIndex
		const sourceListId = item.listId

		/**/
		if (sourceListId !== CleConfig.listItemId) {
			//if (monitor.getItemType() !== "LIST_CHANNEL") {
			// add a temporary channel, if it does not exist yet
			page.addChannel(item.id)
			dragIndex = page.getIndex(item.id)
			item.index = dragIndex
			//monitor.dragIndex = dragIndex
			page.setDragChannel(item.id)
			//monitor.getItem().isDragging = true
			//monitor.isDragging = true
		}

		const elem = document.getElementById(`${(CleConfig.listElemIdPrefix)}${(hoverIndex)}`)
		if (!elem) console.debug("SHIT %o, %o, %o", hoverIndex, monitor.getDifferenceFromInitialOffset().y, monitor.getClientOffset())
		const hoverBoundingRect = document.getElementById(`${(CleConfig.listElemIdPrefix)}${(hoverIndex)}`).getBoundingClientRect()
		const boundary = monitor.getInitialSourceClientOffset().y + monitor.getDifferenceFromInitialOffset().y
		console.debug("drag: %s, hover %s, %s", dragIndex, hoverIndex, item.Name, hoverBoundingRect.top + CleConfig.listCellHeight / 2, boundary, monitor.getDifferenceFromInitialOffset().y)

		if (boundary >= hoverBoundingRect.top + (CleConfig.listCellHeight + CleConfig.listCellMarginBottom) / 2) {
			console.debug("below middle")
			hoverIndex++
			item.hoverIndex = hoverIndex
			if ((item.listId === sourceListId) || (sourceListId === CleConfig.gridItemId)) {
				//console.debug("listId %s id %s page %o", sourceListId, item.channel, props.page)
				moveChannel(dragIndex, hoverIndex)
				// Note: we're mutating the monitor item here! Generally it's better to avoid mutations,
				// but it's good here for the sake of performance to avoid expensive index searches.
				//item.hoverIndex = hoverIndex
				//item.index = hoverIndex
				return
			}
		}

		// Don't replace items with themselves
		if (dragIndex === hoverIndex /*&& dragIndex === item.hoverIndex*/) {
			console.debug("DRAG RETURN")
			return
		}
		console.debug("drag: %s, hover %s, %s", dragIndex, hoverIndex, item.Name)

		// Get vertical middle
		//const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
		//const hoverMiddleY = hoverBoundingRect.bottom - hoverBoundingRect.height / 2 - CleConfig.listCellHeight - CleConfig.listCellMarginBottom
		const hoverMiddleY = hoverBoundingRect.top - CleConfig.listCellHeight - CleConfig.listCellMarginBottom

		// Determine mouse position
		const clientOffset = monitor.getClientOffset()

		// Get pixels to the top
		//const hoverClientY = clientOffset.y - hoverBoundingRect.top
		const hoverClientY = clientOffset.y// - topBoundingRect.top

		// Only perform the move when the mouse has crossed half of the item's height
		// When dragging downwards, only move when the cursor is below 50%
		// When dragging upwards, only move when the cursor is above 50%

		console.debug("bounding %s, %s, %s, %s, %o, %o", dragIndex, hoverIndex, hoverClientY, hoverMiddleY, hoverBoundingRect, monitor.getDifferenceFromInitialOffset().y)
		// Dragging downwards
		//if (dragIndex < hoverIndex && hoverIndex - dragIndex < 1 && hoverClientY < hoverMiddleY) {
		if (dragIndex < hoverIndex /*&& hoverIndex - dragIndex < 1*/ && hoverClientY < hoverMiddleY) {
			//item.hoverIndex = hoverIndex
			console.debug("downwards return %o, %o", hoverClientY, hoverMiddleY)
			return
		}

		// Dragging upwards
		//if (dragIndex > hoverIndex && dragIndex - hoverIndex < 1 && hoverClientY > hoverMiddleY) {
		if (dragIndex > hoverIndex /*&& dragIndex - hoverIndex < 1*/ && hoverClientY > hoverMiddleY) {
			//item.hoverIndex = hoverIndex
			console.debug("upwards return %o, %o", hoverClientY, hoverMiddleY)
			return
		}

		// Time to actually perform the action
		//if ((props.listId === sourceListId) || (sourceListId === 1)) {
		//if ((monitor.getItemType() === "LIST_CHANNEL") /*|| (sourceListId === 1)*/) {
		if ((item.listId === sourceListId) || (sourceListId === CleConfig.gridItemId)) {
			//console.debug("listId %s id %s page %o", sourceListId, item.channel, props.page)
			moveChannel(dragIndex, hoverIndex)
			// Note: we're mutating the monitor item here! Generally it's better to avoid mutations,
			// but it's good here for the sake of performance to avoid expensive index searches.
			item.hoverIndex = hoverIndex
			//item.index = hoverIndex
		}
		/**/
	}

	const [collectedProps, drop] = useDrop({
		accept: [CleConfig.listItemType, CleConfig.gridItemType],
		drop: (item, monitor) => { /*item.dragIndex = item.index;*/ dropHandler(item, monitor) },
		hover: (item, monitor) => { hoverHandler(item, monitor) },
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
			canDrop: !!monitor.canDrop(),
			dragIndex: monitor.getItem()?.index
		})
	})
	console.debug("drop: %o", collectedProps)

	return (//connectDropTarget(
		<div>
			<Paper>
				<List ref={drop} component="nav" disablePadding>
					{page.listChannelIds.map((item, ix) => (
						<ListChannelWithHook page={page} top={ix * (CleConfig.listCellHeight + CleConfig.listCellMarginBottom)} item={Object.assign({ listId: CleConfig.listItemId, index: ix, hoverIndex: ix, dragIndex: collectedProps.dragIndex }, page.getChannel(item))} canDrop={collectedProps.canDrop} index={ix} channel={item} key={item} listId={0} moveChannel={moveChannel} />
					))}
				</List>
			</Paper>
		</div>
	)
})

let previewFix = null
const CleListWrapper = CreateComponent(styles, false, true, ({ page, classes, windowDimensions, props }) => {

	const DndPreview = () => {
		const { display, itemType, item, style } = usePreview()
		if (!display || itemType !== CleConfig.listItemType) {
			previewFix = null
			return null;
		}
		//console.debug("DndPreview: %o, %o, %o, %o", display, itemType, item, style)
		//return <div class="item-list__item" style={style}>{itemType}</div>;
		//const { item, index, classes, page, isDragging, } = props
		/**/
		if (!previewFix) {
			const elem = document.getElementById(CleConfig.gridElemIdPrefix + item.index)
			const elem0 = document.getElementById(CleConfig.gridElemIdPrefix + "0")
			const clRect = elem?.getBoundingClientRect()
			//console.debug("info %o", clRect)
			//style.left = clRect.x - elem0.getBoundingClientRect().x
			//style.top = clRect.y - elem0.getBoundingClientRect().y

			// MTVW-50: added condition to avoid crash
			if (elem && elem0 && clRect)
				previewFix = {
					left: clRect.x - elem0.getBoundingClientRect().x,
					top: clRect.y - elem0.getBoundingClientRect().y
				}
		}
		/**/
		//style.left = previewFix.left
		//style.top = previewFix.top

		//console.debug("disp %o, type %o, item %o, style %o", display, itemType, item, style)
		//const { item, index, classes, page, isDragging } = props
		return <div className="item-list__item" style={style}>
			<ListChannelPreview item={item} classes={classes} page={page} isDragging={true} />
		</div>

		//return <GridChannelImage class="item-list__item" style={style}  {...item} />
	}

	return (
		// MTV-2511: Needs to have a div, otherwise the height will not work if the grid is below the channel list
		<div>
			<Paper className={classnames(classes.CleLeftColumn, classes.CleListWrapper /*+ " CleListWrapperResponsive"*/)}>
				{page.isReady && (
					<div id="channelListId"
						className={classes.CleListWrapperScrollable /*+ " CleGridWrapperScrollableResponsive"*/}
						style={{ height: windowDimensions }}
						//ref={el => page.setRefElemScroll(el)}
						//onScroll={() => page.setVerticalScroll()}
						data-test="cle_channellist"
					>
						<CleListImpl page={page} props={props} />
					</div>
				)}
			</Paper>
			{isTouchAvailable() && (
				<DndPreview />
			)}
		</div>
	)
})

const ChannelList = CreateComponent(styles, false, false, ({ page, classes, windowDimensions, ...props }) => {
	//const { item, index, hidden, isDragging /*, connectDragSource, connectDragPreview */ } = props
	/*
	const [{ nisDragging }, drag] = useDrag({
		//type: ["LIST_CHANNEL", "GRID_CHANNEL"],
		item: item,
		type: "LIST_CHANNEL",
		collect: monitor => ({
			isDragging: !!monitor.isDragging()
		})
	})
	*/
	return (
		<CleListWrapper page={page} windowDimensions={windowDimensions} props={props} />
	)
})

const channelTarget = {
	drop(props, monitor, component) {
		//console.debug("tgprops %o monitor %o comp %o", props, monitor, component)
		const { id } = props
		//const sourceObj = monitor.getItem()
		//if (id !== sourceObj.listId) component.pushChannel(sourceObj.channel)
		return {
			listId: id
		}
	}
}

/*
export default DropTarget(["LIST_CHANNEL", "GRID_CHANNEL"], channelTarget, (connect, monitor) => ({
	connectDropTarget: connect.dropTarget(),
	isOver: monitor.isOver(),
	canDrop: monitor.canDrop()
}))(ChannelList)
*/
export default ChannelList
