import React, { useEffect } from "react"
import { CreateComponent } from "components/utils/CreateComponent"
import { appStats, msVersions } from "utils/TestSupport"
//import format from "format-util"
import ReactHtmlParser from "react-html-parser"
import VConsole from 'vconsole'
import { appFeatures } from "AppFeatures"

let messages = []

/*
const replaceCircular = function (val, original, cache) {
	cache = cache || new WeakSet()
	if (val && typeof (val) === 'object') {
		//original("OBJ IS %o", val)
		if (cache.has(val)) return '[Circular]'

		cache.add(val);

		var obj = (Array.isArray(val) ? [] : {})
		for (var idx in val) {
			switch (typeof val[idx]) {
				case "function":
					break
				case "object":
					if (val[idx] && val[idx] !== "") {
						let objName = Object.prototype.toString.call(val[idx])
						if (objName.indexOf("object CSS") > -1 || objName.indexOf("object Style") > -1 || objName.indexOf("object DOM") > -1 || objName.indexOf("object HTML") > -1 || objName.indexOf("object Array") > -1 || objName.indexOf("object Set") > -1 || objName.indexOf("object Object") > -1 || objName.indexOf("object Name") > -1) continue
						//original("NAME %o", objName)
						switch (objName) {
							case "[object Promise]":
							case "[object Wait]":
							case "[object Object]":
								break
							default:
								//riginal("REPL %o, str %s, name %s", val[idx], val[idx], objName)
								obj[idx] = replaceCircular(val[idx], original, cache)
						}
					}
					break
				default:
					//original("DEFAULT %o, type %s", val[idx], typeof val[idx])
					if (val[idx] && (typeof val[idx] === "string") && (val[idx].indexOf("<") === 0)) {
						// eslint-disable-next-line no-useless-concat
						//val[idx] = "<pre>" + "&ltelem&gt ... &lt/elem&gt" + "</pre>"
						// NOTE / TODO: above statement would crash when switching to fullscreen
						obj[idx] = "... OMITTED ..."
						//original("HIT %s", val[idx])
					}
					else {
						obj[idx] = val[idx]
					}
			}
		}

		cache.delete(val)
		return obj
	}
	return val
}
*/

/*
function hijackConsole() {
	let myCconsole = window.console
	if (!myCconsole) return
	console.debug("in hijackConsole")
	function intercept(method) {
		var original = myCconsole[method]
		myCconsole[method] = function () {
			// output to original console
			try {
				if (original.apply) {
					// For normal browsers
					original.apply(myCconsole, arguments)
				} else {
					// For IE
					const message = Array.prototype.slice.apply(arguments).join(' ')
					original(message)
				}

				// process arguments for in app display
				//original("ARG 0 %o", arguments[0])
				if (arguments[0] && (typeof arguments[0] === "string")) arguments[0] = arguments[0].replaceAll("<", "&lt").replaceAll(">", "&gt").replaceAll("%o", "%s")
				let params = Array.prototype.slice.call(arguments, 1)
				for (var k = 0; k < params.length; k++) {
					//original("PARAMS 1 %o %s", params[k], typeof params[k])
					if (typeof params[k] === "object") {
						//original("CALL %o", params[k])
						params[k] = JSON.stringify(replaceCircular(params[k], original))
					}
					else {
						//original("TYPE %s, obj %o", typeof params[k], params[k])
						if (typeof params[k] === "string") {
							let str = " " + params[k]
							params[k] = str.slice(1).replaceAll("<", "&lt").replaceAll(">", "&gt").replaceAll("%o", "%s")
						}
					}
				}

				const message = params.length ? format(arguments[0], ...params) : arguments[0]
				messages.push({
					type: method, // "log", "warn", "error"
					message
				})
				if (messages.length > 300) {
					messages.shift()
				}

			}
			catch (e) {
				original("Exception in console intercept %o", e)
			}
		}
	}
	const methods = ["info", "log", "warn", "debug", "error"]
	for (var i = 0; i < methods.length; i++)
		intercept(methods[i])
}
*/

//hijackConsole()
// START vConsole
// use lower case id!
const vStatsPlugin = new VConsole.VConsolePlugin("vstats", "Quickline")
vStatsPlugin.on('init', function () {
	//console.debug('Ql plugin init', this);
	//vConsole.setOption("pluginOrder", ["vstats"])
})
vStatsPlugin.on('renderTab', function (callback) {
	//console.debug("appStats", appStats)
	let html = `<table style="padding-left:10px; font-family:'EuropaBold'; width:${window.innerWidth - 50}">`
	appStats.gt12Enabled = appFeatures.gt12Enabled
	for (const [key, value] of Object.entries(appStats)) {
		//console.debug("key: %o, value: %o, type %o, len %o", key, value, typeof value, typeof value === "string" ? value.length : -1)
		html += `<tr><td>${key}</td><td><pre>${value}</pre></td></tr>`
	}
	html += "</table>"
	callback(html);
})

let vConsole = null
const vConsoleReady = () => {
	vConsole.hideSwitch()
	// add in useEffect for up-to-date info
	//vConsole.addPlugin(vStatsPlugin)

	// does not work
	//vConsole.removePlugin("element")
	// does not work
	//vConsole.setOption("pluginOrder", ["log", "network", "storage", "system"])
	//vConsole.setOption("pluginOrder", ["vstats"])
}
function startVConsole() {
	vConsole = new VConsole({ theme: 'dark', onReady: vConsoleReady, })
	vConsole.setOption("log.showTimestamps", true)
	//vConsole.addPlugin(vStatsPlugin)
}

startVConsole()
// END vConsole

const styles = theme => ({
	Wrapper: { display: "flex", flexDirection: "column", width: "100%", paddingTop: 120, paddingLeft: 20, paddingRight: 20 },
	Wrapper1: { display: "flex", flexDirection: "column", width: "100%", /*height: 200,*/ overflow: "auto", marginTop: 30, paddingLeft: 0, paddingRight: 0 },
})

export const Internals = CreateComponent(styles, false, true, ({ classes }) => {
	// eslint-disable-next-line no-unused-vars
	const [isReady, setReady] = React.useState(false)
	//console.debug("in Internals %o", messages)
	// eslint-disable-next-line no-template-curly-in-string

	useEffect(() => {
		msVersions().then(res => {
			if (vConsole) {
				//vConsole.removePlugin("vstats")
				vConsole.addPlugin(vStatsPlugin)
				//vConsole.setOption("pluginOrder", ["vstats"])
				vConsole.showSwitch()
				vConsole.show()
				vConsole.showPlugin("vstats") // -> does not exist
			}
		})
		return (() => {
			if (vConsole) {
				vConsole.hide()
				vConsole.hideSwitch()
				vConsole.removePlugin("vstats")
			}
		})
	}, [])

	let logOutput = `<div>entries: ${messages?.length} (max. 300 recent)</div>`
	for (var i = 0; i < messages?.length; i++) {
		let color = "<div style = 'color:white'>"
		switch (messages[i].type) {
			case "debug":
				color = "<div style = 'color:steelblue'>"
				break
			case "error":
				color = "<div style = 'color:crimson'>"
				break
			case "warn":
				color = "<div style = 'color:lightsalmon'>"
				break
			case "info":
				color = "<div style = 'color:green'>"
				break

			default:
				color = "<div style = 'color:white'>"
		}
		logOutput = logOutput + color + messages[i].message + "</div>"
	}
	msVersions().then(res => {
		setReady(true)
	})

	// nothing to return with vconsole active
	//return isReady && (
	return null && (
		<div className={classes.Wrapper}>
			{JSON.stringify(appStats).replaceAll(",", ", ").replaceAll("\"", "").replaceAll("{", "").replaceAll("}", "").replaceAll(":", ": ")}
			<div className={classes.Wrapper1} style={{ height: window.innerHeight - 260 }}>
				{ReactHtmlParser(logOutput)}
			</div>
		</div>
	)
})
