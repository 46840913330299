// l10n status: done
import { Typography } from "@mui/material"
import { CreateComponent } from "components/utils/CreateComponent.js"
import React from "react"
import rec from "img/recording_indicators/rec.svg"
import rec_successful from "img/recording_indicators/rec_successful.svg"
import rec_failed from "img/recording_indicators/rec_failed.svg"
import rec_series from "img/recording_indicators/rec_series.svg"
import rec_series_episode_successful from "img/recording_indicators/rec_series_episode_successful.svg"
import { rootStore } from "store/RootStore"
import { computed } from "mobx"
import { l10n } from "store/lang/L10n"

const styles = theme => ({
	RecordingIndicatorIcon: { height: 11, marginRight: "4px" },
	recordingIndicatorContainer: {
		display: "flex",
		alignItems: "center",
		float: "left",
		background: "transparent !important",
		borderLeft: "none !important",
		whiteSpace: "nowrap",
	}
})

export const RecordingIndicator = CreateComponent(styles, false, true, ({ classes, eventId, seriesId, showText }) => {
	// MTVW-518: Use computed to update the display of the recordings indicator
	const marker = computed(() => rootStore.page.Recordings.Manager.getMarker(eventId, seriesId)).get()

	if (marker === null /*|| marker?.FactoryState === "Suspended"*/) {
		return ""
	}
	/* LEAVE FOR TESTING PURPOSE MTV-1757 and MTV-1796
	const page = rootStore.page.Recordings.IndexPage
	if (page.oRecPlanned.isReady) {
		page.oRecPlanned.DataArr.map(i => { console.debug(i) })
		console.debug("eventId %s, seriesId %s, type %s, marker %o ", eventId, seriesId, marker.recType, marker)
	}
	*/
	const recTypes = {
		rec_single_planned: {
			imgProps: { src: rec },
			txt: l10n.featureDetailsRecordingStatusPlanned
		},
		rec_single_complete: {
			imgProps: { src: rec_successful },
			txt: l10n.featureDetailsRecordingStatusComplete
		},
		rec_single_failed: {
			imgProps: { src: rec_failed },
			txt: l10n.featureDetailsRecordingStatusFailed
		},
		rec_series_planned: {
			imgProps: { src: rec_series },
			txt: l10n.featureDetailsRecordingStatusSeriesPlanned
		},
		rec_series_complete: {
			imgProps: { src: rec_series_episode_successful },
			txt: l10n.featureDetailsRecordingStatusComplete
		},
		rec_series_failed: {
			imgProps: { src: rec_failed },
			txt: l10n.featureDetailsRecordingStatusSeriesFailed
		}
	}

	const RecType = recTypes[marker.recType]

	return (
		<div className={classes.recordingIndicatorContainer} data-test="recording_indicator">
			<img alt="" className={classes.RecordingIndicatorIcon} {...RecType.imgProps} />
			{showText === true && <Typography variant="body2">{RecType.txt}</Typography>}
		</div>
	)
})
