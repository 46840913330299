// l10n status: done
import React from "react"
import "./adCounters.css"
import ad_volumeOn from "img/player/ad_volumeOn.svg"
import ad_volumeOff from "img/player/ad_volumeOff.svg"
import ad_fullscreenEnter from "img/player/ad_fullscreenEnter.svg"
import ad_fullscreenExit from "img/player/ad_fullscreenExit.svg"
import { observer } from "mobx-react"
import { l10n } from "store/lang/L10n"

// MTVW-733: Added adStyleInfo

const SkipButton = observer(({ adStyleValidCounter, adStyleInfo, source, cssClass, isFullScreen, isControlsVisible }) =>
	// MTVW-453	
	// <div className={cssClass}
	<div id="skipButton" style={isFullScreen && adStyleValidCounter > 0 ? { right: adStyleInfo?.videoRight + 10, top: adStyleInfo?.videoBottom - 60 } : {}}
		className={`${cssClass} ${isControlsVisible ? isFullScreen ? "AdCountDownSkipButtonOnFullscreen" : "AdCountDownSkipButtonControlsOn" : ""}`}
		onClick={(e) => {
			e.stopPropagation()
			source.skipAd()
		}}
		onTouchEnd={(e) => {
			e.stopPropagation()
			e.preventDefault()
			source.skipAd()
		}}
	>
		<div className="AdContDownText ">
			{l10n.featurePlayerSkipPromo}
		</div>
	</div>
)

const visibilityHelper = (bState, style = {}) => {
	return !bState ? { visibility: "hidden", height: 0, width: 0, minWidth: 0, padding: 0 } : style
}

export const AdCounter = observer(({ page, adStyleValidCounter, adStyleInfo, isFullScreen, isControlsVisible = false }) => {
	const player = page.player
	const source = page.source
	const hasCloseButton = !(isFullScreen || source.contentPlayerName === "playerLiveTvVo")
	let counters = null

	// MTVW-727: don't show if in PiP
	if (player.isInPip) return null

	//MTVW-733: Match the breakpoints for AdMainCounterContainer in the css
	const countdownOffset = () => {
		if (adStyleInfo?.videoBottomOffset === 0) return 96
		if (adStyleInfo?.videoWidth > 960) return 96
		else if (adStyleInfo?.videoWidth > 480) return 72
		else return 44
	}

	React.useEffect(() => {
		//console.debug("AdCounter %o, closeButton %s, fs %s, valid %s", document.getElementById("closeOverlay"), hasCloseButton, isFullScreen, adStyleValidCounter)
		console.debug("AdCounter closeButton %s, fs %s, counter %s, style %o", hasCloseButton, isFullScreen, adStyleValidCounter, adStyleInfo)
	}, [hasCloseButton, isFullScreen, adStyleValidCounter])

	// MTVW-733: ultrawidescreen support, added adStyleInfo
	if (source.adState?.replay) {
		if (source.adState.replay.countdown > 0) {
			counters = <div id="adReplayCountdown" style={isFullScreen && adStyleValidCounter > 0 ? { right: adStyleInfo?.videoRight + 24, top: adStyleInfo?.videoBottom - countdownOffset() } : {}}
				className={`AdMainCounterContainer `}>
				<div className="AdContDownText">{l10n.featurePlayerReplayCountdown.format(source.adState.replay.countdown / 1000)}</div>
			</div>
		}
	} else if (source.adState?.fastFwd) {
		if (source.adState.fastFwd.adNumber > 0) {
			counters = <div id="adCounter" style={isFullScreen && adStyleValidCounter > 0 ? { right: adStyleInfo?.videoRight + 24, top: adStyleInfo?.videoBottom - countdownOffset() } : {}}
				className={`AdMainCounterContainer `} >
				<div className="AdCounterText">
					{l10n.featurePlayerFastforwardAdCounter.format(source.adState.fastFwd.adNumber, source.adState.fastFwd.adCount)}
				</div>
			</div>
		}
		if (source.adState.fastFwd.promoCountdown > 0) {
			counters = <div id="adCountdown" style={isFullScreen && adStyleValidCounter > 0 ? { right: adStyleInfo?.videoRight + 24, top: adStyleInfo?.videoBottom - 60 } : {}}
				className="AdContDownPromoMain AdContDownOpacityOn">
				<div className="AdContDownText">{l10n.featurePlayerSkipPromoIn.format(source.adState.fastFwd.promoCountdown / 1000)}</div>
			</div>
		}
		if (source.adState?.fastFwd?.skip) {
			counters = <SkipButton adStyleValidCounter={adStyleValidCounter} adStyleInfo={adStyleInfo} source={source} style={isFullScreen && adStyleValidCounter > 0 ? { right: adStyleInfo?.videoRight + 24, top: adStyleInfo?.videoBottom - 60 } : {}}
				cssClass={'AdContDownPromoMain AdCountDownSkipButton'} isFullScreen={isFullScreen} />
		}
	} else if (source.adState?.linear?.skip) {
		counters = <SkipButton adStyleValidCounter={adStyleValidCounter} adStyleInfo={adStyleInfo} source={source} id="linearSkipButton" style={isFullScreen && adStyleValidCounter > 0 ? { right: adStyleInfo?.videoRight + 10 } : {}}
			cssClass={'AdSkipLinearPromo AdCountDownSkipButton'}
			isFullScreen={isFullScreen}
			isControlsVisible={isControlsVisible} />
	}

	const clickAction = (e, action) => {
		e.stopPropagation()
		e.preventDefault()
		action()
	}

	// MTVW-733: The placement of the buttons don't work correctly without the fullscreen toggling hack in Player.orientationChange
	return (
		<div>
			<div id="adTopControlsContainer" style={isFullScreen && adStyleValidCounter > 0 ? { width: adStyleInfo?.videoWidth - 40, marginLeft: adStyleInfo?.videoRight, top: adStyleInfo?.videoTop + 10 } : {}}
				className={`${hasCloseButton ? 'AdTopControlsContainer' : 'AdTopControlsContainerFullscreen'}`}>
				<div className="VolumeControl" >
					<img src={ad_volumeOn} alt="volume on" style={visibilityHelper(!player.isMuted, {})}
						onClick={(e) => {
							clickAction(e, () => player.setMuteState(true))
						}}>
					</img>
					<img src={ad_volumeOff} alt="volume on" style={visibilityHelper(player.isMuted, {})}
						onClick={(e) => {
							clickAction(e, () => player.setMuteState(false))
						}}>
					</img>
				</div>
				<div className="FullscreenControl">
					<img src={ad_fullscreenEnter} alt="fullscreen enter" style={visibilityHelper(!isFullScreen, {})}
						onClick={(e) => {
							clickAction(e, () => source.setFullScreen())
						}}>
					</img>
					<img src={ad_fullscreenExit} alt="fullscreen exit" style={visibilityHelper(isFullScreen, {})}
						onClick={(e) => {
							clickAction(e, () => source.setFullScreen())
						}}>
					</img>
				</div>
			</div>

			<div>{counters}</div>
		</div>
	)
})
