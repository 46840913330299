// l10n status: partial
import { CreateComponent } from "components/utils/CreateComponent"
import React, { createRef, useEffect, useLayoutEffect, useRef, useState } from "react"
import searchIcon from "img/icons/search.svg"
import CloseIcon from '@mui/icons-material/Close';
import { Button, Divider, ListItemSecondaryAction } from "@mui/material";
import { observer } from "mobx-react"
import { runInAction } from "mobx"
import { rootStore } from "store/RootStore"
import InputBase from '@mui/material/InputBase';
import LinearProgress from "@mui/material/LinearProgress"
import { List } from '@mui/material';
import { ListItem } from '@mui/material';
import { IconButton } from '@mui/material';
import { ListItemText } from '@mui/material';
import goBack from "img/icons/search/arrow_back_ios_white_36dp.svg"
import goForward from "img/icons/search/arrow_forward_ios_white_36dp.svg"
import { ROUTE_NAMES } from "site-ql/RouteConst"
import { useHistoryRouteAdd } from "components/utils/RouteUtils"

import LiveIndicator from "img/icons/liveInSearch.svg"
import Play from "img/icons/playInSearch.svg"
import PersonIcon from "img/person.svg"

import genreLogo from "img/icons/genre.svg"
import serieLogo from "img/icons/series.svg"

import { CALLING_CONTEXT_TYPE } from "store/page/OverlayEventDetails"
import { FilterEventTypeBar } from "scenes/Search/components/FilterTypeEventBar";

import './SearchStyle.css'
import { l10n } from "store/lang/L10n"
import { iso6391 } from "store/util/iso6391"
import { debounce } from "lodash";

let oldFilters = []

const FilterButtons = ({ page }) => {
	// const [size, setSize] = useState()
	const [scrollArrow, setScrollArrows] = useState({
		leftArrow: false,
		rightArrow: false,
	})
	const [filterIsOverflowing, setFilterIsOverflowing] = useState(false)

	const refSearchFilter = useRef()
	let filters = []
	// this condition prevents flickering when a new search is loaded and for a while page.overview is null. 
	if (!page.overview) {
		filters = oldFilters
	}
	else {
		oldFilters = filters
	}

	const handleResize = () => {
		const overFlow = refSearchFilter?.current?.clientWidth < refSearchFilter?.current?.scrollWidth
		setFilterIsOverflowing(overFlow)
		// MTVW-547: update arrow states
		setScrollArrows({
			leftArrow: overFlow ? refSearchFilter?.current?.scrollLeft > 0 : false,
			rightArrow: overFlow ? refSearchFilter?.current?.scrollLeft === 0 : false
		})
	}

	useEffect(() => {
		if (refSearchFilter?.current) {
			refSearchFilter.current.scrollLeft = 0
			setScrollArrows({
				leftArrow: filterIsOverflowing ? refSearchFilter?.current?.scrollLeft > 0 : false,
				rightArrow: filterIsOverflowing ? refSearchFilter?.current?.scrollLeft === 0 : false
			})
		}
	}, [filterIsOverflowing])

	useEffect(() => {
		setFilterIsOverflowing(refSearchFilter?.current?.clientWidth < refSearchFilter?.current?.scrollWidth)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refSearchFilter?.current?.scrollWidth])

	useEffect(() => {
		window.addEventListener("resize", handleResize)
		return (() => {
			window.removeEventListener("resize", handleResize) // when unload
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	for (const item in page.overview) {
		if (page.overview[item]?.length > 0 || page.filterLabel[item] === page.filterLabel[page.CATEGORY_TOP] || item === page.categorySelected) filters.push(item)
	}

	return (
		<div className='SearchFilterBarContainer' >
			<img className={`SearchFilterBarArrow ${!scrollArrow.leftArrow ? 'SearchFilterBarArrowDisabled' : ''}`} src={goBack} alt=" icon"
				onClick={(e) => {
					// MTVW-547:
					//refSearchFilter.current.scrollLeft = 0
					let scrollPos = refSearchFilter?.current?.scrollLeft - refSearchFilter?.current?.clientWidth / 2
					if (scrollPos < 0) scrollPos = 0
					refSearchFilter.current.scrollLeft = scrollPos
					setScrollArrows({ leftArrow: false, rightArrow: true })
				}}
			></img>
			<div className="SearchFilterBar" ref={refSearchFilter}
				onScroll={() => {
					const maxScrollLeft = Math.floor(refSearchFilter?.current?.scrollWidth - refSearchFilter?.current?.clientWidth) - 2
					//console.debug("scroll refSearchFilter.current.scrollLeft =%s --- max=%s", refSearchFilter.current?.scrollLeft, maxScrollLeft)
					if (refSearchFilter?.current?.scrollLeft === 0) {
						setScrollArrows({ leftArrow: false, rightArrow: true })
					} else if (refSearchFilter?.current?.scrollLeft > maxScrollLeft) {
						setScrollArrows({ leftArrow: true, rightArrow: false })
					} else {
						setScrollArrows({ leftArrow: true, rightArrow: true })
					}
				}}
			>
				{filters.map((item, index) => {
					return (
						<FilterButton key={item + index} text={page.filterLabel[item]} originalBackEndValue={item} page={page} isSelected={item === page.categorySelected} />
					)
				})}

			</div>
			<img className={`SearchFilterBarArrow ${!scrollArrow.rightArrow ? 'SearchFilterBarArrowDisabled' : ''}`} src={goForward} alt=" icon"
				onClick={(e) => {
					// MTVW-547:
					//refSearchFilter.current.scrollLeft = refSearchFilter.current.scrollWidth
					let scrollPos = refSearchFilter?.current?.scrollLeft + refSearchFilter?.current?.clientWidth / 2
					if (scrollPos > refSearchFilter?.current?.scrollWidth) scrollPos = refSearchFilter?.current?.scrollWidth
					refSearchFilter.current.scrollLeft = scrollPos
					setScrollArrows({ leftArrow: true, rightArrow: false })
				}}
			></img>
		</div>
	)
}

const FilterButton = ({ text, originalBackEndValue, page, isSelected = false }) => {
	const rootSearchFilterButton = isSelected ? 'searchFilterButtons searchFilterButtonSelected' : 'searchFilterButtons'
	return (
		<Button variant="outlined" style={{ color: "#FFF" }}
			onClick={() => {
				page.setCategorySelected(originalBackEndValue)
			}}
			classes={{ root: `${rootSearchFilterButton}` }}
		>{text}</Button>
	)
}

const SearchBar = React.forwardRef((props, ref) => {
	useEffect(() => {
		/* eslint-disable no-unused-expressions */
		ref?.current?.firstElementChild.focus()
		ref?.current?.focus()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const [top, setTop] = useState(null)
	useEffect(() => {
		/* eslint-disable no-unused-expressions */
		if (props.page.overview?.top) setTop(props.page.overview?.top)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.page.overview?.top])

	return (
		<div className="searchBarTopContainer">
			<div className="searchBarContainer">
				<div className="searchBar">
					<div className="iconSearchTextField">
						{/* MTVW-619: style added */}
						<img src={searchIcon} alt="search" style={{ height: 25, width: 25, marginTop: 9 }} />
					</div>
					<InputBase
						id="search_input_box"
						className="SearchBarInput"
						ref={ref}
						// eslint-disable-next-line jsx-a11y/no-autofocus
						autoFocus={true}
						placeholder={l10n.featureSearchTitle}
						data_test="search_input_box"
						onChange={(e) => { props.handleNewSearch(e.target.value) }}
					/>
					<div className="iconDeleteTextField"
						onClick={(e) => {
							e.stopPropagation()
							props.resetSearchTxt()
						}}>
						{props.showResetIcon && <CloseIcon />}
					</div>
				</div>
			</div>
			<div className="searchAndFilterContainer" >
				{/* MTVW-645: no filter bar if no top results */}
				{props.page.categorySelected && props.page.overview?.top?.length > 0 && <FilterButtons page={props.page} />}
			</div>
			{/* MTVW-624: added argument items */}
			{props.page.categorySelected === props.page.CATEGORY_MOVIES && <FilterEventTypeBar page={props.page} items={props.page.overview?.movies} />}
		</div>
	)
})

const StartPage = observer(({ setSearch, searchBar, page }) => {
	const PopularSearch = ['Tagesschau', 'Meteo', 'The Big Bang Theory', 'Formula 1']
	const [recentSearches, setRecentSearches] = useState(rootStore.sso.browserStore.getRecentSearches())
	runInAction(() => {
		page.overview = []
	})
	return (
		<div className="startPage" data_test="search_start_page">
			{recentSearches?.length > 0 && <div className="startPageSearchesTitles">{l10n.featureSearchRecentSearches}</div>}
			{recentSearches?.length === 0 ? <NotFound type={NOTFOUND_TYPE.NORECENTS} /> : <Divider key={'none'} />}
			<List>
				{recentSearches.map((item, index) => {
					return (<React.Fragment key={item}>
						<ListItem
							key={index + item}
							classes={{ gutters: "startPageListGutters" }}
							onClick={() => {
								searchBar.current.firstElementChild.value = item
								rootStore.sso.lastSearch = item
								setSearch(item)
							}}>
							<ListItemText
								classes={{ primary: "startPageSearchesText" }}
								primary={item}
							/>
							<ListItemSecondaryAction onClick={() => {
								rootStore.sso.browserStore.setRecentSearches(recentSearches.filter(value => value !== item))
								// force page update
								setRecentSearches(rootStore.sso.browserStore.getRecentSearches())
							}}
							>
								<IconButton edge="end" aria-label="delete">
									<CloseIcon classes={{ root: "searchCloseRecentSearchIcon" }} />
								</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
						<Divider key={index} />
					</React.Fragment>
					)
				})}
			</List>
			{/* MTVW-624: popular searches currently commented since they are constant
			<div className="startPageSearchesTitles">{l10n.featureSearchPopularSearches}</div>
			<Divider />
			<List>
				{PopularSearch.map((item, index) => {
					return (<React.Fragment key={index}>
						<ListItem
							key={index + item}
							classes={{ gutters: "startPageListGutters" }}
							onClick={() => {
								searchBar.current.firstElementChild.value = item
								rootStore.sso.lastSearch = item
								setSearch(item)
							}}>
							<ListItemText
								classes={{ primary: "startPageSearchesText" }}
								primary={item}
							/>
						</ListItem>
						<Divider key={index} />
					</React.Fragment>
					)
				})}
			</List>
			*/}
		</div>
	)
})

export const NOTFOUND_TYPE = {
	NOTFOUND: 0,
	NOHINT: 1,
	NORECENTS: 2
}

export const NotFound = ({ type = NOTFOUND_TYPE.NOTFOUND }) => {
	//console.debug("type=", type)
	return (
		<div className="notFoundPage" data_test="search_not_found_page">
			<img className="notFoundIcon" src={searchIcon} alt="search not found" />
			{type !== NOTFOUND_TYPE.NORECENTS ?
				<div>
					<h2>{l10n.featureSearchNoResults}</h2>
					{type !== NOTFOUND_TYPE.NOHINT ? <p>{l10n.featureSearchNoResultsHint}</p> : <></>}
				</div>
				: <></>
			}
		</div>
	)
}

const Channels = ({ item, withName, page }) => {
	const historyRouteAdd = useHistoryRouteAdd()
	if (!withName) {
		return (
			<div className="ChannelContainer" onClick={() => {
				rootStore.page.LiveTv.handlePlayChannelLive(item.id)
				historyRouteAdd(ROUTE_NAMES.LiveTv)
				page.addToRecentSearch(item.Name)
			}}>
				<img className="searchLogoChannel" src={item.logoUrl} alt={`channel ${item.Name} logo`}></img>
			</div>
		)
	}
	else return (
		<div className="namedChannelContainer" onClick={() => {
			rootStore.page.LiveTv.handlePlayChannelLive(item.id)
			historyRouteAdd(ROUTE_NAMES.LiveTv)
			page.addToRecentSearch(item.name)
		}}>
			<div className="namedChannelLogo">
				<img className="namedSearchLogoChannel" src={item.logoUrl} alt={`channel ${item.Name} logo`}></img>
			</div>
			<div className="searchEventActorsAndGenresInfo">
				<div className="searchEventActorsAndGenresText">{item.name}</div>
			</div>
		</div>
	)
}
const ActorsAndGenres = ({ type, item, page }) => {
	let _infoText = ""
	let _cardIcon = null

	if (type === page.TYPE_CREDIT) {
		_infoText = item.name
		_cardIcon = <img className='searchActorIcon' src={PersonIcon} alt=" icon"></img>
	} else if (type === page.TYPE_GENRE) {
		_infoText = item
		_cardIcon = <img className='searchActorIcon' src={genreLogo} alt=" icon"></img>
	} else {
		return null
	}
	return (
		<div className="searchEventActorsAndGenresContainer"
			data_test={`search_actors_genres_container ${type === page.TYPE_CREDIT ? page.CATEGORY_ACTORS : page.CATEGORY_GENRES}`}
			onClick={() => {
				if (type === page.TYPE_CREDIT) {
					rootStore.page.OverlayEventDetails.setActorName(_infoText).setComponentType(page.CATEGORY_ACTORS).setShow(true, CALLING_CONTEXT_TYPE.Search)
				} else if (type === page.TYPE_GENRE) {
					rootStore.page.OverlayEventDetails.setGenre(_infoText).setComponentType(page.CATEGORY_GENRES).setShow(true, CALLING_CONTEXT_TYPE.Search)
				}
				page.addToRecentSearch(_infoText)
			}}
		>
			<div className="searchEventActorsAndGenresLogoContainer">
				{_cardIcon}
			</div>
			<div className="searchEventActorsAndGenresInfo">
				<div className="searchEventActorsAndGenresText">{_infoText}</div>
			</div>
		</div>
	)
}
const Programs = observer((props) => {
	const { item, page, typeCards } = props

	const _EVENT_DATA = {
		logoImgSrc: null,
		channelName: null,
		eventTitle: null,
		genres: null,
		seasonInfo: null,
		programDate: null,
		liveIcon: null,
		progressBar: null,
		playButton: null,
		seriesMobileIcon: null,
		type: null,
		idEvent: null,
		idSerie: null,
	}

	const backGroundImgStyle = { ...page.backGroundImgStyle }

	const loadDataForMoviesEvent = () => {
		_EVENT_DATA.type = page.TYPE_EVENT
		backGroundImgStyle.backgroundImage = `${page.gradientImg},url(${item?.content?.posterUrl}?mode=box&w=480&h=270`
		const isLiveEvent = (item?.event?.eventType === 'c') ? true : false
		const isPastEvent = (item?.event?.eventType === 'p') ? true : false
		_EVENT_DATA.idEvent = item?.event?.id
		_EVENT_DATA.logoImgSrc = page.getChannelLogo(item?.event?.channelId)
		_EVENT_DATA.channelName = <div className="searchChannelName" data_test="search_channel_name">{page.getChannelName(item?.event?.channelId)}</div>
		_EVENT_DATA.eventTitle = item?.content?.Title
		_EVENT_DATA.genres = item?.content?.genres?.join?.(' \u25CF ')
		_EVENT_DATA.programDate = item?.event?.dateAndTime
		_EVENT_DATA.liveIcon = isLiveEvent ? < img src={LiveIndicator} className="searchLiveIndicator" data_test="search_event_live_icon" alt="" /> : null
		_EVENT_DATA.progressBar = isLiveEvent ? <LinearProgress classes={{ root: "searchMuiLinearProgress" }} variant="determinate" value={item.event.durationInPercent} data-test="search_event_progressbar" /> : null
		_EVENT_DATA.playButton = (isLiveEvent || isPastEvent) && page.isReplayAllowed(item?.event) ? <div style={{ position: 'relative' }}>
			<img className="searchPlayIcon" src={Play} alt="play" data_test="search_event_play_icon" style={{ bottom: isLiveEvent ? "10px" : "-1px" }}
				onClick={(e) => {
					// MTVW-624: autoplay support
					e.stopPropagation()
					rootStore.page.OverlayEventDetails.setEpgEventById(_EVENT_DATA.idEvent, true).then((res) => {
						rootStore.page.OverlayEventDetails.setComponentType('event').setShow(true, CALLING_CONTEXT_TYPE.Search)
					})
				}}></img>
		</div> : null
	}

	switch (typeCards) {
		case page.CATEGORY_TOP:
		case page.CATEGORY_SPORTS:
			{
				backGroundImgStyle.backgroundImage = `${page.gradientImg},url(${item?.type === page.TYPE_EVENT ? item?.data?.content?.posterUrl : item?.data?.posterUrl}?mode=box&w=480&h=270)`
				const isLiveEvent = (item.type === page.TYPE_EVENT) && (item?.data?.event?.eventType === 'c') ? true : false
				const isPastEvent = (item.type === page.TYPE_EVENT) && (item?.data?.event?.eventType === 'p') ? true : false
				_EVENT_DATA.type = item.type
				if (item.type === page.TYPE_EVENT) {
					_EVENT_DATA.idEvent = item?.data?.event?.id
					_EVENT_DATA.logoImgSrc = page.getChannelLogo(item?.data?.event?.channelId)
					_EVENT_DATA.channelName = <div className="searchChannelName" data_test="search_channel_name">{page.getChannelName(item?.data?.event?.channelId)}</div>
					_EVENT_DATA.eventTitle = item?.data?.content?.Title
					_EVENT_DATA.genres = item?.data?.content?.genres.join(' \u25CF ')
					_EVENT_DATA.programDate = item?.data?.event?.dateAndTime
					_EVENT_DATA.liveIcon = isLiveEvent ? < img src={LiveIndicator} className="searchLiveIndicator" data_test="search_event_live_icon" alt="" /> : null
					_EVENT_DATA.progressBar = isLiveEvent ? <LinearProgress classes={{ root: "searchMuiLinearProgress" }} variant="determinate" value={item?.data?.event.durationInPercent} data-test="search_event_progressbar" /> : null
					_EVENT_DATA.playButton = (isLiveEvent || isPastEvent) && page.isReplayAllowed(item?.data?.event) ? <div style={{ position: 'relative' }}>
						<img className="searchPlayIcon" src={Play} alt="play" data_test="search_event_play_icon" style={{ bottom: isLiveEvent ? "10px" : "-1px" }}
							onClick={(e) => {
								// MTVW-624: autoplay support
								e.stopPropagation()
								rootStore.page.OverlayEventDetails.setEpgEventById(_EVENT_DATA.idEvent, true).then((res) => {
									rootStore.page.OverlayEventDetails.setComponentType('event').setShow(true, CALLING_CONTEXT_TYPE.Search)
								})
							}}></img>
					</div> : null
				} else if (item.type === page.TYPE_SERIE) {
					_EVENT_DATA.idSerie = item?.data?.id
					_EVENT_DATA.logoImgSrc = serieLogo
					_EVENT_DATA.eventTitle = item?.data?.Title
					//_EVENT_DATA.seasonInfo = `${item?.data?.seasonCount} ${item?.data?.seasonCount > 1 ? 'Staffeln' : 'Staffel'} ${'\u25CF'} ${item?.data?.episodeCount}  Episodes ${'\u25CF'} ${item?.data?.language} `
					_EVENT_DATA.seasonInfo = l10n.featureSearchSeriesInfo.format(item?.data?.seasonCount, item?.data?.episodeCount, iso6391.getNameByCode(item?.data?.language))
					_EVENT_DATA.seriesMobileIcon = <div style={{ position: 'relative' }}>
						<img className="searchSeriesMobileIcon" src={serieLogo} alt="series" data_test="search_event_series_icon"></img>
					</div>
				}
			}
			break
		case page.CATEGORY_SERIES:
			_EVENT_DATA.type = typeCards
			backGroundImgStyle.backgroundImage = `${page.gradientImg},url(${item?.posterUrl}?mode=box&w=480&h=270)`
			_EVENT_DATA.logoImgSrc = serieLogo
			_EVENT_DATA.eventTitle = item?.Title
			_EVENT_DATA.idSerie = item?.id
			//_EVENT_DATA.seasonInfo = `${item?.seasonCount} ${item?.seasonCount > 1 ? 'Staffeln' : 'Staffel'} ${'\u25CF'} ${item?.episodeCount} Episodes  ${'\u25CF'} ${item?.language} `

			_EVENT_DATA.seasonInfo = l10n.featureSearchSeriesInfo.format(item?.seasonCount, item?.episodeCount, iso6391.getNameByCode(item?.language))
			_EVENT_DATA.seriesMobileIcon = <div style={{ position: 'relative' }}>
				<img className="searchSeriesMobileIcon" src={serieLogo} alt="series" data_test="search_event_series_icon"></img>
			</div>
			break
		case page.CATEGORY_MOVIES:
			if (page.filterEventType === page.FILTER_EVENT_ALL) {
				loadDataForMoviesEvent()
			} else if (page.filterEventType === page.FILTER_EVENT_LIVE_REPLAY) {
				if ((item?.event?.eventType === 'p') || (item?.event?.eventType === 'c')) {
					loadDataForMoviesEvent()
				} else {
					return null
				}
			} else if (page.filterEventType === page.FILTER_EVENT_UPCOMING) {
				if ((item?.event?.eventType === 'n')) {
					loadDataForMoviesEvent()
				} else {
					return null
				}
			}
			break
		default:
			break
	}

	// called when clicked
	const handleShowDetails = () => {
		if (_EVENT_DATA.type === page.CATEGORY_SERIES) {
			rootStore.page.OverlayEventDetails.setComponentType(page.CATEGORY_SERIES).setIdSerie(_EVENT_DATA.idSerie).setShow(true, CALLING_CONTEXT_TYPE.Search)
		} else if (_EVENT_DATA.type === page.TYPE_EVENT) {
			rootStore.page.OverlayEventDetails.setEpgEventById(_EVENT_DATA.idEvent).then((res) => {
				rootStore.page.OverlayEventDetails.setComponentType('event').setShow(true, CALLING_CONTEXT_TYPE.Search)
			})
		}
		page.addToRecentSearch(_EVENT_DATA.eventTitle)
	}

	return (
		<div className="eventContainer"
			data_test={`search_event ${_EVENT_DATA.type}`}
			onClick={(e) => {
				//console.debug("Programs")
				e.stopPropagation()
				handleShowDetails()
			}}
		>
			<div className="searchEventLogoChannel">
				<img className={_EVENT_DATA.logoImgSrc === serieLogo ? "searchLogo" : "searchLogoChannel"} src={_EVENT_DATA.logoImgSrc} alt="channel logo"></img>

			</div>
			<div className="searchEventInfoContainer">
				<div className="searchEventInfo">
					<div className="searchEventInfoText" >
						{_EVENT_DATA.channelName}
						<div className="searchEventTitle" data_test="search_event_title">{_EVENT_DATA.eventTitle}</div>
						<div className="searchEventGenresInfo">{_EVENT_DATA.genres}</div>
					</div>
					<div className="searchEventInfoPoster">
						<div className="eventPosterSection" style={backGroundImgStyle} data_test="search_event_poster"></div>
					</div>
				</div>
				<div className="searchEventIconsAndBar">
					{_EVENT_DATA.liveIcon}
					<span data_test="search_event_info"> {_EVENT_DATA.seasonInfo ? _EVENT_DATA.seasonInfo : _EVENT_DATA.programDate}</span>
					{_EVENT_DATA.progressBar}
					{_EVENT_DATA.playButton}
					{_EVENT_DATA.seriesMobileIcon}
				</div>
			</div>
		</div>
	)
})


const MainSearch = CreateComponent(null, false, true, props => {
	const { page } = props
	// eslint-disable-next-line no-unused-vars
	const [newCategory, setNewCategory] = useState()
	// MTVW-624: added useState below, otherwise movies might not correctly refreshed on tab change
	const [current, setCurrent] = useState(null)
	page.setRefresh(false)
	const peoplesInTop = useRef(0)
	const genresInTop = useRef(0)
	const channelsInTop = useRef(0)


	const _event = useRef()
	useLayoutEffect(() => {
		const mainSearch = () => {
			// MTVW-510 : TODO: Define if channels should also be displayed in top
			const TOP_CHANNELS = true
			let savedIndexPeoples = null
			let savedIndexGenres = null
			let savedIndexChannels = null
			let itemInTop = null

			peoplesInTop.current = 0
			genresInTop.current = 0
			channelsInTop.current = 0

			// MTVW-624: no background color for separators, open category when clicked
			const peoplesTitle = <div style={{ backgroundColor: "unset" }} className="eventContainerTitle" key="titleActors" id="testActors"
				onClick={(e) => {
					page.setCategorySelected(page.CATEGORY_ACTORS)
				}}>
				<h1>{l10n.featureSearchFilterCategoryActors} </h1></div>
			const genresTitle = <div style={{ backgroundColor: "unset" }} className="eventContainerTitle" key="titleGenres" id="testGenres"
				onClick={(e) => {
					page.setCategorySelected(page.CATEGORY_GENRES)
				}}>
				<h1>{l10n.featureSearchFilterCategoryGenres}</h1></div>
			const channelsTitle = <div style={{ backgroundColor: "unset" }} className="eventContainerTitle" key="titleChannels" id="testChannels"
				onClick={(e) => {
					page.setCategorySelected(page.CATEGORY_CHANNELS)
				}}>
				<h1>{l10n.featureSearchFilterCategoryChannels}</h1></div>

			if (!page.overview || !page.categorySelected) return
			const _categoryData = page.overview[page.categorySelected]
			switch (page.categorySelected) {
				case page.CATEGORY_TOP:
					_event.current = _categoryData?.map((item, index) => {
						switch (item.type) {
							case page.TYPE_CREDIT:
								peoplesInTop.current++
								savedIndexPeoples = index
								if (savedIndexPeoples !== null && savedIndexPeoples !== index) peoplesInTop.current++
								itemInTop = <React.Fragment key={`actor-gen-frag-${index}`}>
									{peoplesInTop.current === 1 ? peoplesTitle : null}
									<ActorsAndGenres type={item.type}
										item={item.data} key={`${page.categorySelected}-${index}`} page={page} />
								</React.Fragment>
								break
							case page.TYPE_GENRE:
								genresInTop.current++
								savedIndexGenres = index
								if (savedIndexGenres !== null && savedIndexGenres !== index) genresInTop.current++
								itemInTop = <React.Fragment key={`actor-gen-frag-${index}`}>
									{genresInTop.current === 1 ? genresTitle : null}
									<ActorsAndGenres type={item.type}
										item={item.data.name} key={`${page.categorySelected}-${index}`} page={page} />
								</React.Fragment>
								break
							case page.TYPE_CHANNEL:
								if (TOP_CHANNELS) {
									channelsInTop.current++
									savedIndexChannels = index
									if (savedIndexChannels !== null && savedIndexChannels !== index) channelsInTop.current++
									itemInTop = <React.Fragment key={`frag-${index}`}>
										{channelsInTop.current === 1 ? channelsTitle : null}
										<Channels item={item.data} withName={true}
											key={`${page.categorySelected}-${index}`} page={page}
										/>
									</React.Fragment>
								}
								else itemInTop = null
								break
							default:
								itemInTop = <React.Fragment key={`prog-frag-${index}`}>
									<Programs item={item} page={page} typeCards={page.categorySelected}
										key={`${page.categorySelected}-${index}`} />
								</React.Fragment>
								break
						}
						return (<React.Fragment key={`mainTop-${index}`}>{itemInTop}</React.Fragment>)
					})
					break
				// MTVW-624: applyTabFilter for movies 
				case page.CATEGORY_MOVIES:
					_event.current = page.applyTabFilter(_categoryData, page.filterEventType)?.map((item, index) => {
						return (<Programs item={item} page={page} typeCards={page.categorySelected}
							key={`${page.categorySelected}-${index}`} />)
					})
					break
				case page.CATEGORY_SERIES:
				case page.CATEGORY_SPORTS:
					_event.current = _categoryData?.map((item, index) => {
						return (<Programs item={item} page={page} typeCards={page.categorySelected}
							key={`${page.categorySelected}-${index}`} />)
					})
					break
				case page.CATEGORY_ACTORS:
				case page.CATEGORY_GENRES:
					_event.current = _categoryData?.map((item, index) => {
						return (<ActorsAndGenres type={page.categorySelected === page.CATEGORY_ACTORS ? page.TYPE_CREDIT : page.TYPE_GENRE}
							item={item} key={`${page.categorySelected}-${index}`} page={page} />)
					})
					break
				case page.CATEGORY_CHANNELS:
					_event.current =
						<div className="searchChannelsMainContainer">
							{_categoryData?.map((item, index) => {
								return (<Channels item={item} withName={false}
									key={`${page.categorySelected}-${index}`} page={page}
								/>)
							})
							}
						</div>
					break
				default:
					_event.current = null
					break
			}
			setCurrent(_event.current)
		}
		// essential to have the result synchronized with the category
		// without the status change the visualization would be the previously selected category
		setNewCategory(page.categorySelected)
		mainSearch()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page.categorySelected, page.filterEventType])

	return page.overview?.[page.categorySelected]?.length === 0 ? <NotFound /> :
		<div className="programsContainer" data_test={page.categorySelected}>
			{current}
		</div>
})

export const Search = CreateComponent(null, false, true, () => {
	const page = rootStore.page.Search
	const [txtToSearch, setTxtToSearch] = useState("")
	const ref = createRef()
	const refSearchMainContainer = useRef()

	/* MTVW-617: handled in page.addToRecentSearch 
	const MAX_RECENTS = 5
	const removeFromArray = (arr, what) => {
		//console.debug("arr %o, what %s", arr, what)
		for (let i = 0; i < arr?.length; i++) {
			if (arr[i].toLowerCase() === what.toLowerCase()) {
				//console.debug("remove %o", arr[i])
				arr.splice(i, 1);
				i--
			}
		}
	}
	*/

	const handleNewSearchImpl = (value) => {
		setTxtToSearch(value)
		// maintain recent searches in local storage
		// make a copy of the array, otherwise we going to modify the reference
		/* MTVW-617: handled in page.addToRecentSearch 
		let recent = [...rootStore.sso.browserStore.getRecentSearches()]
		if (!recent) recent = []
		const lValue = value
		removeFromArray(recent, rootStore.sso.lastSearch)
		if (lValue?.length > 1) removeFromArray(recent, lValue.substring(0, lValue.length - 1))
		// remove if already existing, could lead to MAX_RECENTS-1 entries
		removeFromArray(recent, lValue)
		// remove oldest at the bottom
		if (recent?.length >= MAX_RECENTS) removeFromArray(recent, recent[recent.length - 1])
		// insert at top
		if (lValue?.length > 0) recent.unshift(lValue)
		rootStore.sso.browserStore.setRecentSearches(recent)
		*/

		// MTVW-185: save last search
		//rootStore.sso.lastSearch = value
	}

	// mitigate flickering updates
	const handleNewSearch = debounce(handleNewSearchImpl, 200)

	const resetSearchTxt = () => {
		setTxtToSearch("")
		// MTVW-185
		rootStore.sso.lastSearch = ""
		if (ref.current) {
			ref.current.firstElementChild.value = ""
		}
		// MTVW-624: combined activityStack for related content and search
		rootStore.page.OverlayEventDetails.resetActivity()
	}

	// change the value in search
	useEffect(() => {
		// MTVW-185: restore the value also on page reload
		// MTVW-511: not need restore the value also on page reload anymore
		// const text = rootStore.sso.lastSearch
		// if (ref.current) {
		// 	ref.current.firstElementChild.value = text
		// }
		// if (page.repoReady && text !== "") {
		// 	setTxtToSearch(text)
		// }
		if (txtToSearch !== "") {
			//page.search(txtToSearch)
			// searchOverview is currently called from page.search, accessible from page.overview
			page.searchOverview(txtToSearch)
			//page.getSeries("crid:~~2F~~2Fmedia-press.tv~~2Fbase~~2Fde~~2F3169734")
			//page.getSeries("crid:~~2F~~2Fmedia-press.tv~~2Fseason~~2Fde~~2F1589422")
			//page.searchActor("Neil Flynn")
			//page.searchGenre("Spielfilm")
			//page.searchActorInGenre("Neil Flynn", "Spielfilm")
			//page.searchActorInGenre("Roger Moore", "Spielfilm")
			//page.search("James Bond", "", "Pierce")
			//page.searchMovies("James Bond", "Thriller", "Sean")
			//page.searchSeries("Big Bang", "Komödie", "Johnny")
			//page.searchEpisodes("Big Bang", "Komödie", "Johnny")
			//page.searchEpisodes("Big Bang")
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [txtToSearch, page.repoReady])

	useEffect(...page.getMountBody(() => { }))

	useEffect(() => {
		if (txtToSearch !== "") {
			if (page.categorySelected === null) page.setCategorySelected(page.CATEGORY_TOP)
			// mainSearch()
		} else {
			page.setCategorySelected(null)
			page.setGenresSelected(page.ALL_GENRES_SELECTED)
			page.setSeasonSelected(page.ALL_SEASON_SELECTED)
			page.setScrollposSelected(null)
		}
		refSearchMainContainer?.current?.scrollTo(0, 0)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page.categorySelected, txtToSearch, page.filterEventType])

	return (
		<div className="rootSearch" data_test="search_main_page">
			<div className="searchMainContainer" data_test="search_main_container" ref={refSearchMainContainer}>
				<SearchBar ref={ref} handleNewSearch={handleNewSearch} resetSearchTxt={resetSearchTxt} showResetIcon={txtToSearch !== ""} page={page} />
				{txtToSearch === "" ? <StartPage setSearch={setTxtToSearch} searchBar={ref} page={page} /> :
					page.isOverViewReady ? <MainSearch page={page} /> : null  /* add spinner?????*/}
			</div>
		</div>
	)
})
