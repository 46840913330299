import { PageAbstract } from "./PageAbstract"
import { rootStore } from "store/RootStore"
import { computed, observable, action, makeObservable, runInAction } from "mobx"
import { appFeatures } from "../../AppFeatures"

export class Onboarding extends PageAbstract {
   // STATE CONSTANTS
   get INIT() { return 0 }      // initial state
   get CHECKING() { return 1 }  // checking announcements
   get AVAILABLE() { return 2 } // announcement available
   get NONE() { return 3 }      // none available or dismissed

   // for BBD test purposes
   get REGULAR_ONBOARDING() { return 0 }  // default value or missing localStorage.onboardingOption
   get SKIP_ONBOARDING() { return 1 }     // don't show onboarding (for speeding up BDD tests)
   get FORCE_ONBOARDING() { return 2 }    // clear announcements, show onboarding

   state = this.INIT
   blockingAnnouncement = false //config flag, if false: load and show content in background
   announcements = null
   announcementLink = null
   iframeProps = {}
   playerMuted = false

   constructor(parent, path) {
      super(parent, path)
      makeObservable(this, {
         state: observable,
         hasBlockingAnnouncement: computed,
         getAnnouncement: action,
         checkAnnouncements: action,
         announcementSeen: action,
         playerActive: action,
         modalDone: action
      })
      // for test purpose
      //appFeatures.persistedOnboardingOption = this.FORCE_ONBOARDING
      if (appFeatures.persistedOnboardingOption === this.FORCE_ONBOARDING) {
         rootStore?.onboardingService?.clearAnnouncementsAsync?.(rootStore?.sso?.contractId)
      }
   }

   get hasBlockingAnnouncement() {
      //console.debug("hasBlockingAnnouncement", this.blockingAnnouncement, this.state)
      return this.blockingAnnouncement && (this.state !== this.NONE)
   }

   get hasNonBlockingAnnouncement() {
      //console.debug("hasNonBlockingAnnouncement", this.blockingAnnouncement, this.state)
      return !this.blockingAnnouncement && (this.state !== this.NONE)
   }

   playerActive() {
      if (this.state === this.AVAILABLE) {
         //console.debug("onboarding playerActive", this.state, rootStore.page.Player.player.isMuted)
         if (!rootStore.page.Player.player.isMuted) {
            rootStore.page.Player.player.setMuteState(true)
            this.playerMuted = true
         }
      }
   }

   modalDone() {
      //console.debug("modalDone")
      this.state = this.NONE  // modal disappears
      if (this.playerMuted) rootStore.page.Player.player.setMuteState(false)
   }

   announcementSeen() {
      console.debug("announcementSeen")
      //this.modalDone()
      if (this.announcements?.length > 0) {
         const announcementId = this.announcements[0].id
         console.debug("announcementSeen ID", announcementId)
         // if enabled, will not get announcement again
         // eslint-disable-next-line no-unused-expressions
         rootStore?.onboardingService?.announcementSeenAsync(rootStore?.sso?.contractId, announcementId)
         this.state = this.INIT
         // remove current announcement
         this.announcements.shift()
         // check if there is another announcement
         this.getAnnouncement()
      }
      else this.modalDone()
   }

   getAnnouncement() {
      if (this.state === this.INIT) {
         this.state = this.CHECKING
         if (this.announcements?.length > 0) {
            try {
               //console.debug("onboarding page url", url)
               //this.announcementLink = "https://canary-preprod.qltv.quickline.ch/onboarding/v002/contracts/39067/announcements/2022_07_08_sample_campaign/sample_campaign.htm"
               //this.announcementLink = "https://canary-preprod.qltv.quickline.ch/onboarding/v002/contracts/96359/announcements/2022_07_08_sample_campaign/2022_07_08_sample_campaign/sample_campaign.htm"
               //this.announcementLink = "campaign/sample_campaign.htm" + "?client=web"
               // this.announcementLink = "campaign/index.htm" + "?client=web"
               this.announcementLink = rootStore?.onboardingService?.getAnnouncementPage(rootStore?.sso?.contractId, this.announcements)
               console.debug("onboarding page url", this.announcementLink)
               if (this.announcementLink) {
                  this.announcementLink += "?client=web"
                  this.state = this.AVAILABLE
                  console.debug("POST openModal")
                  window.postMessage({ request: 'openModal' })
               }
               else {
                  console.debug("NOTHING")
                  this.state = this.INIT
                  // remove current announcement
                  this.announcements.shift()
                  // check if there is another announcement
                  this.getAnnouncement()
               }
            }
            catch (e) {
               this.state = this.NONE
               console.error("checkAnnouncements error %o", e)
            }
         }
         else {
            console.debug("EMPTY")
            //this.state = this.NONE
            this.modalDone()
         }
      }
   }

   //checkAnnouncements = flow(function* (props) {
   checkAnnouncements(props) {
      if (!appFeatures.gt12Enabled || appFeatures.persistedOnboardingOption === this.SKIP_ONBOARDING) {
         this.state = this.NONE
         return
      }
      // prevent multiple fetches
      if (this.state === this.INIT) {
         this.iframeProps = props
         // eslint-disable-next-line no-unused-vars
         const unused = rootStore?.onboardingService?.announcementsAsync(rootStore?.sso?.contractId, "large")
            .then((res) => {
               //console.debug("onboarding:", res)
               this.announcements = res
               // disable modal
               // test this.announcements = {}
               runInAction(() => { this.getAnnouncement() })
            })
            .catch((e) => {
               runInAction(() => { this.state = this.NONE })
               console.error("checkAnnouncements error %o", e)
            })
      }
   }
}
