import { PageAbstract } from "./PageAbstract"
import { action, computed, flow, observable, makeObservable } from "mobx"

export class Settings extends PageAbstract {

	snackbarMessage = null

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			snackbarMessage: observable,
			setSnackbarMessage: action
		})
	}

	setSnackbarMessage(message) {
		//console.debug("setSnackbarMessage", message)
		this.snackbarMessage = message
	}
}
