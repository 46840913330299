import { Typography } from "@mui/material"
import { CreateComponent } from "components/utils/CreateComponent"
import React from "react"
import { rootStore } from "store/RootStore"


// MTVW-181 ok
import '../recordingList.css'

const styles = theme => ({})  //MTVW-181  need for the createComponent
export const RecordingsSpaceInfo = CreateComponent(styles, false, true, () => {
	const Customer = rootStore.page.Recordings.oRecCustomer

	//const widthProgressBar = 240 ///lineProgressContainer width
	const timeHoursFreiInPercentage = parseInt((Customer.NpvrQuotaRemainingInMinutes * 100) / Customer.NpvrQuotaInMinutes)

	const timeHoursUsedInPercentage = 100 - timeHoursFreiInPercentage
	//const hoursUsed = Customer.NpvrQuotaInMinutes - Customer.NpvrQuotaRemainingInMinutes // total hours available  - remaining usable hours
	return (
		<div className={"recordingsInfoWrapper"}>
			<div className={"lineProgressContainer"}>
				<div className={"lineTotHoursUsed"} style={{ width: `${timeHoursUsedInPercentage}%` }}></div>
			</div>
			{Customer.NpvrQuota !== void 0 && (
				<Typography variant="h6" align="center" data-test="recording_space_info_all">
					{Customer.NpvrQuotaRemainingInMinutes} von  {Customer.NpvrQuotaInMinutes} Stunden verfügbar
				</Typography>
			)}

		</div>
	)
})
