import { types as t } from "mobx-state-tree"
import { TraxisAbstract } from "store/api/TraxisAbstract"

const ClearRecordingOptInDateModel = t.model({})

export class ClearRecordingOptInDate extends TraxisAbstract {
	//_onStartCreate = true
	//_onStartRemove = true

	get _model() {
		return ClearRecordingOptInDateModel
	}

	_getParseResp(data) {
		return data
	}

	//_getHashReq(data) {
	//	return ``
	//}

	getXmlReq() {
		return `<?xml version="1.0" encoding="utf-8"?>
<Request xmlns="urn:eventis:traxisweb:1.0">
	<Identity>
		<CustomerId>${this._oVars.customerId}</CustomerId>
	</Identity>
	<ActionQuery resourceType="Customer" resourceId="${this._oVars.resourceId}" actionName="ClearRecordingOptInDate" /> 
</Request>

`
	}
}
