/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-08
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-11
 */

import { action, flow, computed, makeObservable } from "mobx"
import { moment } from "store/util/moment"
//import { SourceAbstract } from "store/page/player/source/SourceAbstract"
import { SourceTypeList } from "store/page/player/source/SourceTypeList"
//import { SourceChannelReplay } from "store/page/player/source/SourceChannelReplay"
//import { SourceAdPlaylist } from "store/page/player/source/SourceAdPlaylist"
import { SourceAbstract, SourceChannelReplay } from './InternalImports'
import { isIphone } from "utils/Utils"
//import { rootStore } from "store/RootStore"

export class SourceChannelLive extends SourceAbstract {
	type = SourceTypeList.ChannelLive

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			getClone: action,
			setPlayPause: action,
			setJumpLive: action,
			setJumpRwd: action,
			setJumpFwd: action,
			setReplay: action,
			isButtonReplayEnabled: computed,
			isButtonJumpFwdEnabled: computed,
			isButtonJumpRwdEnabled: computed
		})
		//console.debug("constructor SourceChannelLive %o, %o", parent.player, path)
		this._player = parent.player
	}

	/**
	 * @returns {SourceChannelLive}
	 */
	static create(parent, channelId, oEventFull, restartDone) {
		const source = new SourceChannelLive(parent, "source")
		source.Channel = channelId
		source.id = channelId
		source.GetEvent = oEventFull
		source.restartDone = restartDone
		return source
	}

	/**
	 * @returns {SourceChannelLive}
	 */
	getClone() {
		const source = new SourceChannelLive(this._parent, "source")
		source.Channel = this.Channel.id
		source.id = this.id
		source.GetEvent = this.GetEvent
		source.restartDone = this.restartDone
		source.isLoading = false
		return source
	}

	// GT12: MTVW-460, MTVW-502: Channels MS flags
	get isReplayAvailable() {
		// hiding of button done in isButtonReplayHidden()
		//return this.MsEpg?.isReplayAllowed(this.id, this.Event?.AvailabilityStartDateTs, this.Event?.AvailabilityEndDateTs)
		return true
	}

	isSourceEqual(source) {
		return this.type === source.type && this.id === String(source.id)
	}

	// buttons disable state
	get isButtonLiveEnabled() {
		return false
	}

	get isButtonReplayEnabled() {
		// TODO joachim: temporary hack until we solve anvato positioning
		if (this.player.isAnvatoSource) {
			return false
		}
		if (!this.player.isStreamLoaded) {
			return false
		}
		if (this.isReplayAvailable) {
			return true
		}
		return false
	}

	get isButtonJumpFwdEnabled() {
		return false
	}

	get isButtonJumpRwdEnabled() {
		// TODO joachim: temporary hack until we solve anvato positioning
		if (this.player.isAnvatoSource) {
			return false
		}
		if (!this.player.isStreamLoaded) {
			return false
		}
		if (this.player.isJumpRwdPossibleSec(this.settings.jumpRwdSec)) {
			return true
		}
		if (this.isReplayAvailable) {
			return true
		}
		return false
	}

	get isButtonPlayPauseEnabled() {
		//console.debug("isButtonPlayPauseEnabled", this.player.isAnvatoSource, this.player.isStreamLoaded, this.isReplayAvailable)
		// TODO joachim: temporary hack until we solve anvato positioning
		if (this.player.isAnvatoSource) {
			return false
		}
		return this.player.isStreamLoaded && this.isReplayAvailable
	}

	get isButtonRecEnabled() {
		return this.player.isStreamLoaded
	}

	// GT12: MTVW-460: Channels MS flags, hasLivePause appies only to live
	get isButtonPlayPauseHidden() {
		//console.debug("hasLivePause %o, %o", this.msEpg?.getChannel(this.id)?.hasLivePause, this.msEpg?.getChannel(this.id))
		return !this.msEpg?.getChannel(this.id)?.hasLivePause
	}

	openStreamAsync = flow(function* () {
		console.info("@@openStreamAsync() -> ")
		yield this._openStreamLiveAsync()
		//yield this._openStreamRollingBufferAsync("Wed, 02 Oct 2019 07:30:00 +0200")
		console.info("@@openStreamAsync() <- ")
	})


	// MTVW-788, MTVW-787: flow function
	//setPlayPause() {
	setPlayPause = flow(function* () {
		//console.debug("setPlayPause SourceChannelLive", this.player.isPaused, this.player)
		//if (!this.debounced()) return
		if (!this.player.isPaused) {
			// GT12 will be handled upon player pause event
			if (import.meta.env.VITE_PLAYER === "vo") {
				this.player.setPause()
			}
			else if (import.meta.env.VITE_PLAYER === "vjs") {
				// MTVW-444
				console.debug("setPlayPause live", this._parent.playerFg, this.player, this._parent)
				this.player.setIsPaused(true, true)

				// inhibit setPlay from Player useEffect
				this.player.setIsPausedUser(false)
				const positionMoment = moment(this.player.positionTimeStamp)
				// GT12: for GT12 we are still in 'startLive', copy sessionId
				//console.debug("setPlayPause", this.GetEvent, positionMoment, positionMoment.utc().valueOf())
				yield this._parent.activateBgPlayer()
				//console.debug("calling setPlayChannelReplayAsync", positionMoment)
				yield this._parent.setPlayChannelReplayAsync(this.Channel?.id, positionMoment, this.GetEvent, false, false, this.sessionId, this.restartDone).then(() => {
					//console.debug("setPlayPause positionMoment", positionMoment)
					//this.player.setPositionTimeStamp(positionMoment).setPlay("SourceChannelLive.setPlayPause")
				})
				//yield this.player.setPlay("SourceChannelLive.setPlayPause")
				/* TODO: We should use handlePlaySourceAsync in order to have the full replay stream
				const source = SourceChannelReplay.create(this._parent, this.Channel?.id, this.GetEvent, this.Event?.AvailabilityStartDate, false, this.sessionId, this.restartDone)
				yield this._parent.handlePlaySourceAsync(source.setAsLoaded(), this.player.positionTimeStamp)
				yield this.player.setPositionTimeStampAsync(positionMoment)
				//yield this.player.setPlay("SourceChannelLive.setPlayPause")
				*/
			}
		} else {
			this.player.setIsPosterVisible(true, "setPlayPause", true)
			console.debug("@() - move to rolling buffer, positionTimeStamp %s, allowed %s", this.player.positionTimeStamp, this.MsEpg?.isReplayAllowed(this.id, this.Event?.AvailabilityStartDateTs, this.Event?.AvailabilityEndDateTs))
			this.removePauseAd()
			// GT12: MTVW-460, MTVW-502: Channels MS flags
			/*
			if (!this.msEpg?.isReplayAllowed(this.id, this.Event?.AvailabilityStartDateTs, this.Event?.AvailabilityEndDateTs)) {
				this.player.setJumpLive().setPlay("SourceChannelLive.setPlayPause")
			}
			else {
				const positionTimeStamp = this.player.positionTimeStamp
				// GT12: for GT12 we are still in 'startLive', copy sessionId
				this._parent.setPlayChannelReplayAsync(this.Channel?.id, positionTimeStamp, this.GetEvent, false, false, this.sessionId, this.restartDone).then(() => {
					this.player.setPositionTimeStamp(positionTimeStamp).setPlay("SourceChannelLive.setPlayPause")
				})
			}
			*/
			if (import.meta.env.VITE_PLAYER === "vjs") {
				// inhibit setPlay from Player useEffect
				this.player.setIsPausedUser(false)
			}
			const positionMoment = moment(this.player.positionTimeStamp)
			// GT12: for GT12 we are still in 'startLive', copy sessionId
			//console.debug("setPlayPause", this.GetEvent, positionMoment, positionMoment.utc().valueOf())
			yield this._parent.setPlayChannelReplayAsync(this.Channel?.id, positionMoment, this.GetEvent, false, false, this.sessionId, this.restartDone).then(() => {
				//console.debug("setPlayPause positionMoment", positionMoment)
				//this.player.setPositionTimeStamp(positionMoment).setPlay("SourceChannelLive.setPlayPause")
			})
		}
		return this
	})

	//setJumpLive() {
	setJumpLive = flow(function* () {
		console.info("@")
		this.removePauseAd()
		yield this.player.setJumpLive().setPlay("setJumpLive")
	})

	setJumpRwd = flow(function* () {
		if (this.isReplayAvailable) {
			//this.removePauseAd()
			this.player.setIsPosterVisible(true, "setJumpRwd", true)
			const positionTimeStamp = moment(this.player.positionTimeStamp).clone().subtract(moment.duration(this.settings.jumpRwdSec, "seconds")).valueOf()
			// GT12: for GT12 we are still in 'startLive', copy sessionId and set trueReplaySession 'false'
			if (import.meta.env.VITE_PLAYER === "vjs") {
				// inhibit setPlay from Player useEffect
				this.player.setIsPausedUser(false)
				yield this._parent.player.stopInstance()
			}
			/**/
			const source = SourceChannelReplay.create(this._parent, this.Channel?.id, this.GetEvent, this.Event?.AvailabilityStartDate, false, this.sessionId, this.restartDone)
			if (import.meta.env.VITE_PLAYER === "vjs") {
				yield this._parent.handlePlaySourceAsync(source.setAsLoaded(), positionTimeStamp)
				//console.debug("setJumpRwd %o, %o", positionTimeStamp, this.player)
				//this.player.setPositionTimeStamp(positionTimeStamp).setPlay("SourceChannelLive.setJumpRwd")
			}
			else if (import.meta.env.VITE_PLAYER === "vo") {
				yield this._parent.handlePlaySourceAsync(source.setAsLoaded())
				//console.debug("setJumpRwd %o, %o", positionTimeStamp, this.player)
				yield this.player.setPositionTimeStamp(positionTimeStamp).setPlay("SourceChannelLive.setJumpRwd")
			}
			/**/

			/*
			this._parent.setPlayChannelReplayAsync(this.Channel?.id, moment(positionTimeStamp), this.GetEvent, false, false, this.sessionId, this.restartDone).then(() => {
				//console.debug("setPlayPause positionMoment", positionMoment)
				//this.player.setPositionTimeStamp(positionMoment)
			})
			*/
		}
	})

	/* GT12, UNSED
	_handlePlayChannelReplayAsync = flow(function* () {
		console.info("@@openStreamAsync() -> ")
		yield this._openStreamLiveAsync()
		//yield this._openStreamRollingBufferAsync("Wed, 02 Oct 2019 07:30:00 +0200")
		console.info("@@openStreamAsync() <- ")
	})
	*/

	handleSetPosition = flow(function* (positionMoment) {
		//console.debug("handleSetPosition", positionMoment, this.player.rangeTimeStampEnd)
		if (positionMoment.isBefore(this.player.rangeTimeStampEnd)) {
			// create source
			//this.removePauseAd()
			// GT12: for GT12 we are still in 'startLive', copy sessionId and set trueReplaySession 'false'
			if (import.meta.env.VITE_PLAYER === "vjs") {
				// inhibit setPlay from Player useEffect
				this.player.setIsPausedUser(false)
				yield this._parent.player.stopInstance()
			}
			const source = SourceChannelReplay.create(this._parent, this.Channel?.id, this.GetEvent, positionMoment, false, this.sessionId, this.restartDone).setAsLoaded()
			// set prev progressBar
			// TODO: Workaround, add 1000ms to force seek event in order to avoid blank screen
			// when progress bar slider is moved to the leftmost position. Less than 1 second will not generate a seek event.
			//console.debug("handleSetPosition", positionMoment)
			yield this._parent.handlePlaySourcePreserveProgressBarAsync(source)
			//console.debug("handleSetPosition %o", positionMoment)
			//console.debug("setPlay fromhandleSetPosition")
			this.player.setPositionTimeStamp(positionMoment.add(1000, 'ms')).setPlay("handleSetPosition")

			/*
			yield this._parent.handlePlaySourcePreserveProgressBarAsync(source).then(() => {
				//console.debug("handleSetPosition %o", positionMoment)
				this.player.setPositionTimeStamp(positionMoment.add(1, 'second')).setPlay("SourceChannelLive.handleSetPosition")
			})
			*/
		}
	})

	setJumpFwd() {
		console.info("@")
		//this.removePauseAd()
		this.player.setJumpFwd()
	}

	// MTVW-287
	//setReplay() {
	setReplay = flow(function* () {
		//this.removePauseAd()
		//const evt = yield rootStore.page.MsEpg.getEventByChannelTime(this.Channel?.id, moment(this.GetEvent.event.AvailabilityStartTs).utc().valueOf())
		//const source = SourceChannelReplay.create(this._parent, this.Channel?.id, evt, undefined, true, null, this.restartDone).setAsLoaded()
		if (import.meta.env.VITE_PLAYER === "vjs") {
			// inhibit setPlay from Player useEffect
			this.player.setIsPausedUser(false)
			yield this._parent.player.stopInstance()
		}
		//console.debug("setReplay parent %o, channel %o, Event %o, GetEvent %o, start %o", this._parent, this.Channel?.id, this.Event, this.GetEvent, this.Event?.AvailabilityStartDate)
		const source = SourceChannelReplay.create(this._parent, this.Channel?.id, this.GetEvent, this.Event?.AvailabilityStartDate, true, null, this.restartDone).setAsLoaded()
		//const source = SourceChannelReplay.create(this._parent, this._parent?.Channel?.id, this._parent?.GetEvent, undefined, true, null, this.restartDone).setAsLoaded()

		// GT12: replay Ad will be handled in _openStreamRollingBufferAsync via Player.handlePlaySourceAsync
		this.player.setIsPosterVisible(true, "setReplay", true)
		yield this._parent.handlePlaySourcePreserveProgressBarAsync(source)
		this.markerPosD = null
	})
}
