import React, { useEffect, useLayoutEffect, useRef } from "react"
import { IconButton } from "@mui/material"
import { rootStore } from "store/RootStore"
import LiveIndicator from "img/icons/liveInSearch.svg"
import Play from "img/icons/playInSearch.svg"
import LinearProgress from "@mui/material/LinearProgress"
import serieLogo from "img/icons/series.svg"
import "./SearchComponents.css"
import "./../../Search/SearchStyle.css"
import { observer } from "mobx-react"
import { CALLING_CONTEXT_TYPE } from "store/page/OverlayEventDetails"
import { FilterEventTypeBar } from "scenes/Search/components/FilterTypeEventBar"
// import goBack from "img/icons/search/arrow_back_ios_white_36dp.svg"
// import goForward from "img/icons/search/arrow_forward_ios_white_36dp.svg"
import { NotFound, NOTFOUND_TYPE } from "./../Search"
import { FilterBar } from "scenes/Search/components/FilterBar"

const Programs = observer(({ item, page, typeCards, serieId }) => {
	const _EVENT_DATA = {
		logoImgSrc: null,
		channelName: null,
		eventTitle: null,
		genres: null,
		seasonInfo: null,
		programDate: null,
		liveIcon: null,
		progressBar: null,
		playButton: null,
		seriesMobileIcon: null,
		type: null,
		idEvent: null,
		idSerie: null,
	}
	const backGroundImgStyle = { ...page.backGroundImgStyle }
	const loadData = () => {
		backGroundImgStyle.backgroundImage = `${page.gradientImg},url(${item?.content?.posterUrl}?mode=box&w=480&h=270)`
		const isLiveEvent = item?.event?.eventType === "c" ? true : false
		const isPastEvent = item?.event?.eventType === "p" ? true : false

		const season = item?.content?.Season ? `S${item?.content?.Season}` : ""
		const episode = item?.content?.Episode ? `E${item?.content?.Episode}` : ""
		_EVENT_DATA.seasonInfo = `${season} ${episode} `

		const title = item?.content?.EpisodeName ? item?.content?.EpisodeName : ""
		// MTVW-624: don't display item.content.Title
		//_EVENT_DATA.eventTitle = `${season !== "" || episode !== "" ? (title !== "" ? "\u25CF" : "") : item.content.Title} ${title}`
		_EVENT_DATA.eventTitle = `${season !== "" || episode !== "" ? (title !== "" ? "\u25CF" : "") : ""} ${title}`

		_EVENT_DATA.idEvent = item?.event?.id
		_EVENT_DATA.logoImgSrc = page.getChannelLogo(item?.event?.channelId)
		_EVENT_DATA.channelName = (
			<div className="searchChannelName" data_test="search_channel_name">
				{page.getChannelName(item?.event?.channelId)}
			</div>
		)

		_EVENT_DATA.genres = item?.content?.genres?.join(" \u25CF ")
		//_EVENT_DATA.genres = _EVENT_DATA.eventTitle ? "" : item?.content?.genres?.join(" \u25CF ")
		_EVENT_DATA.programDate = item?.event?.dateAndTime
		_EVENT_DATA.seriesMobileIcon = (
			<div style={{ position: "relative" }}>
				<img className="searchSeriesMobileIcon" src={serieLogo} alt="series" data_test="search_event_series_icon"></img>
			</div>
		)
		_EVENT_DATA.liveIcon = isLiveEvent ? <img src={LiveIndicator} className="searchLiveIndicator" data_test="search_event_live_icon" alt="" /> : null
		_EVENT_DATA.progressBar = isLiveEvent ? (
			<LinearProgress classes={{ root: "searchMuiLinearProgress" }} variant="determinate" value={item.event.durationInPercent} data-test="search_event_progressbar" />
		) : null
		_EVENT_DATA.playButton =
			(isLiveEvent || isPastEvent) && page.isReplayAllowed(item?.event) ? (
				<div style={{ position: "relative" }}>
					<img className="searchPlayIcon" src={Play} alt="play" data_test="search_event_play_icon" style={{ bottom: isLiveEvent ? "10px" : "-1px" }}
						onClick={(e) => {
							// MTVW-624: autoplay support
							e.stopPropagation()
							// MTVW-629: close overlay if liveTv
							rootStore.page.OverlayEventDetails.setEpgEventById(_EVENT_DATA.idEvent, true).then((res) => {
								rootStore.page.OverlayEventDetails.setComponentType('event').setShow(!rootStore.page.Player.playerLiveTvVo.isPlayerActive, CALLING_CONTEXT_TYPE.Search)
							})
						}}></img>
				</div>
			) : null
	}

	if (item) loadData()
	else return null

	return (
		<div
			className="eventContainer"
			data_test="search_event"
			onClick={(e) => {
				e.stopPropagation()
				// MTVW-629: isPlayerPresent needs to be false in order to display event 
				rootStore.page.OverlayEventDetails.setIsPlayerPresent(false)
				rootStore.page.OverlayEventDetails.setEpgEventById(_EVENT_DATA.idEvent).then((res) => {
					rootStore.page.OverlayEventDetails.setComponentType("event").setShow(true, CALLING_CONTEXT_TYPE.Search)
					// MTVW-624: combined activityStack for related content and search
					rootStore.page.OverlayEventDetails.pushActivity({ type: page.CATEGORY_SERIES, idSerie: serieId })
				})
			}}
		>
			<div className="searchEventLogoChannel">
				<img className="searchLogoChannel" src={_EVENT_DATA.logoImgSrc} alt="channel logo"></img>
			</div>
			<div className="searchEventInfoContainer">
				<div className="searchEventInfo">
					<div className="searchEventInfoText">
						{_EVENT_DATA.channelName}
						<div className="searchEventTitle" data_test="search_event_title">
							{_EVENT_DATA.seasonInfo}
							{_EVENT_DATA.eventTitle}
						</div>
						{/* <div className="searchEventGenresInfo">{_EVENT_DATA.genres}</div> */}
					</div>
					<div className="searchEventInfoPoster">
						<div className="eventPosterSection" style={backGroundImgStyle} data_test="search_event_poster"></div>
					</div>
				</div>
				<div className="searchEventIconsAndBar">
					{_EVENT_DATA.liveIcon}
					<span data_test="search_event_info">{_EVENT_DATA.programDate}</span>
					{_EVENT_DATA.progressBar}
					{_EVENT_DATA.playButton}
				</div>
			</div>
		</div>
	)
})

// const FilterSeason = ({ page }) => {

// 	const [scrollArrow, setScrollArrows] = useState({
// 		leftArrow: false,
// 		rightArrow: false,
// 	})
// 	const [filterIsOverflowing, setFilterIsOverflowing] = useState(false)

// 	const refFilterSeason = useRef()
// 	let _filter = []
// 	const handleResize = () => {
// 		const overFlow = refFilterSeason?.current.clientWidth < refFilterSeason?.current?.scrollWidth
// 		setFilterIsOverflowing(overFlow)
// 		// MTVW-547: update arrow states
// 		setScrollArrows({
// 			leftArrow: overFlow ? refFilterSeason?.current?.scrollLeft > 0 : false,
// 			rightArrow: overFlow ? refFilterSeason?.current?.scrollLeft === 0 : false
// 		})
// 	}

// 	useEffect(() => {
// 		if (refFilterSeason.current) {
// 			refFilterSeason.current.scrollLeft = 0
// 			setScrollArrows({
// 				leftArrow: filterIsOverflowing ? refFilterSeason?.current?.scrollLeft > 0 : false,
// 				rightArrow: filterIsOverflowing ? refFilterSeason?.current?.scrollLeft === 0 : false
// 			})
// 		}

// 	}, [filterIsOverflowing])

// 	//TO Verify  if this useEffect is necessary
// 	useEffect(() => {
// 		setFilterIsOverflowing(refFilterSeason?.current?.clientWidth < refFilterSeason?.current?.scrollWidth)
// 		// eslint-disable-next-line react-hooks/exhaustive-deps
// 	}, [refFilterSeason?.current?.scrollWidth])

// 	useEffect(() => {
// 		handleResize()
// 		// eslint-disable-next-line react-hooks/exhaustive-deps
// 	}, [page?.series?.seasons])

// 	useEffect(() => {
// 		window.addEventListener("resize", handleResize)
// 		return (() => {
// 			window.removeEventListener("resize", handleResize) // when unload
// 		})
// 		// eslint-disable-next-line react-hooks/exhaustive-deps
// 	}, [])
// 	if (page?.series?.seasons?.length > 1) {
// 		_filter.push(page.ALL_SEASON_SELECTED)
// 		// eslint-disable-next-line no-unused-expressions
// 		page?.series?.seasons?.map(item => _filter.push(`${item.number}`))
// 		return (<div className="SearchFilterBarContainer">
// 			<img className={`SearchFilterBarArrow ${!scrollArrow.leftArrow ? 'SearchFilterBarArrowDisabled' : ''}`} src={goBack} alt=" icon"
// 				onClick={(e) => {
// 					// MTVW-547:
// 					//refFilterSeason.current.scrollLeft = 0
// 					let scrollPos = refFilterSeason.current.scrollLeft - refFilterSeason.current.clientWidth / 2
// 					if (scrollPos < 0) scrollPos = 0
// 					refFilterSeason.current.scrollLeft = scrollPos
// 					setScrollArrows({ leftArrow: scrollPos === 0 ? false : true, rightArrow: true })
// 				}}
// 			></img>

// 			<div className="filterSeasonsBar" ref={refFilterSeason}
// 				onScroll={() => {
// 					const maxScrollLeft = Math.floor(refFilterSeason?.current?.scrollWidth - refFilterSeason?.current?.clientWidth) - 2
// 					//console.debug("scroll refSearchFilter.current.scrollLeft =%s --- max=%s", refFilterSeason.current?.scrollLeft, maxScrollLeft)
// 					if (refFilterSeason.current?.scrollLeft === 0) {
// 						setScrollArrows({ leftArrow: false, rightArrow: true })
// 					} else if (refFilterSeason?.current?.scrollLeft > maxScrollLeft) {
// 						setScrollArrows({ leftArrow: true, rightArrow: false })
// 					} else {
// 						setScrollArrows({ leftArrow: true, rightArrow: true })
// 					}
// 				}}
// 			>
// 				{
// 					_filter.map((item, index) => {
// 						const rootSearchFilterButton = page.seasonSelected === item ? 'searchFilterButtons searchFilterButtonSelected' : 'searchFilterButtons'
// 						return (
// 							<Button variant="outlined"
// 								onClick={() => {
// 									page.setSeasonSelected(item)
// 								}}
// 								classes={{ root: `${rootSearchFilterButton}` }}
// 								key={`season ${item}`}
// 							>{item}</Button>
// 						)
// 					})
// 				}
// 			</div>
// 			<img className={`SearchFilterBarArrow ${!scrollArrow.rightArrow ? 'SearchFilterBarArrowDisabled' : ''}`} src={goForward} alt=" icon"
// 				onClick={(e) => {
// 					// MTVW-547:
// 					//refFilterSeason.current.scrollLeft = refFilterSeason.current.scrollWidth
// 					let scrollPos = refFilterSeason.current.scrollLeft + refFilterSeason.current.clientWidth / 2
// 					if (scrollPos > refFilterSeason.current.scrollWidth) scrollPos = refFilterSeason.current.scrollWidth
// 					refFilterSeason.current.scrollLeft = scrollPos
// 					setScrollArrows({ leftArrow: true, rightArrow: scrollPos === refFilterSeason.current.scrollWidth ? false : true })
// 				}}
// 			></img>
// 		</div>
// 		)
// 	} else {
// 		return null
// 	}
// }

export const SeriesComponent = observer((props) => {
	const page = rootStore.page.Search
	const pageOverlay = rootStore.page.OverlayEventDetails
	const refListSeriesContainer = useRef()

	const allEpisodes = []

	useLayoutEffect(() => {
		//console.debug("useLayoutEffect")
		page.setRefresh(true)
		// MTVW-629: set to ALL_SEASON_SELECTED, otherwise there might be no search results
		// MTVW-629: set to FILTER_EVENT_LIVE_REPLAY
		//if (!page.scrollposSelected) page.setSeasonSelected(page.ALL_SEASON_SELECTED)
		page.setFilterEventType(page.FILTER_EVENT_LIVE_REPLAY)
		page.getSeries(props.idSerie)
	}, [page, props.idSerie])

	// the code below is necessary to refresh the data
	useEffect(() => {
		// eslint-disable-next-line no-unused-expressions
		refListSeriesContainer?.current?.scrollTo?.(0, 0)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page.series, page.seasonSelected])

	page.setRefresh(false)
	// end code for refresh

	if (page.seasonSelected === page.ALL_SEASON_SELECTED) {
		// eslint-disable-next-line no-unused-expressions
		page.series?.seasons?.forEach((season) => {
			// eslint-disable-next-line no-unused-expressions
			season.episodes?.forEach((episode) => {
				allEpisodes.push(episode)
			})
		})
	} else {
		// eslint-disable-next-line no-unused-expressions
		page.series?.seasons?.forEach((season) => {
			if (page.seasonSelected === season.number.toString()) {
				season.episodes?.forEach((episode) => {
					allEpisodes.push(episode)
				})
			}
		})
	}

	const filterSeries = () => {
		//console.debug("filteredSeries", page.applyTabFilter(allEpisodes), page.filterEventType)
		const filtered = page.applyTabFilter(allEpisodes, page.filterEventType)
		if (filtered)
			return filtered.map((item, index) => {
				// MTVW-624: scroll to top on tab change
				refListSeriesContainer?.current?.scrollTo?.(0, 0)
				return <Programs item={item} page={page} typeCards={page.categorySelected} key={`${page.categorySelected}-${index}`} serieId={props.idSerie} />
			})
		else return null
	}
	/*
	const visibility = pageOverlay.activityStack.length > 0 ? "visible" : "hidden"
	console.debug("pageOverlay", pageOverlay.activityStack.length, pageOverlay.activityStack.length > 0 ? "visible" : "hidden", pageOverlay)
	*/
	return (
		<div className="seriesComponentMainContainer">
			<div className="seriesComponentTitleContainer">
				<div className="seriesComponentButtonsContainer">
					{/* MTVW-624: combined activityStack for related content and search */}
					<IconButton
						aria-label="Back"
						disableRipple={true}
						disableFocusRipple={true}
						className="seriesComponentBackButton"
						onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
							// MTVW-624: combined activityStack for related content and search
							pageOverlay.restoreActivity()
							// reset filter
							page.setSeasonSelected(page.ALL_SEASON_SELECTED)
							page.setGenresSelected(page.ALL_GENRES_SELECTED)
							// MTVW-620: FILTER_EVENT_ALL -> FILTER_EVENT_LIVE_REPLAY
							page.setFilterEventType(page.FILTER_EVENT_LIVE_REPLAY)
						}}
						data-test="back_series_component"
						style={{ visibility: pageOverlay.activityStack.length > 0 ? "visible" : "hidden" }}
					></IconButton>
					<IconButton
						aria-label="Close"
						disableRipple={true}
						disableFocusRipple={true}
						className="seriesComponentCloseButton"
						onClick={() => {
							// MTVW-624: combined activityStack for related content and search
							pageOverlay.resetActivity()
							pageOverlay.setShow(false)
							// reset filter
							page.setSeasonSelected(page.ALL_SEASON_SELECTED)
							page.setGenresSelected(page.ALL_GENRES_SELECTED)
							// MTVW-620: FILTER_EVENT_ALL -> FILTER_EVENT_LIVE_REPLAY
							page.setFilterEventType(page.FILTER_EVENT_LIVE_REPLAY)
						}}
						data-test="close_series_component"
					></IconButton>
				</div>
				<div className="seriesComponentTitle" data_test="search_series_name">
					{page?.series?.Title}
				</div>
				<div className="seriesComponentFilterSeriesContainer">
					{/* <FilterSeason page={page} /> */}
					<FilterBar page={page} filterType={page.CATEGORY_SERIES} />
				</div>
				<FilterEventTypeBar page={page} items={allEpisodes} />
			</div>
			{
				page.applyTabFilter(allEpisodes, page.filterEventType) ? (
					<div className="seriesComponentListContainer" ref={refListSeriesContainer}>
						{filterSeries()}
					</div>
				) : page?.series ? (
					<NotFound type={NOTFOUND_TYPE.NOHINT} />
				) : (
					<></>
				)
			}
			{/*
			{page?.series && !page?.series?.seasons?.length > 0 ?
				<NotFound />
				: <></>
			}
			*/}
		</div>
	)
})
