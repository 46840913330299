// l10n status: done
import { Link, Typography } from "@mui/material"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import { CreateComponent } from "components/utils/CreateComponent"
import { RouteLink } from "components/utils/RouteUtils"
import React, { useEffect, useRef, useState } from "react"
import { MovieTile } from "scenes/Recordings/components/MovieTile"
import { ROUTE_NAMES } from "site-ql/RouteConst"
import { rootStore } from "store/RootStore"
import { RecordingsEmpty } from "./components/RecordingsEmpty"
import { RecordingsSpaceInfo } from "./components/RecordingsSpaceInfo"
import { ErrorMessage } from "components/ErrorMessage"

import "./recordings.css"
// importing styles from a css file requires a filename with 'ComponentName.module.css'
import styles from "./Recordings.module.css"
import { l10n } from "store/lang/L10n"

// NEVER - USED
// import "./slick.css"
// import "./slick.recordings.css"

const myStyles = theme => ({
	recordingsArrowIconLink: {
		height: 80,
		width: 80,
	}
})

const RecordingsSection = CreateComponent(myStyles, false, true, ({ page, classes, section }) => {
	const gridSectionRef = useRef()
	const [cardLink, setCardLink] = useState(false)
	const [actualWindowSize, setActualWindowSize] = useState(window.innerWidth)

	const [maxCardEvent, setMaxCardEvent] = useState(10)

	useEffect(() => {
		// MTVW-124 scroll on top only at first loading
		window.scrollTo(0, 0)
		setTimeout(() => {
			window.addEventListener("resize", () => { setActualWindowSize(gridSectionRef?.current?.clientWidth) })
		}, 1)
	}, [])

	const handleResizeGridRecording = () => {

		// MTVW-206 safetyMargin is safety margin to prevent the page edge to move over the linkSectionBox
		//	
		// for devices width 320px and 360px is 40 for the other 160 is minimun with 115 + margin of linkSectionBox
		const safetyMargin = actualWindowSize <= 360 ? 0 : 160
		setActualWindowSize(gridSectionRef.current.clientWidth)
		/** TODO create constant
		 * 
		 * 210 is the with of one card recording  look <MovieTile>
		 * **/
		setMaxCardEvent(parseInt((actualWindowSize - safetyMargin) / 200))

		let totCardToLoad = page[section?.sourceName]?.DataArr?.length

		if (maxCardEvent >= totCardToLoad) {
			setCardLink(false)
		} else {
			setCardLink(true)

		}
	}
	useEffect(() => {
		/**
		 * Check the available space and the number of recordings to load
		 * if there are more records to load,  one less is loaded and  is added a div to go to the section
		 */
		if (gridSectionRef.current) {
			handleResizeGridRecording()
		}
		return (() => {
			window.removeEventListener("resize", () => { setActualWindowSize(gridSectionRef?.current.clientWidth) })
		})
	})
	return (
		<div id="gridSectionRef" ref={gridSectionRef} className="gridSectionRef" >

			<div className="recordingsGridList" >
				<RouteLink Component={Link} name={section.route} params={section.routeProps} data-test={section.dataTest} key={section.key}>
					<Typography variant="h6" className="recordingsType">
						{section.name}
						{/*new Date().toISOString()*/}
						<KeyboardArrowRightIcon className="recordingsArrowIcon" />
					</Typography>
				</RouteLink>

				<div className="recordingsSectionContainer" data-test={`section_${section.dataTest}_grid_preview`}>
					{page[section.sourceName].DataArr.map((item, index) => {
						if (maxCardEvent >= index) {
							if ((maxCardEvent === index) && cardLink) {
								return (
									<RouteLink className="linkSectionBox" Component={Link} name={section.route} params={section.routeProps} data-test={`link_to_${section.dataTest}`} key={section.key}>
										<div>
											<KeyboardArrowRightIcon classes={{ root: classes.recordingsArrowIconLink }} />
											<div>{l10n.featureRecordingsSectionPrefixAll} {section.name}</div>
										</div>
									</RouteLink>
								)
							}
							return (<MovieTile movie={item} key={item.id} routeProps={section.routeProps} />)
						}
					})}

				</div>
			</div>
		</div>
	)
})

const RecordingsSections = CreateComponent(null, false, true, ({ page }) => {
	// MTV-3583: Add keys to remove warnings
	const tRecordingSections = [
		{
			name: l10n.featureRecordingsSingleRecordingsSectionTitle,
			sourceName: "oRecSingle",
			route: ROUTE_NAMES.RecordingsList,
			routeProps: { type: "single" },
			dataTest: "episodes",
			key: "episodes"
		},
		{
			name: l10n.featureRecordingsRecordingsSeriesSectionTitle,
			sourceName: "oRecSeries",
			route: ROUTE_NAMES.RecordingsList,
			routeProps: { type: "series" },
			dataTest: "series",
			key: "series"
		},
		{
			name: l10n.featureRecordingsScheduledRecordingsSectionTitle,
			sourceName: "oRecPlanned",
			route: ROUTE_NAMES.RecordingsList,
			routeProps: { type: "planned" },
			dataTest: "planned",
			key: "planned"
		}
	]

	if (!tRecordingSections.reduce((prev, i) => prev + (page[i?.sourceName]?.isReady ? page[i?.sourceName].DataArr?.length : 1), 0)) {
		return <RecordingsEmpty />
	}
	return (
		<>
			{tRecordingSections
				.filter(i => !page[i?.sourceName]?.isReady || page[i?.sourceName].DataArr?.length)
				.map(section => (
					<RecordingsSection key={section.sourceName} section={section} page={page} />
				))}
			<RecordingsSpaceInfo />
		</>
	)
})

/* CHECK_MERGE: overflow on tablet? */
const RecordingsBody = CreateComponent(null, false, true, ({ classes, page }) => (
	<>
		{/* MTVW-440 */}
		<ErrorMessage error={rootStore.page.Player.error} isPlayerError={true} classes={{ ErrorInfoWrapper: styles.RecordingsErrorInfoWrapper }} page={page} id="player" />
		<div className={"recordings"} data-test="recordings_main_section">
			<div className="recordingsWrapper">
				<RecordingsSections page={page} />
			</div>
		</div>
	</>
))

export const Recordings = CreateComponent(null, false, true, () => {
	const page = rootStore.page.Recordings.IndexPage

	useEffect(...page.getMountBody())
	return <RecordingsBody page={page} />
})
