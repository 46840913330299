import { flow /*, observable, computed, action */ } from "mobx"
import { TraxisCpeIdMixin } from "store/api/mixin/TraxisCpeIdMixin"
// MTVW-585: no retries
import { fetchWithRetry, NO_RETRIES, DEFAULT_TIMEOUT } from "./FetchWithRetry"
import { serviceUrls } from "store/qlapi/ServiceUrls"
//import { list, object, serializable, deserialize } from "serializr"
import moment from "moment"
import { getStage } from "utils/TestSupport"
import { getQlHeaders } from "store/qlapi/QlHeaders"

const logMessage = {
	type: "log",
	timeStamp: null,               // Current time in milliseconds, since 1 Jan 1970 UTC.
	logEvent: null,                // The actual log message.
	logLevel: null,                // [VERBOSE, DEBUG, INFO, WARN, ERROR]
	logNumber: 0,                  /* Consecutive number to identify the order of the logs.
	                                 The number shall be reset to 1 when the app is restarted. */
	meta: null,                    /* OPTIONAL. The app can send additional information via the meta field.
	                                  The content shall be JSON formatted. */
	tag: "",                       // OPTIONAL: Usually the name of the class which logged the message.
	//macAddress: null,            // OPTIONAL: Only for STB
	releaseTrack: null,            // OPTIONAL: Required for STB. ['INTERNAL', "ALPHA", "BETA", "PRODUCTION"]
	//networkType: null,           // OPTIONAL: Only for STB. [UNKNOWN, ETHERNET, WIFI, CELLULAR]
	//steadyClockTimestamp: null   // OPTIONAL: Only for STB
}

const analyticsMessage = {
	type: "analytics",
	timeStamp: null,               // Current time in milliseconds, since 1 Jan 1970 UTC.
	name: null,                    // The name of the analytics event. Currently the application can define the name of the event.
	//duration: null,              // OPTIONAL: Duration in milliseconds. This field is used if the performance of an action is measured.
	//meta: null,                  // OPTIONAL. The app can send additional information via the meta field. The content shall be JSON formatted.
	//macAddress: null,            // OPTIONAL: Only for STB
	releaseTrack: null,            // OPTIONAL: Required for STB. ['INTERNAL', "ALPHA", "BETA", "PRODUCTION"]
	//networkType: null,           // OPTIONAL: Only for STB. [UNKNOWN, ETHERNET, WIFI, CELLULAR]
	//steadyClockTimestamp: null   // OPTIONAL: Only for STB
}

const CREDENTIALS = 'include'
const STD_HEADERS = {
	'Accept': 'application/json',
	// Would cause CORS in keepAlive
	//'pragma': 'no-cache', 'cache-control': 'no-cache'
}

export const logLevel = {
	VERBOSE: "VERBOSE",
	DEBUG: "DEBUG",
	INFO: "INFO",
	WARN: "WARN",
	ERROR: "ERROR"
}
export class LogService extends TraxisCpeIdMixin {

	get xHeaders() {
		// make a copy of STD_HEADERS, otherwise STD_HEADERS would be changed!
		const headers = {}
		Object.assign(headers, STD_HEADERS)
		Object.assign(headers, getQlHeaders())
		return headers
	}


	version = flow(function* (retryCount = NO_RETRIES) {
		// TODO: remove once everywhere deployed
		//if (!serviceUrls.isLogMsAvailable) return null
		const config = {
			url: serviceUrls.logUrl + "version",
			fetchOptions: {
				method: 'GET',
				headers: this.xHeaders,
				credentials: CREDENTIALS,
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["LogService version"],
			service: "LogService",
			createException: true,
			displayError: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		return `${resultJson["build_tag"]} (${resultJson["build_date"]})`
	})

	versionUrl() {
		return [serviceUrls.logUrl + "version", this.xHeaders]
	}

	// TODO: collect in queue
	logAsync = flow(function* (logLevel, logEvent, tag = "", meta = null, retryCount = NO_RETRIES) {
		// TODO: remove once everywhere deployed
		//if (!serviceUrls.isLogMsAvailable) return null
		logMessage.logNumber++
		// make a copy of STD_HEADERS, otherwise STD_HEADERS would be changed!
		const message = {}
		Object.assign(message, logMessage)
		message.type = "log"
		message.timeStamp = moment().utc().valueOf()
		message.logEvent = logEvent
		message.logLevel = logLevel
		message.meta = meta
		message.tag = tag
		message.releaseTrack = getStage(true)
		const messages = []
		messages.push(message)

		//console.debug("LOG MESSAGES %o", messages)
		const deviceId = this._getCpeId ? this._getCpeId : ''

		const config = {
			url: serviceUrls.logUrl + `messages?deviceId=${deviceId}`,
			fetchOptions: {
				method: 'POST',
				headers: this.xHeaders,
				credentials: CREDENTIALS,
				body: JSON.stringify(messages),
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["LogService logAsync"],
			service: "LogService",
			createException: true,
			displayError: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		//console.debug("logAsync resultJson %o", resultJson)
	})

	analyticsAsync = flow(function* (name, duration, meta = null, retryCount = NO_RETRIES) {
		// TODO: remove once everywhere deployed
		//if (!serviceUrls.isLogMsAvailable) return null
		// make a copy of STD_HEADERS, otherwise STD_HEADERS would be changed!
		const message = {}
		Object.assign(message, analyticsMessage)
		message.type = "analytics"
		message.timeStamp = moment().utc().valueOf()
		message.name = name
		if (duration) message.duration = duration
		if (meta) message.meta = meta
		message.releaseTrack = getStage(true)
		const messages = []
		messages.push(message)

		//console.debug("ANALYTICS MESSAGES %o", messages)
		const deviceId = this._getCpeId ? this._getCpeId : ''

		const config = {
			url: serviceUrls.logUrl + `messages?deviceId=${deviceId}`,
			fetchOptions: {
				method: 'POST',
				headers: this.xHeaders,
				credentials: CREDENTIALS,
				body: JSON.stringify(messages),
			},
			retryCount: retryCount,
			timeout: DEFAULT_TIMEOUT,
			codes: ["LogService analyticsAsync"],
			service: "LogService",
			createException: true,
			displayError: true
		}

		// eslint-disable-next-line no-unused-vars
		const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
		//console.debug("analyticsAsync resultJson %o", resultJson)
	})
}
