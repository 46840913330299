// l10n status: partial
import { action, computed, flow, observable, makeObservable } from "mobx"
import { ClassAbstract } from "store/ClassTools"
import { ErrorBase } from "store/ErrorBase"
//import { PATH_GET_PROFILE, PATH_PROFILE_UPDATE, PATH_GET_TOKEN, SSO_AUTH_TYPES } from "store/model/sso/SsoConst"
import { SSO_AUTH_TYPES } from "store/model/sso/SsoConst"
//import { SsoToken } from "store/model/sso/SsoToken"
import { applyDataToObject/*, makeObject*/ } from "store/ModelTools"
import { logSsoError } from "utils/BugsnagReporter"
import { rootStore } from "store/RootStore"
//import { Profile } from "store/qlapi/LoginService"
import { timeNow } from "utils/Utils"
import { l10n } from "store/lang/L10n"

export class SsoProfile extends ClassAbstract {
	// LoginService Profile
	ProfileIdentificationGuid = null
	Nickname = null
	EMail = null
	MobileNumber = null
	Color = null
	IsMainProfile = null
	IsChildProfile = null
	FirstInstallationDone = null
	LoginPinProtected = null
	/** @type {import("store/model/sso/SsoConst").SSO_AUTH_TYPES_JSDOC|null} */
	_PurchaseAuthentication = null
	/** @type {import("store/model/sso/SsoConst").SSO_AUTH_TYPES_JSDOC|null} */
	_ManagementAuthentication = null
	/** @type {import("store/model/sso/SsoConst").SSO_AUTH_TYPES_JSDOC|null} */
	_AdultAuthentication = null
	AdultContentAccessShown = null
	NonSubscribedChannelsShown = null
	// MTVW-627: not used anymore, can be removed
	PersonalizedRecommendationsEnabled = null

	// Check whether below properties are still needed

	/** @type {import("store/model/sso/SsoConst").SSO_AUTH_TYPES_JSDOC|null} */
	ProfileAuthentication = null
	loginResult = null

	// optional from detail part
	/** @type {string|null} */
	SubscriberIdentificationGuid = null

	// Tokens
	tokenProfile = null
	tokenManagement = null
	tokenPurchase = null
	tokenAdult = null

	// localTypes
	error = null
	profileTokenTimerId = null

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			// LoginService Profile
			ProfileIdentificationGuid: observable,
			Nickname: observable,
			setNickname: action,
			EMail: observable,
			MobileNumber: observable,
			Color: observable,
			IsMainProfile: observable,
			IsChildProfile: observable,
			FirstInstallationDone: observable,
			LoginPinProtected: observable,
			setLoginPinProtected: action,
			_PurchaseAuthentication: observable,
			PurchaseAuthentication: computed,
			setPurchaseAuthentication: action,
			_AdultAuthentication: observable,
			AdultAuthentication: computed,
			setAdultAuthentication: action,
			_ManagementAuthentication: observable,
			AdultContentAccessShown: observable,
			NonSubscribedChannelsShown: observable,
			// MTVW-627: not used anymore, can be removed
			PersonalizedRecommendationsEnabled: observable,
			// MTVW-627: not used anymore, can be removed
			setPersonalizedRecommendationsEnabled: action,
			clearRecommendations: action,

			ProfileAuthentication: observable,
			SubscriberIdentificationGuid: observable,
			tokenProfile: observable,
			tokenManagement: observable,
			tokenPurchase: observable,
			tokenAdult: observable,
			error: observable.shallow,
			id: computed,
			isFullProfile: computed,
			isProfileAuthPinRequired: computed,
			isAuthProfile: computed,
			isProfilePinRequired: computed,
			isAuthPurchase: computed,
			isAuthAdult: computed,
			profilesOther: computed,
			removeTokens: action,
			_handleError: action
		})
	}

	validateProfileName(name, currentProfile) {
		// MTVW-276: Increase max. length from 20 to 30
		const MIN_LENGTH = 3
		const MAX_LENGTH = 30
		//console.debug("validate %s name, prof %o", name, currentProfile)
		// strip off invalid characters and limit string length
		//  MTVW-594 \"@\", \".\", \"_\" und \"-\" -> \"-\"
		//const validStr = name.replace(/[^a-zA-Z0-9-@._-]+/g, "").substring(0, MAX_LENGTH)
		const validStr = name.replace(/[^a-zA-Z0-9--]+/g, "").substring(0, MAX_LENGTH)

		if ((validStr?.length < MIN_LENGTH) || (validStr?.length > MAX_LENGTH)) {
			return [validStr, l10n.featureNewProfileStep1Field1ErrorTooShort]
		}

		if (validStr?.length >= 3) {
			// check for duplicate profile name, currentProfile is null if called from create profile and all profiles have to be checked
			const profiles = rootStore.sso.profileOrder
			for (let i = 0; i < profiles?.length; i++) {
				//if (currentProfile?.ProfileIdentificationGuid !== profiles[i].ProfileIdentificationGuid) console.debug(validStr, profiles[i]?.Nickname, validStr === profiles[i].Nickname)
				if ((currentProfile?.ProfileIdentificationGuid !== profiles[i].ProfileIdentificationGuid) && (validStr === profiles[i]?.Nickname)) {
					return [validStr, l10n.featureNewProfileStep1Field1ErrorDuplicateName]
				}
			}
		}
		return [validStr, ""]
	}

	updateProfileAndReload(detail, pin) {
		// make sure to return a promise for error handling in the caller
		let result = null
		result = this.updateProfileDetailsAsync(detail, pin).then(res => {
			// reload the profile
			// MTVW-21 / QLMS-569: TODO reenable commented code
			/*
			result = this.getProfile().finally((res) => {
				return res
			})
			*/
			// QLMS-569: TODO remove setTimeout
			setTimeout(() => {
				result = this.getProfile().finally((res) => {
					return res
				})
			}, 1000)
		}).finally((res) => {
			return res
		})
		return result
	}

	setNickname(value, pin) {
		// avoid visual delay, immediately change value for good user experience
		this.Nickname = value
		const detail = { name: value }
		// return promise for error handling
		return this.updateProfileAndReload(detail, pin)
	}

	setPin(value, pin) {
		const detail = { pinCode: value }
		return this.updateProfileAndReload(detail, pin)
	}

	tryPinCode = flow(function* (pin_code) {
		// try to set a profile property with it's own value to check whether the entered pin is correct
		//console.debug("tryPinCode")
		// MTVW-627: flag not used anymore, can be removed
		const updateProfileDetails = { personalizedRecommendationsEnabled: rootStore.sso.profile.PersonalizedRecommendationsEnabled.toString() }
		return yield this.updateProfileDetailsAsync(updateProfileDetails, pin_code)
	})

	setLoginPinProtected(value, pin) {
		// avoid visual delay, immediately change value for good user experience
		this.LoginPinProtected = value
		const detail = { isLoginPinProtected: value.toString() }
		return this.updateProfileAndReload(detail, pin)
	}

	get PurchaseAuthentication() {
		return this._PurchaseAuthentication === SSO_AUTH_TYPES.PROFILE_PIN_REQUIRED ? true : false
	}

	setPurchaseAuthentication(value, pin) {
		// avoid visual delay, immediately change value for good user experience
		this._PurchaseAuthentication = value ? SSO_AUTH_TYPES.PROFILE_PIN_REQUIRED : SSO_AUTH_TYPES.NO_PIN_REQUIRED
		const detail = { purchaseAuthentication: this._PurchaseAuthentication }
		return this.updateProfileAndReload(detail, pin)
	}

	get AdultAuthentication() {
		return this._AdultAuthentication === SSO_AUTH_TYPES.PROFILE_PIN_REQUIRED ? true : false
	}

	setAdultAuthentication(value, pin) {
		// avoid visual delay, immediately change value for good user experience
		this._AdultAuthentication = value ? SSO_AUTH_TYPES.PROFILE_PIN_REQUIRED : SSO_AUTH_TYPES.NO_PIN_REQUIRED
		const detail = { adultAuthentication: this._AdultAuthentication }
		return this.updateProfileAndReload(detail, pin)
	}

	// MTVW-627: not used anymore, can be removed
	setPersonalizedRecommendationsEnabled(value, pin) {
		// avoid visual delay, immediately change value for good user experience
		this.PersonalizedRecommendationsEnabled = value
		const detail = { personalizedRecommendationsEnabled: value.toString() }
		return this.updateProfileAndReload(detail, pin)
	}

	// MTVW-627: not used anymore, can be removed
	clearRecommendations(pin) {
		// avoid visual delay, immediately change value for good user experience
		this.PersonalizedRecommendationsEnabled = true
		try {
			// recommendations are cleared by setting "personalizedRecommendationsEnabled" false and then true
			let detail = { personalizedRecommendationsEnabled: "false" }
			this.updateProfileDetailsAsync(detail, pin).then(res => {
				detail = { personalizedRecommendationsEnabled: "true" }
				this.updateProfileDetailsAsync(detail, pin).then(res => {
					//reload the profile
					this.getProfile()
				})
			})
		}
		catch (e) {
			// TODO?: We cannot do much in case of an error
			console.error("CAUGHT in clearRecommendations: %o", e)
		}
	}

	get id() {
		return this.ProfileIdentificationGuid
	}

	get isFullProfile() {
		return !!this.SubscriberIdentificationGuid
	}

	get isProfileAuthPinRequired() {
		return this.LoginPinProtected
		//return this.ProfileAuthentication !== SSO_AUTH_TYPES.NOT_ALLOWED && this.ProfileAuthentication !== SSO_AUTH_TYPES.NO_PIN_REQUIRED
	}

	get isAuthProfile() {
		//console.debug("get isAuthProfile %s, %s, %o", this.ProfileAuthentication, this.tokenProfile, this)
		return this.ProfileAuthentication !== SSO_AUTH_TYPES.NOT_ALLOWED && this.tokenProfile ? true : false
	}

	get isProfilePinRequired() {
		return this._ManagementAuthentication === SSO_AUTH_TYPES.PROFILE_PIN_REQUIRED
	}

	get isAuthPurchase() {
		return this.PurchaseAuthentication !== SSO_AUTH_TYPES.NOT_ALLOWED && this.tokenPurchase
	}

	get isAuthAdult() {
		return this.AdultAuthentication !== SSO_AUTH_TYPES.NOT_ALLOWED && this.tokenAdult
	}

	get profilesOther() {
		return this.sso.profileStore.filter(i => i.id !== this.id)
	}

	get sso() {
		return this._root.sso
	}

	/**
	 * @returns {WaitQueue}
	 */
	get waitForAuth() {
		return this._root.waitForAuth
	}

	get isSelected() {
		return this._parent.profile?.id === this.id
	}

	get managementToken() {
		return this.tokenManagement.access_token
	}

	handleAuthProfileAsync = flow(function* (pin_code = "") {
		console.info("@@handleAuthProfileAsync(pin_code=%o) -> ", pin_code)
		// remember pin in local storage
		rootStore.sso.setPin(pin_code)
		this.error = null

		// CAUTION: error handling is done in Login component
		//try {
		const loginResult = yield rootStore.loginService.loginAsync(this.id, pin_code)
		applyDataToObject(this, loginResult.profile)
		this.loginResult = loginResult
		console.debug("loginResult %o, url %s, this %o", loginResult, window.location.href, this)
		/*
		access_token: "0067b91e-0c95-6d89-d48c-1cdec530be1f"
		expires_in: 60
		parameters:
		cacheexpiration: 30
		customer_at: "Cable"
		onetime: false
		partner_id: "29"
		profileid: "a4e37055-90ec-4ed8-b21d-1f9292d72a26"
		refresh_token: "efb29a70-dd66-7104-398e-e9be5be9fab7"
		token_type: "profile"
		*/
		// MTVW-157: TODO check access_token
		this.applyProfileToken(loginResult.ProfileToken)
		//}
		//catch (e) {
		//	rootStore.sso.handleLogOffAsync()
		//}

		//console.debug("refreshToken = %o", yield rootStore.loginService.refreshTokens().ProfileToken)

		// return as quick as possible, just trigger fetch of main user contact info so that it is available
		// for bugsnag reports and browserStore
		setTimeout(() => {
			rootStore.sso.mainUserContactInfo().then(() => {
				//console.debug("sso is %o", rootStore.sso)
				// MTV-3431: Change for supporting account switches without new device registration.
				// Account data is now available, update cpedId
				this.sso.browserStore.applyAccountCpeId()
				// GT12: prefetch recordings markers (for the case when the app is reloaded from the recordings tab)
				this._root.api.GetRecordingsMarkers().fetchDataAsync()
				// GT12: prefetch channels (for the case when the app is reloaded from search)
				rootStore.page.MsEpg.getChannelsAsync()
			})
		}, 100)
		//console.debug("handleAuthProfileAsync url %s", window.location.href)
	})

	applyProfileToken = (profileToken) => {
		/*
		console.debug("tokenProfile %o %o", this, this.tokenProfile)
		makeObject(this, "tokenProfile", { access_token: profileToken, token_type: "profile" }, SsoToken)
		this.tokenProfile.isValid = true
		console.debug("tokenProfile %o %o", this, this.tokenProfile)
		*/
		console.debug("applyProfileToken", profileToken)
		this.tokenProfile = profileToken
		rootStore.sso.browserStore.tokenProfile = profileToken
		this.waitForAuth.setResolve()
		// MTVW-633: periodic instead of timeout
		if (this.profileTokenTimerId !== null) clearInterval(this.profileTokenTimerId)
		// schedule token refresh
		this.profileTokenTimerId = setInterval(() => {
			this.refreshTokens()
		}, rootStore.loginService.profileTokenRefreshInterval * 1000)
	}

	refreshTokens = flow(function* () {
		try {
			if (this.waitForAuth.isStarted === false) {
				// avoid concurrent token refreshes
				this.waitForAuth.setRestart()
				const result = yield rootStore.loginService.refreshTokens()
				this.applyProfileToken(result.ProfileToken)
				console.debug("%s, token refreshed %o %o %o", timeNow(), result, this, this.tokenProfile)
			}
			else {
				//console.debug("invoking waitAsync")
				yield this.waitForAuth.waitAsync()
				//console.debug("waitAsync done")
			}
		}
		catch {
			this.waitForAuth.setResolve()
			// force re-authentication
			rootStore.sso.handleLogOffAsync()
		}
	})

	getProfile = flow(function* () {
		const profile = yield rootStore.loginService.getProfileAsync(this.id)
		applyDataToObject(this, profile)
		//console.debug("updated profile %o", this)
		// apply token to profile
		//this.refreshTokens()
	})

	updateProfileDetailsAsync = flow(function* (profileDetails, pin_code = "") {
		//console.debug("@@updateProfileDetailsAsync(profileDetails=%o, pin_code=%s) ->")
		yield rootStore.loginService.updateProfileAsync(this.ProfileIdentificationGuid, profileDetails, pin_code)
	})

	createProfileAsync = flow(function* (profileDetails, pin_code = "") {
		//console.debug("@@createProfileAsync(profileDetails=%o, pin_code=%s) ->")
		// MTVW-609: avoid backend errors with missing header properties
		this.waitForAuth.setRestart()

		const profileId = yield rootStore.loginService.createProfileAsync(profileDetails, pin_code)
		// TODO: fetch profiles!
		yield rootStore.sso.getLoginProfiles()
		//console.debug("new profile id %s", profileId)
		// MTVW-405: open new profile
		rootStore.sso.handleChangeProfile(profileId, false)

		// MTVW-609: avoid backend errors with missing header properties
		yield this.waitForAuth.waitAsync()
		return profileId
	})

	// pofileId: Must be the same profileId as the id of the profile into which the user logged-in.
	deleteProfileAsync = flow(function* (profileId, pin_code = "") {
		//console.debug("@@deleteProfileAsync(profileId=%s, pin_code=%s) ->")
		// MTVW-609: avoid backend errors with missing header properties
		this.waitForAuth.setRestart()
		// set token profile for all profiles
		rootStore.sso.profileOrder.forEach(i => {
			i.tokenProfile = this.tokenProfile
		})
		rootStore.sso.profileStore.forEach(i => {
			i.tokenProfile = this.tokenProfile
		})
		yield rootStore.loginService.deleteProfileAsync(profileId, pin_code)
		// select live TV and invalidate current profile in order to show profile list
		rootStore.sso.fixUrl()
		// invalidate current profile in order to show profile list
		rootStore.sso.browserStore.currentProfileId = null
		// refresh profile list
		yield rootStore.sso.getLoginProfiles()

		// MTVW-609: avoid backend errors with missing header properties
		yield this.waitForAuth.waitAsync()
	})

	removeTokens() {
		console.info("@()")
		this.tokenAdult = null
		this.tokenManagement = null
		this.tokenProfile = null
		this.tokenPurchase = null
		return this
	}

	_handleError(e) {
		console.info("@(e=%o)", e)
		if (e instanceof ErrorBase) {
			this.error = e
		}
		logSsoError(e, "SsoProfile._handleError")
		// TODO: recheck, throw could cause an endless restart of the app.
		// reproducible when generating an error by calling 'applyAccountCpeId()()'
		throw e
	}
}
