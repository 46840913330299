import md5 from "md5"
import { AddCpeId } from "store/api/AddCpeId"
import { CreateBookmark } from "store/api/CreateBookmark"
/* MTVW-577: UNUSED OLD TRAXIS IMPLEMENTATION
import { CreateChannelPlaylist } from "store/api/CreateChannelPlaylist"
import { CreateContentPlaylist } from "store/api/CreateContentPlaylist"
import { CreateRollingBufferPlaylist } from "store/api/CreateRollingBufferPlaylist"
*/
import { GetCategorySoftlinks } from "store/api/GetCategorySoftlinks"
import { GetChannelLists } from "store/api/GetChannelLists"
import { GetChannels } from "store/api/GetChannels"
import { GetCustomer } from "store/api/GetCustomer"
import { UpdateCustomerProperty } from "store/api/UpdateCustomerProperty"
import { ClearRecordingOptInDate } from "store/api/ClearRecordingOptInDate"
import { GetEventByChannelTime, GetEventById } from "store/api/GetEvent"
import { GetProducts } from "store/api/GetProducts"
import { GetProfile } from "store/api/GetProfile"
import { UpdateProfileProperty } from "store/api/UpdateProfileProperty"
import { AddNamedProperty } from "store/api/AddNamedProperty"
import { AddChannelsToRecordingOptInChannelList } from "store/api/AddChannelsToRecordingOptInChannelList"
import { DeleteChannelsFromRecordingOptInChannelList } from "store/api/DeleteChannelsFromRecordingOptInChannelList"
import { GetRecordings } from "store/api/GetRecordings"
import { GetRecordingsMarkers } from "store/api/GetRecordingsMarkers"
import { ManageRecordings } from "store/api/ManageRecordings"
import { CreateChannelList } from "store/api/CreateChannelList"
import { CreatePrivateChannelList } from "store/api/CreatePrivateChannelList"
import { CreateChannelInChannelList } from "store/api/CreateChannelInChannelList"
import { DeleteChannelList } from "store/api/DeleteChannelList"
import { DeletePrivateChannelList } from "store/api/DeletePrivateChannelList"
import { DeleteChannelFromChannelList } from "store/api/DeleteChannelFromChannelList"
import { UpdateChannelList } from "store/api/UpdateChannelList"
import { TraxisCacheStore } from "store/api/TraxisCache"
import { ClassAbstract } from "store/ClassTools"
import { rootStore } from "store/RootStore"

export class TraxisStore extends ClassAbstract {
	_cache = null
	_mapTypes = new Set()

	_mapStore = new Map()

	constructor(parent, path) {
		super(parent, path)
		this._cache = TraxisCacheStore
		//console.debug("TraxisStore constructor")
	}

	clearInitializeDataSource() {
		this._mapStore.forEach(data => {
			data.abortReq()
			//data.isReady = false
			data.Data = null
		})
	}

	_getProfile(profileId = null) {
		if (profileId === false) {
			return false
		}
		if (!rootStore.sso) {
			return null
		}
		return profileId === null || profileId === true ? rootStore.sso.profile : rootStore.sso.getProfile(profileId)
	}

	_getProfileId(profileId = null) {
		return this._getProfile(profileId)?.id ?? null
	}

	_getTokenId(profileId = null, tokenType = "tokenProfile") {
		// MTVW-157: TODO is it always 'tokenProfile'?
		//console.debug("type %s, _getProfile %o, token %o", tokenType, this._getProfile(profileId), this._getProfile(profileId)?.[tokenType])
		//return this._getProfile(profileId)?.[tokenType]?.accessToken ?? null
		return this._getProfile(profileId)?.tokenProfile ?? null
	}

	_getItem(objectName, objectDefinition, oVars, oCache) {
		try {
			// defaults
			oVars = {
				...{
					_idCache: null,
					_objectName: objectName,
					_profileId: true,
					_seacToken: true,
					_tags: [],
					_type: "select"
				},
				...oVars
			}
			oCache = {
				...{
					cache: oCache !== false,
					store: oCache !== false,
					stale: oCache !== false,
					showStale: oCache !== false,
					softProfile: false
				},
				...oCache
			}

			oVars._idCache = oVars._objectName + ":" + (oVars._idCache === null ? md5(JSON.stringify(oVars)).toString() : oVars._idCache)

			console.info("@(objectName=%o, objectDefinition=%o, oVars=%o, oCache=%o) isExisting", objectName, [objectDefinition], oVars, oCache, this._mapStore.has(oVars._idCache))

			if (!this._mapStore.has(oVars._idCache)) {
				this._mapStore.set(oVars._idCache, new objectDefinition(this, oVars._idCache))
				this._mapTypes.add(oVars._objectName)
			}
			return this._mapStore.get(oVars._idCache)._setVars(oVars, oCache, this._cache)
		} catch (e) {
			console.error(e)
		}
	}

	/**
	 * @returns {CreateChannelPlaylist}
	 */
	/* MTVW-577: UNUSED OLD TRAXIS IMPLEMENTATION
	CreateChannelPlaylist(oVars = {}, oCache = null) {
		return this._getItem("CreateChannelPlaylist", CreateChannelPlaylist, oVars, { ...{ softProfile: false, cache: false }, ...oCache })
	}
	*/

	/**
	 * @returns {CreateRollingBufferPlaylist}
	 */
	/* MTVW-577: UNUSED OLD TRAXIS IMPLEMENTATION
	CreateRollingBufferPlaylist(oVars = {}, oCache = null) {
		return this._getItem("CreateRollingBufferPlaylist", CreateRollingBufferPlaylist, oVars, { ...{ softProfile: false, cache: false }, ...oCache })
	}
	*/

	/**
	 * @returns {CreateRollingBufferPlaylist}
	 */
	/* MTVW-577: UNUSED OLD TRAXIS IMPLEMENTATION
	CreateContentPlaylist(oVars = {}, oCache = null) {
		return this._getItem("CreateContentPlaylist", CreateContentPlaylist, oVars, { ...{ softProfile: false, cache: false }, ...oCache })
	}
	*/

	/**
	 * @returns {GetChannels}
	 */
	GetChannels(oVars = {}, oCache = null) {
		return this._getItem("GetChannels", GetChannels, oVars, { ...{ softProfile: true, cache: false }, ...oCache })
	}

	/**
	 * @returns {GetProducts}
	 */
	GetProducts(oVars = {}, oCache = null) {
		return this._getItem("GetProducts", GetProducts, oVars, { ...{ softProfile: false }, ...oCache })
	}

	/**
	 * @returns {GetChannelLists}
	 */
	GetChannelLists(oVars = {}, oCache = null) {
		return this._getItem("GetChannelLists", GetChannelLists, oVars, { ...{ softProfile: true, cache: false }, ...oCache })
	}

	/**
	 * @returns {CreateChannelList}
	 */
	CreateChannelList(oVars = {}, oCache = null) {
		return this._getItem("CreateChannelList", CreateChannelList, oVars, { ...{ softProfile: true, channelListName: null }, ...oCache })
	}

	/**
	 * @returns {CreatePrivateChannelList}
	 */
	CreatePrivateChannelList(oVars = {}, oCache = null) {
		return this._getItem("CreatePrivateChannelList", CreatePrivateChannelList, oVars, { ...{ softProfile: true, channelListId: null }, ...oCache })
	}

	/**
	 * @returns {CreateChannelInChannelList}
	 */
	CreateChannelInChannelList(oVars = {}, oCache = null) {
		return this._getItem("CreateChannelInChannelList", CreateChannelInChannelList, oVars, { ...{ softProfile: true, channelId: null, channelListId: null, personalChannelNumber: null }, ...oCache })
	}

	/**
	 * @returns {DeleteChannelList}
	 */
	DeleteChannelList(oVars = {}, oCache = null) {
		return this._getItem("DeleteChannelList", DeleteChannelList, oVars, { ...{ softProfile: true, channelListId: null }, ...oCache })
	}

	/**
	 * @returns {DeletePrivateChannelList}
	 */
	DeletePrivateChannelList(oVars = {}, oCache = null) {
		return this._getItem("DeletePrivateChannelList", DeletePrivateChannelList, oVars, { ...{ softProfile: true, channelListId: null }, ...oCache })
	}

	/**
	 * @returns {DeleteChannelFromChannelList}
	 */
	DeleteChannelFromChannelList(oVars = {}, oCache = null) {
		return this._getItem("DeleteChannelFromChannelList", DeleteChannelFromChannelList, oVars, { ...{ softProfile: true, channelId: null, channelListId: null }, ...oCache })
	}

	/**
	 * @returns {UpdateChannelList}
	 */
	UpdateChannelList(oVars = {}, oCache = null) {
		return this._getItem("UpdateChannelList", UpdateChannelList, oVars, { ...{ softProfile: true, channelListId: null, channelListName: null, ordinal: null, channelList: null }, ...oCache })
	}

	/**
	 * @returns {GetCustomer}
	 */
	GetCustomer(oVars = {}, oCache = null) {
		return this._getItem("GetCustomer", GetCustomer, oVars, { ...{ softProfile: false, cache: false, showStale: true }, ...oCache })
	}

	/**
	 * @returns {UpdateCustomerProperty}
	 */
	UpdateCustomerProperty(oVars = {}, oCache = null) {
		return this._getItem("UpdateCustomerProperty", UpdateCustomerProperty, oVars, { ...{ softProfile: false, showStale: true, property: null, newValue: null }, ...oCache })
	}

	/**
	 * @returns {ClearRecordingOptInDate}
	 */
	ClearRecordingOptInDate(oVars = {}, oCache = null) {
		return this._getItem("ClearRecordingOptInDate", ClearRecordingOptInDate, oVars, { ...{ softProfile: false, showStale: true, customerId: null, resourceId: null }, ...oCache })
	}

	/**
	 * @returns {GetRecordingsMarkers}
	 */
	GetRecordingsMarkers(oVars = {}, oCache = null) {
		return this._getItem("GetRecordingsMarkers", GetRecordingsMarkers, { ...{ time: null }, ...oVars }, { ...{ softProfile: false, showStale: true }, ...oCache })
	}

	/**
	 * @returns {GetRecordingsMarkers}
	 */
	GetEventById(oVars = {}, oCache = null) {
		return this._getItem("GetEventById", GetEventById, { ...{ EventId: null }, ...oVars }, { ...{ softProfile: true, showStale: false, cache: false }, ...oCache })
	}

	/**
	 * @returns {GetRecordingsMarkers}
	 */
	GetEventByChannelTime(oVars = {}, oCache = null) {
		return this._getItem("GetEventByChannelTime", GetEventByChannelTime, { ...{ ChannelId: null, time: null }, ...oVars }, { ...{ softProfile: true, showStale: false }, ...oCache })
	}

	GetCategorySoftlinks(oVars = {}, oCache = null) {
		return this._getItem("GetCategorySoftlinks", GetCategorySoftlinks, { ...{ _profileId: false, _seacToken: false, cache: false }, ...oVars }, oCache)
	}

	/**
	 * @returns {GetProfile}
	 */
	GetProfile(oVars = {}, oCache = null) {
		return this._getItem("GetProfile", GetProfile, oVars, { ...{ softProfile: false, cache: false }, ...oCache })
	}

	/**
	 * @returns {UpdateProfileProperty}
	 */
	UpdateProfileProperty(oVars = {}, oCache = null) {
		return this._getItem("UpdateProfileProperty", UpdateProfileProperty, oVars, { ...{ softProfile: false, showStale: true, tokendId: null, property: null, newValue: null }, ...oCache })
	}

	/**
	  * @returns {AddNamedProperty}
	  */
	AddNamedProperty(oVars = {}, oCache = null) {
		return this._getItem("AddNamedProperty", AddNamedProperty, oVars, { ...{ softProfile: false, showStale: true, tokendId: null, property: null, newValue: null }, ...oCache })
	}

	/**
	 * @returns {AddChannelsToRecordingOptInChannelList}
	 */
	AddChannelsToRecordingOptInChannelList(oVars = {}, oCache = null) {
		return this._getItem("AddChannelsToRecordingOptInChannelList", AddChannelsToRecordingOptInChannelList, oVars, { ...{ softProfile: false, showStale: true /*,tokendId: nul,*/ }, ...oCache })
	}

	/**
	 * @returns {DeleteChannelsFromRecordingOptInChannelList}
	 */
	DeleteChannelsFromRecordingOptInChannelList(oVars = {}, oCache = null) {
		return this._getItem("DeleteChannelsFromRecordingOptInChannelList", DeleteChannelsFromRecordingOptInChannelList, oVars, {
			...{ softProfile: false, showStale: true /*tokendId: null,*/ },
			...oCache
		})
	}

	/**
	 * @returns {GetRecordings}
	 */
	GetRecordings(oVars = {}, oCache = null) {
		return this._getItem(
			"GetRecordings",
			GetRecordings,
			{
				...{
					Ids: null,
					isSingle: null,
					isSeries: null,
					isPlanned: null,
					isRecorded: null,
					isNotEmpty: null,
					isOnlyMine: null,
					SeriesId: null,
					EventId: null,
					genreFilter: null,
					timeFilter: null,
					isAdult: null,
					iOffset: null,
					iLimit: null,
					isSortDesc: null,
					isChildRecordings: null,
					isChildRecordingsCount: null
				},
				...oVars
			},
			{ ...{ softProfile: true, showStale: true }, ...oCache }
		)
	}

	/**
	 * @returns {GetSeriesEvents}
	 */
	//GetSeriesEvents(oVars = {}, oCache = null) {
	//	return this._getItem("GetSeriesEvents", GetSeriesEvents, { ...{ time: null }, ...oVars }, { ...{ softProfile: false, showStale: true }, ...oCache })
	//}

	AddCpeId(oVars = {}, oCache = null) {
		return this._getItem("AddCpeId", AddCpeId, { ...{ ChannelId: null, time: null }, ...oVars }, false)
	}

	ManageRecordings(oVars = {}, oCache = null) {
		return this._getItem("ManageRecordings", ManageRecordings, { ...{ ChannelId: null, time: null }, ...oVars }, false)
	}

	/**
	 * @returns {CreateBookmark}
	 */
	CreateBookmark(oVars = {}, oCache = null) {
		return this._getItem("CreateBookmark", CreateBookmark, { ...{ Type: null, Id: null, durationInSeconds: null }, ...oVars }, false)
	}
}
