/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-09
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-11
 */

import { ManageAbstract, MANAGE_RESOURCE_TYPE } from "store/api/ManageAbstract"
import { TraxisModelAbstract } from "store/api/TraxisModelAbstract"

export const MANAGE_RECORDINGS_ACTIONS = {
	Cancel: "Cancel",
	Record: "Record",
	Resume: "Resume",
	Update: "Update",
	Delete: "Delete",
	//DeleteAllRecordings: "DeleteAllRecordings",
	AddToPersonalRecordings: "AddToPersonalRecordings",
	DeleteFromPersonalRecordings: "DeleteFromPersonalRecordings"
}

class ManageRecordingsModel extends TraxisModelAbstract {}

export class ManageRecordings extends ManageAbstract {
	_onStartCreate = true

	get _model() {
		return ManageRecordingsModel
	}

	_getParseResp(data) {
		return data
	}

	_getXmlActionResourceType() {
		return this._oVars.ActionType ? this._oVars.ActionType : MANAGE_RESOURCE_TYPE.Recording
	}
}

//ActionName, Arguments, Ids ActionType

/*
	// single rec
	store.api.ManageRecordings({
		ActionType: 'Event',
		ActionName: 'Record',
		// event id
		Ids: "crid:~~2F~~2Fmedia-press.tv~~2Fde~~2F136814627,imi:00100000040F1ECC",
		Arguments :{ 
			Location: 'Network',
			GuardTimeStart: 'PT10M',
			GuardTimeEnd:'PT10M'
		}
	}).fetchData()

	// series recording
	<ActionQuery resourceType="Series" resourceId="crid:~~2F~~2Fmedia-press.tv~~2Fseason~~2Fde~~2F2803519_8" actionName="Record">

	store.api.ManageRecordings({
		ActionType: 'Series',
		ActionName: 'Record',
		// series id
		Ids: "crid:~~2F~~2Fmedia-press.tv~~2Fseason~~2Fde~~2F2803519_8",
		Arguments :{ 
			Location: 'Network',
			GuardTimeStart: 'PT10M',
			GuardTimeEnd:'PT10M',
		}
	}).fetchData()



	// cancel (remove)
	store.api.ManageRecordings({
		ActionType: 'Recording',
		ActionName: 'Cancel',
		Ids: "e3f67699-b3e1-467f-9fe5-81c6da42efb9",
	}).fetchData()




	// delete single recording or series
	store.api.ManageRecordings({
		ActionType: 'Recording',
		ActionName: 'Delete',
		Ids: "dd5744a3-ba31-4f04-87b1-5334e6cb2ca2",
	}).fetchData()


*/
