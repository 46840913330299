import { types as t } from "mobx-state-tree"

export const PATH_GET_TOKEN = "Authorisation/GetToken"
export const PATH_MANAGE_TOKEN = "ManageToken/Token"
export const PATH_LOGIN = "Authorize/Index"

export const PATH_PROFILE_CREATE = "UserProfile/Create"
export const PATH_PROFILE_DELETE = "UserProfile/Delete"
export const PATH_PROFILE_UPDATE = "UserProfile/Update"
export const PATH_PROFILE_RESET_PIN = "UserProfile/ResetProfilePin"
export const PATH_GET_PROFILE = "UserProfile/GetProfile"
export const PATH_GET_PROFILES = "Subscriber/GetProfiles"
export const PATH_CUSTOMER_LOCATION = "CustomerLocation/Get"

export const STORE_NAME = "store.sso"

// QLVisionLogin / throb-TBik_gj
export const AUTHORIZATION_BASIC = "Basic UUxWaXNpb25Mb2dpbjp0aHJvYi1UQmlrX2dq" //	window.btoa(`${AUTHORIZATION_USER}:${AUTHORIZATION_PASSWORD}`);

/**
 * @typedef {object} SSO_AUTH_TYPES_JSDOC
 * @property {string} ProfilePinRequired
 * @property {string} NoPinRequired
 * @property {string} NotAllowed
 * @property {string} AdultAuthenticationRequired
 */

/**
 * @typedef {object} LOCATION_JSDOC
 * @property {string} OutOfCountry
 * @property {string} InCountry
 * @property {string} InNetwork
 */

export const SSO_AUTH_TYPES = {
	PROFILE_PIN_REQUIRED: "ProfilePinRequired",
	NO_PIN_REQUIRED: "NoPinRequired",
	NOT_ALLOWED: "NotAllowed",
	ADULT_AUTHENTICATION_REQUIRED: "AdultAuthenticationRequired"
}

/**
 * @typedef {string} SSO_TOKEN_TYPES_JSDOC
 * @enum {SSO_TOKEN_TYPES_JSDOC}
 */
export const SSO_TOKEN_TYPES = {
	SUBSCRIBER: "subscriber",
	PROFILE: "profile",
	PURCHASE: "purchase",
	ADULT: "adult",
	MANAGEMENT: "profile_management"
}

export const PinAuthTypes = t.enumeration("PinType", Object.values(SSO_AUTH_TYPES))
