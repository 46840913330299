/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-10
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-10
 */

import { observable, makeObservable, computed } from "mobx";
import { deepObserve } from "mobx-utils"
import { list, object, getDefaultModelSchema, serializable, serialize, update } from "serializr"
import { STORE_NAME } from "store/model/sso/SsoConst"
//import { SsoToken } from "store/model/sso/SsoToken"
import { rootStore } from "store/RootStore"
import { localStorage } from "store/util/LocalStorage"
import { getCpeId } from "store/api/mixin/TraxisCpeIdMixin"

// MTVW-33
class ProfileEntry {
	@serializable profileId = null
	@serializable contractId = null
	@serializable nickName = null
	@serializable channelListId = null
	@serializable subtitle = null
	@serializable(list()) recentSearches = []
	// MTVW-96
	@serializable volume = null
	@serializable muted = null

	constructor(profileId, nickName, namedProp, value) {
		//console.debug("STORE profileId %s", profileId)
		this.profileId = profileId
		this.nickName = nickName
		this[namedProp] = value
	}
}
// MTV-3431
class AccountEntry {
	@serializable cpeId = null
	@serializable initialProfileId = null
	@serializable accountId = null
	@serializable nickName = null
	// MTVW-33
	@serializable(list(object(ProfileEntry))) profiles = []

	constructor(cpeId, mainProfileId, accountId, nickName) {
		this.cpeId = cpeId
		this.initialProfileId = mainProfileId
		this.accountId = accountId
		this.nickName = nickName
	}
}

// MTV-3431
const STORE_EXT = STORE_NAME + ".ext"
class Accounts {
	@serializable ver = 1
	@serializable entries = []

	constructor() {
		getDefaultModelSchema(Accounts).props["entries"] = list(object(AccountEntry))
	}

	_init() {
		console.debug("_init")
		if (localStorage.has(STORE_EXT)) {
			const store = localStorage.get(STORE_EXT)
			if (store.ver === this.ver) {
				update(this, store)
			} else {
				localStorage.remove(STORE_EXT)
			}
		}
	}

	update(profileId) {
		// get account's main profile
		let mainProfile = rootStore?.sso?.profileOrder.filter(i => {
			return (i?.IsMainProfile === true)
		})
		mainProfile = mainProfile?.length > 0 ? mainProfile[0] : null

		// if we don't have any entries yet, we initially add the stored profile and cpeId
		if (this.entries?.length === 0) {
			if (localStorage.has("store.cpeId")) {
				this.entries.push(new AccountEntry(localStorage.get("store.cpeId"), mainProfile?.ProfileIdentificationGuid, rootStore?.sso?.UserName, mainProfile?.Nickname))
			}
		}

		// check wheter a profile of the account has already been registered
		// if so, we can apply it's cepId
		const registered = this.entries.filter(i => {
			return rootStore.sso.profileOrder.filter(j => {
				return i?.initialProfileId === j?.ProfileIdentificationGuid
			})?.length === 1
		})
		console.debug("registered user %o nick %s cpeId", rootStore?.sso?.UserName, mainProfile?.Nickname, registered[0]?.cpeId)

		if (registered?.length === 0) {
			// create new cpeId
			localStorage.remove("store.cpeId")
			let cpeId = getCpeId()
			this.entries.push(new AccountEntry(cpeId, mainProfile?.ProfileIdentificationGuid, rootStore?.sso?.UserName, mainProfile?.Nickname))
		}
		else if (registered?.length === 1) {
			// apply cpeId and accountId
			localStorage.set("store.cpeId", registered[0].cpeId, true)
			registered[0].initialProfileId = mainProfile?.ProfileIdentificationGuid
			registered[0].accountId = rootStore?.sso?.UserName
			registered[0].nickName = mainProfile?.Nickname
		}
		localStorage.set(STORE_EXT, serialize(this))
	}

	// MTVW-33
	setChannelList(profileId, channelListId, channelListName) {
		this.setProfileProp(profileId, "channelListId", channelListId)
	}

	// MTVW-33
	getChannelListId(profileId) {
		return this.getProfileProp(profileId, "channelListId")
	}

	// MTVW-92
	setSubTitle(profileId, lang) {
		this.setProfileProp(profileId, "subtitle", lang)
	}

	// MTVW-92
	getSubTitle(profileId) {
		return this.getProfileProp(profileId, "subtitle")
	}

	// MTVW-96
	setVolume(profileId, value) {
		this.setProfileProp(profileId, "volume", value)
	}

	// MTVW-96
	getVolume(profileId) {
		return this.getProfileProp(profileId, "volume")
	}

	// MTVW-96
	setMuted(profileId, value) {
		this.setProfileProp(profileId, "muted", value)
	}

	// MTVW-96
	getMuted(profileId) {
		return this.getProfileProp(profileId, "muted")
	}

	setRecentSearches(profileId, searches) {
		this.setProfileProp(profileId, "recentSearches", searches)
	}

	getRecentSearches(profileId) {
		return this.getProfileProp(profileId, "recentSearches")
	}

	// MTVW-609: avoid backend errors with missing header properties
	setContractId(profileId, contractId) {
		this.setProfileProp(profileId, "contractId", contractId)
	}

	getContractId(profileId) {
		return this.getProfileProp(profileId, "contractId")
	}

	_getRegisteredProfile(profileId) {
		// check wheter a profile of the account has already been registered
		const registered = this.entries.filter(i => {
			return rootStore.sso.profileOrder.filter(j => {
				return i?.initialProfileId === j?.ProfileIdentificationGuid
			})?.length === 1
		})
		//console.debug("_getRegisteredProfile profileId %s, account %o", profileId, registered[0])

		if (registered?.length === 1) {
			const profiles = registered[0]?.profiles?.filter(i => {
				return i.profileId === profileId
			})

			if (profiles?.length === 1) {
				return [profiles[0], registered]
			}
		}
		// MTVW-609: avoid backend errors with missing header properties
		else if (rootStore.sso.profileOrder.length === 0) {
			// profileOrder may created somewhat later, retrieve from entries
			const res = []
			this.entries?.map(i => {
				i.profiles?.map(j => {
					if (profileId === j?.profileId) res.push(j)
				})
			})
			if (res?.length === 1) {
				return [res[0], res]
			}
		}
		return [null, registered]
	}

	// MTVW-92
	getProfileProp(profileId, namedProp) {
		// eslint-disable-next-line no-unused-vars
		const [profile, registered] = this._getRegisteredProfile(profileId)
		if (profile) {
			//console.debug("namedProp %o, profile[namedProp] %o, %o, registered %o", namedProp, profile[namedProp], profile["channelListId"], registered)
			return profile[namedProp]
		}
		return null
	}

	// MTVW-92
	setProfileProp(profileId, namedProp, value) {
		const [profile, registered] = this._getRegisteredProfile(profileId)
		//console.debug("setProfileProp id %s, prop %s, prof %o, reg %o", profileId, namedProp, profile, registered)
		if (profile) {
			profile[namedProp] = value
			//console.debug("prof %o, name %o, value %o", profile, namedProp, value)
		}
		else {
			//registered[0].profiles.push(new ProfileEntry(profileId, rootStore.sso.profile?.Nickname, channelListId, channelListName))
			registered[0]?.profiles?.push(new ProfileEntry(profileId, rootStore.sso.profile?.Nickname, namedProp, value))
		}
		localStorage.set(STORE_EXT, serialize(this))
	}
}

export class SsoBrowserStore {
	@serializable currentProfileId = null
	// MTVW-427 unused, Bugsnag 'The same observable object cannot appear twice in the same tree
	//@serializable(object(SsoToken)) tokenSubscriber = null
	@serializable ver = 1
	// MTVW-157: new properties, they will be removed if an older version of the web app is used, but it shouldn't harm
	@serializable keepSignedIn = null
	@serializable changeProfile = null
	@serializable pin = ""
	@serializable tokenProfile = null
	// MTVW-185
	@serializable lastSearch = null
	@serializable loggedOff = null

	// MTV-3431: don't save in store.sso, older version of the apps would overwrite it. Use STORE_EXT instead.
	//@serializable(object(Accounts))
	accounts = new Accounts()

	// time of modification or creation
	time = rootStore.time.getTimeStamp()

	DEFAULT_ADSTIMEOUT = 500
	HIGH_ADSTIMEOUT = 2000

	constructor() {
		makeObservable(this, {
			currentProfileId: observable,
			//tokenSubscriber: observable,
			keepSignedIn: observable,
			changeProfile: observable,
			pin: observable,
			tokenProfile: observable,
			lastSearch: observable,
			loggedOff: observable,
			accounts: observable,
			adsMock: computed,
			testButtons: computed,
			pinReset: computed
		})
	}

	_init() {
		if (localStorage.has(STORE_NAME)) {
			const store = localStorage.get(STORE_NAME)
			if (store.ver === this.ver) {
				update(this, store)
			} else {
				localStorage.remove(STORE_NAME)
			}
		}
		deepObserve(this, () => this.saveStorage())
		//observe(this, "currentProfileId", () => this.saveStorage())
		//observe(this.tokenSubscriber, "access_token", () => this.saveStorage())
		//observe(this.tokenSubscriber, "refresh_token", () => this.saveStorage())
		//observe(this, "keepSignedIn", () => this.saveStorage())

		// MTV-3431: load account store
		this.accounts._init()
	}

	saveStorage() {
		//console.debug("SsoBrowserStore.saveStorage()")
		this.time = rootStore.time.getTimeStamp()
		localStorage.set(STORE_NAME, serialize(this))
	}

	removeStorage() {
		this.currentProfileId = null
		// MTVW-427 unused, Bugsnag 'The same observable object cannot appear twice in the same tree
		//this.tokenSubscriber = null
		this.keepSignedIn = true
		this.changeProfile = false
		this.pin = null
		this.tokenProfile = null
		return this
	}

	// MTV-3431: applies the cpeId for the current account
	// adds support for multiple accounts without re-registering an already registered device again after a login to another account
	applyAccountCpeId() {
		if (this.currentProfileId) {
			this.accounts.update(this.currentProfileId)
			//localStorage.set(STORE_NAME, serialize(this))
		}
	}

	// MTVW-33
	setChannelList(channelListId, channelListName) {
		if (this.currentProfileId) {
			this.accounts.setChannelList(this.currentProfileId, channelListId)
		}
	}

	// MTVW-33
	getChannelListId() {
		if (this.currentProfileId) {
			return this.accounts.getChannelListId(this.currentProfileId)
		}
		else return null
	}

	// MTVW-92
	setSubTitle(lang) {
		if (this.currentProfileId) {
			this.accounts.setSubTitle(this.currentProfileId, lang)
		}
	}

	// MTVW-92
	getSubTitle() {
		if (this.currentProfileId) {
			return this.accounts.getSubTitle(this.currentProfileId)
		}
		else return null
	}

	// MTVW-96
	setVolume(value) {
		if (this.currentProfileId) {
			this.accounts.setVolume(this.currentProfileId, value)
		}
	}

	// MTVW-96
	getVolume() {
		const defaultVolume = 60
		if (this.currentProfileId) {
			let volume = this.accounts.getVolume(this.currentProfileId)
			if (volume === undefined || volume === null) volume = defaultVolume
			return volume
		}
		else return defaultVolume
	}

	// MTVW-96
	setMuted(profileId, value) {
		if (this.currentProfileId) {
			this.accounts.setMuted(this.currentProfileId, value)
		}
	}

	// MTVW-96
	getMuted(profileId) {
		let muted = false
		if (this.currentProfileId) {
			muted = this.accounts.getMuted(this.currentProfileId)
			if (muted === undefined || muted === null) muted = false
		}
		return muted
	}

	setRecentSearches(searches) {
		if (this.currentProfileId) {
			this.accounts.setRecentSearches(this.currentProfileId, searches)
		}
	}

	getRecentSearches() {
		if (this.currentProfileId) {
			const searches = this.accounts.getRecentSearches(this.currentProfileId)
			//console.debug("getRecentSearches", this.accounts, searches ? searches : [])
			return searches ? searches : []
		}
		else return []
	}

	// MTVW-609: avoid backend errors with missing header properties
	setContractId(contractId) {
		if (this.currentProfileId) {
			this.accounts.setContractId(this.currentProfileId, contractId)
		}
	}

	getContractId() {
		if (this.currentProfileId) {
			console.debug("getContractId", this.accounts.getContractId(this.currentProfileId))
			return this.accounts.getContractId(this.currentProfileId)
		}
		else return "undefined"
	}

	setGt12State(enable) {
		//console.debug("setGt12State %o", enable)
		localStorage.set("gt12Override", enable, true)
	}

	get gt12State() {
		let value = undefined
		if (localStorage.has("gt12Override")) {
			value = localStorage.get("gt12Override")
		}
		//console.debug("adsMock is %o", value)
		return value
	}

	setAdsMock(enable) {
		//console.debug("setAdsMock %o", enable)
		localStorage.set("adsMock", enable, true)
	}

	get adsMock() {
		let value = false
		if (localStorage.has("adsMock")) {
			value = localStorage.get("adsMock")
		}
		//console.debug("adsMock is %o", value)
		return value
	}

	setTestButtons(enable) {
		localStorage.set("additionalTestButtons", enable, true)
	}

	get testButtons() {
		let value = false
		if (localStorage.has("additionalTestButtons")) {
			value = localStorage.get("additionalTestButtons")
		}
		return value
	}

	setAdsTimeout(value) {
		localStorage.set("adsTimeout", value, true)
	}

	get adsTimeout() {
		let value = this.DEFAULT_ADSTIMEOUT
		if (localStorage.has("adsTimeout")) {
			value = localStorage.get("adsTimeout")
		}
		return value
	}

	setPlayerLog(enable) {
		localStorage.set("playerLog", enable, true)
	}

	get playerLog() {
		let value = false
		if (localStorage.has("playerLog")) {
			value = localStorage.get("playerLog")
		}
		return value
	}

	// MTVW-593, MTVW-848: NTL
	setNtl(enable) {
		//console.debug("setNtl %o", enable)
		localStorage.set("ntl", enable, true)
	}

	get ntl() {
		let value = true
		if (localStorage.has("ntl")) {
			value = localStorage.get("ntl")
		}
		//console.debug("ntl is %o", value)
		return value
	}

	setOnboardingOption(value) {
		localStorage.set("onboardingOption", value, true)
	}

	get onboardingOption() {
		let value = 0
		if (localStorage.has("onboardingOption")) {
			value = localStorage.get("onboardingOption")
		}
		return value
	}

	setPinReset(enable) {
		localStorage.set("pinReset", enable, true)
	}

	get pinReset() {
		let value = false
		if (localStorage.has("pinReset")) {
			value = localStorage.get("pinReset")
		}
		return value
	}

	get controlsVisibleDuration() {
		let value = 3000	// default 3 seconds
		if (localStorage.has("controlsVisibleDuration")) {
			value = localStorage.get("controlsVisibleDuration")
		}
		return value
	}
}
