// l10n status: partial
import { action, computed, flow, observable, makeObservable, override } from "mobx"
import { PageAbstract } from "./PageAbstract"
import { rootStore } from "store/RootStore"
import { CleConfig } from "scenes/ChannelListEditor/ChannelListEditor"
import { useHistoryRouteAdd } from "components/utils/RouteUtils"
import { ROUTE_NAMES } from "site-ql/RouteConst"
import { Observe } from "store/page/mixin/ObserveMixin"
import { EventEmitter } from "components/utils/EventEmitter"
import { LiveTvConfig } from "scenes/LiveTv/LiveTv"
import { l10n } from "store/lang/L10n"

export class ChannelListEditor extends PageAbstract {
	// MTVW-503: Use Channels MS
	msEpg = rootStore.page.MsEpg
	//channelLists = this.channelListRepo.ChannelLists
	get channelLists() {
		//return this.channelListRepo.isReady ? this.channelListRepo.ChannelLists : []
		// MTVW-657, MTVW-686: Predefined channel lists
		return rootStore.page.singleton.channelLists.DataArray.ChannelList
	}

	scrollTop = 0
	// TODO: change initialization, change to computed?
	listChannelIds = []
	listName = ""
	searchString = ""
	filteredChannels = []
	currentChannelListId = null
	editListId = null
	isPersonalList = true

	_observer = new Observe(this, "_observer")
	readyCallback = null
	channelAddedCallback = null
	listUpdatePosition = 0
	dragChannel = -1
	// MTVW-657
	MAX_NAME_LENGTH = 30

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			channelLists: computed,
			scrollTop: observable,
			listChannelIds: observable,
			listName: observable,
			searchString: observable,
			filteredChannels: observable,
			currentChannelListId: observable,
			editListId: observable,
			isPersonalList: observable,
			listUpdatePosition: observable,
			dragChannel: observable,
			_init: action,
			_setupListAndName: action,
			channelListRepo: computed,
			// MTV-3583: do not make it computed here, mobx 6 generates an error if it's already registered in the base class
			//isReady: computed,
			setCurrentChannelListId: action,
			currentChannels: computed,
			validateChannelListName: action,
			// change for mobx 6.3 which is more strict
			//setRouteParams: action,
			setRouteParams: override,
			saveChannelList: action,
			deleteChannelList: action,
			toggle: action,
			addChannel: action,
			remove: action,
			removeAll: action,
			selectAll: action,
			filterChannels: action,
			setDragChannel: action,
			setVerticalScroll: action,
			setListChannelIds: action
		})
	}

	// TODO
	historyRouteAdd = useHistoryRouteAdd()

	_refElemScroll = null

	_initAsync = flow(function* () {
		//console.debug("initAsync")
		const start = new Date().getTime()
		yield this.channelListRepo.waitAsync()
		yield this.msEpg.getChannelsAsync()
		const elapsed = new Date().getTime() - start
		console.debug("isReady %s elapsed %s", this.isReady, elapsed)
		//this.channelListRepo.preloadChannelPictures("?mode=box&w=480&h=270)
		this._setupListAndName()
	})

	_onMount() {
	}

	_init = flow(function* (id) {
		//console.debug("_init %s", id)
		this.scrollTop = 0
		this.listChannelIds = []
		this.listName = ""
		this.searchString = ""
		this.filteredChannels = []
		this.currentChannelListId = null
		this.editListId = null
		this.isPersonalList = true
		this._refElemScroll = null
		this.listUpdatePosition = 0
		yield this._initAsync()
	})

	_setupListAndName() {
		this.editListId = this.routeParams.type === "edit" ? this.routeParams.id : null
		//console.debug("_setupListAndName %s", this.editListId)
		if (this.editListId !== null) {
			// edit mode
			if (this.channelListRepo.isReady) {
				// traxis cleanup: use msEpg
				//const channels = this.channelListRepo.getChannelsForList(this.editListId)
				const channels = this.msEpg.getChannelsForList(this.editListId)
				//console.debug("getChannelsForList 1", /*this.msEpg.isReady,*/ channels)
				//console.debug("list %o", list)
				this.listChannelIds = []
				for (let i = 0; i < channels?.length; i++) {
					this.listChannelIds.push(channels[i].id)
				}
				//console.debug("listChannelIds %o", this.listChannelIds)

				const chList = this.channelListRepo.getChannelList(this.editListId)
				//console.debug("getChannelList", chList)
				this.listName = chList?.Name
				this.isPersonalList = chList?.IsPersonal
				//console.debug("edit channels length %s name %s personal", channels.length, this.listName, this.isPersonalList)
				this.listUpdatePosition = 0
				const isCreator = chList?.ProfileId === rootStore.sso.profile.id
				//console.debug("isCreator %s %s %s", isCreator, chList?.ProfileId, rootStore.sso.profile.id)
				if (this.readyCallback) this.readyCallback(this.listName, this.isPersonalList, isCreator)
			}
			else {
				//console.debug("channelListRepo not ready")
			}
		}
		else {
			this.listChannelIds = ["2021", "2023"] // preset to first 2 elements for creating a new list
			this._assignUniqueChannelListName(/*rootStore.sso.profile.Nickname + "s*/ l10n.featureChannelListsTitle)
			//console.debug("init channels for create")
			this.listUpdatePosition = 0
			if (this.readyCallback) this.readyCallback(this.listName, this.isPersonalList, true)
		}
	}

	_assignUniqueChannelListName = (name,) => {
		// MTVW-657
		let validStr = this._getValidName(name)

		let unique = false
		while (!unique) {
			unique = this.isUniqueChannelListName(validStr)
			if (!unique) {
				validStr += " 1"
			}
		}
		this.listName = validStr
	}

	isUniqueChannelListName = (name = this.listName) => {
		const filtered = this.channelLists.filter(i => { return i.Name.toLowerCase() === name.toLowerCase() })
		if (this.editListId === null) {
			// must be unique if new channel list is created
			return filtered?.length === 0
		}
		// editing cases
		else if (filtered?.length === 1 && this.editListId === filtered[0]?.id) {
			// name has not been changed
			return true
		}
		else if (filtered?.length === 0) {
			// update the name of the channel list being edited
			return true
		}
		else {
			return false
		}
	}

	_getValidName(name, maxLength = this.MAX_NAME_LENGTH) {
		// strip off invalid characters and limit string length
		// MTVW-657
		//let validStr = name.replace(/[^a-zA-Z0-9 -]+/g, "").substring(0, 20)
		//0-9, A-Z, a-z, ä. Ä, ü, Ü, ö, Ö, é, É, è, È, à, À, (, ), -, _, !, "space" 
		return name.replace(/[^a-zA-Z0-9 äÄüÜöÖéÉèÈàÀ\(\)_!-]+/g, "").substring(0, maxLength)
	}

	validateChannelListName = (name = this.listName) => {
		const validStr = this._getValidName(name)
		this.listName = validStr
		//console.debug("validate %s %s", name, validStr)
		return (validStr?.length >= 2) && (validStr?.length <= this.MAX_NAME_LENGTH)
	}

	setRouteParams(params) {
		super.setRouteParams(params)
		console.debug("routeParams %s %s", this.routeParams.type, this.routeParams.id)
		this._init()
		if ((this.routeParams.type !== "edit") && (this.routeParams.type !== "create")) {
			// TODO: 401
			this.historyRouteAdd(ROUTE_NAMES.NotFound)
		}
		//this._setupListAndName()
		return this
	}

	get channelListRepo() {
		return this._pageStore.ChannelListRepo
	}

	get isReady() {
		return this._pageStore.ChannelListRepo.isReady
	}

	setCurrentChannelListId(id) {
		this.currentChannelListId = id
	}

	get currentChannels() {
		if (this.isReady) {
			if (this.searchString?.length > 0) {
				return this.filteredChannels
			}
			else {
				// MTVW-503: Use Channels MS
				//return this.channelListRepo.isReady ? this.channelListRepo.getChannelsForList(this.currentChannelListId) : []
				// traxis cleanup: use msEpg
				//const result = this.channelListRepo.isReady ? this.channelListRepo.getChannelsForList(this.currentChannelListId) : []
				//return this.msEpg.convertTraxisChannelList(this.msEpg.channels, result)
				const result = this.msEpg.getChannelsForList(this.currentChannelListId)
				//console.debug("getChannelsForList 2", result)
				return result
			}
		}
		else {
			return []
		}
	}

	isSelected(channelId) {
		return computed(() => {
			return this.listChannelIds.includes(channelId)
			//return oEvent.id === this._root.page.ChannelListRepo?.activeChannelId
		}).get()
	}

	toDelete = [
		//"45ed8302-8865-4a81-bbc9-5c5fe301441d",
		//"1b9aaf3b-18bd-4c72-b3d7-4e163be3f67a",
		//"72fad586-1098-4764-8592-324168db2340",
		//"aa078fc5-7fc2-4b47-9fc4-373d634a0ff7",
		//"f77d7e5f-9718-4e19-a4b7-a1695c23505b",
		//"731b35b8-f184-48f6-9ab5-02142bcbfaca",
		//"987d77ed-3e9f-43b9-8b09-06270331d9fd",
		//"8d9dc47b-ba92-496b-815c-323c9b870b71",
	]

	saveChannelList = flow(function* (channelListName = this.listName) {
		try {
			for (let i = 0; i < this.toDelete?.length; i++) {
				yield rootStore.api.DeleteChannelList({ channelListId: this.toDelete[i] }).fetchDataAsync()
			}

			let clListId = this.editListId
			if (clListId === null) {
				// create new channel list
				const req = rootStore.api.CreateChannelList({ channelListName: channelListName })
				yield req.fetchDataAsync()
				clListId = req.Data.ChannelList.id
			}
			//console.debug("req %o", req)
			yield rootStore.api.UpdateChannelList({ channelListId: clListId, channelListName: channelListName, ordinal: 1, channelList: this.listChannelIds }).fetchDataAsync()

			if (this.isPersonalList) {
				yield rootStore.api.CreatePrivateChannelList(({ channelListId: clListId })).fetchDataAsync()
			}
			else {
				yield rootStore.api.DeletePrivateChannelList(({ channelListId: clListId })).fetchDataAsync()
			}
			// refresh, kick on fetching current data
			// MTVW-636: refresh channel lists
			EventEmitter.dispatch("reloadChannelLists", "")

			// check whether active channel has been deleted
			if (this.channelListRepo.activeChannelListId === clListId) {
				if ((this.listChannelIds.indexOf(this.channelListRepo.activeChannel) < 0) && (this.listChannelIds?.length > 0)) {
					this.channelListRepo.setActiveChannel(this.listChannelIds[0])
					rootStore.page.LiveTv.setEpgListPos(this.listChannelIds[0], 0)
				}
			}
		}
		catch (error) {
			throw (error)
		}
	})

	deleteChannelList = flow(function* (channelListId = null) {
		if (channelListId !== null) {
			const activeId = this.channelListRepo.activeChannelListId
			yield rootStore.api.DeleteChannelList({ channelListId: channelListId }).fetchDataAsync()

			// MTVW-636
			// notify ChannelListMenu in case active channel list has been deleted
			//console.debug("deleteChannelList", activeId, channelListId)
			// reload will be done in ChannelListMenu
			EventEmitter.dispatch("reloadChannelLists", activeId === channelListId ? "deleted" : "")

			rootStore.page.LiveTv.setEpgListPos(this.channelListRepo.activeChannelId, LiveTvConfig.cellHeight + LiveTvConfig.cellMarginBottom)
		}
	})

	toggle(channelId) {
		const index = this.listChannelIds.indexOf(channelId)
		if (index > -1) {
			this.remove(index)
		} else {
			this.listChannelIds.push(channelId)
			this.setChannelListPos(this.listChannelIds?.length - 1, CleConfig.listCellHeight + CleConfig.listCellMarginBottom)
			if (this.channelAddedCallback) this.channelAddedCallback(this.listChannelIds?.length - 1)
			//this._refElemScroll.scrollTop = this.scrollTop
		}
		//console.debug("channelIds %o", this.listChannelIds)
	}

	addChannel(channelId) {
		// only used for adding a temporary channel during drag, don't invoke channelAddedCallback
		const index = this.listChannelIds.indexOf(channelId)
		if (index < 0) {
			this.listChannelIds.push(channelId)
		}
	}

	remove(index) {
		//this.listChannelIds.splice(index, 1)
		this.listChannelIds = this.listChannelIds.filter(item => { return item !== this.listChannelIds[index] })
		this.listUpdatePosition = (index < this.listChannelIds?.length) ? index : Math.max(0, this.listChannelIds?.length - 1)
	}

	removeAll(list) {
		for (let i = 0; i < list?.length; i++) {
			let index = this.listChannelIds.indexOf(list[i].id)
			if (index > -1) {
				this.remove(index)
			}
		}
	}

	selectAll(list) {
		for (let i = 0; i < list?.length; i++) {
			if (this.listChannelIds.indexOf(list[i].id) < 0) {
				this.listChannelIds.push(list[i].id)
			}
		}
	}

	filterChannels(query) {
		const searchString = query.toLowerCase().replace(/ /gi, "")
		// MTVW-503: Use Channels MS
		//const filtered = this.channelListRepo.Channels.filter(i => { return i.Name.toLowerCase().replace(/ /gi, "").indexOf(searchString) > -1 })
		const filtered = this.msEpg.channels.filter(i => { return i.Name.toLowerCase().replace(/ /gi, "").indexOf(searchString) > -1 })
		//console.debug("filtered %o", filtered)
		this.filteredChannels = filtered
		this.searchString = searchString
	}

	setDragChannel(channel) {
		this.dragChannel = channel
	}

	getChannel(channelId) {
		// MTVW-503: Use Channels MS
		//const index = this.channelListRepo.Channels.findIndex(i => i.id === channelId)
		//return this.channelListRepo.Channels[index]
		return this.msEpg.getChannel(channelId)
	}
	getIndex(channelId) {
		return this.listChannelIds.indexOf(channelId)
	}
	setRefElemScroll(refElem) {
		this._refElemScroll = refElem
		if (refElem !== null) {
			this._refElemScroll.scrollTop = this.scrollTop
		}
	}
	setChannelListPos(index, height = null) {
		if (height !== null) {
			// index = this.channelListRepo.getIndexChannelOnList(channelId)
			this.scrollTop = index > 0 ? index * height : 0
		}
		this.listUpdatePosition = index
	}

	setVerticalScroll() {
		console.log("@()")
		if (this._refElemScroll !== null) {
			this.scrollTop = Math.max(0, this._refElemScroll.scrollTop)
		}
		return this
	}

	setListChannelIds(ids) {
		this.listChannelIds = ids
	}
}
