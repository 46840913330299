import { computed } from "mobx"
import { flow, types as t } from "mobx-state-tree"
import { adjustUtcStartDay, HourInMinute, moment, } from "store/util/moment"
import { rootStore } from "store/RootStore"
import { CALLING_CONTEXT_TYPE } from "store/page/OverlayEventDetails"
import { ObserveMixin } from "store/page/mixin/ObserveMixin"
import { sleep } from "utils/Utils"

let fullListProgramForChannel = []
const daysBefore = 7
const daysAfter = 7

export const MiniEpg = t.compose(
	"MiniEpg",
	// PageAbstractMbx,
	// RefElemMixin,
	ObserveMixin,
	t
		.model({
			contextName: "miniEpg",
			daysBefore: daysBefore,
			daysAfter: daysAfter,
		})
		.volatile(self => ({
			msEpg: rootStore.page.MsEpg,
			pageTVGuide: rootStore.page.TvGuide,
			isShow: false,
			selectedChannel: null,
			listProgramForChannel: [],
			isReady: false,

			refDaysHead: null,

			selectedDayTs: null,
			// iDateHour and iDateMinute are not yet used in the MiniEpg, but might be needed in the future
			iDateHour: null,
			iDateMinute: null,

			refreshVisibleTIme: {},
			_refreshTimeQueue: [],
			_refreshTimeDone: true,

			inSearch: false,
			hideNowButton: false,

			startNewRequest: false,
		}))
		.views(self => ({
			get getSelectedDayTs() {
				return self.selectedDayTs
			},
			get getSelectedChannel() {
				return self.selectedChannel
			},
			get isReady() {
				return self.msEpg.isReady
			}
		}))

		.actions(self => ({
			setShow(bShow = null, channelSelected = null, callingContext = CALLING_CONTEXT_TYPE.keepOldContext) {
				self.isShow = bShow === null ? !self.isShow : bShow
				if (self.isShow) {
					// in the prototype is always open to the current day. reset the commented code to open on selected day in tvGuide 
					// if (callingContext === CALLING_CONTEXT_TYPE.TvGuide) {
					// 	self.setSelectedDayTs(self.pageTVGuide.getSelectedDayTs)
					// } else {
					const now = moment()
					// MTVW-414
					//self.setSelectedDayTs(now.utc().startOf('day').valueOf(), now.hour(), now.minute())
					self.setSelectedDayTs(adjustUtcStartDay().valueOf(), now.hour(), now.minute())
					// }

					self.selectedChannel = channelSelected === null ? self.selectedChannel : channelSelected
					// For refresh every minute
					self._addObserve(rootStore.time.getTimeStampTick(HourInMinute, true), changes => {
						if (!self.inSearch) self.loadMiniEpgProgramsForChannel()
					})
				} else {
					self.unload()
				}
				return self
			},
			closeModal() {
				self.setShow(false) // used in the onClose property of the modal dialog
			},
			setRefDaysHead(ref) {
				self.refDaysHead = ref
			},
			addElementToRef(element) {
				self.refDaysHead.current.push(element)
			},
			setSelectedDayTs(dayTs, dateHour = null, dateMinute = null) {
				if (dayTs !== null) {
					self.selectedDayTs = dayTs
					// console.debug("selectedDay in miniEpg is ", moment(dayTs).format("DD MM YYYY"))
				}
				if (dateHour !== null) self.iDateHour = dateHour
				if (dateMinute !== null) self.iDateMinute = dateMinute
			},
			async openEventDetails(oEvent) {
				rootStore.page.Player.error = null
				await rootStore.page.OverlayEventDetails.setEpgEventById(oEvent.id)
				rootStore.page.OverlayEventDetails.setComponentType('event').setShow(true, CALLING_CONTEXT_TYPE.TvGuide)
				return self
			},
			isSelectedChannel(chId) {
				return computed(() => {
					return chId === rootStore.page.ChannelListRepo.activeChannelId
				}).get()
			},
			setVisibleTime(dayTs, dateHour = null, dateMinute = null, noAnimation = false) {
				self._refreshTimeQueue.push({ time: { dayTs: dayTs, dateHour: dateHour, dateMinute: dateMinute } })
				self._dequeueRefreshTime()
				// return self
			},
			// MTVW-414: commented the function below
			// _getToDayTs() {
			// 	return moment().utc().startOf('day').valueOf()
			// },
			setToDayAsSelectedDay() {
				// MTVW-414
				//self.setVisibleTime(self._getToDayTs())
				self.setVisibleTime(adjustUtcStartDay().valueOf())
			},
			isCurrentDaySelected() {
				// MTVW-414
				//return self.selectedDayTs === self._getToDayTs()
				return self.selectedDayTs === adjustUtcStartDay().valueOf()
			},
			unload() {
				self.listProgramForChannel = []
				fullListProgramForChannel = []
				if (self.refDaysHead) self.refDaysHead.current = []
				self._refreshTimeQueue = []
				self._refreshTimeDone = true
				self.inSearch = false
				self.hideNowButton = false
			},
			filterProgramList(txtToFilter = "") {
				txtToFilter = txtToFilter.toLowerCase()
				self.listProgramForChannel = fullListProgramForChannel.filter((program) => {
					return program.Title.Name.toLowerCase().includes(txtToFilter);
				})
			},
			setVisibleTimeDone() {
				self._refreshTimeDone = true
				self._dequeueRefreshTime()
			},
			_dequeueRefreshTime() {
				if (self._refreshTimeDone) {
					const item = self._refreshTimeQueue.shift()
					if (item) {
						self._refreshTimeDone = false
						self.refreshVisibleTIme = item
					}
				}
			},
			setInSearchMode(value) {
				self.inSearch = value
			},
			setHideNowButton(value) {
				self.hideNowButton = value
			},

			loadMiniEpgProgramsForChannel: flow(function* () {
				if (self.selectedChannel) {
					try {
						if (fullListProgramForChannel?.length === 0) self.startNewRequest = true
						yield self.msEpg.getProgramsForChannelAsync(self.selectedChannel.id, daysBefore, daysAfter)
						// If the miniEpg is closed or if the channel displayed in the miniEpg is not the same loaded from the request (es. fast click in different channels ) return empty arrays
						if (self.isShow === false || self.msEpg?.channelDayPrograms === null || self.msEpg?.channelDayPrograms[0].channelId !== self.selectedChannel?.id) {
							fullListProgramForChannel = []
							self.listProgramForChannel = []
						} else {
							fullListProgramForChannel = self.msEpg.channelDayPrograms
							self.listProgramForChannel = fullListProgramForChannel
							// leave the spinner longer to hide the scrolling of the list at this time
							yield sleep(100);
							self.startNewRequest = false
						}

					} catch (error) {
						//TODO: handle case of error
						// fullListProgramForChannel = []
						// self.listProgramForChannel = []
						console.error("loadMiniEpgProgramsForChannel", error, self.msEpg)
					}
				}
			})
		}))
)
