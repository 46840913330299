import { observer } from "mobx-react"
import React from "react"

export const FilterEventTypeBar = observer(({ page, items, filterEventType = page.filterEventType }) => {
	if (!filterEventType || !items || items.length === 0) return null

	// MTVW-624: only show when there is content
	const visibility = []
	visibility[page.FILTER_EVENT_LIVE_REPLAY] = page.applyTabFilter(items, page.FILTER_EVENT_LIVE_REPLAY) != null
	visibility[page.FILTER_EVENT_UPCOMING] = page.applyTabFilter(items, page.FILTER_EVENT_UPCOMING) != null
	visibility[page.FILTER_EVENT_ALL] = page.applyTabFilter(items, page.FILTER_EVENT_ALL) != null
	if (!visibility[page.FILTER_EVENT_LIVE_REPLAY] && !visibility[page.FILTER_EVENT_UPCOMING]) {
		page.setFilterEventType(page.FILTER_EVENT_ALL)
		visibility[page.FILTER_EVENT_LIVE_REPLAY] = false
		visibility[page.FILTER_EVENT_UPCOMING] = false
	}
	else if (!visibility[page.FILTER_EVENT_LIVE_REPLAY]) {
		page.setFilterEventType(page.FILTER_EVENT_UPCOMING)
		visibility[page.FILTER_EVENT_ALL] = false
	}
	else if (!visibility[page.FILTER_EVENT_UPCOMING]) {
		page.setFilterEventType(page.FILTER_EVENT_LIVE_REPLAY)
		visibility[page.FILTER_EVENT_ALL] = false
	}

	return (
		<div className="searchFilterTypeEvents">
			<div style={{ visibility: visibility[page.FILTER_EVENT_LIVE_REPLAY] ? 'visible' : 'hidden' }} className={`${filterEventType === page.FILTER_EVENT_LIVE_REPLAY ? "searchFilterTypeEventsItem searchFilterTypeEventsItemSelected" : "searchFilterTypeEventsItem"}`}
				onClick={() => {
					page.setFilterEventType(page.FILTER_EVENT_LIVE_REPLAY)
				}}
				data_test="filter_type_live_and_replay_events"
			>{page.FILTER_EVENT_LIVE_REPLAY}</div>
			<div style={{ visibility: visibility[page.FILTER_EVENT_UPCOMING] ? 'visible' : 'hidden' }} className={`${filterEventType === page.FILTER_EVENT_UPCOMING ? "searchFilterTypeEventsItem searchFilterTypeEventsItemSelected" : "searchFilterTypeEventsItem"}`}
				onClick={() => {
					page.setFilterEventType(page.FILTER_EVENT_UPCOMING)
				}}
				data_test="filter_type_upcoming_events"
			>{page.FILTER_EVENT_UPCOMING}</div>
			{/* MTVW-620: FILTER_EVENT_ALL at the end */}
			<div style={{ visibility: visibility[page.FILTER_EVENT_ALL] ? 'visible' : 'hidden' }} className={`${filterEventType === page.FILTER_EVENT_ALL ? "searchFilterTypeEventsItem searchFilterTypeEventsItemSelected" : "searchFilterTypeEventsItem"}`}
				onClick={() => {
					page.setFilterEventType(page.FILTER_EVENT_ALL)
				}}
				data_test="filter_type_all_events"
			> {page.FILTER_EVENT_ALL}</div>
		</div>
	)
})
