import { types as t } from "mobx-state-tree"
import { moment } from "store/util/moment"

export const RollingBuffer = t.model("RollingBuffer", {
	id: t.identifier,
	Products: t.model({
		resultCount: t.number,
		Product: t.array(
			t
				.model({
					id: t.identifier,
					RelationDurationIntoPast: t.maybeNull(t.string)
				})
				.views(self => ({
					get RelationDurationIntoPastMoment() {
						return moment.duration(self.RelationDurationIntoPast)
					}
				}))
		)
	})
})
//.views(self => ({}))

export const RollingBuffers = t
	.model("RollingBuffers", {
		resultCount: t.number,
		RollingBuffer: t.array(RollingBuffer)
	})
	.views(self => ({
		get getBufferId() {
			return self?.RollingBuffer[0]?.id
		},
		get getBufferDurationMoment() {
			return self.RollingBuffer[0]?.Products?.Product[0]?.RelationDurationIntoPastMoment
		}
		// filter(callbackFn) {
		// 	return self.CustomProperty.filter(callbackFn)
		// },
		// find(callbackFn) {
		// 	return self.CustomProperty.find(callbackFn)
		// },
		// filterByHref(hrefFn) {
		// 	return self.CustomProperty.filter(i => hrefFn(i.href) === true)
		// },
		// findByHref(hrefFn) {
		// 	return self.CustomProperty.find(i => hrefFn(i.href) === true)
		// },
		// getByHref(hrefFn) {
		// 	const property = self.CustomProperty.find(i => hrefFn(i.href) === true)
		// 	if (property === undefined) {
		// 		return null
		// 	}
		// 	return property.Value
		// }
	}))
