// l10n status: done
import React from "react"
import { Stepper, Step, StepButton, StepLabel, StepContent, Button, IconButton, Typography, TextField, FormControlLabel, Switch, Divider, Link } from "@mui/material"
import { CreateComponent } from "components/utils/CreateComponent"
import Edit from "@mui/icons-material/Edit"
import { firstInstallStore } from "store/FirstInstallStore"
import logo from "img/Quickline_logo.svg"
import { useHistory } from "react-router"
import { PinEntryDialog } from "scenes/components/Login"
import { rootStore } from "store/RootStore"
import { PinEntryTextFields } from "scenes/Settings/PinEntryTextFields"
import "./firstInstall.css"
import { ModalMessage } from "components/ModalMessage"
import { ROUTE_NAMES } from "site-ql/RouteConst"
import { useHistoryRouteAdd } from "components/utils/RouteUtils"
import { l10n } from "store/lang/L10n"

const styles = theme => ({
	firstInstallRadioLabel: { "& span": { fontSize: 14 } },
	firstInstallButton: { padding: 0 },
	firstInstallEdit: { width: 24, height: 24, borderWidth: 1, borderStyle: "solid", borderRadius: "50%", padding: 3 },
	firstInstallProfilName: { display: "flex", marginBottom: 24 },
	firstInstallDivider: { width: "100%", marginBottom: 14 },
})
// profile name
const ActionStep0 = CreateComponent(styles, false, true, ({ classes, page, config, store, setModalMessage }) => {
	const nameInput = React.createRef()

	React.useEffect(() => {
		// validate profile name
		onProfileNameChange({ target: { value: store.profileName } })

		if (store.backendError !== null) {
			//setProfileNameError("Der gewählte Name ist nicht gültig")
			setProfileNameError(store.backendError.msResponse.detail)
			store.setDisableContinue(true)
		}

		const input = document.getElementById(`first-install-name-${config.firstRun ? "0" : "1"}`)
		setCaretPosition(input, store?.profileName?.length)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const [profileNameError, setProfileNameError] = React.useState("")

	function setCaretPosition(ctrl, pos) {
		//nameInput.current.focus()
		if (ctrl.setSelectionRange) {
			ctrl.focus()
			ctrl.setSelectionRange(pos, pos)
		}
	}

	function onProfileNameChange(event) {
		// strip off invalid characters, limit string length and check for duplicate profile name
		const [validStr, error] = rootStore.sso.profile.validateProfileName(event.target.value, !config.firstRun ? null : rootStore.sso.profile)
		//console.debug("validStr %s, error %s", validStr, error)
		store.setProfileName(validStr)
		setProfileNameError(error)
		if (error !== "") {
			store.setDisableContinue(true)
		}
		else {
			store.setBackendError(null)
			store.setDisableContinue(false)
		}
	}

	return (
		<>
			<TextField id={`first-install-name-${config.firstRun ? "0" : "1"}`} pattern="[0-9]*" error helperText={profileNameError} margin="normal" variant="outlined" className={classes.firstInstallProfilName} value={store.profileName} onChange={onProfileNameChange} ref={nameInput} />
		</>
	)
})

// PIN entry
const ActionStep1 = CreateComponent(styles, false, false, ({ classes, page, config, store, setModalMessage }) => {

	const setIsValidPin = (value) => {
		store.setDisableContinue(!value)
	}

	const setNewPin = (newPin) => {
		store.setPin(newPin)
	}

	return (
		<PinEntryTextFields storedPin={store.pin}
			setNewPin={setNewPin}
			setIsValidPin={setIsValidPin}
			hidePin={false}
			showPinInStepper={true}
		/>
	)
})

// Profile PIN required
const ActionStep2 = CreateComponent(styles, false, true, ({ classes, page, config, store, setModalMessage }) => {
	const toggleProfileAuthentication = () => event => {
		store.setProfileAuthentication(event.target.checked)
	}

	return (
		<>
			<FormControlLabel value="start" control={<Switch checked={store.profileAuthentication} onChange={toggleProfileAuthentication()} color="primary" />} label={l10n.featureNewProfileStep3Field1Text} labelPlacement="start" className={classes.firstInstallRadioLabel} />
		</>
	)
})

// Personal recommendations
const ActionStep3 = CreateComponent(styles, false, true, ({ classes, page, config, store, setModalMessage }) => {
	const togglePersonalizationOptIn = () => event => {
		store.setPersonalizationOptIn(event.target.checked)
	}

	return (
		<>
			<FormControlLabel value="start" control={<Switch checked={store.personalizationOptIn} onChange={togglePersonalizationOptIn()} color="primary" />} label={l10n.featureNewProfileStep4Field1Text} labelPlacement="start" className={classes.firstInstallRadioLabel} />
		</>
	)
})

// Replay opt in
const ActionStep4 = CreateComponent(styles, false, true, ({ classes, page, config, store, setModalMessage }) => {
	const ReplayMessage = {
		title: l10n.featureFirstRunReplayMessageTitle,
		content: l10n.featureFirstRunReplayMessageText,
		button1: l10n.featureFirstRunReplayButtonActivate,
		button2: l10n.featureFirstRunReplayButtonDeactivate
	}

	const cancel = (modalMessage) => {
		// replay still on
		//console.debug("cancel %o", modalMessage)
		setModalMessage(modalMessage => ({ ...modalMessage, show: false }))
	}

	const confirm = (modalMessage) => {
		// replay off
		//console.debug("confirm %o", modalMessage)
		document.getElementById("replaySwitch").checked = false
		store.setChannelsOptIn(false)
		setModalMessage(modalMessage => ({ ...modalMessage, show: false }))
	}

	const toggleChannelsOptIn = () => event => {
		if (!event.target.checked) { // if set false open modal message
			setModalMessage({ show: true, info: ReplayMessage, onCancel: cancel, onConfirm: confirm })
		} else {
			store.setChannelsOptIn(true)
		}
	}

	return (
		<FormControlLabel value="start" control={<Switch id="replaySwitch" checked={store.channelsOptIn} onChange={toggleChannelsOptIn()} color="primary" />} label={l10n.featureFirstRunStep5Field1Text} labelPlacement="start" className={classes.firstInstallRadioLabel} />
	)
})

// Done
const ActionStep5 = CreateComponent(styles, false, true, ({ classes, page, config, store }) => {
	return (
		<>
			<div className="firstInstallSummary">
				<Divider className={classes.firstInstallDivider} variant="middle" />
				<Typography variant="caption">{config.trailer1}</Typography>
				<Typography variant="caption">{config.trailer2}</Typography>
				{/*
					<Button className={classes.firstInstallLink}>
					Profil hinzufügen
					</Button>
				*/}
			</div>
		</>
	)
})

export { configFirstRun, configCreateProfile, FirstInstall, CreateProfile }
// MTVW-513 / l10n: function to avoid static initialization issue with dynamically loading l10n strings
//export const configFirstRun = {
const configFirstRun = () => {
	const config = {
		firstRun: true,
		title: l10n.featureFirstRunScreenTitle,
		subtitle: l10n.featureFirstRunScreenIntroText,
		trailer1: l10n.featureFirstRunScreenTrailer1,
		trailer2: l10n.featureFirstRunScreenTrailer2,

		steps: [
			{
				title: l10n.featureNewProfileStep1Title,
				body: l10n.featureNewProfileStep1Label,
				action: (config, store, setModalMessage) => <ActionStep0 config={config} store={store} setModalMessage={setModalMessage} />,
			},
			{
				title: l10n.featureNewProfileStep2Title,
				body: l10n.featureNewProfileStep2Text,
				action: (config, store, setModalMessage) => <ActionStep1 config={config} store={store} setModalMessage={setModalMessage} />,
			},
			{
				title: l10n.featureNewProfileStep3Title,
				body: l10n.featureNewProfileStep3Text,
				action: (config, store, setModalMessage) => <ActionStep2 config={config} store={store} setModalMessage={setModalMessage} />,
			},
			// MTVW-627: disable personal recommendations
			/*
			{
				title: l10n.featureNewProfileStep4Title,
				body: l10n.featureFirstRunStep4Text,
				link: true,
				action: (config, store, setModalMessage) => <ActionStep3 config={config} store={store} setModalMessage={setModalMessage} />,
			},
			*/
			{
				title: l10n.featureFirstRunStep5Title,
				body: l10n.featureFirstRunStep5Text,
				action: (config, store, setModalMessage) => <ActionStep4 config={config} store={store} setModalMessage={setModalMessage} />,
			},
			{
				title: l10n.featureNewProfileStep6Title,
				body: l10n.featureFirstRunStep6Title,
				action: (config, store, setModalMessage) => <ActionStep5 config={config} store={store} setModalMessage={setModalMessage} />,
			}
		]
	}
	return config
}

// MTVW-513 / l10n: function to avoid static initialization issue with dynamically loading l10n strings
//export const configCreateProfile = {
const configCreateProfile = () => {
	const config =
	{
		firstRun: false,
		title: l10n.featureNewProfileScreenTitle,
		subtitle: l10n.featureNewProfileScreenIntroText,
		trailer1: "",
		trailer2: "",

		steps: [
			{
				title: l10n.featureNewProfileStep1Title,
				body: l10n.featureNewProfileStep1Label,
				action: (config, store, setModalMessage) => <ActionStep0 config={config} store={store} setModalMessage={setModalMessage} />,
			},
			{
				title: l10n.featureNewProfileStep2Title,
				body: l10n.featureNewProfileStep2Text,
				action: (config, store, setModalMessage) => <ActionStep1 config={config} store={store} setModalMessage={setModalMessage} />,
			},
			{
				title: l10n.featureNewProfileStep3Title,
				body: l10n.featureNewProfileStep3Text,
				action: (config, store, setModalMessage) => <ActionStep2 config={config} store={store} setModalMessage={setModalMessage} />,
			},
			// MTVW-627: disable personal recommendations
			/*
			{
				title: l10n.featureNewProfileStep4Title,
				body: l10n.featureFirstRunStep4Text,
				link: true,
				action: (config, store, setModalMessage) => <ActionStep3 config={config} store={store} setModalMessage={setModalMessage} />,
			},
			*/
			{
				title: l10n.featureNewProfileStep6Title,
				body: l10n.featureNewProfileStep6Text,
				action: (config, store, setModalMessage) => <ActionStep5 config={config} store={store} setModalMessage={setModalMessage} />,
			}
		]
	}
	return config
}

const DisabledMessage = {
	show: false, info: {}, onCancel: null, onConfirm: null
}

const FirstInstall = CreateComponent(styles, false, false, ({ classes, config, completionCb = null }) => {
	const store = firstInstallStore
	const history = useHistory()
	const [modalMessage, setModalMessage] = React.useState(DisabledMessage)

	React.useEffect(() => {
		if (store.backendError !== null) {
			setActiveStep(0)
		}
	}, [store.backendError])

	const [activeStep, setActiveStep] = React.useState(0)
	const [configDone, setConfigDone] = React.useState(false)
	// prepare the store, doing it in useEffect would not return the proper updated result in 'render'
	if (!configDone) {
		store.firstRun(config.firstRun)
		setConfigDone(true)
	}

	const confirmError = (modalMessage) => {
		//console.debug("cancel %o", modalMessage)
		setModalMessage(modalMessage => ({ ...modalMessage, show: false }))
		if (config.firstRun) {
			rootStore.sso.handleLogOffAsync()
		}
		else {
			history.goBack()
		}
	}

	function handleNext() {
		if (activeStep === config?.steps?.length - 1) {
			store.updateBackend().then(() => {
				localStorage.setItem("firstLogin", true)
				localStorage.setItem("showModalInfo", false)
				// MTVW-21: don't call goBack here, pass to the parent compoment
				//if (!store._firstRun) history.goBack()
				if (completionCb !== null) completionCb()
			}).catch((error) => {
				console.debug("ERROR in FirstInstall %o, be %o", error, store.backendError)
				rootStore.isLoading = false
				setModalMessage({
					show: true, info: {
						title: error.msResponse.title,
						content: error.msResponse.detail,
						button1: null,
						button2: config.firstRun ? l10n.commonActionSignOut : l10n.commonActionOk
					}, onCancel: null, onConfirm: confirmError
				})
				//store.setBackendError(error)
				//setActiveStep(0)
			})
		} else {
			store.saveStorage()
			setActiveStep(prevActiveStep => prevActiveStep + 1)
		}
	}

	function handleBack() {
		setActiveStep(prevActiveStep => prevActiveStep - 1)
		store.setDisableContinue(false)
	}

	const handleStep = step => () => {
		setActiveStep(step)
	}

	const handleClose = () => {
		store.removeStorage()
		if (config.firstRun) {
			// first install
			rootStore.sso.handleLogOffAsync()
		}
		else {
			// new profile
			history.goBack()
		}
	}

	function EditStepIcon(props) {
		return (
			<IconButton className={classes.firstInstallButton}>
				<Edit className={classes.firstInstallEdit} color="primary" />
			</IconButton>
		)
	}

	const CommonButtons = CreateComponent(styles, false, true, ({ classes, page }) => {
		// MTVW-627: Allow pressing "Enter" for "Continue" button
		React.useEffect(() => {
			document.addEventListener("keydown", handleKeyPress, false)
			return () => {
				document.removeEventListener("keydown", handleKeyPress, false)
			}
		}, [])

		const handleKeyPress = (e) => {
			e.stopPropagation()
			//console.debug("key", e.key)
			if (e.key === "Enter") {
				if (!store.disableContinue) handleNext()
			}
			if (e.key === "Backspace") {
				if (activeStep > 1) handleBack()
			}
		}
		return (
			<div className="firstInstallButtons">
				{activeStep !== 0 && (
					<Button disableFocusRipple={true} className="backButton" color="primary" onClick={handleBack} >
						{l10n.featureNewProfileButtonBack}
					</Button>
				)}

				<Button disableFocusRipple={true} className="nextButton" variant="contained" color="primary" onClick={handleNext} disabled={store.disableContinue}>
					{activeStep === config?.steps?.length - 1 ? l10n.featureNewProfileButtonStart : l10n.featureNewProfileButtonContinue}
				</Button>
			</div>
		)
	})

	return (
		<>
			<ModalMessage
				show={modalMessage.show}
				msgInfo={modalMessage.info}
				onCancel={() => { if (modalMessage.onCancel) modalMessage.onCancel(modalMessage) }}
				onConfirm={() => { if (modalMessage.onConfirm) modalMessage.onConfirm(modalMessage) }}
			/>
			{store.render && (
				<div className="firstInstall">
					<div className="firstInstallHead">
						<img src={logo} className="quicklineLogo" alt="Quickline" />
						<Button
							onClick={() => { handleClose() }}
							className={"discardButton"}
						>
							{config.firstRun ? l10n.commonActionSignOut : l10n.commonActionCancel}
						</Button>
					</div>
					<div className={"FirstInstallTitleContainer"} >
						<div className="firstInstallTitle">{config.title} </div>
						<div className="firstInstallSubTitle"> {config.subtitle}</div>
					</div>

					<Stepper activeStep={activeStep} orientation="vertical" id="stepper">
						{config.steps.map((step, index) => (
							<Step key={index}>
								{index >= activeStep ? (
									<StepButton>{step.title}</StepButton>
								) : (
									<StepLabel StepIconComponent={EditStepIcon} onClick={handleStep(index)}>
										{step.title}
									</StepLabel>
								)}
								<StepContent>
									<Typography variant="caption" className="firstInstallStepTitle">
										{step.body} {step.link === true && <Link underline="hover" href={l10n.configInformationalTermsDefaultUrl} target="_blank">{l10n.configInformationalTermsDefaultUrl}</Link>}
									</Typography>
									<div className="firstInstallStepsContent">
										{config.steps[activeStep].action(config, store, setModalMessage)}
									</div>
									<CommonButtons />
								</StepContent>
							</Step>
						))}
					</Stepper>
					{/*activeStep === 5 && <ActionStep5 config={config} store={store} />*/}
				</div>
			)}
		</>
	)
})

const CreateProfile = CreateComponent(styles, false, true, ({ classes }) => {
	const history = useHistory()
	const historyRouteAdd = useHistoryRouteAdd()
	//const [open, setOpen] = React.useState(rootStore.sso.pin === "")
	// open pin entry in any case
	const [open, setOpen] = React.useState(firstInstallStore.creatingProfile)

	React.useEffect(() => {
		// MTVW-405: load new profile after profile creation
		// avoid intermediate rendering transitions
		setOpen(firstInstallStore.creatingProfile)
		return () => {
			// MTVW-21: go back on component will unmount
			//history.goBack(), see note in handleClose below
			setOpen(false)
		}
	}, [])

	const onPinEntered = (pin) => {
		const result = rootStore.sso.profile.tryPinCode(pin).then(() => {
			// remember pin
			//console.debug("onPinEntered %s", pin)
			rootStore.sso.setPin(pin)
			setOpen(false)
		})
		// return promise for handling errors
		return result
	}
	const handleClose = () => {
		// MTVW-21: if goBack is called here, the create profile screen will disappear and afterwards appear for a short moment again. This happens only with 'yield', see note in firstInstallStore
		// does not work
		//history.replace(ROUTE_NAMES.LiveTv)		// MTVW-405: load new profile after profile creation
		// avoid intermediate rendering transitions
		if (!firstInstallStore.profileCreated) history.goBack()
		else {
			firstInstallStore.startNewProfile(false)
			setTimeout(() => {
				historyRouteAdd(ROUTE_NAMES.LiveTv)
				//rootStore.sso.fixUrl()
			}, 100)
		}
	}

	return (
		// Set restorePos to false, since we don't have rendered the create profile page yet
		<>
			<PinEntryDialog open={open} onPinDone={onPinEntered} onClose={handleClose} profileName={rootStore.sso.profile?.Nickname} info={"CreateProfile"} flags={{ enableLogoff: false, fullScreen: false, restorePos: false }} data-test="pin enter section" />
			{!open && (
				<>
					<FirstInstall config={configCreateProfile()} completionCb={handleClose} />
				</>
			)}
		</>
	)
})
