/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-03
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-10
 */

import { CircularProgress } from "@mui/material"
import React from "react"
import { CreateComponent } from "components/utils/CreateComponent"
import { rootStore } from "store/RootStore"

export const WaitForInit = CreateComponent(null, false, true, ({ children }) => {
	if (rootStore.isLoading === true) {
		return (
			<div style={{ display: "flex", justifyContent: "center", marginTop: "20%" }}>
				<CircularProgress size={100} />
			</div>
		)
	}
	return <>{children}</>
})
