// MTV-3583: updated moment packages say _moment.tz is undefined
//import * as _moment from "moment"
import "moment/locale/de-ch"
// MTV-3583: import as _moment from moment-timezone instead
//import "moment-timezone/moment-timezone"
import * as __moment from "moment-timezone/moment-timezone"
import "moment-timezone/builds/moment-timezone-with-data"
import * as __mdfSetup from "moment-duration-format"

// VITE BUILD: function for development, module with key 'default' for production
const _moment = typeof __moment === "function" ? __moment : __moment.default
const _mdfSetup = typeof __mdfSetup === "function" ? __mdfSetup : __mdfSetup.default
//console.debug("_moment %o, momentDurationFormatSetup  %o", _moment, _mdfSetup)

_mdfSetup(_moment)
_moment.locale("de-ch")
_moment.tz.setDefault("Europe/Zurich")
// below line would delete the locale
//_moment.locale('en', null)

_moment.updateLocale('de-ch', {
	weekdays: [
		"Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"
	]
})
/* 3 letter abbreviations are not used in German
_moment.updateLocale('de-ch', {
	weekdaysShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"]
})
*/
_moment.updateLocale('de-ch', {
	weekdaysMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"]
})

_moment.updateLocale('de-ch', {
	months: [
		"Januar", "Februar", "März", "April", "Mai", "Juni", "Juli",
		"August", "September", "Oktober", "November", "Dezember"
	]
});

_moment.updateLocale('de-ch', {
	monthsShort: [
		"Jan", "Feb", "Mär", "Apr", "Mai", "Jun",
		"Jul", "Aug", "Sep", "Okt", "Nov", "Dez"
	]
});

// extend moment with some functions
_moment.fn.dump = function () {
	return this.format("YYYY-MM-DD HH:mm:ss ZZ")
}

export const moment = _moment

export const HourInSec = 3600
export const HourInMinute = 60
export const MinuteInMs = HourInMinute * 1000
export const HourInMs = HourInSec * 1000
export const DayInMs = HourInMs * 24
export const DayInSec = HourInSec * 24
export const utcOffsetInHour = moment().utcOffset() / 60

/** MTVW-414:
 * Problem:
 * in case the local time is between midnight and midnight + utcOffset
 * the calculation of the time in UTC returns as value the previous day.
 * E.g. utcOffset=+1, ClockTime=00:30, UTC->23:30
 * The following functions solve the problem 
 * by checking the time and utcOffset and returning the correct date and time.
 */

export const adjustUtc = (dateTs = null) => {
	const _date = dateTs === null ? moment() : moment(dateTs)
	const _utcDate = _date.clone().utc()

	if (_date.diff(_utcDate.format("YYYY-MM-DD"), 'days') !== 0) {
		return _date.add(_date.utcOffset(), 'minutes').utc()
	}
	return _utcDate
}

export const adjustUtcStartDay = (dateTs = null) => {
	const _date = dateTs === null ? moment() : moment(dateTs)
	const _utcDate = _date.clone().utc()

	let _startDay = _utcDate.clone().startOf('day')
	if (_date.diff(_startDay.format("YYYY-MM-DD"), 'days') !== 0) {
		_startDay = _date.add(_date.utcOffset(), 'minutes').utc()
		return _startDay.startOf('day')
	}
	return _startDay
}

//MTVW-414: old version of function splitted in two function in the code above
// export const getUtcNewDate = (returnStartOfDay = false, dateToVerify = null) => {
	
// 	const _dateToVerify = dateToVerify === null ? moment() : moment(dateToVerify)
// 	const _utcNewDate = _dateToVerify.clone().utc()

// 	let _newDate = returnStartOfDay ? _utcNewDate.clone().startOf('day') : _utcNewDate
// 	if (_dateToVerify.diff(_newDate.format("MM-DD-YYYY"), 'days') !== 0) {
// 		_newDate = _dateToVerify.add(_dateToVerify.utcOffset(), 'minutes').utc()
// 		return returnStartOfDay ? _newDate.startOf('day') : _newDate
// 	}
// 	return _newDate
// }
