// l10n status: done
import { Typography } from "@mui/material"
import React from "react"
import { l10n } from "store/lang/L10n"

import "./recordingComponentsStyles.css"

export const RecordingsEmpty = () => (
	<div className="RecordingsEmptyNote">
		<Typography variant="h5" >
			{l10n.featureRecordingsEmptyTitle}
		</Typography>
		<Typography variant="body2" >
			{l10n.featureRecordingsEmptyBody}
		</Typography>
	</div>
)
