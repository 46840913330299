import { CreateComponent } from "components/utils/CreateComponent"
import React from "react"
// import { rootStore } from "store/RootStore"
//  NEVER-USED import { RecordingsMenuIcons } from "../scenes/Recordings/components/RecordingsMenuIcons"
import { RouteTab, RouteTabs } from "./utils/RouteTab"

const styles = theme => ({
	// MTV-1691: don't use margin: "0 auto" since the item 'TV GUIDE' would disappear when clicking on 'AUFNAHMEN' 
	TopMenuWrapper: { margin: 0, display: "flex", minHeight: 40 },
	TopMenuTabs: { flexGrow: 1, minHeight: 40 }, //boxShadow: theme.shadows[5],
	TabItem: { minWidth: 180, width: 180, minHeight: 74.8 },
	indicator: { backgroundColor: theme.palette.primary["main"] }

})

export const TopMenu = CreateComponent(styles, false, true, ({ classes }) => (
	<div className={classes.TopMenuWrapper}>
		<RouteTabs centered className={classes.TopMenuTabs} routeCustomName="top_menu" classes={{ indicator: classes.indicator }}>
			<RouteTab className={classes.TabItem + " LinkItemNavBar"} label="Live TV" value="LiveTv" data-test="livetv" />
			<RouteTab className={classes.TabItem + " LinkItemNavBar"} label="TV Guide" value="TvGuide" data-test="tvguide" />
			<RouteTab className={classes.TabItem + " LinkItemNavBar"} label="Aufnahmen" value="Recordings" data-test="recordings" />
		</RouteTabs>
		{/* NEVER-USED {rootStore.page.Recordings.isVisible && <RecordingsMenuIcons page={rootStore.page.Recordings} />} */}
	</div>
))
