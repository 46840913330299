import { CssBaseline } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import { CreateComponent, rootStore, BrowserSupport, OnboardingModal, WaitForInit, Router, routeStore, ConnectionMonitor, firstInstallStore, FirstInstall, configFirstRun, RouterProvider, Login, NavBar, OverlayEventDetails} from "./"
//import React from "react"
import { HashRouter } from "react-router-dom"
//import { Player } from "scenes/Player/Player"
import "./site-ql/style.css"
import { theme } from "./site-ql/theme"

const styles = (theme: any) => ({
	// MTVW-754 trial
	pageWrapper: { /*paddingTop: 66,*/ height: "100%", width: "100vw", margin: "0 auto" },
	page: { height: "100%" },
	"@global": {
		"#root": {
			height: "100%"
		}
	},
	Player: {
		position: "absolute",
		width: 920,
		top: 90,
		left: "50%",
		marginLeft: -512,
		transition: "all 0.5s linear"
	},
	PlayerOverlay: {
		margin: 0,
		bottom: 10,
		top: "auto",
		position: "fixed",
		left: 10,
		transition: "all 0.5s linear",
		width: 400
	}
})

export const App = CreateComponent(styles, false, true, ({ classes }: any) => {

	return (
		<ThemeProvider theme={theme}>
			<ConnectionMonitor isShow={false} />
			<CssBaseline />
			<HashRouter>
				<RouterProvider.Provider value={routeStore}>
					<WaitForInit>
						<BrowserSupport />
						{/* MTVW-157: TODO checks for this.browserStore.currentProfileId? */}
						{!rootStore.sso.isAuthenticatedProfile ? (
							<Login store={rootStore} />
						) : (
							<>
								{firstInstallStore.isFirstInstall ? (
									<FirstInstall config={configFirstRun()} />
								) : (
									<>
									{rootStore.page.Onboarding.hasBlockingAnnouncement ? (
										<OnboardingModal />
									) : (
										<>
										{rootStore.page.Onboarding.hasNonBlockingAnnouncement ? (
											<OnboardingModal />
										) : <></>
										}
										{/* MTVW-754 trial */}
										<div /*style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center"}}*/ className={classes.page}>
											<NavBar />
											<div /*style={{ position: "relative", margin: "0 auto", width: "80vw"}}*/
												id="pageWrapper" className={classes.pageWrapper} /*style={{ width: "100vw" }}*/ >
												{/* TYPESCRIPT: store is not used*/}		
												<Router /*store={rootStore}*/ />
												{/*<Router {...test} />*/}
											</div>
											{/*<div className={classes.Player}><Player page={rootStore.page.Player} /></div>*/}
										</div>
										<OverlayEventDetails isShow={true} />
										</>
								)}
									</>
								)}
							</>
						)}
					</WaitForInit>
				</RouterProvider.Provider>
			</HashRouter>
		</ThemeProvider>
	)
})
