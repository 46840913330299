import { RouteStore } from "components/utils/RouteUtils"
import React from "react"
import { FirstInstall, CreateProfile } from "scenes/FirstInstall/FirstInstall"
import { LiveTv } from "scenes/LiveTv/LiveTv"
import { Movies } from "scenes/Movies/Movies"
import { Search } from "scenes/Search/Search"
import { NotFound } from "scenes/NotFound/NotFound"
import { Recordings } from "scenes/Recordings/Recordings"
import { RecordingsList } from "scenes/Recordings/RecordingsList"
import { Settings } from "scenes/Settings/Settings"
import { Sport } from "scenes/Sport/Sport"
import { TvGuide } from "scenes/TvGuide/TvGuide"
import { ChannelListEditor } from "scenes/ChannelListEditor/ChannelListEditor"
import { ROUTE_NAMES } from "site-ql/RouteConst"
import { Internals } from "scenes/Internals/Internals"

export const routeStore = new RouteStore()
	.add(ROUTE_NAMES.LiveTv, "/", LiveTv, {
		search_menu: false
	})
	.add(ROUTE_NAMES.TvGuide, "/tv-guide", TvGuide, {
		search_menu: false
	})
	.add(ROUTE_NAMES.Movies, "/movies", Movies)
	.add(ROUTE_NAMES.Sport, "/sport", Sport)
	.add(ROUTE_NAMES.Search, "/search", Search, {
		top_menu: false
	})
	.add(ROUTE_NAMES.Settings, "/settings", Settings, {
		// suppress warning: "value provided to the Tabs component is invalid."
		top_menu: false,//  MTV-2130 no selection in tab topmenu
		search_menu: false
	})
	.add(ROUTE_NAMES.FirstInstall, "/first-install", FirstInstall, {
		top_menu: false,
		search_menu: false
	})
	.add(ROUTE_NAMES.CreateProfile, "/create-profile", CreateProfile, {
		top_menu: false,
		search_menu: false
	})
	.add(ROUTE_NAMES.Recordings, "/recordings", Recordings, {
		search_menu: false
	})
	.add(ROUTE_NAMES.RecordingsList, "/recordings/:type(series|single|planned)/:isDelete(delete)?", RecordingsList, {
		type: "single",
		isDelete: void 0,
		top_menu: "Recordings",
		search_menu: false
	})
	.add(ROUTE_NAMES.RecordingsSeriesList, "/recordings/:type(series|single|planned)/series_details/:serieId/:isDelete(delete)?", RecordingsList, {
		type: "series",
		isDelete: void 0,
		top_menu: "Recordings",
		search_menu: false
	})
	.add(ROUTE_NAMES.ChannelListEditor, "/channel-list-editor/:type(create|edit)/:id?", ChannelListEditor, {
		// suppress warning: "value provided to the Tabs component is invalid."
		type: "create",
		top_menu: false,//  MTV-2130 no selection in tab topmenu
		search_menu: false
	})
	.add(ROUTE_NAMES.Internals, "/appinternals", Internals, {
		// suppress warning: "value provided to the Tabs component is invalid."
		top_menu: false,
		search_menu: false
	})
	.add(ROUTE_NAMES.NotFound, "*", NotFound)

export const Router = () => <>{routeStore.renderRoutes()}</>
