import React, { Component } from "react"
import { Fab } from "@mui/material"
import { withStyles } from "@mui/styles"

const styles = {
	root: { width: "844px", margin: "31px auto", justifyContent: "space-between", display: "flex" }
}

export const MoviesGenres = withStyles(styles)(
	class extends Component {
		render() {
			const { classes } = this.props

			return (
				<div className={classes.root}>
					<Fab variant="extended" size="medium" color="primary" aria-label="Komödie">
						Komödie
					</Fab>
					<Fab variant="extended" size="medium" color="primary" aria-label="Fantasy / Sci-FiFantasy / Sci-Fi">
						Fantasy / Sci-Fi
					</Fab>
					<Fab variant="extended" size="medium" color="primary" aria-label="Action">
						Action
					</Fab>
					<Fab variant="extended" size="medium" color="primary" aria-label="Drama">
						Drama
					</Fab>
					<Fab variant="extended" size="medium" color="primary" aria-label="Thriller">
						Thriller
					</Fab>
					<Fab variant="extended" size="medium" color="primary" aria-label="Horror">
						Horror
					</Fab>
					<Fab variant="extended" size="medium" color="primary" aria-label="Dokumentation">
						Dokumentation
					</Fab>
				</div>
			)
		}
	}
)
