// l10n status: done
import { action, computed, flow, observable, makeObservable } from "mobx"
import { PageAbstract } from "./PageAbstract"
import { rootStore } from "store/RootStore"
import { urlFixHttpProtocolMismatch } from "store/util/urlFixHttpProtocolMismatch"
import { l10n } from "store/lang/L10n"

export class Search extends PageAbstract {
	gradientImg = `linear-gradient(
		90deg,hsl(0,0%,18.82%) 0%,
    hsla(0,0%,18.82%,0.9854227405) 8.62%,
    hsla(0,0%,18.82%,0.944606414) 16.56%,
    hsla(0,0%,18.82%,0.8819241983) 23.93%,
    hsla(0,0%,18.82%,0.8017492711) 30.85%,
    hsla(0,0%,18.82%,0.7084548104999999) 37.42%,
    hsla(0,0%,18.82%,0.6064139942) 43.77%,
    hsla(0,0%,18.82%,0.5) 50%,
    hsla(0,0%,18.82%,0.3935860058) 56.23%,
    hsla(0,0%,18.82%,0.29154518949999997) 62.58%,
    hsla(0,0%,18.82%,0.19825072889999995) 69.15%,
    hsla(0,0%,18.82%,0.11807580169999998) 76.07%,
    hsla(0,0%,18.82%,0.055393585999999995) 83.44%,
    hsla(0,0%,18.82%,0.014577259499999995) 91.38%,
    hsla(0,0%,18.82%,0) 100%) `

	backGroundImgStyle = {
		backgroundImage: null,
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		// MTVW-624: overlap left to make poster bigger
		maxWidth: "300px"
	}

	ALL_SEASON_SELECTED = l10n.featureSearchAllEpisodes
	//TODO: check if needed a category for the all genres
	ALL_GENRES_SELECTED = l10n.featureSearchFilterCategoryAll
	FILTER_EVENT_ALL = l10n.featureSearchFilterCategoryAll
	FILTER_EVENT_LIVE_REPLAY = l10n.featureSearchTimeFilterLiveAndReplay
	FILTER_EVENT_UPCOMING = l10n.featureSearchTimeFilterUpcoming

	results = null
	overview = null
	actor = null
	genre = null
	actorInGenre = null
	genreInGenre = null
	series = null
	categorySelected = null
	seasonSelected = this.ALL_SEASON_SELECTED
	genresSelected = this.ALL_GENRES_SELECTED
	scrollposSelected = null
	refresh = false
	// MTVW-620: FILTER_EVENT_ALL -> FILTER_EVENT_LIVE_REPLAY
	filterEventType = this.FILTER_EVENT_LIVE_REPLAY
	//lastSearch = null
	_intervalId = null
	msEpg = rootStore.page.MsEpg

	CATEGORY_TOP = "top"
	CATEGORY_EVENTS = "events"
	CATEGORY_MOVIES = "movies"
	CATEGORY_SERIES = "series"
	CATEGORY_EPISODES = "episodes"
	CATEGORY_SPORTS = "sports"
	CATEGORY_ACTORS = "actors"
	CATEGORY_GENRES = "genres"
	CATEGORY_CHANNELS = "channels"
	//CATEGORY_PROGRAMS = 'programs'

	// SearchMatch types
	TYPE_EVENT = "event"
	TYPE_SERIE = "series"
	TYPE_GENRE = "genre"
	TYPE_CREDIT = "credits"
	TYPE_CHANNEL = "channel"

	filterLabel = {
		[this.CATEGORY_TOP]: l10n.featureDetailsFilterCategoryTopResults,
		[this.CATEGORY_SERIES]: l10n.featureSearchFilterCategorySeries,
		[this.CATEGORY_MOVIES]: l10n.featureSearchFilterCategoryMovies,
		[this.CATEGORY_SPORTS]: l10n.featureSearchFilterCategorySports,
		[this.CATEGORY_ACTORS]: l10n.featureSearchFilterCategoryActors,
		[this.CATEGORY_GENRES]: l10n.featureSearchFilterCategoryGenres,
		[this.CATEGORY_CHANNELS]: l10n.featureSearchFilterCategoryChannels,
		// [PROGRAMS]: 'Programs'
	}

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			results: observable,
			overview: observable,
			actor: observable,
			genre: observable,
			actorInGenre: observable,
			genreInGenre: observable,
			series: observable,
			categorySelected: observable,
			seasonSelected: observable,
			genresSelected: observable,
			scrollposSelected: observable,
			refresh: observable,
			filterEventType: observable,
			// below would create a cyclic dependency
			//isReady: computed,
			repoReady: computed,
			//channelListRepo: computed,
			setCategorySelected: action,
			setRefresh: action,
			searchOverview: action,
			search: action,
			_init: action,
			setSeasonSelected: action,
			setFilterEventType: action,
			setGenresSelected: action,
			setScrollposSelected: action
		})
	}

	isReplayAllowed = (event, maxReplayDuration = null) => {
		let allowed = rootStore.page.MsEpg?.isReplayAllowed(event?.channelId, event?.AvailabilityStartTs, event?.AvailabilityEndTs, false, maxReplayDuration)
		if (rootStore.page.Recordings.Manager.getMarker(event?.id)?.isComplete) allowed = true
		return allowed
	}

	get repoReady() {
		// MTVW-508: rootStore.page.MsEpg.channels, use channels MS
		return this.msEpg.channels
	}

	get isReady() {
		return this.repoReady && (this.results !== null)
	}

	get isOverViewReady() {
		return this.repoReady && (this.overview !== null)
	}

	getChannel(channelId) {
		return this.msEpg.getChannel(channelId)
	}

	isChannelSubscribed(channelId) {
		return this.getChannel(channelId) !== null
	}

	// MTVW-631
	isOneChannelSubscribed(channels) {
		// temporarily true for environments that don't return channels yet
		let subscribed = channels.length > 0 ? false : true
		for (let i = 0; i < channels.length; i++) {
			subscribed ||= this.isChannelSubscribed(channels[i])
		}
		return subscribed
	}

	// MTVW-631
	isSearchMatchSubscribed(item) {
		switch (item.type) {
			case "event":
				return this.isChannelSubscribed(item.data.event?.channelId)
			case "series":
				return this.isOneChannelSubscribed(item.data.channels)
			case "credits":
				return this.isOneChannelSubscribed(item.data.channels)
			case "channel":
				return this.isChannelSubscribed(item.data.id)
			case "genre":
				return true
			default:
				return true
		}
	}

	setCategorySelected(value) {
		this.categorySelected = value
	}

	setSeasonSelected(value) {
		this.seasonSelected = value
	}

	setGenresSelected(value) {
		this.genresSelected = value
	}

	setFilterEventType(value) {
		this.filterEventType = value
	}

	setScrollposSelected(value) {
		this.scrollposSelected = value
	}

	applyTabFilter(items, filterEventType = this.FILTER_EVENT_LIVE_REPLAY) {
		if (!items) return null
		//console.debug("items=", items, filterEventType)
		let result = items.map((item, index) => {
			if (filterEventType === this.FILTER_EVENT_LIVE_REPLAY) {
				if (item?.event?.eventType === "p" || item?.event?.eventType === "c") {
					// MTVW-624: added check for out of replay window
					//if (!this.isReplayAllowed(item?.event)) console.debug("not allowed", item?.event)
					// MTVW-631: show items within 7 days, override maxReplayDuration
					return this.isReplayAllowed(item?.event, "P7D") ? item : null
					// MTVW-629: don't filter anymore
					//return item
				} else {
					return null
				}
			} else if (filterEventType === this.FILTER_EVENT_UPCOMING) {
				if (item?.event?.eventType === "n") {
					// MTVW-631: show items within 7 days, override maxReplayDuration
					return this.isReplayAllowed(item?.event, "P7D") ? item : null
					// MTVW-629: don't filter anymore
					//return item
				} else {
					return null
				}
			} else if (filterEventType === this.FILTER_EVENT_ALL) {
				// MTVW-631: show items within 7 days, override maxReplayDuration
				return this.isReplayAllowed(item?.event, "P7D") ? item : null
				// MTVW-629: don't filter anymore
				//return item
			}
		})
		//result = result.every((element) => element === null) ? null : result
		// MTVW-624: remove 'null' elements and sort
		//console.debug("result=", result)
		if (result) {
			result = result.filter((element) => {
				return element != null
			})
			if (filterEventType === this.FILTER_EVENT_UPCOMING) {
				result = result.sort((a, b) => {
					//console.debug("a %o, b %o", a?.event?.AvailabilityStartTs, b?.event?.AvailabilityStartTs)
					return a?.event?.AvailabilityStartTs - b?.event?.AvailabilityStartTs
				})
			}
			else {
				result = result.sort((a, b) => {
					//console.debug("a %o, b %o", a?.event?.AvailabilityStartTs, b?.event?.AvailabilityStartTs)
					return b?.event?.AvailabilityStartTs - a?.event?.AvailabilityStartTs
				})
			}
		}
		//console.debug("returning result=", result, result.length, filterEventType)
		return result?.length > 0 ? result : null
	}

	getChannelName(channelId) {
		const channel = this.getChannel(channelId)
		if (!channel) return null
		return channel.Name
	}

	getChannelLogo(channelId) {
		const channel = this.getChannel(channelId)
		if (!channel) return null
		// make sure to return secure url
		return urlFixHttpProtocolMismatch(channel.logoUrl)
	}

	setRefresh(value) {
		this.refresh = value
	}

	filterChannels(itemName, item, conditionCallback) {
		let filtered = []
		if (item?.length > 0) {
			filtered = item.filter(i => { return conditionCallback(i)/*this.isChannelSubscribed(i.id)*/ })
		}
		//console.debug(`# search ${itemName}: %s, filtered: %s, unfiltered %o, filtered %o`, item?.length, filtered?.length, item, filtered)
		return filtered
	}

	searchOverview = flow(function* (query) {
		if (this._intervalId) clearInterval(this._intervalId)
		this._intervalId = null
		this.overview = null
		try {
			this.overview = yield rootStore.searchService.overviewAsync(query)
		}
		catch (e) {
			console.error("searchOverview", e)
			return
		}

		// sort descending by eventCount
		//if (this.overview?.top) this.overview.top.sort((a, b) => (a.data.eventCount > b.data.eventCount) ? -1 : ((b.data.eventCount > a.data.eventCount) ? 1 : 0))
		// consider only subscribed channels
		this.overview.top = this.filterChannels("top", this.overview.top, i => this.isSearchMatchSubscribed(i))
		this.overview.series = this.filterChannels("series", this.overview.series, i => this.isOneChannelSubscribed(i.channels))
		this.overview.actors = this.filterChannels("actors", this.overview.actors, i => this.isOneChannelSubscribed(i.channels))

		this.overview.movies = this.filterChannels("movies", this.overview.movies, i => this.isChannelSubscribed(i.event?.channelId))
		this.overview.sports = this.filterChannels("sports", this.overview.sports, i => this.isSearchMatchSubscribed(i))
		//this.overview.programs = this.filterChannels("programs", this.overview.programs, i => this.isChannelSubscribed(i.event?.channelId))
		this.overview.channels = this.filterChannels("channels", this.overview.channels, i => this.isChannelSubscribed(i.id))

		if (this.overview?.top?.length === 0) {
			console.debug("NO TOP RESULTS", this.results)
			//this.overview = {}
		}
		else {
			// generate periodic refresh
			this._intervalId = setInterval(() => {
				this.setRefresh(true)
			}, 60 * 1000)
		}
		// force update by toggling refresh
		this.setRefresh(false)
		this.setRefresh(true)
		console.debug("OVERVIEW %o", this.overview)
	})

	// category = [ CATEGORY_EVENTS, CATEGORY_MOVIES, CATEGORY_SERIES, CATEGORY_EPISODES ]
	search = flow(function* (query, genre = "", actor = "", category = this.CATEGORY_EVENTS) {
		console.debug("query %s, genre %s, actor %s, category %s", query, genre, actor, category)
		//this.lastSearch = query
		if (this._intervalId) clearInterval(this._intervalId)
		this._intervalId = null
		this.results = null
		try {
			this.results = yield rootStore.searchService.searchAsync(query, genre, actor, category)
		}
		catch (e) {
			console.error("search", e)
			return
		}

		// consider only subscribed channels
		this.results = this.filterChannels("simple", this.results, i => this.isChannelSubscribed(i.event?.channelId))

		if (this.results?.length === 0) {
			console.debug("NO SEARCH RESULTS", this.results)
			this.results = []
		}
		else {
			// generate periodic refresh
			this._intervalId = setInterval(() => {
				this.setRefresh(true)
			}, 60 * 1000)
		}
		// force update by toggling refresh
		this.setRefresh(false)
		this.setRefresh(true)
		// Test: call searchOverview separately
		//yield this.searchOverview(query)
		//this.infoGenre(query)
		//this.infoActor(query)
	})

	// basic search
	searchMovies = flow(function* (query, genre = "", actor = "") {
		yield this.search(query, genre, actor, this.CATEGORY_MOVIES)
		//console.debug("Search Movies query %s, genre %s, actor %s, result %o", query, genre, actor, this.results)
	})

	// basic search
	searchSeries = flow(function* (query, genre = "", actor = "") {
		this.setRefresh(true)
		yield this.search(query, genre, actor, this.CATEGORY_SERIES)
		//console.debug("Search Series query %s, genre %s, actor %s, result %o", query, genre, actor, this.results)
	})

	// basic search
	searchEpisodes = flow(function* (query, genre = "", actor = "") {
		yield this.search(query, genre, actor, this.CATEGORY_EPISODES)
		//console.debug("Search Episodes query %s, genre %s, actor %s, result %o", query, genre, actor, this.results)
	})

	// use isSubscribed(channelId) in the UI to filter channels
	infoActor = flow(function* (query, updatePageResult = true) {
		if (updatePageResult) this.actor = null
		let result = null
		try {
			result = yield rootStore.searchService.infoActorAsync(query)
		}
		catch (e) {
			console.error("infoActor", e)
		}

		// filter not subcribed channels
		// if result is null not need to be filtered
		if (result?.contents) {
			result.contents = this.filterChannels("infoActor", result.contents, i => {
				return this.isSearchMatchSubscribed(i)
			})
		}
		if (!updatePageResult) return result
		this.actor = result
		console.debug("actor is", this.actor)
		// TODO: refresh?
		return this.actor
	})

	// use isSubscribed(channelId) in the UI to filter channels
	infoGenre = flow(function* (query, updatePageResult = true) {
		if (updatePageResult) this.genre = null
		let result = null
		try {
			result = yield rootStore.searchService.infoGenreAsync(query)
		}
		catch (e) {
			console.error("infoGenre", e)
		}

		// filter not subcribed channels
		if (result?.contents) {
			result.contents = this.filterChannels("infoGenre", result.contents, i => {
				return this.isSearchMatchSubscribed(i)
			})
		}
		// TODO: refresh?
		if (!updatePageResult) return result
		this.genre = result
		//console.debug("genre is", this.genre)
		return this.genre
	})

	// use isSubscribed(channelId) in the UI to filter channels
	searchActorInGenre = flow(function* (actor, genre, updatePageResult = true) {
		if (updatePageResult) this.actorInGenre = null
		let result = null
		try {
			result = yield rootStore.searchService.searchActorInGenreAsync(actor, genre)
		}
		catch (e) {
			console.error("searchActorInGenre", e)
		}

		if (result?.contents) {
			result.contents = this.filterChannels("infoGenre", result.contents, i => {
				return this.isSearchMatchSubscribed(i)
			})
		}
		// TODO: refresh?
		if (!updatePageResult) return result
		this.actorInGenre = result
		console.debug("actorInGenre is", this.actorInGenre)
		return this.actorInGenre
	})

	// use isSubscribed(channelId) in the UI to filter channels
	searchGenreInGenre = flow(function* (genre, subGenre, updatePageResult = true) {
		if (updatePageResult) this.genreInGenre = null
		let result = null
		try {
			result = yield rootStore.searchService.searchGenreInGenreAsync(genre, subGenre)
		}
		catch (e) {
			console.error("searchGenreInGenre", e)
		}

		if (result?.contents) {
			result.contents = this.filterChannels("infoGenre", result.contents, i => {
				return this.isSearchMatchSubscribed(i)
			})
		}
		// TODO: refresh?
		if (!updatePageResult) return result
		this.genreInGenre = result
		console.debug("genreInGenre is", this.genreInGenre)
		return this.genreInGenre
	})

	// use isSubscribed(channelId) in the UI to filter channels
	getSeries = flow(function* (seriesId) {
		this.series = null
		let result = null
		if (seriesId) result = yield rootStore.epgService.seriesAsync(seriesId)
		// filter not subcribed channels
		let hasEpisodes = false
		if (result?.seasons) {
			result.seasons.forEach(season => {
				const filtered = this.filterChannels("episodes", season.episodes, episode => {
					//console.debug("channel", episode.event.channelId, this.isChannelSubscribed(episode.event.channelId))
					return this.isChannelSubscribed(episode.event.channelId)
				})
				season.episodes = filtered
				hasEpisodes = hasEpisodes || filtered?.length > 0
			})
			if (!hasEpisodes) result.seasons = []
		}
		// TODO: refresh?
		this.series = result
		console.debug("getSeries", this.series)
		return this.series
	})

	removeFromArray(arr, what) {
		//console.debug("arr %o, what %s", arr, what)
		for (let i = 0; i < arr?.length; i++) {
			if (arr[i].toLowerCase() === what.toLowerCase()) {
				//console.debug("remove %o", arr[i])
				arr.splice(i, 1)
				i--
			}
		}
	}

	addToRecentSearch(searchText) {
		//console.debug("searchText", searchText)
		const MAX_RECENTS = 5
		let recent = [...rootStore.sso.browserStore.getRecentSearches()]
		if (!recent) recent = []
		const lValue = searchText
		// MTVW-617: Line below commented. Don't remove lastSearch but only exact match.
		//this.removeFromArray(recent, rootStore.sso.lastSearch)
		if (lValue?.length > 1) this.removeFromArray(recent, lValue.substring(0, lValue.length - 1))
		// remove if already existing, could lead to MAX_RECENTS-1 entries
		this.removeFromArray(recent, lValue)
		// remove oldest at the bottom
		if (recent?.length >= MAX_RECENTS) this.removeFromArray(recent, recent[recent.length - 1])
		// insert at top
		if (lValue?.length > 0) recent.unshift(lValue)
		rootStore.sso.browserStore.setRecentSearches(recent)

		rootStore.sso.lastSearch = searchText
	}

	recentSearches() {
		return rootStore.sso.browserStore.getRecentSearches()
	}

	_initAsync = flow(function* () {
		/*
		yield this.channelListRepo.waitAsync()
		this.channelListRepo.preloadChannelPictures("?mode=box&w=480&h=270")
		*/
	})

	_onMount() {
		// console.debug("search _onMount")
		if (this._intervalId) clearInterval(this._intervalId)
		this._intervalId = null
		this.refesh = false
		this.results = null
	}

	_onUnmount() {
		// console.debug("search _onUnmount")
		if (this._intervalId) clearInterval(this._intervalId)
		this._intervalId = null
		this.refesh = false
		this.results = null
	}

	_init() {
		this._initAsync()
	}
}
