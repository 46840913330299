// l10n status: partial
import { types as t, flow } from "mobx-state-tree"
import { PageAbstractMbx } from "./PageAbstract"
//import { TraxisModel } from "../api/TraxisModel"
import { EventDetailsMixin } from "./mixin/EventDetailsMixin"
import { rootStore } from "store/RootStore"
import { SourceTypeList } from "store/page/player/source/SourceTypeList"
import { LiveTvConfig } from "scenes/LiveTv/LiveTv"
//import { moment } from "store/util/moment"
//import { TIME_FORMAT_BACKEND } from "store/model/Time"
import { logLevel } from "store/qlapi/LogService"
import { computed } from "mobx"
import { cDebug } from "utils/TestSupport"
import { l10n } from "store/lang/L10n"
import Velocity from "velocity-animate"

export const CALLING_CONTEXT_TYPE = {
	TvLive: "TvLive",
	Recording: "Recording",
	TvGuide: "TvGuide",
	TvInfo: "TvInfo",
	RecordingRelatedContent: "RecordingRelatedContent",
	Search: "Search",
	keepOldContext: "keepOldContext"
}

export const OverlayEventDetails = t.compose(
	"OverlayEventDetails",
	PageAbstractMbx,
	EventDetailsMixin,
	t
		.model({
			EventId: t.maybeNull(t.string)
		})
		.volatile(self => ({
			//MTVW-272
			//oEvent: new TraxisModel(),
			oEvent: null,
			isShow: false,
			type: null,
			typeData: null,
			prevId: null,
			context: CALLING_CONTEXT_TYPE.TvLive, //MTV-2940 TvLive, TvGuide, Recording, RelatedContent
			adSkipInfo: null,
			_adSkipInfoQueue: [],
			_recMarker: null,
			_markerFetched: false,
			//MTVW-490 added componentType,stackComponents
			componentType: null,
			idSerie: null,
			actorName: null,
			genre: null,
			subItem: null,
			stackComponents: [],
			_isPlayerPresent: false,
			// MTVW-624: autoplay support
			autoplay: false,
			// MTVW-624: combined activityStack for related content and search
			activityStack: [],
			activityBackItem: null,
			// MTVVW-708
			playEventStarted: false
		}))
		.views(self => ({
			//MTVW-272
			get isReady() {
				return self.oEvent && !self.oEvent?.error ? true : false
			},
			get isPlayerPresent() {
				return self._isPlayerPresent
			},
			get isVisible() {
				return self.isMounted && self.isShow
			},
			//MTV-2940 section where the relatedContent is open
			get callingContext() {
				return self.context
			},
			get getComponentType() {
				return self.componentType
			},
			get getIdSeries() {
				return self.idSerie
			},
			get getActorName() {
				return self.actorName
			},
			get getGenre() {
				return self.genre
			},
			get getSubItem() {
				return self.subItem
			},
			get event() {
				return self.oEvent /*&& !rootStore.page.Player.isInLiveTv*/ ? self.oEvent : rootStore.page.Player?.source?.Event
			},
			get isPlayButtonEnabled() {
				// MTV-1630: No play icon for channels without replay
				// condition for "ContentPlaylist" that has been recorded from a disabled replay channel
				// Need to call CreateRollingBufferPlaylist to detect "Replay Funtion für den gewünschten Zeitraum nicht verfügbar" ??
				/* MTVW-272
				return ((page.oEvent.Data.getCurrentEventType !== "n")
					? ((((page.oEvent.Data.getCurrentEventType === "p") &&
						!page?.oEvent?.Data?.Channel?.IsOnRecordingOptinList &&
						page?.type !== "ContentPlaylist")
						? false
						:
						//MTV2550
						// in this case should be "c" -> current
						// and check if is in LiveTv section and the event is Live the button it will not visible 
						(rootStore.page.Player.source.Event?.id === page.oEvent.Data?.id && rootStore.page.LiveTv.isMounted) ? false : true
					))
					: false)
					*/
				//console.debug("curType %s, type %s, srcId %s, pgId %s. recOptIn %s ", page.oEvent.getCurrentEventType, page?.type, rootStore.page.Player.source.Event?.id, page.oEvent.EventId, rootStore.page.MsEpg.getChannel(page.oEvent?.ChannelId).IsOnRecordingOptinList)
				// GT12: MTVW-460, MTVW-502: Channels MS flags
				//const isReplayAllowed = rootStore.page.MsEpg?.getChannel(self.oEvent?.ChannelId)?.hasReplay || self.type === "ContentPlaylist"
				let isReplayAllowed = computed(() => self.callingContext === CALLING_CONTEXT_TYPE.Recording || rootStore.page.MsEpg?.isReplayAllowed(self.oEvent?.ChannelId, self.oEvent?.AvailabilityStartDateTs, self.oEvent?.AvailabilityEndDateTs, self.type === "ContentPlaylist")).get()
				// MTVW-508
				if (!isReplayAllowed) {
					// recording outside of replay window
					if (self.getMarker()?.isComplete) isReplayAllowed = true
				}
				return ((self.oEvent?.getCurrentEventType !== "n")
					? ((((self.oEvent?.getCurrentEventType === "p") &&
						// GT12
						//!channel?.IsOnRecordingOptinList && self.type !== "ContentPlaylist")
						!isReplayAllowed)
						? false
						:
						// MTV-2550
						// in this case should be "c" -> current
						// and check if is in LiveTv section and the event is Live the button it will not visible 
						//(rootStore.page.Player.source.Event?.id === page.oEvent.Data?.id && rootStore.page.LiveTv.isMounted) ? false : true
						// MTVW-629: added self.context === CALLING_CONTEXT_TYPE.TvLive
						(rootStore.page.Player.source?.Event?.id === self.oEvent?.EventId && rootStore.page.LiveTv?.isMounted && self.context === CALLING_CONTEXT_TYPE.TvLive) ? false : true
					))
					: false)
			}
		}))
		.actions(self => ({
			// MTVW-708
			setPlayEventStarted(value) {
				self.playEventStarted = value
			},
			_afterCreate() {
			},
			_afterCreateAsync: flow(function* () {
				yield self._root.page.ChannelListRepo.waitAsync()
			}),
			setShow(bShow = null, context = CALLING_CONTEXT_TYPE.keepOldContext) {
				if (context !== CALLING_CONTEXT_TYPE.keepOldContext) {
					self.context = context
				}

				self.isShow = bShow === null ? !self.isShow : bShow
				// MTV-1774 reset error when not showing
				if (!bShow) { /*console.debug("reset ERROR");*/

					rootStore.page.Player.error = null
					// MTVW-186 MTVW-178

					if (self.oEvent?.error) {
						self.prevId = null
						self.oEvent.error = null
					}
					// MTVW-808: make sure that genres and actors are loaded when switching LiveTv <-> TVGuide
					self.setGenre(null)
					self.setActorName(null)

				}

				return self
			},
			setComponentType(componentType) {
				self.componentType = componentType
				//console.debug("test>>>>>>> set new componet", self.component)
				return self
			},
			setIsPlayerPresent(newValue) {
				self._isPlayerPresent = newValue
				return self
			},
			setTypeContentPlaylist(contentId) {
				self.type = SourceTypeList.ContentPlaylist
				self.typeData = contentId
				return self
			},
			setCallingContext(context) {
				self.context = context
				return self
			},
			/*
			_setEvent(event) {
				self.oEvent = event
			},
			*/
			getMarker() {
				if (!self._markerFetched) {
					const event = self.event
					self._recMarker = rootStore.page.Recordings.Manager.getMarker(event?.id)
				}
				self._markerFetched = true
				return self._recMarker
			},
			getRecording: flow(function* (markerId) {
				const recs = rootStore.api.GetRecordings({ ...{ isRecorded: true, isPlanned: true, iLimit: 2000, isChildRecordings: true, isSortDesc: false }, ...{ Ids: markerId } })
				yield recs.fetchDataAsync()
				return recs
			}),
			_getEventDetails: flow(function* (id) {
				//MTVW-272
				// avoid displaying the previous (old) content
				self.oEvent = null
				self.oEvent = yield rootStore.page.MsEpg.getEventDetailsAsync(id)
				//console.debug("_getEventDetails", self.oEvent)

				// GT12: MTVW-460, MTVW-502: Channels MS flags
				//const isReplayAllowed = rootStore.page.MsEpg?.getChannel(self.oEvent?.ChannelId)?.hasReplay || self.type === "ContentPlaylist"
				const isReplayAllowed = computed(() => self.callingContext === CALLING_CONTEXT_TYPE.Recording || rootStore.page.MsEpg?.isReplayAllowed(self.oEvent?.ChannelId, self.oEvent?.AvailabilityStartDateTs, self.oEvent?.AvailabilityEndDateTs, self.type === "ContentPlaylist")).get()
				// MTVW-508
				if (!isReplayAllowed) {
					const marker = self.getMarker()
					if (marker?.isComplete) {
						const recs = yield self.getRecording(marker.id)
						self.oEvent = yield rootStore.page.MsEpg.getEventDetailsAsync(recs?.DataMain?.EventId)
						self.prevId = recs.DataMain.EventId
						self.setTypeContentPlaylist(marker.id)
					}
				}

				self.getAdSkipInfo()
				/*
				yield rootStore.page.MsEpg.getEventDetailsAsync(id).then((result) => {
					self._setEvent(result)
				})
				*/
				//console.debug("DETAILS %o", self.oEvent)
				// MTVW-624: autoplay support
				if (self.autoplay) {
					// don't use yield
					/*yield*/ self.setPlayEvent()
					rootStore.page.LiveTv.memoSelectedEpg = null //MTV-3740 restore the snapshot for the channelList used in EpgChannel.js
					console.debug("autoplay isInLiveTv", rootStore.page.Player.isInLiveTv /*rootStore.page.Player.playerLiveTvVo.isPlayerActive*/)
					// MTVW-727b: use isInLiveTv
					if (!rootStore.page.Player.isInLiveTv /*rootStore.page.Player.playerLiveTvVo.isPlayerActive === false*/) {
						self.setIsPlayerPresent(true)
					}
					/*else {
						self.setShow(false)
						rootStore.page.MiniEpg.setShow(false)
						self.resetActivity()
						historyRouteAdd(ROUTE_NAMES.LiveTv)
					}
					*/
					// MTVW-629: close overlay if liveTv
					else {
						self.setShow(false)
						setTimeout(() => {
							//window.scrollTo(0, 0)
							const elem = document.getElementById("tvLiveAndDetailsContainer")
							if (elem) elem.scrollTop = 0
						}, 100)
					}
				}
			}),
			// MTVW-624: autoplay support
			//setEpgEventById(id, autoplay = false) {
			setEpgEventById: flow(function* (id, autoplay = false) {
				// MTV-1690: TV Guide: Clicking on the same program entry multiple times shows the overlay only at the first click
				//console.debug("setEpgEventById %s %o", self.prevId !== id, id)
				if (!id) return
				self.autoplay = autoplay
				rootStore.page.Player.source.trueReplaySession = false // reset for adSkipInfo
				const mtv1690Workaround = false
				// MTVW-508: disable MTV-1690 to properly start recording or replay if the show is the same
				if (!mtv1690Workaround || self.prevId !== id) {
					self.type = null
					self.typeData = null
					self._markerFetched = false
					// MTVW-246
					/* MTVW-272
					self.oEvent = self._root.api
						.GetEventById({ _idCache: "OverlayEventDetails", EventId: id })
						.abortReq()
						.fetchData()
					*/
					//console.debug("calling _getEventDetails")
					yield self._getEventDetails(id)
					self.prevId = id
				}
				else self.getAdSkipInfo()
				console.log("setEpgEventById with id = %s", id)
				//return self
			}),
			//setPlayEvent(startFullScreen = false) {
			setPlayEvent: flow(function* (startFullScreen = false, selectedType = "") {
				let type = self.type !== null ? self.type : self.oEvent?.getCurrentEventType === "c" ? SourceTypeList.ChannelLive : SourceTypeList.ChannelReplay
				// MTVW-811
				if (selectedType?.length > 0) {
					type = selectedType === "c" ? SourceTypeList.ChannelLive : SourceTypeList.ChannelReplay
					self.type = type
				}
				const player = rootStore.page.Player
				//console.debug("player is", player, player.player.__path)
				// MTVVW-727b
				const isInLiveTv = player.isInLiveTv
				console.debug("setPlayEvent ctx %s, type %s, %s, playerLiveTvVo inPip %s, liveTv %s", self.callingContext, type, self.type, player.playerLiveTvVo.isInPip, isInLiveTv)
				//if (player.playerLiveTvVo.isPlayerActive && player.playerLiveTvVo.isInPip && self.callingContext !== CALLING_CONTEXT_TYPE.TvLive) {
				if (/*player.playerLiveTvVo.isPlayerActive &&*/ player.playerLiveTvVo.isInPip && !isInLiveTv) {
					console.debug("setPlayEvent stop TvLive")
					player.setPiPPlayer(null)
					// MTVW-795: Don't use stopInstance, otherwise player.player below will return playerLiveTvVo!
					//yield player.playerLiveTvVo.stopInstance()
					yield player.playerLiveTvVo.setRefElemContainer(null, true)
					//console.debug("setPlayEvent=", player.player, document.getElementById(player.playerDetailPageVo.idDomElement))
					self.setShow(true)
				}
				// MTVW-708:
				self.setPlayEventStarted(true)
				//MTVW-272
				//const type = self.type !== null ? self.type : self.oEvent.Data.getCurrentEventType === "c" ? SourceTypeList.ChannelLive : SourceTypeList.ChannelReplay

				if (type === SourceTypeList.ContentPlaylist) {
					/* MTVW-272
					player.setPlayContentAsync(self.typeData, () => rootStore.api.GetEventById({ EventId: self.oEvent.Data.id }), true)
					*/
					yield player.player.stopInstance()
					yield player.setPlayContentAsync(self.typeData, self.oEvent, true)
				} else if (type === SourceTypeList.ChannelLive) {
					// MTVW-213: unpause player, otherwise it would would move to a rolling buffer.
					// The issue was caused by useEffect of scenes\Player\Player.js in the
					// statement: if (page.player.isPausedUser && (page.player.inactiveReason === null)) { page.source.setPlayPause() }
					// useEffect should be re-analyzed. Fix it here for now to minimize potential side effects.
					player.player.setIsPausedUser(false)
					/* MTVW-272
					rootStore.page.LiveTv.setEpgListPos(self.oEvent.Data.Channel.id, LiveTvConfig.cellHeight + LiveTvConfig.cellMarginBottom)
					yield player.setPlayChannelLiveAsync(self.oEvent.Data.Channel.id, () => rootStore.api.GetEventById({ EventId: self.oEvent.Data.id }), true)
					*/
					rootStore.page.LiveTv.setEpgListPos(self.oEvent?.ChannelId, LiveTvConfig.cellHeight + LiveTvConfig.cellMarginBottom)
					yield player.player.stopInstance()
					yield player.setPlayChannelLiveAsync(self.oEvent?.ChannelId, self.oEvent, true)
				} else if (type === SourceTypeList.ChannelReplay) {
					// MTV-1607
					/* MTVW-272
					rootStore.page.ChannelListRepo.setActiveChannel(self.oEvent.Data.Channel.id)

					rootStore.page.LiveTv.setEpgListPos(self.oEvent.Data.Channel.id, LiveTvConfig.cellHeight + LiveTvConfig.cellMarginBottom)
					player.setPlayChannelReplayAsync(self.oEvent.Data.Channel.id, self.oEvent.Data.AvailabilityStart, () => rootStore.api.GetEventById({ EventId: self.oEvent.Data.id }), true)
					*/
					rootStore.page.ChannelListRepo.setActiveChannel(self.oEvent?.ChannelId)

					rootStore.page.LiveTv.setEpgListPos(self.oEvent?.ChannelId, LiveTvConfig.cellHeight + LiveTvConfig.cellMarginBottom)
					/* MTVW-272
					player.setPlayChannelReplayAsync(self.oEvent?.ChannelId, self.oEvent.AvailabilityStart, () => rootStore.api.GetEventById({ EventId: self.oEvent.Data.id }), true)
					*/
					yield player.player.stopInstance()
					yield player.setPlayChannelReplayAsync(self.oEvent?.ChannelId, self.oEvent.AvailabilityStartDate, self.oEvent, true, true)
				}
				if (player.isPlayerOnOverlay === true) {
					player.player.setFullScreen(startFullScreen)
				}
			}),
			setIdSerie(newValue, subItem = null) {
				self.idSerie = newValue
				self.subItem = subItem
				return self
			},
			setActorName(newValue, subItem = null) {
				self.actorName = newValue
				self.subItem = subItem
				return self
			},
			setGenre(newValue, subItem = null) {
				self.genre = newValue
				self.subItem = subItem
				return self
			},
			setSubItem(newValue) {
				self.subItem = newValue
				return self
			},
			// MTVW-624: combined activityStack for related content and search
			pushActivity(item) {
				const playerRect = document.getElementById("ql_player_container")?.getBoundingClientRect() ?? null
				const metaRect = document.getElementById("coverMetadataContainer")?.getBoundingClientRect() ?? null
				const navbarRect = document.getElementById("navbarContainer")?.getBoundingClientRect() ?? null
				const elem = document.getElementsByClassName("MuiDialog-container")
				// adjustment when player is active and the title is below the cover,
				// the overlay layout changes since the player is stopped
				const adjustMent = !rootStore.page.Player.playerLiveTvVo.isPlayerActive && navbarRect?.width > 800 && metaRect?.bottom > playerRect?.bottom ? metaRect?.height : 0
				console.debug("pushActivity rPlayer %o, rMeta %o, navbar %o, top %s, adjust %s", playerRect, metaRect, navbarRect, elem[0]?.scrollTop, adjustMent)
				if (elem?.length > 0) item.scrollTop = elem[0].scrollTop - adjustMent
				if (item?.type === 'related') {
					// load the stack content title
					// do not add if title already exists
					const duplicate = self.activityStack.filter(i => i?.type === 'related' && i?.id === item?.id)
					console.debug("duplicate", duplicate, duplicate.length)
					if (duplicate.length === 0) {
						self.activityStack.push(item)
					}
					self.showPreviousContent = self.activityStack.filter(i => i?.type === 'related') > 0 ? true : false
				}
				else self.activityStack.push(item)
				console.debug("pushActivity", self.activityStack)
			},
			popActivity() {
				let result = null
				if (self.activityStack.length > 0) result = self.activityStack.pop()
				console.debug("popActivity", result, self.activityStack)
				return result
			},
			resetActivity() {
				self.activityStack = []
				console.debug("resetActivity")
			},
			restoreActivity() {
				const item = self.popActivity()
				// MTVW-629: added .setComponentType('event').setShow(true, CALLING_CONTEXT_TYPE.TvGuide)
				if (item?.type === 'related') {
					// MTVW-629: setIsPlayerPresent(false) so that the BoxCover is shown
					self.setIsPlayerPresent(false)
					self.setEpgEventById(item?.id).then(res => {
						self.setComponentType('event').setShow(true, CALLING_CONTEXT_TYPE.TvGuide)
						if (item?.scrollTop) {
							const elem = document.getElementsByClassName("MuiDialog-container")
							if (elem?.length > 0) {
								console.debug("restoreActivity top", item?.scrollTop, elem)
								//https://easings.net/
								const easingOption = "easeInOutExpo"
								Velocity(elem[0], { scrollTop: item.scrollTop + "px" }, {
									duration: 1200,	// value increased from 1000 to 1200 for iPad
									easing: easingOption,
									complete: function (val) {
										//if (onComplete) onComplete()
									}
								})
							}
						}
					})
				}
				else self.setActivityBackItem(item)
				//console.debug("restoreActivity backItem", self.activityBackItem, item, item?.type)
			},
			setActivityBackItem(item) {
				self.activityBackItem = item
			},

			_enqueueAdSkipInfoRequest(fn) {
				self._adSkipInfoQueue.push(fn)
				if (self._adSkipInfoQueue?.length === 1) self._dequeueAdSkipInfoRequest()
			},
			_dequeueAdSkipInfoRequest() {
				//console.debug("_dequeueAdSkipInfoRequest %s", self._adSkipInfoQueue?.length)
				if (self._adSkipInfoQueue?.length > 0) {
					const fn = self._adSkipInfoQueue[0]
					if (fn) {
						//console.debug("dayTs", moment(dayTs).toString())
						fn().then(() => {
							self._adSkipInfoQueue.shift()
							self._dequeueAdSkipInfoRequest()
						})
					}
				}
			},
			getAdSkipInfo() {
				// use queue to synchronize multiple calls (start next call when previous call has been completed)
				self._enqueueAdSkipInfoRequest(self._getAdSkipInfo)
			},
			_getAdSkipInfo: flow(function* () {
				self.adSkipInfo = null
				yield rootStore.page.ChannelListRepo.waitAsync()
				const event = self.event
				const source = rootStore.page.Player?.source

				cDebug("overlay _getAdSkipInfo %o, %o, %o, %o, %o, %o", self.context, event?.getCurrentEventType, source?.type, event, source?.trueReplaySession, source?.sessionAdZones)

				//console.debug("_getAdSkipInfo %o, %o, %s", source, event, event?.getCurrentEventType)
				//if (((source?.type === SourceTypeList.ChannelReplay || source?.type === SourceTypeList.ContentPlaylist) && source?.trueReplaySession)
				//	|| (self.isPlayButtonEnabled && event?.getCurrentEventType !== "c") /*|| source?.sessionAdZones?.adZones?.length > 0*/) {

				// MTVW-431: show indicator / button also in live
				//if (source.isGt12Channel(event?.ChannelId) && (event?.getCurrentEventType === "p" || source?.trueReplaySession)) {
				if (source.isGt12Channel(event?.ChannelId)) {
					try {
						/* MTVW-431: no exemption anymore
						const marker = self.getMarker()
						let recInfo = null
						if (marker?.isComplete) {
							const recs = yield self.getRecording(marker.id)
							//console.debug("RECORDINGS %o, %o, %o", recs, recs.DataMain.CreateTime, moment(recs.DataMain.CreateTime).utc().valueOf())
							recInfo = { recordingId: marker.id, isSeries: marker.isSeries, bookingTime: moment(recs.DataMain.CreateTime).utc().valueOf() }
						}
						// TODO: check timing issue (?) with recording Navy CIS: L.A.
						console.debug("marker %o, recInfo %o", marker, recInfo)
						*/

						/* MTVW-431: Derive information from channels MS
						const channel = rootStore.page.MsEpg.getChannel(event?.ChannelId)
						const result = yield rootStore.adsService.adSkipInfoAsync(event?.ChannelId, recInfo?.recordingId, event?.AvailabilityStartDateTs, recInfo?.isSeries, recInfo?.bookingTime, moment.duration(channel.maxReplayDuration, moment.ISO_8601).valueOf(), false)
						*/
						const result = {}
						result.adSkipping = source.isPremium(event?.ChannelId) ? "allowed" : "notAllowed"
						// MTVW-808: liveTv / overlay decoupling, add channelId to result
						result.channelId = event?.ChannelId

						// MTVW-459
						// Exemption:
						// Not subscribed to Replay Premium but the program is exempt from alternative ads
						// GT12 Channel for a user with "Replay Premium" where this content is outside the replay window or
						// is a pre-scheduled single recording. Treated like a non-GT12 channel.
						if (result?.adSkipping === "notAllowed") {
							result.adButtonTitle = l10n.featureUpsellGt12Title
							// text with newline(s)
							result.adButtonText = l10n.featureUpsellGt12Description.replaceAll("\\n", "\n")
						}
						else if (result?.adSkipping === "allowed") {
							result.adButtonTitle = null
							result.adButtonText = null
						}
						/* MTVW-431> no exepmption anymore
						if (recInfo) {
							console.debug("recinfo %o, booked %s, isSeries %s, MS: %s", recInfo, moment(recInfo.bookingTime).format(TIME_FORMAT_BACKEND), recInfo.isSeries, result.adSkipping)
						}
						if (recInfo && result?.adSkipping === "notAllowed") {
							const daysAired = moment().utc().diff(moment(event?.AvailabilityStartDate).utc(), 'days')
							const bookingBeforeAired = recInfo.bookingTime < moment(event?.AvailabilityStartDate).utc().valueOf()
							console.debug("days since aired %s, recorded before aired %s", daysAired, bookingBeforeAired)
							if (recInfo.isSeries && daysAired >= 7) {
								result.adSkipping = "unavailable"
							}
							if (!recInfo.isSeries && bookingBeforeAired) {
								result.adSkipping = "unavailable"
							}
						}
						*/
						self.adSkipInfo = result
					}
					catch (e) {
						console.error("CAUGHT in _getAdSkipInfo %", e)
						rootStore.logService.logAsync(logLevel.ERROR, "_getAdSkipInfo", "OverlayEventDetails", e)
						self.adSkipInfo = null
					}
				}
				else {
					self.adSkipInfo = null
				}
				//console.debug("overlay %o, %o, %o, %o, %o, %o, %o", self.context, event, event?.getCurrentEventType, source?.type, self.oEvent, source?.trueReplaySession, source?.sessionAdZones)
				//console.debug("return adSkipInfo %o", self.adSkipInfo ? self.adSkipInfo : "NO adSkipInfo")
			})
		}))
)
