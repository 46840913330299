/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-09
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-11
 */

import { TraxisAbstract } from "store/api/TraxisAbstract"
import { TraxisConditionAnd } from "store/api/TraxisCondition"
import { Channels } from "store/model/traxis/Channel"

const GetChannelsModel = Channels.named("GetChannelsModel")
/* MTV-2654: Disable channel exclusion list 
const excludedChannels = [
	"2315", "2451", "2452", "2454", "2455", "2458", "2888", "2880", "2934", "2932",
	"2935", "2936", "2366", "2421", "2364", "2359", "2369", "2370", "2350", "2355",
	"2375", "2357", "2345", "2349", "2342", "2344", "2346", "2409", "2411", "2407",
	"2405", "2418", "2415", "2403", "2404", "2459", "2477", "2468", "2469", "2470",
	"2919", "2780", "2782", "2784", "2786", "2787", "2788", "2794", "2795", "2796",
	"2791", "2824", "2792", "2793", "2809", "2804", "2808", "2806", "2816", "2826",
	"2820", "2822", "2707", "2708", "2715", "2713", "2742", "3094", "3116", "3873",
	"3875", "3879", "3877", "3884", "3885", "3887", "3993", "3995", "3446", "3760",
	"3762", "3782", "3788", "2334", "2398"]
*/
const excludedChannels = []

export class GetChannels extends TraxisAbstract {
	//_onStartCreate = true
	//_onStartRemove = true

	get _model() {
		return GetChannelsModel
	}

	_getHashReq(data) {
		return `GetChannels_${this.profileId}`
	}

	_getParseResp(data) {
		const filtered = data.Channels.Channel.filter(i => { return excludedChannels.indexOf(i.id) === -1 })
		/*
		console.debug("FILTERED")
		filtered.map(i => console.debug(i.Name))

		console.debug("UNFILTERED")
		data.Channels.Channel.map(i => console.debug(i.Name))
		*/
		data.Channels.Channel = filtered
		data.Channels.resultCount = filtered?.length

		return data.Channels
	}

	getXmlReq() {
		const filterChannels = TraxisConditionAnd()
			.setAsFilterOption()
			.add("IsAudioOnly==false")
			.add("!isnull(LogicalChannelNumber)")
			.add("IsAdult==false")

		return `<?xml version="1.0" encoding="utf-8"?>
<Request xmlns="urn:eventis:traxisweb:1.0">
	<Identity>
		<ProfileId>${this.profileId}</ProfileId>
	</Identity>
	<RootRelationQuery relationName="Channels">
		<Options>
			<Option type="Props">Name,IsAdult,NetworkRecordingEncodingProfiles,IsNetworkRecordingAllowed,Blocked,IsPersonalBlockedChannel,LogicalChannelNumber,Pictures,TstvEventCount,IsViewableOnCpe,IsOnRecordingOptinList,CustomProperties,NetworkRecordingEntitlementState,IsHD</Option>
			${filterChannels}
			<Option type="Sort">LogicalChannelNumber</Option>
		</Options>
		<SubQueries>
			<SubQuery relationName="Products">
				<Options>
					${/*
					<Option type="Props">Type,Aliases,Pictures,Name,Currency,ListPrice,OfferPrice,EntitlementState,EntitlementEnd,RentalPeriod</Option>
					*/ ""}
					${/* MTV-2654: Activate filter to show only subscribed channels */ ""}
					<Option type="Props">Type,Name,EntitlementState</Option>
					<Option type="Filter"><![CDATA[EntitlementState==Entitled]]></Option>
					${/* */ ""}
				</Options>
			</SubQuery>
			<SubQuery relationName="RollingBuffers">
				<Options>
					<Option type="Filter"><![CDATA[IsViewableOnCpe==true&&Products>0]]></Option>
				</Options>
				<SubQueries>
					<SubQuery relationName="Products">
						<Options>
							<Option type="Sort">~RelationDurationIntoPast</Option>
							<Option type="Props">RelationDurationIntoPast</Option>
							${/* MTV-2654: Activate filter to show only subscribed channels */ ""} 
							<Option type="Filter"><![CDATA[EntitlementState==Entitled]]></Option>
							${/* */ ""}
							<Option type="Limit">1</Option>
						</Options>
					</SubQuery>
				</SubQueries>
			</SubQuery>
		</SubQueries>
	</RootRelationQuery>
</Request>`
	}
}
