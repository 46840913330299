import { action, flow, observable, runInAction, computed, makeObservable } from "mobx";
import { ClassAbstract } from "store/ClassTools"
import { Math_floor } from "store/util/Math"
import { moment } from "store/util/moment"

// Date: Wed, 07 Nov 2018 09:35:03 GMT
export const TIME_FORMAT_HTTP_HEADER = "ddd, DD MMM YYYY HH:mm:ss"

export const TIME_FORMAT_BACKEND = "YYYY-MM-DD[T]HH:mm:ss[Z]"

export class Time extends ClassAbstract {
	_oTicks = new Map();
	_idTimerTick = null

	iTimer = null
	iTimeDiff = 0
	bTimeDiff = false
	bServerTime = false
	oTimeStamp = null

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			_oTicks: observable,
			setDatetimeFromHttpHeader: action,
			setDatetime: action,
			_checkDatetimeFromObject: action,
			handleSyncDateFromServerAsync: action,
			getTimeStampTick: observable,
			getTimeFormatBackendTick: observable,
			getTimeTick: observable
		})

		this.iTimer = window.performance.now()
		this.oTimeStamp = moment().valueOf()
		this._idTimerTick = setInterval(() => this._checkTicks(), 1000)
	}

	_getTick(sec = 10) {
		console.log("@(sec=%o)", sec)
		if (!this._oTicks.has(sec)) {
			this._oTicks.set(sec, this.getTimeStampPrecision(sec * 1000))
		}
		return this._oTicks.get(sec)
	}

	_checkTicks() {
		let tUpdates = []
		// cache what change and run when needed
		this._oTicks.forEach((time, sec) => {
			const timeCurr = this.getTimeStampPrecision(sec * 1000)
			if (time !== timeCurr) {
				// MTVW-323: mobile devices (e.g. iPhone) in the background will encounter a shift
				// because they might go to sleep and this method call is suspended!
				//tUpdates.push({ time: timeCurr, sec })
				this.oTimeStamp = moment().valueOf()
				this.iTimer = window.performance.now()
				tUpdates.push({ time: timeCurr, sec })
				/*
				console.debug("timeCurr %s", moment(timeCurr).format("HH:mm"))
				console.debug("oTimeStamp %s, %s, perf %s, iTimer %s, diff %s", this.oTimeStamp, moment(this.oTimeStamp).format("HH:mm"),
					window.performance.now(), this.iTimer, this.iTimeDiff)
				*/
			}
		})
		// apply updates
		if (tUpdates?.length) {
			// MTV-3583: mobx 6 removed 'name' in 'runInAction' parameters
			runInAction(/*import.meta.env.MODE !== "production" ? "Time._checkTicks_runInAction timers - " + tUpdates.map(i => `${i.sec}s`).join(", ") : "",*/() => {
				tUpdates.forEach(i => {
					//console.debug("UPD TIME %s, %s", i.sec, moment(i.time).format("HH:mm"))
					this._oTicks.set(i.sec, i.time)
				})
			})
		}
	}

	setDateFromServerAsync = flow(function* () {
		console.log("@")
		try {
			//const { headers } = yield this._root.api.GetCategorySoftlinks({ _idCache: "MainSoftLinks" }, false).fetchDataAsync()
			const rsp = yield this._root.api.GetCategorySoftlinks({ _idCache: "MainSoftLinks" }, false).fetchDataAsync()
			console.debug("@@setDateFromServerAsync headers=%o", rsp?.headers, rsp)
			if (rsp?.headers?.date) this.setDatetimeFromHttpHeader(rsp?.headers?.date)
		} catch (e) {
			console.error(e)
		}
		return this
	})

	setDatetimeFromHttpHeader(dateHeader) {
		console.log("@(dateHeader=%o)", dateHeader)
		if (this._checkDatetimeFromObject(moment.utc(dateHeader, TIME_FORMAT_HTTP_HEADER, "en"))) {
			this.bServerTime = true
			this.bTimeDiff = true
			this._checkTicks()
		}
		return this
	}

	setDatetime(dateTime) {
		console.log("@(dateTime=%o)", dateTime)
		if (this._checkDatetimeFromObject(moment.utc(dateTime))) {
			this.bServerTime = false
			this.bTimeDiff = true
			this._checkTicks()
		}
		return this
	}

	_checkDatetimeFromObject(oMoment) {
		if (oMoment.isValid()) {
			const iDiff = Math.floor(
				oMoment
					.local()
					.add(this.iTimeDiff, "seconds")
					.diff(this.getTime(), "seconds")
			)
			console.log("@(oMoment=%o) .getTime()=%o iDiff=%o", oMoment, this.getTime(), iDiff)
			if (this.bServerTime === false) {
				this.iTimeDiff = iDiff
				return true
				// change diff only if there will be 15s diff
			} else if (iDiff < this.iTimeDiff - 15 || iDiff > this.iTimeDiff + 15) {
				this.iTimeDiff = iDiff
				return true
			}
			this._checkTicks()
		}
		return false
	}

	handleSyncDateFromServerAsync = flow(function* () {
		console.info("@")
		if (import.meta.env.VITE_CONF_SYNC_INIT_DATE === "true") {
			yield this.setDateFromServerAsync()
		} else {
			this.bServerTime = true
		}
	})

	getTimeStamp() {
		//console.debug("oTimeStamp %s, %s, perf %s, iTimer %s, diff %s", this.oTimeStamp, moment(this.oTimeStamp).format("HH:mm"),
		//	window.performance.now(), this.iTimer, this.iTimeDiff)
		return Math.floor(this.oTimeStamp + window.performance.now() - this.iTimer + this.iTimeDiff * 1000)
	}

	getUnixTimeStamp() {
		return Math.floor(this.getTimeStamp() / 1000)
	}

	getTimeStampPrecision(iPrecision) {
		return !iPrecision ? this.getTimeStamp() : parseInt(Math_floor(this.getTimeStamp(), iPrecision), 10)
	}

	getTimeStampTick(sec, bObject = false) {
		console.log("@(sec=%o,bObject=%o)", sec, bObject)
		if (bObject === true) {
			return computed(() => this._getTick(sec))
		}
		return this._getTick(sec)
	}

	getTimeFormatBackendTick(sec, callback = null) {
		console.log("@(sec=%o,callback=%o)", sec, callback)
		if (callback !== null) {
			return callback(moment(this.getTimeStampTick(sec)).utc()).format(TIME_FORMAT_BACKEND)
		}
		return moment(this.getTimeStampTick(sec))
			.utc()
			.format(TIME_FORMAT_BACKEND)
	}

	getTime() {
		return moment(this.getTimeStamp())
	}

	getTimeTick(sec) {
		console.log("@(sec=%o)", sec)
		return moment(this.getTimeStampTick(sec))
	}

	getTimeFormatBackend() {
		console.log("@()")
		return this.getTime()
			.utc()
			.format(TIME_FORMAT_BACKEND)
	}

	getTimeUser(time) {
		console.log("@(time=%o)", time)
		return moment(time).subtract(this.iTimeDiff, "seconds")
	}
}
