import { Grid } from "@mui/material"
import { Typography } from "@mui/material"
//import HdIcon from "img/tv_hd.svg"
import React, { useEffect } from "react"
//import { rootStore } from "store/RootStore"
import { Img } from "components/Img"
import fallback from "img/fallback/empty.png"
import { alpha } from "@mui/material/styles"
import { CleConfig } from "scenes/ChannelListEditor/ChannelListEditor"
import { useDrag } from 'react-dnd'
//import CustomDragLayer from "./CustomDragLayer"
import { CreateComponent } from "components/utils/CreateComponent"
import scrollHelper from "./ScrollHelper"
import { logResourceLoadFailure } from "utils/BugsnagReporter"
import { theme as _theme } from "../../site-ql/theme"

const styles = theme => ({
	CleGridItem: {
		marginBottom: 11,
		marginLeft: 2, // consider border for selected item
		marginRight: 6,
		width: CleConfig.gridCellWidth,
		float: "left",
		cursor: "pointer",
		position: "relative",
		border: "2px solid transparent",
		"&:hover $CleGridTextWrapper": {
			backgroundColor: "#585858"
		},
		// disable long touch
		"-webkit-touch-callout": "none !important"
		// "&:hover ": {
		// 	transform: "scale(1.1)",
		// }

	},
	CleChannelLogoWrapper: {
		height: CleConfig.gridCellHeigth / 2,
		alignItems: "center",
		display: "flex",
		cursor: "pointer",
		backgroundColor: alpha(theme.palette.secondary[700], 0.8)
	},
	CleChannelLogo: {
		width: CleConfig.gridLogoWidth,
		marginLeft: "auto",
		marginRight: "auto",
		opacity: 0.7,
	},
	CleGridTextWrapper: {
		//padding: "5px 5px 15px",
		//backgroundColor: theme.palette.secondary.main,
		display: "flex",
		flexDirection: "column",
		paddingTop: 10,
		backgroundColor: "#2A2A2A",
		// MTVW-464: No HD badge
		//height: CleConfig.gridCellHeigth / 2,
		height: CleConfig.gridCellHeigth / 3,
		alignItems: "center",
		textAlign: "center",
		padding: "0px 10px 0px 10px",
		// MTVW-464: No HD badge
		//justifyContent: "space-between"
		justifyContent: "center"
	},
	CleGridChannel: {
		marginTop: 0,
		//paddingTop: 10,
		paddingBottom: 10,
	},
	CleIcon: {
		//display: "flex",
		display: "inline-block", // for scaling on zoom
		overflow: "hidden", // for scaling on zoom
		paddingBottom: 10,
		minWidth: 22,
		//order: 1,
		//flex: "0 1 auto",
	},
	// copy needed for DndPreview
	CleGridWrapper: { position: "relative" },
	CleGridWrapperScrollable: {
		overflowY: "scroll",
		scrollbarWidth: "none",
		marginTop: 0,
		"&::-webkit-scrollbar": { display: "none" },
		// TODO: remove?
		//paddingBottom: 64 - 15,
		paddingBottom: 3
	},
})

export const GridChannelPreview = CreateComponent(styles, false, true, ({ ...props }) => {
	const { item, classes, page, isDragging, } = props
	//const opacity = isDragging ? 0 : 1
	const opacity = 0.5
	//if (!item) return null
	//console.debug("image: item %o, index %o, classes %o, page %o, isdragging %o", item, item.index, classes, page, isDragging)

	return (
		<>
			{/*<CustomDragLayer props />*/}
			<Grid id={`${(CleConfig.gridElemIdPrefix)}${item.index}`} item className={classes.CleGridItem /*+ " CleGridItemResponsive"*/} style={{ opacity, border: page.isSelected(item?.id) ? `2px solid ${_theme.palette.primary["main"]}` : "" }} onClick={() => page.toggle(item.id)} data-test="cle-tile">
				<div className={classes.CleChannelLogoWrapper} style={{ backgroundImage: null /*`url(${imageCategory})`*/ }}>
					{/* MTVW-503: Use Channels MS
					{item?.Pictures && <Img src={item?.Pictures.getLogo} params="mode=box&w=480&h=270" onError={e => { logResourceLoadFailure(item?.Pictures.getLogo + "?mode=box&w=480&h=270", e) }} fallback={fallback} alt={item?.Name} className={classes.CleChannelLogo} />}
					*/}
					{/* MTVW-831: "mode=box&w=100" -> "mode=box&w=480&h=270" */}
					{item?.logoUrl && <Img src={item?.logoUrl} params="mode=box&w=480&h=270" onError={e => { logResourceLoadFailure(item?.logoUrl + "?mode=box&w=480&h=270", e) }} fallback={fallback} alt={item?.Name} className={classes.CleChannelLogo} />}
				</div>
				<div className={classes.CleGridTextWrapper}>
					<Typography variant="subtitle2" className={classes.CleGridChannel} noWrap={false} style={{ fontSize: 13, fontFamily: "EuropaBold", WebkitUserSelect: "none !important" }}>
						{item?.Name}
					</Typography>
					{/* MTVW-464: No HD badge
					<img className={classes.CleIcon} src={HdIcon} alt="" style={{ visibility: item?.IsHD === true ? "visible" : "hidden" }} >
					</img>
					*/}
				</div>
			</Grid>
		</>
	)
})

const GridChannel = CreateComponent(styles, false, true, ({ ...props }) => {

	const { item, classes, page, hidden, isDragging /*, connectDragSource, connectDragPreview */ } = props
	const opacity = isDragging ? 0.4 : 1

	//console.debug("in GridChannel %o", props)

	/**/
	const [collected, drag, dragPreview] = useDrag({
		//item: item,
		item: monitor => {
			//console.debug("DRAG BEGIN GRID %o, %o, %s, %s", monitor, item, page.isSelected(item.id), item.index)
			//scrollHelper.addScrollMonitor("channelListId")
			// need to be fully in the view for correct preview image!
			scrollHelper.scrollIntoView("gridListId", `${(CleConfig.gridElemIdPrefix)}${(item.index)}`)

			item.isSelected = page.isSelected(item.id)
			if (page.listChannelIds.length === 0) {
				// add a temporary channel, if the list is empty
				page.addChannel(item.id)
			}

			// enable scrolling while dragging in channel list
			// for touch enabled devices we need to pass the element id that is being dragged to listen to touchmove events in the channel list
			scrollHelper.addScrollMonitor("channelListId", `${(CleConfig.gridElemIdPrefix)}${(item.index)}`)
			return item
		},
		end: (item, monitor) => {
			const listElem = document.getElementById(`${(CleConfig.listElemIdPrefix)}${(item.index)}`)
			if (listElem) listElem.style.opacity = 1
			console.debug("DRAG END GRID %o %o, %o", item, monitor, listElem)
			const dropResult = monitor.getDropResult()
			//console.debug("dropResult %o item %o", dropResult, item)

			// deregister event handler
			scrollHelper.removeScrollMonitor()
			page.setDragChannel(-1)
			if (!dropResult && !item.isSelected) {
				page.remove(page.getIndex(item.id))
			}
		},
		type: CleConfig.gridItemType,
		collect: monitor => ({
			isDragging: !!monitor.isDragging()
		})
	})
	//console.debug("dragPreview grid %o", dragPreview, collected)
	/**/

	useEffect(() => {
		// disable automatic snapshot with HTML5Backend (which can produce ghost images), instead we are now using react-dnd-preview
		const img = new Image(1, 1)
		//img.src = item?.Pictures?.getLogo
		// 1x1 pixel image with opacity 0
		img.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
		//img.onload = () => connectDragPreview(img)
	}, [])

	/*
	connectDragPreview(<div>{item.Pictures && <Img src={item.Pictures.getLogo} params="mode=box&w=480&h=270" fallback={fallback} alt={item.Name} className={classes.CleChannelLogo} />} </div>)
	*/


	/*
	return connectDragPreview(
		<div>
			{connectDragSource(
	*/
	return (//connectDragSource(
		<div>
			{/*<CustomDragLayer props />*/}
			<Grid isDragging={collected.isDragging} ref={drag} id={`${(CleConfig.gridElemIdPrefix)}${item.index}`}
				item className={classes.CleGridItem /*+ " CleGridItemResponsive"*/}
				style={{ opacity, visibility: hidden ? "hidden" : "visible", border: page.isSelected(item.id) ? `2px solid ${_theme.palette.primary["main"]}` : "" }}
				onClick={() => page.toggle(item.id)}
				data-test={page.isSelected(item.id) ? "cle-tile_selected" : "cle-tile"}
			>
				<div className={classes.CleChannelLogoWrapper} style={{ backgroundImage: null /*`url(${imageCategory})`*/ }}>
					{/* MTVW-503: Use Channels MS
					{item.Pictures && <Img src={item?.Pictures.getLogo} params="mode=box&w=480&h=270" onError={e => { logResourceLoadFailure(item?.Pictures.getLogo + "?mode=box&w=480&h=270", e) }} fallback={fallback} alt={item.Name} className={classes.CleChannelLogo} />}
					*/}
					{item?.logoUrl && <Img src={item?.logoUrl} params="mode=box&w=480&h=270" onError={e => { logResourceLoadFailure(item?.logoUrl + "?mode=box&w=480&h=270", e) }} fallback={fallback} alt={item?.Name} className={classes.CleChannelLogo} />}
				</div>
				<div className={classes.CleGridTextWrapper}>
					<Typography variant="subtitle2" className={classes.CleGridChannel} noWrap={false} style={{ fontSize: 13, fontFamily: "EuropaBold", WebkitUserSelect: "none !important" }}>
						{item.Name}
					</Typography>
					{/* MTVW-464: No HD badge
					<img className={classes.CleIcon} src={HdIcon} alt="" style={{ visibility: (item.IsHD === true && !hidden) ? "visible" : "hidden" }} >
					</img>
					*/}
				</div>
			</Grid>
		</div>
	)
	/*
	}
	</div>,
	//{ offsetX: CleConfig.gridCellWidth / 2 + 40, offsetY: CleConfig.gridCellHeigth / 2 + 50 }
	{ offsetX: 20, offsetY: 0 }
	)
	*/
})

const channelSource = {
	beginDrag(props, monitor, component) {
		//console.debug("beginDrag ix %s id %s ch %o mon %o comp %o", props.index, props.listId, props.channel, monitor, component)

		// need to be fully in the view for correct preview image!
		scrollHelper.scrollIntoView("gridListId", `${(CleConfig.gridElemIdPrefix)}${(props.index)}`)

		const isSelected = props.page.isSelected(props.channel)
		if (props?.page?.listChannelIds?.length === 0) {
			// add a temporary channel, if the list is empty
			props.page.addChannel(props.item.id)
		}

		// enable scrolling while dragging in channel list
		// for touch enabled devices we need to pass the element id that is being dragged to listen to touchmove events in the channel list
		scrollHelper.addScrollMonitor("channelListId", `${(CleConfig.gridElemIdPrefix)}${(props.index)}`)
		return {
			index: props.index,
			listId: props.listId,
			channel: props.channel,
			page: props.page,
			item: props.item,
			//isSelected: props.page.isSelected(props.channel),
			isSelected: isSelected
		}
	},

	endDrag(props, monitor, component) {
		const item = monitor.getItem()
		const dropResult = monitor.getDropResult()
		//console.debug("dropResult %o item %o", dropResult, item)

		// deregister event handler
		scrollHelper.removeScrollMonitor()
		props.page.setDragChannel(-1)
		if (!dropResult && !item.isSelected) {
			props.page.remove(props.page.getIndex(item.channel))
		}
	}
}

/*
export default DragSource("GRID_CHANNEL", channelSource, (connect, monitor) => ({
	connectDragSource: connect.dragSource(),
	connectDragPreview: connect.dragPreview(),
	isDragging: monitor.isDragging()
}))
*/

export default GridChannel
