/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-05
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-10
 */

import { computed } from "mobx"
import { flow, types as t } from "mobx-state-tree"
//import { TraxisModel } from "store/api/TraxisModel"
import { ObserveMixin } from "store/page/mixin/ObserveMixin"
import { RefElemMixin } from "store/page/mixin/RefElemMixin"
import { PageAbstractMbx } from "store/page/PageAbstract"
import { Math_floor } from "store/util/Math"
import { HourInMinute, DayInMs, HourInMs, MinuteInMs, moment, utcOffsetInHour } from "store/util/moment"
import { rootStore } from "store/RootStore"
import { CALLING_CONTEXT_TYPE } from "store/page/OverlayEventDetails"
import { EventEmitter } from "components/utils/EventEmitter"
import { stopWatch } from "utils/TestSupport"
import { TIME_FORMAT_BACKEND } from "store/model/Time"


export const TvGuideConf = {
	// SO we fix timezone to be always on 6
	// dayStartAtHour: 6,
	dayHourPeriod: 24,
	hoursPreviousDay: 6,	// for calculated bufferSize, TODO check 0 - 6 am CET!
	hoursNextDay: 6, // for calculated bufferSize
	egpHoursBefore: 6,
	egpHoursAfter: 6,
	/* MTV- 2349: 3 visible hours instead of 4
	dayDurationTs: (24 - 4) * HourInMs,
	*/
	//dayDurationTs: (24 - 3) * HourInMs,

	dayScopePrev: 7,
	//dayScopeNext: 13,
	dayScopeNext: 7,

	pixelsPerHour: 360,
	/* MTV- 2349: 3 visible hours instead of 4
	 // 4h 5m
	 visibleHour: 4,
	*/
	// 3h 5m
	//visibleHour: 3,

	// important when jumping to JETZT
	// visibleLeftHour: 1,

	// MTV-2349: Still needed?
	//visibleExtraMinute: 5,
	visibleExtraMinute: 0,

	/* MTV- 2349: 3 visible hours instead of 4
	 minuteInPx: 5,
	*/
	// MTV-2349: RESPONSIVE_CHANGE, now pixelsPerMinute
	//minuteInPx: 7.5,
	// rowHeightInPx: 71,
	rowHeightInPx: 71,
	arrowHourPeriod: 3,

	channelWidth: 100,

	//20:15
	dayPrimeHourTs: (20 - utcOffsetInHour) * HourInMs + 15 * MinuteInMs
	//dayPrimeHourTs: (12 - 6) * HourInMs + 8 * MinuteInMs
}

export const TvGuide = t.compose(
	"TvGuide",
	PageAbstractMbx,
	RefElemMixin,
	ObserveMixin,
	t
		.model({
			visibleHour: t.optional(t.number, 0),
			visibleLeftHour: t.optional(t.number, 0),
			contextName: "tvGuide"
		})
		.volatile(self => ({
			// MTVW-251: not needed anymore
			//oEpgCurr: new TraxisModel(),
			// MTVW-251: new msEpg
			msEpg: rootStore.page.MsEpg,
			iDateTs: 0,

			// timestamp of selected day at 00:00 UTC
			selectedDayTs: null,
			iDateHour: null,
			iDateMinute: null,

			iDayOffsetTs: 0,
			bDayCurrent: true,
			bOnTimeRoller: true,
			iDateTsLoaded: null,
			// MTV-2349: RESPONSIVE_CHANGE
			epgWidth: 0,
			pixelsPerMinute: 6,
			// MTV-2765
			firstMountDone: false,
			_refScroll: null,
			scrollEpg: () => { },
			_refreshCount: 0,
			// NOTE: additional information could be put into below object
			refreshVisibleTIme: {},
			_refreshTimeQueue: [],
			_refreshTimeDone: true,
			programCells: new Map(),
			//_currentHour: moment.duration(moment().format("HH:mm")).asHours(),
			_currentHour: moment.duration(moment(rootStore.time.getTimeStampTick(60)).format("HH:mm")).asHours(),
			filteredChannels: [],
			tvGuideBody: null,
			_wrapperLeftTs: 0,
			_wrapperRightTs: 0
		}))
		.views(self => ({
			get bufferSize() {
				return (TvGuideConf.dayHourPeriod + TvGuideConf.hoursPreviousDay + TvGuideConf.hoursNextDay) * self.getPixelsPerMinute * 60
				//return self.epgWidth
			},
			get getVisibleLeftHour() {
				//return self.iDateHour + self.iDateMinute / HourInMinute - self.epgWidth / 2 / (self.getPixelsPerMinute * HourInMinute) + TvGuideConf.channelWidth / 2 / self.getPixelsPerMinute / HourInMinute
				// optimization
				return self.visibleLeftHour
			},
			get getVisibleHour() {
				return self.visibleHour
			},
			get currentHour() {
				return self._currentHour
			},
			/* UNUSED
			get getDayDurationTs() {
				//return (24 - self.visibleHour) * HourInMs
				return self.visibleHour < TvGuideConf.dayHourPeriod ? (24 - self.visibleHour) * HourInMs : self.visibleHour * HourInMs
			},
			*/
			get isReady() {
				//MTVW-251: use msEpg
				//return rootStore.page.ChannelListRepo.isReady && self.oEpgCurr.isReady
				return self.msEpg.isReady
			},
			// MTV-2349: RESPONSIVE_CHANGE
			get getPixelsPerMinute() {
				return self.pixelsPerMinute
			},
			get ChannelsFromList() {
				// MTVW-251
				//return rootStore.page.ChannelListRepo.isReady ? rootStore.page.ChannelListRepo.ChannelsFromList : []
				return (self.isReady) ? self.msEpg.getChannelsFromList(rootStore.page.ChannelListRepo.ChannelList) : []
			},
			get getDateMin() {
				return self.getDateCurrTs - TvGuideConf.dayScopePrev * HourInMs * 24
			},
			get getDateMax() {
				return self.getDateCurrTs + TvGuideConf.dayScopeNext * HourInMs * 24
			},
			// get getDayStartHour() {
			// 	return parseInt(
			// 		moment(rootStore.time.getTimeStampTick(3600))
			// 			.startOf("day")
			// 			.add(TvGuideConf.dayStartAtHour, "hour")
			// 			.utc()
			// 			.format("H"),
			// 		10
			// 	)
			// },
			get getDateCurrTs() {
				//console.debug("getTimeStampTick(3600) %o", rootStore.time.getTimeStampTick(3600))
				// return Math_floor(rootStore.time.getTimeStampTick(3600) - self.getDayStartHour * HourInMs, 24 * HourInMs)
				return Math_floor(rootStore.time.getTimeStampTick(3600), 24 * HourInMs)
			},
			get getDayOffsetCurrTs() {
				// rootStore.time.getTimeStampTick(HourInMinute) is now
				// return (rootStore.time.getTimeStampTick(HourInMinute) - self.getDayStartHour * HourInMs) % DayInMs
				//console.debug("getDayOffsetCurrTs %s", (rootStore.time.getTimeStampTick(HourInMinute)) % DayInMs)
				return (rootStore.time.getTimeStampTick(HourInMinute)) % DayInMs
			},
			get getDayOffsetInPx() {
				return (self.getVisibleLeftHour + TvGuideConf.egpHoursBefore) * TvGuideConf.pixelsPerHour - moment().utcOffset() * self.pixelsPerMinute
			},
			// MTVW-318 added: for the selected day
			getOffesetForTimeInPx(hour, minute) {
				return (TvGuideConf.hoursPreviousDay - 2 + hour + minute / HourInMinute) * TvGuideConf.pixelsPerHour - self.epgWidth / 2 + TvGuideConf.channelWidth / 2

			},
			/* UNUSED
			get getDayMidnightTs() {
				// return self.iDateTs + self.getDayStartHour * HourInMs
				return self.iDateTs
			},
			get getDateSelectedTz() {
				return moment(self.iDateTs).utcOffset() * MinuteInMs
			},
			*/
			/* MTVW-251: unused
			getEpgForChannel2(channelId) {
				if (!self.oEpgCurr.isReady) {
					return []
				}
				const oEpg = self.oEpgCurr.Data.getChannelEpg(channelId)
				if (oEpg && oEpg.Events) {
					return oEpg.Events.items
				}
				return []
			},
			*/
			getEpgForChannel(channelId) {
				// MTVW-251
				/*
				if (!self.oEpgCurr.isReady) {
					return []
				}
				//console.debug("Channels: %o", self.oEpgCurr.Data.Channels)
				const oEpg = self.oEpgCurr.Data.Channels.get(channelId)
				//console.debug("newEvents %o", newEvents)
				if (oEpg && oEpg.Events) {
					console.debug("Events: %o", oEpg.Events)
					return oEpg.Events
				}
				else {
					// MTV-1911: Need to refresh EPG when channel list changes 
					self.refreshEpg()
				}
				return []
				*/

				//console.debug("getEpgForChannel %s", channelId)
				if (!self.isReady) return []
				const events = self.msEpg.events(channelId)
				/*
				events.forEach(function (evt, ix) {
					console.debug("evt %s, %o, ix %", evt.AvailabilityStartDateFormat("HH:mm") + "-" + evt.AvailabilityEndDateFormat("HH:mm"), evt.AvailabilityEndDateTs, ix)
				})
				*/
				//console.debug("msEvents: %o, %s", events, events.length)
				return events
			},
			get getValueHorizontalScroll() {
				//console.debug("valHoriz %s", Math.floor((self.iDayOffsetTs / HourInMs) * TvGuideConf.pixelsPerHour))
				return Math.floor((self.iDayOffsetTs / HourInMs) * TvGuideConf.pixelsPerHour)
			},
			getElemPosLeftInPx(oEpgElem) {
				// MTV-2349: RESPONSIVE_CHANGE
				// return ((oEpgElem.AvailabilityStartDateTs - self.iDateTs - self.getDayStartHour * HourInMs) * self.getPixelsPerMinute /*TvGuideConf.minuteInPx*/) / MinuteInMs

				return ((oEpgElem.AvailabilityStartDateTs - self.iDateTs) * self.getPixelsPerMinute /*TvGuideConf.minuteInPx*/) / MinuteInMs
			},
			getElemPosRightInPx(oEpgElem) {
				return ((oEpgElem.AvailabilityEndDateTs - self.iDateTs) * self.getPixelsPerMinute /*TvGuideConf.minuteInPx*/) / MinuteInMs
			},
			/* UNUSED
			getVisibleElemPosLeftInPx(epgElem) {
				const posLeftInPx = self.getElemPosLeftInPx(epgElem)
				//console.debug("posLeftInPx %s, %s", posLeftInPx, posLeftInPx / self.getPixelsPerMinute)
				if (posLeftInPx < self.getWrapperLeftInPx) {
					// MTV-1564: start at getWrapperLeftInPx
					return self.getWrapperLeftInPx
				}
				//console.debug("elemPosLeft %s, %s, channel %s, %s - %s, %o", posLeftInPx, epgElem.Title.Name, epgElem.channelId, epgElem.AvailabilityStartDateFormat("HH:mm"), epgElem.AvailabilityEndDateFormat("HH:mm"), epgElem)
				return posLeftInPx //+ 360 * 6
			},
			*/
			getElemLeftPadding(epgElem, domRef, id) {
				if (!epgElem) return 0
				const posLeftInPx = self.getElemPosLeftInPx(epgElem)
				const posRightInPx = self.getElemPosRightInPx(epgElem)

				let paddingLeft = 0
				if ((posRightInPx >= self.refScroll.scrollLeft + self.getWrapperLeftInPx) &&
					(posLeftInPx < self.refScroll.scrollLeft + self.getWrapperLeftInPx)) {
					paddingLeft = self.refScroll.scrollLeft + self.getWrapperLeftInPx - posLeftInPx
					// fix for long events (starting at the begin of the epg)
					if (paddingLeft > self.refScroll.scrollLeft) paddingLeft = self.refScroll.scrollLeft
					//console.debug("left %s, right %s, %o, %s, %s, %s, t %s, %o", posLeftInPx, posRightInPx, self.getWrapperLeftInPx, self.refScroll.scrollLeft + self.getWrapperLeftInPx, posRightInPx >= self.refScroll.scrollLeft + self.getWrapperLeftInPx, posLeftInPx < self.refScroll.scrollLeft + self.getWrapperLeftInPx, epgElem.Title.Name, epgElem)
					//console.debug("paddingLeft %s, %s", paddingLeft, self.refScroll.scrollLeft)
				}
				if (domRef) self.programCells.set(id, { epgElem: epgElem, domRef: domRef, id: id })
				//console.debug("paddingLeft %s, %s, channel %s, %s - %s, %o", paddingLeft, epgElem.Title.Name, epgElem.channelId, epgElem.AvailabilityStartDateFormat("HH:mm"), epgElem.AvailabilityEndDateFormat("HH:mm"), epgElem)
				return paddingLeft
			},
			getVisibleElemWidthInPx(epgElem, domRef, id) {
				if (!epgElem) return 0
				// recalculates the width of the first program, so that the end is correctly aligned, and consequently all the following programs
				const posLeftInPx = self.getElemPosLeftInPx(epgElem)
				//const posRightInPx = self.getElemPosRightInPx(epgElem)
				// MTV-2349: RESPONSIVE_CHANGE
				const totalWidth = epgElem.AvailabilityDurationInMinutes * self.getPixelsPerMinute
				//console.debug("elem %s, channel %s, %s - %s, totalWidth %s", epgElem.Title.Name, epgElem.channelId, epgElem.AvailabilityStartDateFormat("HH:mm"), epgElem.AvailabilityEndDateFormat("HH:mm"), totalWidth)

				if (domRef) self.programCells.set(id, { epgElem: epgElem, domRef: domRef, id: id })

				if (posLeftInPx < self.getWrapperLeftInPx) {
					//MTV-1564: start at getWrapperLeftInPx
					//console.debug("totalWidth %s", totalWidth - (self.getWrapperLeftInPx - posLeftInPx) - 1)
					return totalWidth - (self.getWrapperLeftInPx - posLeftInPx) - 1
				}
				return Math.floor(totalWidth)
			},
			// MTVW-251
			//_getEpgDay(timeStamp) {
			_getEpgDay: flow(function* (timeStamp) {
				// MTVW-251
				//console.debug("_getEpgDay %s, %o, %o", timeStamp, moment(timeStamp).format("YYYYMMDD"), self.msEpg)
				/* TEST */ self.clearLeftEpgPaddingsMap()
				console.debug("_getEpgDay", timeStamp, moment(timeStamp).format(TIME_FORMAT_BACKEND))
				yield self.msEpg.getProgramsForDayAsync(moment(timeStamp).format("YYYYMMDD"))/*.then(res => {
					console.debug("_getEpgDay %o", res)
				})*/
				//self.scrollEpg()
				//self.requestUpdate()
			}),
			isSelectedChannel(chId) {
				return computed(() => {
					return chId === rootStore.page.ChannelListRepo.activeChannelId
				}).get()
			},
			// TODO: don't use anymore
			get isTimeRollerMoveable() {
				console.info(
					"root.page.TvGuide.isTimeRollerMoveable date=%i dateCurr=%i dateSame=%o min=%i curr=%i max=%i",
					self.iDateTs,
					self.getDateCurrTs,
					self.iDateTs === self.getDateCurrTs,
					Math.floor(self.iDayOffsetTs / MinuteInMs),
					Math.floor(self.getDayOffsetCurrTs / MinuteInMs),
					Math.floor((self.iDayOffsetTs + 3 * HourInMs) / MinuteInMs)
				)
				return (
					self.iDateTs === self.getDateCurrTs &&
					self.iDayOffsetTs <= self.getDayOffsetCurrTs &&
					self.getDayOffsetCurrTs < self.iDayOffsetTs + self.visibleHour * HourInMs + TvGuideConf.visibleExtraMinute * MinuteInMs
				)
			},
			get isDateCurrTs() {
				return self.iDateTs === self.getDateCurrTs
			},
			/* NOT CALLED ANYMORE
			get getMoveMakerPosLeftInPx() {
				if (self.iDateTs !== self.getDateCurrTs) {
					return null
				}
				// MTV-2349: RESPONSIVE_CHANGE
				return Math.floor(self.getDayOffsetCurrTs / MinuteInMs) * self.getPixelsPerMinute
			},
			
			get isTimeBeforePrimeTime() {
				return self.getDayOffsetCurrTs <= TvGuideConf.dayPrimeHourTs
			},
			get isWrapperRightBeforePrimeTime() {
				return self.iDayOffsetTs + self.visibleHour * HourInMs <= TvGuideConf.dayPrimeHourTs
			},
			get isWrapperRightBeforeNow() {
				return self.iDayOffsetTs + self.visibleHour * HourInMs <= self.getDayOffsetCurrTs
			},
			get isWrapperOnRightBorder() {
				return self.iDayOffsetTs >= self.getDayDurationTs - TvGuideConf.visibleExtraMinute * MinuteInMs
			},
			get isWrapperLeftBeforePrimeTime() {
				return self.iDayOffsetTs <= TvGuideConf.dayPrimeHourTs
			},
			get isWrapperLeftAfterNow() {
				return self.iDayOffsetTs > self.getDayOffsetCurrTs
			},
			get isWrapperOnLeftBorder() {
				return self.iDayOffsetTs === 0
			},
			*/
			get getSelectedDayTs() {
				return self.selectedDayTs
			},
			get getDateHour() {
				return self.iDateHour
			},
			get getDateMinute() {
				return self.iDateMinute
			},
			get getWrapperLeftTs() {
				//return self.iDateTs + self.iDayOffsetTs //+ self.getDayStartHour * HourInMs
				// TODO: self.visibleLeftHour is 0!
				//console.debug("wrapLeftTs %s, vLeft %s, %s", self.iDateTs + self.iDayOffsetTs + self.visibleLeftHour * HourInMs - TvGuideConf.egpHoursBefore * DayInMs, self.visibleLeftHour, self.getVisibleLeftHour)
				//return self.iDateTs + self.iDayOffsetTs - TvGuideConf.hoursPreviousDay * DayInMs//+ self.getDayStartHour * HourInMs
				// start of day, midnight CET

				//return self.iDateTs + self.iDayOffsetTs + self.visibleLeftHour * HourInMs - TvGuideConf.egpHoursBefore * DayInMs
				// optimization
				return self._wrapperLeftTs
			},
			get getWrapperLeftInPx() {
				// MTV-2349: RESPONSIVE_CHANGE
				//console.debug("wrapLeftPx %s", (self.iDayOffsetTs / MinuteInMs - (TvGuideConf.egpHoursBefore - moment().utcOffset() / HourInMinute) * HourInMinute) * self.getPixelsPerMinute)
				return (self.iDayOffsetTs / MinuteInMs - (TvGuideConf.egpHoursBefore - moment().utcOffset() / HourInMinute) * HourInMinute) * self.getPixelsPerMinute
				// NEW
				//return -TvGuideConf.egpHoursBefore * 60 * self.getPixelsPerMinute
			},
			get getWrapperRightTs() {
				// return self.iDateTs + self.iDayOffsetTs + (self.getDayStartHour + self.visibleHour) * HourInMs + TvGuideConf.visibleExtraMinute * MinuteInMs
				//console.debug("self.iDateTs %s, %s, %s, %s", self.iDateTs, self.iDayOffsetTs, self.visibleHour, self.iDayOffsetTs + self.visibleHour * HourInMs + /*TvGuideConf.visibleExtraMinute*/ 5 * MinuteInMs)
				//return self.iDateTs /*+ self.iDayOffsetTs*/ + self.visibleHour * HourInMs + TvGuideConf.visibleExtraMinute * MinuteInMs

				// TODO: 5 minutes below (MTVW-323) should not be necessary, but sometimes there were gaps on the right
				//return self.iDateTs + self.iDayOffsetTs + self.visibleHour * HourInMs + /*TvGuideConf.visibleExtraMinute*/ 5 * MinuteInMs
				// optimization
				return self._wrapperRightTs
			},
			isElemInVisibleArea(oEpgElem) {
				//console.debug("isVisible %s <= %s   %s >= %s", oEpgElem.AvailabilityStartDateTs, self.getWrapperRightTs, oEpgElem.AvailabilityEndDateTs, self.getWrapperLeftTs)
				//console.debug("AvailabilityStartDateTs>>>>>>>>", oEpgElem.AvailabilityStartDateTs)

				// TODO
				//console.debug("VIS %s, %s, %s", self.getVisibleLeftHour, self.getVisibleHour, self.getVisibleHour - self.getVisibleLeftHour)
				return (oEpgElem.AvailabilityStartDateTs <= self.getWrapperRightTs && oEpgElem.AvailabilityEndDateTs >= self.getWrapperLeftTs)
			},
			isElemInView(oEpgElem) {
				const leftTs = self.selectedDayTs + (-utcOffsetInHour + self.getVisibleLeftHour) * 3600 * 1000
				const rightTs = self.selectedDayTs + (-utcOffsetInHour + self.getVisibleHour) * 3600 * 1000

				//console.debug("leftTs %o, rightTs %o, %o", leftTs, rightTs, oEpgElem.AvailabilityStartDateTs)
				return oEpgElem.AvailabilityStartDateTs <= rightTs && oEpgElem.AvailabilityEndDateTs >= leftTs
			},
			get isDateLoadedChange() {
				return self.iDateTsLoaded !== self.iDateTs
			},
			get refElementScroll() {
				return self._tRefElements.get("bLeft").refElem
			},
			get refScroll() {
				return self._refScroll
			},
			get doUpdate() {
				return self._refreshCount
			}
		}))
		.actions(self => ({
			_afterCreate() {
				//self._setDayOffsetCurrTs()
				//console.debug("_afterCreate")
				EventEmitter.subscribe("inactive", (event) => { self._isInactive(event) })
				EventEmitter.subscribe("active", (event) => { self._isActive(event) })
				self.setCurrentTimeTs()
			},
			_updateVisibleHour() {
				self.visibleLeftHour = self.iDateHour + self.iDateMinute / HourInMinute - self.epgWidth / 2 / (self.getPixelsPerMinute * HourInMinute) + TvGuideConf.channelWidth / 2 / self.getPixelsPerMinute / HourInMinute
				self.visibleHour = self.visibleLeftHour + self.epgWidth / self.getPixelsPerMinute / HourInMinute
				//console.debug("self.getVisibleLeftHour %s, self.visibleHour %s", self.getVisibleLeftHour, self.visibleHour)
				self._wrapperLeftTs = self.iDateTs + self.iDayOffsetTs + self.visibleLeftHour * HourInMs - TvGuideConf.egpHoursBefore * DayInMs
				// TODO: 5 minutes below (MTVW-323) should not be necessary, but sometimes there were gaps on the right
				self._wrapperRightTs = self.iDateTs + self.iDayOffsetTs + self.visibleHour * HourInMs + /*TvGuideConf.visibleExtraMinute*/ 5 * MinuteInMs
			},
			// MTV-2349: RESPONSIVE_CHANGE
			_handleResize() {
				//self._refreshCount++
				//console.debug("_handleResize")
				// MTVW-754
				const pageWrapper = document.getElementById("pageWrapper")
				if (pageWrapper) pageWrapper.style.width = "100vw"
				self.calcEpgWidth()
				self._updateVisibleHour()
				//console.debug("visibleHour %s, %s", self.visibleHour, self.getVisibleLeftHour)
			},
			_onMount() {
				// can be called multiple times on mobile devices (return from background)
				//console.debug("_onMount")
				self._currentHour = moment.duration(moment(rootStore.time.getTimeStampTick(60)).format("HH:mm")).asHours()
				window.addEventListener("resize", self._handleResize)
				// calculate pixels per minute
				self._handleResize()
			},
			_onUnmount() {
				//console.debug("_onUnmount")
				window.removeEventListener("resize", self._handleResize)
			},
			// MTVW-536: flow function
			_preloadDays: flow(function* () {
				//for (let i = 1; i <= Math.max(TvGuideConf.dayScopePrev, TvGuideConf.dayScopeNext); i++) {
				// TEST reduced memory consumption (for Android)
				for (let i = 1; i <= 1; i++) {
					// MTVW-536: use yield
					yield self.msEpg.preloadDay(moment(self.iDateTs).utc().add(i, "days").format("YYYYMMDD"))
					yield self.msEpg.preloadDay(moment(self.iDateTs).utc().add(-i, "days").format("YYYYMMDD"))
				}
				// TODO: cover asymemtric range
			}),
			// MTVW-536: flow function
			_afterCreateAsync: flow(function* () {
				self.setCurrentTimeTs()
				// MTVW-251: we still need ChannelListRepo for filtering unsubscribed channels
				yield rootStore.page.ChannelListRepo.waitAsync()
				// MTVW-536: use yield
				yield self.msEpg.getChannelsAsync()
				yield self.msEpg.getProgramsForDayAsync(moment(self.iDateTs).utc().format("YYYYMMDD"))
				//console.debug("getProgramsForDayAsync", self.msEpg.programs)
				yield self._preloadDays()
				//yield self.setMoveTimeRollerAsync()

				self._addObserve(rootStore.time.getTimeStampTick(HourInMinute, true), changes => {
					console.info("root.page.TvGuide observe(rootStore.time.getTimeStampTick(60)) self=%o changes=%o", self, changes)
					self._setObserveTimeStamp60sAsync()
				})
			}),
			_setObserveTimeStamp60sAsync: flow(function* () {
				//self._currentHour = moment.duration(moment().format("HH:mm")).asHours()
				self._currentHour = moment.duration(moment(rootStore.time.getTimeStampTick(60)).format("HH:mm")).asHours()
				if (self.bOnTimeRoller === true || self.isTimeRollerMoveable) {
					// MTV-1703: Disable jump to JETZT every 60 seconds
					//yield self.setMoveTimeRollerAsync()
					//self.bOnTimeRoller = true
				}
				// to be sure to move epg to next day or watch borders
				else {
					yield self.setDayChangeTsAsync(null, self.bDayCurrent ? self.getDateCurrTs : null, false)
				}
				// TODO check also condiiton above
				console.debug("1 minute update")
				//yield self.setDayChangeTsAsync(null, self.bDayCurrent ? self.getDateCurrTs : null, false)
				yield self._loadEpgAsync()
				self.requestUpdate()
			}),
			calcEpgWidth() {
				const elem = document.getElementById("TvGuideMainContainer")
				if (elem) {
					self.epgWidth = elem.clientWidth - TvGuideConf.channelWidth
					//console.debug("current epgWidth %s", self.epgWidth)
				}
			},
			setFilteredChannels(filteredChannels) {
				self.filteredChannels = filteredChannels
			},
			setDayOffsetTs(iDayOffsetTs = null, iDateTs = null) {
				//console.debug("iDayOffsetTs %o", iDayOffsetTs)
				//console.debug("iDateTs %o, self.iDateTs %s", iDateTs, moment(iDayOffsetTs).toString(), moment(iDateTs).toString(), moment(self.iDateTs).toString())
				if (iDateTs === null) {
					iDateTs = self.iDateTs
				} else {
					iDateTs = Math_floor(iDateTs, DayInMs)
				}
				if (iDayOffsetTs === null) {
					iDayOffsetTs = self.iDayOffsetTs
				}
				if (iDateTs < self.getDateMax) {
					// in next order
					self.iDateTs = iDateTs
				} else {
					iDateTs = self.getDateMax
				}
				// in prev order
				if (iDateTs > self.getDateMin) {
					self.iDateTs = iDateTs
				} else if (iDateTs === self.getDateMin) {
					const iCurrentOffsetTs = Math.floor(rootStore.time.getTimeStampTick(HourInMinute) % DayInMs) //- self.getDayStartHour * HourInMs
					if (iCurrentOffsetTs > iDayOffsetTs) {
						iDayOffsetTs = iCurrentOffsetTs
					}
				} else {
					iDateTs = self.getDateMin
				}
				self.bDayCurrent = self.iDateTs === self.getDateCurrTs
				//console.debug("now iDateTs %s, %s, ofs %s, cur %s", iDateTs, moment(iDateTs).toString(), iDayOffsetTs / 1000 / 3600, self.bDayCurrent)

				/*if (iDayOffsetTs < 0) {
					self.iDayOffsetTs = 0
				} else if (iDayOffsetTs > self.getDayDurationTs - TvGuideConf.visibleExtraMinute * MinuteInMs) {
					self.iDayOffsetTs = self.getDayDurationTs - TvGuideConf.visibleExtraMinute * MinuteInMs
				} else {
					self.iDayOffsetTs = iDayOffsetTs
				}*/
				//self.iDayOffsetTs = iDayOffsetTs
				self.iDayOffsetTs = (-moment().utcOffset() /*- 5*/) * MinuteInMs	//-2 * 3600 * 1000
				self._runRefElem()
				//console.debug("iDayOffsetTs %s, %s", iDayOffsetTs, iDayOffsetTs / 1000 / 3600)
				return self
			},

			// NOT CALLED ANYMORE
			/*
			setValueHorizontalScroll(value, bCheckTimeFrame = true, shiftHours = 0) {
				const iDayOffsetTs = Math.floor((value * HourInMs) / TvGuideConf.pixelsPerHour)
				self.setDayOffsetTs(iDayOffsetTs)
				if (bCheckTimeFrame === true) {
					self.bOnTimeRoller = self.isTimeRollerMoveable
				}
				// patch for small display width
				if (shiftHours > 0) {
					self.iDayOffsetTs += shiftHours * HourInMs
					self._runRefElem()
				}
				return self
			},
			*/

			setDayChangeTsAsync: flow(function* (iDayOffsetTs = null, iDateTs = null, bCheckTimeFrame = true) {
				const _iDateTs = self.iDateTs
				self.setDayOffsetTs(iDayOffsetTs, iDateTs)
				if (_iDateTs !== self.iDateTs) {
					yield self._loadEpgAsync()
				}
				if (bCheckTimeFrame === true) {
					self.bOnTimeRoller = self.isTimeRollerMoveable
				}
				return self
			}),
			// setArrowMoveAsync: flow(function* (bRight = false, bCheckTimeFrame = true) {
			// 	//const iDayOffsetTs = Math_floor(self.iDayOffsetTs, HourInMs * TvGuideConf.arrowHourPeriod) + (bRight === true ? 1 : -1) * TvGuideConf.arrowHourPeriod * HourInMs
			// 	const iDayOffsetTs = self.iDayOffsetTs + (bRight === true ? 1 : -1) * TvGuideConf.arrowHourPeriod * HourInMs
			// 	const iDateTsCurr = self.iDateTs
			// 	const iDayOffsetEndTs = TvGuideConf.dayDurationTs
			// 	// move left
			// 	if (iDayOffsetTs < 0 && self.isWrapperOnLeftBorder) {

			// 		self.setDayOffsetTs(iDayOffsetTs, self.iDateTs - DayInMs)
			// 		if (iDateTsCurr !== self.iDateTs) {

			// 			self.setDayOffsetTs(iDayOffsetEndTs)
			// 			yield self._loadEpgAsync()
			// 		}
			// 	}
			// 	// move right
			// 	else if (iDayOffsetTs > iDayOffsetEndTs && self.isWrapperOnRightBorder) {

			// 		self.setDayOffsetTs(iDayOffsetTs, self.iDateTs + DayInMs)
			// 		if (iDateTsCurr !== self.iDateTs) {

			// 			self.setDayOffsetTs(0)
			// 			yield self._loadEpgAsync()
			// 		}
			// 	} else {
			// 		self.setDayOffsetTs(iDayOffsetTs)
			// 	}
			// 	if (bCheckTimeFrame === true) {
			// 		self.bOnTimeRoller = self.isTimeRollerMoveable
			// 	}
			// 	return self
			// }),
			setDayFromCalendarAsync: flow(function* (timestamp, bCheckTimeFrame = true) {
				const iDateTs = self.iDateTs
				self.setDayOffsetTs(null, timestamp)//	timestamp.utc().valueOf()
				if (iDateTs !== self.iDateTs) {
					console.info("root.page.TvGuide.setDayFromCalendarAsync(timestamp=%o self.iDateTs=%o iDateTs=%o)", timestamp, self.iDateTs, iDateTs)
					yield self._loadEpgAsync()
				}
				if (bCheckTimeFrame === true) {
					self.bOnTimeRoller = self.isTimeRollerMoveable
				}
				// MTVW-109 commented change of MTV-1775
				// MTV-1775: display active channel on top
				//const index = rootStore.page.ChannelListRepo.getIndexChannelOnList(rootStore.page.ChannelListRepo.activeChannelId)
				//window.scrollTo(0, index * TvGuideConf.rowHeightInPx)
				return self
			}),
			setEpgEventClick: flow(function* (oEvent, bCheckIsChanged = true) {
				//setEpgEventClick(oEvent, bCheckIsChanged = true) {
				// MTV-1607: don't set active channel here
				//rootStore.page.ChannelListRepo.setActiveChannel(oEvent.Channel.id)
				rootStore.page.Player.error = null
				//MTVW-490 added setComponentType
				yield rootStore.page.OverlayEventDetails.setEpgEventById(oEvent.id)
				rootStore.page.OverlayEventDetails.setComponentType('event').setShow(true, CALLING_CONTEXT_TYPE.TvGuide)
				return self
			}),
			// NOT CALLED ANYMORE
			/*
			setMoveTimeRollerAsync: flow(function* () {
				self._setDayOffsetCurrTs()
				if (self.isDateLoadedChange) {
					yield self._loadEpgAsync()
					self.setDayOffsetTs(self.getDayOffsetCurrTs - self.getVisibleLeftHour * HourInMs)
				}
			}),
			*/
			_setDayOffsetCurrTs() {
				//console.debug("6, d %s, o %s, %s, %s, %s", self.getDateCurrTs, self.getDayOffsetCurrTs, self.getDayOffsetCurrTs - self.getVisibleLeftHour * HourInMs, self.getVisibleLeftHour, self.visibleHour)
				self.setDayOffsetTs(self.getDayOffsetCurrTs - self.getVisibleLeftHour * HourInMs, self.getDateCurrTs)
			},
			_loadEpgAsync: flow(function* () {
				// MTVW-251: commented condition
				////if (self.isDateLoadedChange) {
				//self.oEpgCurr.abortReq()
				//self.oEpgCurr = self._getEpgDay(self.iDateTs)
				yield self._getEpgDay(self.iDateTs)
				self.iDateTsLoaded = self.iDateTs
				////}
				// MTVW-251
				//yield self.oEpgCurr.waitAsync()
				self.setDayOffsetTs()
				// NEW CHANGE
				//self.requestUpdate()
			}),
			setRefElemScroll(...args) {
				self.setRefElem(...args)
				return self
			},
			setRefScroll(ref) {
				//console.debug("setRefScroll %o", ref)
				self._refScroll = ref
			},
			// MTVW-251
			//refreshEpg() {
			refreshEpg: flow(function* () {
				//console.debug("refreshEpg")
				//self.oEpgCurr = self._getEpgDay(self.iDateTs)
				yield self._getEpgDay(self.iDateTs)
			}),
			// MTV-2765
			setFirstMountDone(done) {
				self.firstMountDone = done
			},
			setCurrentTimeTs() {
				self.iDateTs = self.getDateCurrTs
				const now = moment()
				// CAUTION: use separate variables, otherwise 'now.utc().startOf('day').valueOf()' would modify now
				//self.setSelectedDayTs(now.utc().startOf('day').valueOf(), now.hour(), now.minute())
				self.setSelectedDayTs(self.iDateTs, now.hour(), now.minute())
				//console.debug("CURRENT %o, %s, %s", moment(self.getSelectedDayTs).add(self.getDateHour, 'h').add(self.getDateMinute, 'm'), now.hour(), now.minute())
				//console.debug("NOW iDateTs %s, selectedDayTs %s", self.iDateTs, self.getSelectedDayTs)
			},
			setSelectedDayTs(dayTs, dateHour = null, dateMinute = null) {
				if (dayTs !== null) {
					// MTVW-414: do not use adjustUtcStartDay here, we need the real utc
					self.selectedDayTs = moment(dayTs).utc().startOf('day').valueOf()
					self.setDayFromCalendarAsync(dayTs)
				}
				if (dateHour !== null) self.iDateHour = dateHour
				if (dateMinute !== null) self.iDateMinute = dateMinute
				self._updateVisibleHour()
				//console.debug("visibleHour %s, %s", self.visibleHour, self.getVisibleLeftHour)
				self.scrollEpg()
			},
			setScrollEpg(fn) {
				self.scrollEpg = fn
			},
			requestUpdate() {
				self._refreshCount++
			},
			adjustLeftEpgPaddings() {
				//self._refreshCount++
				//console.debug("programCells %o", self.programCells)

				if (self.filteredChannels?.length === 0) return
				/* NOTE: this approach would also rsult in performance degradation
				self.requestUpdate()
				return
				*/

				stopWatch.lap("start adjust")
				// determine visible rows
				///* TEST */ if (!self.tvGuideBody) self.tvGuideBody = document.getElementById("TvGuideBody")
				///* TEST */ if (!self.tvGuideBody) return
				///* TEST */ const firstIx = Math.trunc(self.tvGuideBody.scrollTop / TvGuideConf.rowHeightInPx)
				//console.debug("BODY %s", self.tvGuideBody.scrollTop, firstIx)
				///* TEST */ const nbRows = Math.round(self.tvGuideBody.clientHeight / TvGuideConf.rowHeightInPx + 0.5)
				//console.debug("nbRows %s, %s", self.tvGuideBody.clientHeight / TvGuideConf.rowHeightInPx, nbRows)
				//console.debug("filteredChannels %o", self.filteredChannels)


				///* TEST */ const visibleChannels = self.filteredChannels.slice(firstIx, firstIx + nbRows + 1)
				//console.debug("VISIBLE %s, %s, %o", self.filteredChannels[firstIx].Name, self.filteredChannels[firstIx + nbRows]?.Name, visibleChannels)

				self.programCells.forEach(function (value, key) {
					if (value.domRef.current) {
						///* TEST */ const visibleChannel = visibleChannels?.find(i => i.id === value.epgElem.channelId)
						///* TEST */ if (visibleChannel && self.isElemInView(value.epgElem)) {
						//console.debug("visible %s, %o, %o", nbRows, value, visibleChannel.Name, value.epgElem.content.Title)
						const paddingLeft = self.getElemLeftPadding(value.epgElem)
						//value.domRef.current.style.paddingLeft = paddingLeft + "px"
						value.domRef.current.style.paddingLeft = `${paddingLeft}px`
						/* TEST */
						//}
						//else if (!visibleChannel) self.programCells.delete(key)
					}
				})
				stopWatch.lap("end adjust")
			},
			resetLeftEpgPaddings() {
				//self._refreshCount++
				//console.debug("%o", self.programCells)
				/* TEST */return
				/*
				self.programCells.forEach(function (value, key) {
					if (value.domRef.current) value.domRef.current.style.paddingLeft = "0px"
				})
				*/
			},
			clearLeftEpgPaddingsMap() {
				//console.debug("map before clear %s", self.programCells.size)
				self.programCells.clear()
				//console.debug("map after clear %s", self.programCells.size)
			},
			setVisibleTime(dayTs, dateHour = null, dateMinute = null, noAnimation = false) {
				// with animation, pass values in self.refreshVisibleTime
				// self.setSelectedDayTs would immediately scroll the EPG
				//self.setSelectedDayTs(dayTs, dateHour, dateMinute)
				// create a new object reference in order to notify observer(s)
				self._refreshTimeQueue.push({ time: { dayTs: dayTs, dateHour: dateHour, dateMinute: dateMinute } })
				self._dequeueRefreshTime()
				//self.refreshVisibleTIme = { time: { dayTs: dayTs, dateHour: dateHour, dateMinute: dateMinute } }
			},
			setVisibleTimeDone() {
				self._refreshTimeDone = true
				self._dequeueRefreshTime()
			},
			_dequeueRefreshTime() {
				if (self._refreshTimeDone) {
					const item = self._refreshTimeQueue.shift()
					if (item) {
						self._refreshTimeDone = false
						/*
						item.previousDayTs = self.selectedDayTs
						// apply target Ts
						self.setSelectedDayTs(item.time.dayTs, item.time.dateHour, item.time.dateMinute)
						*/
						// notify HoursSelector
						self.refreshVisibleTIme = item
					}
				}
			},
			_isInactive(event) {
				//console.debug("LiveTv inactive")
				// nothing to do yet
			},
			_isActive: flow(function* (event) {
				//console.debug("TvGuide active")
				// MTV-3395: Improve reliability of Jetzt when back to active
				self.setCurrentTimeTs()
				// MTVW-251
				//yield rootStore.page.ChannelListRepo.waitAsync()
				yield self._loadEpgAsync()
				//yield self.setMoveTimeRollerAsync()
			})
		}))
)
