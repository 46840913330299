import { types as t } from "mobx-state-tree"
import { CustomProperties } from "./CustomProperty"
import { moment } from "store/util/moment"
import { rootStore } from "store/RootStore"
import { TitlesFull, Titles } from "store/model/traxis/Title"

export const Event_Availability_Part = t
	.model({
		AvailabilityEnd: t.string,
		AvailabilityStart: t.string
	})
	.views(self => ({
		get AvailabilityEndDate() {
			return moment(self.AvailabilityEnd)
		},
		AvailabilityEndDateFormat(format) {
			return moment(self.AvailabilityEnd).format(format)
		},
		get AvailabilityEndDateTs() {
			return moment(self.AvailabilityEnd)
				.utc()
				.valueOf()
		},
		get AvailabilityStartDate() {
			return moment(self.AvailabilityStart)
		},
		AvailabilityStartDateFormat(format) {
			return moment(self.AvailabilityStart).format(format)
		},
		get AvailabilityStartDateTs() {
			return moment(self.AvailabilityStart)
				.utc()
				.valueOf()
		},
		get AvailabilityDurationInMinutes() {
			return Math.round(moment.duration(self.AvailabilityEndDate.diff(self.AvailabilityStartDate)).asMinutes())
		},
		get CurrentDurationInMinutes() {
			return moment.duration(moment(rootStore.time.getTimeStampTick(30)).diff(self.AvailabilityStartDate)).asMinutes()
		},
		get CurrentDurationInPercents() {
			if (self.AvailabilityDurationInMinutes) {
				return (self.CurrentDurationInMinutes / self.AvailabilityDurationInMinutes) * 100
			}
			return 0
		},
		get isCurrentEvent() {
			return self.getCurrentEventType === "c"
		},
		get getCurrentEventType() {
			const timeCurr = rootStore.time.getTimeStampTick(60),
				timeStart = self.AvailabilityStartDateTs,
				timeEnd = self.AvailabilityEndDateTs
			return timeStart <= timeCurr && timeEnd > timeCurr ? "c" : timeStart >= timeCurr ? "n" : "p"
		},
		get DurationInMinutes() {
			if (self.Title && self.Title.DurationInMinutes !== null) {
				return self.Title.DurationInMinutes
			}
			return self.AvailabilityDurationInMinutes
		},
		get CurrentDurationToStartInMinutes() {
			return Math.floor(moment.duration(moment(self.AvailabilityStartDate).diff(rootStore.time.getTimeStampTick(60))).asMinutes())
		},
		isTimeInEventAvailability(time) {
			return self.AvailabilityStartDate.isSameOrBefore(time) && self.AvailabilityEndDate.isAfter(time)
		}
	}))

export const Event_Titles_Collection = (prefix = "", { _Titles = Titles } = {}) =>
	t
		.model({
			Titles: _Titles
		})
		.views(self => ({
			get Title() {
				return self.Titles.Title[0]
			}
		}))

export const Event_CustomProperties_Part = t.model({
	CustomProperties: t.maybeNull(CustomProperties)
})

export const Event = t.compose(
	"Event",
	Event_Availability_Part,
	Event_Titles_Collection(),
	t.model({
		id: t.identifier
	})
)

export const EventFull = t.compose(
	"EventFull",
	Event,
	Event_CustomProperties_Part,
	t.model({
		AspectRatio: t.maybeNull(t.string),
		Is3D: t.boolean,
		IsHD: t.boolean,
		IsLive: t.maybeNull(t.boolean),
		IsNetworkRecordingAllowed: t.boolean,
		// Whether the event has time shift content
		HasTstv: t.maybeNull(t.boolean),
		IsAvailable: t.boolean,
		IsRecorded: t.maybeNull(t.boolean),
		IsNetworkRecordingViewableOnCpe: t.boolean,
		OnReminders: t.boolean,
		Titles: TitlesFull,
		NetworkRecordingEntitlementState: t.string
	})
)

export const Events_Model = (prefix = "", { _Event = Event } = {}) =>
	t
		.model(prefix + "Events", {
			resultCount: t.number,
			Event: t.array(_Event)
		})
		.views(self => ({
			get items() {
				return self.Event
			}
		}))

export const Events = Events_Model("", { _Event: Event })
