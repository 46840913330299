import { createTheme } from "@mui/material/styles"

export const theme = createTheme({
	themeDefaultWidth: 1280,
	themeExtendedWidth: 1536,
	themeSurfaceColor2: "#2A2A2A", //#1c1717 width opacity. TBD
	themeSurfaceColorLight: "#323232", // #3F3F3F with 0.8 opacity over #000 tbd
	themeSurfaceColorSelected: "#AA002C", // #D40037 with 0.8 opacity over #000 tbd
	themeSurfaceColorHover: "#3F3F3F !important",
	themeColorNowWarker: "#af0630",

	palette: {
		primary: {
			main: "#D40037", //"#D61036",
			hover: "#E04C73",
			900: "#BB0022",
			800: "#C9002F",
			700: "#D61036",
			600: "#E81F3D",
			500: "#F82B3E",
			400: "#F14557",
			300: "#E66B78",
			200: "#EF959D",
			100: "#FECBD4",
			50: "#FFEAEF"
		},
		secondary: {
			main: "#191919", //"#1c1717",
			900: "#191919",
			800: "#2A2A2A",
			700: "#3F3F3F",
			600: "#585858",
			500: "#747474",
			400: "#919191",
			300: "#AEAEAE",
			200: "#CCCCCC",
			100: "#E7E7E7",
			50: "#FFFFFF"
		},
		background: {
			default: "#1a1a1a",
			paper: "#1a1a1a"
		},
		mode: "dark"
	},
	spacing: 1,
	typography: {
		fontFamily: "EuropaRegular",
		useNextVariants: true,
		fontOpacity: 0.6,
		body1: {
			lineHeight: "28px",
			fontSize: "20px",
			fontWeight: 400,
			//color: props => props.themeSurfaceColorLight
		},
		body2: {
			lineHeight: "20px",
			letterSpacing: "0.25px"
		},
		body3: {
			lineHeight: "28px",
			fontSize: "16px"
		},
		caption: {
			lineHeight: "16px",
			opacity: "0.6"
		},
		overline: {
			letterSpacing: "2px",
			opacity: "0.6",
			lineHeight: "16px",
			marginBottom: "5px"
		},
		h6: {
			lineHeight: "29px",
			letterSpacing: "0.25px"
		},
		button: {
			letterSpacing: "1.25px",
			fontWeight: 600
		}
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					fontSize: "0.875rem",
					lineHeight: 1.43,
					letterSpacing: "0.01071em",
					// MTVW-754
					overflowY: "auto",
					scrollbarWidth: "none",
					"&::-webkit-scrollbar": { display: "none" }
				},
			},
		},
		/*
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					width: 14,
					height: 14
				}
			}
		},
		/*
		MuiSnackbar: {
			styleOverrides: {
				root: {
					bottom: "0px",
					left: "0px"
				},
			},
		},
		*/
		MuiPaper: {
			styleOverrides: {
				root: {
					color: "#fff",
					backgroundImage: "unset"
				}
			}
		},
		MuiStepper: {
			styleOverrides: {
				vertical: {
					padding: 15
				}
			}
		},
		MuiStepConnector: {
			styleOverrides: {
				vertical: { paddingBottom: 2 },
				lineVertical: { minHeight: 18, marginBottom: 6 }
			}
		},
		MuiStepButton: {
			styleOverrides: {
				vertical: { padding: "4px 9px" }
			}
		},
		MuiStep: {
			styleOverrides: {
				vertical: {},
			}
		},
		MuiToolbar: {
			styleOverrides: {
				regular: {
					"@media(min-width:600px)": {
						minHeight: "56px"
					}
				}
			}
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					minHeight: "40px"
				}
			}
		},
		MuiTab: {
			styleOverrides: {
				root: {
					//backgroundColor: 'red',
					'&.Mui-selected': {
						backgroundColor: "#3F3F3F",
						color: "#FFF",
					},
					color: "#FFF",
					minHeight: "40px",
					letterSpacing: "1.25px",
					fontWeight: 600,
					"@media(min-width:960px)": {
						minWidth: "120px"
					},
					minWidth: "180px", width: "180px", minHeight: "74.8px",
				},
				textColorInherit: {
					opacity: "1"
				}
			}
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					'&.Mui-checked': {
						color: "#FFF",
					},
					color: "#FFF",
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					"&:hover": {
						backgroundColor: "rgba(255, 255, 255, 0.2)"
					}
				}
			}
		},
		/*
		MuiTabTabItem: {
			styleOverrides: {
				root: {
					minWidth: "180px", width: "180px", minHeight: "74.8px"
				}
			}
		},
		*/
		MuiLink: {
			styleOverrides: {
				underlineHover: {
					color: "#FFF",
					cursor: "pointer",
					"&:hover": {
						textDecoration: "none" //everywhere I guess
					}
				}
			},
			defaultProps: {
				underline: 'hover',
			},
		},
		PrivateTabIndicator: {
			styleOverrides: {
				colorSecondary: {
					backgroundColor: "#D40037"//"#FFF"
				}
			}
		},

		MuiListItem: {
			styleOverrides: {
				root: {
					paddingLeft: 0
				}
			}
		},
		MuiGrid: {
			/*
			styleOverrides: ({ ownerState }) => ({
				...ownerState.alignItems === 'flex-end' && {
					marginTop: 20,
				},
			}),
			*/
			/*
			styleOverrides: {
				root: {
					//width: "unset"
				}
			}
			*/
		},
		MuiGridListTileBar: {
			styleOverrides: {
				title: {
					fontSize: "12px",
					padding: "6px"
				}
			}
		},
		MuiLinearProgress: {
			styleOverrides: {
				colorPrimary: {
					backgroundColor: "#746c6e"
				},
				barColorPrimary: {
					backgroundColor: "#FFEAEF"
				}
			}
		},
		MuiCard: {
			styleOverrides: {
				root: {
					borderRadius: 0
				}
			}
		},
		MuiCardMedia: {
			styleOverrides: {
				media: {
					borderRadius: 0
				}
			}
		},
		MuiFab: {
			extended: {
				padding: "0 16px"
			}
		},
		MuiDialog: {
			styleOverrides: {
				paperWidthSm: {
					maxWidth: "720px"
				}
			}
		},
		MuiTypography: {
			styleOverrides: {
				paragraph: {
					marginBottom: 8
				}
			}
		},
		MuiFormControlLabel: {
			styleOverrides: {
				// used at settings page
				label: {
					flex: 1
				},
				labelPlacementStart: {
					marginLeft: 0,
					width: "100%"
				}
			}
		},
		MuiInput: {
			styleOverrides: {
				underline: {
					"&::after": {
						borderBottom: "0 solid"
					}
				}
			}
		},
		MuiExpansionPanelSummary: {
			styleOverrides: {
				root: {
					expanded: { minHeight: "none" }
				}
			}
		}
	}
})

if (import.meta.env.MODE === "development") {
	window.theme = theme
	console.info("Theme theme.js window.theme=%o", theme)
}
