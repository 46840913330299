// l10n status: not applicable
//import { FormControlLabel, Switch } from "@mui/material"
import { CreateComponent } from "components/utils/CreateComponent"
import defaultCoverImg from "img/cover-no-photo.gif"
import React from "react"
import { EpgChannelDetailsInfo, PROGRAM_ORIGIN } from "scenes/components/EpgChannelDetailsInfo"
import { Player } from "scenes/Player/Player"
import { rootStore } from "store/RootStore"
import { ErrorMessage } from "components/ErrorMessage"

import { EpgChannelRelatedContent } from "../components/EpgChannelRelatedContent"
import { CALLING_CONTEXT_TYPE } from "store/page/OverlayEventDetails"
//import { l10n } from "store/lang/L10n"

const styles = theme => ({
	EpgChannelDetailsInfo: { opacity: theme.typography.fontOpacity },
	EpgChannelDetailsWrapper: { paddingTop: "7px", marginTop: "5px" },
	EpgChannelDetailsButton: { color: theme.palette.primary[500], margin: "0 11px" },
	ErrorPosition: { display: "flex", position: "relative" },
	ErrorInfoWrapper: {
		//position: "absolute",
		height: "100%",
		width: "100%",
		left: 0,
		top: 300,
		alignItems: "center",
		//justifyContent: "center",
		display: "flex",
		zIndex: 20000,
	},
	/* CHECK_MERGE
	EpgChannelDetailsInfoScroll: {
		height: "100%",
		overflowY: "scroll"
	}
	*/
	// MTVW-808: tweaks, padding: "24px 36px 24px 36px" -> padding: "0px 36px 24px 36px"
	relatedContentContainer: { padding: "0px 36px 24px 36px" }

})

// MTV-2940 called only from TvLive, use page.player.source.Event and origin={PROGRAM_ORIGIN.liveTv}
export const EpgChannelDetails = CreateComponent(styles, false, true, ({ classes, page }) => {
	// MTVW-186 MTVW-178 added for intercept  OverlayEventDetails error not only player error
	const errorOrigin = rootStore.page.OverlayEventDetails.oEvent?.error ? rootStore.page.OverlayEventDetails.oEvent?.error : page?.player?.error
	const pageForErrorMessage = rootStore.page.OverlayEventDetails.oEvent?.error ? rootStore.page.OverlayEventDetails.oEvent : page?.player
	const isPlayerError = page?.player?.error !== null

	return (
		<div>
			{/*}
		{rootStore.page.Player.error ? console.debug("ERROR") : console.debug("NO ERROR")}
		*/}
			<div className={classes.ErrorPosition} >
				{!rootStore.page.OverlayEventDetails.isVisible && (
					<ErrorMessage error={errorOrigin} isPlayerError={isPlayerError} classes={{ ErrorInfoWrapper: classes.ErrorInfoWrapper }} page={pageForErrorMessage} id="player" />
				)}
			</div>
			<Player defaultCoverImg={defaultCoverImg} />
			<div className={classes.EpgChannelDetailsWrapper + " HideEpgChannelDetailWrapperResponsive"} data-test="livetv_channel_detailpage_wrapper">
				{page.player.source.Event && (
					<>
						{/* Mtv-2759 added padding also in TvLive */}
						<div className={classes.relatedContentContainer} data-test="tvLive-details-info" >
							{/* {console.debug("EpgChannelDetails GOT EVENT %o", page.player.source.Event)} */}
							{/*console.debug("page.player.source %o", page.player.source)*/}
							<EpgChannelDetailsInfo oEventData={page.player.source.Event} page={page} origin={PROGRAM_ORIGIN.liveTv} />
							<EpgChannelRelatedContent oEventData={page.player.source.Event} page={rootStore?.page?.EpgChannelRelatedContent} callingContext={CALLING_CONTEXT_TYPE.TvLive} />
							{/*<FormControlLabel value="start" control={<Switch color="primary" />} label={l10n.dialogRecordOnlyInThisProfileLabel} labelPlacement="start" />*/}

							{/* Placeholder here */}
							{/* <div className="meta-description-suggest" style={{ "height": "1200px", "backgroundColor": "rgba(91, 85, 85, 0.8)" }}></div> */}
						</div>
					</>
				)}
			</div>
		</div>
	)
})
