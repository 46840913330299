import React, { Component } from "react"
import { Card, CardMedia, Typography } from "@mui/material"
import { withStyles } from "@mui/styles"
import Slider from "react-slick"
import { moviesslider } from "./../../../data/MoviesSlider.js"
/*import "./slick.css"*/

const styles = {
	slider: { margin: "0 auto", height: "261px", position: "relative" },
	slide: {
		width: "100%",
		height: "100%",
		alignItems: "baseline",
		display: "flex",
		margin: 6,
		"&::after": {
			content: "''",
			position: "absolute",
			top: 0,
			right: "auto",
			bottom: 0,
			left: "auto",
			backgroundImage: "linear-gradient(to bottom,transparent,#1A0F11)",
			opacity: 1,
			width: "100%"
		}
	},
	slideText: { padding: "20px", alignSelf: "flex-end", zIndex: 100 }
}

export const MoviesSlider = withStyles(styles)(
	class extends Component {
		render() {
			const { classes } = this.props
			const settings = {
				className: "center",
				centerMode: true,
				infinite: true,
				centerPadding: "0",
				slidesToShow: 1,
				speed: 500,
				focusOnSelect: true,
				variableWidth: true
			}
			return (
				<div>
					<Slider {...settings}>
						{moviesslider.map((slide, index) => (
							<div key={index} style={{ width: 844, background: "blue" }}>
								<Card style={Object.assign({})} className={classes.slider}>
									<CardMedia image={slide.poster} className={classes.slide}>
										<Typography variant="h4" className={classes.slideText}>
											{slide.title}
										</Typography>
									</CardMedia>
								</Card>
							</div>
						))}
					</Slider>
				</div>
			)
		}
	}
)
