import React from "react"
import { Grid, Typography } from "@mui/material"
import { withStyles } from "@mui/styles"

const styles = theme => ({})

export const Sport = withStyles(styles)(props => {
	return (
		<React.Fragment>
			<Grid container alignContent="flex-start" spacing={0}>
				<Typography variant="h1">Sport</Typography>
			</Grid>
		</React.Fragment>
	)
})
