import { withStyles } from "@mui/styles"
import React from "react"
import { MoviesGenres } from "./components/MoviesGenres"
import { MoviesMenu } from "./components/MoviesMenu"
import { MoviesSlider } from "./components/MoviesSlider"
import { Login } from "scenes/components/Login"

const styles = theme => ({})

export const Movies = withStyles(styles)(props => {
	return (
		<div>
			<MoviesMenu />
			<MoviesSlider />
			<MoviesGenres />
			<MoviesMenu />
			<MoviesSlider />
			<MoviesGenres /> <MoviesMenu />
			<MoviesSlider />
			<MoviesGenres /> <MoviesMenu />
			<MoviesSlider />
			<MoviesGenres />
			<Login />
		</div>
	)
})
