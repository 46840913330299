import React from "react"

const useScrollListener = (element, handleScroll, throttle = 1000) => {
	const scrollingTimer = React.useRef()

	const listenToScroll = React.useCallback((e) => {
		clearTimeout(scrollingTimer.current)
		scrollingTimer.current = setTimeout(
			() =>
				requestAnimationFrame(() => {
					handleScroll(e)
				}),
			throttle // parameter for the hook, handleScroll will fire max every throttle (ms)
		);
	}, [handleScroll, throttle])

	const removeScrollListener = React.useCallback(() => {
		if (element.current) {
			clearTimeout(scrollingTimer.current)
			// eslint-disable-next-line no-unused-expressions
			element.current?.removeEventListener('scroll', listenToScroll)
		}
	}, [scrollingTimer, listenToScroll, element])

	React.useEffect(() => {
		if (element.current) {
			element.current.addEventListener('scroll', listenToScroll)
		}
		return () => {
			removeScrollListener()
		}
	}, [listenToScroll, removeScrollListener, element])
}

export default useScrollListener
