import { flow /*, observable, computed, action */ } from "mobx"
import { TraxisCpeIdMixin } from "store/api/mixin/TraxisCpeIdMixin"
// MTVW-585: 1 retry
import { fetchWithRetry, DEFAULT_RETRIES, DEFAULT_TIMEOUT } from "./FetchWithRetry"
import { serviceUrls } from "store/qlapi/ServiceUrls"
import { list, object, serializable, deserialize } from "serializr"
import { getQlHeaders } from "store/qlapi/QlHeaders"
import { appVersion } from "utils/TestSupport"

class Feature {
   @serializable title = null
   @serializable body = null
   @serializable image = null
}

class Announcement {
   @serializable id = null
   @serializable type = null
   @serializable title = null
   @serializable body = null
   @serializable image = null
   @serializable page = null
   @serializable(list(object(Feature))) features = []
}


const CREDENTIALS = 'include'
const STD_HEADERS = {
   'Accept': 'application/json',
   // Would cause CORS in keepAlive
   //'pragma': 'no-cache', 'cache-control': 'no-cache'
}

export class OnboardingService extends TraxisCpeIdMixin {

   get xHeaders() {
      // make a copy of STD_HEADERS, otherwise STD_HEADERS would be changed!
      const headers = {}
      Object.assign(headers, STD_HEADERS)
      Object.assign(headers, getQlHeaders())
      return headers
   }

   version = flow(function* (retryCount = DEFAULT_RETRIES) {
      const config = {
         url: serviceUrls.onboardingUrl + "version",
         fetchOptions: {
            method: 'GET',
            headers: this.xHeaders,
            credentials: CREDENTIALS,
         },
         retryCount: retryCount,
         timeout: DEFAULT_TIMEOUT,
         codes: ["OnboardingService version"],
         service: "OnboardingService",
         createException: true,
         displayError: true
      }

      // eslint-disable-next-line no-unused-vars
      const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
      return `${resultJson["build_tag"]} (${resultJson["build_date"]})`
   })

   versionUrl() {
      return [serviceUrls.onboardingUrl + "version", this.xHeaders]
   }

   announcementsAsync = flow(function* (contractId, screenSize, retryCount = DEFAULT_RETRIES) {
      const version = appVersion().replace("v", "")
      const url = serviceUrls.onboardingUrl + `contracts/${contractId}/announcements?appVersion=${version}&deviceType=web&screenSize=${screenSize}`
      const config = {
         url: url,
         fetchOptions: {
            method: 'GET',
            headers: this.xHeaders,
            credentials: CREDENTIALS,
         },
         retryCount: retryCount,
         timeout: DEFAULT_TIMEOUT,
         codes: ["OnboardingService announcementsAsync"],
         service: "OnboardingService",
         createException: true,
         displayError: true
      }

      // eslint-disable-next-line no-unused-vars
      const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
      // deserializes an array of Announcement objects
      return deserialize(Announcement, resultJson, (err, result) => {
      })
   })

   clearAnnouncementsAsync = flow(function* (contractId, retryCount = DEFAULT_RETRIES) {
      const url = serviceUrls.onboardingUrl + `contracts/${contractId}/announcements`
      const config = {
         url: url,
         fetchOptions: {
            method: 'DELETE',
            headers: this.xHeaders,
            credentials: CREDENTIALS,
         },
         retryCount: retryCount,
         timeout: DEFAULT_TIMEOUT,
         codes: ["OnboardingService clearAnnouncementsAsync"],
         service: "OnboardingService",
         createException: true,
         displayError: true
      }

      // eslint-disable-next-line no-unused-vars
      const [response, resultJson, requestId, error] = yield fetchWithRetry(config)
      // deserializes an array of Announcement objects
      return deserialize(Announcement, resultJson, (err, result) => {
      })
   })

   announcementSeenAsync = flow(function* (contractId, announcementId, retryCount = DEFAULT_RETRIES) {
      const url = serviceUrls.onboardingUrl + `contracts/${contractId}/announcements/${announcementId}`
      const config = {
         url: url,
         fetchOptions: {
            method: 'PUT',
            headers: this.xHeaders,
            credentials: CREDENTIALS,
         },
         retryCount: retryCount,
         timeout: DEFAULT_TIMEOUT,
         codes: ["OnboardingService announcementSeenAsync"],
         service: "OnboardingService",
         createException: true,
         displayError: true
      }

      // eslint-disable-next-line no-unused-vars
      const [response, resultJson, requestId, error] = yield fetchWithRetry(config)

      //console.debug("announcementSeenAsync json", resultJson)
   })

   getAnnouncementPage = (contractId, announcements) => {
      let url = null
      if (announcements?.length > 0) {
         const htmlAnnouncements = announcements.filter(i => i.page !== null)
         if (htmlAnnouncements?.length > 0) {
            url = htmlAnnouncements[0].page
            //console.debug("onboarding url", url)
            if (!url.startsWith("http")) {
               url = new URL(url, serviceUrls.onboardingUrl + `contracts/${contractId}/announcements/`)
               //console.debug("onboarding url", url, url.href)
               url = url.href
            }
            //console.debug("onboarding url", url)
         }
      }
      return url
   }
}
