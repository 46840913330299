import { detect } from "detect-browser"
import { flow } from "mobx"
import { printf } from "fast-printf"
import { moment } from "store/util/moment"

/* previous criteria
	const touchBackend = (browser && ((browser.os === "iOS") || (browser.os === "iPadOS") || isPadOS() || (browser.os === "Android OS"))) ? true : false
*/

const browser = detect()
const MOBILE_SIZE = 540


export const isTouchAvailable = (): boolean => {
	//console.debug("isTouchAvailable ontouchstart %s, maxTouchPoints %s", 'ontouchstart' in window, navigator.maxTouchPoints)
	return ('ontouchstart' in window) ||
		(navigator.maxTouchPoints > 0)
		// only IE10 
		//|| (navigator.msMaxTouchPoints > 0)
}

export const isIpad = ():boolean => {
	// MTV-2818: Since Apple claimed Desktop-Class Browsing with Safari on iPadOS, mobile Safari seems to mimic macOS behavior and user agent.
	// The actual value of maxTouchPoints on the iPad is 5, thus it should be pretty safe.
	let iPad = false
	if (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
		iPad = true
	}
	return iPad
}

export const isIphone = (): boolean => {
	return browserName().toLowerCase().indexOf("ios") >= 0
}

export const isSafari = ():boolean => {
	return ((browserName() === "safari") || (browserName() === "ios") || isIpad())
}

export const safariMainVersion = ():number => {
	if (isSafari()) {
		const version = browserVersion().split(".")
		//console.debug("SAFARI VERSION %o", version)
		if (version?.length > 0) {
			return parseInt(version[0])
		}
	}
	return -1
}

export const isMobileOrTablet = ():boolean => {
	// Windows might have touch support devices, Windows Mobile is dead
	console.debug("browserOs %s isTouch %s", browserOs(), isTouchAvailable())
	return isTouchAvailable() && browserOs().indexOf("Windows") < 0
}

export const browserName = ():string => {
	return browser?.name ? browser?.name?.toString() : "n/a"
}

export const browserVersion = ():string => {
	return browser?.version ? browser?.version?.toString() : "n/a"
}

// TODO: add separate methods for OSs to avoid hardcoded strings everywhere, e.g. isAndroid, etc.
export const browserOs = ():string => {
	// fix for Chrome on Android, Chrome reports "Linux" since v???
	if (browser?.os) {
		let os = browser.os.toString()
		if (os.indexOf("Linux") >= 0) os = "Android or Linux"
		return os
	}
	else return "n/a"
}

export const isAndroid = (): boolean => {
	return browserOs().indexOf("Android") >= 0
}

export const isMobileScreenSize = ():boolean => {
	/*
	// eslint-disable-next-line no-alert
	alert("width: " + window.innerWidth + " height: " + window.innerHeight)
	*/

	// On Galaxy S6 Tab Lite, innerHeight in portrait mode is 632 pixels and it shows 1,5 grid rows
	// 540 should be a safe value for the channel list editor in portrait mode
	// (channel list left, 1 row of the grid at the right side)
	return Math.min(window.innerWidth, window.innerHeight) < MOBILE_SIZE
}


export const isMobileScreenSizeWidth = ():boolean => {
	return window.innerWidth < MOBILE_SIZE
}

export const isPhoneSize = ():boolean => {
	// css mobileFirst the condition identify not a mobile so need to invert the result of matchMedia(query)
	return !window.matchMedia("(min-width:540px) and (min-height:415px) , not screen and (max-width:823px)").matches
}

export const isDesktop = (): boolean => {
	return (browser?.os.indexOf("Windows") >= 0 || browser?.os.indexOf("Mac OS") >= 0) && !isIpad()
}

export const screenInfo = () => {
	let orient = "landscape"
	// https://dev.to/capscode/how-to-detect-screen-orientation-using-javascript-b0m
	// screen.orientation.type not available on Edge/iOS and iPadOS
	//if (screen.orientation.type === "landscape-primary" || screen.orientation.type === "landscape-secondary") {
	// https://stackoverflow.com/questions/5456582/screen-width-and-screen-availwidth-difference-in-javascript#:~:text=width%20%2D%3E%20Returns%20the%20width%20of,pixels%20available%20to%20the%20window.
	if (screen.availWidth < screen.availHeight) orient = "portrait"
	return {
		orientation: orient,
		sWidth: screen.width,
		aWidth: screen.availWidth,
		oWidth: window.outerWidth,
		iWidth: window.innerWidth,
		aHeight: screen.availHeight,
		sHeight: screen.height,
		oHeight: window.outerHeight,
		iHeight: window.innerHeight,
	}
}

export const checkMSE = () => {
	const mse = [{
		id: 'mp4avc',
		codec: 'video/mp4; codecs="avc1.4D4028"',
		supported: false
	}, {
		id: 'mp4ec3',
		codec: 'video/mp4; codecs="ec-3"',
		supported: false
	}, {
		id: 'webm',
		codec: 'video/webm; codecs="vorbis,vp8"',
		supported: false
	}, {
		id: 'mp2t',
		codec: 'video/mp2t; codecs="avc1.42E01E,mp4a.40.2"',
		supported: false
	}]

	let formatted = ''
	for (var i = 0; i < mse?.length; i++) {
		mse[i].supported = false
		if ('MediaSource' in window && MediaSource.isTypeSupported(mse[i].codec))
			mse[i].supported = true
		formatted += printf('id %s, codec %s, supported %s\n', mse[i].id, mse[i].codec, mse[i].supported)
	}

	//console.debug("formatted", formatted)
	return [mse, formatted]
}

// from https://github.com/videojs/videojs-contrib-eme/tree/main#detectsupportedcdms
// calling navigator.requestMediaKeySystemAccess()) can have user-visible effects,
// such as prompting for system resource permissions, which could be disruptive if invoked at inappropriate times.
// use this function sparingly
let _fairplayPromise = new Promise(resolve => {
	const config = {
		initDataTypes: ['cenc', 'sinf', 'skd'],
		videoCapabilities: [{ contentType: 'video/mp4; codecs="avc1.42E01E"' }],
	}
	navigator.requestMediaKeySystemAccess('com.apple.fps', [config]).then(response => {
		console.warn("fairplay supported, keySystem %s", response.keySystem)
		resolve(true)
	})
		.catch(() => {
		console.warn("No fairplay support")
		resolve(false)
	})
})

export const isFairPlaySupported = flow(function* () {
	return yield _fairplayPromise
})

let _widevinePromise = new Promise(resolve => {
	const start = performance.now()
	const config = {
		initDataTypes: ['cenc'/*, 'sinf', 'skd'*/],
		audioCapabilities: [{contentType: 'audio/mp4; codecs="mp4a.40.2"', robustness: 'SW_SECURE_CRYPTO'}],
		videoCapabilities: [{ contentType: 'video/mp4; codecs="avc1.4D401E, avc1.4D4020, avc1.640020, avc1.64002A"', robustness: 'SW_SECURE_CRYPTO' }],
	}
	navigator.requestMediaKeySystemAccess('com.widevine.alpha', [config]).then(response => {
		const end = performance.now()
		console.warn("widevine supported, keySystem %s, duration:", response.keySystem, end - start, start, end)
		resolve(true)
	})
		.catch(() => {
		console.warn("No widevine support")
		resolve(false)
	})
})

export const isWidevineSupported = flow(function* () {
	return yield _widevinePromise
})

export const timeNow = (): string => {
	return moment().local().format("HH:mm:ss.SSS")//new Date().toLocaleTimeString()
}

// to be used with await
export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

// MTVW-585: backoff
export const backoff = (retry: number): number => {
	// see https://cloud.google.com/iot/docs/how-tos/exponential-backoff
	const maxBackoff = 32000
	if (retry < 1) retry = 1
	return Math.trunc(Math.min(Math.pow(2, 2 + retry - 1) * 1000 + Math.random() * 12000, maxBackoff))
}

// MTVW-585: shouldRetry
export const shouldRetry = (status: number, retryCount: number): boolean => {
	// https://denalibalser.medium.com/best-practices-for-retry-685bf58de797
	switch (status) {
		case 400: // Bad Request
		case 401: // Unauthorized
		case 403: // Forbidden -> generates mediaError403
		case 404: // Not Found
		case 410: // GONE
		case 500: // Internal Server Error
		case 503: // Service Unavailable
			return false
		default:
			return retryCount !== 0
	}
}

export const getUrlQueryParam = (param: string): any => {
	const searchParams = new URLSearchParams(window.location.href)
	for (const [key, value] of searchParams.entries()) {
		// No URL parsing, see https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
		const cKey = key.substring(key.indexOf("?") + 1)
		if (cKey === param) {
			//console.debug("found", cKey, value)
			return value
		}
	}
	return null
}
