import { Input, Button } from "@mui/material"
import { CreateComponent } from "components/utils/CreateComponent"
import React, { useEffect, useRef, useState } from "react"
import CloseIcon from '@mui/icons-material/Close';
import FilterChannelicon from './icons/channel-filter.svg'
import "./filterChannelInput.css"

export const IMPUT_CHANNEL_LIST_FILTER_ID = "inputChannelListFilter"

const styles = theme => ({
	tvGuideChannelListFilter: {
		display: "flex",
		background: "transparent",
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
		cursor: "pointer",
		"-webkit-user-select": "none",
		"-moz-user-select": "none",
		"-ms-user-select": "none",
		"user-select": "none",
		"-webkit-tap-highlight-color": "transparent",
		//maxWidth: 100
	},
	tvGuideFilterChannelText: {
		marginLeft: "5px",
		marginRight: "5px",
		background: "transparent !important",
		color: "#FFFFFF",
	},
	tvGuideChannelListFilterButton: {
		width: 100,
		background: "transparent",
		"-webkit-user-select": "none",
		"-moz-user-select": "none",
		"-ms-user-select": "none",
		"user-select": "none",
		"-webkit-tap-highlight-color": "transparent",
	},
	tvGuideChannelListFilterButtonWidthLabel: {
		display: "flex",
		alignItems: "center",
		"-webkit-user-select": "none",
		"-moz-user-select": "none",
		"-ms-user-select": "none",
		"user-select": "none",
		"-webkit-tap-highlight-color": "transparent",
		fontSize: "16px",
		letterSpacing: "0.4px",
		width: 150,
		background: "transparent",
		textTransform: "none",
		fontWeight: "normal",
		"& input:-internal-autofill-selected": {
			background: "red",
			color: "red"
		}
	}

});

export const TvGuideChannelFilter = CreateComponent(styles, false, true, ({ classes, page, showLabel = false, changeFilterForChannelSearch }) => {
	const [filterChannel, setFilterChannel] = useState(false)
	const filter = useRef()


	useEffect(() => {
		if (filterChannel) { filter.current.focus() }
	}, [filterChannel])

	return (
		<div id="filter_channel" className={classes.tvGuideChannelListFilter}>
			{filterChannel ?
				<>
					<Input id={IMPUT_CHANNEL_LIST_FILTER_ID}
						inputRef={filter}
						className={classes.tvGuideFilterChannelText}
						placeholder="Sender"
						onChange={(e) => changeFilterForChannelSearch(e.target.value)} />

					<CloseIcon onClick={() => {
						setFilterChannel(false)
						changeFilterForChannelSearch("")
					}} />
				</>
				:
				<Button className={showLabel ? classes.tvGuideChannelListFilterButtonWidthLabel : classes.tvGuideChannelListFilterButton} onClick={() => {
					setFilterChannel(true)
				}}>
					<img src={FilterChannelicon} alt="filter channel" />
					{showLabel && <span style={{ textTransform: "none", fontSize: "1rem", letterSpacing: "0.4px", fontWeight: "normal", color: "#FFF" }} className="FilterChannelText">Senderfilter</span>}
				</Button>
			}
		</div>
	);
})

//MTVW-323  this code has been commented out and replaced with the code above Button to align this component to the filter lists Channel
// {/* <div className={showLabel ? classes.tvGuideChannelListFilterButtonWidthLabel : classes.tvGuideChannelListFilterButton} onClick={() => {
// 					setFilterChannel(true)
// 				}}>
// 					<img src={FilterChannelicon} alt="filter channel" />
// 					{showLabel && <span>Filter Sender</span>}
// 				</div> */}
