import React from "react"
import { generatePath, useLocation, useHistory } from "react-router"
import { matchRoutes, renderRoutes } from "react-router-config"
import { Link } from "react-router-dom"

export const RouterProvider = React.createContext()

export class RouteStore {
	routeStoreByName = new Map()
	routeStoreByPath = new Map()
	_arrayCache = null

	_getArrayCache() {
		if (this._arrayCache === null) {
			this._arrayCache = Array.from(this.routeStoreByName.values())
		}
		return this._arrayCache
	}

	add(name, path, component, vars = {}, exact = true) {
		this.routeStoreByName.set(name, { ...vars, ...{ name, path, exact, component } })
		this.routeStoreByPath.set(path, name)
		this._arrayCache = null
		return this
	}

	renderRoutes(...props) {
		return <>{renderRoutes(this._getArrayCache(), ...props)}</>
	}

	matchRoutes(pathname) {
		return matchRoutes(this._getArrayCache(), pathname)
	}

	getVar(pathname, varName, defVal = null) {
		return this.matchRoutes(pathname)?.[0].route?.[varName] ?? this.matchRoutes(pathname)?.[0].route?.name ?? defVal
	}

	getPath(routeName = null, params = null, routeMatch) {
		if (routeName !== null) {
			const route = this.routeStoreByName.get(routeName)
			return generatePath(route.path, { ...route, ...params })
		}

		const route = this.matchRoutes(routeMatch.pathname) ?? []
		for (let i = 0; i < route?.length; i++) {
			try {
				return generatePath(route[i].match.path, { ...route[i].match.params, ...params })
			} catch (e) {
				// eslint-disable-next-line no-empty
			}
		}
	}
}

export const useRouteStore = () => {
	return React.useContext(RouterProvider)
}

export const useRouteLink = () => {
	const routeStore = useRouteStore()
	const routeMatch = useLocation()

	return (routeName = null, params = null) => routeStore.getPath(routeName, params, routeMatch)
}

export const useRoutePropsUi = () => {
	const routeLink = useRouteLink()
	return (routeName = null, params = null) => ({
		to: routeLink(routeName, params),
		component: Link
	})
}

export const RouteLink = ({ name = null, params = null, children, Component, ...props }) => {
	const routeLink = useRouteLink()
	return (
		<Component to={routeLink(name, params)} component={Link} {...props}>
			{children}
		</Component>
	)
}

export const useHistoryRouteAdd = () => {
	const history = useHistory()
	const routeLink = useRouteLink()
	return (routeName = null, params = null) => history.push(routeLink(routeName, params))
}
