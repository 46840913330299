import { flow, runInAction } from "mobx"
import { rootStore } from "store/RootStore"
import { logTraxisError } from "utils/BugsnagReporter"
import { MicroServiceError } from "./MicroServiceError"
import { EventEmitter } from "components/utils/EventEmitter"
import { appStats, updateMemoryStats } from "utils/TestSupport"
import { TimeoutError } from "./TimeoutError"
import { timeNow, sleep, backoff, shouldRetry } from "utils/Utils"

// MTVW-585: 1 retry
export const DEFAULT_RETRIES = 1
export const NO_RETRIES = 0
export const DEFAULT_TIMEOUT = 40000

export const fetchWithRetry = flow(function* (config, currentTry = 0) {
	//console.debug("fetchWithRetry", config, currentTry)
	let response, error, resultJson, requestId
	[response, error] = yield doFetch(config, currentTry)
	if (error) {
		yield _handleFetchError(response, error, config, currentTry)
	}
	else {
		//console.debug("fetchWithRetry received url= %s, retryCount= %s, response= %o", config.url, config.retryCount, response)
		appStats.reqReceived++
		[response, resultJson, requestId, error] = yield _getResponse([response, error], config)
		//console.debug("_getResponse resultJson", resultJson, requestId, error)
		//console.debug("shouldRetry", response?.status, shouldRetry(response?.status, config.retryCount))
		error = _checkResponse([response, resultJson, requestId, error], config, currentTry)
	}
	if (!error) {
		return [response, resultJson, requestId, error]
	} else {
		//console.debug("shouldRetry", response?.status, shouldRetry(response?.status, config.retryCount))
		if (shouldRetry(response?.status, config.retryCount)) {
			config.retryCount--
			appStats.reqRetries++
			appStats.reqSent--
			// MTVW-585: backoff
			const val = backoff(currentTry + 1)
			const start = performance.now()
			yield sleep(val)
			console.debug("backoff ms %s, sleep %s, stat %s, url %s", val, performance.now() - start, response?.status, config.url)
			return fetchWithRetry(config, currentTry + 1)
		}
		else {
			appStats.reqErrors++
			appStats.reqReceived++
			//logTraxisError(error, {})
			//console.debug("fetchWithRetry retry=0, url= %s, retryCount= %s, response %o, error %o", config.url, config.retryCount, response, error)
			return [response, resultJson, requestId, error]
		}
	}
})

const _handleFetchError = flow(function* (response, error, config, currentTry) {
	// error case
	console.error('%s: _handleFetchError error rsp %o, retry %s, curTry %s, url %s, options %o, error %o', timeNow(), response, config.retryCount, currentTry, config.url, config.fetchOptions, error)
	if (error instanceof TimeoutError && !shouldRetry(response?.status, config.retryCount)) {
		// MTVW-633
		rootStore.logService.analyticsAsync("msRequestTimedOut", 0, { url: config.url, timeoutDuration: config.timeout, requestId: "not-accessible" })
		const err = MicroServiceError.tryCreateErrorFromJson(["Microservice timeout"], response, response, `Request failed with timeout`, config.fetchOptions.headers["X-Request-Id"], "error")
		logTraxisError(err, {})
		//return Promise.reject(new TimeoutError())
	}
})

const doFetch = flow(function* (config, currentTry) {
	// MTV-2133: Add timeout
	const controller = new AbortController()
	// includes the controller's `signal` in the request options
	const options = { ...config.fetchOptions, signal: controller.signal }
	//console.debug("doFetch url= %s, retryCount= %s, currentTry= %s", config.url, config.retryCount, currentTry)
	// MTVW-471: TODO (MTVW-549: server configuration on streamers)
	if (!config.url.includes("streamer") && options?.headers) options.headers["X-Request-Id"] = rootStore.appSession.uniqueRequestId
	//else console.warn("NO HEADERS in doFetch url %s ", url/*, new Error().stack*/)
	// If the timeout is reached before the request is completed, it will be cancelled.
	let aborted = false
	const _timeout = setTimeout(() => {
		aborted = true
		controller.abort()
	}, config.timeout)

	appStats.reqSent++

	let response = null
	let error = null
	try {
		response = yield fetch(config.url, options)
	}
	catch (err) {
		error = aborted ? new TimeoutError() : err
		// will be displayed in _handleFetchError
		//console.error("caught in doFetch", config.url, error)
	}
	clearTimeout(_timeout)
	updateMemoryStats()
	return [response, error]
	// MTV-3180: retry doesn't make much sense since the request will usually return the same result again
	/*
	if (!response?.ok) {
		console.error("doFetch NOT OK, retryCount %s", retryCount);
		if (retryCount > 0) {
			appStats.reqRetries++
			appStats.reqSent--
			doFetch(url, options, retryCount - 1)
		}
	}
	*/
})

const _getResponse = flow(function* (fetchResult, config) {
	//[response, error], config.codes, config.service, config.createException, config.displayError
	let [response, error] = fetchResult
	//console.debug("_getResponse", response, error, config)
	// MS needs to provide Access-Control-Expose-Headers X-Request-Id
	let requestId = null
	if (response?.headers) requestId = response.headers.get("X-Request-Id")
	if (!requestId) requestId = "not-accessible"
	const contentType = response.headers.get("content-type")
	const contentLength = response?.headers.get("content-length") ? parseInt(response?.headers.get("content-length")) : null
	// content-length is not properly defined for all requests
	if (response?.status !== 204 && contentLength === null) console.warn("_getResponse contentType %s, contentLength %s, headerValue %o, rsp %o, error %o", contentType, contentLength, response?.headers.get("content-length"), response, error)
	let resultJson = {}
	//console.debug("noJsonResponse", config.noJsonResponse, response)
	try {
		if (response?.status !== 204 && contentLength !== 0 && config.noJsonResponse !== true) resultJson = yield response?.json()
		///*if (response?.ok && response?.status !== 204 && contentLength !== 0)*/ resultJson = yield response?.json()
	}
	catch (ex) {
		// empty body causes 'SyntaxError: Unexpected end of JSON input', ignore
		if (ex instanceof TypeError) {
			error = ex
		}
		console.error("resultJson len= %s, json %o, rsp %o, error %o", contentLength, resultJson, response, ex)
	}
	return [response, resultJson, requestId, error]
})

const _checkResponse = (result, config, currentTry) => {
	let [response, resultJson, requestId, error] = result
	if (error !== null) {
		error = MicroServiceError.createErrorFromException(config.codes, `Request failed with ${error?.message}`, requestId, "error")
		// MTVW-440: TEST
		//if (config.service === "ChannelsService") {
		if (error.message?.toLowerCase().indexOf("failed to fetch") > -1) {
			// TEST purpose
			/*
			const test = {
				"status": 503,
				"id": "generic_default",
				"title": "Test",
				"detail": "Es ist ein unbekannter Fehler aufgetreten. Wir arbeiten daran, diesen schnellstm�glich zu beheben.",
				"hint": "popup",
				"action": {
					"type": "",
					"name": ""
				},
				"source": "epg",
				"internal": "failed to lock inmemorydb: priming"
			}
			const error = MicroServiceError.tryCreateErrorFromJson(config.codes, test, `Request failed with status ${response?.status}`, requestId, "error")
			rootStore.page.Player.error = error
			throw error
			*/
			// END TEST purpose
			console.error(`Failed to fetch: ${config.service} (possibly CORS), exception %o`, error)
			// MTVW-440
			const info = {
				"title": "Kommunikationsfehler",
				"detail": "Bei der Kommunikation mit dem Server ist ein Problem aufgetreten.",
			}
			error = MicroServiceError.tryCreateErrorFromJson(config.codes, response, info, `Request failed with status ${response?.status}`, requestId, "error")
			// MTVW--440
			if (!shouldRetry(response?.status, config.retryCount) && config.displayError)
				runInAction(() => {
					rootStore.page.Player.error = error
				})
			//return Promise.reject([])
		}
		console.error(`Error in ${config.service} %o`, config.createException, error)
		if (!shouldRetry(response?.status, config.retryCount)) {
			if (config.createException) {
				//return Promise.reject(exception)
				throw error
				// function returns error
			}
			else {
				logTraxisError(error, {})
			}
		}
	}
	else if (response !== null) {
		if (!response.ok) {
			// MTV-3829: 410 could produce an endless loop without error message
			if (!shouldRetry(response?.status, config.retryCount) && config.service === "MediaService" && ((response?.status === 403) /*|| (response?.status === 410)*/)) {
				//console.debug("resp ok %s status %s", response.ok, response?.status)
				// restart stream
				//console.debug("mediaError403: response %o, restarting stream", response)
				console.error("mediaError403: response %o", response)
				// MTVW-567, MTVW-568: Do not restart on 403 for keepalive
				if (!response.url.includes("/keepalive")) EventEmitter.dispatch("mediaError403", response)
				else {
					console.error("%s: _checkResponse keepalive %o", timeNow(), response)
					if (config.createException) {
						const generated = {
							"status": 403,
							"internal": "keepAlive failed"
						}
						error = MicroServiceError.tryCreateErrorFromJson(config.codes, response, generated, `Request failed with status ${response?.status}`, requestId, "error")
						throw error
						// function returns error
					}
					else {
						EventEmitter.dispatch("playerError", response)
					}
				}
			}
			else {
				error = MicroServiceError.tryCreateErrorFromJson(config.codes, response, resultJson, `Request failed with status ${response?.status}`, requestId, "error")
				if (config.url.includes("/live")) console.warn("LIVE", resultJson, error)
				if (!config.createException) console.error(`response Error in ${config.service} %s, createException %s, %o`, response?.status, config.createException, error)
				// MTVW-455
				// media MS returns hint "player"
				if (!shouldRetry(response?.status, config.retryCount)) {
					if (config.displayError && !rootStore.page.Player.error && (error.hint === "popup" || error.hint === "player"))
						runInAction(() => {
							rootStore.page.Player.error = error
						})
					if (config.createException) {
						//return Promise.reject(error)
						throw error
						// function returns error
					}
					else {
						logTraxisError(error, {})
					}
				}
			}
		}
	}
	return error
}

