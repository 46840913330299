import { types as t, isAlive, resolveIdentifier } from "mobx-state-tree"
import { Channel } from "./Channel"

export const ChannelList = t.model({
	id: t.identifier,
	Name: t.string,
	ProfileId: t.string,
	IsPersonal: t.boolean,
	Channels: t.maybeNull(
		t
			.model({
				resultCount: t.number,
				Channel: t.array(t.model("ChannelListChannels", { id: t.identifier }))
			})
			.views(self => ({
				get items() {
					return isAlive(self) ? self.Channel.map(i => resolveIdentifier(Channel, self, i.id)).filter(i => i) : []
				}
			}))
	),
	// MTVW-657: added for marking the first predefined channel list
	firstPredefined: t.maybeNull(t.boolean)
})

export const ChannelLists = t
	.model("ChannelLists", {
		resultCount: t.number,
		ChannelList: t.array(ChannelList),
		isReady: t.maybeNull(t.boolean)
	})
	.views(self => ({
		get items() {
			return self.ChannelList
		}
	}))
	// added for mobx 6.3 which is more strict
	.actions(self => ({
		setReady(value) {
			self.isReady = value
		}
	}))
