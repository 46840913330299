/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-09
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-09
 */

import React from "react"

export const imgPropsHelper = ({ fallback = null, hideBefore = false, params = null, src, ...props }) => {
	if (src !== null) {
		if (fallback !== null) {
			props.onError = ev => {
				ev.target.src = fallback
			}
		}
		if (hideBefore === true) {
			props.onLoad = ev => {
				ev.target.style.visibility = ""
			}
			props.style = props.style ? { ...props.style, ...{ visibility: "hidden" } } : { visibility: "hidden" }
		}
		if (params !== null) {
			props.src = src + "?" + params
		} else {
			props.src = src
		}
	} else if (fallback !== null) {
		props.src = fallback
	} else {
		return {}
	}
	return props
}

export const Img = ({ ...props }) => (
	// eslint-disable-next-line jsx-a11y/alt-text
	<img {...imgPropsHelper(props)} />
)
