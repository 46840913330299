// l10n status: partial
import { CardMedia, Collapse, Grid, Typography, Button } from "@mui/material"
import SwitchBase from "@mui/material/internal/SwitchBase"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { CreateComponent } from "components/utils/CreateComponent"
import React, { useRef, useState, useLayoutEffect, useEffect } from "react"
import { EpgChannelDetailsToolsInfo, EpgChannelDetailsToolsTitleSecondary } from "scenes/components/EpgChannelDetailsTools"
import { RecordingButtons } from "scenes/components/RecordingButtons"
import { RecordingIndicator } from "scenes/components/RecordingIndicator"
//import defaultCoverImg from "img/cover-no-photo.gif"
import { logResourceLoadFailure } from "utils/BugsnagReporter"
import { rootStore } from "store/RootStore"
import ffAd_BlackCircle from "img/player/FFad_BlackCircle.svg"
import ffAd_icon from "img/player/ffAd_icon.svg"
import { SkipAdDialog, infoMsgSkipAd } from "./skipAdModal/skipAdModal"
import { ROUTE_NAMES } from "site-ql/RouteConst"
import { useHistoryRouteAdd } from "components/utils/RouteUtils"
import { l10n } from "store/lang/L10n"
import { getPersonPicture } from "store/tmdb/Tmdb.js"
import directorBadge from "img/player/director_small.png"
import personSvg from "img/player/person.svg"
import { CALLING_CONTEXT_TYPE } from "store/page/OverlayEventDetails"
import { ButtonPlay, ButtonLive, ButtonRestart, ButtonPremium } from "./ButtonsStock"
import Velocity from "velocity-animate"
import "velocity-animate/velocity.ui.min.js"
import { throttle } from "lodash";

//import "./EpgChannelDetailsInfo.css"
import "./../Overlay/OverlayEventDetails.css"

const styles = theme => ({
	EpgChannelDetailsRecording: { opacity: theme.typography.fontOpacity },
	EpgChannelDetailsLogoWrapper: { alignItems: "baseline", justifyContent: "flex-end", padding: "0px 0px 0px 0px", display: "flex", opacity: 0.7 },
	EpgChannelDetailsTextWrapper: { marginTop: -20, marginBottom: 0 },
	EpgChannelDetailsLogo: { maxWidth: 65, maxHeight: 65, marginLeft: 0, marginRight: 0 },
	EpgChannelDetailsAttribute: { display: "flex", marginTop: 3 },
	EpgChannelDetailsKey: { opacity: theme.typography.fontOpacity },
	EpgChannelDetailsValue: { paddingLeft: "20px" },//flexGrow: 0, flexShrink: 0, flexBasis: "77%" },
	EpgChannelDetailsInfo: {
		flex: 1,
		paddingLeft: "60px",
		"&:first-child": {
			paddingTop: 30,
			paddingBottom: 8,
		},
	},
	EpgChannelDetailsSwitch: {
		marginTop: "auto",
		"&:hover": {
			background: "none"
		}
	},
	EpgChannelDetailsBackgroundImg: {
		//MTV-2698
		//backgroundColor: "black",//"rgba(33, 31, 31, 0.5)",
		// backgroundRepeat: "no-repeat",
		//backgroundSize: "cover !important",

		//MTV-2759 padding: "24px 24px 0px 24px",
		// MTVW-810: commented
		//boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 2px 10px 0 rgba(0, 0, 0, 0.5)"
	},
	channelDetailInfo: { marginBottom: 0 },
})

export const PROGRAM_DETAILS_MODE = {
	desktop: "desktop",
	tabletOrMobile: "tabletOrMobile",
	liveTv: "liveTv"
}

export const PROGRAM_ORIGIN = {
	liveTv: "liveTv",
	overlay: "overlay"
}

export const ProgramDetailsInfo = CreateComponent(styles, false, true, ({ page, oEventData, programDetailsMode, origin = PROGRAM_ORIGIN.overlay }) => {
	const nDash = '\u2013'
	// MTVW-810: resize
	// CAUTION: must fit @media (max-width: 540px) in OverlayEventDetails.css
	const widthBreak = 800
	const [innerWidth, setInnerWidth] = useState(window.innerWidth)
	// MTVW-808: liveTv / overlay decoupling
	//const adSkipInfo = rootStore.page.OverlayEventDetails?.adSkipInfo
	const [adSkipInfo, setAdSkipInfo] = useState(null)

	//console.debug("oEventData", oEventData)

	// don't use ...page.getMountBody, it would cause LiveTv to unmount/mount when closing OverlayDetails in LiveTv
	useEffect(() => {
		window.addEventListener("resize", handleResize)
		handleResize()
		setAdSkipInfo(null)
		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [])

	// MTVW-808: liveTv / overlay decoupling, check channelId
	useEffect(() => {
		//console.debug("setAdSkipInfo", oEventData?.ChannelId, rootStore.page.MsEpg?.getChannel(oEventData?.ChannelId)?.hasAlternativeAds ? rootStore.page.OverlayEventDetails?.adSkipInfo : null)
		if (rootStore.page.OverlayEventDetails?.adSkipInfo?.channelId === oEventData?.ChannelId) setAdSkipInfo(rootStore.page.OverlayEventDetails?.adSkipInfo)
	}, [rootStore.page.OverlayEventDetails?.adSkipInfo])

	useEffect(() => {
		setAdSkipInfo(null)
	}, [oEventData, origin])

	const handleResize = () => {
		setInnerWidth(window.innerWidth)
	}

	let styling = {}
	if (programDetailsMode === PROGRAM_DETAILS_MODE.tabletOrMobile)
		styling = { position: "relative", paddingLeft: 35, paddingRight: 0, paddingTop: 0 }
	else if (programDetailsMode === PROGRAM_DETAILS_MODE.liveTv)
		styling = { position: "relative", paddingLeft: 0, paddingRight: 0, paddingTop: 18 }

	//const MovieInfoMain = (() => {
	const MovieInfoMain = CreateComponent(styles, false, true, ({ classes }) => {
		return (
			<div className="coverMovieInfoMainContainer">
				<div className="coverMovieInfoContainer">
					<div className="coverChannelLogoContainer">
						{/* MTVW-831: "mode=box&w=256" -> "mode=box&w=480&h=270" */}
						<img className="coverChannelLogo" src={rootStore.page.MsEpg.getChannel(oEventData?.ChannelId)?.logoUrl + "?mode=box&w=480&h=270"} data-test="detail_info_channel" title={rootStore.page.MsEpg.getChannel(oEventData?.ChannelId)?.Name} />
					</div>
					<div className="coverTimeContainer">
						<div className="coverDate" data-test="detail_info_date">{oEventData?.AvailabilityStartDateFormat?.("dddd, DD.MM.YYYY")}</div>
						<div className="coverTime" data-test="detail_info_time">{oEventData?.AvailabilityStartDateFormat?.("HH:mm")}{nDash}{oEventData?.AvailabilityEndDateFormat?.("HH:mm")} • {oEventData?.AvailabilityDurationInHoursAndMinutes}</div>
						{(adSkipInfo?.adSkipping === infoMsgSkipAd.ALLOWED || adSkipInfo?.adSkipping === infoMsgSkipAd.NOT_ALLOWED) &&
							<div className="overlaySkipInfoContainer">
								<div className="overlaysSkipInfoBlackCircle">
									<img className="overlaysSkipInfoBlackCircleImg" src={ffAd_BlackCircle} alt=""></img>
									<div className="skipInfoWhiteIcon">
										<img className="skipInfoWhiteIconImg" src={ffAd_icon} alt=""></img>
									</div>
								</div>
								<div className="overlaySkipInfoText">
									{/*  MTVW-459: TODO check if the flag/text is correct??? premium user? */}
									{adSkipInfo?.adSkipping === infoMsgSkipAd.ALLOWED ? l10n.featureDetailsAlternativeAdIndicatorTitleForPremium : l10n.featureDetailsAlternativeAdIndicatorTitleForNonPremium}
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		)
	})

	console.debug("programDetailsMode %s, origin %s inner %s, break %s", programDetailsMode, origin, innerWidth, widthBreak, oEventData?.Title?.Name)
	return (
		<>
			{/* CHECK || programDetailsMode === PROGRAM_DETAILS_MODE.liveTv */}
			{(programDetailsMode === PROGRAM_DETAILS_MODE.desktop && innerWidth > widthBreak) || (programDetailsMode === PROGRAM_DETAILS_MODE.liveTv /*&& origin === PROGRAM_ORIGIN.liveTv*/) ?
				<div id="coverMetadataContainer" className="coverMetadataContainer" style={styling} data-test="movie_details_container_dialog">
					{console.debug("PART 1")}
					<div className="coverTitleContainer">
						{/* MTVW-837: apply smaller font size in liveTv */}
						<div className="coverTitle" id="infoTitle" data-test="detail_info_title"
							style={programDetailsMode === PROGRAM_DETAILS_MODE.liveTv && origin === PROGRAM_ORIGIN.liveTv ? { fontSize: "2.125rem", lineHeight: 1.235 } : {}}>{oEventData?.Title?.Name}</div>
						{innerWidth > widthBreak ?
							<div className="coverEpisode" data-test="detail_info_meta_data">
								<EpgChannelDetailsToolsTitleSecondary innerWidth={innerWidth} widthBreak={widthBreak} oTitle={oEventData?.Title} suffix={<br />} />
							</div>
							: <></>
						}
					</div>
					<MovieInfoMain />
					<RecordingIndicator eventId={oEventData?.id} showText={true} />
				</div>
				:
				// tablet or mobile, liveTv
				<>{(programDetailsMode === PROGRAM_DETAILS_MODE.tabletOrMobile && innerWidth <= widthBreak) || origin === PROGRAM_ORIGIN.liveTv ?
					<div>
						{console.debug("PART 2")}
						<div className="coverTitleContainer" style={styling}>
							<div className="coverTitle" id="infoTitle" data-test="detail_info_title">{oEventData?.Title?.Name}</div>
							{/* tablet or mobile */}
							<div className="coverEpisode" data-test="detail_info_meta_data">
								<EpgChannelDetailsToolsTitleSecondary innerWidth={innerWidth} widthBreak={widthBreak} oTitle={oEventData?.Title} suffix={<br />} />
							</div>
							<MovieInfoMain />
							<RecordingIndicator eventId={oEventData?.id} showText={true} />
						</div>
					</div>
					: <></>
				}</>
			}
		</>
	)
})

export const EpgChannelDetailsInfo = CreateComponent(styles, false, true, ({ classes, oEventData, page, origin }) => {
	/* MTVW-808: commented
	const anchorEl = useRef(null)
	const [isCollapseToggle, setCollapseToggle] = useState(false)
	const defHeight = 35
	const nDash = '\u2013'
	*/
	// MTVW-808: event decoupling
	oEventData = origin === PROGRAM_ORIGIN.liveTv ? oEventData : rootStore.page.OverlayEventDetails.event

	//MTVW-272
	//const [backGroundImg, setBackGroundImg] = useState(oEventData?.Title?.Pictures?.getBoxCover)
	// eslint-disable-next-line no-unused-vars
	//const [backGroundImg, setBackGroundImg] = useState(oEventData?.Title?.BoxCover)
	//const [channel, setChannel] = useState(rootStore.page.MsEpg.getChannel(oEventData?.ChannelId))

	const [openSkipAd, setOpenSkipAd] = useState(false)
	// MTVW-808: liveTv / overlay decoupling
	//const adSkipInfo = rootStore.page.OverlayEventDetails?.adSkipInfo
	const [adSkipInfo, setAdSkipInfo] = useState(null)

	function closeSkipAdModal() {
		setOpenSkipAd(false)
	}

	const historyRouteAdd = useHistoryRouteAdd()

	/* MTVW-808: commented
	useLayoutEffect(() => {
				console.log("<EpgChannelDetailsInfo> clientHeight=%o", anchorEl.current.children[0].clientHeight)
		// .MuiTypography-paragraph {margin - bottom: 8px; }
		setCollapseToggle(anchorEl.current.children[0].clientHeight > defHeight + 8)
	}, [oEventData])
	*/

	const [personsArray, setPersonsArray] = useState([])
	const [scrollArrow, setScrollArrows] = useState({
		leftArrow: false,
		rightArrow: false,
	})
	const [mouseDown, setMouseDown] = useState(false)
	const [isDragging, setIsDragging] = useState(false)

	const refPeopleContainer = useRef(null)
	const scrollStep = () => refPeopleContainer?.current ? refPeopleContainer?.current?.clientWidth / 2 : 200

	//const easingOption = [.17, .67, .83, .67]
	const easingOption = [0, 0.75, 0, 1]
	function velocityAnimation(elem, left, duration, onComplete = null) {
		Velocity(elem, { scrollLeft: left + "px" }, {
			duration: duration,
			easing: easingOption,
			complete: function (val) {
				if (onComplete) onComplete()
			}
		})
	}

	const updateScrollArrowState = () => {
		const maxScrollLeft = Math.floor(refPeopleContainer?.current?.scrollWidth - refPeopleContainer?.current?.clientWidth) - 2
		//console.debug("maxScrollLeft", maxScrollLeft, refPeopleContainer?.current?.scrollLeft, refPeopleContainer?.current?.scrollWidth, refPeopleContainer?.current?.clientWidth)
		const elemPeople = document.getElementById("peopleContainer")
		const elemGrid = document.getElementById("peopleGrid")
		//const style = getComputedStyle(elemContent)
		//console.debug("elemPeople width %s, %o, max %s, %o", elemPeople?.clientWidth, elemPeople?.getBoundingClientRect(), elemGrid?.clientWidth, elemGrid?.getBoundingClientRect())
		if (elemPeople) {
			if (refPeopleContainer?.current?.scrollLeft === 0) {
				let rightArrow = false
				if (refPeopleContainer?.current?.scrollWidth > refPeopleContainer?.current?.clientWidth) rightArrow = true
				setScrollArrows({ leftArrow: false, rightArrow: rightArrow })
			} else if (refPeopleContainer?.current?.scrollLeft > maxScrollLeft) {
				setScrollArrows({ leftArrow: true, rightArrow: false })
			} else {
				setScrollArrows({ leftArrow: true, rightArrow: true })
			}
		}
	}

	const velocityThrottled = throttle(velocityAnimation, 350)
	const handleKeyDown = (event) => {
		//console.debug("EpgChannelDetailsInfo handleKeyDown", refPeopleContainer?.current.scrollLeft, event)
		if (refPeopleContainer?.current) {
			if (event.keyCode === 39) {
				//console.debug("right", scrollStep(), refPeopleContainer?.current?.clientWidth)
				event.preventDefault()
				event.stopPropagation()
				const targetPos = refPeopleContainer.current.scrollLeft + scrollStep()
				velocityThrottled(refPeopleContainer.current, targetPos, 300)
			}
			if (event.keyCode === 37) {
				//console.debug("left", scrollStep(), refPeopleContainer?.current?.clientWidth)
				event.preventDefault()
				event.stopPropagation()
				const targetPos = refPeopleContainer.current.scrollLeft - scrollStep()
				velocityThrottled(refPeopleContainer.current, targetPos, 300)
			}
		}
	}
	//const handleKeyDown = throttle(handleKeyDownImpl, 350)

	const handleMouseDown = (event) => {
		// DO NOT call preventDefault and stopPropagation, otherwise when opened from search the search input box
		// will not work, since the component might be left open and removeEventListeners are not called
		//event.preventDefault()
		//event.stopPropagation()
		setIsDragging(false)
		setMouseDown(true)
	}

	const handleMouseMove = (event) => {
		//console.debug("handleMouseMove", mouseDown, event.movementX, event)
		//event.preventDefault()
		//event.stopPropagation()
		setIsDragging(true)
		if (mouseDown && refPeopleContainer?.current) refPeopleContainer.current.scrollLeft -= event.movementX
	}

	const handleMouseUp = (event) => {
		//event.preventDefault()
		//event.stopPropagation()
		setMouseDown(false)
		if (isDragging) event.stopImmediatePropagation()
		setTimeout(() => {
			setIsDragging(false)
		}, 200)
	}

	useEffect(() => {
		// set 'useCapture' to true, so that we get the event before TvGuide and can stopPropagation
		document.addEventListener("keydown", handleKeyDown, true)
		// use mousedown event to catch event outside of container
		document.addEventListener("mousedown", handleMouseDown, false)
		document.addEventListener("mousemove", handleMouseMove, false)
		// use mouseup event to catch event outside of container
		document.addEventListener("mouseup", handleMouseUp, false)
		setPersonsArray([])
		setScrollArrows({ leftArrow: false, rightArrow: false })
		setAdSkipInfo(null)
		return (() => {
			document.removeEventListener("keydown", handleKeyDown, true)
			document.removeEventListener("mousedown", handleMouseDown, false)
			document.removeEventListener("mousemove", handleMouseMove, false)
			document.removeEventListener("mouseup", handleMouseUp, false)
		})
	}, [])

	useLayoutEffect(() => {
		//console.debug("useLayoutEffect=", rootStore.page.Player.player?.settings.isFullScreen)
		// MTVW-754: added
		rootStore.page.Player.adjustPlayerStyles()
	})

	// MTVW-808
	const fetchPersons = async () => {
		console.debug("ovl event %o, oEventData %o", rootStore.page.OverlayEventDetails.event, oEventData)
		// oEventData is set depending on origin at the top of the component
		const event = oEventData
		const actors = event?.Title?.Credits?.Actors
		const directors = event?.Title?.Credits?.Directors
		console.debug("origin=", origin, rootStore.page.Player.isInLiveTv, actors)
		console.debug("directors=", oEventData?.Title?.Credits?.Directors)
		console.debug("actors=", oEventData?.Title?.Credits?.Actors)

		rootStore.page.OverlayEventDetails.setGenre(null)
		rootStore.page.OverlayEventDetails.setActorName(null)
		setScrollArrows({ leftArrow: false, rightArrow: false })
		const persons = []
		// create a new array to update the reference
		setPersonsArray([...persons])
		for (var i = 0; i < actors?.length; i++) {
			try {
				const elem = actors[i]
				const pic = await getPersonPicture(elem)
				//setPersonsArray(oldArray => [...oldArray, { url: pic, name: elem, type: "actor" }])
				persons.push({ url: pic, name: elem, type: "actor" })
				setPersonsArray([...persons])
				updateScrollArrowState()
			}
			catch (e) {
				console.error("fetchPersons actors", e)
			}
		}
		for (var i = 0; i < directors?.length; i++) {
			try {
				const elem = directors[i]
				const pic = await getPersonPicture(elem)
				//setPersonsArray(oldArray => [...oldArray, { url: pic, name: elem, type: "director" }])
				persons.push({ url: pic, name: elem, type: "director" })
				setPersonsArray([...persons])
				updateScrollArrowState()
			}
			catch (e) {
				console.error("fetchPersons directors", e)
			}
		}
		//console.debug("personsArray", [...persons])
		setPersonsArray([...persons])
	}

	useEffect(() => {
		//console.debug("callingContext", page.callingContext, rootStore.page.OverlayEventDetails.isShow)
		//setPersonsArray([])
		setAdSkipInfo(null)
		fetchPersons().then((res) => {
			// setTimeout to get updated refPeopleContainer?.current?.scrollWidth in updateScrollArrowState
			setTimeout(() => {
				updateScrollArrowState()
			})
		}).catch((e) => {
			// make sure to catch any error
			console.error("fetchPersons", e)
		})

		//MTVW-272
		//setBackGroundImg(oEventData?.Title?.Pictures?.getBoxCover)
		//setBackGroundImg(oEventData?.Title?.BoxCover)
		//setChannel(rootStore.page.MsEpg.getChannel(oEventData?.ChannelId))
		// MTVW-417 in case the event is live rootStore.page.OverlayEventDetails.adSkipInfo=null
	}, [/*rootStore.page.OverlayEventDetails.oEvent,*/ oEventData, origin])

	// MTVW-808: liveTv / overlay decoupling, check channelId
	useEffect(() => {
		//console.debug("setAdSkipInfo", oEventData?.ChannelId, rootStore.page.MsEpg?.getChannel(oEventData?.ChannelId)?.hasAlternativeAds ? rootStore.page.OverlayEventDetails?.adSkipInfo : null)
		if (rootStore.page.OverlayEventDetails?.adSkipInfo?.channelId === oEventData?.ChannelId) setAdSkipInfo(rootStore.page.OverlayEventDetails?.adSkipInfo)
	}, [rootStore.page.OverlayEventDetails?.adSkipInfo])

	// MTVW-811: factored out
	const onPlayClick = (e, selectedType = "") => {
		e.stopPropagation()
		e.preventDefault()
		//rootStore.page.OverlayEventDetails.setEpgEventById(oEventData.id)
		if (rootStore.page.Player.isInLiveTv) {
			rootStore.page.OverlayEventDetails.setShow(false)
			rootStore.page.MiniEpg.setShow(false)
			/* TODO: Possibly recheck
			if (selectedType === "c") {
				rootStore.page.Player.handleChangeChannel(oEventData.event.ChannelId)
			}
			*/
		}

		rootStore.page.OverlayEventDetails.setPlayEvent(false, selectedType)//.then(() => {
		rootStore.page.LiveTv.memoSelectedEpg = null //MTV-3740 restore the snapshot for the channelList used in EpgChannel.js
		// MTVW-727b: use isInLiveTv
		if (!rootStore.page.Player.isInLiveTv) { // was rootStore.page.Player.playerLiveTvVo.isPlayerActive === false
			rootStore.page.OverlayEventDetails.setIsPlayerPresent(true)
		} else {
			/* MTVW-810: moved above before setPlayEvent
			rootStore.page.OverlayEventDetails.setShow(false)
			rootStore.page.MiniEpg.setShow(false)
			*/
			// MTVW-624: combined activityStack for related content and search
			rootStore.page.OverlayEventDetails.resetActivity()
			historyRouteAdd(ROUTE_NAMES.LiveTv)
		}
		setTimeout(() => {
			//window.scrollTo(0, 0)
			let elem = document.getElementById("tvLiveAndDetailsContainer")
			if (elem) elem.scrollTop = 0
			// MTVW-810
			elem = document.getElementsByClassName("MuiDialog-container")
			//console.debug("overlay-dialog", elem)
			if (elem?.length > 0) elem[0].scrollTop = 0
		}, 100)
	}

	//{origin === PROGRAM_ORIGIN.liveTv || !rootStore.page.OverlayEventDetails.isShow /*&& !rootStore.page.Player.isPlayerOnOverlay && page.callingContext !== CALLING_CONTEXT_TYPE.TvLive*/ || rootStore.page.OverlayEventDetails.playEventStarted ?

	return (
		oEventData && (
			<div id="epgChannelDetailsInfo" /*style={{ zIndex: 100000 }}*/ className={classes.EpgChannelDetailsBackgroundImg}  >
				<SkipAdDialog open={openSkipAd} close={closeSkipAdModal} adSkipping={adSkipInfo} />
				<Grid container >
					<Grid item xs={12}>
						{/* MTVW-810: liveTv / overlay decoupling, show ProgramDetailsInfo if origin === PROGRAM_ORIGIN.liveTv */}
						{origin === PROGRAM_ORIGIN.liveTv || !rootStore.page.OverlayEventDetails.isShow /*&& !rootStore.page.Player.isPlayerOnOverlay && page.callingContext !== CALLING_CONTEXT_TYPE.TvLive*/ || rootStore.page.OverlayEventDetails.playEventStarted ?
							/*
							<div>
								<Typography id="infoTitle" variant="h4" paragraph={true} data-test="detail_info_title">
									{oEventData?.Title?.Name}
								</Typography>
								<Typography variant="h6" paragraph={true} data-test="detail_info_meta_data">
									<EpgChannelDetailsToolsTitleSecondary innerWidth={innerWidth} widthBreak={widthBreak} oTitle={oEventData?.Title} suffix={<br />} />
									<span data-test="detail_info_time"> {oEventData?.AvailabilityStartDateFormat?.("dddd, DD.MM.YYYY, HH:mm")}{nDash}{oEventData?.AvailabilityEndDateFormat?.("HH:mm")}</span>
								</Typography>
							</div>
							*/
							<div>
								{console.debug("FROM EpgChannelDetailsInfo")}
								<ProgramDetailsInfo page={page} oEventData={oEventData} programDetailsMode={PROGRAM_DETAILS_MODE.liveTv} origin={origin} />
							</div>
							: <></>
						}
						{/* MTVW-459 */}
						{/*
						{(adSkipInfo?.adSkipping === infoMsgSkipAd.ALLOWED || adSkipInfo?.adSkipping === infoMsgSkipAd.NOT_ALLOWED) && <div className={classes.overlaySkipInfoContainer}>
							<div className={classes.overlaysSkipInfoBlackCircle} style={{ paddingTop: 2 }}>
								<img src={ffAd_BlackCircle} alt="" style={{ height: 11, width: 11 }}></img>
								<div className={classes.skipInfoWhiteIcon}>
									<img src={ffAd_icon} alt="" style={{ height: 9, width: 9 }}></img>
								</div>
							</div>
							<div className={classes.overlaySkipInfoText}>
								{adSkipInfo?.adSkipping === infoMsgSkipAd.ALLOWED ? l10n.featureDetailsAlternativeAdIndicatorTitleForPremium : l10n.featureDetailsAlternativeAdIndicatorTitleForNonPremium}
							</div>
						</div>
						}
						*/}
						{/* MTVW-808: tweaks, moved to ProgramDetailsInfo */}
						{/*<RecordingIndicator eventId={oEventData?.id} showText={true} />*/}
					</Grid>
					{/*
					<Grid xs={2} className={classes.EpgChannelDetailsLogoWrapper} item>
					*/}
					{/* MTVW-810: condition added */}
					{/*
						{channel?.logoUrl && (!rootStore.page.Player.isPlayerOnOverlay || rootStore.page.OverlayEventDetails.playEventStarted) && (
							<CardMedia
								src={channel?.logoUrl + "?mode=box&w=" + 65 * 3}
								onError={e => { logResourceLoadFailure(channel?.logoUrl + "?mode=box&w=" + 65 * 3, e) }}
								title={channel?.Name}
								component="img"
								className={classes.EpgChannelDetailsLogo}
								data-test="detail_info_channel"
							/>
						)}
						</Grid>
					*/}
					<Grid item xs={12}>
						{/*console.debug("oEventData %o", oEventData)*/}
						<div id={`recordInfoContainer_${origin}`} className="overlayRecordInfoContainer">
							{/* MTVW-708: ANSEHEN Button */}
							{/*console.debug("Ansehen button isShow %s, bEnabled %s, !started %s, origin %s, ctx %s, type %s, evt %o", rootStore.page.OverlayEventDetails.isShow, rootStore.page.OverlayEventDetails.isPlayButtonEnabled, !rootStore.page.OverlayEventDetails.playEventStarted, origin, rootStore.page.OverlayEventDetails.context, oEventData?.getCurrentEventType, oEventData)*/}
							{/* MTVW-810: liveTv / overlay decoupling, show only for origin === PROGRAM_ORIGIN.overlay */}
							{rootStore.page.OverlayEventDetails.isShow && rootStore.page.OverlayEventDetails.isPlayButtonEnabled && !rootStore.page.OverlayEventDetails.playEventStarted && origin === PROGRAM_ORIGIN.overlay &&
								// MTVW-811
								<>
									{oEventData?.getCurrentEventType === "c" ?
										<>
											<ButtonLive bId={`buttonLive_${origin}`} label={l10n.commonLabelLive}
												onClick={(e) => { onPlayClick(e, "c") }} data-test="view_button" />
											{rootStore.page.MsEpg?.isReplayAllowed(oEventData.ChannelId, oEventData?.AvailabilityStartDateTs, oEventData?.AvailabilityEndDateTs) &&
												<ButtonRestart bId={`buttonRestart_${origin}`} label={l10n.commonActionRestart}
													onClick={(e) => { onPlayClick(e, "p") }} data-test="view_restart" />
											}
										</>
										:
										<ButtonPlay bId={`buttonPlay_${origin}`} label={l10n.commonActionView}
											onClick={(e) => { onPlayClick(e) }} data-test="view_button">
										</ButtonPlay>
									}
								</>
							}

							<RecordingButtons oEvent={oEventData} origin={origin} />
							{/* MTVW-459
							{rootStore.page.OverlayEventDetails.adSkipInfo !== null && rootStore.page.OverlayEventDetails.adSkipInfo?.adSkipping !== infoMsgSkipAd.UNAVAILABLE && <div
							*/}
							{adSkipInfo?.adSkipping === infoMsgSkipAd.NOT_ALLOWED &&
								<ButtonPremium bId={`buttonPremium_${origin}`} label={l10n.featureDetailsAdSkipInfoButtonTitle}
									onClick={(e) => {
										e.stopPropagation()
										setOpenSkipAd(true)
									}} />
								/*
								<div className={classes.overlaySkipInfoButtonContainer}
									onClick={(e) => {
										e.stopPropagation()
										setOpenSkipAd(true)
									}}>
									<div className={classes.overlaysSkipInfoBlackCircle}>
										<img src={ffAd_BlackCircle} alt="" style={{ height: 32, width: 32 }}></img>
										<div className={classes.skipInfoWhiteIcon}>
											<img src={ffAd_icon} alt="" style={{ height: 26, width: 26 }}></img>
										</div>
									</div>
									<div className={classes.overlaySkipButtonText}>{l10n.featureDetailsAdSkipInfoButtonTitle}</div>
								</div>
								*/
							}
						</div>
					</Grid>
					<Grid item xs={12}>
						{/* CHECK MERGE
 						<Typography variant="body1" paragraph={true} data-test="detail_info_long_synopsis" style={{ width: 584 }}>
             		*/}
						<Typography variant="body1" paragraph={true} data-test="detail_info_long_synopsis">
							{oEventData?.Title?.LongSynopsis}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						{/* MTVW-809: commented
						<Typography variant="body1" paragraph={true} data-test="detail_info_tech_description" className={`${classes.EpgChannelDetailsKey} ${classes.channelDetailInfo}`}>
							<EpgChannelDetailsToolsInfo oEvent={oEventData} />
						</Typography>
						*/}
						<EpgChannelDetailsToolsInfo data-test="detail_info_tech_description" oEvent={oEventData} origin={origin} />
					</Grid>
				</Grid>

				{/* Material UI 4.12: collapsedHeight renamed to collapsedSize*/}
				{/* MTVW-808: commented
				<Grid container className={classes.EpgChannelDetailsTextWrapper}>
					<Collapse id="collapse " in={page.isTextExpanded} className={classes.EpgChannelDetailsInfo + " EpgChannelDetailsInfo"} collapsedSize={`${defHeight}px`} ref={anchorEl}>
				*/}
				{/* MTVW-809: commented
						<div className={classes.EpgChannelDetailsAttribute + " EpgChannelDetailsAttributeResponsive"}>
							<Grid item xs={3}>
								<Typography variant="body1" align="right" className={classes.EpgChannelDetailsKey}>
									{l10n.featureDetailsAgeRecommendation}
								</Typography>
							</Grid>
							<Grid item xs={9}>
								<Typography variant="body1" className={classes.EpgChannelDetailsValue}>
									{oEventData?.Title?.Rating && oEventData?.Title?.Rating !== "FSK0" ?
										`${oEventData?.Title?.Rating}`
										:
										<>&mdash;</>
									}
								</Typography>
							</Grid>
						</div>

						<div className={classes.EpgChannelDetailsAttribute + " EpgChannelDetailsAttributeResponsive"}>
							<Grid item xs={3}>
								<Typography variant="body1" align="right" className={classes.EpgChannelDetailsKey}>
									Sprache
								</Typography>
							</Grid>
							<Grid item xs={9}>
								<Typography variant="body1" className={classes.EpgChannelDetailsValue}>
									{oEventData?.Title?.OriginalLanguageName !== null ?
										oEventData?.Title?.OriginalLanguageName
										:
										<>&mdash;</>}
								</Typography>
							</Grid>
						</div>
						*/}

				{/* MTVW-808: commented
				<div className={classes.EpgChannelDetailsAttribute + " EpgChannelDetailsAttributeResponsive"}>
					<Grid item xs={3}>
						<Typography variant="body1" align="right" className={classes.EpgChannelDetailsKey}>
							Director
						</Typography>
					</Grid>
					<Grid item xs={9}>
						<Typography variant="body1" className={classes.EpgChannelDetailsValue}>
							{oEventData?.Title?.Credits?.Directors && oEventData?.Title?.Credits?.Directors?.length > 0 ?
								oEventData?.Title?.Credits?.Directors.map(director => director).join(", ")
								:
								<>&mdash;</>}
						</Typography>
					</Grid>
				</div>

				<div className={classes.EpgChannelDetailsAttribute + " EpgChannelDetailsAttributeResponsive"} style={{ paddingBottom: "7px" }}>
					<Grid item xs={3}>
						<Typography variant="body1" align="right" className={classes.EpgChannelDetailsKey}>
							Schauspieler
						</Typography>
					</Grid>
					<Grid item xs={9}>
						<Typography variant="body1" className={classes.EpgChannelDetailsValue} >
							{oEventData.Title?.Credits?.Actors && oEventData?.Title?.Credits?.Actors?.length > 0 ?
								oEventData?.Title?.Credits?.Actors.map(actor => actor).join(", ")
								:
								<>&mdash;</>
							}

						</Typography>
					</Grid>
				</div>

				</Collapse>
					{isCollapseToggle && (
						<SwitchBase
							onClick={() => void page.setTextExpanded()}
							type="checkbox"
							disableRipple={true}
							icon={<KeyboardArrowDownIcon />}
							checkedIcon={<KeyboardArrowUpIcon />}
							checked={page.isTextExpanded}
							aria-label="More info"
							className={classes.EpgChannelDetailsSwitch}
							data-test="detail_info_arrow_icon"
						/>
					)
				}
				</Grid >
				*/}

				{personsArray.length > 0 ?
					// MTVW-808: relative position for proper position of arrow chevrons in live TV
					// MTVW-808: tweaks, paddingTop: 35 -> paddingTop: 28
					<Grid id="peopleGrid" container style={{ paddingTop: 28, position: "relative" }}>
						<div id="peopleContainer" className="peopleContainer" ref={refPeopleContainer}
							onMouseMove={(e) => handleMouseMove(e)}
							onScroll={() => {
								updateScrollArrowState()
							}}
						>
							<div className="peopleFrame">
								{personsArray?.map((item, ix) => (
									<div key={"people" + ix}>
										<div className="personTile">
											<div className="personImageContainer"
												onClick={(e) => {
													e.stopPropagation()
													if (!isDragging) {
														//console.debug("isDragging=", isDragging)
														// no back button if in LiveTv
														//if (rootStore.page.Player.isPlayerOnOverlay) {
														if (rootStore.page.OverlayEventDetails.isVisible) {
															rootStore.page.OverlayEventDetails.pushActivity({ type: 'related', id: oEventData?.id })
														}
														rootStore.page.OverlayEventDetails.setActorName(item.name).setComponentType(rootStore.page.Search.CATEGORY_ACTORS).setShow(true, CALLING_CONTEXT_TYPE.TvGuide)
													}
												}}
											>
												<div className="personEllipse1" />
												{item.url ?
													<img className="personImage" src={item.url} />
													:
													<img className="personImageSvg" src={personSvg} />
												}
												<div className="personEllipse2" />
											</div>
											{item.type === "director" ?
												<div className="directorBadgeContainer">
													<div className="directorEllipse" />
													<div className="directorMaskGroup">
														<img className="directorBadgeLogo" src={directorBadge} />
														<div className="directorRectangle" />
													</div>
												</div>
												: <></>
											}
										</div>
										<div className="personName">{item.name}</div>
									</div>
								))}
							</div>

							{scrollArrow.leftArrow ?
								<div className="edgeContainerLeft">
									<div className="edgeButtonContainerLeft" style={{ left: rootStore.page.Player.isInLiveTv ? -35 : "" }}>
										<div className="edgeButtonBg" />
										<div className="edgeButtonLeftImg" onClick={(e) => {
											e.preventDefault()
											e.stopPropagation()
											if (refPeopleContainer?.current) {
												const targetPos = refPeopleContainer.current.scrollLeft - scrollStep()
												velocityThrottled(refPeopleContainer.current, targetPos, 300)
											}
										}} />
									</div>
								</div>
								: <></>
							}
							{scrollArrow.rightArrow ?
								<div className="edgeContainerRight">
									<div className="edgeButtonContainerRight">
										<div className="edgeButtonBg" />
										<div className="edgeButtonRightImg" onClick={(e) => {
											e.preventDefault()
											e.stopPropagation()
											if (refPeopleContainer?.current) {
												const targetPos = refPeopleContainer.current.scrollLeft + scrollStep()
												velocityThrottled(refPeopleContainer.current, targetPos, 300)
											}
										}} />
									</div>
								</div>
								: <></>
							}
						</div>
					</Grid>
					: <></>
				}
			</div>
		)
	)
})
