import { action, computed, observable, makeObservable } from "mobx"
import { flow, getPath } from "mobx-state-tree"
import { RepoAbstract, RepoAbstractMbx } from "store/page/repo/RepoAbstract"
import { rootStore } from "store/RootStore"

export class PageAbstract extends RepoAbstract {
	isMounted = false

	routeParams = {}

	constructor(parent, path) {
		super(parent, path)
		makeObservable(this, {
			isMounted: observable,
			routeParams: observable,
			isVisible: computed,
			_afterCreateCustomAsync: action,
			_afterAuthCustomAsync: action,
			_setMounted: action,
			_onMount: action,
			_onUnmount: action,
			setRouteParams: action
		})
	}

	get isVisible() {
		return this.isMounted
	}

	getMountBody(onMount = null, onUnmount = null) {
		return [
			() => {
				this._setMounted(true)
				if (this._onMount) {
					this._onMount()
				}
				if (onMount) {
					onMount()
				}
				console.info("getMountBody(onMount=%o, onUnmount=%o) - onMount .isMounted=%o .isVisible=%o", onMount, onUnmount, this.isMounted, this.isVisible)
				return () => {
					//console.debug("PageAbstract getMountBody")
					this._setMounted(false)
					if (this._onUnmount) {
						this._onUnmount()
					}
					if (onUnmount) {
						onUnmount()
					}
					console.info("getMountBody(onMount=%o, onUnmount=%o) - onUnmount .isMounted=%o .isVisible=%o", onMount, onUnmount, this.isMounted, this.isVisible)
				}
			},
			[]
		]
	}

	/**
	 * return {PageStore}
	 */
	get _pageStore() {
		return this._root.page
	}

	_init() {
		console.log("@()")
		if (this.init) {
			console.log("@@init()")
			this.init()
		}
		if (this.initAsync) {
			console.log("@@initAsync()")
			this.initAsync()
		}
	}

	/**
	 * @todo
	 */
	afterCreate() {
		if (this._afterCreate) {
			this._afterCreate()
		}
		this._afterCreateCustomAsync()
	}

	/**
	 * @todo
	 */
	_afterCreateCustomAsync = flow(function* () {
		if (this._afterCreateAsync || this._afterAuth || this._afterAuthAsync) {
			yield this._root.waitForTimeSync.waitAsync()

			if (this._afterCreateAsync) {
				// MTVW-536: use yield
				yield this._afterCreateAsync()
			}
			this._afterAuthCustomAsync()
		}
	})

	/**
	 * @todo
	 */
	_afterAuthCustomAsync = flow(function* () {
		if (this._afterAuth || this._afterAuthAsync) {
			yield this._root.waitForAuth.waitAsync()
			if (this._afterAuth) {
				this._afterAuth()
			}
			if (this._afterAuthAsync) {
				this._afterAuthAsync()
			}
		}
	})

	_setMounted(bState) {
		console.log("@(bState=%o)", bState)
		this.isMounted = bState
	}

	_onMount() {
		console.info("@")
	}

	_onUnmount() {
		console.info("@")
	}

	setRouteParams(params) {
		console.info("@(params=%o)", params)
		this.routeParams = params
		return this
	}
}

export const PageAbstractMbx = RepoAbstractMbx.named("PageAbstract")
	.volatile(self => ({
		isMounted: false
	}))
	.views(self => ({
		/**
		 * @returns {import("mobx-state-tree").Instance<typeof import("store/RootStore").RootStore>}
		 */
		get _root() {
			return rootStore
		},
		get isVisible() {
			return self.isMounted
		},
		getMountBody(onMount = null, onUnmount = null) {
			return [
				() => {
					self._setMounted(true)
					self._onMount()
					if (onMount) {
						onMount()
					}
					console.info("%s isMounted=%o isVisible=%o self=%o", getPath(self), self.isMounted, self.isVisible, self)
					return () => {
						//console.debug("PageAbstractMbx getMountBody")
						self._setMounted(false)
						self._onUnmount()
						if (onUnmount) {
							onUnmount()
						}
						console.info("%s isMounted=%o isVisible=%o self=%o", getPath(self), self.isMounted, self.isVisible, self)
					}
				},
				[]
			]
		},
		get pageStore() {
			return self._root.page
		}
	}))
	.actions(self => ({
		afterCreate() {
			// assign root store for var
			//			self._root = rootStore
			if (self._afterCreate) {
				self._afterCreate()
			}
			self._afterCreateCustomAsync()
		},
		_afterCreateCustomAsync: flow(function* () {
			if (self._afterCreateAsync || self._afterAuth || self._afterAuthAsync) {
				yield self._root.waitForTimeSync.waitAsync()

				if (self._afterCreateAsync) {
					// MTVW-536: use yield
					yield self._afterCreateAsync()
				}
				self._afterAuthCustomAsync()
			}
		}),
		_afterAuthCustomAsync: flow(function* () {
			if (self._afterAuth || self._afterAuthAsync) {
				yield self._root.waitForAuth.waitAsync()
				if (self._afterAuth) {
					self._afterAuth()
				}
				if (self._afterAuthAsync) {
					self._afterAuthAsync()
				}
			}
		}),
		_setMounted(bState) {
			self.isMounted = bState
		},
		_onMount() { },
		_onUnmount() { },
		_init() { }
		// fake
		//_afterCreate() {},
		// fake
		//_afterAuth() {},
		// fake
		//_afterCreateAsync: flow(function*() {}),
		// fake
		//_afterAuthAsync: flow(function*() {})
	}))
