import { resolveIdentifier } from "mobx-state-tree"
import { Channel } from "store/model/traxis/Channel"
import { Product } from "store/model/traxis/Product"
import { lazyCallback } from "store/ModelTools"
import { rootStore } from "store/RootStore"
import { ChannelLists } from "store/model/traxis/ChannelList"
import { ClassAbstract } from "store/ClassTools"

export class SingletonStore extends ClassAbstract {
	/**
	 * @returns {import("mobx-state-tree").Instance<typeof import("store/api/GetChannels").GetChannels>}
	 */
	get channels() {
		return lazyCallback(this, "channels", () => rootStore.api.GetChannels().fetchData())
	}

	channelsResolveIdentifier(id) {
		return resolveIdentifier(Channel, this.channels.Data, id)
	}

	/**
	 * @returns {import("store/api/GetChannelLists").GetChannelLists}
	 */
	get channelLists() {
		return lazyCallback(this, "channelLists", () => rootStore.api.GetChannelLists().fetchData())
	}

	channelListsResolveIdentifier(id) {
		return resolveIdentifier(ChannelLists, this.channelLists.Data, id)
	}

	/**
	 * @returns {import("store/api/GetProducts").GetProducts}
	 */
	get products() {
		return lazyCallback(this, "products", () => rootStore.api.GetProducts().fetchData())
	}

	productsResolveIdentifier(id) {
		return resolveIdentifier(Product, this.products.Data, id)
	}

	/**
	 * @returns {import("store/api/GetCustomer").GetCustomer}
	 */
	get customer() {
		return lazyCallback(this, "customer", () => rootStore.api.GetCustomer().fetchData())
	}

	/**
	 * @returns {import("store/api/GetProfile").GetProfile}
	 */
	get profile() {
		return lazyCallback(this, "profile", () => rootStore.api.GetProfile().fetchData())
	}
}
