/**
 * @ Author: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Create Time: 2019-06
 * @ Modified by: Kamil Michalak (k.michalak@kstudio.pl)
 * @ Modified time: 2019-10
 */

export const urlFixHttpProtocolMismatch = url => {
	// if via http:// try make this make the same like a domain
	url = String(url)
	if (url.startsWith("http:")) {
		return url.substring(5)
	}
	return url
}
