import { Button } from "@mui/material"
import { RouteLink } from "components/utils/RouteUtils"
import React from "react"
import { ROUTE_NAMES } from "site-ql/RouteConst"

import "./notFound.css"

export const NotFound = () => {
	return (
		<div className="notFoundWrapper">
			<div className="notFoundContent">
				<div className="notFoundTitle">
					Hoppla… Diese Seite existiert nicht (mehr)
				</div>
				<div className="notFound404">
					404
				</div>
				<div className="notFoundTxt" color="primary">
					Die aufgerufene Seite konnte nicht gefunden werden.
				</div>
				<RouteLink Component={Button} name={ROUTE_NAMES.LiveTv} variant="contained" color="primary" className="notFoundHomeButton">
					Zur Startseite
				</RouteLink>
			</div>
		</div>
	)
}
