// l10n status: not applicable
// MTVW-435
import { mapKeys, camelCase } from "lodash"
import { printf } from "fast-printf"
import { flow } from "mobx"

export var l10n = {}

export const setLanguage = flow(function* (lang) {
   //export async function setLanguage(lang) {
   try {
      const response = yield fetch(`${lang}.json`)
      let data = yield response.json()
      l10n = yield mapKeys(data, (value, key) => camelCase(key))
      //console.debug("L10n data %o, %o", l10n, l10n.featurePinRecoveryNewPinEmail)
      //mytest()
   }
   catch (e) {
      // TODO: add fallback object or reload app?
      console.error("FATAL Error in setLanguage %o", e)
   }
})

// eslint-disable-next-line no-extend-native
String.prototype.format = function () {
   return arguments?.length === 0 ? this : printf(this, ...arguments)
}

/*
function mytest() {
   console.debug("PLACEHOLDERS Test %s", l10n.featurePinRecoveryNewPinEmailWithChangeLink.format("User", "Ivo", "Ivo", "1234"))
   console.debug("EMPTY %s", "Nothing in this string".format())
}
*/
